import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { PageHeader } from '../../components/PageComponents';
import DescriptionCard from '../../components/DescriptionCard';
import DashboardCard from '../../components/reusedComponents/DashboardCard.js';
import ItemDeleteModal from '../../components/reusedComponents/ItemDeleteModal.js';
import {
  ContentContainer,
  LinkButton,
  Icon,
  Grid,
  FormSelectInput,
  VerticalContainer,
  MessageField,
} from '../../components/CommonComponents';
import SearchBar from '../../components/SearchBar';
import { dynamicSortAlpha } from '../../utils/ListUtils';
import { getUsername } from '../../utils/TokenUtils';
import { toast } from 'react-toastify';
import add from '../../images/icons/add.png';
import premiseIcon from '../../images/icons/premise.png';
import { getSelectedRole } from '../../utils/RoleUtils.js';
import Loader from 'react-loader-spinner';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';

const PremiseView = () => {
  const [premises, setPremises] = useState([]);
  const [redirect, setRedirect] = useState('');
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState('active');
  const [searchQuery, setSearchQuery] = useState('');
  const [sortField, setSortField] = useState('name');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    fetchPremises(sortField);
  }, [sortField]);

  const fetchPremises = async (sortField) => {
    try {
      setLoading(true);
      const selectedRole = getSelectedRole();
      const response = await axios.get(`/api/premises/user/${getUsername()}`, {
        params: {
          selectedRole: selectedRole,
        },
      });

      const fetchedPremises = response.data;

      const transformedPremises = fetchedPremises.map((premise) => ({
        _id: premise._id ?? 'N/A',
        address: premise.address ?? 'N/A',
        municipality: premise.municipality ?? 'N/A',
        name: premise.name ?? 'N/A',
        operation: premise?.operation?.businessName ?? 'N/A',
        pid: premise.pid ?? 'N/A',
        province: premise.province ?? 'N/A',
        isDeleted: premise.isDeleted !== undefined ? premise.isDeleted : 'N/A',
      }));

      setPremises(transformedPremises.sort(dynamicSortAlpha(sortField)));
    } catch (error) {
      toast.error('Failed to fetch premises. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = (event) => {
    setSortField(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredPremises = premises.filter((premise) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const matchesSearch =
      premise.name?.toLowerCase().includes(lowerCaseQuery) ||
      premise.pid?.toLowerCase().includes(lowerCaseQuery) ||
      premise.municipality?.toLowerCase().includes(lowerCaseQuery) ||
      premise.province?.toLowerCase().includes(lowerCaseQuery) ||
      premise.operation?.toLowerCase().includes(lowerCaseQuery);

    if (filter === 'active' && !premise.isDeleted) {
      return matchesSearch;
    }

    if (filter === 'closed' && premise.isDeleted) {
      return matchesSearch;
    }

    if (filter === 'all') {
      return matchesSearch;
    }

    return false;
  });

  const viewPremise = ({ _id }) => {
    setRedirect(`premises/${_id}`);
  };

  const editPremise = ({ _id }) => {
    setRedirect(`premises/update/${_id}`);
  };

  const deletePremise = async ({ _id }) => {
    try {
      await axios.delete(`/api/premises/${_id}`).then(function () {
        fetchPremises(sortField, setPremises);
        toast.success('Operation Successfully Deleted');
      });
    } catch (err) {
      toast.error(`Error: ${err.response?.data?.message || err.message}`);
    }
  };

  const restorePremise = async ({ _id }) => {
    try {
      await axios.put(`/api/premises/restore/${_id}`);
      fetchPremises(sortField);
      setFilter('active');
      toast.success('Premise Restored Successfully');
    } catch (error) {
      toast.error(`Error: ${error.response?.data?.message || error.message}`);
    }
  };

  useEffect(() => {
    const handleResize = () => setWindowSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (redirect !== '') {
    return <Redirect push to={redirect} />;
  }

  const description =
    'Premises are defined as the physical locations where events occur. Premises need to be identified by Premises Identification (PID) issued by your provincial government and events need to be reported using PIDs. You can create your premises here.';

  return (
    <>
      {loading ? (
        <PageCard
          title="Loading your premises..."
          loader={
            <Loader type="Oval" color={agGreen} height={50} width={150} />
          }
        />
      ) : (
        <VerticalContainer justify_content="flex_start" align_items="stretch">
          <PageHeader style={{ margin: '34px 0px 0px' }}>Premises</PageHeader>
          <DescriptionCard
            page="premise"
            content={description}
            icon={premiseIcon}
          />
          <p>
            If you need a PID for your premises, you can find out how to get one{' '}
            <a
              href="https://inspection.canada.ca/animal-health/terrestrial-animals/traceability/premises-identification/eng/1495127375452/1495127376419"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            .
          </p>
          <h2>Add a new premise</h2>
          <LinkButton $stylePreset="dark" to="/premises/create">
            <span>Add</span> <Icon width="20px" m="0px" src={add} />
          </LinkButton>
          <br />
          <ContentContainer
            $stylePreset={'light'}
            mb="30px"
            style={{
              flexDirection: 'row',
              gap: '30px 20px',
              width: 'fit-content',
            }}
          >
            <VerticalContainer style={{ alignContent: 'right' }}>
              <h2>Search for a Premise</h2>
              <SearchBar
                placeholder="Search for a Premise"
                value={searchQuery}
                onChange={handleSearch}
                containerStyle={{ marginBottom: '20px' }}
              />
            </VerticalContainer>
            <VerticalContainer style={{ alignContent: 'right' }}>
              <h2>Sort List</h2>
              <FormSelectInput
                name="sort field"
                value={sortField}
                onChange={handleSort}
                style={{ marginBottom: '20px' }}
              >
                <option value="pid">PID</option>
                <option value="name">Premise Name</option>
                <option value="operation">Operation Name</option>
                <option value="municipality">Municipality</option>
                <option value="province">Province/Territory</option>
              </FormSelectInput>
            </VerticalContainer>
            <VerticalContainer style={{ alignContent: 'right' }}>
              <h2>Filters</h2>
              <FormSelectInput
                name="filter"
                value={filter}
                onChange={handleFilterChange}
                style={{ marginBottom: '20px' }}
              >
                <option value="active">Active Premises</option>
                <option value="closed">Closed Premises</option>
                <option value="all">All Premises</option>
              </FormSelectInput>
            </VerticalContainer>
          </ContentContainer>
          {filteredPremises.length === 0 ? (
            <MessageField>
              It looks like you don't have any {filter} premises at the moment.
            </MessageField>
          ) : (
            <Grid
              templateColumns="repeat(auto-fill, minmax(320px, 1fr))"
              gap="30px 20px"
            >
              {filteredPremises.map((premise) => (
                <DashboardCard
                  data={premise}
                  viewItem={viewPremise}
                  editItem={editPremise}
                  deleteItem={() => {
                    setShowDeleteModal(true);
                    setSelectedItem(premise);
                  }}
                  restoreItem={restorePremise}
                  key={premise._id}
                  itemType="premise"
                  cardTitle="PID"
                  additionalFields={[
                    { label: 'Premise Name', key: 'name' },
                    { label: 'Operation', key: 'operation' },
                    { label: 'Address', key: 'address' },
                    { label: 'Municipality', key: 'municipality' },
                    { label: 'Province', key: 'province' },
                  ]}
                />
              ))}
            </Grid>
          )}
          {showDeleteModal && (
            <ItemDeleteModal
              item={selectedItem}
              itemType={'premise'}
              deleteItem={deletePremise}
              closeModal={setShowDeleteModal}
            />
          )}
        </VerticalContainer>
      )}
    </>
  );
};

export default PremiseView;
