import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { HorizontalContainer } from '../CommonComponents';
import { COLORS } from '../../utils/Constants';

import Timeline from './Timeline';
import StyledForm from './StyledForm';
import CustomButton from './CustomButton';
import DynamicFormFields from './DynamicFormFields';
import { SubmittingSpinner } from '../CommonComponents';

const StepBasedForm = memo(
  ({
    formFields,
    currentStep,
    stepsNames,
    handleStepClick,
    handleFieldChange,
    validFieldsInCurrentStep,
    validForm,
    submitting,
    handleSubmit,
    title,
    onCancelPath = null,
    disableBack = false,
  }) => {
    if (!formFields) {
      return null;
    }

    return (
      <StyledForm
        width="80%"
        height="100%"
        align_items="stretch"
        title={{ text: title, placement: 'outside' }}
        onCancelPath={onCancelPath}
      >
        {/* Steps Timeline */}
        {stepsNames.length > 1 && (
          <Timeline
            steps={stepsNames}
            currentStep={currentStep}
            onStepClick={handleStepClick}
          />
        )}
        {/* Horizontal Separator Line */}
        <hr style={{ width: '100%', border: `1px solid ${COLORS.primary}` }} />

        {/* Step Fields */}
        <DynamicFormFields
          stepFields={formFields[currentStep].fields}
          handleFieldChange={handleFieldChange}
        />

        {/* Navigation Buttons */}
        <HorizontalContainer mt="2rem" justify_content="space-between">
          {!disableBack && currentStep > 0 ? (
            <CustomButton
              $stylePreset={currentStep === 0 ? 'back-disabled' : 'back-active'}
              type="button"
              disabled={currentStep === 0}
              onClick={() => handleStepClick(currentStep - 1)}
            >
              Back
            </CustomButton>
          ) : (
            <p></p>
          )}

          {currentStep < formFields.length - 1 && (
            <CustomButton
              type="button"
              $stylePreset={
                validFieldsInCurrentStep ? 'next-active' : 'next-disabled'
              }
              onClick={() => handleStepClick(currentStep + 1)}
              disabled={!validFieldsInCurrentStep || submitting}
            >
              Next
            </CustomButton>
          )}
          {currentStep === formFields.length - 1 && (
            <CustomButton
              type="button"
              $stylePreset={validForm ? 'submit-active' : 'submit-disabled'}
              disabled={submitting || !validForm}
              onClick={handleSubmit}
              submitting={submitting}
            >
              Submit
            </CustomButton>
          )}
        </HorizontalContainer>
      </StyledForm>
    );
  },
);

StepBasedForm.propTypes = {
  formFields: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  stepsNames: PropTypes.array.isRequired,
  handleStepClick: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  validFieldsInCurrentStep: PropTypes.bool.isRequired,
  validForm: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  onCancelPath: PropTypes.string,
  disableBack: PropTypes.bool,
};

export default StepBasedForm;
