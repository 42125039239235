import React from 'react';
import styled, { keyframes } from 'styled-components';
import { COLORS } from '../../utils/Constants';
import TextInputField from './TextInputField';
import PasswordField from './PasswordField';
import DropDownMenu from './DropDownMenu';
import Instruction from './Instruction';
import FieldLabel from './FieldLabel';
import SearchableDropdown from '../SearchableDropdown';
import { toast } from 'react-toastify';
import { useState } from 'react';

const DynamicFormFields = ({ stepFields, handleFieldChange }) => {
  const [loading, setLoading] = useState(false);
  if (!stepFields) {
    return null;
  }
  const renderInputField = (field, index) => {
    if (!field || field.hidden) {
      return null;
    }
    switch (field.type) {
      case 'text':
        return (
          <div key={`text-${index}`}>
            <TextInputField
              field={field}
              onChange={(e) => handleFieldChange(index, e)}
            />
          </div>
        );

      case 'select':
        if (field.optionsFilter) {
          field.options = field.optionsFilter(stepFields);
        }
        return (
          <div key={`select-${index}`}>
            <DropDownMenu
              handleFieldChange={handleFieldChange}
              field={field}
              index={index}
              required={field.required}
            />
          </div>
        );

      case 'content':
        return (
          <div key={`text-${index}`}>
            {field.name && field.requiredLabel ? (
              <FieldLabel name={field.name} required={true} />
            ) : (
              field.name && <FieldLabel name={field.name} required={false} />
            )}
            {field.content}
          </div>
        );

      case 'searchable':
        return (
          <div key={`searchable-${index}`}>
            <FieldLabel name={field.name} requiredLabel={field.required} />
            <SearchableDropdown
              type={field.type}
              options={field.options}
              optionKey={field.optionKey}
              selectedValues={field.selectedValues || []}
              onSelectionChange={(selectedValues) =>
                handleFieldChange(index, { target: { value: selectedValues } })
              }
            />
          </div>
        );

      case 'instruction':
        return <Instruction field={field} />;

      case 'password':
        return (
          <PasswordField
            key={`password-${index}`}
            field={field}
            onChange={(e) => handleFieldChange(index, e)}
          />
        );

      case 'location':
        return (
          <div key={`location-${index}`}>
            <TextInputField
              field={field}
              onChange={(e) => handleFieldChange(index, e)}
              required={field.required}
            />
            <LocationButtonWrapper
              style={{
                display: 'flex',
                textAlign: 'center',
                marginTop: 10,
              }}
            >
              <LocationButton
                $stylePreset="alt"
                type="button"
                onClick={() => {
                  setLoading(true);
                  function success(position) {
                    const lat = position.coords.latitude;
                    const long = position.coords.longitude;
                    handleFieldChange(index, {
                      target: { value: `${lat},${long}` },
                    });
                    setLoading(false);
                  }
                  function error(err) {
                    if (err.code === 1 || err.code === 2) {
                      toast.error(
                        'Position Unavailable, Ensure location services are enabled',
                      );
                    } else {
                      toast.error('Request Timed out, try again later.');
                    }
                    setLoading(false);
                  }
                  navigator.geolocation.getCurrentPosition(success, error);
                }}
                disabled={loading}
              >
                {loading ? <Spinner /> : 'Get current location'}
              </LocationButton>
              <LocationButton
                type="button"
                onClick={() => {
                  handleFieldChange(index, {
                    target: { value: '' },
                  });
                }}
              >
                Clear location
              </LocationButton>
            </LocationButtonWrapper>
            <small style={{ marginTop: '3px' }}>{field.description}</small>
          </div>
        );

      case 'textarea':
        return (
          <div key={`textarea-${index}`}>
            {field.name && (
              <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                {field.name}:{field.required && ' *'}
              </p>
            )}
            <DescBox
              id="descBox"
              maxLength={field.maxLength}
              value={field.value}
              disabled={field.disabled}
              placeholder={field.hint || field.name}
              onChange={(e) => handleFieldChange(index, e)}
            />
            <DescBoxLimitLabel>
              {field.maxLength - (field.value ? field.value.length : 0)}{' '}
              characters
            </DescBoxLimitLabel>
            <small style={{ marginTop: '3px' }}>{field.description}</small>
          </div>
        );

      case 'time':
        return (
          <div key={`time-${index}`}>
            {field.name && (
              <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                {field.name}:{field.required && ' *'}
              </p>
            )}
            <input
              key={`time-input-${index}`}
              type={field.type}
              onChange={(e) => handleFieldChange(index, e)}
              value={
                field.value ||
                new Date().toLocaleTimeString('en-US', {
                  hour12: false,
                  hour: 'numeric',
                  minute: 'numeric',
                })
              }
              disabled={field.disabled}
            />
            {field.validity === false && field.value !== '' && (
              <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                {field.errorMessage === ''
                  ? 'Invalid input.'
                  : field.errorMessage}
              </p>
            )}
          </div>
        );

      case 'date':
        return (
          <div key={`date-${index}`}>
            {field.name && (
              <p style={{ fontWeight: 'bold', marginBottom: '7px' }}>
                {field.name}:{field.required && ' *'}
              </p>
            )}
            <input
              key={`date-input-${index}`}
              type={field.type}
              onChange={(e) => handleFieldChange(index, e)}
              value={
                field.required
                  ? field.value || new Date().toISOString().split('T')[0]
                  : field.value
              }
              disabled={field.disabled}
              max="9999-12-31"
            />
            {field.validity === false && field.value !== '' && (
              <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                {field.errorMessage === ''
                  ? 'Invalid input.'
                  : field.errorMessage}
              </p>
            )}
          </div>
        );
      case 'datetime-local':
        return (
          <div key={`date-${index}`}>
            {field.name && (
              <p style={{ fontWeight: 'bold', marginBottom: '7px' }}>
                {field.name}:{field.required && ' *'}
              </p>
            )}
            <input
              type={field.type}
              onChange={(e) => handleFieldChange(index, e)}
              value={
                field.required
                  ? field.value
                    ? field.value
                    : (field.value = `${new Date().toLocaleDateString()}T${new Date().toLocaleTimeString(
                        'en-US',
                        {
                          hour12: false,
                          hour: 'numeric',
                          minute: 'numeric',
                        },
                      )}`)
                  : field.value
              }
              disabled={field.disabled}
            />
            {field.validity === false && field.value !== '' && (
              <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                {field.errorMessage === ''
                  ? 'Invalid input.'
                  : field.errorMessage}
              </p>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      {stepFields &&
        stepFields.map((field, index) => (
          <div
            key={index}
            style={{
              width: '50%',
              minWidth: '200px',
              margin: '0 auto',
              paddingTop: '1rem',
            }}
          >
            {renderInputField(field, index)}
            <small style={{ marginTop: '3px' }}>{field.description}</small>
          </div>
        ))}
    </>
  );
};

const DescBox = styled.textarea`
  min-height: 90px;
  width: 100%;
  resize: vertical; /* Allowing vertical resizing */
  border: 1px solid ${COLORS.primary};
  border-radius: 10px;
  padding: 10px;
  margin-bottom:7px
  font-family: 'Inter-Regular', sans-serif; /* Ensure correct font-family syntax */
  font-size: 14px;
  line-height: 1.5; /* Adjust line-height for readability */
`;

const DescBoxLimitLabel = styled.div`
  font-size: 12px;
  text-align: right;
  transform: translateY(10px);
`;

const LocationButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
  margin-bottom: 50px;
`;

const LocationButton = styled.button`
  display: inline-block;
  width: 42%;
  padding: 10px;
  font-size: 15px;
  color: ${COLORS.primary};
  background-color: white;
  border: ${COLORS.primary} solid 2px;
  border-radius: 25px;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    color: ${COLORS.primary};
      background-color: white;
      }
  }

  ${(props) => {
    switch (props.$stylePreset) {
      case 'alt':
        return `
color: white;
background-color: ${COLORS.primary};

  &:hover {
    color: ${COLORS.primary};
      background-color: white;
      }
      `;
    }
  }}
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  border: 4px solid ${COLORS.primary};
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
`;

export default DynamicFormFields;
