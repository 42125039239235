import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import {
  Button,
  HorizontalContainer,
  LinkButton,
  OldBackLink,
  VerticalContainer,
} from '../../components/CommonComponents.js';
import axios from 'axios';
import { COLORS } from '../../utils/Constants.js';
import CleanTable from '../../components/CleanTable.js';
import ViewApproveDenyIconMenu from '../../components/reusedComponents/ViewApproveDenyIconMenu.js';
import { ConfirmationModal } from '../../components/reusedComponents/ConfirmationModal.js';
import SearchBar from '../../components/SearchBar.js';
import {
  dynamicSortAlpha,
  dynamicSortDate,
  dynamicSortNumeric,
} from '../../utils/ListUtils.js';
import queue from '../../images/icons/queue.png';

import { data } from './TagAllocationData.js'; // <--- Temporary, just for testing

const columns = [
  { name: 'Tag Manufacturer', id: 'manufacturer' },
  { name: '# of Tags Requested', id: 'numberOfTags' },
  { name: 'Approval/Denial Date', id: 'decisionDate' },
  { name: 'Name', id: 'name' },
  { name: 'Status', id: 'status' },
  { name: 'Actions', id: 'actions' },
];

export default function TagAllocationReviewListView() {
  const [redirect, setRedirect] = useState();
  const history = useHistory();
  const [roles, setRoles] = useState([]);

  const [allocationRequests, setAllocationRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState('');
  const [reason, setReason] = useState(''); // this is unused but will be sent to the back-end in the future

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get('/api/users/role');
        setRoles(res.data);
      } catch (err) {
        toast.error(err.message);
      }
    })();

    fetchAndFormatAllocationRequests();
  }, []);

  const fetchAndFormatAllocationRequests = () => {
    let fetchedAllocationReqs = data;
    fetchedAllocationReqs.forEach((r) => {
      r.actions = (
        <ViewApproveDenyIconMenu
          approveLink={r.status === 'Awaiting Approval' ? approve : 'disabled'}
          viewLink={`/tagallocation/review/${r._id}`}
          denyLink={r.status === 'Awaiting Approval' ? deny : 'disabled'}
        />
      );
    });

    setAllocationRequests(fetchedAllocationReqs);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = (sortField) => {
    const sortedArr = [...allocationRequests];

    if (sortField === 'numberOfTags' || sortField === '-numberOfTags')
      setAllocationRequests(sortedArr.sort(dynamicSortNumeric(sortField)));
    else if (sortField === 'decisionDate' || sortField === '-decisionDate')
      setAllocationRequests(sortedArr.sort(dynamicSortDate(sortField)));
    else setAllocationRequests(sortedArr.sort(dynamicSortAlpha(sortField)));
  };

  const filteredTagAllocReqs = allocationRequests.filter((r) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      r.manufacturer.toLowerCase().includes(lowerCaseQuery) ||
      r.numberOfTags.includes(lowerCaseQuery) ||
      r.name.toLowerCase().includes(lowerCaseQuery) ||
      r.status.toLowerCase().includes(lowerCaseQuery)
    );
  });

  const approve = () => {
    setModalMode('approve');
    setShowModal(true);
  };

  const confirmApprove = async () => {
    setShowModal(false);
    toast.success('Tag Allocation Request Approved');
  };

  const deny = () => {
    setModalMode('deny');
    setShowModal(true);
  };

  const confirmDeny = async () => {
    setShowModal(false);
    toast.success('Tag Allocation Request Denied');
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <VerticalContainer justify_content="start" align_items="start" mt="50px">
      <OldBackLink onClick={history.goBack}>&larr; Back</OldBackLink>
      <Header style={{ marginRight: 'auto' }}>
        Review Tag Allocation Requests
      </Header>
      <p>
        All requests from users for tag allocation. Search with the bar below,
        or click on any of the headers to sort the list.
      </p>

      <p>
        Click on any of the icons to the right of the table rows to approve,
        deny or view requests. Click the "Process All" button to get the oldest
        request and process the remaining requests in a queue.
      </p>
      <SearchAndProcessDiv>
        <SearchContainer>
          <SearchBar
            placeholder="Search Tag Allocation Requests"
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchContainer>

        <LinkButton $stylePreset="dark" to="/tagallocation/queue">
          <span>Process All </span>
          <QueueIcon src={queue} />
        </LinkButton>
      </SearchAndProcessDiv>
      {roles.some((r) => r.roleType == 'admin') ? (
        <CleanTable
          columns={columns}
          rows={filteredTagAllocReqs}
          handleSort={handleSort}
          defaultSortColumnId="decisionDate"
        />
      ) : (
        <>
          <h2>
            You do not have the Permission to view this page, please click the
            button below to be redirected back
          </h2>

          <Button
            onClick={() => {
              setRedirect('/');
            }}
          >
            Home
          </Button>
        </>
      )}
      <ConfirmationModal
        message={
          modalMode === 'deny'
            ? 'Are you sure you would like to deny?'
            : 'Are you sure you would like to approve?'
        }
        subMessage={
          modalMode === 'deny' ? 'Please enter the reason for denial' : null
        }
        cancel={() => {
          setShowModal(false);
        }}
        confirm={modalMode === 'deny' ? confirmDeny : confirmApprove}
        textSet={modalMode === 'deny' ? setReason : null}
        show={showModal}
      />
    </VerticalContainer>
  );
}

const Header = styled.h2`
  color: ${COLORS.primary};
  margin-top: 0px;
  margin-bottom: 20px;
`;

const SearchContainer = styled.div`
  width: 300px;
`;

const SearchAndProcessDiv = styled(HorizontalContainer)`
  width: 100%;
  justify-content: space-between;
`;

const QueueIcon = styled.img`
  width: 25px;
  margin: 0;
`;
