import {
  getTransportLogs,
  getRelatedTransportLogs,
} from '../../utils/TransportUtils';
import ViewTransfer from '../../components/ViewTransfer';
import { sortReportsByDate } from '../../components/SheepReportComponents';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import { localTimeFormatter } from '../../utils/TimeUtils';
import React from 'react';

const columns = [
  {
    id: 'departureDate',
    name: 'Transport Date',
  },
  {
    id: 'departurePID',
    name: 'Departure Premise PID',
  },
  {
    id: 'destinationPID',
    name: 'Destination Premise PID',
  },
  {
    id: 'animalTags',
    name: 'Transported Animals',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const TransportReportListView = () => {
  const fetchTransportReports = async () => {
    let relatedLogs = await getRelatedTransportLogs().then((reports) => {
      return reports.map((report) => ({ ...report, related: true }));
    });

    let fetchedReports = await getTransportLogs().then((reports) => {
      return reports.concat(relatedLogs);
    });

    fetchedReports.forEach((r) => {
      r.actions = <ViewTransfer id={r._id} endpoint={'/reports/transport/'} />;
      r.departureDate = localTimeFormatter(r.departureDate);
      r.numberOfAnimals = r.animals.length;
    });
    return sortReportsByDate(fetchedReports);
  };

  return (
    <SheepReportListComponent
      title="Transport"
      description="A Transport Report captures information related to how 
      animals were moved between premises. You are required to ensure that 
      animals being moved between operations are accompanied by a movement 
      document. However, you are not required to report that you are 
      transporting animals. If a movement document has not been created by 
      the departure site, you can create one here."
      fetchReportsFunction={fetchTransportReports}
      detailEndpoint="/reports/transport/"
      createEndpoint="/reports/transport/create"
      csvEndpoint="/reports/transport/create/csv"
      columns={columns}
    />
  );
};

export default TransportReportListView;
