import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TestNotifications from './views/TestNotifications';

// Route pages
import RestrictedRoute from './components/RestrictedRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Landing from './components/Landing';
import Login from './components/Login';
import PasswordResetView from './views/PasswordResetView';
import Register from './components/Register';
import ForgotPasswordView from './views/ForgotPasswordView';
import ForgotUsernameView from './views/ForgotUsernameView';
import HomePage from './views/HomePage.js';
import UnactivatedView from './views/Unactivated.js';
import AdminRouter from './routes/AdminRouter';
import OperationRouter from './routes/OperationRouter';
import VehicleRouter from './routes/VehicleRouter';
import FeedbackRouter from './routes/FeedbackRouter';
import ContactRouter from './routes/ContactRouter';
import PremiseRouter from './routes/PremiseRouter';
import TraceRouter from './routes/TraceRouter';
import SheepRouter from './routes/SheepRouter';
import NotificationRouter from './routes/NotificationRouter';
import SlaughterReportRouter from './routes/SlaughterReportRouter';
import UserView from './views/User/UserView';
import ErrorPage from './views/ErrorPage';
import Carousel from './views/Sheep/CarouselView';
import TransactionVerificationRouter from './routes/TransactionVerificationRouter';
import PIDRouter from './routes/PIDRouter';
import HelpRouter from './routes/HelpRouter';
import TagAllocationRouter from './routes/TagAllocationRouter.js';
import { PageContainer } from './components/PageComponents.js';
import styled, { createGlobalStyle } from 'styled-components';
import {
  getToken,
  getUsername,
  tokenTimer,
  logoutAndClearTokens,
  loginAndSetToken,
  refreshTokens,
  getRoles,
} from './utils/TokenUtils';
import { COLORS } from './utils/Constants';

import axios from 'axios';
import UserRouter from './routes/UserRouter';
import SheepCsvImportView from './views/Sheep/SheepCsvImportView';
import ReportRouter from './routes/ReportRouter';
import TutorialDashboard from './views/TutorialDashboard';
import landing_page_background from './images/landing_page_background.png';
import { RoleChangeContext } from './RoleChangeContext.js';

// Create a timer to when the refresh token (! the access token) will expire
// TODO: Perhaps create a session expired page for a better user experience?

const axiosSetup = (setState) => {
  // Intercept all RESPONSES, and try to refresh token if needed
  axios.interceptors.response.use(
    (res) => res,
    (error) => {
      if (!error.response) return Promise.reject(error);
      console.log('Error status: ', error.response.status);
      // Did it fail due to an authorization problem (aka tokens, aka 401)?
      if (error.response.status !== 401) {
        return Promise.reject(error);
      } else {
        // This checks if refreshTokens() failed and if it failed, log out user.
        if (error.response.data.error_type === 'TOKEN_REFRESH_FAIL') {
          setState(null);
          return Promise.reject(error);
        }

        // Refresh the token from the session storage
        return refreshTokens().then((res) => {
          // The refresh token is still valid
          // Update the state containing the token
          setState(res);

          // Reset the timer:
          tokenTimer();

          // Retry the request
          error.response.config.headers.authorization = `Bearer ${res}`;
          error.response.config.baseURL = process.env.REACT_APP_API_URL;
          return axios(error.response.config);
        });
      }
    },
  );

  // Intercept all REQUESTS, and inject the token into header
  axios.interceptors.request.use(
    (config) => {
      config.headers.authorization = `Bearer ${getToken()}`;
      config.baseURL = process.env.REACT_APP_API_URL;
      return config;
    },
    (error) => Promise.reject(error),
  );
};

const establishWebsocket = async () => {
  const response = await axios.get('/api/notifications/ticket');
  const ticket = response.data.ticket;

  const client = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);

  client.onopen = () => {
    if (client.readyState === WebSocket.OPEN) {
      client.send(
        JSON.stringify({
          request: 'LOGIN',
          data: { wsTicket: ticket, username: getUsername() },
        }),
      );
    }
  };
  // The server sent us a message:
  client.onmessage = (e) => {
    const parsedData = JSON.parse(e.data);
    console.log('Got message: ', parsedData);
    if (parsedData.type === 'NOTIFICATION') {
      toast.info(parsedData.data);
    } else if (parsedData.type === 'STATUS') {
      if (parsedData.data === 'Failure') {
        toast.success('WebSocket connection failed. Please refresh the page.');
      }
    }
  };
  client.onerror = (error) => {
    toast.error(`WebSocket error:`, error);
  };

  return client;
};

export default function App() {
  const [token, setToken] = useState(getToken());
  const [selectedRole, setSelectedRole] = useState();
  const [newRoleArray, setNewRoleArray] = useState(false);
  const [client, setClient] = useState(null);

  axiosSetup(setToken);

  useEffect(() => {
    if (!token) return;
    if (process.env.REACT_APP_USE_WEBSOCKETS) {
      setClient(establishWebsocket());
    }
    const roles = getRoles();
    setSelectedRole(roles && roles.length > 0 ? roles[0].role : null);
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    logoutAndClearTokens();
    setToken(null);
  };

  const handleLogin = (user) => {
    return loginAndSetToken(user).then(
      ({ res, token }) => {
        setToken(token);
        return res;
      },
      (err) => err.response,
    );
  };

  const unactivated =
    token &&
    !getRoles(token).some((r) => r.role === 'operator' || r.role === 'admin');

  return (
    <AppContainer>
      {!token && <Background background={COLORS.light} />}
      <GlobalStyle />
      <Router>
        <RoleChangeContext.Provider value={{ newRoleArray, setNewRoleArray }}>
          <Navbar
            token={token}
            handleLogout={handleLogout}
            selectedRole={selectedRole}
          />
          <PageContainer>
            <Switch>
              {/* Unrestricted routes */}
              <Route path="/" exact>
                {!token && (
                  <Background
                    background={`url(${landing_page_background})`}
                    bottom={0}
                    left={0}
                  />
                )}
                {token ? (
                  unactivated ? (
                    <UnactivatedView />
                  ) : (
                    <HomePage />
                  )
                ) : (
                  <Landing />
                )}
              </Route>

              <Route path="/register">
                <Register handleLogin={handleLogin} />
              </Route>
              <Route path="/login">
                <Login handleLogin={handleLogin} token={token} />
              </Route>
              <Route path="/forgot/username" component={ForgotUsernameView} />
              <Route path="/forgot/password" component={ForgotPasswordView} />
              <Route path="/reset/:resetToken?" component={PasswordResetView} />
              {/* Only logged in users */}
              {!token && <Redirect to="/login" />}
              {token && unactivated && <Redirect to="/" />}
              {process.env.REACT_APP_USE_WEBSOCKETS && (
                <Route exact path="/testNotifications">
                  <TestNotifications client={client} />
                </Route>
              )}
              <Route
                path="/notifications"
                render={() => <NotificationRouter />}
              />
              <Route path="/tutorial">
                <TutorialDashboard />
              </Route>
              <Route
                path="/tagallocation"
                render={() => <TagAllocationRouter />}
              />
              <Route path="/admin" render={() => <AdminRouter />} />
              <Route exact path="/users/:username" component={UserView} />
              <Route path="/help" render={() => <HelpRouter />} />
              <Route path="/sheep" render={() => <SheepRouter />} />
              <Route path="/pid" render={() => <PIDRouter />} />
              <Route path="/operations" render={() => <OperationRouter />} />
              <Route path="/vehicles" render={() => <VehicleRouter />} />
              <Route path="/feedback" render={() => <FeedbackRouter />} />
              <Route path="/contact" render={() => <ContactRouter />} />
              <Route path="/reports/dash" render={() => <ReportRouter />} />
              <Route path="/premises" render={() => <PremiseRouter />} />
              <Route
                path="/slaughterReport"
                render={() => <SlaughterReportRouter />}
              />
              <Route path="/trace" render={() => <TraceRouter />} />
              <Route
                path="/verify"
                render={() => <TransactionVerificationRouter />}
              />
              <RestrictedRoute
                path="/import/:route"
                redirect="/403"
                // restricted={restrictedCheck(createAnimal)}
                component={SheepCsvImportView}
              />
              <Route path="/onboarding/:page" component={Carousel} />
              {/* Only admins */}
              <Route path="/users/" render={() => <UserRouter />} />
              <Route path="/reports" render={() => <ReportRouter />} />

              <Route component={ErrorPage} />
            </Switch>
          </PageContainer>
          <StyledToastContainer>
            <ToastContainer
              transition={Zoom}
              position="top-center"
              autoClose={5000} // Close the toast after 4 seconds
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </StyledToastContainer>
          <Footer />
        </RoleChangeContext.Provider>
      </Router>
    </AppContainer>
  );
}

const Background = styled.div`
  z-index: -1;
  background: ${(props) => props.background};
  position: absolute;
  background-size: cover;
  background-position: left bottom;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

const AppContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  background-size: cover;
`;

const StyledToastContainer = styled.div`
  .Toastify__toast-container--top-center {
    top: 100px;
  }
`;

// To export things like default fonts, etc
const GlobalStyle = createGlobalStyle`
	* {
		font-family: 'Roboto' !important;
	}
`;
