import { validateSingleCsip } from 'agroledger-shared-library/TagUtils';
const getFormFields = (premises, animals) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Complete this form to activate a tag on a sheep, if you do not have any sheep, add one here:  `,
      LinkData: { label: 'Add a Sheep', path: '/sheep/create/manual' },
    },
    {
      name: 'Premise',
      id: 'premise',
      type: 'select',
      options: premises,
      required: true,
      validityCheck: (fieldValues) => fieldValues.value !== '',
    },
    {
      name: 'Animal',
      id: 'animalTag',
      type: 'select',
      required: true,
      optionsFilter: (fieldValues) => {
        const premiseField = fieldValues.find(
          (field) => field.id === 'premise',
        );
        const selectedPremise = premiseField && premiseField.value;
        if (selectedPremise) {
          const filteredSheeps = animals
            .filter(
              (sheep) =>
                sheep.premise && sheep.premise?.pid === selectedPremise,
            )
            .filter((sheep) => !sheep.tag?.isoNumber);
          const formattedSheeps = filteredSheeps.map((sheep) => {
            if (sheep.tag.localMgmtNumber) {
              return {
                name: `Local Management Number: ${sheep.tag.localMgmtNumber}`,
                value: sheep.tag._id,
              };
            } else if (sheep.tag.tattooNumber) {
              return {
                name: `Tattoo Number: ${sheep.tag.tattooNumber}`,
                value: sheep.tag._id,
              };
            } else if (sheep.tag.usScrapieId) {
              return {
                name: `US Scrapie ID: ${sheep.tag.usScrapieId}`,
                value: sheep.tag._id,
              };
            }
          });
          return formattedSheeps;
        } else {
          return [];
        }
      },
      validityCheck: (fieldValues) => {
        if (fieldValues.value === '') {
          return false;
        } else {
          return true;
        }
      },
    },
    {
      name: 'New CSIP Number',
      id: 'isoNumber',
      type: 'text',
      required: true,
      errorMessage: '',
      value: '',
      onChange: (value) => {
        return value.replace(/\s/g, '');
      },
      validityCheck: (fieldValues) => {
        return validateSingleCsip(fieldValues.value, '');
      },
    },
  ];

  // Grouping steps into a single array
  const addVehicleFields = [
    {
      name: 'Tag Activation',
      fields: stepOneFields,
      isValid: false,
    },
  ];
  return addVehicleFields;
};

export default getFormFields;
