import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import {
  FormContainer,
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
  Button,
} from '../components/CommonComponents';
import FormCard from '../components/FormCard';
import { toast } from 'react-toastify';
import axios from 'axios';
import PasswordField from '../components/reusedComponents/PasswordField';

const PasswordResetView = () => {
  const [form, setForm] = useState({
    verification: '',
    password: '',
    rePassword: '',
  });
  const [error, setError] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const validateForm = () => {
      const { verification, password, rePassword } = form;
      let result = { validity: true, errorMessage: '' };

      const passRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@#$%^&*()!]{8,24}$/;

      if (!password || !rePassword || !verification) {
        result.validity = false;
        result.errorMessage = 'Please fill in all fields';
      } else if (password !== rePassword) {
        result.validity = false;
        result.errorMessage =
          "The retyped password doesn't match. Please make sure your new password is entered correctly.";
      } else if (!passRegex.test(password)) {
        result.validity = false;
        result.errorMessage =
          'Your new password does not meet complexity requirements';
      }
      setError(result.errorMessage);
      setIsFormValid(result.validity);
    };

    validateForm();
  }, [form]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) return;
    try {
      const body = {
        token: form.verification,
        password: form.password,
      };
      const resp = await axios.put('/api/users/passwordreset', body);
      if (resp.status === 200) {
        toast.success('Password reset successful!');
        setRedirect(true);
      }
    } catch (err) {
      toast.error(`An error occurred: ${err.response.data.Error}`);
    }
  };

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <LoginContainer>
      <FormCard title="New Password" errors={error ? [error] : []}>
        <FormContainer>
          <FormInputWrapper key="verificationCode">
            <FormInputLabel htmlFor="verificationCode">
              Verification Code
            </FormInputLabel>
            <FormTextInput
              placeholder="Your verification code"
              type="text"
              id="verificationCode"
              name="verificationCode"
              value={form.verification}
              onChange={(e) =>
                setForm({
                  ...form,
                  verification: e.target.value,
                })
              }
            />
          </FormInputWrapper>
          <FormInputWrapper key="password">
            <PasswordField
              field={{ id: 'retypedPassword', name: 'New Password' }}
              value={form.password}
              onChange={(e) =>
                setForm({
                  ...form,
                  password: e.target.value,
                })
              }
            />
          </FormInputWrapper>
          <FormInputWrapper key="rePassword">
            <PasswordField
              field={{ id: 'retypedPassword', name: 'Re-Enter New Password' }}
              value={form.rePassword}
              onChange={(e) =>
                setForm({
                  ...form,
                  rePassword: e.target.value,
                })
              }
            />
          </FormInputWrapper>
          <Button type="submit" onClick={handleSubmit} disabled={!isFormValid}>
            Reset Password
          </Button>
        </FormContainer>
      </FormCard>
    </LoginContainer>
  );
};

const LoginContainer = styled.div`
  margin: auto;
`;

export default PasswordResetView;
