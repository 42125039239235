// PremiseUtils.js
const axios = require('axios');

export const getPremises = () => {
  return axios.get('/api/premises').then((res) => {
    return res.data;
  });
};

export const getPremisesById = async (id) => {
  const res = await axios.get(`/api/premises/${id}`);
  return res.data;
};

export const getPremiseByPid = async (pid) => {
  return axios
    .get(`/api/premises/pid/${pid}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const validatePremiseExists = async (id) => {
  return axios
    .get(`/api/premises/validate/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const updatePremise = async (premise, id) => {
  try {
    const res = await axios.put(`/api/premises/${id}`, premise);
    return res.data;
  } catch (err) {
    err.message = err.response.data.message;
    throw new Error(err);
  }
};

export const getPremisesForUser = async (username) => {
  try {
    const response = await axios.get(`/api/premises/user/${username}`);
    const fetchedPremises = response.data;
    const activePremises = fetchedPremises.filter(
      (premise) => !premise.isDeleted,
    );
    return activePremises;
  } catch (err) {
    const error = new Error('An error occurred fetching the premises');
    error.code = err.response.status;
    error.message = err.response.data;
    throw error;
  }
};

export async function deletePremises(name) {
  await axios
    .delete(`/api/premises/${name}`)
    .then(function (response) {
      console.log(response.data);
      console.log(response.status);
    })
    .catch(function (error) {
      console.log(error);
    });
  window.location.reload(true);
}
