import React, { useState, useEffect } from 'react';

import AddSheep from '../../components/Forms/AddSheep.js';
import { toast } from 'react-toastify';
import axios from 'axios';
import { FetchPremises } from '../../components/SheepReportComponents.js';
import { ContentContainer } from '../../components/CommonComponents';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath';
const fetchBreeds = async () => {
  try {
    const request = await axios.get(`/api/breed`);
    const fetchedBreeds = request.data;
    return fetchedBreeds;
  } catch (err) {
    throw new Error(`An error occurred fetching breeds`, err);
  }
};

// Order breeds alphabetically, keep 'Other' option at top
const orderBreeds = (breeds) => {
  const sortedBreeds = breeds.sort((a, b) => {
    if (a.name === 'Other') return -1;
    if (b.name === 'Other') return 1;
    return a.name.localeCompare(b.name);
  });
  return sortedBreeds;
};

/*
 * Wrapper to fetch and order breeds alphabetically
 * Note: Duplicate name and value properties of the returned object
 *       array is intentional, follows the mapping used in the original
 *       unordered implementation.
 */
const fetchAndOrderBreeds = async () => {
  const fetchedBreeds = await fetchBreeds();
  return orderBreeds(
    fetchedBreeds.map((breed) => ({
      name: breed.name,
      value: breed.name,
    })),
  );
};

export default function SheepCreateManualView() {
  const [data, setData] = useState({
    genders: [{ name: 'Male' }, { name: 'Female' }],
  });
  const [breeds, setBreeds] = useState([]);
  const [premises, setPremises] = useState([]);

  /**
   * Sends a request to the api endpoint /breed/populate
   */
  const populateBreeds = async (e) => {
    e.preventDefault();
    try {
      toast.info('Populating the breeds collection...');

      // The result of this request isn't useful so we discard it
      await axios.get('/api/breed/populate');

      // Fetch the populated breeds again, and store them in
      // alphabetical order (with the "Other" option at the top)
      setBreeds(await fetchAndOrderBreeds());
    } catch (err) {
      // /breed/populate shouldn't throw any errors except in the case of a database
      // insertion failure
      console.debug(err);
      toast.error('Unable to populate the breeds collection. Try again later');
    }
  };

  const fetchBreedsAndPremises = async () => {
    try {
      setBreeds(await fetchAndOrderBreeds());
    } catch (err) {
      toast.error(err.message);
    }

    try {
      const fetchedPremises = await FetchPremises();

      setPremises(
        fetchedPremises.map((premise) => ({
          name: premise.name ? `${premise.pid} - ${premise.name}` : premise.pid,
          value: premise._id,
        })),
      );
    } catch (err) {
      if (err.response && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  useEffect(() => {
    // Getting all breeds
    fetchBreedsAndPremises();

    axios
      .get('/api/breed/')
      .then((res) => {
        setData({ ...data, breeds: res.data });
      })
      .catch((err) => {
        console.log(`Error during breed retrieval: ${JSON.stringify(err)}`);
      });
  }, []);
  const paths = [
    { url: '/sheep', title: 'Dashboard' },
    { url: '/sheep/add', title: 'Add a Sheep' },
  ];
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddSheep
            errors={{}}
            breeds={breeds}
            premises={premises}
            breedPopulateHandler={populateBreeds}
          />
        </ContentContainer>
      </div>
    </>
  );
}
