import React, { useEffect, useState } from 'react';
import PageCard from './PageCard';
import SimpleCard from './SimpleCard';
import { toast } from 'react-toastify';
import StripedTable from './StripedTable';
import ViewEditDispose from './ViewEditDispose';
import { getMoveLogs } from '../utils/MoveUtils';
import propTypes from 'prop-types';
import {
  DescriptionArea,
  FetchPremises,
  PremisesFormatter,
  FilterSection,
  NAField,
} from './SheepReportComponents';
import axios from 'axios';
import { getExportReports } from '../utils/ExportUtils';
import { getMedicalReports } from '../utils/MedicalReportUtils';

const getSightingReports = () => {
  return axios
    .get('/api/animalsighting')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const setPidAsLocation = (report) => {
  if (!report.destinationLocation || report.destinationLocation === '') {
    return report.destinationPID;
  } else return report.destinationLocation;
};

const convertToAmPm = (time) => {
  const hourMinute = time.split(':');
  let hr = +hourMinute[0];
  let min = +hourMinute[1];
  const ampm = hr >= 12 ? 'pm' : 'am';
  hr = hr % 12;
  hr = hr > 0 ? hr : 12; // 0 hr should be 12
  min = min < 10 ? '0' + min : min;
  return `${hr}:${min}${ampm}`;
};

const SheepReportListView = ({
  title,
  description,
  fetchReportsFunction,
  detailEndpoint,
}) => {
  /* States */
  const [reports, setReports] = useState([]);
  const [premises, setPremises] = useState([]);
  const [moveOutReports, setMoveOutReports] = useState([]);
  const [moveInReports, setMoveInReports] = useState([]);
  const [exportReports, setExportReports] = useState([]);
  const [importReports, setImportReports] = useState([]);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    pid: '',
    gender: '',
  });
  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
    pid: '',
    gender: '',
  });
  const [sightingReport, setSightingReport] = useState([]);
  const [medicalReports, setMedicalReports] = useState([]);
  /* Transforms */

  const fetchReportsAndTransform = async () => {
    try {
      let fetchedReports = await fetchReportsFunction();
      fetchedReports.forEach((r) => {
        r.actions = (
          <ViewEditDispose
            id={r._id}
            endpoint={detailEndpoint}
            hideDispose
            hideEdit
            hideReplace
          />
        );
        if (!r.localMgmtNumber) r.localMgmtNumber = <NAField />;
        if (!r.isoNumber) r.isoNumber = <NAField />;
        if (!r.gender) r.gender = <NAField />;
        if (!r.subgender) r.subgender = <NAField />;

        r.createdAt = new Date(r.createdAt).toISOString().split('T')[0];
      });

      setReports(fetchedReports);
    } catch (err) {
      toast.error(err);
    }
  };

  const fetchSightingReport = async () => {
    let fetchedReport = await getSightingReports();
    fetchedReport.forEach((r) => {
      r.actions = (
        <ViewEditDispose
          id={r._id}
          endpoint={'/reports/sighting/'}
          hideDispose
          hideEdit
          hideReplace
        />
      );
      r.reportDate = new Date(r.reportDate).toISOString().split('T')[0];
    });
    setSightingReport(fetchedReport);
  };

  const fetchMedicalReports = async () => {
    let fetchedReports = await getMedicalReports();
    fetchedReports.forEach((r) => {
      r.actions = (
        <ViewEditDispose
          id={r._id}
          endpoint={'/reports/medical/'}
          hideDispose
          hideEdit
          hideReplace
        />
      );
      r.createdAt = new Date(r.createdAt).toISOString().split('T')[0];
      r.firstTreatmentDate = new Date(r.firstTreatmentDate)
        .toISOString()
        .split('T')[0];
      r.finalTreatmentDate = new Date(r.finalTreatmentDate)
        .toISOString()
        .split('T')[0];
    });
    setMedicalReports(fetchedReports);
  };

  const fetchPremisesAndTransform = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      const transformedPremises = PremisesFormatter(fetchedPremises);

      setPremises(transformedPremises);
    } catch (err) {
      toast.error(err);
    }
  };

  const fetchExportReports = async () => {
    let fetchedReport = await getExportReports();
    fetchedReport.forEach((r) => {
      r.destinationLocation = setPidAsLocation(r);
      r.actions = (
        <ViewEditDispose
          id={r._id}
          endpoint={'/reports/'}
          hideDispose
          hideEdit
          hideReplace
        />
      );
      r.departureTime = new Date(r.departureTime).toISOString().split('T')[0];

      if (r.arrivalTime)
        r.arrivalTime = new Date(r.arrivalTime).toISOString().split('T')[0];

      if (r.isTemp == true) {
        r.isTemp = 'Temporary';
      } else {
        r.isTemp = 'Permanent';
      }
    });
    setExportReports(fetchedReport);
  };

  useEffect(() => {
    fetchReportsAndTransform();
    fetchPremisesAndTransform();
    fetchSightingReport();
    fetchExportReports();
    fetchMedicalReports();

    //import Report
    axios
      .get('/api/import')
      .then((reports) => {
        reports.forEach((r) => {
          r.dateOfArrival = new Date(r.dateOfArrival)
            .toISOString()
            .split('T')[0];
        });
        setImportReports(reports);
      })
      .catch((err) => {
        console.log(err);
      });

    //move logs
    getMoveLogs().then((moveLogs) => {
      const moveOut = moveLogs.filter((log) => log.moveOut);
      const moveIn = moveLogs.filter((log) => !log.moveOut);
      setMoveOutReports(
        moveOut.map((moveLog) => ({
          ...moveLog,
          departurePremise: moveLog.departurePremise
            ? moveLog.departurePremise.name
            : '',
          destinationPremise: moveLog.destinationPremise
            ? moveLog.destinationPremise.name
            : '',
          departureTime: new Date(moveLog.departureTime)
            .toISOString()
            .split('T')[0],
          loadTime: moveLog.loadTime ? convertToAmPm(moveLog.loadTime) : '',
        })),
      );
      setMoveInReports(
        moveIn.map((moveLog) => ({
          ...moveLog,
          departurePremise: moveLog.departurePremise
            ? moveLog.departurePremise.name
            : '',
          destinationPremise: moveLog.destinationPremise
            ? moveLog.destinationPremise.name
            : '',
          departureTime: new Date(moveLog.departureTime)
            .toISOString()
            .split('T')[0],
          receivedTime: new Date(moveLog.receivedTime)
            .toISOString()
            .split('T')[0],
          unloadTime: moveLog.unloadTime
            ? convertToAmPm(moveLog.unloadTime)
            : '',
        })),
      );
    });
  }, []);

  /* Handlers */

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = { ...tempFilter };
    newFilter[filterID] = updatedValue;
    if (filterID === 'startDate') {
      if (tempFilter.endDate === '') {
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate);
        if (startDate > endDate) newFilter.endDate = updatedValue;
      }

      if (updatedValue === '') newFilter.endDate = '';
    }

    if (filterID === 'endDate') {
      if (tempFilter.startDate === '') {
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if (endDate < startDate) newFilter.startDate = updatedValue;
      }

      if (updatedValue === '') newFilter.startDate = '';
    }
    setTempFilter(newFilter);
  };

  const handleApply = () => {
    const newFilter = { ...tempFilter };
    setFilter(newFilter);
  };

  const handleClear = (e) => {
    e.preventDefault();
    const newFilter = {
      startDate: '',
      endDate: '',
      pid: '',
      gender: '',
    };

    setTempFilter(newFilter);
    setFilter(newFilter);
  };

  /* Data */
  let filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'startDate');
      },
      onClick: (e) => {
        e.preventDefault();
        updateTempFilter('', 'startDate');
      },
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => {
        updateTempFilter(e.target.value, 'endDate');
      },
      onClick: () => {
        updateTempFilter('', 'endDate');
      },
    },
    {
      id: 'pid',
      name: 'Premise',
      dropdown: true,
      options: premises,
      onChange: (e) => {
        updateTempFilter(e.target.value, 'pid');
      },
    },
    {
      id: 'gender',
      name: 'Gender',
      dropdown: true,
      options: [
        {
          value: 'male',
          name: 'Male',
        },
        {
          value: 'female',
          name: 'Female',
        },
      ],
      onChange: (e) => {
        updateTempFilter(e.target.value, 'gender');
      },
    },
  ];

  const columns = [
    {
      id: 'action',
      name: 'Action taken',
    },
    {
      id: 'pid',
      name: 'Premise ID',
    },
    {
      id: 'isoNumber',
      name: 'Isonumber',
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number',
    },
    {
      id: 'gender',
      name: 'Gender',
    },
    {
      id: 'subgender',
      name: 'Sub-Gender',
    },
    {
      id: 'createdAt',
      name: 'Created At',
    },
    {
      id: 'actions',
      name: 'Actions',
    },
  ];

  const sightingColumns = [
    {
      id: 'isoNumber',
      name: 'ISO Number',
      required: true,
    },
    {
      id: 'localMgmtNumber',
      name: 'Local Management Number',
      required: true,
    },
    {
      id: 'tattooNumber',
      name: 'Tattoo Number',
      required: true,
    },
    {
      id: 'location',
      name: 'Location',
    },
    {
      id: 'reporterName',
      name: 'Reporter of Sighting',
    },
    {
      id: 'reportDate',
      name: 'Date Sighted',
      required: true,
    },
    {
      id: 'actions',
      name: 'Actions',
    },
  ];

  const moveOutColumns = [
    {
      id: 'departurePremise',
      name: 'Departure Premise',
    },
    {
      id: 'destinationPremise',
      name: 'Destination Premise',
    },
    {
      id: 'destinationType',
      name: 'Destination Premise Type',
    },
    {
      id: 'animalTags',
      name: 'Moved Out Animals',
    },
    {
      id: 'vehicleNumber',
      name: 'Vehicle',
    },
    {
      id: 'departureTime',
      name: 'Date of Departure',
    },
    {
      id: 'loadTime',
      name: 'Load Time',
    },
  ];

  const moveInColumns = [
    {
      id: 'departurePremise',
      name: 'Departure Premise',
    },
    {
      id: 'destinationPremise',
      name: 'Destination Premise',
    },
    {
      id: 'animalTags',
      name: 'Moved In Animals',
    },
    {
      id: 'vehicleNumber',
      name: 'Vehicle',
    },
    {
      id: 'departureTime',
      name: 'Date of Departure',
    },
    {
      id: 'receivedTime',
      name: 'Date of Reception',
    },
    {
      id: 'unloadTime',
      name: 'Unload Time',
    },
  ];

  const exportColumns = [
    {
      id: 'isTemp',
      name: 'Action Taken',
    },
    {
      id: 'departurePID',
      name: 'Departure Site PID',
    },
    {
      id: 'destinationLocation',
      name: 'Export Location or PID',
    },
    {
      id: 'animalTags',
      name: 'Moved Out Animals (Tag Info)',
    },
    {
      id: 'departureTime',
      name: 'Departure Date',
    },
    {
      id: 'arrivalTime',
      name: 'Arrival Date (If Applicable)',
    },
    {
      id: 'vehicleNumber',
      name: 'Vehicle',
    },
    {
      id: 'actions',
      name: 'Actions',
    },
  ];

  const importColumns = [
    {
      id: 'exportingCountry',
      name: 'Exporting Country',
    },
    {
      id: 'departurePID',
      name: 'Departure Site',
    },
    {
      id: 'abattoirPID',
      name: 'Abattoir Premise',
    },
    {
      id: 'firstDestinationPID',
      name: 'First Destination Premise',
    },
    {
      id: 'dateOfArrival',
      name: 'Date of Arrival',
    },
    {
      id: 'animalIdentifications',
      name: 'Imported Animals (Tag Info)',
    },
    {
      id: 'vehicleIdentification',
      name: 'Vehicle',
    },
  ];

  const medicalColumns = [
    {
      id: 'premiseID',
      name: 'Sheep Premise ID',
    },
    {
      id: 'treatmentReason',
      name: 'Reason for Treatment',
    },
    {
      id: 'numberOfAnimals',
      name: 'Number of Sheep Treated',
    },
    {
      id: 'firstTreatmentDate',
      name: 'First Treatment Date',
    },
    {
      id: 'finalTreatmentDate',
      name: 'Final Treatment Date',
    },
    {
      id: 'createdAt',
      name: 'Date Created',
    },
    {
      id: 'actions',
      name: 'Actions',
    },
  ];

  const filteredReports = reports.filter((r) => {
    let filterResult = true;
    const filterKeys = Object.keys(filter);
    filterKeys.forEach((key) => {
      if (filter[key] === '') return;
      if (key.includes('Date')) return;
      if (r[key] !== filter[key]) filterResult = false;
    });
    const reportDate = new Date(r['createdAt']);
    const startDate = new Date(filter.startDate);
    const endDate = new Date(filter.endDate);
    if (reportDate < startDate) return false;
    if (reportDate > endDate) return false;
    return filterResult;
  });

  return (
    <>
      <PageCard title={title} line back maxContent={true}>
        <DescriptionArea>{description}</DescriptionArea>
        <SimpleCard
          title="Filters"
          description="Use these filters to narrow down reports to a specific date, premise or gender"
        >
          <FilterSection
            data={filterFields}
            onApply={handleApply}
            onClear={handleClear}
            values={tempFilter}
          />
        </SimpleCard>
        <SimpleCard title="Reports">
          <StripedTable
            columns={columns}
            rows={filteredReports}
            ShowFilters
            ShowCount
            max={10}
            paginate
          />
        </SimpleCard>
        <SimpleCard title="Sighting Report">
          <StripedTable
            columns={sightingColumns}
            rows={sightingReport}
            ShowFilters
            ShowCount
            max={10}
            paginate
          />
        </SimpleCard>
        <SimpleCard title="Move-In Report">
          <StripedTable
            columns={moveInColumns}
            rows={moveInReports}
            ShowFilters
            ShowCount
            max={10}
            paginate
          />
        </SimpleCard>
        <SimpleCard title="Move-Out Report">
          <StripedTable
            columns={moveOutColumns}
            rows={moveOutReports}
            ShowFilters
            ShowCount
            max={10}
            paginate
          />
        </SimpleCard>
        <SimpleCard title="Export Report">
          <StripedTable
            columns={exportColumns}
            rows={exportReports}
            ShowFilters
            ShowCount
            max={10}
            paginate
          />
        </SimpleCard>
        <SimpleCard title="Import Report">
          <StripedTable
            columns={importColumns}
            rows={importReports}
            ShowFilters
            ShowCount
            max={10}
            paginate
          />
        </SimpleCard>
        <SimpleCard title="Medical Reports">
          <StripedTable
            columns={medicalColumns}
            rows={medicalReports}
            ShowFilters
            ShowCount
            max={10}
            paginate
          />
        </SimpleCard>
      </PageCard>
    </>
  );
};

SheepReportListView.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  fetchReportsFunction: propTypes.func,
  detailEndpoint: propTypes.any,
};

export default SheepReportListView;
