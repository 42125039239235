import PropTypes from 'prop-types';
import styled from 'styled-components';
import React from 'react';
import { COLORS } from '../../utils/Constants';

const TimelineWrapper = styled.div`
  display: flex;
  justify-content: space-around; // Distribute steps evenly
  align-items: stretch;
`;

const TimelineStep = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -50%;
    width: 100%;
    height: 2px;
    background-color: ${COLORS.disabled};
    z-index: -1;
    transform: translateY(-50%);
  }

  &:first-child::before {
    display: none;
  }

  &.completed::before {
    background-color: ${COLORS.primary};
  }
`;

const TimelineCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${COLORS.disabled};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  cursor: pointer;
  color: white;
  &.completed {
    background-color: ${COLORS.primary};
  }
`;

const TimelineLabel = styled.div`
  text-align: center;
`;

const Timeline = ({ steps, currentStep, onStepClick }) => {
  return (
    <TimelineWrapper>
      {steps.map((step, index) => (
        <TimelineStep
          key={index}
          className={index <= currentStep ? 'completed' : ''}
        >
          <TimelineCircle
            className={index <= currentStep ? 'completed' : ''}
            onClick={() => onStepClick(index)}
          >
            {index + 1}
          </TimelineCircle>
          <TimelineLabel>{step}</TimelineLabel>
        </TimelineStep>
      ))}
    </TimelineWrapper>
  );
};

Timeline.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStep: PropTypes.number.isRequired,
  onStepClick: PropTypes.func.isRequired,
};

export default Timeline;
