import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import {
  FetchPremises,
  FetchVehicles,
  PremisesFormatter,
  FetchAnimals,
} from '../../components/SheepReportComponents';
import { ContentContainer } from '../../components/CommonComponents';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import AddMoveIn from '../../components/Forms/AddMoveIn.js';

const MoveInReportCreateView = () => {
  const [redirect, setRedirect] = useState(false);
  const [formattedPremises, setFormattedPremises] = useState([]);
  const [allPremises, setAllPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [animalIds, setAnimalIds] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetVehicles();
    fetchAndSetAnimalIds();
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      const formattedPremises = PremisesFormatter(fetchedPremises);
      setAllPremises(fetchedPremises);
      setFormattedPremises(formattedPremises);
    } catch (err) {
      if (err.code == 404) {
        setFormattedPremises([]);
        setAllPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetVehicles = async () => {
    try {
      const fetchedVehicles = await FetchVehicles();
      setVehicles(fetchedVehicles);
    } catch (err) {
      if (err.code == 404) {
        setVehicles([]);
      } else {
        throw new Error(
          'We were unable to check which vehicles you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetAnimalIds = async () => {
    try {
      const animalIdsFetched = await FetchAnimals();
      setAnimalIds(animalIdsFetched);
    } catch (err) {
      if (err.code === 404) {
        setAnimalIds([]);
      } else {
        throw new Error('Unable to Find any Animals');
      }
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  const paths = [
    { url: '/reports/movein', title: 'Dashboard' },
    {
      url: '/reports/movein/new',
      title: 'Create a Move In Report',
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddMoveIn
            errors={{}}
            premises={formattedPremises}
            allPremises={allPremises}
            animals={animalIds}
            vehicles={vehicles}
          />
        </ContentContainer>
      </div>
    </>
  );
};

export default MoveInReportCreateView;
