// VehicleRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import VehiclesView from '../views/Vehicle/VehiclesView';
import VehicleCreate from '../views/Vehicle/VehicleCreate';
import VehicleUpdate from '../views/Vehicle/VehicleUpdate';
import VehicleView from '../views/Vehicle/VehicleView';

const VehicleRouter = () => (
  <Switch>
    <Route exact path="/vehicles" component={VehiclesView} />
    <Route exact path="/vehicles/create" component={VehicleCreate} />
    <Route exact path="/vehicles/:vehicleId/update" component={VehicleUpdate} />
    <Route exact path="/vehicles/:vehicleId" component={VehicleView} />
  </Switch>
);

export default VehicleRouter;
