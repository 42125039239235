import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CheckboxLabelContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

const StyledCheckbox = styled.input`
  width: 17.5px;
  height: 17.5px;
`;

const StyledLabel = styled.p``;

export const CheckboxLabel = ({ text, handleClick }) => {
  return (
    <CheckboxLabelContainer>
      <StyledLabel>{text}</StyledLabel>
      <StyledCheckbox type="checkbox" onClick={() => handleClick()} />
    </CheckboxLabelContainer>
  );
};

CheckboxLabel.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
};
