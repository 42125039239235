import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import SearchableDropdown from '../../components/SearchableDropdown';
import styled from 'styled-components';
import { extractDataFromHeaders } from '../../utils/CSVUtils';
import { Redirect } from 'react-router';
import propTypes from 'prop-types';
import { FetchPremises } from '../../components/SheepReportComponents';
import Form from '../../components/Form.js';
import { createDate } from '../../utils/TimeUtils.js';
import Table from '../../components/Table.js';
import { Button } from '../../components/CommonComponents.js';
import { bulkSheepSightingReport } from '../../utils/SightingUtils.js';

const CheckBox = ({ onChange, checked }) => {
  return (
    <input
      style={{ cursor: 'pointer' }}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  );
};

CheckBox.propTypes = {
  onChange: propTypes.func,
  checked: propTypes.bool,
};

const fetchPremises = async () => {
  const fetchedPremises = await FetchPremises();
  return fetchedPremises;
};

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const SheepSight = ({ data, headers }) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [premises, setPremises] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState(null);
  const [formValues, setFormValues] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');

  const columns = [
    '',
    'CSIP Number',
    'Local Management Number',
    'Tattoo Number',
    'US Scrapie ID',
  ];
  const columnIds = [
    'isoNumber',
    'localMgmtNumber',
    'tattooNumber',
    'scrapieId',
  ];

  useEffect(async () => {
    sessionStorage.clear();
    setSheeps(extractDataFromHeaders(data, headers));
    return fetchPremises()
      .then((fetchedPremises) => {
        setPremises(fetchedPremises);
      })
      .catch((err) => {
        toast.error(`An error occurred while fetching data: ${err}`);
      });
  }, [data, headers]);

  const sheepRowsWithCheck = sheeps.map((s) => ({
    check: (
      <CheckBox
        checked={
          selectedSheeps.filter(
            (selectedSheep) => selectedSheep.dummyID === s.dummyID,
          ).length > 0
        }
        onChange={() => {
          const alreadySelected =
            selectedSheeps.filter(
              (selectedSheep) => selectedSheep.dummyID == s.dummyID,
            ).length > 0;
          if (alreadySelected) {
            const newSelectedSheeps = selectedSheeps.filter(
              (sheep) => sheep.dummyID !== s.dummyID,
            );
            setSelectedSheeps(newSelectedSheeps);
          } else {
            const newSelectedSheeps = [
              sheeps.filter((sheep) => sheep.dummyID === s.dummyID)[0],
              ...selectedSheeps,
            ];
            setSelectedSheeps(newSelectedSheeps);
          }
        }}
      />
    ),
  }));

  for (let i = 0; i < sheeps.length; i++) {
    for (const columnId of columnIds) {
      sheepRowsWithCheck[i][columnId] =
        columnId !== ''
          ? sheeps[i][columnId]
            ? sheeps[i][columnId]
            : '-'
          : '';
    }
  }

  const SelectAllSection = () => (
    <CenteredButtonDiv>
      <Button
        style={{ marginRight: '10px' }}
        onClick={(e) => {
          e.preventDefault();
          setSelectedSheeps(sheeps);
        }}
      >
        Select All
      </Button>
      {selectedSheeps.length > 0 ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelectedSheeps([]);
          }}
        >
          Clear All
        </Button>
      ) : (
        <></>
      )}
    </CenteredButtonDiv>
  );

  const handleSubmit = (formValues) => {
    try {
      setSubmitting(true);
      setFormValues(formValues);
      if (!selectedSheeps.length > 0) {
        toast.error('At least one sheep is required.');
        return;
      }
      toast.info(
        'Submitting sighting reports to the server. This may take up to a minute.',
      );
      const logInfo = {
        sheep: selectedSheeps,
        reporterName: formValues.reporter,
        date: formValues.date,
        location: selectedPremise.pid || selectedPremise,
        geoLocation: formValues.location,
      };

      bulkSheepSightingReport(logInfo)
        .then(() => {
          setSubmitting(false);
          toast.success('Success: Report created');
          setRedirect('/reports/sighting/listview');
        })
        .catch((err) => {
          setSubmitting(false);
          toast.dismiss();
          toast.error('Error creating export report: ' + err);
        });
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      toast.error('Error creating sighting report: ' + err);
    }
  };

  const formFields = [
    {
      name: 'Premise',
      id: 'premise',
      type: 'content',
      requiredLabel: true,
      content: (
        <SearchableDropdown
          id="premise-search"
          type="premise"
          optionKey="pid"
          options={premises}
          placeholder="Search by Operation Name..."
          onChange={(premise) => setSelectedPremise(premise)}
          onSelect={(premise) => setSelectedPremise(premise)}
        />
      ),
    },
    {
      id: 'sheepSelect',
      name: 'Select Sheep to include in Report',
      type: 'content',
      content: (
        <Table columns={columns} rows={sheepRowsWithCheck} paginate={true} />
      ),
    },
    {
      type: 'content',
      content: <SelectAllSection />,
    },
    {
      name: 'Reporter of Sighting',
      id: 'reporter',
      required: true,
    },
    {
      name: 'Date Sighted',
      id: 'date',
      type: 'date',
      required: true,
      value: formValues?.date || '',
      validityCheck: (fieldValues) => {
        if (new Date(createDate(fieldValues.date)).getTime() > Date.now()) {
          return 'Sighting Date cannot be in the future';
        }
        return true;
      },
    },
    {
      name: 'Sighting Location',
      id: 'location',
      type: 'location',
    },
  ].filter(Boolean);

  if (redirect !== '') return <Redirect to={redirect} />;
  return (
    <Form
      style={{ width: '900px', maxWidth: '900px' }}
      title="Bulk Sheep Sighting"
      onSubmit={handleSubmit}
      fieldInfo={formFields}
      disabled={selectedSheeps.length <= 0 || selectedPremise === null}
    />
  );
};

SheepSight.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string),
};

export default SheepSight;
