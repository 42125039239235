import { getSelectedRole } from './RoleUtils';

const axios = require('axios');
const { getUsername } = require('./TokenUtils');

export const getMedicalReports = () => {
  return axios
    .get(`/api/animalmedical/?username=${getUsername()}`, {
      params: { selectedRole: getSelectedRole() },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

//get the medical reports for a specific sheep
export const getMedicalReportsForSheep = (id) => {
  return axios
    .get(`/api/animalmedical/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createMedicalReport = (data) => {
  return axios
    .post('/api/animalmedical', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};
