// This function takes a date as an argument and returns the local time in string format
export const localTimeFormatter = (date, includeTime = true) => {
  if (date !== null) {
    const utcDate = new Date(date);
    if (!isFinite(utcDate.getTime())) {
      return 'Invalid date';
    }
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const options = {
      timeZone: userTimeZone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      ...(includeTime && {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      }),
    };
    const formatter = new Intl.DateTimeFormat('en-CA', options);
    const localDateString = formatter.format(utcDate);
    return localDateString;
  }
};

// Convert a datetime variable into strictly the date for display in a view
export const localDateFormatter = (date) => {
  return new Date(date).toISOString().split('T')[0];
};

// Takes date of form yyyy-mm-dd, returns locale date string format set
// to midnight.
export const toLocaleDateString = (dateString) => {
  let [yyyy, mm, dd] = dateString.split('-');
  if (!yyyy || !mm || !dd) {
    [yyyy, mm, dd] = dateString.split('/');
  }
  return `${dd}/${mm}/${yyyy}, 12:00 am`;
};

// Takes locale date strings of formats "dd/mm/yyyy, hh:mm [am/pm]" and
// "dd/mm/yyyy" (time is optional). Returns a Date object in user local time.
export const localeToDate = (localeDateString) => {
  let [datePart, timePart] = localeDateString.split(', ');
  let [dd, mm, yyyy] = datePart.split('/');
  if (!dd || !mm || !yyyy) {
    [yyyy, mm, dd] = datePart.split('-');
  }
  let hours = 0;
  let min = 0;

  if (timePart) {
    const [time, period] = timePart.split(' ');
    const [hh, m] = time.split(':');
    min = m;

    // convert to 24-hr format
    let hours = parseInt(hh, 10);
    if (period.toLowerCase() === 'pm' && hours < 12) {
      hours += 12;
    } else if (period.toLowerCase() === 'am' && hours === 12) {
      hours = 0;
    }
  }

  // months are 0-indexed
  const isoDate = new Date(yyyy, mm - 1, dd, hours, min);
  return isoDate;
};

// Wrapper for yyyy-mm-dd -> locale date string -> Date
export const createDate = (dateString, time) => {
  const localeDateString = toLocaleDateString(dateString, time);
  return localeToDate(localeDateString);
};

export const formatDateField = (date) => {
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (!isFinite(date.getTime())) {
    return 'Invalid date';
  }
  return new Intl.DateTimeFormat('en-CA', {
    timeZone: userTimeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date);
};
