import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import PageCard from '../components/PageCard.js';
import TraceSheepReports from '../components/TraceSheepReports.js';
import { getSheep } from '../utils/SheepUtils.js';

export default function TraceSheepReportsList(props) {
  const sheepID = props.match.params.isoNumber;
  const [loaded, setLoaded] = useState(false);
  const [sheep, setSheep] = useState();
  const history = useHistory();

  useEffect(() => {
    const fetchSheep = async () => {
      try {
        const sheep = await getSheep(sheepID, '');
        setSheep(sheep);
        setLoaded(true);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.toString());
        }
      }
    };

    fetchSheep();
  }, [sheepID]);

  const handleTrace = (sheepID) => {
    history.push(`/trace/${sheepID}`);
  };

  return (
    <PageCard
      title={`Trace Sheep ${
        sheep?.tag?.isoNumber ||
        sheep?.tag?.localMgmtNumber ||
        sheep?.tag?.tattooNumber ||
        sheep?.tag?.usScrapieId ||
        ''
      }`}
      back
      line
    >
      <TraceButton onClick={() => handleTrace(sheepID)}>
        Trace Sheep Contact
      </TraceButton>
      {loaded ? <TraceSheepReports sheepId={sheepID} /> : <>Loading...</>}
    </PageCard>
  );
}

TraceSheepReportsList.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      isoNumber: PropTypes.any,
    }),
  }),
};

const TraceButton = styled.button`
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5em;

  background-color: #66ad57;
  cursor: pointer;
`;
