import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const LineContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const DateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 0;
`;

const BigDay = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

const MonthYear = styled.div`
  font-size: 16px;
`;

const DayOfWeek = styled.div`
  font-size: 12px;
`;

const Line = ({ startDateTime, endDateTime }) => {
  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    const dayOfWeek = date.toLocaleString('default', { weekday: 'long' }); // Get day of the week
    return { day, month, year, dayOfWeek };
  };

  const formattedStart = startDateTime ? formatDate(startDateTime) : null;
  const formattedEnd = endDateTime ? formatDate(endDateTime) : null;

  return (
    <LineContainer>
      {formattedStart && (
        <DateTimeContainer>
          <BigDay>{`${formattedStart.day}, ${formattedStart.month}`}</BigDay>
          <MonthYear>{formattedStart.year}</MonthYear>
          <DayOfWeek>{formattedStart.dayOfWeek}</DayOfWeek>
        </DateTimeContainer>
      )}
      <hr
        style={{
          width: '2px',
          height: '100px', // Adjust based on your margins
          backgroundColor: '#97908E',
          border: 'none',
        }}
      />
      {formattedEnd && (
        <DateTimeContainer>
          <BigDay>{`${formattedEnd.day}`}</BigDay>
          <MonthYear>{`${formattedEnd.month}, ${formattedEnd.year}`}</MonthYear>
          <DayOfWeek>{formattedEnd.dayOfWeek}</DayOfWeek>
        </DateTimeContainer>
      )}
    </LineContainer>
  );
};
Line.propTypes = {
  startDateTime: PropTypes.string,
  endDateTime: PropTypes.string,
};
export default Line;
