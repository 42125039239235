import { Button } from '../components/CommonComponents';
import PageCard from '../components/PageCard';
import { Link } from 'react-router-dom';
import React from 'react';

const TutorialDashboard = () => {
  return (
    <>
      <PageCard title="Tutorials">
        <Link to="events/animaltagging/tutorial">
          <Button>I want to activate a new tag</Button>
        </Link>
      </PageCard>
    </>
  );
};

export default TutorialDashboard;
