import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormContainer,
  Button,
  FormInputWrapper,
  FormInputLabel,
  FormParagraphInput,
} from '../components/CommonComponents.js';
import FormCard from '../components/FormCard.js';
import { Redirect } from 'react-router-dom';

const SubmittingDiv = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const FeedbackView = () => {
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState({ data: '' });
  const [redirect, setRedirect] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      await axios.post('/api/feedback', form);
      toast.success('Suggestions submitted!');
      setRedirect(`/`);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        toast.error(
          `An error has occurred: Information is wrong or already exists. Please refresh and try again.`,
        );
      } else {
        toast.error(`An error has occurred: ${err.message}.`);
      }
    } finally {
      setSubmitting(false);
    }
  };

  if (redirect !== '') {
    return <Redirect to={redirect} />;
  }

  return (
    <FormCard
      title="Provide Feedback"
      back={{ to: '/', label: 'Return to Home' }}
    >
      <FormContainer onSubmit={handleSubmit}>
        <FormInputWrapper>
          <FormInputLabel htmlFor="feedback">Provide Feedback</FormInputLabel>
          <FormParagraphInput
            placeholder="Please give us suggestions for improvements."
            type="text"
            id="feedback"
            name="data"
            value={form.data}
            onChange={(e) =>
              setForm({ ...form, [e.target.name]: e.target.value })
            }
          />
        </FormInputWrapper>
        <Button type="submit" disabled={!form.data}>
          Submit
        </Button>
        {submitting && (
          <SubmittingDiv>
            <FontAwesomeIcon icon="spinner" spin />
            &nbsp;Submitting...
          </SubmittingDiv>
        )}
      </FormContainer>
    </FormCard>
  );
};

export default FeedbackView;
