import {
  getValidationResult,
  isLocalMgmtNumber,
  isTattooNumber,
  isUSScrapieId,
} from 'agroledger-shared-library/TagUtils';
import SearchableDropdown from '../components/SearchableDropdown';
import React from 'react';

// Common fields in forms

export const CSIPNumberField = {
  name: 'CSIP Number',
  id: 'isoNumber',
  type: 'text',
  value: '',
  errorMessage: '',
  isRange: false,
  helper: 'Enter a single ID or a range separated by a hyphen',
  onChange: (value) => {
    return value.replace(/\s/g, ''); //remove whitespace
  },

  validityCheck: (field, fieldValues) => {
    let res = getValidationResult(field.value);

    const localMgmtNumberField = fieldValues.find(
      (field) => field.id === 'localMgmtNumber',
    );
    const tattooNumberField = fieldValues.find(
      (field) => field.id === 'tattooNumber',
    );
    const usScrapieIdField = fieldValues.find(
      (field) => field.id === 'usScrapieId',
    );
    if (res.isRange) {
      localMgmtNumberField.disabled = true;
      localMgmtNumberField.value = '';
      tattooNumberField.disabled = true;
      tattooNumberField.value = '';
      usScrapieIdField.disabled = true;
      usScrapieIdField.value = '';
    } else {
      localMgmtNumberField.disabled = false;
      tattooNumberField.disabled = false;
      usScrapieIdField.disabled = false;
    }
    return res;
  },
};
export const localMgmtNumberField = {
  name: 'Local Management Number',
  type: 'text',
  id: 'localMgmtNumber',
  value: '',
  disabled: false,
  errorMessage:
    'Local Management Number must only contain alphanumeric characters.',
  resetOnChange: true,
  onChange: (value) => {
    return value.toUpperCase();
  },
  validityCheck: (field) => {
    if (field.localMgmtNumber === '' || isLocalMgmtNumber(field.value)) {
      field.validity = true;
      return true;
    } else {
      field.validity = false;
      return false;
    }
  },
};

export const tattooNumberField = {
  name: 'Tattoo Number',
  id: 'tattooNumber',
  disabled: false,
  type: 'text',
  value: '',
  errorMessage: 'Tattoo Number must only contain alphanumeric characters.',
  resetOnChange: true,
  validityCheck: (field) => {
    if (field.tattooNumber === '' || isTattooNumber(field.value)) {
      return true;
    } else {
      return false;
    }
  },
};

export const usScrapieIdField = {
  name: 'US Scrapie ID',
  id: 'usScrapieId',
  type: 'text',
  disabled: false,
  value: '',
  helper:
    'US Scrapie ID must start with a US State abbreviation, followed by 7 digits.',
  errorMessage: 'Invalid US Scrapie ID',
  resetOnChange: true,
  validityCheck: (field) => {
    if (field.value === '' || isUSScrapieId(field.value)) {
      return true;
    } else {
      return false;
    }
  },
};

export const operationSelect = (operations) => ({
  name: 'Operation',
  id: 'operation',
  type: 'select',
  required: true,
  options: operations.map((operation) => ({
    value: operation._id,
    name: operation.businessName,
  })),
  validityCheck: (field) => field !== 'Select Operation',
});

export const searchableDropdownField = (
  name,
  id,
  type,
  optionKey,
  options,
  placeholder,
  onSelect,
  description,
  required,
  multiselect,
) => ({
  name: name,
  id: id,
  type: 'content',
  requiredLabel: required,
  content: (
    <SearchableDropdown
      key={id}
      id={id}
      type={type}
      optionKey={optionKey}
      options={options}
      placeholder={placeholder}
      onChange={onSelect}
      onSelect={onSelect}
      onSelectionChange={onSelect}
      multiselect={multiselect}
    />
  ),
  description: description,
});

export const multiselectSearchableDropdownField = (
  name,
  id,
  type,
  optionKey,
  options,
  placeholder,
  onSelect,
  description,
  required,
  selectedValues,
  onSelectionChange,
  onChange,
) => ({
  name: name,
  type: 'content',
  id: id,
  requiredLabel: required,
  required: required,
  value: selectedValues,
  content: (
    <SearchableDropdown
      id={id}
      type={type}
      optionKey={optionKey}
      options={options}
      placeholder={placeholder}
      onSelect={onSelect}
      onSelectionChange={onSelectionChange}
      selectedValues={selectedValues}
      multiselect
      onChange={onChange}
    />
  ),
  description: description,
});
