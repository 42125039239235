import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';

const Body = styled.div`
  position: relative;
  width: 100%;
  height: 30px;
  background: ${COLORS.secondary};
  display: flex;
  align-items: center;
  color: ${COLORS.text};
`;

const Name = styled.div`
  position: relative;
  left: 10px;

  @media (max-width: 768px) {
    left: 3px;
    font-size: x-small;
  }
`;

const Legal = styled.div`
  text-align: center;
  width: 100%;

  @media (max-width: 768px) {
    font-size: small;
  }
`;

export default function Footer() {
  return (
    <Body>
      <Name>AgroLedger</Name>
      <Legal>
        Copyright © 2020 Canadian Sheep Federation. All Rights Reserved. Build{' '}
        {process.env.REACT_APP_BUILD}{' '}
      </Legal>
    </Body>
  );
}
