import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { PageHeader } from '../../components/PageComponents';
import DashboardCard from '../../components/reusedComponents/DashboardCard.js';
import ItemDeleteModal from '../../components/reusedComponents/ItemDeleteModal.js';
import styled from 'styled-components';
import operation from '../../images/icons/operation.png';
import add from '../../images/icons/add.png';
import DescriptionCard from '../../components/DescriptionCard';
import { getUsername, getRoles } from '../../utils/TokenUtils.js';
import {
  ContentContainer,
  Grid,
  VerticalContainer,
  LinkButton,
  HorizontalContainer,
  FormSelectInput,
  MessageField,
} from '../../components/CommonComponents';
import { getOperationTypeNiceName } from '../../utils/Constants';
import { boolToYN } from '../../utils/StringUtils.js';
import { dynamicSortAlpha } from '../../utils/ListUtils.js';
import { toast } from 'react-toastify';
import SearchBar from '../../components/SearchBar.js';
import { useParams, useHistory } from 'react-router-dom';
import { getSelectedRole } from '../../utils/RoleUtils.js';
import Loader from 'react-loader-spinner';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';

export const Icon = styled.img`
  display: inline-block;
  width: ${(props) => (props.width ? props.width : '50px')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  margin: ${(props) => (props.m ? props.m : '10px')};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
`;

const OperationsView = () => {
  const [operations, setOperations] = useState([]);
  const [redirect, setRedirect] = useState('');
  const [windowSize, setWindowSize] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState('active');
  const [sortField, setSortField] = useState('businessName');
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { hash } = useParams();
  const history = useHistory();

  const fetchOperations = async (sortField, setOperations, setIsAdmin) => {
    try {
      setLoading(true);
      const selectedRole = getSelectedRole();
      const response = await axios.get(
        `/api/operations/user/${getUsername()}`,
        {
          params: {
            selectedRole: selectedRole,
          },
        },
      );
      const isAdminCheck = getRoles().some((role) => role.role === 'admin');
      setIsAdmin(isAdminCheck);
      const fetchedOperations = response.data;

      const transformedOperations = fetchedOperations.map((operation) => ({
        _id: operation._id,
        businessName: operation.businessName,
        operationType: getOperationTypeNiceName(operation.operationType),
        phoneNumber: operation.phoneNumber,
        cvsp: boolToYN(operation.cvsp),
        sfcp: boolToYN(operation.sfcp),
        email: operation.email,
        premises: operation.premises,
        isDeleted: operation.isDeleted,
      }));

      setOperations(transformedOperations.sort(dynamicSortAlpha(sortField)));
    } catch (err) {
      console.error(err);
      setOperations([]);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchOperations(sortField, setOperations, setIsAdmin);
  }, [sortField]);

  useEffect(() => {
    if (hash) {
      verifyToken(hash);
    }
  }, [hash]);

  const verifyToken = async (hash) => {
    try {
      const response = await axios.post('/api/operations/verify', { hash });
      if (response.data.emailVerified) {
        toast.success(response.data.message);
        history.push(`/operations/`);
        history.push(`/operations/${response.data.id}`);
      } else {
        toast.error(`Verification failed: ${response.data.error}`);
      }
    } catch (err) {
      if (err.response) {
        toast.error(
          `Verification failed: ${
            err.response.data.error || err.response.data.details
          }`,
        );
      } else {
        toast.error(`Verification failed. Please try again.`);
      }
    }
  };

  if (window.innerWidth !== windowSize) {
    setWindowSize(window.innerWidth);
  }

  if (redirect !== '') {
    return <Redirect push to={redirect} />;
  }

  const deleteOperation = ({ _id }) => {
    axios
      .delete(`/api/operations/${_id}`)
      .then(function () {
        fetchOperations(sortField, setOperations, setIsAdmin);
        toast.success('Operation Successfully Deleted');
      })
      .catch(function (error) {
        toast.error(
          `An error occurred deleting the Operation: ${error.response.data.message}`,
        );
      });
  };

  const restoreOperation = ({ _id }) => {
    axios
      .put(`/api/operations/restore/${_id}`)
      .then(function () {
        fetchOperations(sortField, setOperations, setIsAdmin);
        setFilter('active');
        toast.success('Operation Restored Successfully');
      })
      .catch(function (error) {
        toast.error(
          `An error occurred restoring the Operation: ${error.response.data.message}`,
        );
      });
  };

  const viewOperation = ({ _id }) => {
    setRedirect(`operations/${_id}`);
  };

  const editOperation = ({ _id }) => {
    setRedirect(`operations/${_id}/update`);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = (event) => {
    setSortField(event.target.value);
    setOperations(operations.sort(dynamicSortAlpha(event.target.value)));
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredOperations = operations.filter((operation) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const matchesSearch =
      operation.businessName?.toLowerCase().includes(lowerCaseQuery) ||
      operation.phoneNumber?.includes(lowerCaseQuery) ||
      operation.operationType?.toLowerCase().includes(lowerCaseQuery) ||
      operation.email?.toLowerCase().includes(lowerCaseQuery);

    if (filter === 'active' && !operation.isDeleted) {
      return matchesSearch;
    }

    if (filter === 'closed' && operation.isDeleted) {
      return matchesSearch;
    }

    if (filter === 'all') {
      return matchesSearch;
    }

    return false;
  });

  const description =
    'All operations are listed in its own card below.' +
    ' You can view and edit operations using one o' +
    'f the “Actions” options, enabled by clicking ' +
    'on the three dots in the top-right corner of ' +
    'the respective operations’ card.';

  return (
    <>
      {loading ? (
        <PageCard
          title="Loading your operations..."
          loader={
            <Loader type="Oval" color={agGreen} height={50} width={150} />
          }
        />
      ) : (
        <VerticalContainer justify_content="flex_start" align_items="stretch">
          <HorizontalContainer
            width="100%"
            justify_content="flex-start"
            align_items="flex-start"
            tablet="flex-direction: row;"
          >
            <VerticalContainer
              mr="30px"
              width="100%"
              justify_content="flex-start"
              align_items="flex-start"
              mobile="flex-direction: column;"
            >
              <PageHeader style={{ margin: '34px 0px 0px' }}>
                Operations
              </PageHeader>
              <DescriptionCard
                page="Operation"
                content={description}
                icon={operation}
              ></DescriptionCard>
              <h2>Add a new Operation</h2>
              <LinkButton $stylePreset="dark" to="/operations/create">
                <span>Add</span>
                <Icon width="25px" m="0px" src={add} />
              </LinkButton>
              <br />
              <ContentContainer
                $stylePreset={'light'}
                mb="30px"
                style={{
                  flexDirection: 'row',
                  gap: '30px 20px',
                  width: 'fit-content',
                }}
              >
                {isAdmin && (
                  <VerticalContainer style={{ alignContent: 'right' }}>
                    <h2>Search for an Operation</h2>
                    <SearchBar
                      placeholder="Search for an Operation"
                      value={searchQuery}
                      onChange={handleSearch}
                      containerStyle={{ marginBottom: '20px' }}
                    />
                  </VerticalContainer>
                )}
                <VerticalContainer style={{ alignContent: 'right' }}>
                  <h2>Sort List</h2>
                  <FormSelectInput
                    name="sort field"
                    value={sortField}
                    onChange={handleSort}
                    style={{ marginBottom: '20px' }}
                  >
                    <option value="businessName">Business Name</option>
                    <option value="operationType">Operation Type</option>
                    <option value="phoneNumber">Contact</option>
                    <option value="email">Email</option>
                    <option value="-cvsp">CVSP</option>
                    <option value="-sfcp">SFCP</option>
                  </FormSelectInput>
                </VerticalContainer>
                <VerticalContainer style={{ alignContent: 'right' }}>
                  <h2>Filters</h2>
                  <FormSelectInput
                    name="filter"
                    value={filter}
                    onChange={handleFilterChange}
                    style={{ marginBottom: '20px' }}
                  >
                    <option value="active">Active Operations</option>
                    <option value="closed">Closed Operations</option>
                    <option value="all">All Operations</option>
                  </FormSelectInput>
                </VerticalContainer>
              </ContentContainer>
            </VerticalContainer>
            <ContentContainer
              $stylePreset="borderless"
              width="620px"
              m="auto"
              align_items="flex-start"
              justify_content="flex-start"
              tablet="flex-direction: column;"
              style={{ boxShadow: '5px 5px 10px #00000029' }}
            >
              <VerticalContainer>
                <h2
                  style={{
                    marginRight: 'auto',
                    marginBottom: '0px',
                    fontSize: 'xx-large',
                    color: '#1F341B',
                  }}
                >
                  Operation Updates
                </h2>
                <p
                  style={{
                    fontSize: '25px',
                    color: '#1F341B',
                    marginBottom: '10px',
                  }}
                >
                  View here for any operation updates
                </p>
                <h3
                  style={{
                    fontSize: '20px',
                    marginRight: 'auto',
                    color: '#1F341B',
                  }}
                >
                  Click here to view updated reports.
                </h3>
                <LinkButton
                  to="/reports/updateReports"
                  $stylePreset="dark-border"
                  style={{
                    marginRight: 'auto',
                    boxShadow: '0px 3px 6px #00000029',
                  }}
                >
                  <span>View</span>
                </LinkButton>
              </VerticalContainer>
            </ContentContainer>
          </HorizontalContainer>
          <br />
          {filteredOperations.length === 0 ? (
            <MessageField>
              It looks like you don&apos;t have any {filter} operations at the
              moment.
            </MessageField>
          ) : (
            <Grid
              templateColumns="repeat(auto-fill, minmax(320px, 1fr))"
              gap="30px 20px"
            >
              {filteredOperations.map((operation) => (
                <DashboardCard
                  data={operation}
                  viewItem={viewOperation}
                  editItem={editOperation}
                  deleteItem={() => {
                    setShowDeleteModal(true);
                    setSelectedItem(operation);
                  }}
                  restoreItem={restoreOperation}
                  key={operation._id}
                  cardTitle="Business Name"
                  additionalFields={[
                    { label: 'Type', key: 'operationType' },
                    { label: 'Phone', key: 'phoneNumber' },
                    { label: 'Email', key: 'email' },
                    { label: 'CVSP', key: 'cvsp' },
                    { label: 'SFCP', key: 'sfcp' },
                  ]}
                />
              ))}
            </Grid>
          )}
          {showDeleteModal && (
            <ItemDeleteModal
              item={selectedItem}
              itemType={'operation'}
              deleteItem={deleteOperation}
              closeModal={setShowDeleteModal}
            />
          )}
        </VerticalContainer>
      )}
    </>
  );
};

export default OperationsView;
