import React from 'react';
import {
  searchableDropdownField,
  multiselectSearchableDropdownField,
} from './commonFields';
import {
  VerticalContainer,
  HorizontalContainer,
} from '../components/CommonComponents';
import { COLORS } from '../utils/Constants';
import { formatDateField } from '../utils/TimeUtils';

const getFormFields = (
  premises,
  allPremises,
  setSelectedDestinationPID,
  setCreateTransportReport,
  animals,
  selectedSheep,
  setSelectedSheep,
  handleSelectionChange,
  vehicles,
  setSelectedVehicle,
) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the transport details for the report`,
    },
    {
      name: 'Departure PID',
      id: 'departurePID',
      type: 'select',
      options: premises,
      required: true,
      validityCheck: (fieldValues) => fieldValues.value !== '',
    },
    {
      ...searchableDropdownField(
        'Destination PID',
        'destinationPID',
        'premise',
        'pid',
        allPremises,
        'Search by PID...',
        setSelectedDestinationPID,
        'Fill out the PID of the destination premise',
        true,
      ),
    },
    {
      name: 'Departure Date',
      id: 'departureDate',
      type: 'date',
      required: true,
      validity: true,
      value: formatDateField(new Date()),
      errorMessage: 'Departure Date cannot be in the future',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Departure Time',
      id: 'departureTime',
      type: 'time',
      required: true,
      validity: true,
      errorMessage: 'Departure Date cannot be in the future',
      value: new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
      }),
      validityCheck: (fieldValues) => {
        const moveoutDateField = stepOneFields.find(
          (field) => field.id === 'departureDate',
        );
        const selectedDateTime = new Date(
          `${moveoutDateField.value} ${fieldValues.value}`,
        );
        return selectedDateTime < Date.now();
      },
    },
  ];

  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Complete this form to move animals out of your premise, if you do not have a premise, create one here:  `,
      LinkData: { label: 'Add a Premise', path: '/premises/create' },
    },
    {
      ...multiselectSearchableDropdownField(
        'Animal IDs',
        'animals',
        'sheep',
        'tag.isoNumber',
        animals,
        'Search by Animal ID',
        setSelectedSheep,
        'Fill out the IDs of the animals that were moved in or select your own. You can also enter a range of IDs separated by a dash (eg: 124000311111115-124000311111125)',
        true,
        selectedSheep,
        handleSelectionChange,
      ),
    },
  ];

  const stepThreeFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the transporation details for the report`,
    },
    {
      name: 'Loading Date',
      id: 'loadDate',
      type: 'date',
      required: true,
      validity: true,
      value: formatDateField(new Date()),
      errorMessage:
        'Loading Date cannot be after Move-out Date or be in the future',
      validityCheck: (fieldValues) => {
        const departureDateField = stepOneFields.find(
          (field) => field.id === 'departureDate',
        );
        if (
          new Date(fieldValues.value).getTime() > Date.now() ||
          new Date(fieldValues.value) > new Date(departureDateField.value)
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Loading Time',
      id: 'loadTime',
      type: 'time',
      required: true,
      validity: true,
      value: new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
      }),
      errorMessage:
        'Loading Time cannot be after Move-out Time or be in the future',
      validityCheck: (fieldValues) => {
        // Verify if date is in the future
        const loadDateField = stepThreeFields.find(
          (field) => field.id === 'loadDate',
        );
        const selectedDateTime = new Date(
          `${loadDateField.value} ${fieldValues.value}`,
        );

        if (selectedDateTime > Date.now()) {
          return false;
        }

        // Verify if date is after move-out date
        const moveoutDateField = stepOneFields.find(
          (field) => field.id === 'departureDate',
        );
        const moveoutTimeField = stepOneFields.find(
          (field) => field.id === 'departureTime',
        );
        const moveoutDateTime = new Date(
          `${moveoutDateField.value} ${moveoutTimeField.value}`,
        );
        return selectedDateTime < moveoutDateTime;
      },
    },
    {
      ...searchableDropdownField(
        'Vehicle Identification',
        'vehicle-search',
        'vehicle',
        'licensePlateNum',
        vehicles,
        'Search by Vehicle ID',
        setSelectedVehicle,
        'Fill out the ID of the transport vehicle',
        true,
      ),
    },
  ];

  const stepFourFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `You can file a transport report alongside the moveout report, or submit without one.`,
    },
    {
      type: 'content',
      content: (
        <>
          <VerticalContainer align_items="flex-start">
            <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
              Create Corresponding Transport Report:
            </p>
            <HorizontalContainer mobile="none">
              <input
                type="radio"
                id="yes"
                name="transportReport"
                value={true}
                style={{ accentColor: COLORS.primary }}
                onChange={() => {
                  setCreateTransportReport(true);
                }}
              />
              <label htmlFor="yes" style={{ marginRight: '30px' }}>
                Yes
              </label>
              <input
                type="radio"
                id="no"
                name="transportReport"
                value={false}
                style={{ accentColor: COLORS.primary }}
                onChange={() => {
                  setCreateTransportReport(false);
                }}
              />
              <label htmlFor="no">No</label>
            </HorizontalContainer>
          </VerticalContainer>
        </>
      ),
    },
  ];

  // Grouping steps into a single array
  const addMoveOutFields = [
    {
      name: 'Move Out Information',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
    {
      name: 'Animal Identification',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'At least one animal ID is required.',
    },
    {
      name: 'Transportation Information',
      fields: stepThreeFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
    {
      name: 'Transport Report',
      fields: stepFourFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
  ];
  return addMoveOutFields;
};

export default getFormFields;
