import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormContainer,
  Button,
  FormTextInput,
  FormInputWrapper,
  FormInputLabel,
  FormParagraphInput,
  FormSelectInput,
} from '../components/CommonComponents.js';
import phone from 'phone';
import FormCard from '../components/FormCard.js';
import { Redirect } from 'react-router-dom';

const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

const validateInput = (form) => {
  var error = null;

  if (!form.description || !form.method)
    error = 'Please complete all fields with a red star';
  else if (!form.number && !form.email)
    error = 'Please enter a phone number or email';
  else if (
    form.method === 'number' &&
    (!form.hasOwnProperty('number') || !phone(form.number).isValid)
  )
    error = 'Please enter a valid phone number';
  else if (
    form.method === 'email' &&
    (!form.hasOwnProperty('email') || !emailRegex.test(form.email))
  )
    error = 'Please enter a valid email';

  return error;
};

const validateForm = (form) => {
  if (form == undefined) {
    return false;
  }

  return true;
};

//Submitting spinners
const SubmittingDiv = styled.div`
  text-align: center;
`;
const SubmittingSpinner = () => {
  return (
    <SubmittingDiv>
      <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
      &nbsp;Submitting...
    </SubmittingDiv>
  );
};

const ContactView = () => {
  const [submitting, setSubmitting] = useState(false);
  const [form, setForm] = useState();
  const [redirect, setRedirect] = useState('');

  const formInputDescription = [
    {
      name: 'Description',
      hint: 'How can we help you?',
      id: 'description',
    },
  ];

  const otherInputs = {
    number: {
      name: 'Phone Number',
      hint: '3072776286',
      id: 'number',
      type: 'tel',
    },
    email: {
      name: 'Email',
      hint: 'agroledger@sheep.com',
      id: 'email',
      type: 'text',
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var err = validateInput(form);

    if (err == null) {
      try {
        await axios.post('/api/contact', form);

        toast.success(
          'Your issue has been submitted! We will get back to you as soon as possible.',
        );
        setRedirect(`/`);
      } catch (err) {
        if (err.message == 'Request failed with status code 400') {
          toast.error(
            `An error has occurred: Information is wrong or already exist, please refresh and try again.`,
          );
        } else {
          toast.error(`An error has occurred: ${err.message}.`);
        }
        setSubmitting(false);
      }
    } else {
      toast.error(err);
    }
  };

  if (redirect !== '') {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <FormCard
        title={
          <>
            <>Contact Us</>
          </>
        }
        back={{ to: '/', label: 'Return to Home' }}
      >
        <FormContainer onSubmit={handleSubmit}>
          {formInputDescription.map((i) => (
            <React.Fragment key={i.name}>
              <FormInputWrapper>
                <FormInputLabel htmlFor="input">
                  {i.name} <span style={{ color: 'red' }}>*</span>
                </FormInputLabel>
                <FormParagraphInput
                  placeholder={i.hint}
                  type={i.type ? i.type : 'text'}
                  id="input"
                  name={i.id}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      // eslint-disable-next-line no-unused-vars
                      const { [i.id]: _, ...updatedState } = form;
                      setForm(updatedState);
                    } else
                      setForm({ ...form, [e.target.name]: e.target.value });
                  }}
                />
              </FormInputWrapper>
            </React.Fragment>
          ))}

          <FormInputWrapper>
            <FormInputLabel htmlFor="input">
              Preferred Contact Method <span style={{ color: 'red' }}>*</span>
            </FormInputLabel>
            <FormSelectInput
              id="input"
              name="method"
              onChange={(e) => {
                if (e.target.value === '') {
                  // eslint-disable-next-line no-unused-vars
                  const { [e.target.name]: _, ...updatedState } = form;
                  setForm(updatedState);
                } else setForm({ ...form, [e.target.name]: e.target.value });
              }}
            >
              <option hidden>Select one...</option>
              <option key="number" value="number">
                Telephone
              </option>
              <option key="email" value="email">
                {' '}
                Email{' '}
              </option>
            </FormSelectInput>
          </FormInputWrapper>

          {form?.method && otherInputs[form.method] && (
            <FormInputWrapper>
              <FormInputLabel htmlFor="input">
                {otherInputs[form.method].name}{' '}
                <span style={{ color: 'orange' }}>*</span>
              </FormInputLabel>
              <FormTextInput
                type={otherInputs[form.method].type}
                placeholder={otherInputs[form.method].hint}
                id="input"
                name={otherInputs[form.method].id}
                onChange={(e) => {
                  if (e.target.value === '') {
                    // eslint-disable-next-line no-unused-vars
                    const {
                      [otherInputs[form.method].id]: _,
                      ...updatedState
                    } = form;
                    setForm(updatedState);
                  } else setForm({ ...form, [e.target.name]: e.target.value });
                }}
              />
            </FormInputWrapper>
          )}

          <Button disabled={!validateForm(form)}>Submit</Button>
          {submitting ? <SubmittingSpinner /> : <></>}
        </FormContainer>
      </FormCard>
    </>
  );
};

//export
export default ContactView;
