//ListUtils.js
import { localeToDate } from './TimeUtils';
/**
 * Sorting function to sort objects alphabetically by string property
 * @param {string} property - string property of some object
 */
function dynamicSortAlpha(property) {
  var sortOrder = 1;
  // if property begins with "-" (eg "-name") sort order is reversed
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substring(1);
  }
  return function (a, b) {
    // strings are sorted alphabetical (numbers before letters) case-insensitive
    var aValue = a[property];
    var bValue = b[property];
    var aLower =
      aValue !== null && aValue !== undefined
        ? aValue.toString().toLowerCase()
        : '';
    var bLower =
      bValue !== null && bValue !== undefined
        ? bValue.toString().toLowerCase()
        : '';
    var result = aLower < bLower ? -1 : aLower > bLower ? 1 : 0;
    // sort nulls to the end
    if (bLower === null || bLower === '' || bLower === 'N/A') {
      result = -1;
    }
    return result * sortOrder;
  };
}

/**
 * Sorting function to sort objects from in ascending or descending order by numeric property
 * @param {string} property - string property of some object
 */
function dynamicSortNumeric(property) {
  var sortOrder = 1;
  // if property begins with "-" (eg "-name") sort order is reversed
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substring(1);
  }
  return function (a, b) {
    // numerical data is sorted least to greatest
    var aNumber = Number(a[property]);
    var bNumber = Number(b[property]);
    var result = aNumber < bNumber ? -1 : aNumber > bNumber ? 1 : 0;
    return result * sortOrder;
  };
}

/**
 * Sorting function to sort objects from in ascending or descending order by Date
 * @param {string} property - string property of some object
 */
function dynamicSortDate(property, isLocale=false) {
  var sortOrder = 1;
  // if property begins with "-" (eg "-name") sort order is reversed
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substring(1);
  }

  if (isLocale){
    return function (a, b) {
      // numerical data is sorted least to greatest
      var aDate = localeToDate(a[property]);
      var bDate = localeToDate(b[property]);
      var result = aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
      return result * sortOrder;
    };
  }
  else{
    return function (a, b) {
      // numerical data is sorted least to greatest
      var aDate = new Date(a[property]);
      var bDate = new Date(b[property]);
      var result = aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
      return result * sortOrder;
    };
  }
}

export { dynamicSortAlpha, dynamicSortNumeric, dynamicSortDate };
