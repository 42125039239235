import { toast } from 'react-toastify';
import { getImportReports } from '../../utils/ImportUtils';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import { localDateFormatter } from '../../utils/TimeUtils';
import React from 'react';

const importColumns = [
  {
    id: 'dateOfArrival',
    name: 'Date of Arrival',
  },
  {
    id: 'exportingCountry',
    name: 'Exporting Country',
  },
  {
    id: 'departureSite',
    name: 'Departure Site',
  },
  {
    id: 'firstDestinationPID',
    name: 'First Destination PID',
  },
  {
    id: 'animalIdentifications',
    name: 'Imported Animals',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const ImportReportListView = () => {
  const fetchAndTransformReports = async () => {
    try {
      let allImportReports = await getImportReports();
      allImportReports.forEach((r) => {
        r.dateOfArrival = r.dateOfArrival
          ? localDateFormatter(r.dateOfArrival)
          : '';
      });
      return allImportReports.reverse();
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <SheepReportListComponent
      title="Import"
      description="An Import Report records that animals originating from 
      another country have arrived at their first Canadian premises. You 
      are required to report importing animals or the carcass of an animal 
      that died during import into Canada. Animals that are imported are 
      exempt from the requirement of information to accompany the animals 
      during transport. You can create your import report here."
      fetchReportsFunction={fetchAndTransformReports}
      detailEndpoint="/reports/import/"
      createEndpoint="/reports/import/create/manual"
      csvEndpoint="/reports/import/create/csv"
      columns={importColumns}
    />
  );
};

export default ImportReportListView;
