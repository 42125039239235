//  Sighting ReportDetailView
import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import { NAField } from '../../components/SheepReportComponents';
import StripedTable from '../../components/StripedTable';
import styled from 'styled-components';
import { localDateFormatter } from '../../utils/TimeUtils';

const columns = [
  {
    id: 'isoNumber',
    name: 'ISO Number',
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
  },
  {
    id: 'usScrapieId',
    name: 'US Scrapie ID',
  },
];

const fetchSightingReportInfo = async (id) => {
  try {
    const request = await axios.get(`/api/animalsighting/${id}`);
    return request.data;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
};

const Info = ({ name, value }) => {
  return (
    <div>
      <span>{name}:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
};

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any,
};

const SightingReportDetailView = () => {
  const [sightingInfo, setSightingInfo] = useState();
  const params = useParams();
  const id = params.id;

  const fetchAndSetSightingInfo = async () => {
    try {
      let fetchedSightingInfo = await fetchSightingReportInfo(id);
      fetchedSightingInfo.reportDate = localDateFormatter(
        fetchedSightingInfo.reportDate,
      );

      const geolocation = fetchedSightingInfo.geolocation;
      if (
        geolocation &&
        geolocation.coordinates &&
        geolocation.coordinates.length === 2
      ) {
        fetchedSightingInfo.geolocation =
          geolocation.coordinates[1] + ', ' + geolocation.coordinates[0];
      } else {
        fetchedSightingInfo.geolocation = '';
      }

      setSightingInfo(fetchedSightingInfo);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchAndSetSightingInfo();
  }, []);

  if (sightingInfo) {
    if (!sightingInfo.isoNumber) sightingInfo.isoNumber = <NAField />;
    if (!sightingInfo.tattooNumber) sightingInfo.tattooNumber = <NAField />;
    if (!sightingInfo.localMgmtNumber)
      sightingInfo.localMgmtNumber = <NAField />;
    if (!sightingInfo.usScrapieId) sightingInfo.usScrapieId = <NAField />;
  }

  return (
    <>
      <PageCard
        title="View Sighting Report"
        verify={`/verify/sighting/${id}`}
        line
        back
      >
        {sightingInfo ? (
          <>
            <SimpleCard title="Report Information" size="md">
              <HalfDiv>
                <Info
                  name="Reporter of Sighting"
                  value={sightingInfo.reporterName}
                />
                <Info name="Date Sighted" value={sightingInfo.reportDate} />
              </HalfDiv>
              <HalfDiv>
                <Info
                  name="Location of Sighting"
                  value={sightingInfo.location}
                />
                {sightingInfo.geolocation ? (
                  <Info
                    name="Geolocation (Latitude, Longitude)"
                    value={sightingInfo.geolocation}
                  />
                ) : (
                  <></>
                )}
              </HalfDiv>
            </SimpleCard>
            <br></br>
            <br></br>

            {/* Sheep Info Table */}
            <SimpleCard title="Sheep Sighted" size="md">
              <StripedTable columns={columns} rows={[sightingInfo]} />
            </SimpleCard>
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`;

export default SightingReportDetailView;
