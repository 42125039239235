import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFormFields, {
  getStepThreeFields,
} from '../../Fields/AddMoveoutFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import { createTransportLog } from '../../utils/TransportUtils';
import { createMoveLog } from '../../utils/MoveUtils.js';

export default function AddMoveOut({
  premises,
  allPremises,
  animals,
  vehicles,
}) {
  const [redirect, setRedirect] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedSheep, setSelectedSheep] = useState([]);
  const [selectedDestinationPID, setSelectedDestinationPID] = useState(null);
  const [selectedDeparturePID, setSelectedDeparturePID] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  /** Add Transport Fields if the user creates a transport report */
  const handleTransportCreation = (value) => {
    setFormFields((prevFormFields) => {
      const updatedFields = [...prevFormFields];

      // Find the step containing transport-related fields
      const transportFieldStep = updatedFields.find(
        (step) => step.name === 'Transport Report',
      );

      // Clear existing fields if value is true
      if (value) {
        const existingFields = transportFieldStep.fields.filter(
          (field) =>
            field.id === 'fwrDatetime' ||
            field.id === 'fwrAddress' ||
            field.id === 'condition',
        );
        if (existingFields.length === 0) {
          transportFieldStep.fields.push(
            {
              name: 'Last Access to Feed, Water and Rest Datetime',
              id: 'fwrDatetime',
              type: 'datetime-local',
              required: true,
              validity: true,
              errorMessage: 'FWR Datetime cannot be in the future',
              validityCheck: (fieldValues) =>
                new Date(fieldValues.value) <= new Date(),
            },
            {
              name: 'Last Access to Feed, Water and Rest Location',
              hint: '3 CherryTree Street',
              id: 'fwrAddress',
              type: 'text',
              required: true,
            },
            {
              name: 'Condition of Animals upon Arrival',
              hint: 'Description of animal condition',
              id: 'condition',
              type: 'textarea',
              maxLength: 100,
            },
          );
        }
      } else {
        // Remove Transport Report Fields
        transportFieldStep.fields = transportFieldStep.fields.filter(
          (field) =>
            field.id !== 'fwrDatetime' &&
            field.id !== 'fwrAddress' &&
            field.id !== 'condition',
        );
        // Set validity flags and update state for other conditions
        setValidFieldsInCurrentStep(true);
        setValidForm(true);
        updatedFields[3].isValid = true;
      }

      return updatedFields; // Return the updated copy of formFields
    });
  };

  /** Update Form Fields */
  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  /** Handle a sheep selection for multi-select  */
  const handleSelectionChange = (selectedSheep) => {
    if (!formFields) {
      return;
    }
    setSelectedSheep(selectedSheep);
    const isValid = selectedSheep.length > 0;
    setValidFieldsInCurrentStep(isValid);
  };

  useEffect(() => {
    // Check validity
    if (currentStep === 0) {
      if (!formFields) {
        return;
      }
      const updatedFields = [...formFields];
      const fieldToUpdate = updatedFields[currentStep].fields.find(
        (field) => field.id === 'destinationPID',
      );
      if (fieldToUpdate.value && selectedDeparturePID) {
        sessionStorage.setItem(
          'pid',
          selectedDeparturePID.pid || selectedDeparturePID,
        );
        // Update validity logic here
        setValidFieldsInCurrentStep(true);

        // Update the isValid flag for the current step in formFields
        updatedFields[currentStep].isValid = true;
        // Update the form fields
        updateFields(updatedFields);
        FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
      }
    }
  }, [selectedDeparturePID]);

  useEffect(() => {
    // Check validity
    if (!formFields) {
      return;
    }
    if (currentStep === 0 && selectedDestinationPID) {
      const updatedFields = [...formFields];
      const fieldToUpdate = updatedFields[currentStep].fields.find(
        (field) => field.id === 'departurePID',
      );
      const searchableField = updatedFields[currentStep].fields.find(
        (field) => field.id === 'destinationPID',
      );
      searchableField.validity = true;
      if (fieldToUpdate.value && selectedDestinationPID) {
        // Update validity logic here
        setValidFieldsInCurrentStep(true);

        // Update the isValid flag for the current step in formFields
        updatedFields[currentStep].isValid = true;
      }
      updateFields(updatedFields);
      FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
    }
  }, [selectedDestinationPID]);

  useEffect(() => {
    if (currentStep === 2 && selectedVehicle) {
      const updatedFields = [...formFields];

      let isValid = FormUtils.checkRequiredFieldsValidity(
        updatedFields[currentStep].fields,
      );
      setValidFieldsInCurrentStep(isValid);
      updatedFields[currentStep].isValid = isValid;
      FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
    }
  });
  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(
          premises,
          allPremises,
          setSelectedDestinationPID,
          handleTransportCreation,
          animals,
          selectedSheep,
          setSelectedSheep,
          handleSelectionChange,
          vehicles,
          setSelectedVehicle,
        );
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [premises, allPremises, animals, vehicles]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );
          // Determine what to show in region field
          if (fieldToUpdate.id === 'country') {
            const regionField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'locations',
            );
            if (value !== '') {
              regionField.disabled = false;
            }
          }
          // If departureDate is changed, check if time is valid
          if (fieldToUpdate.id === 'departureDate') {
            const timeField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'departureTime',
            );
            FormUtils.checkFieldInputValidity(
              timeField,
              updatedFields[currentStep].fields,
            );
          } else if (fieldToUpdate.id === 'departureTime') {
            const dateField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'departureDate',
            );
            FormUtils.checkFieldInputValidity(
              dateField,
              updatedFields[currentStep].fields,
            );
          } else if (fieldToUpdate.id === 'loadDate') {
            const timeField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'loadTime',
            );
            FormUtils.checkFieldInputValidity(
              timeField,
              updatedFields[currentStep].fields,
            );
          } else if (fieldToUpdate.id === 'loadTime') {
            const dateField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'loadDate',
            );
            FormUtils.checkFieldInputValidity(
              dateField,
              updatedFields[currentStep].fields,
            );
          }
          if (fieldToUpdate.id === 'departurePID') {
            // Hack solution to fix departurePID erasing for some reason
            setSelectedDeparturePID(value);
            sessionStorage.setItem('pid', value);
          }
          if (currentStep === 0) {
            if (selectedDestinationPID && isValid) {
              setValidFieldsInCurrentStep(isValid);
              updatedFields[0].isValid = isValid;
            } else {
              setValidFieldsInCurrentStep(false);
              updatedFields[0].isValid = false;
            }
          } else if (currentStep === 2) {
            setValidFieldsInCurrentStep(isValid);
          } else if (currentStep === 3 && isValid) {
            setValidFieldsInCurrentStep(true);
            setValidForm(true);
            updatedFields[3].isValid = true;
            updateFields(updatedFields);
            return;
          } else {
            setValidFieldsInCurrentStep(isValid);
          }

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    let isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );

    if (currentStep === 1 && stepIndex > currentStep) {
      const validity = selectedSheep.length > 0;
      isValid = validity;
      formFields[1].isValid = validity;
    }

    setValidFieldsInCurrentStep(isValid);

    if (currentStep === 0 && !selectedDestinationPID) {
      setValidFieldsInCurrentStep(false);
      formFields[0].isValid = false;
    }

    if (currentStep === 2 && isValid && selectedVehicle) {
      formFields[2].isValid = true;
    }

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStepData.isValid || stepIndex < currentStep) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      if (nextStep === 2) {
        setValidFieldsInCurrentStep(false);
      }
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  const handleSubmit = async () => {
    const animalIds = selectedSheep.map((animal) => animal.tag.isoNumber);
    try {
      const formValues = FormUtils.flattenForm(formFields);
      setSubmitting(true);
      toast.info(
        'Submitting move-in report to the server. This may take up to a minute.',
      );

      const localDepDateTime = new Date(
        `${formValues.departureDate}T${formValues.departureTime}`,
      );
      const utcDepDateTime = new Date(localDepDateTime.toUTCString());
      const utcDepDateTimeString = utcDepDateTime.toISOString();
      // Create and submit move-out report
      const logInfo = {
        moveOut: true,
        departurePID: selectedDeparturePID,
        destinationPID: selectedDestinationPID.pid || selectedDestinationPID,
        animals: animalIds,
        vehicleNumber: selectedVehicle.licensePlateNum || selectedVehicle,
        departureTime: utcDepDateTimeString,
        loadTime: formValues.loadDate + 'T' + formValues.loadTime,
      };

      await createMoveLog(logInfo);

      // If the user is creating a transport report, submit it to the system
      if (formValues.fwrDatetime) {
        try {
          const localFWRDate = new Date(formValues.fwrDatetime);
          const utcFWRDate = new Date(localFWRDate.toUTCString());
          const utcFWRDateString = utcFWRDate.toISOString();

          await createTransportLog({
            ...logInfo,
            departureDate: utcDepDateTimeString,
            fwrDatetime: utcFWRDateString,
            fwrAddress: formValues.fwrAddress,
            condition: formValues.condition,
          });
        } catch (err) {
          setSubmitting(false);
          toast.dismiss();
          toast.error('Error creating transport report: ' + err);
          return;
        }
      }
      setSubmitting(false);
      toast.success('Success: Report created');
      setRedirect('/reports/moveout');
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      toast.error('Error creating move-out report: ' + err);
      return;
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create a Move Out Report"
      onCancelPath="/reports/moveout"
    />
  );
}
