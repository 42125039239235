// TagActivationDetailView.js
import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { useParams } from 'react-router';
import { getTagActivationReportById } from '../../utils/TagUtils';
import { getPremiseByPid } from '../../utils/PremiseUtils';
import { Info } from '../../components/TraceSheepReports';
import { localTimeFormatter } from '../../utils/TimeUtils';

const detailExportColumns = [
  {
    id: 'isoNumber',
    name: 'Tag ISO Number',
    required: true,
  },
];

const TagActivationDetailView = () => {
  const [activationReport, setActivationReport] = useState();
  const [tagsInfo, setTagsInfo] = useState([]);
  const params = useParams();
  const id = params.id;

  const fetchActivationReport = async () => {
    let fetchedReport = await getTagActivationReportById(id);

    // Date Activated
    fetchedReport.date = localTimeFormatter(fetchedReport.date);

    // Premise Name
    const premise = await getPremiseByPid(fetchedReport.premise);
    fetchedReport.premiseName = premise.name ? premise.name : 'N/A';

    // Set Tag Info
    setTagsInfo([
      { _id: fetchedReport.isoNumber, isoNumber: fetchedReport.isoNumber },
    ]);

    setActivationReport(fetchedReport);
  };

  useEffect(() => {
    fetchActivationReport();
  }, []);

  return (
    <>
      <PageCard
        title=" Tag Activation Report Details"
        verify={`/verify/tagActivation/${id}`}
        line
        back
      >
        {activationReport ? (
          <>
            <SimpleCard title="Report Information" size="md">
              <FullDiv>
                <Info name="Activation Date" value={activationReport.date} />
              </FullDiv>
            </SimpleCard>

            <SimpleCard title="Premises Information" size="md">
              <HalfDiv>
                <Info name="PID" value={activationReport.premise} />
              </HalfDiv>
              <HalfDiv>
                <Info name="Name" value={activationReport.premiseName} />
              </HalfDiv>
            </SimpleCard>

            <SimpleCard title="Activated Tag Information" size="md">
              <StripedTable
                columns={detailExportColumns}
                rows={tagsInfo}
                ShowCount
                max={10}
              />
            </SimpleCard>
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const FullDiv = styled.div`
  width: 100%;
  float: left;
  display: inline-block;
  margin-bottom: 20px;
`;

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
  margin-bottom: 20px;
`;

export default TagActivationDetailView;
