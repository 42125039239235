import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import {
  ContentContainer,
  HorizontalContainer,
  Icon,
} from '../components/CommonComponents.js';
import left from '../images/icons/chevron_left.png';
import right from '../images/icons/chevron_right.png';

const StyledTh = styled.th`
  background: ${COLORS.secondary};
  color: black;
  height: 70px;
  width: auto;
  padding-block: auto;
  padding-inline: 30px;
  font-weight: medium;
`;

const StyledBoldTh = styled.th`
  z-index: 10;
  background: ${COLORS.text};
  color: white;
  height: 70px;
  width: 130px;
  padding-block: auto;
  padding-inline: 30px;
  font-weight: medium;
`;

const StyledTd = styled.td`
  left: 190px;
  text-align: center;
  height: 70px;
  width: auto;
  padding-inline: 30px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid #707070;
  &::first-letter {
    text-transform: capitalize;
  }

  background: ${(props) => (props.bold ? COLORS.text : COLORS.light)};
  color: ${(props) => (props.bold ? 'white' : 'black')};
`;

const PaginationContainer = styled(ContentContainer)`
  padding: 0px;
  border: none;
  background: ;
  flex: display;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NumberOfRecordsContainer = styled(ContentContainer)`
  padding: 0px;
  border: none;
  background: white;
  align-items: center;
  width: 100px;
`;

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`;

const columns = [
  { name: 'Breed', id: 'breed' },
  { name: 'Gender', id: 'gender' },
];

/**
 * Renders a table of sheep
 * @param {Array}     sheeps    Array of sheep objects from the database
 * @param {integer}   max       Maximum number of sheep to show on a single page
 * @param {Function}  onChange  Called when selected, deselected, or all are selected. Passed an array of selected sheep objects
 */
const SheepMultiSelector = ({ sheeps = [], max = 10, onChange }) => {
  // Current page
  const [page, setPage] = useState(1);

  // The user-configurable maximum number of sheep to show per page
  const [currentMax, setCurrentMax] = useState(max);

  // Carries the value of the "select all" checkbox
  const [allSelected, setAllSelected] = useState(false);

  // Array of sheep objects selected by the user
  const [selectedSheep, setSelectedSheep] = useState(() => {
    const storedSelectedSheep = sessionStorage.getItem('selectedSheep');
    return storedSelectedSheep ? JSON.parse(storedSelectedSheep) : [];
  });

  const PaginationComponent = () => {
    return (
      <PaginationContainer>
        <HorizontalContainer mobile="none" style={{ alignSelf: 'center' }}>
          <Icon
            width="25px"
            src={left}
            hover="filter: opacity(0.5)"
            disabled={page === 1}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          />
          {page - 1 >= 1 && (
            <h3
              style={{ paddingBlock: '5px', paddingInline: '10px' }}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              {page - 1}
            </h3>
          )}
          <h3
            style={{
              background: COLORS.secondary,
              borderRadius: '10px',
              paddingBlock: '5px',
              paddingInline: '10px',
            }}
          >
            {page}
          </h3>
          {page + 1 <= Math.ceil(sheeps.length / currentMax) && (
            <h3
              style={{ paddingBlock: '5px', paddingInline: '10px' }}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              {page + 1}
            </h3>
          )}
          <Icon
            width="25px"
            src={right}
            hover="filter: opacity(0.5)"
            disabled={page === Math.ceil(sheeps.length / currentMax)}
            onClick={() => {
              if (page < Math.ceil(sheeps.length / currentMax)) {
                setPage(page + 1);
              }
            }}
          />
        </HorizontalContainer>
        <h4 style={{ textAlign: 'center', paddingRight: '10px' }}>
          Records per page
        </h4>
        <NumberOfRecordsContainer>
          <select
            value={currentMax}
            style={{
              borderColor: 'green',
              borderRadius: '10px',
              padding: '5px',
              paddingLeft: '20px',
              paddingRight: '20px',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
            onChange={(e) => {
              setCurrentMax(e.target.value);
            }}
          >
            {[10, 25, 50].map((pageSize) => {
              return (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              );
            })}
          </select>
        </NumberOfRecordsContainer>
      </PaginationContainer>
    );
  };

  useEffect(() => {
    sessionStorage.setItem('selectedSheep', JSON.stringify(selectedSheep));
  }, [selectedSheep]);

  // Reset the page to page 1 if the number of records shown changes
  // This way you don't end up on an empty page by accident
  useEffect(() => {
    setPage(1);
  }, [currentMax]);

  // Called every time a sheep is selected in the table
  const handleSelectSheep = (sheep) => () => {
    // If the sheep's not in the list yet, this must be a selection
    const selected = !selectedSheep.find((s) => s._id === sheep._id);

    // Check to see if the user had "selected all", and set it to false
    // if we're no longer selecting all sheep
    if (!selected && selectedSheep.length == sheeps.length) {
      setAllSelected(false);
    }

    // The user is about to add the last sheep to the premise, so
    // we'll want to set allSelected
    if (selected && selectedSheep.length + 1 == sheeps.length) {
      setAllSelected(true);
    }

    const updatedSelectedSheep = selected
      ? [...selectedSheep, sheep]
      : selectedSheep.filter((s) => s._id !== sheep._id);

    setSelectedSheep(updatedSelectedSheep);
    onChange(updatedSelectedSheep);
  };

  return (
    <TableContainer>
      <table style={{ width: '100%', borderSpacing: 0 }}>
        <thead>
          <tr>
            <StyledBoldTh>{'Sheep ID'}</StyledBoldTh>
            {columns.map((c) => (
              <StyledTh key={c.id}>{c.name}</StyledTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {sheeps.length === 0 ? (
            <tr>
              <td colSpan={columns.length + 1}>
                <p style={{ textAlign: 'center' }}>Nothing to show.</p>
              </td>
            </tr>
          ) : (
            sheeps
              .slice((page - 1) * currentMax, page * currentMax)
              .map((sheep) => (
                <tr key={sheep._id} onClick={handleSelectSheep(sheep)}>
                  <StyledTd
                    bold={selectedSheep.find((s) => s._id === sheep._id)}
                  >
                    {sheep.tag.isoNumber ||
                      sheep.tag.usScrapieId ||
                      sheep.tag.localMgmtNumber ||
                      sheep.tag.tattooNumber}
                  </StyledTd>
                  {columns.map((column) => (
                    <StyledTd
                      bold={selectedSheep.find((s) => s._id === sheep._id)}
                      key={column.id}
                    >
                      {sheep[column.id]
                        ? Array.isArray(sheep[column.id])
                          ? sheep[column.id].join(', ')
                          : sheep[column.id]
                        : '-'}
                    </StyledTd>
                  ))}
                </tr>
              ))
          )}
        </tbody>
      </table>
      <PaginationComponent />
      <HorizontalContainer>
        <input
          type="checkbox"
          checked={allSelected}
          onChange={(e) => {
            let updatedSelectedSheeps = e.target.checked ? sheeps : [];

            setAllSelected(e.target.checked);
            setSelectedSheep(updatedSelectedSheeps);
            onChange(updatedSelectedSheeps);
          }}
        />

        <p>Select all sheep on premise</p>
      </HorizontalContainer>
    </TableContainer>
  );
};

SheepMultiSelector.propTypes = {
  sheeps: propTypes.array,
  max: propTypes.number,
  onChange: propTypes.func,
};

export default SheepMultiSelector;
