import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/Constants';
import { Icon } from '../CommonComponents.js';
import options from '../../images/icons/dot_menu.png';
import eye from '../../images/icons/eye.png';
import edit from '../../images/icons/edit.png';
import restore from '../../images/icons/restore.png';
import deleteIcon from '../../images/icons/delete.png';

const DashboardCard = ({
  data,
  viewItem,
  editItem,
  deleteItem,
  restoreItem,
  cardTitle,
  additionalFields,
}) => {
  if (!data) return null;

  return (
    <CardContainer isDeleted={data.isDeleted}>
      <CardOptions>
        <Icon width="30px" src={options} />
        <CardDropdownContainer>
          <CardDropdown onClick={() => viewItem({ _id: data._id || data.id })}>
            <Icon width="20px" src={eye} />
            <DropdownText>View</DropdownText>
          </CardDropdown>
          {data.isDeleted ? (
            <CardDropdown
              onClick={() => restoreItem({ _id: data._id || data.id })}
            >
              <Icon width="20px" src={restore} />
              <DropdownText>Restore</DropdownText>
            </CardDropdown>
          ) : (
            <>
              <CardDropdown
                onClick={() => editItem({ _id: data._id || data.id })}
              >
                <Icon width="20px" src={edit} />
                <DropdownText>Edit</DropdownText>
              </CardDropdown>
              <CardDropdown
                onClick={() => deleteItem({ _id: data.id || data.id })}
              >
                <Icon
                  width="20px"
                  src={deleteIcon}
                  style={{
                    filter:
                      'invert(14%) sepia(68%) saturate(4982%) hue-rotate(352deg) brightness(80%) contrast(103%)',
                  }}
                />
                <DropdownText>Close</DropdownText>
              </CardDropdown>
            </>
          )}
        </CardDropdownContainer>
      </CardOptions>
      <CardTextContainer>
        <CardTextHeader>{cardTitle}:</CardTextHeader>
        <CardTitle isDeleted={data.isDeleted}>
          {data.businessName || data.pid || data.name || 'N/A'}
        </CardTitle>
      </CardTextContainer>
      {additionalFields.map((field, index) => (
        <CardTextBox key={index} isDeleted={data.isDeleted}>
          {field.label}: <CardTextData>{data[field.key] || 'N/A'}</CardTextData>
        </CardTextBox>
      ))}
    </CardContainer>
  );
};

DashboardCard.propTypes = {
  data: PropTypes.object.isRequired,
  viewItem: PropTypes.func.isRequired,
  editItem: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  restoreItem: PropTypes.func,
  cardTitle: PropTypes.string.isRequired,
  additionalFields: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 30px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
  background: ${(props) => (props.isDeleted ? '#E0E0E0' : COLORS.secondary)};
  box-sizing: border-box;
`;

export const CardTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: ${(props) => (props.isDeleted ? 'black' : COLORS.text)};
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardTextBox = styled.div`
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
  color: ${(props) => (props.isDeleted ? 'black' : COLORS.text)};
  background: ${(props) => (props.isDeleted ? 'transparent' : 'white')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardTextData = styled.span`
  font-weight: bold;
  color: ${COLORS.text};
`;

export const CardTextHeader = styled.div`
  color: ${COLORS.text};
`;

export const CardOptions = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
  width: 64px;
  border: none;
  background: none;
`;

export const CardDropdownContainer = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  background: white;
  top: 40px;
  left: 15px;
  width: 120px;
  border: none;
  border-radius: 15px;
  z-index: 9999;

  ${CardOptions}:hover & {
    display: flex;
  }
`;

export const CardDropdown = styled.button`
  display: flex;
  align-items: center;
  background: white;
  border: ${COLORS.text} 1px solid;
  font-size: 0px;

  &:hover {
    background: lightgrey;
    transition: background 0.1s;
    cursor: pointer;
  }

  &:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: none;
  }

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: none;
    color: red;
  }
`;

export const DropdownText = styled.span`
  font-size: 20px;
`;
export default DashboardCard;
