import { useState } from 'react';
import DecisionCard from '../../components/DecisionCard';
import React from 'react';

const AnimalMoveDecisionView = () => {
  const [currentId, setCurrentId] = useState('INIT');

  const EventDecisions = [
    {
      id: 'INIT',
      backToHelp: true,
      title: 'Move Between Premises - Same Operation',
      description: 'Are the animal(s) being moved within the same operation?',
      choices: [
        {
          name: 'Yes',
          update: () => {
            setCurrentId('SAME');
          },
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setCurrentId('DIFFERENT');
          },
        },
      ],
    },
    {
      id: 'SAME',
      backToHelp: true,
      prev: 'INIT',
      onBack: () => setCurrentId('INIT'),
      title: 'Move Between Premises - Same Operation',
      description:
        'You are not required to report the movement of animals within a farming ' +
        'operation, even if moving to different premises within the same operation',
      choices: [
        {
          name: 'Back to home page',
          link: '/',
        },
      ],
    },
    {
      id: 'DIFFERENT',
      backToHelp: true,
      prev: 'INIT',
      onBack: () => setCurrentId('INIT'),
      title: 'Move Between Premises - Different Operation',
      description:
        'You are required to ensure that animals being moved between operations ' +
        'are accompanied by a movement document. However, you are not required ' +
        'to report that you are transporting animals. If a movement document has ' +
        'not been created by the departure site, you can create one here.',
      choices: [
        {
          name: 'Create Move-out Report',
          link: '/reports/moveout/new',
        },
      ],
    },
  ];

  const getDecision = (id) => {
    const decisions = EventDecisions.filter((d) => d.id == id);
    if (decisions.length == 0) {
      setCurrentId('INIT');
      alert('Invalid decision id');
      return EventDecisions[0];
    }

    return decisions[0];
  };

  return <DecisionCard decision={getDecision(currentId)} />;
};

export default AnimalMoveDecisionView;
