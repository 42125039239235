import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';

import styled, { keyframes } from 'styled-components';
import {
  OldBackLink,
  VerticalContainer,
} from '../../components/CommonComponents.js';
import { COLORS } from '../../utils/Constants.js';
import TagAllocationData from '../../components/TagAllocationData.js';

import { queueData } from './TagAllocationData.js'; // <--- Temporary, just for testing
import TagAllocationButtons from '../../components/TagAllocationButtons.js';

const fetchTagAllocationQueueData = async () => {
  return queueData;
};

export default function TagAllocationQueueView() {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const [redirect, setRedirect] = useState();

  const [reason, setReason] = useState(''); // this is unused but will be sent to the back-end in the future

  const history = useHistory();

  useEffect(() => {
    fetchAndSetReportInfo();
  }, []);

  useEffect(() => {
    setCurrentReportInfo(allData, index);
  }, [allData, index]);

  const fetchAndSetReportInfo = async () => {
    let fetchedTagAllocationData = await fetchTagAllocationQueueData();
    setAllData(fetchedTagAllocationData);
  };

  const setCurrentReportInfo = (allData, index) => {
    setData(allData[index]);
  };

  const next = () => {
    setLoading(true);
    setIndex(index + 1);
  };

  const load = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const deny = async () => {
    toast.success('Tag Allocation Request Denied');
    next();
  };

  const approve = async () => {
    toast.success('Tag Allocation Request Approved');
    next();
  };

  if (redirect) return <Redirect to={redirect} />;

  if (loading) load();

  return (
    <TagAllocationContainer>
      <VerticalContainer width="100%" align_items="start" mt="50px">
        <OldBackLink onClick={history.goBack}>&larr; Back</OldBackLink>
        <Header>Processing All Tag Allocation Requests</Header>
        <p>
          This is the tag allocation request details page, from here you can
          approve or deny requests
        </p>
        {allData && !loading && (
          <>
            {data && index < allData.length && (
              <>
                <h3>
                  Processing Tag Allocation Request {index + 1} of{' '}
                  {allData.length}
                </h3>
                <TagAllocationData data={data} />

                <h3>Status: {data.status}</h3>
                {data.status === 'Awaiting Approval' && (
                  <TagAllocationButtons
                    approve={approve}
                    deny={deny}
                    skip={() => {
                      next();
                      toast.success('Tag Allocation Request Skipped');
                    }}
                    setReason={setReason}
                  />
                )}
              </>
            )}

            {allData.length == index && (
              <>
                <h2>No More Requests to Process</h2>
              </>
            )}
          </>
        )}

        {loading && (
          <SpinnerDiv>
            <Spinner />
          </SpinnerDiv>
        )}
      </VerticalContainer>
    </TagAllocationContainer>
  );
}

const TagAllocationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  height: auto;
`;

const Header = styled.h2`
  color: ${COLORS.primary};
  margin-top: 0px;
  margin-bottom: 10px;
`;

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Spinner = styled.div`
  align-self: center;
  justify-self: center;
  border: 4px solid ${COLORS.primary};
  border-top: 4px solid transparent;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: ${spin} 1s linear infinite;
`;

const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
  height: 300px;
`;
