import React, { useEffect } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';

export default function ErrorArea({ formErrors }) {
  useEffect(() => {
    if (Object.keys(formErrors).length) {
      // Focus & bring the container into view
      const errContainer = document.getElementById('ErrorContainer');
      errContainer.scrollIntoView({ behavior: 'smooth' });
    }
  }, [formErrors]);

  return (
    <ErrorContainer
      id="ErrorContainer"
      tabIndex="0"
      valid={Object.keys(formErrors).length}
    >
      {Object.keys(formErrors).map((field, index) => (
        <Error key={index}>{formErrors[field]}</Error>
      ))}
    </ErrorContainer>
  );
}

ErrorArea.propTypes = {
  formErrors: propTypes.node,
};

const ErrorContainer = styled.div`
  display: ${(props) => (props.valid ? 'block' : 'none')};
  padding: 10px;
  color: orange;
  font-weight: bold;
`;

const Error = styled.li``;
