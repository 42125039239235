import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
const { getFormFields } = require('../../Fields/TagAllocation.js');
import * as FormUtils from '../../utils/FormUtils.js';
import { toast } from 'react-toastify';

export default function TagAllocationRequest({ premises }) {
  // next line will be used when back end is hooked up
  // eslint-disable-next-line no-unused-vars
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState();

  const [currentStep, setCurrentStep] = useState(0);
  const [validForm, setValidForm] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] = useState(
    false,
  );
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);

  // next line will be used when back end is hooked up
  // eslint-disable-next-line no-unused-vars
  const [selectedPID, setSelectedPID] = useState('');

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  useEffect(() => {
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(premises, setSelectedPID);
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [premises]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );
          setValidFieldsInCurrentStep(isValid);

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    const isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );
    setValidFieldsInCurrentStep(isValid);

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStepData.isValid || stepIndex < currentStep) {
      let isValid,
        nextStep = FormUtils.nextStep(formFields, stepIndex, currentStep);
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }
  };

  if (!formFields) {
    return null;
  }

  const handleSubmit = () => {
    toast.success('Report Created!');
    setRedirect('/tagallocation/requests');
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Tag Allocation Request"
      showSteps={false}
    />
  );
}
