import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';
import { ContentContainer } from '../components/CommonComponents';
import ViewEditDispose from './ViewEditDispose';
import React from 'react';

/**
 * Card component for a vehicles details page
 * @param data - data from "GET/api/vehicles/${params.id}" endpoint
 */
export const VehicleDetailsCard = ({ data, deleteVehicle }) => {
  const deleteBtn = document.getElementById('deleteModal');

  const confirmDelete = () => {
    deleteVehicle();
    deleteBtn.style.display = 'none';
  };

  return (
    <ContentContainer
      alignItems="stretch"
      position="relative"
      padding="25px"
      tablet="padding: 10px;"
    >
      <DeleteModal id="deleteModal">
        <DeleteModalContent>
          <h1 style={{ textAlign: 'center' }}>
            Are you sure you want to delete?
          </h1>
          <h3 style={{ textAlign: 'center' }}>
            This vehicle will be deleted forever.
          </h3>
          <DeleteModalButtonContainer>
            <DeleteModalButton
              onClick={() => {
                deleteBtn.style.display = 'none';
              }}
            >
              Cancel
            </DeleteModalButton>
            <DeleteModalButton
              onClick={() => {
                confirmDelete();
              }}
              color="white"
              background={COLORS.text}
            >
              Confirm
            </DeleteModalButton>
          </DeleteModalButtonContainer>
        </DeleteModalContent>
      </DeleteModal>
      <ViewEditDispose
        id={data && data.id}
        editLink={`/vehicles/${data && data.id}/update`}
        disposeLink={() => {
          deleteBtn.style.display = 'flex';
        }}
      />
      <CardTextBox background={COLORS.secondary}>
        Vehicle Name: <CardTextData>{data?.name || 'N/A'}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Vehicle ID:{' '}
        <CardTextData>{data?.licensePlateNum || 'N/A'}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Province/State:{' '}
        <CardTextData>{data?.location || 'N/A'}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Operation: <CardTextData>{data?.operation || 'N/A'}</CardTextData>
      </CardTextBox>
    </ContentContainer>
  );
};

VehicleDetailsCard.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.any,
    licensePlateNum: PropTypes.string,
    name: PropTypes.string,
    operation: PropTypes.string,
    location: PropTypes.string,
  }),
  deleteVehicle: PropTypes.func,
};

/**
 * Card text display box
 */
const CardTextBox = styled.div`
  padding: 15px 20px 15px 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  height: 19px;
  width: calc(100% - 40px);
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
  color: ${COLORS.text};
  background: ${(props) => (props.background ? props.background : 'white')};
`;

/**
 * Text (string) data taken from backend
 */
const CardTextData = styled.span`
  font-weight: bold;
  color: ${COLORS.text};
`;

/**
 * Custom Grid Container for styling components
 * idea taken from:
 * https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
 */
const ResponsiveGrid = styled.div`
  --grid-layout-gap: ${(props) => (props.gap ? props.gap : '10px')};
  --grid-column-count: ${(props) => (props.count ? props.count : '2')};
  --grid-item--min-width: ${(props) => (props.width ? props.width : '202px')};

  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--grid-column-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  grid-gap: var(--grid-layout-gap);
`;

/**
 * Modal popup to delete premise
 */
const DeleteModal = styled.div`
  display: none;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
`;

/**
 * Contents of model for deleting the premise
 */
const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 35%;
  color: ${COLORS.text};
  background-color: ${COLORS.light};
  margin: auto;
  padding: 20px 20px 40px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
  margin: 0px 20px;
`;

const DeleteModalButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

/**
 * Delete Modal button
 */
const DeleteModalButton = styled.button`
  color: ${(props) => (props.color ? props.color : COLORS.text)};
  font-size: 20px;
  font-weight: bold;
  background: ${(props) => (props.background ? props.background : 'white')};
  border: ${COLORS.text} 1px solid;
  border-radius: 30px;
  width: 120px;
  height: 40px;

  &:hover {
    filter: contrast(1.5);
    cursor: pointer;
  }
`;
