import { useState } from 'react';
import DecisionCard from '../../components/DecisionCard';
import React from 'react';
const ExportDecisionView = () => {
  const [currentId, setCurrentId] = useState('INIT');

  const EventDecisions = [
    {
      id: 'INIT',
      backToHelp: true,
      title: 'Exporting Animals',
      description:
        'If you are exporting animals, you can create the export report here.',
      choices: [
        {
          name: 'Create Export Report',
          link: '/reports/export/create/manual',
        },
      ],
    },
  ];

  const getDecision = (id) => {
    const decisions = EventDecisions.filter((d) => d.id == id);
    if (decisions.length == 0) {
      setCurrentId('INIT');
      alert('Invalid decision id');
      return EventDecisions[0];
    }

    return decisions[0];
  };

  return <DecisionCard decision={getDecision(currentId)} />;
};

export default ExportDecisionView;
