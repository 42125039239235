import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from './CommonComponents';
import { formatDateField } from '../utils/TimeUtils';

const StyledLabel = styled.label`
  font-weight: bold;
  margin-bottom: 5px;
  margin-top: 16px;
  display: block;
`;

const routes = [
  'IW - In the water',
  'IF - In the feed',
  'TT - Topical Treatment',
  'OR - Oral',
  'SQ - Subcutaneous',
  'IM - Intramuscular',
  'IV - Intravenous',
  'IMM - Intramammary',
];

const TreatmentAdder = ({ treatments, setTreatments }) => {
  const [localTreatments, setLocalTreatments] = useState(treatments);
  const [form, setForm] = useState({
    medicine: '',
    dosage: '',
    expiryDate: formatDateField(new Date()),
    isPrescription: '',
    route: routes[0],
  });

  const handleInput = (name) => (e) => {
    const value = e.target.value;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  useEffect(() => {
    const storedTreatments = sessionStorage.getItem('treatments');
    if (storedTreatments) {
      setLocalTreatments(JSON.parse(storedTreatments));
    }
  }, []);

  // Store treatments in local storage
  useEffect(() => {
    sessionStorage.setItem('treatments', JSON.stringify(localTreatments));
  }, [localTreatments]);

  const handleAdd = () => {
    const newTreatments = [...localTreatments, form];
    setTreatments(newTreatments);
    setLocalTreatments(newTreatments);
    setForm({
      medicine: '',
      dosage: '',
      expiryDate: new Date().toLocaleDateString(),
      isPrescription: '',
      route: routes[0],
    });
  };

  const isValidForm =
    form.medicine &&
    form.dosage &&
    form.expiryDate &&
    form.isPrescription !== '' &&
    form.route;

  return (
    <div>
      <h2>Treatment Information</h2>

      {localTreatments.length > 0 ? (
        localTreatments.map((treatment, i) => (
          <div key={i}>
            <h3>Treatment {i + 1}</h3>
            <p>
              Product Name: {treatment.medicine}
              <br />
              Suggested Dosage: {treatment.dosage}
              <br />
              Expiry Date: {treatment.expiryDate}
              <br />
              Prescription: {treatment.isPrescription ? 'Yes' : 'No'}
              <br />
              Route: {treatment.route}
              <br />
              <button
                type="button"
                style={{
                  color: 'blue',
                  textDecoration: 'underline',
                  border: 'none',
                  background: 'none',
                  padding: 0,
                  fontSize: '14px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  const newTreatments = [...treatments];
                  newTreatments.splice(i, 1);
                  setTreatments(newTreatments);
                  setLocalTreatments(newTreatments);
                }}
              >
                Remove this treatment
              </button>
            </p>
          </div>
        ))
      ) : (
        <p>Add at least one treatment to continue</p>
      )}

      <StyledLabel htmlFor="medicine">Product Name: *</StyledLabel>
      <input
        name="medicine"
        placeholder="XYZ Medication"
        value={form.medicine}
        onChange={handleInput('medicine')}
      />

      <StyledLabel htmlFor="dosage">Suggested Dosage: *</StyledLabel>
      <input
        name="dosage"
        placeholder="3cc/45kg"
        value={form.dosage}
        onChange={handleInput('dosage')}
      />

      <StyledLabel htmlFor="expiryDate">Medicine Expiry Date: *</StyledLabel>
      <input
        name="expiryDate"
        type="date"
        value={form.expiryDate}
        onChange={handleInput('expiryDate')}
      />

      <StyledLabel htmlFor="isPrescription">Medicine Type: *</StyledLabel>
      <select
        name="isPrescription"
        value={form.isPrescription}
        onChange={handleInput('isPrescription')}
      >
        <option value="" disabled hidden>
          Select
        </option>
        <option value={false}>Non-Prescription</option>
        <option value={true}>Prescription</option>
      </select>

      <StyledLabel htmlFor="route">Route: *</StyledLabel>
      <select name="route" value={form.route} onChange={handleInput('route')}>
        <option value="" disabled hidden>
          Select
        </option>
        {routes.map((route, i) => (
          <option key={i} value={route}>
            {route}
          </option>
        ))}
      </select>

      <Button onClick={handleAdd} type="button" disabled={!isValidForm}>
        Add Treatment
      </Button>
    </div>
  );
};

export default TreatmentAdder;
