// EditButtons.js
import React from 'react';
import CustomButton from '../../components/reusedComponents/CustomButton';
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const EditButtons = ({ editMode, onCancel, onSave, onEdit }) => {
  return (
    <ButtonContainer>
      {!editMode ? (
        <CustomButton $stylePreset="next-active" onClick={onEdit}>
          Edit
        </CustomButton>
      ) : (
        <>
          <CustomButton $stylePreset="next-active" onClick={onSave}>
            Save
          </CustomButton>
          <CustomButton $stylePreset="back-active" onClick={onCancel}>
            Cancel
          </CustomButton>
        </>
      )}
    </ButtonContainer>
  );
};

export default EditButtons;
