// ViewEditDelete.js
import React from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import { Icon } from './CommonComponents';
import dotMenu from '../images/icons/dot_menu.png';
import { COLORS } from '../utils/Constants';
import eye from '../images/icons/eye.png';
import edit from '../images/icons/edit.png';
import trash from '../images/icons/delete.png';

/**
 * Pop up menu with a view, edit, and dispose button
 * @param {String} id ID of the vehicle
 * @param {String or Function} [viewLink] String: Link to single view, Function: Runs on view button click, None: Hides view button
 * @param {String or Function} [editLink] String: Link to edit view, Function: Runs on edit button click, None: Hides edit button
 * @param {String or Function} [disposeLink] String: Link to dispose view, Function: Runs on dispose button click, None: Hides dispose button
 * @param {String} [disposeName] Dispose button name
 * @param {String} [position] If ViewEditDispose is within an element with 'position: relative', set position to absolute (default: relative)
 * @returns
 */
const ViewEditDispose = ({
  id,
  viewLink,
  editLink,
  disposeLink,
  disposeName,
  position = 'relative',
}) => {
  const history = useHistory();
  if (!disposeName) disposeName = 'Dispose';

  const openMenu = () => {
    let menu = document.getElementById(`menu-${id}`);
    menu.style.display = 'flex';
    window.addEventListener('click', closeMenu);
  };

  const closeMenu = (e) => {
    let menu = document.getElementById(`menu-${id}`);
    let button = document.getElementById(`button-${id}`);
    if (e.target !== button) {
      if (menu !== null) menu.style.display = 'none';
      window.removeEventListener('click', closeMenu);
    }
  };

  return (
    <div
      style={{
        width: '100%',
        textAlign: 'right',
        position: position === 'absolute' ? null : position,
      }}
    >
      <Icon
        src={dotMenu}
        width="30px"
        m="0px"
        hover="filter: opacity(0.5)"
        onClick={openMenu}
        id={`button-${id}`}
      />
      <ViewEditDisposeDiv id={`menu-${id}`}>
        {viewLink && (
          <FlatButton
            {...(typeof viewLink === 'function'
              ? {
                  onClick: (e) => {
                    e.preventDefault();
                    viewLink();
                  },
                }
              : {
                  onClick: () => {
                    history.push(viewLink);
                  },
                })}
          >
            <Icon width="20px" m="0" mr="auto" src={eye} />
            <span style={{ margin: 'auto' }}>View</span>
          </FlatButton>
        )}
        {editLink && (
          <FlatButton
            {...(typeof editLink === 'function'
              ? {
                  onClick: (e) => {
                    e.preventDefault();
                    editLink();
                  },
                }
              : {
                  onClick: () => {
                    history.push(editLink);
                  },
                })}
          >
            <Icon width="20px" m="0" mr="auto" src={edit} />
            <span style={{ margin: 'auto' }}>Edit</span>
          </FlatButton>
        )}
        {disposeLink && (
          <FlatButton
            {...(typeof disposeLink === 'function'
              ? {
                  onClick: (e) => {
                    e.preventDefault();
                    disposeLink();
                  },
                }
              : {
                  onClick: () => {
                    history.push(disposeLink);
                  },
                })}
            style={{ color: '#BF0D0D' }}
          >
            <Icon
              width="20px"
              m="0"
              mr="auto"
              src={trash}
              style={{
                filter:
                  'invert(14%) sepia(68%) saturate(4982%) hue-rotate(352deg) brightness(80%) contrast(103%)',
              }}
            />
            <span style={{ margin: 'auto' }}>Dispose</span>
          </FlatButton>
        )}
      </ViewEditDisposeDiv>
    </div>
  );
};

ViewEditDispose.propTypes = {
  id: propTypes.any,
  viewLink: propTypes.any,
  editLink: propTypes.any,
  disposeLink: propTypes.any,
  disposeName: propTypes.any,
  position: propTypes.any,
};

const ViewEditDisposeDiv = styled.div`
  z-index: 100;
  display: none;
  flex-direction: column;
  position: absolute;
  background: white;
  width: 120px;
  border: ${COLORS.text} 1px solid;
  border-radius: 15px;
  right: -15px;
`;

const FlatButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: calc(100% - 20px);
  color: ${COLORS.text};
  background: none;
  border: none;
  padding: 10px;
  border-bottom: ${COLORS.text} 1px solid;
  text-align: center;
  text-decoration: none;

  &:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom: none;
  }

  &:hover {
    backdrop-filter: brightness(0.85);
    color: black;
  }
`;

export default ViewEditDispose;
