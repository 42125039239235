import {
  multiselectSearchableDropdownField,
  searchableDropdownField,
} from './commonFields';
import { formatDateField } from '../utils/TimeUtils';
const getFormFields = (
  premises,
  vehicles,
  setSelectedVehicle,
  animals,
  setSelectedSheep,
  selectedSheep,
  handleSelectionChange,
) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Complete this form to file an export report:`,
    },
    {
      name: 'Exporting PID',
      id: 'departurePID',
      type: 'select',
      options: premises,
      required: true,
    },
    {
      name: 'Departure Date',
      id: 'departureDate',
      type: 'date',
      required: true,
      validity: true,
      value: formatDateField(new Date()),
      errorMessage: 'Departure Date cannot be in the future',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Departure Time',
      id: 'departureTime',
      type: 'time',
      required: true,
      validity: true,
      errorMessage: 'Departure Date cannot be in the future',
      value: new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
      }),
      validityCheck: (fieldValues) => {
        const moveoutDateField = stepOneFields.find(
          (field) => field.id === 'departureDate',
        );
        const selectedDateTime = new Date(
          `${moveoutDateField.value} ${fieldValues.value}`,
        );
        return selectedDateTime < Date.now();
      },
    },
    {
      ...searchableDropdownField(
        'Transport Vehicle ID',
        'vehicle',
        'vehicle',
        'licensePlateNum',
        vehicles,
        'Search by Vehicle ID...',
        setSelectedVehicle,
        'Fill out the identifier of the vehicle',
        true,
      ),
    },
    {
      name: 'Departure Location (Latitude, Longitude)',
      hint: 'Latitude, Longitude',
      id: 'location',
      type: 'location',
    },
  ];

  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the identification for the animals being exported. You can also enter a range of IDs separated by a dash (eg: 124000311111115-124000311111125)`,
    },
    {
      ...multiselectSearchableDropdownField(
        'Animal IDs',
        'animals',
        'sheep',
        'tag.isoNumber',
        animals,
        'Search by Animal ID',
        setSelectedSheep,
        'Fill out the IDs of the animals that were moved in or select your own',
        true,
        selectedSheep,
        handleSelectionChange,
      ),
    },
  ];

  const stepThreeFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the Destination details for this report`,
    },
    {
      name: 'Destination (Country, State, County, Town or District)',
      id: 'destinationSite',
      required: true,
      type: 'text',
    },
    {
      name: 'Destination Region',
      id: 'destinationRegion',
      required: true,
      type: 'text',
    },
  ];

  // Grouping steps into a single array
  const addExportFields = [
    {
      name: 'Departure Information',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
    {
      name: 'Animal Information',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'At least one animal ID is required.',
    },
    {
      name: 'Destination Information',
      fields: stepThreeFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
  ];
  return addExportFields;
};

export default getFormFields;
