import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';

const FileUpload = ({ fileName, onFileChange }) => (
  <FileContainer>
    <FileInputLabel htmlFor="fileInput">
      {fileName ? fileName : 'Upload CSV or Excel File'}
    </FileInputLabel>
    <FileInput
      id="fileInput"
      type="file"
      accept=".csv,.xls,.xlsx"
      onChange={onFileChange}
    />
  </FileContainer>
);

const FileContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: ${COLORS.text};
`;

const FileInputLabel = styled.label`
  background-color: ${COLORS.primary};
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
`;

const FileInput = styled.input`
  display: none;
`;

export default FileUpload;
