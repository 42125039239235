import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import { useParams } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { Info } from '../../components/TraceSheepReports';
import { localTimeFormatter, localDateFormatter } from '../../utils/TimeUtils';

const getRetireReports = (id) => {
  return axios
    .get(`/api/tagretirement/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const detailExportColumns = [
  {
    id: 'isoNumber',
    name: 'Tag ISO Number',
    required: true,
  },
];

const TagRetireDetailView = () => {
  const [retireReport, setRetireReport] = useState();
  const [tagsInfo, setTagsInfo] = useState([]);
  const params = useParams();
  const id = params.id;

  const fetchRetireReports = async () => {
    let fetchedReport = await getRetireReports(id);

    // Tags
    const tagsArray = [];
    fetchedReport.animals.forEach((id) => {
      tagsArray.push({ _id: id, isoNumber: id });
    });
    setTagsInfo(tagsArray);

    // Check for departure PID
    if (!fetchedReport.departurePID) fetchedReport.departurePID = 'N/A';

    // Set dates to be in simple "YYYY-MM-DD" format
    fetchedReport.createdAt = localTimeFormatter(fetchedReport.createdAt);

    fetchedReport.dateOfDisposal = localDateFormatter(
      fetchedReport.dateOfDisposal,
    );
    fetchedReport.dateOfConveyance = fetchedReport.dateOfConveyance
      ? localDateFormatter(fetchedReport.dateOfConveyance)
      : 'N/A';

    // Check for vehicle
    if (!fetchedReport.vehicle) fetchedReport.vehicle = 'N/A';

    // Revived
    if (fetchedReport.revived) {
      fetchedReport.revived = 'Yes';
    } else {
      fetchedReport.revived = 'No';
    }

    setRetireReport(fetchedReport);
  };

  useEffect(() => {
    fetchRetireReports();
  }, []);

  return (
    <>
      <PageCard
        title=" Tag Retirement Report Details"
        verify={`/verify/retire/${id}`}
        line
        back
      >
        {retireReport ? (
          <>
            <SimpleCard title="Report Information" size="md">
              <FullDiv>
                <Info name="Retirement Date" value={retireReport.createdAt} />
              </FullDiv>
            </SimpleCard>

            <SimpleCard title="Premises Information" size="md">
              <HalfDiv>
                <Info
                  name="Disposal Site PID"
                  value={retireReport.disposalPID}
                />
              </HalfDiv>
              <HalfDiv>
                <Info
                  name="Departure Site PID"
                  value={retireReport.departurePID}
                />
              </HalfDiv>
            </SimpleCard>

            <SimpleCard title="Date Information" size="md">
              <HalfDiv>
                <Info
                  name="Date of Disposal"
                  value={localTimeFormatter(retireReport.dateOfDisposal)}
                />
              </HalfDiv>
              <HalfDiv>
                <Info
                  name="Date of Conveyance"
                  value={localTimeFormatter(retireReport.dateOfConveyance)}
                />
              </HalfDiv>
            </SimpleCard>

            <SimpleCard title="Tag Information" size="md">
              <StripedTable
                columns={detailExportColumns}
                rows={tagsInfo}
                ShowCount
                max={10}
              />
            </SimpleCard>

            <SimpleCard title="Vehicle Information" size="md">
              <Info name="Vehicle" value={retireReport.vehicle} />
            </SimpleCard>

            <SimpleCard title="Revived" size="md">
              <Info name="Revived" value={retireReport.revived} />
            </SimpleCard>
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const FullDiv = styled.div`
  width: 100%;
  float: left;
  display: inline-block;
  margin-bottom: 20px;
`;

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
  margin-bottom: 20px;
`;

export default TagRetireDetailView;
