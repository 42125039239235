import Phone from 'phone';
const getFormFields = (operationTypes) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provice the name of your operation, as well as your operation type:`,
    },
    {
      name: 'Business name',
      id: 'businessName',
      required: true,
      type: 'text',
      hint: 'Operation name...',
    },
    {
      name: 'Operation Type',
      id: 'operationType',
      required: true,
      type: 'select',
      options: operationTypes,
    },
  ];

  // Step 2 fields
  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Provide the contact information for your operation:`,
    },
    {
      name: 'E-Mail',
      id: 'email',
      required: true,
      type: 'text',
      errorMessage: 'Please enter a valid email address',
      validityCheck: (fields) => {
        const basicEmailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        if (!basicEmailRegex.test(fields.value)) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Phone Number',
      id: 'phoneNumber',
      required: true,
      type: 'text',
      errorMessage: 'Please enter a valid phone number',
      validityCheck: (data) => {
        if (data.value && !Phone(data.value).isValid) {
          return false;
        }
        return true;
      },
    },
  ];

  // Step 3 fields
  const stepThreeFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: 'Is this Operation participating in any of these programs? ',
      LinkData: { label: ' Learn More', path: '/operations/LearnMore' },
    },
    {
      name: 'CVSP',
      id: 'cvsp',
      type: 'select',
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
    },
    {
      name: 'SFCP',
      id: 'sfcp',
      type: 'select',
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
    },
  ];

  // Grouping steps into a single array
  const addOperationFields = [
    {
      name: 'Operation Information',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Operation name and type are required',
    },
    {
      name: 'Contact Information',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'You must provide contact information',
    },
    {
      name: 'Participating Programs',
      fields: stepThreeFields,
      isValid: true,
      errorMessage: 'Required fields are missing',
    },
  ];
  return addOperationFields;
};

export default getFormFields;
