import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import EditButtons from '../../components/reusedComponents/EditButtons';
import TextInputField from '../../components/reusedComponents/TextInputField';
import { FormInputWrapper } from '../../components/CommonComponents';
import FormCard from '../../components/FormCard';
import { updateUser, uploadProfileImage } from '../../utils/UserUtils.js'; // Adjust the path as per your project structure

import { toast } from 'react-toastify';
import phone from 'phone';
import UserPicture from '../../components/reusedComponents/UserPicture'; // Adjust the path as per your project structure
import FileInput from '../../components/reusedComponents/FileInput'; // Adjust the path as per your project structure

// Styled components
const ProfilePictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const EditProfile = ({ userData, fetchUserData }) => {
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewURL, setPreviewURL] = useState('');
  useEffect(() => {
    if (userData) {
      setFirstName(userData.firstName || '');
      setLastName(userData.lastName || '');
      setPhoneNumber(userData.phone || '');
      setUser(userData);
      setPreviewURL(userData.profileImage || '');
    }
  }, [userData]);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setFirstName(user.firstName || '');
    setLastName(user.lastName || '');
    setPhoneNumber(user.phone || '');

    setPreviewURL(user.profileImage || '');

    setEditMode(false);
  };

  const checkPhoneValidity = (newNumber) => {
    let result = { validity: true, errorMessage: '' };
    // Make phone number optional for the user
    if (newNumber === '') {
      return result;
    } else if (newNumber === undefined || !phone(newNumber).isValid) {
      result.validity = false;
      result.errorMessage = 'Your new phone number must be in a valid format';
    }
    return result;
  };

  const handleSave = async () => {
    if (!isDataChanged()) {
      toast.info('No changes applied.');
      setEditMode(false);
      return;
    }

    if (!validateInputs()) {
      return;
    }

    try {
      const updatedUser = await prepareUpdatedUserData();
      await updateUserProfile(updatedUser);
    } catch (error) {
      // Handle  errors
      if (error.response && error.response.status === 400) {
        toast.error('Bad request: Invalid data provided');
      } else if (error.message === 'Network Error') {
        toast.error('Network error: Please check your internet connection');
      } else {
        toast.error(`Error saving profile`);
      }
    }
  };

  const isDataChanged = () => {
    return (
      firstName !== user.firstName ||
      lastName !== user.lastName ||
      phoneNumber !== user.phone ||
      selectedFile !== null
    );
  };

  const validateInputs = () => {
    if (!firstName.trim() || !lastName.trim()) {
      toast.error('First Name and Last Name are required.');
      return false;
    }

    let phoneCheck = checkPhoneValidity(phoneNumber);
    if (!phoneCheck.validity) {
      toast.error(phoneCheck.errorMessage);
      return false;
    }

    return true;
  };

  const prepareUpdatedUserData = async () => {
    let updatedUser = {
      firstName: firstName,
      lastName: lastName,
      phone: phoneNumber || null,
    };

    if (selectedFile) {
      const imageUrl = await uploadProfileImage(selectedFile, user.id);
      updatedUser.profileImage = imageUrl;
    }

    return updatedUser;
  };

  const updateUserProfile = async (updatedUser) => {
    await updateUser(user.username, updatedUser);
    toast.success('Profile has been updated');
    setEditMode(false);
    setUser({ ...user, ...updatedUser });
    fetchUserData();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const url = URL.createObjectURL(file);
      setPreviewURL(url);
    }
  };

  return (
    <FormCard title="Profile">
      {user ? (
        <>
          <ProfilePictureContainer>
            <UserPicture
              src={previewURL}
              firstName={firstName}
              lastName={lastName}
              isSelected={true}
            />
            {editMode && (
              <FileInput labelText="Change Image" onChange={handleFileChange} />
            )}
          </ProfilePictureContainer>
          <FormInputWrapper>
            <TextInputField
              field={{ id: 'firstName', name: 'First Name', type: 'text' }}
              value={firstName}
              onChange={handleFirstNameChange}
              disabled={!editMode}
            />
            <TextInputField
              field={{ id: 'lastName', name: 'Last Name', type: 'text' }}
              value={lastName}
              onChange={handleLastNameChange}
              disabled={!editMode}
            />
            <TextInputField
              field={{ id: 'phone', name: 'Phone Number', type: 'tel' }}
              value={phoneNumber}
              onChange={handlePhoneChange}
              disabled={!editMode}
            />
            <EditButtons
              editMode={editMode}
              onCancel={handleCancel}
              onSave={handleSave}
              onEdit={handleEdit}
            />
          </FormInputWrapper>
        </>
      ) : (
        <>Loading...</>
      )}
    </FormCard>
  );
};

export default EditProfile;
