import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  FetchPremises,
  FetchVehicles,
  FetchAnimals,
} from '../../components/SheepReportComponents.js';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents';
import AddTransport from '../../components/Forms/AddTransport.js';
import { getUsername } from '../../utils/TokenUtils.js';
import axios from 'axios';

const TransportReportCreateView = () => {
  const [redirect, setRedirect] = useState();
  const [animals, setAnimals] = useState([]);
  const [premises, setPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [operations, setOperations] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetVehicles();
    fetchAndSetAnimals();
    fetchOperations();
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      setPremises(fetchedPremises);
    } catch (err) {
      if (err.code == 404) {
        setPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetVehicles = async () => {
    try {
      const fetchedVehicles = await FetchVehicles();
      setVehicles(fetchedVehicles);
    } catch (err) {
      if (err.code == 404) {
        setVehicles([]);
      } else {
        throw new Error(
          'We were unable to check which vehicles you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetAnimals = async () => {
    try {
      const animals = await FetchAnimals();
      setAnimals(animals);
    } catch (err) {
      if (err.code === 404) {
        setAnimals([]);
      } else {
        throw new Error('Unable to Find any Animals');
      }
    }
  };

  const fetchOperations = async () => {
    const result = await axios.get(`/api/operations/user/${getUsername()}`);
    const reducedOperations = result.data.map((o) => ({
      name: o.businessName,
      value: o._id,
    }));
    setOperations(reducedOperations);
  };

  const paths = [
    { url: '/reports/transport/list', title: 'Dashboard' },
    {
      url: '/reports/transport/create',
      title: 'Create a Transport Report',
    },
  ];

  if (redirect) return <Redirect to={redirect} />;
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddTransport
            errors={{}}
            allPremises={premises}
            animals={animals}
            vehicles={vehicles}
            operations={operations}
          />
        </ContentContainer>
      </div>
    </>
  );
};

export default TransportReportCreateView;
