import { getMoveLogs, getRelatedMoveLogs } from '../../utils/MoveUtils';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import React from 'react';
import { localTimeFormatter } from '../../utils/TimeUtils';
import { dynamicSortDate } from '../../utils/ListUtils';

const moveInColumns = [
  {
    id: 'receivedTime',
    name: 'Date of Reception',
  },
  {
    id: 'departurePremise',
    name: 'Departure Premise',
  },
  {
    id: 'destinationPremise',
    name: 'Destination Premise',
  },
  {
    id: 'animalTags',
    name: 'Moved In Animals',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const MoveInReportListView = () => {
  const fetchAndTransformMoveLogs = async () => {
    const relatedLogs = await getRelatedMoveLogs().then((reports) => {
      return reports
        .filter((log) => !log.moveOut)
        .map((report) => ({ ...report, related: true }));
    });

    return getMoveLogs().then((moveLogs) => {
      let moveIn = moveLogs
        .filter((log) => !log.moveOut)
        .concat(relatedLogs)
        .sort(dynamicSortDate('-createdAt'));

      moveIn = moveIn.map((moveLog) => ({
        ...moveLog,
        departurePremise: moveLog.departurePID ? moveLog.departurePID : '',
        destinationPremise: moveLog.destinationPID
          ? moveLog.destinationPID
          : '',
        receivedTime: moveLog.receivedTime
          ? localTimeFormatter(moveLog.receivedTime)
          : '',
      }));
      return moveIn;
    });
  };

  return (
    <SheepReportListComponent
      title="Move-In"
      description="A Move-in Report records the receipt of animals onto a premises. 
      All operations are required to report the receipt of animals, except community 
      pasture operators. You can report the receipt of animals here."
      fetchReportsFunction={fetchAndTransformMoveLogs}
      detailEndpoint="/reports/movein/"
      createEndpoint="/reports/movein/new"
      columns={moveInColumns}
      csvEndpoint="movein/new/csv"
      frEndpoint="reports/movein/new/fr"
    />
  );
};

export default MoveInReportListView;
