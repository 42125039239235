import PropTypes from 'prop-types';
import React, { useState, useReducer, useEffect } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { Button, HorizontalContainer } from './CommonComponents';
import { COLORS } from '../utils/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

/**
 *  React Form Component
 *  @param {String}             title                       The string present in the form's header
 *  @param {String}             subtitle                    The optional string representing the form's subtitle
 *  @param {Array}              fieldInfo                   Array of 'field' objects which contain the following format:
 *      @param {Object}             [field]                     Object containing properties for an input element
 *      @param {String}             [field.name]                Name of the field that appears in the input's title
 *      @param {String}             [field.id]                  ID of the field, used to get the field's value in returned object in the 'getFieldValues' function
 *      @param {String}             [field.type]                Type of input field, includes 'select', and 'content' which passes a react component through the 'content' parameter
 *      @param {String}             [field.description]         Small text that appears below the field
 *      @param {Any}                [field.value]               Initial value of the field
 *      @param {String}             [field.hint]                Placeholder value
 *      @param {Boolean}            [field.disabled]            Whether or not the field is disabled (default: false)
 *      @param {Boolean}            [field.required]            Boolean stating whether the field is required (default: false)
 *      @param {Boolean}            [field.resetOnChange]       Set `value` to its default when `fieldInfo` is updated
 *      @param {Function}           [field.validityCheck]       Function that is passed 'getFieldValues()' and must either return true (field is valid), or an error message string (field is invalid and form submit button is disabled)
 *      @param {Function}           [field.onChange]            Function passed the fields object when its value is changed
 *      @param {number}           [field.maxLength]             The maximum length for `type='textarea'` inputs
 *      @param {Array}            [field.options]           Array of 'options' objects used if type='select' in the following format:
 *          @param {Object}             [option]                    Object containing name and value data for a select element option
 *          @param {String}             [option.name]               Text displayed inside the option
 *          @param {Any}                [option.value]              Value of the option component
 *      @param {Function}           [field.optionsFilter]       Function that is passed 'getFieldValues()' and must return an array of options to be listed
 *      @param {React Component}    [field.content]             React component displayed if type='content'
 *  @param {Function}           [fieldFilter]               Function that is passed 'getFieldValues()' and must return an array of ids of fields which are hidden from user
 *  @param {Function}           [onCancel]                  Function that is called when the 'cancel' button is clicked
 *  @param {Function}           onSubmit                    Function that is passed 'getFieldValues()' and is called when the 'submit' button is clicked
 *  @param {Object}             [style]                     React component style property object that is applied to the form
 *  @param {ReactComponent}     [trailingContent]           React component that, if given, will be rendered after the submit button
 *  @param {String}             [submitName]                The text in the submit button (default: "Submit")
 *  @param {String}             [buttonTheme]               The theme of the submit button (default: "dark")
 *  @param {Boolean}            [disabled]                  True if the form should be disabled even if the input is valid
 *  @param {Boolean}            [isSubmitting]              Boolean stating whether the form is currently submitting to disable the submit button (default: false)
 *  @returns React form component
 */
const Form = ({
  title,
  subtitle,
  fieldInfo,
  fieldFilter,
  onCancel,
  onSubmit,
  style,
  trailingContent,
  submitName,
  buttonTheme,
  isSubmitting,
  disabled,
}) => {
  const [fields, setFields] = useState([]);
  const [formValidity, setFormValidity] = useState(true);

  /**
   * Removes fields from the passed in array with ids returned from 'fieldFilter()'
   * @param {Object} fields Array of 'field' objects
   * @returns Array of 'field' objects
   */
  const filterFields = (fields) => {
    if (typeof fieldFilter === 'undefined') return fields;
    const removedFields = fieldFilter(
      getFieldValues(fields !== [] ? fields : fieldInfo),
    );
    fields.forEach((field) => {
      if (removedFields.includes(field.id)) {
        field.hidden = true;
        field.value = '';
      } else {
        field.hidden = false;
      }
    });
    return fields;
  };

  /**
   * For 'select' elements, if `optionsFilter` is specified, only the values
   * returned by `optionsFilter` will be rendered
   *
   * @param {Object} fields Array of 'field' objects
   * @returns Array of 'field' objects
   */
  const filterOptions = (fields) =>
    fields.map((field) => {
      // We skip this step if the field isn't a select element or if no
      // filter function is given
      if (!field.optionsFilter) return field;

      field.options = field.optionsFilter(
        getFieldValues(fields !== [] ? fields : fieldInfo),
      );

      // Determine if the the current value of the select element was
      // dropped while filtering
      let valueIncluded = false;

      for (let option of field.options) {
        if (option.value === field.value) {
          valueIncluded = true;
          break;
        }
      }

      // Reset the value if so
      if (!valueIncluded) {
        field.value = '';
      }

      return field;
    });

  /**
   * Checks if field values are valid/required, if not an error message returned from 'validityCheck()' is set and the 'submit' button is disabled
   * @param {Object} fields Array of 'field objects
   * @returns Array of 'field' objects
   */
  const checkFields = (fields) => {
    let isFormValid = true;
    const checkedFields = fields.map((field) => {
      if (typeof field.validityCheck !== 'undefined') {
        let fieldValidity = field.validityCheck(
          getFieldValues(fields !== [] ? fields : fieldInfo),
        );
        if (fieldValidity !== true) {
          field.errorMessage = fieldValidity;
          isFormValid = false;
        } else {
          field.errorMessage = null;
        }
      }
      if (field.required && field.value.trim().length === 0) {
        isFormValid = false;
      }
      return field;
    });
    setFormValidity(isFormValid);
    return checkedFields;
  };

  // Filters field and options, and checks field validity
  const updateFields = () => {
    let tempFields = [...fields];
    tempFields = filterFields(tempFields);
    tempFields = filterOptions(tempFields);
    tempFields = checkFields(tempFields);
    setFields(tempFields);
  };

  /**
   * Gets the values of all non-content fields
   * @param {Object} fields Array of 'field' objects
   * @returns Object with keys equal to the ids of the fields, and value equal to the values of the fields
   */
  const getFieldValues = (fields) => {
    let values = {};
    fields.forEach((field) => {
      if (field.type === 'content' || field.disabled === true) return;
      values[field.id] = field.value;
    });
    return values;
  };

  // Called when submit button is clicked
  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(getFieldValues(fields));
  };

  // Updates the fields when the fieldInfo property is initiated and mutated
  useEffect(() => {
    let fieldTemplate = fieldInfo.map((field) => {
      // The field we're working with in this round, as it exists in the
      // field state variable
      let currentField =
        fields.find((stateField) => stateField.id == field.id) || field;

      return {
        name: field.name,
        id: field.id,
        type: field.type,
        hint: field.hint,
        description: field.description,
        required: field.required || false,
        requiredLabel: field.requiredLabel || false,
        resetOnChange: field.resetOnChange || false,
        validityCheck: field.validityCheck,
        onChange: field.onChange,
        options: field.options || [],
        optionsFilter: field.optionsFilter,
        content: field.content,
        maxLength: field.maxLength,
        disabled: field.disabled || false,
        errorMessage: null,
        value: (field.resetOnChange ? field.value : currentField.value) || '',
      };
    });
    fieldTemplate = filterFields(fieldTemplate);
    fieldTemplate = filterOptions(fieldTemplate);
    fieldTemplate = checkFields(fieldTemplate);
    setFields(fieldTemplate);
  }, [fieldInfo]);

  const handleInput = (field, index) => (e) => {
    // Safely update the state by cloning it before mutation
    let tempFields = [...fields];
    tempFields[index].value = e.target.value;

    // Call the field onChange handler if given
    if (field.onChange) {
      field.onChange(getFieldValues(tempFields));
    }

    setFields(tempFields);
  };

  return (
    <StyledForm onChange={updateFields} style={style}>
      <h1 style={{ alignSelf: 'center' }}>{title}</h1>
      {subtitle && (
        <p
          style={{
            textAlign: 'center',
            fontSize: '1.2em',
            margin: 0,
          }}
        >
          {subtitle}
        </p>
      )}
      {fields.map((field, index) => {
        return (
          !field.hidden && (
            <>
              {field.name && (
                <p style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                  {field.name}:{(field.requiredLabel || field.required) && ' *'}
                </p>
              )}
              {(field.type === 'select' && (
                <select
                  onChange={handleInput(field, index)}
                  disabled={field.disabled || field.options.length === 0}
                >
                  <option
                    value=""
                    disabled
                    selected={field.value === ''}
                    hidden
                  >
                    {field.options.length > 0
                      ? field.hint || 'Select ' + field.name
                      : 'Nothing to show'}
                  </option>
                  {field.options.map((option) => {
                    return (
                      <option
                        key={option.name}
                        value={option.value}
                        selected={field.value === option.value}
                      >
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              )) ||
                (field.type === 'content' && field.content) ||
                (field.type === 'password' && (
                  <FormPasswordField
                    field={field}
                    onChange={handleInput(field, index)}
                  />
                )) ||
                (field.type === 'location' && (
                  <>
                    <input
                      placeholder={field.hint || field.name}
                      type="text"
                      onChange={handleInput(field, index)}
                      value={field.value}
                      disabled={field.disabled}
                    />
                    <LocationButtonWrapper
                      style={{
                        display: 'flex',
                        textAlign: 'center',
                        marginTop: 10,
                      }}
                    >
                      <LocationButton
                        $stylePreset="alt"
                        type="button"
                        onClick={() => {
                          function success(position) {
                            const lat = position.coords.latitude;
                            const long = position.coords.longitude;

                            let tempFields = [...fields];
                            tempFields[index].value = lat + ',' + long;

                            // This will not trigger the
                            // onChange event on the form, which
                            // handles the following logic, so
                            // we must do it here
                            tempFields = filterFields(tempFields);
                            tempFields = checkFields(tempFields);
                            setFields(tempFields);
                          }

                          function error(err) {
                            err.code == 1 || err.code == 2
                              ? toast.error(
                                  'Position Unavailable, Ensure location services are enabled',
                                )
                              : toast.error(
                                  'Request Timed out, try again later.',
                                );
                          }

                          navigator.geolocation.getCurrentPosition(
                            success,
                            error,
                          );
                        }}
                      >
                        Get current location
                      </LocationButton>
                      <LocationButton
                        type="button"
                        onClick={() => {
                          let tempFields = [...fields];
                          tempFields[index].value = '';
                          tempFields = filterFields(tempFields);
                          tempFields = checkFields(tempFields);
                          setFields(tempFields);
                        }}
                      >
                        Clear location
                      </LocationButton>
                    </LocationButtonWrapper>
                  </>
                )) ||
                (field.type === 'textarea' && (
                  <>
                    <DescBox
                      id="descBox"
                      maxLength={field.maxLength}
                      value={field.value}
                      disabled={field.disabled}
                      placeholder={field.hint || field.name}
                      onChange={handleInput(field, index)}
                    ></DescBox>
                    <DescBoxLimitLabel>
                      {field.maxLength - field.value.length} characters
                    </DescBoxLimitLabel>
                  </>
                )) ||
                (field.type === 'time' && (
                  <input
                    type={field.type}
                    onChange={handleInput(field, index)}
                    value={
                      field.value
                        ? field.value
                        : (field.value = new Date().toLocaleTimeString(
                            'en-US',
                            {
                              hour12: false,
                              hour: 'numeric',
                              minute: 'numeric',
                            },
                          ))
                    }
                    disabled={field.disabled}
                  />
                )) ||
                (field.type === 'date' && (
                  <input
                    type={field.type}
                    onChange={handleInput(field, index)}
                    value={
                      field.required
                        ? field.value
                          ? field.value
                          : (field.value = new Date().toLocaleDateString())
                        : field.value
                    }
                    disabled={field.disabled}
                    max="9999-12-31"
                  />
                )) ||
                (field.type === 'datetime-local' && (
                  <input
                    type={field.type}
                    onChange={handleInput(field, index)}
                    value={
                      field.required
                        ? field.value
                          ? field.value
                          : (field.value = `${new Date().toLocaleDateString()}T${new Date().toLocaleTimeString(
                              'en-US',
                              {
                                hour12: false,
                                hour: 'numeric',
                                minute: 'numeric',
                              },
                            )}`)
                        : field.value
                    }
                    disabled={field.disabled}
                  />
                )) || (
                  <input
                    type={field.type}
                    onChange={handleInput(field, index)}
                    placeholder={field.hint || field.name}
                    value={field.value}
                    disabled={field.disabled}
                  />
                )}
              <small style={{ marginTop: '3px' }}>{field.description}</small>
              {field.errorMessage && field.value !== '' && (
                <p
                  style={{
                    color: 'red',
                    fontSize: '15px',
                    marginTop: '5px',
                    paddingInline: '10px',
                  }}
                >
                  {field.errorMessage}
                </p>
              )}
            </>
          )
        );
      })}
      <HorizontalContainer mobile="none;">
        {onCancel && (
          <StyledButton
            type="button"
            onClick={onCancel}
            style={{ marginRight: '20px' }}
          >
            Cancel
          </StyledButton>
        )}
        <StyledButton
          $stylePreset={buttonTheme ? buttonTheme : 'dark'}
          onClick={handleSubmit}
          disabled={!formValidity || disabled || isSubmitting}
        >
          {submitName ? submitName : 'Submit'}
        </StyledButton>
      </HorizontalContainer>
      {trailingContent ? trailingContent : <></>}
    </StyledForm>
  );
};

Form.propTypes = {
  buttonTheme: PropTypes.any,
  fieldFilter: PropTypes.func,
  fieldInfo: PropTypes.array,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  style: PropTypes.object,
  submitName: PropTypes.any,
  subtitle: PropTypes.any,
  title: PropTypes.string,
  trailingContent: PropTypes.any,
  isSubmitting: PropTypes.bool,
  disabled: PropTypes.bool,
};

const FormPasswordField = ({ field, onChange }) => {
  const [peek, togglePeek] = useReducer((peeked) => !peeked, false);
  const FormPassPeek = styled.span`
    z-index: 1;
    cursor: pointer;
    position: relative;
    width: 20px;
    left: 100%;
    transform: translateX(-30px) translateY(-26px);
  `;

  return (
    <>
      <input
        type={peek ? 'text' : 'password'}
        onChange={onChange}
        placeholder={field.hint || field.name}
        value={field.value}
        disabled={field.disabled}
      />
      <FormPassPeek onClick={togglePeek}>
        <FontAwesomeIcon icon={peek ? faEyeSlash : faEye} color="gray" />
      </FormPassPeek>
    </>
  );
};

FormPasswordField.propTypes = {
  field: PropTypes.shape({
    disabled: PropTypes.any,
    hint: PropTypes.any,
    name: PropTypes.any,
    value: PropTypes.any,
  }),
  onChange: PropTypes.any,
};

const LocationButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: center;
  margin-bottom: 50px;
`;

const LocationButton = styled.button`
  display: inline-block;
  width: 42%;
  padding: 10px;
  font-size: 15px;
  color: ${COLORS.primary};
  background-color: white;
  border: ${COLORS.primary} solid 2px;
  border-radius: 25px;
  cursor: pointer;

  &:hover {
    color: white;
    background-color: ${COLORS.primary};
  }

  ${(props) => {
    switch (props.$stylePreset) {
      case 'alt':
        return `
color: white;
background-color: ${COLORS.primary};

  &:hover {
    color: ${COLORS.primary};
      background-color: white;
      }
      `;
    }
  }}
`;

const DescBox = styled.textarea`
  height: 90px;
  width: auto;
  resize: none;
  border: 1px solid ${COLORS.primary};
  border-radius: 10px;
  padding: 10px;
  font-family: Inter-Regular;
  font-size: 14px;
`;

const DescBoxLimitLabel = styled.div`
  font-size: 12px;
  text-align: right;
  margin-right: 10px;
  transform: translateY(-25px);
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 520px;
  width: 520px;
  height: min-content;
  padding: 20px 60px;
  background-color: ${COLORS.light};
  color: ${COLORS.text};
  border-radius: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  input:not([type='checkbox']),
  select {
    width: 100%;
    height: 35px;
    border: ${(props) =>
      props.border ? props.border : `1px solid ${COLORS.primary}`};
    border-radius: 15px;
    background: ${(props) => (props.background ? props.background : 'white')};
    font-size: 14px;
    outline: none;
    padding: 8px 15px;

    &:disabled {
      background-color: ${COLORS.secondary};
      color: ${COLORS.text};
      opacity: 0.85;
    }

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }

  @media (max-width: 768px) {
    min-width: 100vw;
    width: 100vw;
  }
`;

const StyledButton = styled(Button)`
  min-width: 120px;
  margin-block: 20px;
`;

export default Form;
