import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import {
  FetchPremises,
  FetchVehicles,
  PremisesFormatter,
  FetchAnimals,
} from '../../components/SheepReportComponents';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents';
import AddRetireTag from '../../components/Forms/AddRetireTag.js';
import { dynamicSortAlpha } from '../../utils/ListUtils.js';

const TagRetireCreateView = () => {
  const [redirect, setRedirect] = useState(false);
  const [premises, setPremises] = useState([]);
  const [animals, setAnimals] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetVehicles();
    fetchAndSetAnimals();
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      setPremises(PremisesFormatter(fetchedPremises));
    } catch (err) {
      if (err.code == 404) {
        setPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetVehicles = async () => {
    try {
      const fetchedVehicles = await FetchVehicles();
      setVehicles(fetchedVehicles);
    } catch (err) {
      if (err.code == 404) {
        setVehicles([]);
      } else {
        throw new Error(
          'We were unable to check which vehicles you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetAnimals = async () => {
    try {
      const animalIdsFetched = await FetchAnimals();
      const filteredAnimals = animalIdsFetched.filter(
        (animal) => animal.tag && animal.tag.isoNumber,
      );
      filteredAnimals.forEach((r) => {
        r.isoNumber = r.tag.isoNumber;
      });
      setAnimals(filteredAnimals.sort(dynamicSortAlpha('isoNumber')));
    } catch (err) {
      if (err.code === 404) {
        setAnimals([]);
      } else {
        throw new Error('Unable to Find any Animals');
      }
    }
  };

  if (redirect) return <Redirect to={redirect} />;

  const paths = [
    { url: '/reports/replace/listView', title: 'Dashboard' },
    { url: '/reports/replace/create', title: 'Create a Tag Retirement Report' },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddRetireTag
            errors={{}}
            animals={animals}
            vehicles={vehicles}
            premises={premises}
          />
        </ContentContainer>
      </div>
    </>
  );
};

export default TagRetireCreateView;
