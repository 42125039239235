// AuthUtils.js
import axios from 'axios';
import jwt_decode from 'jwt-decode';

/**
 * Retrieves and returns the token from localStorage. This function
 * returns null if the tokens haven't already been stored.
 *
 * @function
 * @returns {string|null} The JWT encoded token
 */
export const getToken = () => {
  const token = localStorage.getItem('agroledgerToken');
  if (token === undefined) return null;
  return token;
};

/**
 * Retrieves and returns the refresh token from localStorage. This function
 * returns null if the tokens haven't already been stored.
 *
 * @function
 * @returns {string|null} The JWT encoded refresh token
 */
export const getRefreshToken = () => {
  const refToken = localStorage.getItem('agroledgerRefreshToken');
  if (refToken === undefined) return null;
  return refToken;
};

/** Set a timer to retrieve a refreshed token prior to the current token expiring
 * @function
 */
export const tokenTimer = () => {
  let timer = sessionStorage.getItem('tokenTimer');
  const token = getToken();
  const decodedToken = decodeToken(token);

  if (!decodedToken) {
    window.location.reload();
  } else {
    if (timer) clearTimeout(timer);
    // Set timer to expire 1 minute before token expires
    const time = decodedToken.exp * 1000 - new Date().getTime();
    timer = setTimeout(() => {
      refreshTokens().catch(() => {
        logoutAndClearTokens();
        window.location.reload();
      });
    }, time);
    sessionStorage.setItem('tokenTimer', timer);
  }
};

// Could just use an interceptor?
export const axiosWithAuth = (token) =>
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token || getToken()}`,
    },
  });

/**
 * Pulls the current user's roles from the JWT token.
 *
 * @function
 * returns {Array<Object>} An array of objects representing the token
 */
export const getRoles = () => {
  const token = decodeToken(getToken());
  if (!token) return;
  return token.roles;
};

/**
 * Gets the username from the JWT token
 *
 * @function
 * @returns {string} The username
 */
export const getUsername = () => {
  const token = decodeToken(getToken());
  if (!token) return;
  return token.username;
};

/**
 * Get the entire decoded payload
 *
 * @function
 * @param {string} The JWT encoded token
 * @return {Object} An object containing the encoded payload
 */
export const decodeToken = (token = getToken()) => {
  let decoded = null;
  try {
    decoded = jwt_decode(token);
  } catch (err) {
    console.log(err);
  }

  return decoded;
};

// Logs into the application and gets the tokens
export const loginAndSetToken = (user) => {
  return axios.post('/api/users/login', user).then((res) => {
    const { token, refreshToken } = res.data.tokens;
    // Set the auth token
    localStorage.setItem('agroledgerToken', token);
    // Set the refresh token
    localStorage.setItem('agroledgerRefreshToken', refreshToken);
    tokenTimer();
    return { res, token };
  });
};

/**
 * Deletes both tokens in the localStorage.
 *
 * @function
 */
export const logoutAndClearTokens = () => {
  // Clear the auth token
  localStorage.removeItem('agroledgerToken');
  // Clear the refresh token
  localStorage.removeItem('agroledgerRefreshToken');
};

/**
 * Try to refresh the user's tokens. If this request fails, that implies that
 * both the token and the refresh token have expired. In this case, the tokens
 * will be deleted from the localStorage and the user will be asked to log in
 * again.
 *
 * @function
 */
export const refreshTokens = async () => {
  return axios
    .post('/api/users/refresh', { refreshToken: getRefreshToken() })
    .then(
      (res) => {
        // Refresh token was valid. Replace the tokens
        const { token, refreshToken } = res.data;
        localStorage.setItem('agroledgerToken', token);
        localStorage.setItem('agroledgerRefreshToken', refreshToken);
        return token;
      },
      (err) => {
        // Remove the invalid tokens (since both have expired) from local storage
        logoutAndClearTokens();
        return Promise.reject(err);
      },
    );
};
