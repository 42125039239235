import styled from 'styled-components';

export const PageHeader = styled.span`
  font-size: 40px;
  font-weight: bold;
  color: black;
`;

export const PageCardContainer = styled.div`
  padding: 10px;
  ${(props) => (props.form === 'true' ? '' : 'min-height: 500px;')}
  color: white;
  ${(props) => (props.maxContent ? 'max-width: max-content;' : '')}
  color: black;
  ${(props) => (props.form === 'true' ? 'max-width: 600px;' : '')}
  background: white;
  border-radius: 10px;
  ${(props) => (props.relative ? 'position: relative;' : '')}
`;

export const PageContainer = styled.div`
  margin: 10px auto;
  justify-content: center;
  width: 90%;
  min-height: 500px;

  display: flex;
  flex: 1;

  > * {
    flex-grow: 1;
  }
`;
