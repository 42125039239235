import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { decodeToken } from '../utils/TokenUtils';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { ImageContainer, ContentContainer } from './CommonComponents';
import login_page_image from '../images/login_page_image.png';
import StyledForm from './reusedComponents/StyledForm';
import PasswordField from './reusedComponents/PasswordField';
import TextInputField from './reusedComponents/TextInputField';
import CustomButton from './reusedComponents/CustomButton';
import { COLORS } from '../utils/Constants';
const Login = ({ handleLogin, token }) => {
  const [usernameField, setUsernameField] = useState({
    name: 'Username',
    value: '',
    hint: 'Enter your username',
    disabled: false,
  });

  const [passwordField, setPasswordField] = useState({
    name: 'Password',
    value: '',
    hint: 'Enter your password',
    disabled: false,
  });

  const handleUsernameChange = (e) => {
    setUsernameField({ ...usernameField, value: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordField({ ...passwordField, value: e.target.value });
  };

  if (token) {
    const decodedToken = decodeToken();
    if (decodedToken.reset) {
      return <Redirect to="/reset" />;
    }
    return <Redirect to="/" />;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    const user = {
      username: usernameField.value,
      password: passwordField.value,
    };
    handleLogin(user).then((res) => {
      if (!res) {
        toast.error("Couldn't connect to the server");
      } else if (res.status !== 200) {
        toast.error(res.data.message);
      }
    });
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ display: 'flex' }}>
        <ImageContainer
          src={login_page_image}
          alt="sheep image"
          width={'80%'}
          m={'auto'}
        />
      </div>
      <ContentContainer
        alignItems="center"
        height="100%"
        flexDirection="column"
        $stylePreset="borderless"
        style={{
          backgroundColor: COLORS.secondary,
        }}
      >
        <StyledForm
          title={{ text: 'Login', placement: 'inside' }}
          width="100%"
          height="100%"
          align_items="stretch"
          style={{
            width: '50%',
            height: '100%',
            marginRight: '20%',
            marginLeft: '20%',
            backgroundColor: COLORS.secondary,
          }}
        >
          <TextInputField
            field={usernameField}
            onChange={handleUsernameChange}
            require={true}
          />
          <PasswordField
            field={passwordField}
            onChange={handlePasswordChange}
          />
          <p style={{ textAlign: 'center' }}>
            <Link to="/forgot/password">Forgot Password?</Link>
          </p>
          <CustomButton
            width="10rem"
            $stylePreset="bold"
            onClick={onSubmit}
            disabled={false}
            alignSelf={'center'}
          >
            Login
          </CustomButton>
          <p style={{ textAlign: 'center' }}>
            Don{"'"}t have an account? <Link to="/register">Sign Up</Link>
          </p>
        </StyledForm>
      </ContentContainer>
    </div>
  );
};

Login.propTypes = {
  handleLogin: PropTypes.func.isRequired,
  token: PropTypes.string,
};

export default Login;
