import React from 'react';
import styled from 'styled-components';
import { Button, Text } from './CommonComponents';
import Table from './Table.js';
import { COLORS } from '../utils/Constants';
import {
  FormContainer,
  FormSelectInput,
  FormInputLabel,
} from './CommonComponents';

const FilePreview = ({
  headings,
  data,
  showAllColumns,
  setShowAllColumns,
  fields,
  headers,
  setHeaders,
  onSubmit,
  disableSubmit,
}) => (
  <PreviewContainer>
    <Title>
      Preview:
      {headings.length > 5 && (
        <div>
          <Button onClick={() => setShowAllColumns(!showAllColumns)}>
            Show {showAllColumns ? 'limited' : 'all'} columns
          </Button>
        </div>
      )}
    </Title>
    {data && (
      <>
        <Table
          columns={
            showAllColumns
              ? headings.map((heading) => heading.name)
              : headings.map((heading) => heading.name).slice(0, 5)
          }
          rows={
            showAllColumns
              ? data
              : data.map((row) =>
                  headings.slice(0, 5).map((heading) => row[heading.name]),
                )
          }
          maxShowing={5}
          $stylePreset="boxyScrollable"
          paginate={true}
        />
      </>
    )}
    <Title>Headers:</Title>
    <HeaderForm>
      {fields.map((element) => (
        <SelectWrapper key={element.id}>
          <FormInputLabel>
            {element.name}
            {element.required && (
              <>
                &nbsp;<RequiredSpan>*</RequiredSpan>
              </>
            )}
          </FormInputLabel>
          <FormSelectInput
            value={headers[element.id]}
            onChange={(e) =>
              setHeaders({
                ...headers,
                [element.id]: e.target.value,
              })
            }
          >
            <option hidden>Select</option>
            <option>N/A</option>
            {headings &&
              headings.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
          </FormSelectInput>
        </SelectWrapper>
      ))}
      <SubmitButton type="submit" onClick={onSubmit} disabled={disableSubmit}>
        Select Headers
      </SubmitButton>
    </HeaderForm>
  </PreviewContainer>
);

const PreviewContainer = styled.div`
  margin: 20px 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const HeaderForm = styled(FormContainer)`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    flex-basis: 100%;
    margin-bottom: 10px;
  }
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SubmitButton = styled(Button)`
  margin: auto;
`;

const Title = styled(Text)`
  color: ${COLORS.text};
  font-size: 25px;
  text-align: left;
  margin: 20px 0;
`;

const RequiredSpan = styled.span`
  color: red;
`;

export default FilePreview;
