import React, { useState, useEffect } from 'react';
import AddOperation from '../../components/Forms/AddOperation.js';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents.js';

const axios = require('axios');

const OperationCreate = () => {
  const [operationTypes, setOperationTypes] = useState([]);

  useEffect(() => {
    (async () => {
      // Fetch the operation types
      const result = await axios.get('/api/operationType/');
      setOperationTypes(
        result.data.map((data) => ({
          name: data.niceName,
          value: data.name,
        })),
      );
    })();
  }, []);

  const paths = [
    { url: '/operations', title: 'Dashboard' },
    { url: '/operations/create', title: 'Add an Operation' },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddOperation errors={{}} operationTypes={operationTypes} />
        </ContentContainer>
      </div>
    </>
  );
};

export default OperationCreate;
