import { getExportReports } from '../../utils/ExportUtils';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import { localTimeFormatter } from '../../utils/TimeUtils';
import React from 'react';

const exportColumns = [
  {
    id: 'departureDate',
    name: 'Departure Date',
  },
  {
    id: 'destinationSite',
    name: 'Destination',
  },
  {
    id: 'destinationRegion',
    name: 'Destination Region',
  },
  {
    id: 'departurePID',
    name: 'Departure Site PID',
  },
  {
    id: 'animalTags',
    name: 'Moved Out Animals (Tag Info)',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const ExportReportListView = () => {
  //if the destination PID is given, set that as the location so the table displays it
  const setPidAsLocation = (report) => {
    if (!report.destinationLocation || report.destinationLocation === '') {
      return report.destinationPID;
    } else return report.destinationLocation;
  };

  const fetchExportReports = async () => {
    let fetchedReport = await getExportReports();
    fetchedReport.forEach((r) => {
      r.destinationLocation = setPidAsLocation(r);
      r.departureDate = r.departureTime
        ? localTimeFormatter(r.departureTime)
        : '';
      r.arrivalDate = r.arrivalTime ? localTimeFormatter(r.arrivalTime) : '';
    });
    return fetchedReport.filter((report) => !report.isTemp).reverse();
  };

  return (
    <SheepReportListComponent
      title="Export"
      description="An Export Report records details related to animals moving 
      from a Canadian premises to another country. You are required to report 
      exporting animals. Animals that are imported are exempt from the requirement 
      of information to accompany the animals during transport. You can create 
      your export report here."
      fetchReportsFunction={fetchExportReports}
      detailEndpoint="/reports/export/"
      createEndpoint="/reports/export/create/manual"
      csvEndpoint="/reports/export/create/csv"
      columns={exportColumns}
    />
  );
};

export default ExportReportListView;
