import axios from 'axios';
import { getSelectedRole } from './RoleUtils';

export const getExportReports = () => {
  return axios
    .get('/api/animalexport', { params: { selectedRole: getSelectedRole() } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

//get the export reports for a specific sheep
export const getExportReportsForSheep = async (id) => {
  return axios
    .get(`/api/animalexport/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createTempExportReport = (data) => {
  return axios
    .post('/api/animalexport', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.error;
    });
};

export const createExportReport = (data) => {
  return axios
    .post('/api/animalexport', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data;
    });
};

export const createBulkExportReport = (data) => {
  return axios
    .post('/api/animalexport', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.error;
    });
};

export const createBulkTempExportReport = (data) => {
  return axios
    .post('/api/animalexport', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.error;
    });
};
