import React from 'react';
import PropTypes from 'prop-types';

const FieldLabel = ({ name, required }) => {
  return (
    name && (
      <p style={{ fontWeight: 'bold', marginBottom: '10px' }}>
        {name}:{required && ' *'}
      </p>
    )
  );
};

FieldLabel.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
};

export default FieldLabel;
