import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import {
  Button,
  HorizontalContainer,
  VerticalContainer,
  ImageContainer,
} from './CommonComponents';
import landing_page_image from '../images/Landing Page image.png';

// TODO: The simple throws the sentence off center.
// So two options:
// 1) Pick a different 4 letter word
// 2) Recenter the sentence upon word change
// Change is still TBD
const options = ['easy', 'simple', 'fast'];

export default function Landing() {
  return (
    <LandingContainer>
      <HorizontalContainer width={'100%'}>
        <VerticalContainer mobile={'margin: 0px;'} m={'auto'}>
          <Text style={{ position: 'relative', left: '-45px' }}>
            Tracking sheep, made&nbsp;
            {options.map((option, i) => (
              <TextOptions key={i} delay={i}>
                {option}.
              </TextOptions>
            ))}
          </Text>
          <ButtonGroup>
            <HorizontalContainer>
              <Link to="/register">
                <Button $stylePreset={'bold'}>Sign Up</Button>
              </Link>
              <Link to="/login">
                <Button>Login</Button>
              </Link>
            </HorizontalContainer>
          </ButtonGroup>
        </VerticalContainer>
        <ImageContainer
          src={landing_page_image}
          width={'50%'}
          mobile={'margin: 0px; width: 80%;'}
          m={'auto'}
        />
      </HorizontalContainer>
    </LandingContainer>
  );
}

//const BorderedButton = styled(Button)`
//background-color: transparent;
//border: 2px solid #4caf50;
//color: #17a737;
//font-weight: bold;
//`;

const LandingContainer = styled.div`
  margin: auto;
`;

const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  Button {
    margin: 5px;
  }
`;

const Text = styled.div`
  font-size: 200%;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 150%;
  }
`;

const DisappearKeyframe = keyframes`
	0%,${100 / options.length}% {
		opacity: 1;
	}
	${100 / options.length + 0.01}%,100% {
		opacity: 0;
	}
`;

const TextOptions = styled.span`
  font-size: inherit;
  position: absolute;
  color: #17a737;
  opacity: 0;

  animation: ${DisappearKeyframe} ${options.length}s infinite;
  animation-delay: ${(props) => props.delay}s;
`;
