import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import {
  getOperationById,
  deleteOperation,
} from '../../utils/OperationUtils.js';
import { OperationDetailsCard } from '../../components/OperationDetailsCard.js';
import {
  VerticalContainer,
  OldBackLink,
} from '../../components/CommonComponents.js';
import { PageHeader } from '../../components/PageComponents.js';

const OperationView = () => {
  const [operation, setOperation] = useState();
  const [pids, setPids] = useState([]);
  const [redirect, setRedirect] = useState('');
  const history = useHistory();

  const { id } = useParams();

  const fetchAndSetOperationId = async () => {
    let result = {};
    try {
      result = await getOperationById(id);
      const transformedOperation = {
        id: result._id,
        businessName: result.businessName,
        operationType: result.operationType,
        phoneNumber: result.phoneNumber,
        sfcp: result.sfcp ? 'Yes' : 'No',
        cvsp: result.cvsp ? 'Yes' : 'No',
        email: result.email,
        pids: result.pids,
      };

      setOperation(transformedOperation);

      const premisesReq = await axios.get(
        `/api/premises/operation/${result._id}`,
      );
      const pids = premisesReq.data.map((premise) => premise.pid);

      setPids(pids);
    } catch (err) {
      if (err.reponse && err.reponse.status == 404) {
        toast.error('Operation not found');
      } else if (err.response) {
        toast.error(err.response.data.message);
      } else {
        console.error(err);
        toast.error(err.message);
      }
    }
  };

  useEffect(() => {
    fetchAndSetOperationId();
  }, [id]);

  if (redirect !== '') return <Redirect push to={redirect} />;

  return (
    <VerticalContainer align_items="stretch" justify_content="flex-start">
      <div>
        <OldBackLink onClick={history.goBack}>&larr; Back</OldBackLink>
      </div>
      <PageHeader>Operation</PageHeader>
      <br />
      <br />
      <h3>Operation Details</h3>
      <OperationDetailsCard
        data={operation}
        pids={pids}
        deleteOperation={() => {
          deleteOperation(id);
          setRedirect('/operations');
        }}
      />
      <br />
      {redirect ? <Redirect to={`/operations`} /> : null}
    </VerticalContainer>
  );
};

export default OperationView;
