import {
  validateSingleCsip,
  isLocalMgmtNumber,
  isTattooNumber,
  isUSScrapieId,
} from 'agroledger-shared-library/TagUtils';
import { searchableDropdownField } from './commonFields';

const getFormFields = (
  premises,
  animals,
  handleAnimalSelect,
  vehicles,
  handleVehicleSelect,
) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Complete this form to replace a tag on a sheep, if you do not have any sheep, add one here:  `,
      LinkData: { label: 'Add a Sheep', path: '/sheep/create/manual' },
    },
    {
      name: 'Tag Replacement Premise',
      id: 'pid',
      type: 'select',
      options: premises,
      required: true,
      validityCheck: (fieldValues) => {
        return !!fieldValues.value;
      },
    },
    {
      ...searchableDropdownField(
        'Animal ID',
        'animal-search',
        'sheep',
        'tag.isoNumber',
        animals,
        'Fill out the ID of the Animal',
        handleAnimalSelect,
        'Search by Animal ID',
        true,
        false,
      ),
    },
    {
      ...searchableDropdownField(
        'Vehicle',
        'vehicle-search',
        'vehicle',
        'licensePlateNum',
        vehicles,
        'Fill out the ID of the Vehicle',
        handleVehicleSelect,
        'Search by Vehicle ID, if the animal was moved in',
        false,
      ),
    },
  ];

  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the new identification details for the selected sheep: `,
    },
    {
      name: 'New CSIP Number',
      hint: '124 000 310 020 965',
      id: 'newIsoNumber',
      type: 'text',
      errorMessage: '',
      required: true,
      validityCheck: (fields) => validateSingleCsip(fields.value, ''),
    },
    {
      name: 'New Local Management Number',
      id: 'newLocalMgmtNumber',
      type: 'text',
      hint: '98902183...',
      errorMessage:
        'Local Management Number must only contain alphanumeric characters.',
      onChange: (field) => {
        return field.toUpperCase();
      },
      validityCheck: (field) => {
        if (field.localMgmtNumber === '' || isLocalMgmtNumber(field.value)) {
          field.validity = true;
          return true;
        } else {
          field.validity = false;
          return false;
        }
      },
    },
    {
      name: 'New Tattoo Number',
      hint: 'AB1234',
      id: 'newTattooNumber',
      type: 'text',
      errorMessage: 'Tattoo Number must only contain alphanumeric characters.',
      validityCheck: (field) => {
        if (field.tattooNumber === '' || isTattooNumber(field.value)) {
          return true;
        } else {
          return false;
        }
      },
    },
    {
      name: 'New US Scrapie ID',
      hint: 'CA0283748',
      id: 'newUsScrapieId',
      type: 'text',
      helper:
        'US Scrapie ID must start with a US State abbreviation, followed by 7 digits.',
      errorMessage: 'Invalid US Scrapie ID',
      validityCheck: (field) => {
        if (field.value === '' || isUSScrapieId(field.value)) {
          return true;
        } else {
          return false;
        }
      },
    },
  ];

  // Grouping steps into a single array
  const addReplacementFields = [
    {
      name: 'Animal Identification',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'You must provide the tag replacement site and an animal',
    },
    {
      name: 'New Identification',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'At least one new identifier is required',
    },
  ];
  return addReplacementFields;
};

export default getFormFields;
