import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import styled from 'styled-components';
import Form from './Form.js';
import { HorizontalContainer, ImageContainer } from './CommonComponents';
import axios from 'axios';
import { getToken } from '../utils/TokenUtils.js';
import { toast } from 'react-toastify';
import login_page_image from '../images/login_page_image.png';

export default function Register({ handleLogin }) {
  const [redirect, setRedirect] = useState(false);
  const fields = [
    {
      name: 'First Name',
      required: true,
      id: 'firstName',
    },
    {
      name: 'Last Name',
      required: true,
      id: 'lastName',
    },
    {
      name: 'Phone',
      required: true,
      id: 'phone',
    },
    {
      name: 'Username',
      required: true,
      id: 'username',
    },
    {
      name: 'Email',
      required: true,
      id: 'email',
    },
    {
      type: 'password',
      name: 'Password',
      required: true,
      id: 'password',
    },
    {
      type: 'password',
      name: 'Re-enter your password',
      required: true,
      id: 'cPassword',
    },
  ];

  // If user is already authenticated, redirect
  if (getToken() || redirect) {
    return <Redirect to="/" />;
  }

  /**
   * Registers the user on the server. This function is called in response to the
   * submit button being pressed
   *
   * @function
   */
  const register = async (user) => {
    try {
      await axios.post('/api/users/register', user);
      toast.success('Account created!');

      await handleLogin(user);

      setRedirect(true);
    } catch (error) {
      if (error.response.data) {
        for (let err of error.response.data) {
          toast.error(err.message);
        }
      } else {
        toast.error(error.response.data.message);
      }
    }
  };

  return (
    <HorizontalContainer>
      <ImageContainer
        src={login_page_image}
        alt="sheep image"
        width={'40%'}
        m={'auto'}
      />
      <RegisterContainer>
        <Form
          title="Create an Account"
          fieldInfo={fields}
          onSubmit={register}
          submitName="Sign up"
          style={{ backgroundColor: '#E9EFE9' }}
          buttonTheme="bold"
          trailingContent={
            <p style={{ textAlign: 'center' }}>
              Already have an account?&nbsp;<Link to="/login">Login</Link>
            </p>
          }
        />
      </RegisterContainer>
    </HorizontalContainer>
  );
}

Register.propTypes = {
  handleLogin: PropTypes.func.isRequired,
};

// TODO: should probably import this from a file since Login.js uses it too
const RegisterContainer = styled.div`
  margin: auto;
`;
