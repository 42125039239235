// SearchBar.js
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Icon } from './CommonComponents';
import { COLORS } from '../utils/Constants';
import searchIcon from '../images/icons/search.png';

const StyledInput = styled.input`
  width: calc(100% - 30px - 2em);
  font-size: 14px;
  border: none;
  padding: 0.5em;
  background-color: inherit;
  &:focus {
    outline: none;
  }
`;

const SearchBarContainer = styled.div`
  width: 100%;
  border: ${COLORS.primary} 1px solid;
  display: flex;
  flex-direction: row;
`;

const SearchBar = ({ value, onChange, placeholder, containerStyle, type }) => {
  return (
    <>
      <SearchBarContainer style={containerStyle}>
        <Icon src={searchIcon} width="20px" m="5px" />
        <StyledInput
          value={value}
          type={type || 'text'}
          placeholder={placeholder}
          onChange={onChange}
          max={type === 'date' ? '9999-12-31' : undefined}
        />
      </SearchBarContainer>
    </>
  );
};

SearchBar.propTypes = {
  containerStyle: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.any,
};

export default SearchBar;
