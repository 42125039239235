import React from 'react';
import { toast } from 'react-toastify';

import axios from 'axios';

import SheepReportListComponent from '../../components/SheepReportListComponent';
import { localTimeFormatter } from '../../utils/TimeUtils';
import { getSelectedRole } from '../../utils/RoleUtils';

const getReplaceReports = () => {
  return axios
    .get('/api/tagreplacement', {
      params: {
        selectedRole: getSelectedRole(),
      },
    })
    .then((res) => {
      let data = res.data.reverse();

      data.forEach((report) => {
        report.replacementDate = localTimeFormatter(report.replacementDate);
        // This allows for the reports to be filtered by animal ID
        report.animalIds = [
          report.oldIsoNumber,
          report.oldUsScrapieId,
          report.oldLocalMgmtNumber,
          report.oldTattooNumber,
          report.newIsoNumber,
          report.newUsScrapieId,
          report.newLocalMgmtNumber,
          report.newTattooNumber,
        ].filter((id) => id !== undefined);

        // Find the best ID to display in the table
        report.oldId =
          report.oldIsoNumber ||
          report.oldUsScrapieId ||
          report.oldLocalMgmtNumber ||
          report.oldTattooNumber ||
          'N/A';

        // Determine the new, most important identifier
        if (report.newIsoNumber && report.oldIsoNumber != report.newIsoNumber) {
          report.newId = report.newIsoNumber;
        } else if (
          report.newUsScrapieId &&
          report.oldUsScrapieId != report.newUsScrapieId
        ) {
          report.newId = report.newUsScrapieId;
        } else if (
          report.newLocalMgmtNumber &&
          report.oldLocalMgmtNumber != report.newLocalMgmtNumber
        ) {
          report.newId = report.newUsScrapieId;
        } else if (
          report.newTattooNumber &&
          report.oldTattooNumber != report.newTattooNumber
        ) {
          report.newId = report.newTattooNumber;
        } else {
          // Impossible case
          report.newId = 'N/A';
        }
      });

      return data;
    })
    .catch((err) => {
      toast.error(err.response.data.message);
      return [];
    });
};

const tableColumns = [
  {
    id: 'replacementDate',
    name: 'Replacement Date',
  },
  {
    id: 'pid',
    name: 'PID',
  },
  {
    id: 'oldId',
    name: 'Old Identifier',
  },
  {
    id: 'newId',
    name: 'New Identifier',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const SheepReplaceListView = () => {
  return (
    <>
      <SheepReportListComponent
        title="Tag Replacement"
        description="Tag replacement reports records the replacement of an approved identifier. You are required to report
                    the application of an approved tag to an animal that has lost an approved tag, whose previous tag has
                    been revoked, or whose approved tag has malfunctioned (only permitted by direction of the CFIA)."
        detailEndpoint="/reports/replace/view/"
        createEndpoint="/reports/replace/create"
        fetchReportsFunction={getReplaceReports}
        columns={tableColumns}
      />
    </>
  );
};

export default SheepReplaceListView;
