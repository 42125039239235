import React from 'react';
import styled from 'styled-components';
import ErrorArea from './ErrorArea';
import MessageArea from './MessageArea.js';
import PropTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import { BackLink } from './CommonComponents.js';

const FormCard = ({ title, children, errors, messages, style, back }) => {
  return (
    <FormDiv style={style}>
      <FormTitle>{title}</FormTitle>
      {children}
      {errors && <ErrorArea formErrors={errors} />}
      {messages && messages.length > 0 && <MessageArea messages={messages} />}
      {back && back.to && <BackLink to={back.to} label={back.label} />}
    </FormDiv>
  );
};

FormCard.propTypes = {
  back: PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  }),
  children: PropTypes.node,
  errors: PropTypes.node,
  messages: PropTypes.node,
  style: PropTypes.any,
  title: PropTypes.node,
};

export default FormCard;

const FormDiv = styled.div`
  width: 480px;
  max-width: fit-content;
  background-color: ${COLORS.light};
  border-radius: 25px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 7%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: ${COLORS.text};

  @media (max-width: 768px) {
    padding-inline: 0%;
    width: 100vw;
  }
`;

const FormTitle = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 30px;
  border-radius: 10px 10px 0px 0px;
  color: ${COLORS.text};
  font-weight: bold;
`;
