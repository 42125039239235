// DropDown.js
import React from 'react';
import {
  FormInputWrapper,
  FormInputLabel,
  FormTextList,
  FormSelectInput,
} from './CommonComponents.js';
import propTypes from 'prop-types';

const DropDown = ({ id, name, value, onChange, options, required }) => (
  <>
    <FormInputWrapper key={id}>
      <FormInputLabel htmlFor="input">
        {name}
        {required ? <span style={{ color: 'red' }}>*</span> : <></>}
      </FormInputLabel>
      <FormTextList>
        <FormSelectInput name={name} id={id} value={value} onChange={onChange}>
          {!value ? (
            <option value="" disabled selected>
              Select {name}
            </option>
          ) : (
            <></>
          )}
          {options.map((o) => (
            <>
              <option value={o.value}>{o.name}</option>
            </>
          ))}
        </FormSelectInput>
      </FormTextList>
    </FormInputWrapper>
  </>
);

DropDown.propTypes = {
  id: propTypes.node,
  name: propTypes.node,
  value: propTypes.node,
  onChange: propTypes.func,
  options: propTypes.array,
  required: propTypes.bool,
};

export default DropDown;
