// ReportComponents.js
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { getUsername } from '../utils/TokenUtils';
import propTypes from 'prop-types';
import SearchBar from '../components/SearchBar';
import { ContentContainer, Button } from '../components/CommonComponents';
import { getPremisesForUser } from '../utils/PremiseUtils';
import { getSheepsForUser } from '../utils/SheepUtils';
import { getVehicleByUser } from '../utils/VehicleUtils';
import { checkAdmin } from '../utils/RoleUtils';
import { localeToDate, createDate } from '../utils/TimeUtils';
import { COLORS } from '../utils/Constants';
import { dynamicSortAlpha, dynamicSortDate } from '../utils/ListUtils.js';

const DropDown = ({ options, name, id, onChange, value }) => {
  if (!options) options = [];
  return (
    <>
      <select name={name} id={id} onChange={onChange} value={value}>
        <option value={''}>Any</option>
        {options.map((o) => (
          <option value={o.value} key={o.name}>
            {o.name}
          </option>
        ))}
      </select>
    </>
  );
};

DropDown.propTypes = {
  options: propTypes.any,
  name: propTypes.any,
  id: propTypes.any,
  onChange: propTypes.any,
  value: propTypes.any,
};

const ClearButtonComponent = ({ onClick }) => (
  <ClearButton onClick={onClick}>Clear</ClearButton>
);

ClearButtonComponent.propTypes = {
  onClick: propTypes.any,
};

const ClearButton = styled.button`
  cursor: pointer;
`;
const FilterField = ({ field, value }) => {
  return (
    <div>
      <label>
        {field.name}{' '}
        {field.type === 'date' ? (
          <>
            <ClearButtonComponent onClick={field.onClick} />
          </>
        ) : (
          <></>
        )}
      </label>
      <br />
      {field.dropdown ? (
        <>
          <DropDown
            options={field.options}
            onChange={field.onChange}
            value={value}
          />
        </>
      ) : (
        <input
          type={field.type ? field.type : 'text'}
          onChange={field.onChange}
          value={value}
        ></input>
      )}
    </div>
  );
};

FilterField.propTypes = {
  field: propTypes.any,
  value: propTypes.any,
};

const DescriptionArea = styled.div`
  font-size: 1rem;
  margin-bottom: 2rem;
`;

const FilterSectionContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 25px;
  padding-right: 25px;

  h3 {
    margin-top: 0px;
  }
`;

const SearchFieldsContainer = styled(ContentContainer)`
  display: flex;
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  flex-direction: row;
  align-items: start;
  max-width: 100%;

  @media (max-width: 1150px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
  }
  @media (max-width: 950px) {
    width: 95%;
  }
  @media (max-width: 768px) {
    gap: 25px;
  }
  @media (max-width: 500px) {
    width: 85%;
  }
  @media (max-width: 460px) {
    width: 75%;
  }
`;

const DateContainer = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  width: 58%;
  p {
    margin-top: 0px;
    font-size: 12px;
  }
  @media (max-width: 1150px) {
    width: 100%;
    p {
      font-size: 15px;
    }
  }
`;

const IdentifierPremisesContainer = styled(ContentContainer)`
  padding: 0px;
  background: white;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  @media (max-width: 1150px) {
    gap: 23px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 23px;
  }
`;

const InputContainer = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 23px;
  }
`;

const IdentifierContainer = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  width: 75%;
  p {
    margin-top: 0px;
    margin-bottom: 7px;
    font-size: 12px;
  }
  @media (max-width: 1150px) {
    width: 100%;
    p {
      font-size: 15px;
    }
  }
`;

const PremisesContainer = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  width: 50%;
  p {
    margin-top: 0px;
    margin-bottom: 7px;
    font-size: 12px;
  }
  @media (max-width: 1150px) {
    width: 100%;
    p {
      font-size: 15px;
    }
  }
`;

const RelatedContainer = styled(ContentContainer)`
  padding: 0px;
  background: white;
  min-width: 100px;
  border: none;
  display: flex;
  @media (max-width: 1150px) {
    gap: 23px;
    width: 100%;
    p {
      font-size: 15px;
    }
  }
  p {
    margin-top: 0px;
    margin-bottom: 7px;
    font-size: 12px;
  }

  input:not([type='checkbox']),
  select {
    width: 100%;
    height: 35px;
    border: ${(props) =>
      props.border ? props.border : `1px solid ${COLORS.primary}`};
    border-radius: 15px;
    background: ${(props) => (props.background ? props.background : 'white')};
    font-size: 14px;
    outline: none;
    padding: 8px 15px;

    &:disabled {
      background-color: ${COLORS.secondary};
      color: ${COLORS.text};
      opacity: 0.85;
    }

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
`;

const ButtonsContainer = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  margin-top: 1.5em;
  display: flex;
  flex-direction: row;
  width: 10%;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

const CheckboxContainer = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 10em;
  gap: 5px;
  align-items: right;
  justify-content: right;
  label {
    font-size: 12px;
  }
`;

const ClearAllButton = styled(Button)`
  min-width: 100px;
  font-size: 15px;
  margin: 0px;
  cursor: pointer;
`;

const FilterSection = ({ data, onApply }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [animalId, setAnimalId] = useState('');
  const [pid, setPid] = useState('');
  const [selectedRelated, setSelectedRelated] = useState(null);

  const [dateFields, setDateFields] = useState([]);
  const [tagFields, setTagFields] = useState([]);
  const [pidFields, setPidFields] = useState([]);
  const [relatedCheck, setRelatedCheck] = useState(false);
  const [selected, setSelected] = useState([]);

  /*
   * Different reports have different fields names that refer to the same
   * attribute (e.g. retirement reports use "dateOfDisposal", activation reports
   * use "date").
   * These arrays are look-ups to match fields with reports during filtering.
   */
  const allDateFields = [
    'date',
    'replacementDate',
    'dateOfDisposal',
    'dateOfConveyance',
    'departureDate',
    'loadTime',
    'receivedTime',
    'dateOfArrival',
    'arrivalDate',
    'reportDate',
    'firstTreatmentDate',
    'finalTreatmentDate',
    'meatWithdrawalDate',
    'milkWithdrawalDate',
  ];

  const allTagFields = [
    'animalTags',
    'animalIdentifications',
    'isoNumber',
    'newId',
    'oldId',
    'localMgmtNumber',
    'tattooNumber',
  ];

  const allPidFields = [
    'pid',
    'disposalPID',
    'departurePID',
    'destinationPID',
    'firstDestinationPID',
    'premiseID',
  ];

  // determine which date, tag, and pid fields are in the current data
  const findSearchableFields = () => {
    const dateFieldsFound = allDateFields.filter((df) => {
      for (let report of data) {
        if (df in report) {
          return true;
        }
      }
      return false;
    });
    setDateFields(dateFieldsFound);

    const pidFieldsFound = allPidFields.filter((pf) => {
      for (let report of data) {
        if (pf in report) {
          return true;
        }
      }
      return false;
    });
    setPidFields(pidFieldsFound);

    const tagFieldsFound = allTagFields.filter((tf) => {
      for (let report of data) {
        if (tf in report) {
          return true;
        }
      }
      return false;
    });
    setTagFields(tagFieldsFound);

    for (let report of data) {
      if (report.related) {
        setRelatedCheck(true);
        break;
      }
    }

    setSelected(dateFieldsFound.concat(pidFieldsFound, tagFieldsFound));
  };

  useEffect(() => {
    (async () => {
      await findSearchableFields();
    })();
  }, []);

  const clearFilters = () => {
    setStartDate('');
    setEndDate('');
    setAnimalId('');
    setPid('');
    setSelectedRelated(null);
    setSelected(dateFields.concat(tagFields, pidFields));
  };

  const formatFieldName = (name) => {
    return name
      .replace(/([A-Z]+)/g, ' $1')
      .replace(/_/g, ' ')
      .replace(/^\s*/, '')
      .replace(/\s*$/, '')
      .toLowerCase()
      .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase());
  };

  const handleSort = (reports) => {};

  // filter function, called when any filter field is changed
  useEffect(() => {
    const selectedDateFields = dateFields.filter((v) => selected.includes(v));
    const selectedTagFields = tagFields.filter((v) => selected.includes(v));
    const selectedPidFields = pidFields.filter((v) => selected.includes(v));

    const filteredData = data.filter((data) => {
      let validReport = true;

      if (startDate) {
        const startFilter = createDate(startDate).setHours(0, 0, 0, 0);
        let startDateValid = false;
        for (let dateField of selectedDateFields) {
          let reportDate = data[dateField]
            ? localeToDate(data[dateField]).setHours(0, 0, 0, 0)
            : null; // Set time to start of day
          if (reportDate !== null && startFilter < reportDate) {
            startDateValid = true;
          }
        }
        validReport = startDateValid;
      }

      if (endDate && validReport) {
        const endFilter = createDate(endDate).setHours(23, 59, 59, 999);
        let endDateValid = false;
        for (let dateField of selectedDateFields) {
          let reportDate = data[dateField]
            ? localeToDate(data[dateField]).setHours(23, 59, 59, 999)
            : null;
          if (reportDate !== null && endFilter > reportDate) {
            endDateValid = true;
          }
        }
        validReport = endDateValid;
      }

      if (animalId && validReport) {
        const lowerCaseTag = animalId.toString().toLowerCase();
        let animalIdValid = false;
        for (let tagField of selectedTagFields) {
          let reportTag = data[tagField] ?? null;
          if (Array.isArray(reportTag)) {
            reportTag = data[tagField].join(',');
          }

          if (reportTag !== null && reportTag.includes(lowerCaseTag)) {
            animalIdValid = true;
          }
        }
        validReport = animalIdValid;
      }

      if (pid && validReport) {
        const lowerCasePid = pid.toString().toLowerCase();
        let pidValid = false;
        for (let pidField of selectedPidFields) {
          let reportPid = data[pidField] ?? null;
          if (Array.isArray(reportPid)) {
            reportPid = data[pidField].join(',');
          }
          if (
            reportPid !== null &&
            reportPid.toLowerCase().includes(lowerCasePid)
          ) {
            pidValid = true;
          }
        }
        validReport = pidValid;
      }

      if (selectedRelated && validReport) {
        if (selectedRelated === 'mine') if (data.related) validReport = false;

        if (selectedRelated === 'related')
          if (!data.related) validReport = false;
      }

      return validReport;
    });

    let sortedReports = filteredData;

    if (selectedTagFields.length > 0) {
      let firstTagField = selectedTagFields[0];
      sortedReports = sortedReports.sort(dynamicSortAlpha(firstTagField));
    }

    if (selectedPidFields.length > 0) {
      let firstPidField = selectedPidFields[0];
      sortedReports = sortedReports.sort(dynamicSortAlpha(firstPidField));
    }

    if (selectedDateFields.length > 0) {
      let firstDateField = selectedDateFields[0];
      sortedReports = sortedReports.sort(
        dynamicSortDate('-' + firstDateField, true),
      );
    }

    onApply(sortedReports);
  }, [startDate, endDate, animalId, pid, selected, selectedRelated]);

  const checkboxHandler = (e) => {
    const { id, checked } = e.target;
    setSelected((prevSelected) =>
      checked
        ? [...prevSelected, id]
        : prevSelected.filter((field) => field !== id),
    );
  };

  return (
    <>
      <FilterSectionContainer $stylePreset="light">
        <h3>Filters</h3>
        <SearchFieldsContainer>
          <DateContainer>
            <p>Filter based on the report date</p>
            <InputContainer>
              <SearchBar
                type="date"
                placeholder={'Start date'}
                icon={false}
                value={startDate}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                containerStyle={{
                  width: '50%',
                  borderRadius: '15px',
                  paddingBlock: '0.25em',
                  marginInline: '0px',
                  marginBlock: '5px',
                  marginLeft: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              />
              <SearchBar
                type="date"
                placeholder={'End date'}
                icon={false}
                value={endDate}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                containerStyle={{
                  width: '50%',
                  borderRadius: '15px',
                  paddingBlock: '0.25em',
                  marginInline: '0px',
                  marginBlock: '5px',
                  marginRight: '0px',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              />
              {dateFields.length > 1 && (
                <CheckboxContainer key="dateCheck">
                  {dateFields.map((df) => (
                    <div id={df} key={df}>
                      <label>{formatFieldName(df)}:</label>
                      <input
                        type="checkbox"
                        id={df}
                        defaultChecked
                        checked={selected.includes(df)}
                        onClick={checkboxHandler}
                      />
                    </div>
                  ))}
                </CheckboxContainer>
              )}
            </InputContainer>
          </DateContainer>

          <IdentifierPremisesContainer>
            <IdentifierContainer>
              <p>Filter based on animal ID</p>
              <InputContainer>
                <SearchBar
                  placeholder={'Identifier Search'}
                  icon={false}
                  value={animalId}
                  onChange={(e) => {
                    setAnimalId(e.target.value);
                  }}
                  containerStyle={{
                    width: '100%',
                    minWidth: '10em',
                    borderRadius: '15px',
                    paddingBlock: '0.3em',
                    marginInline: '10px',
                    marginBlock: '5px',
                    marginLeft: '0px',
                    marginBottom: '0px',
                    paddingTop: '3px',
                  }}
                />
                {tagFields.length > 1 && (
                  <CheckboxContainer key="tagCheck">
                    {tagFields.map((tf) => (
                      <div id={tf} key={tf}>
                        <label>{formatFieldName(tf)}:</label>
                        <input
                          type="checkbox"
                          id={tf}
                          defaultChecked
                          checked={selected.includes(tf)}
                          onClick={checkboxHandler}
                        />
                      </div>
                    ))}
                  </CheckboxContainer>
                )}
              </InputContainer>
            </IdentifierContainer>

            <PremisesContainer>
              <p>Filter based on Premise ID</p>
              <InputContainer>
                <SearchBar
                  placeholder={'Premises ID'}
                  icon={false}
                  value={pid}
                  onChange={(e) => {
                    setPid(e.target.value);
                  }}
                  containerStyle={{
                    width: '100%',
                    minWidth: '10em',
                    borderRadius: '15px',
                    paddingBlock: '0.3em',
                    marginInline: '10px',
                    marginBlock: '5px',
                    marginLeft: '0px',
                    marginBottom: '0px',
                    paddingTop: '3px',
                  }}
                />
                {pidFields.length > 1 && (
                  <CheckboxContainer key="pidCheck">
                    {pidFields.map((pf) => (
                      <div id={pf} key={pf}>
                        <label>{formatFieldName(pf)}:</label>
                        <input
                          type="checkbox"
                          id={pf}
                          checked={selected.includes(pf)}
                          defaultChecked
                          onClick={checkboxHandler}
                        />
                      </div>
                    ))}
                  </CheckboxContainer>
                )}
              </InputContainer>
            </PremisesContainer>
          </IdentifierPremisesContainer>

          <ButtonsContainer
            style={
              relatedCheck
                ? {
                    flexDirection: 'column',
                    marginTop: '0',
                    alignItems: 'end',
                    gap: '10px',
                  }
                : null
            }
          >
            {relatedCheck && (
              <RelatedContainer>
                <div>
                  <select
                    value={selectedRelated ? selectedRelated : 'both'}
                    onChange={(e) => setSelectedRelated(e.target.value)}
                  >
                    <option value={null}>All Reports</option>
                    <option value="mine">My Reports</option>
                    <option value="related">Related Reports</option>
                  </select>
                </div>
              </RelatedContainer>
            )}

            <ClearAllButton
              onClick={(_) => {
                clearFilters();
                onApply(data);
              }}
            >
              Clear
            </ClearAllButton>
          </ButtonsContainer>
        </SearchFieldsContainer>
      </FilterSectionContainer>
    </>
  );
};

FilterSection.propTypes = {
  data: propTypes.any,
  onApply: propTypes.any,
  values: propTypes.any,
  onClear: propTypes.any,
};

const FetchCarcassDisposalReports = async () => {
  try {
    let reports = await FetchSheepReportsByAction('CARCASSDISPOSAL');
    return sortReportsByDate(reports);
  } catch (err) {
    throw new Error(
      `An error occurred fetching carcass disposal reports: ${err.message}`,
    );
  }
};

const FetchSlaughterReports = async () => {
  try {
    let reports = await FetchSheepReportsByAction('SLAUGHTER');
    return sortReportsByDate(reports);
  } catch (err) {
    throw new Error(
      `An error occurred fetching slaughter reports: ${err.message}`,
    );
  }
};

const FetchAndSortDisposalReports = async () => {
  try {
    const slaughterReports = await FetchSheepReportsByAction('SLAUGHTER');
    let allReports = slaughterReports.concat(
      await FetchSheepReportsByAction('CARCASSDISPOSAL'),
    );

    return sortReportsByDate(allReports);
  } catch (err) {
    throw new Error(`An error occurred fetching reports: ${err.message}`);
  }
};

const sortReportsByDate = (reports) => {
  reports.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  return reports;
};

const FetchSheepReportsByAction = async (action) => {
  const username = getUsername();
  const request = await axios.get(
    `/api/sheepreports?username=${username}&action=${action}`,
  );
  const fetchedReports = request.data;
  return fetchedReports;
};

const FetchAllSheepReports = async () => {
  try {
    const username = getUsername();
    const request = await axios.get(`/api/sheepreports?username=${username}`);
    const fetchedReports = request.data;
    return fetchedReports;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep reports: ${err.message}`);
  }
};

const FetchSheepReportsById = async (id) => {
  try {
    const request = await axios.get(`/api/sheepreports/sheep/${id}`);
    const fetchedReports = request.data;
    return fetchedReports;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep reports: ${err.message}`);
  }
};

const NASpan = styled.span`
  color: #818181;
`;
const NAField = () => <NASpan>N/A</NASpan>;

const FetchPremises = async () => {
  try {
    const username = getUsername();
    const fetchedPremises = getPremisesForUser(username);
    return fetchedPremises;
  } catch (err) {
    throw new Error(`An error occurred fetching premises: ${err.message}`);
  }
};
const PremisesFormatter = (premises) => {
  if (premises.length == 0) return [];
  const formattedPremises = premises.map((premise) => ({
    name: premise.name ? `${premise.pid} - ${premise.name}` : premise.pid,
    value: premise.pid,
  }));
  return formattedPremises;
};
const FetchVehicles = async () => {
  try {
    const username = getUsername();
    const vehicleList = getVehicleByUser(username);
    return vehicleList;
  } catch (err) {
    throw new Error(`An error occurred fetching vehicles: ${err.message}`);
  }
};
const VehicleFormatter = (vehicles) => {
  if (vehicles.length == 0) return [];
  const fetchedVehicleIDs = vehicles.map((v) => ({
    id: v.licensePlateNum,
    name: v.name ? `${v.name} - ${v.licensePlateNum}` : v.licensePlateNum,
    value: v.licensePlateNum,
  }));
  return fetchedVehicleIDs;
};

const FetchAnimals = async () => {
  try {
    const username = getUsername();
    const admin = checkAdmin();
    const fetchedAnimals = getSheepsForUser(username, admin);
    return fetchedAnimals;
  } catch (err) {
    throw new Error(`An error occurred fetching animals: ${err.message}`);
  }
};

const FetchReport = async (id) => {
  try {
    const request = await axios.get(`/api/sheepreports/${id}`);
    const fetchedReport = request.data;
    return fetchedReport;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep report: ${err.message}`);
  }
};

const PrettyPrintJson = ({ data }) => {
  return (
    <>
      <div>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </div>
    </>
  );
};

const PrintDate = ({ date }) => {
  const formatted = new Date(date).toISOString();

  return <>{formatted}</>;
};

PrettyPrintJson.propTypes = {
  data: propTypes.any,
};

PrintDate.propTypes = {
  date: propTypes.any,
};

export {
  PrintDate,
  DescriptionArea,
  FilterSection,
  FetchReport,
  FetchCarcassDisposalReports,
  FetchAndSortDisposalReports,
  FetchSlaughterReports,
  FetchAllSheepReports,
  NAField,
  FetchPremises,
  PremisesFormatter,
  FetchVehicles,
  VehicleFormatter,
  FetchAnimals,
  PrettyPrintJson,
  sortReportsByDate,
  FetchSheepReportsById,
};
