import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  FetchPremises,
  PremisesFormatter,
} from '../../components/SheepReportComponents.js';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents.js';
import AddSighting from '../../components/Forms/AddSighting.js';
import { FetchAnimals } from '../../components/SheepReportComponents.js';

const SightingReportCreateView = () => {
  const [redirect, setRedirect] = useState(false);
  const [premises, setPremises] = useState([]);
  const [animals, setAnimals] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetAnimalIds();
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      const formattedPremises = PremisesFormatter(fetchedPremises);

      setPremises(formattedPremises);
    } catch (err) {
      if (err.code == 404) {
        setPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetAnimalIds = async () => {
    try {
      const animalIdsFetched = await FetchAnimals();
      setAnimals(animalIdsFetched);
    } catch (err) {
      if (err.code === 404) {
        setAnimalIds([]);
      } else {
        throw new Error('Unable to Find any Animals');
      }
    }
  };

  const paths = [
    { url: '/reports/sighting/listview', title: 'Dashboard' },
    {
      url: '/reports/sighting/create',
      title: 'Create a Sighting Report',
    },
  ];

  if (redirect) return <Redirect to="/reports/sighting/listView" />;

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddSighting errors={{}} animals={animals} premises={premises} />
        </ContentContainer>
      </div>
    </>
  );
};

export default SightingReportCreateView;
