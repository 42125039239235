import React, { useEffect, useState } from 'react';
import SearchableTable from '../../components/SearchableTable';
import SimpleCard from '../../components/SimpleCard';
import { toast } from 'react-toastify';
import { getUsername } from '../../utils/TokenUtils';
import axios from 'axios';
import ViewEditDispose from '../../components/ViewEditDispose';
import {
  ImageContainer,
  ContentContainer,
  Grid,
  Icon,
  VerticalContainer,
  LinkButton,
  HorizontalContainer,
  Button,
} from '../../components/CommonComponents';
import sheep from '../../images/icons/sheep.png';
import add from '../../images/icons/add.png';
import left from '../../images/icons/chevron_left.png';
import right from '../../images/icons/chevron_right.png';
import reportButton from '../../images/icons/report_button.png';
import b3 from '../../images/b3.jpg';
import { COLORS } from '../../utils/Constants';
import { checkAdmin, getSelectedRole } from '../../utils/RoleUtils';
import SearchBar from '../../components/SearchBar';
import { CheckboxLabel } from '../../components/CheckboxLabel';
import PageCard from '../../components/PageCard';
import { agGreen } from '../../components/Colors';
import Loader from 'react-loader-spinner';

const OperationsComponent = () => {
  const [premises, setPremises] = useState([]);
  const [premiseSearch, setPremiseSearch] = useState('');
  const [sheepSearch, setSheepSearch] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [showInactiveSheeps, setShowInactiveSheeps] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayAllSheeps, setDisplayAllSheeps] = useState(() => {
    return checkAdmin() ? true : false;
  });
  const [showSheepInTransit, setShowSheepInTransit] = useState(false);

  const fetchSheeps = async (inactive) => {
    try {
      let uri = `/api/sheep?premise=true&getInactive=${inactive}`;

      if (!displayAllSheeps) {
        const username = getUsername();
        uri += `&username=${username}`;
      }

       const request = await axios.get(uri, {
        params: { selectedRole: getSelectedRole() },
      });
      const fetchedSheeps = request.data;

      // Filter sheep based on the selected checkboxes
      return fetchedSheeps.filter(
        (sheep) => sheep.movedOut === showSheepInTransit,
      );

    } catch (err) {
      throw new Error(`An error occurred fetching sheep: ${err.message}`, err);
    }
  };

  // Fetch operations and transform data as necessary
  const fetchAndTransformSheepData = async () => {
    try {
      setLoading(true);
      const fetchedSheeps = await fetchSheeps(showInactiveSheeps);
      let premises = [];

      // Simplify
      fetchedSheeps.map((s) => {
        // Exported sheep don't have a premise so we skip them
        if (!s.premise) return;

        const simpleSheep = {
          id: s._id,
          premiseId: s.premise._id,
          premiseName: s.premise.name,
          pid: s.premise.pid,
        };

        if (s.tag?.tattooNumber) simpleSheep.identifier = s.tag.tattooNumber;
        if (s.tag?.localMgmtNumber)
          simpleSheep.identifier = s.tag.localMgmtNumber;
        if (s.tag?.usScrapieId) simpleSheep.identifier = s.tag.usScrapieId;
        if (s.tag?.isoNumber) simpleSheep.identifier = s.tag.isoNumber;

        simpleSheep.actions = (
          <ViewEditDispose
            id={s._id}
            viewLink={`/sheep/${s._id}`}
            editLink={`/sheep/edit?id=${s._id}`}
            disposeLink={`/sheep/dispose?pid=${s.premise.pid}&id=${s._id}`}
          />
        );

        let premiseExists = false;
        for (let i = 0; i < premises.length; i++) {
          if (premises[i].id == simpleSheep.premiseId) premiseExists = i;
        }

        if (!premiseExists && premiseExists !== 0) {
          let premise = {
            id: simpleSheep.premiseId,
            name: simpleSheep.premiseName,
            pid: simpleSheep.pid,
            sheep: [simpleSheep],
          };
          premise.displayName = premise.name
            ? `${premise.name} - ${premise.pid}`
            : premise.pid;
          premises.push(premise);
        } else {
          premises[premiseExists].sheep.push(simpleSheep);
        }

        return simpleSheep;
      });
      // Sort Premises alphabetically

      premises.sort((a, b) => {
        if (a.pid < b.pid) return -1;
        if (a.pid > b.pid) return 1;
        return 0;
      });

      setPremises(premises);
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchAndTransformSheepData();
  }, [displayAllSheeps, showInactiveSheeps, showSheepInTransit]);

  let filteredPremises = [];
  for (const premise of premises) {
    if (
      (premise.name &&
        premise.name.toLowerCase().includes(premiseSearch.toLowerCase())) ||
      (premise.pid &&
        premise.pid.toLowerCase().includes(premiseSearch.toLowerCase()))
    ) {
      let tempSheeps = [];
      for (const sheep of premise.sheep) {
        for (const [_key, value] of Object.entries(sheep)) {
          if (typeof value === 'string' && value.includes(sheepSearch)) {
            tempSheeps.push(sheep);
            break;
          }
        }
      }

      // Hide premises that don't contain any matching sheep
      if (tempSheeps.length == 0) {
        continue;
      }

      filteredPremises.push({
        id: premise.id,
        name: premise.name,
        pid: premise.pid,
        sheep: tempSheeps,
        displayName: premise.displayName,
      });
    }
  }
  const premisesSlice = filteredPremises.slice(
    (pageNumber - 1) * 5,
    pageNumber * 5,
  );

  return (
    <>
      <VerticalContainer align_items="flex-start" width="100%">
        <h1>Sheep Dashboard</h1>
        <ContentContainer $stylePreset={'light'} mb="30px">
          <h2 style={{ margin: '0px' }}>Filters and Search</h2>
          <p>Search for a specific premise or animal</p>
          <HorizontalContainer
            width="100%"
            justify_content="flex-start"
            tablet="flex-direction: column;"
          >
            <SearchBar
              placeholder={'Search for an animal via identifier'}
              value={sheepSearch}
              onChange={(e) => setSheepSearch(e.target.value)}
              containerStyle={{
                width: '100%',
                borderRadius: '15px',
                paddingBlock: '0.25em',
                marginInline: '10px',
                marginBlock: '5px',
              }}
              icon={true}
            />
            <SearchBar
              placeholder={'Search Premises'}
              value={premiseSearch}
              onChange={(e) => setPremiseSearch(e.target.value)}
              containerStyle={{
                width: '100%',
                borderRadius: '15px',
                paddingBlock: '0.25em',
                marginInline: '10px',
                marginBlock: '5px',
              }}
              icon={true}
            />
            <Button
              style={{ alignSelf: 'flex-start' }}
              onClick={() => {
                setSheepSearch('');
                setPremiseSearch('');
              }}
            >
              Clear
            </Button>
          </HorizontalContainer>
          <HorizontalContainer
            width="100%"
            justify_content="flex-start"
            tablet="flex-direction: column;"
          >
            {checkAdmin() && (
              <CheckboxLabel
                text="My Sheep Only"
                handleClick={() => setDisplayAllSheeps(!displayAllSheeps)}
              />
            )}
            <CheckboxLabel
              text="Sheep Disposed"
              handleClick={() => setShowInactiveSheeps(!showInactiveSheeps)}
            />
            <CheckboxLabel
              text="Sheep In-Transit"
              handleClick={() => setShowSheepInTransit(!showSheepInTransit)}
            />
          </HorizontalContainer>
        </ContentContainer>
        {loading ? (
          <PageCard
            title="Loading your sheep..."
            loader={
              <Loader type="Oval" color={agGreen} height={50} width={150} />
            }
          />
        ) : (
          <VerticalContainer
            width="calc(100% - 60px)"
            mobile="width: 95%; align-self: center;"
            style={{
              background: `${COLORS.secondary}`,
              padding: '30px',
              paddingBottom: '0px',
              borderRadius: '15px',
            }}
          >
            {premisesSlice.map((p) => {
              // Columns
              const columns = [
                {
                  name: 'Identifier (iso #, local management # or tattoo #)',
                  id: 'identifier',
                },
                { name: '', id: 'actions' },
              ];

              const rows = p.sheep.map((sheep) => {
                delete sheep.id;
                delete sheep.premiseId;
                delete sheep.premiseName;
                delete sheep.pid;
                return sheep;
              });

              return (
                <React.Fragment key={p.id}>
                  <ContentContainer $stylePreset="light" width="100%" mb="30px">
                    <SimpleCard title={p.displayName}>
                      <SearchableTable
                        viewMoreLink={`/sheep/operation/${p.id}`}
                        searchPlaceholder="Enter the identifier you wish to search for"
                        columns={columns}
                        rows={rows}
                        maxShowing={10}
                      />
                    </SimpleCard>
                  </ContentContainer>
                </React.Fragment>
              );
            })}
          </VerticalContainer>
        )}
        <HorizontalContainer mobile="none" style={{ alignSelf: 'center' }}>
          <Icon
            width="25px"
            src={left}
            hover="filter: opacity(0.5)"
            disabled={pageNumber === 1}
            onClick={() => {
              if (pageNumber > 1) {
                setPageNumber(pageNumber - 1);
              }
            }}
          />
          {pageNumber - 1 >= 1 && (
            <h3
              style={{ paddingBlock: '5px', paddingInline: '10px' }}
              onClick={() => {
                setPageNumber(pageNumber - 1);
              }}
            >
              {pageNumber - 1}
            </h3>
          )}
          <h3
            style={{
              background: COLORS.secondary,
              borderRadius: '10px',
              paddingBlock: '5px',
              paddingInline: '10px',
            }}
          >
            {pageNumber}
          </h3>
          {pageNumber + 1 <= Math.ceil(filteredPremises.length / 5) && (
            <h3
              style={{ paddingBlock: '5px', paddingInline: '10px' }}
              onClick={() => {
                setPageNumber(pageNumber + 1);
              }}
            >
              {pageNumber + 1}
            </h3>
          )}
          <Icon
            width="25px"
            src={right}
            hover="filter: opacity(0.5)"
            disabled={pageNumber === Math.ceil(filteredPremises.length / 5)}
            onClick={() => {
              if (pageNumber < Math.ceil(filteredPremises.length / 5)) {
                setPageNumber(pageNumber + 1);
              }
            }}
          />
        </HorizontalContainer>
      </VerticalContainer>
    </>
  );
};

/* EXPORTED COMPONENT */
const SheepDashboardView = () => {
  return (
    <Grid
      gap="60px"
      templateColumns="calc(70% - 30px) calc(30% - 30px)"
      tablet="display: flex;"
      alignItems="start"
    >
      <h1 style={{ gridColumn: '1 / span 2', fontSize: 'xxx-large' }}>Sheep</h1>
      <ContentContainer
        $stylePreset="borderless"
        flexDirection="row"
        alignItems="center"
        tablet="flex-direction: column;"
        style={{
          gridColumn: '1 / span 2',
          boxShadow: '5px 5px 20px #00000029',
        }}
      >
        <HorizontalContainer
          width="60%"
          tablet="width: 100%; margin-bottom: 20px;"
          mobile="flex-direction: row;"
        >
          <Icon src={sheep} width="100px" hover="none" tablet="width: 50px;" />
          <h2
            style={{
              fontWeight: 'normal',
              fontSize: 'calc(1vw + 10px)',
              margin: 'auto',
              marginInline: '10px',
            }}
          >
            Welcome to the Sheep Dashboard. You can find all of the sheep
            registered to your operation here, sorted by premises.
          </h2>
        </HorizontalContainer>
        <ImageContainer
          src={b3}
          width="40%"
          height="175px"
          object_position="50% 0"
          m="0"
          ml="auto"
          tablet="width: 100%"
          style={{ borderRadius: '25px' }}
        />
      </ContentContainer>
      <OperationsComponent />
      <VerticalContainer align_items="flex-start">
        <h1>Reports and Help</h1>
        <VerticalContainer
          align_items="flex-start"
          tablet="flex-direction: row;"
          mobile="flex-direction: column;"
        >
          <ContentContainer
            $stylePreset="light"
            mb="30px"
            tablet="margin-right: 30px;"
            mobile="margin-right: 0px;"
          >
            <h2>Add Sheep</h2>
            <p>Need to register your sheep? Select one of these options.</p>
            <VerticalContainer
              mobile="none"
              align_items="flex-start"
              width="100%"
            >
              <LinkButton $stylePreset="dark" to="/sheep/create/manual/">
                <span>Add Manually </span>
                <Icon width="25px" m="0px" src={add} />
              </LinkButton>

              <LinkButton $stylePreset="dark" to="/sheep/create/csv/">
                <span>Add via CSV </span>
                <Icon width="25px" m="0px" src={add} />
              </LinkButton>

              <LinkButton $stylePreset="dark" to="/sheep/create/fr/">
                <span>Add via FR</span>
                <Icon width="25px" m="0px" src={add} />
              </LinkButton>
            </VerticalContainer>
            <h2>Reports Dashboard</h2>
            <p>Need to view and edit reports? Click here.</p>
            <LinkButton $stylePreset="dark" to="/reports/">
              <span>Reports</span>
              <Icon width="25px" m="0px" src={reportButton} />
            </LinkButton>
          </ContentContainer>
          <ContentContainer>
            <h2>Need Help?</h2>
            <p>
              You can find answers to common questions about how to user
              AgroLedger here.
            </p>
            <LinkButton to="/help/">Help</LinkButton>
          </ContentContainer>
        </VerticalContainer>
      </VerticalContainer>
      <HorizontalContainer></HorizontalContainer>
    </Grid>
  );
};

export default SheepDashboardView;
