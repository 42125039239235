import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import EditButtons from '../../components/reusedComponents/EditButtons';
import { FormInputWrapper } from '../../components/CommonComponents';
import FormCard from '../../components/FormCard';
import PasswordField from '../../components/reusedComponents/PasswordField';
import Instruction from '../../components/reusedComponents/Instruction';

const EditPassword = ({ userData, fetchUserData }) => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [retypedPassword, setRetypedPassword] = useState('');

  const [editMode, setEditMode] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    if (userData) {
      setUser(userData);
    }
  }, [userData]);

  const handlePasswordChange = (setter) => (e) => {
    setter(e.target.value);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setCurrentPassword('');
    setNewPassword('');
    setRetypedPassword('');
    setEditMode(false);
  };

  const checkPasswordsValidity = (inputData) => {
    let result = { validity: true, errorMessage: '' };

    const passRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d@#$%^&*()!]{8,24}$/;

    if (!currentPassword || !inputData.newPassword || !retypedPassword) {
      result.validity = false;
      result.errorMessage = 'Please fill in all fields';
    } else if (currentPassword === newPassword) {
      result.validity = false;
      result.errorMessage =
        "Please choose a password that hasn't been used before.";
    } else if (!passRegex.test(newPassword)) {
      result.validity = false;
      result.errorMessage =
        'Your new password does not meet complexity requirements';
    } else if (newPassword !== retypedPassword) {
      result.validity = false;
      result.errorMessage =
        "The retyped password doesn't match. Please make sure your new password is entered correctly.";
    }
    return result;
  };

  const handleSave = async () => {
    const inputData = {
      oldPassword: currentPassword,
      newPassword: newPassword,
      retypedPassword: retypedPassword,
    };

    const passwordsCheck = checkPasswordsValidity(inputData);
    if (!passwordsCheck.validity) {
      toast.error(passwordsCheck.errorMessage);
      return;
    }

    try {
      await axios.put(`/api/users/${user.username}/password`, {
        currentPassword: currentPassword,
        newPassword: newPassword,
      });
      toast.success('Your password has been updated');
      handleCancel();
      fetchUserData();
    } catch (err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.errorType === 'passwordMismatch'
      ) {
        toast.error(err.response.data.message);
        // Handle specific password mismatch error
      } else {
        toast.error(`Error: ${err.message}`);
        // Handle other errors
      }
    }
  };

  return (
    <FormCard title="Edit Password">
      {user ? (
        <>
          <FormInputWrapper>
            <PasswordField
              field={{ id: 'currentPassword', name: 'Current Password' }}
              value={currentPassword}
              onChange={handlePasswordChange(setCurrentPassword)}
              disabled={!editMode}
            />

            <PasswordField
              field={{ id: 'newPassword', name: 'New Password' }}
              value={newPassword}
              onChange={handlePasswordChange(setNewPassword)}
              disabled={!editMode}
            />

            <PasswordField
              field={{ id: 'retypedPassword', name: 'Re-type New Password' }}
              value={retypedPassword}
              onChange={handlePasswordChange(setRetypedPassword)}
              disabled={!editMode}
            />

            {editMode && (
              <Instruction
                field={{
                  subtype: 'withLink',
                  message: ` Passwords must be between 8 and 24 characters with at least one
              number and at least one lower and uppercase letter`,
                }}
              />
            )}

            <EditButtons
              editMode={editMode}
              onCancel={handleCancel}
              onSave={handleSave}
              onEdit={handleEdit}
            />
          </FormInputWrapper>
        </>
      ) : (
        <>Loading...</>
      )}
    </FormCard>
  );
};

export default EditPassword;
