import React from 'react';
import styled from 'styled-components';
import PageCard from '../../components/PageCard.js';
import SimpleCard from '../../components/SimpleCard.js';
import { Link } from 'react-router-dom';

export default function HelpDashboard() {
  return (
    <PageCard title="Help" back line maxContent>
      <WelcomeDiv>
        <SimpleCard title="Welcome to the Help Section" size="md" />
        <WelcomeMessage>
          You can find answers to common questions about how to use AgroLedger
          here. If the question you have is not answered here, please contact
          us.
        </WelcomeMessage>
      </WelcomeDiv>

      <HelpSection>
        <HelpSectionHeader>What would you like to do?</HelpSectionHeader>

        <HelpSectionLink>
          <Link to="/help/animaltagging">
            I want to report tagging of an animal
          </Link>
        </HelpSectionLink>

        <HelpSectionLink>
          <Link to="/help/animaldeath">
            I want to report the death of an animal
          </Link>
        </HelpSectionLink>

        <HelpSectionLink>
          <Link to="/help/leavepremise">
            I want to report animal(s) leaving my premises
          </Link>
        </HelpSectionLink>

        <HelpSectionLink>
          <Link to="/help/receivepremise">
            I want to report receiving animals at my premises
          </Link>
        </HelpSectionLink>

        <HelpSectionLink>
          <Link to="/help/animalmove">
            I am moving animals between my premises
          </Link>
        </HelpSectionLink>

        <HelpSectionLink>
          <Link to="/help/import">I want to report imported animals</Link>
        </HelpSectionLink>

        <HelpSectionLink>
          <Link to="/help/export">I want to report exported animals</Link>
        </HelpSectionLink>
      </HelpSection>
    </PageCard>
  );
}

const WelcomeDiv = styled.div`
  border-radius: 7px;
  background: green;
  color: white;
  width: 800px;
  margin: 20px auto;
  padding: 0.5em;
  box-shadow: 0 10px 7px rgb(25, 25, 25);
`;

const WelcomeMessage = styled.div`
  font-style: italic;
  font-size: 18px;
  padding: 1em;
`;

const HelpSection = styled.div`
  width: 800px;
  margin: 3em auto;
  color: black;
  font-size: 16px;
`;

const HelpSectionHeader = styled.h3`
  margin: 0;
  padding: 12px;
  background: rgb(211, 211, 211);
  font-size: 22px;
  border-bottom: 2px solid grey;
`;

const HelpSectionLink = styled.div`
  background: white;
  padding: 12px 15px;
  border-bottom: 1px solid #ddd;
  transition: background 0.3s ease;
  border: 2px solid #ddd;

  &:hover {
    background: #f0f0f0;
  }
`;
