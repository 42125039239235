import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFormFields from '../../Fields/AddExportFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import { createExportReport } from '../../utils/ExportUtils.js';

export default function AddExport({ animals, premises, vehicles }) {
  const [redirect, setRedirect] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [selectedSheep, setSelectedSheep] = useState([]);

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  const handleSelectionChange = (selectedSheep) => {
    if (!formFields) {
      return;
    }
    setSelectedSheep(selectedSheep);
    let isValid = selectedSheep.length > 0;
    setValidFieldsInCurrentStep(isValid);
  };

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(
          premises,
          vehicles,
          setSelectedVehicle,
          animals,
          setSelectedSheep,
          selectedSheep,
          handleSelectionChange,
        );
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [animals, premises, vehicles]);

  useEffect(() => {
    // Check validity
    if (formFields && selectedVehicle) {
      const updatedFields = [...formFields];
      let isValid = FormUtils.checkRequiredFieldsValidity(
        updatedFields[currentStep].fields,
      );
      // Update validity logic here
      setValidFieldsInCurrentStep(isValid);
      // Update the isValid flag for the current step in formFields
      updatedFields[currentStep].isValid = isValid;
      // Update the form fields
      updateFields(updatedFields);
      FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
    }
  }, [selectedVehicle]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Handle Changes in date, by checking validity overall
          if (fieldToUpdate.id === 'departureDate') {
            const timeField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'departureTime',
            );
            FormUtils.checkFieldInputValidity(
              timeField,
              updatedFields[currentStep].fields,
            );
          } else if (fieldToUpdate.id === 'departureTime') {
            const dateField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'departureDate',
            );
            FormUtils.checkFieldInputValidity(
              dateField,
              updatedFields[currentStep].fields,
            );
          } else if (fieldToUpdate.id === 'departurePID') {
            sessionStorage.setItem('pid', value);
          }
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );

          if (currentStep === 0) {
            isValid = isValid && selectedVehicle;
          }
          if (currentStep === 1) {
            isValid =
              isValid &&
              selectedSheep !== undefined &&
              selectedSheep.length > 0;
          }

          setValidFieldsInCurrentStep(isValid);

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    let isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );

    setValidFieldsInCurrentStep(isValid);

    if (currentStep === 1) {
      const validity = selectedSheep && selectedSheep.length > 0;
      isValid = isValid && validity;
      formFields[1].isValid = isValid && validity;
    }

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStepData.isValid || stepIndex < currentStep) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      let nextValid = FormUtils.checkRequiredFieldsValidity(
        formFields[nextStep].fields,
      );
      formFields[nextStep].isValid = nextValid;
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  /** Submit the Operation to the Database */
  const handleSubmit = async () => {
    const animalIds = selectedSheep.map((animal) => animal.tag.isoNumber);
    const formValues = FormUtils.flattenForm(formFields);
    try {
      setSubmitting(true);
      const localDepDateTime = new Date(
        `${formValues.departureDate}T${formValues.departureTime}`,
      );

      // Convert this local Date object to UTC
      const utcDepDateTime = new Date(localDepDateTime.toUTCString());
      const utcDepDateTimeString = utcDepDateTime.toISOString();

      const report = await createExportReport({
        departurePID: formValues.departurePID,
        destinationSite: formValues.destinationSite,
        destinationRegion: formValues.destinationRegion,
        location: formValues.location,
        animalTags: animalIds,
        departureTime: utcDepDateTimeString,
        vehicleNumber: selectedVehicle.licensePlateNum || selectedVehicle,
      });
      setSubmitting(false);
      toast.dismiss();
      toast.success('Success: Report created');
      setRedirect(`/reports/export/${report._id}`);
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      if (err.invalidSheeps?.length > 0) {
        const ids = err.invalidSheeps.map(
          (s) =>
            s.tag.isoNumber ||
            s.tag.usScrapieId ||
            s.tag.localMgmtNumber ||
            s.tattoNumber,
        );
        toast.error(
          `The following sheep aren't on premise ${
            formValues.departurePID
          }: ${ids.join(', ')}`,
        );
      } else {
        toast.error(`Error submitting report: ${err.message}`);
      }
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create a new Export Report"
    />
  );
}
