// OperationRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import OperationsView from '../views/Operations/OperationsView';
import OperationCreate from '../views/Operations/OperationCreate';
import LearnMore from '../views/LearnMore';
import OperationUpdate from '../views/Operations/OperationUpdate';
import OperationView from '../views/Operations/OperationView';

const OperationRouter = () => (
  <Switch>
    <Route exact path="/operations" component={OperationsView} />
    <Route exact path="/operations/create" component={OperationCreate} />
    <Route exact path="/operations/learnMore" component={LearnMore} />
    <Route exact path="/operations/verify/:hash" component={OperationsView} />
    <Route path="/operations/:id/update" component={OperationUpdate} />
    <Route exact path="/operations/:id" component={OperationView} />
  </Switch>
);

export default OperationRouter;
