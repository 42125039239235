import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import {
  LinkButton,
  ContentContainer,
  Grid,
  ImageContainer,
  Icon,
  HorizontalContainer,
} from '../components/CommonComponents.js';
import { Link } from 'react-router-dom';
import { getUsername } from '../utils/TokenUtils.js';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';
import b1 from '../images/b1.jpg';
import sheep from '../images/icons/sheep.png';
import report from '../images/icons/report.png';
import premise from '../images/icons/premise.png';
import operation from '../images/icons/operation.png';
import vehicle from '../images/icons/vehicle.png';
import profile from '../images/icons/profile_large.png';
import feedback from '../images/icons/feedback.png';
import axios from 'axios';
import { getSelectedRole } from '../utils/RoleUtils.js';

const HomePage = () => {
  // eslint-disable-next-line
  const [currRole, setCurrRole] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);
  const [isTagManufacturer, setIsTagManufacturer] = useState(false);

  const fetchCurrentRole = async () => {
    const temp = await axios.get('/api/users/role');
    return temp;
  };

  const fetchAndSetRoleInfo = async () => {
    try {
      let fetchedRole = await fetchCurrentRole();
      setCurrRole(fetchedRole.data);
      if (
        fetchedRole.data.some((r) => r.roleType == 'admin') &&
        getSelectedRole() === 'admin'
      ) {
        setIsAdmin(true);
      }
      if (
        fetchedRole.data.some((r) => r.roleType === 'tag manufacturer') &&
        getSelectedRole() === 'tag manufacturer'
      ) {
        setIsTagManufacturer(true);
      }
    } catch (err) {
      throw err;
    }
  };

  useEffect(() => {
    fetchAndSetRoleInfo();
  }, []);

  return (
    <Grid
      templateColumns={'calc(40% - 30px) calc(60% - 30px)'}
      tablet={
        'display: flex; flex-direction: column; width: 100%; align-items: flex-start;'
      }
      gap={'60px'}
    >
      <h1
        style={{
          gridColumn: '1 / span 2',
          fontSize: 'xxx-large',
          marginBottom: '0px',
        }}
      >
        Home
      </h1>
      <DashboardContainer
        $stylePreset={'borderless'}
        tablet={'display: none;'}
        style={{ boxShadow: '5px 5px 20px #00000029' }}
      >
        <ImageContainer
          src={b1}
          width={'100%'}
          style={{ borderRadius: '25px' }}
        />
        <h1 style={{ fontWeight: 'normal', fontSize: '40px' }}>
          Agro
          <span style={{ fontWeight: 'bold', color: `${COLORS.primary}` }}>
            Ledger
          </span>
        </h1>
        <h3 style={{ fontWeight: 'normal', marginBlock: '0px' }}>
          A new way to track.
        </h3>
      </DashboardContainer>
      <Grid
        templateColumns={'calc(50% - 10px) calc(50% - 10px)'}
        style={{
          background: `${COLORS.secondary}`,
          borderRadius: '15px',
          padding: '40px',
          gridRowEnd: 'span 3',
        }}
        mobile={'width: 100vw; align-self: center; align-items: flex-start;'}
      >
        <div style={{ gridColumn: '1 / span 2' }}>
          <h1 style={{ fontSize: 'calc(1vw + 25px)', marginTop: '0px' }}>
            Dashboard
          </h1>
          <h1 style={{ fontWeight: 'normal', fontSize: 'calc(1vw + 15px)' }}>
            Welcome, {getUsername()}
          </h1>
        </div>
        <h3 style={{ gridColumn: '1 / span 2' }}>Manage Your Sheep</h3>
        <DashboardContainer $stylePreset={'bold'}>
          <IconHeaderContainer>
            <DashboardIcon src={sheep} />
            <h3>Sheep</h3>
          </IconHeaderContainer>
          <p>
            View, add and edit information about your sheep on the Sheep
            dashboard here.
          </p>
          <DashboardButton $stylePreset={'dark'} to="/sheep">
            Sheep
          </DashboardButton>
        </DashboardContainer>
        <DashboardContainer $stylePreset={'bold'}>
          <IconHeaderContainer>
            <DashboardIcon src={report} />
            <h3>Reports</h3>
          </IconHeaderContainer>
          <p>View and create event reports on the Reports dashboard here.</p>
          <DashboardButton $stylePreset={'dark'} to="/reports">
            Reports
          </DashboardButton>
        </DashboardContainer>
        {isAdmin && (
          <DashboardContainer $stylePreset={'borderless'}>
            <h3>Traceability</h3>
            <p>Trace a sheep’s movement history here.</p>
            <DashboardButton $stylePreset={'dark-text'} to="/trace">
              Trace
            </DashboardButton>
          </DashboardContainer>
        )}
        {isAdmin && (
          <DashboardContainer $stylePreset={'borderless'}>
            <h3>View Users</h3>
            <p>Look up user profiles here.</p>
            <DashboardButton $stylePreset={'dark-text'} to="/users">
              Users
            </DashboardButton>
          </DashboardContainer>
        )}
        {(isAdmin || isTagManufacturer) && (
          <DashboardContainer
            $stylePreset={'borderless'}
            style={!isAdmin ? { gridColumn: 'span 2' } : null}
          >
            <h3>Tag Allocation Requests</h3>
            <p>Create a tag allocation request and view previous requests.</p>
            <DashboardButton
              $stylePreset={'dark-text'}
              style={{ marginRight: '10px' }}
              to="/tagallocation/requests"
            >
              Requests
            </DashboardButton>
          </DashboardContainer>
        )}
        {isAdmin && (
          <DashboardContainer
            $stylePreset={'borderless'}
            style={!isAdmin ? { gridColumn: 'span 2' } : null}
          >
            <h3>Tag Allocation Review</h3>
            <p>View, approve and deny tag allocation requests from users.</p>
            <DashboardButton
              $stylePreset={'dark-text'}
              style={{ marginRight: '10px' }}
              to="/tagallocation/reviews"
            >
              Review
            </DashboardButton>
          </DashboardContainer>
        )}
        <DashboardContainer
          $stylePreset={'bold'}
          style={{ gridRowEnd: 'span 2' }}
        >
          <h3>Manage Your Holdings</h3>
          <p>Look up operations, premises and vehicle information here.</p>
          <DashboardButton
            $stylePreset={'dark-text'}
            style={{ paddingBlock: '4px', width: '200px' }}
            to="/premises"
          >
            <IconHeaderContainer>
              Premises
              <HoldingsIcon src={premise} />
            </IconHeaderContainer>
          </DashboardButton>
          <DashboardButton
            $stylePreset={'dark-text'}
            style={{ paddingBlock: '4px', width: '200px' }}
            to="/operations"
          >
            <IconHeaderContainer>
              Operations
              <HoldingsIcon src={operation} />
            </IconHeaderContainer>
          </DashboardButton>
          <DashboardButton
            $stylePreset={'dark-text'}
            style={{ paddingBlock: '4px', width: '200px' }}
            to="/vehicles"
          >
            <IconHeaderContainer>
              Vehicles
              <HoldingsIcon src={vehicle} />
            </IconHeaderContainer>
          </DashboardButton>
        </DashboardContainer>
        <DashboardContainer $stylePreset={'secondary'}>
          <IconHeaderContainer>
            <DashboardIcon src={profile} />
            <h3>My Profile</h3>
          </IconHeaderContainer>
          <p>View and edit your user account details here.</p>
          <DashboardButton
            $stylePreset={'dark-text'}
            to={`/users/${getUsername()}`}
          >
            Profile
          </DashboardButton>
        </DashboardContainer>
        <DashboardContainer $stylePreset={'secondary'}>
          <IconHeaderContainer>
            <DashboardIcon src={feedback} />
            <h3>Feedback</h3>
          </IconHeaderContainer>
          <p>
            Do you have suggestions for improving your AgroLedger experience?
            Send us your feedback here.
          </p>
          <DashboardButton $stylePreset={'dark-text'} to="/feedback">
            Feedback
          </DashboardButton>
        </DashboardContainer>
      </Grid>
      <Grid templateColumns={'calc(50% - 10px) calc(50% - 10px)'}>
        <h3 style={{ gridColumn: '1 / span 2' }}>Resources</h3>
        <DashboardContainer>
          <h3>Need Help?</h3>
          <p>
            You can find answer to common questions about how to use AgroLedger
            here.
          </p>
          <DashboardButton $stylePreset={'dark-text'} to="/help">
            Help
          </DashboardButton>
        </DashboardContainer>
        <DashboardContainer>
          <h3>Contact Us</h3>
          <p>Have any questions or need to contact us? Click here.</p>
          <DashboardButton $stylePreset={'dark-text'} to="/contact">
            Contact
          </DashboardButton>
        </DashboardContainer>
        <DashboardContainer style={{ gridColumn: '1 / span 2' }}>
          <h3>How Transaction Verification Works</h3>
          <p>
            Transaction verification adds confidence and value to your reports.
            Learn more here.
          </p>
          <Link to="/verify" style={{ textDecoration: 'none' }}>
            <h3>Learn more &#8594;</h3>
          </Link>
        </DashboardContainer>
      </Grid>
    </Grid>
  );
};

const DashboardContainer = styled(ContentContainer)`
  height: 100%;
`;

const DashboardButton = styled(LinkButton)`
  margin-top: auto;
`;

const IconHeaderContainer = styled(HorizontalContainer)`
  justify-content: flex-start;
  margin: 0px;

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

const DashboardIcon = (props) => {
  return <Icon src={props.src} hover={'none'} m={'none'} mr={'10px'} />;
};

DashboardIcon.propTypes = {
  src: PropTypes.any,
};

const HoldingsIcon = (props) => {
  return (
    <Icon
      src={props.src}
      hover={'none'}
      m={'none'}
      width={'36px'}
      ml={'auto'}
    />
  );
};

HoldingsIcon.propTypes = {
  src: PropTypes.any,
};

export default HomePage;
