import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import VehicleRelatedMovesComponent from '../components/VehicleRelatedMovesComponent';
import { localTimeFormatter } from '../utils/TimeUtils';
import { BackLink, ContentContainer } from '../components/CommonComponents.js';

const ReportsSingleView = styled(ContentContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
`;

export default function VehicleMoveHistoryListView(props) {
  const history = useHistory();
  const vehicleID = props.match.params.vehicleId;

  const moveOutColumns = [
    {
      id: 'type',
      name: 'Type',
    },
    {
      id: 'departureDate',
      name: 'Date of Departure',
    },
    {
      id: 'departurePremise',
      name: 'Departure',
    },
    {
      id: 'destinationPremise',
      name: 'Destination',
    },
    {
      id: 'animalTags',
      name: 'Moved Out Animals',
    },
    {
      id: 'actions',
      name: 'Actions',
    },
  ];

  const fetchAndTransformMoveLogs = async () => {
    const response = await getMoveLogsForVehicle(vehicleID);
    const movements = response.data;
    let styledMovements = movements.map((moveLog) => ({
      ...moveLog,
      departurePremise: moveLog.departurePID ? moveLog.departurePID : '',
      destinationPremise: moveLog.destinationPID ? moveLog.destinationPID : '',
      departureDate: moveLog.departureTime
          ? localTimeFormatter(moveLog.departureTime)
          : '',
      loadTime: moveLog.loadTime
      ? localTimeFormatter(moveLog.loadTime)
      : '',
      receivedTime: moveLog.receivedTime
          ? localTimeFormatter(moveLog.receivedTime)
          : '',
      type: moveLog.moveOut ? 'Move-Out' : 'Move-In',
    }));
    return styledMovements;
  };
  const customBack = () => {
    history.goBack();
  };

  return (
    <div>
      <h1
        style={{
          gridColumn: '1 / span 2',
          fontSize: 'xxx-large',
          marginRight: 'auto',
          marginBottom: '18px',
          marginTop: '30px',
        }}
      >
        Move Reports for {vehicleID}
      </h1>
      <BackLink/>
      <VehicleRelatedMovesComponent
        title="Move History"
        description="Here is a list of movements history for the related vehicles of a specific sheep."
        fetchReportsFunction={fetchAndTransformMoveLogs}
        detailEndpoint={{
          moveout: '/reports/moveout/',
          movein: '/reports/movein/',
        }}
        columns={moveOutColumns}
        vehicleNumber={vehicleID}
      />
    </div>
  );
}
