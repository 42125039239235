// SheepImportNew.js
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  Button,
  ContentContainer,
  BackLink,
  VerticalContainer,
} from '../../components/CommonComponents.js';
import styled from 'styled-components';
import { extractDataFromHeaders, parseAsync } from '../../utils/CSVUtils';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { COLORS } from '../../utils/Constants';
import Table from '../../components/Table';
import { PageHeader } from '../../components/PageComponents.js';
import { FetchPremises } from '../../components/SheepReportComponents';

const CheckBox = ({ onChange, checked }) => {
  return <input type="checkbox" checked={checked} onChange={onChange} />;
};

CheckBox.propTypes = {
  onChange: PropTypes.any,
  checked: PropTypes.any,
};

const extractDateFromFormat = (date, format) => {
  const formatSplit = format.split(/[-/]/);
  const dateSplit = date.split(/[-/]/);
  const month = dateSplit[formatSplit.findIndex((format) => format === 'MM')];
  const day = dateSplit[formatSplit.findIndex((format) => format === 'DD')];
  const year = dateSplit[formatSplit.findIndex((format) => format === 'YYYY')];
  return month + '/' + day + '/' + year;
};

const buildSheepForSubmit = (sheeps, dateFormat) => {
  let sheepsForSubmit = [];
  sheeps.forEach((s) => {
    // remove any spaces and commas from the ISO number

    const newSheep = {
      errorID: s.dummyID,
      tag: {
        localMgmtNumber: s.localMgmtNumber,
        isoNumber: s.isoNumber,
        tattooNumber: s.tattooNumber,
        usScrapieId: s.usScrapieId,
        activeDate: s.activeDate
          ? extractDateFromFormat(s.activeDate, dateFormat)
          : null,
      },
      gender: s.gender,
      subgender: s.subgender ? s.subgender.toLowerCase() : undefined,
      birthdate: s.birthdate
        ? extractDateFromFormat(s.birthdate, dateFormat)
        : null,
      breed: s.breed,
      isExported: false,
    };
    Object.keys(newSheep.tag).forEach(
      (key) => newSheep.tag[key] === undefined && delete newSheep.tag[key],
    );
    Object.keys(newSheep).forEach(
      (key) => newSheep[key] === undefined && delete newSheep[key],
    );
    sheepsForSubmit.push(newSheep);
  });
  return sheepsForSubmit;
};

const bulkSheepSubmit = async (submissionObject) => {
  try {
    await axios.post(`/api/sheep/bulk`, submissionObject);
    toast.info(
      'Submitting sheep to the backend. It may take up to a minute. You will be redirected when the sheep have been created. Please stay on this page.',
    );
    toast.success('Sheep created successfully');
    return { success: true };
  } catch (err) {
    return {
      success: false,
      errors: err.response.data.message,
    };
  }
};

const DropdownDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-block: 15px;
`;

const PremiseSelect = styled.select`
  margin-left: 5px;
  padding: 5px 10px;
  height: min-content;
  border: ${COLORS.primary} 2px solid;
  border-radius: 15px;
`;

const CenteredButtonDiv = styled.div`
  text-align: center;
`;

const SheepImport = ({ data, headers }) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [premises, setPremises] = useState([]);
  const [selectedPremise, setSelectedPremise] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({});

  const fetchAndSetPremises = async () => {
    const fetchedPremises = await FetchPremises();
    setPremises(fetchedPremises);
  };

  useEffect(() => {
    var parsedData = data.filter((data) => {
      const values = Object.values(data);
      for (const value of values) {
        if (value !== '') return true;
      }
      return false;
    });
    parsedData = extractDataFromHeaders(parsedData, headers);
    parsedData = parsedData.map((data) => {
      if (data.isoNumber && data.isoNumber.length < 15 && headers.csipPrefix)
        data.isoNumber = headers.csipPrefix + data.isoNumber;
      return data;
    });
    setSheeps(parsedData);
    fetchAndSetPremises();
  }, [data, headers]);

  useEffect(() => {
    if (selectedSheeps.length === 0 || selectedPremise === '') {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [selectedSheeps, selectedPremise]);

  const columns = [
    '',
    'CSIP Number',
    'Local Management Number',
    'Tattoo Number',
    'US Scrapie ID',
    'Gender',
    'Sub Gender',
    'DOB',
    'Breed',
  ];

  const columnIds = [
    'isoNumber',
    'localMgmtNumber',
    'tattooNumber',
    'usScrapieId',
    'gender',
    'subgender',
    'birthdate',
    'breed',
  ];

  const handleSubmit = async (e) => {
    var errors = null;
    try {
      e.preventDefault();
      setSubmitting(true);

      const sheepsToSubmit = buildSheepForSubmit(
        selectedSheeps,
        headers.dateFormat,
      );
      const submissionObject = {
        sheep: sheepsToSubmit,
        premise: selectedPremise,
      };

      const res = await bulkSheepSubmit(submissionObject);
      if (res.success) setRedirect('/sheep');
      else {
        try {
          errors = JSON.parse(res.errors);
        } catch (err) {
          throw new Error(res.errors);
        }
        throw new Error('Errors occurred while submitting');
      }
    } catch (err) {
      if (errors) setErrors(errors);
      toast.error(err.message);
      setSubmitting(false);
    }
  };

  const SelectAllSection = () => (
    <CenteredButtonDiv>
      <Button
        style={{ marginRight: '10px' }}
        onClick={(e) => {
          e.preventDefault();
          setSelectedSheeps(sheeps);
        }}
      >
        Select All
      </Button>
      {selectedSheeps.length > 0 ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelectedSheeps([]);
          }}
        >
          Clear All
        </Button>
      ) : (
        <></>
      )}
    </CenteredButtonDiv>
  );
  const sheepRowsWithCheck = sheeps.map((s) => ({
    check: (
      <CheckBox
        checked={
          selectedSheeps.filter(
            (selectedSheep) => selectedSheep.dummyID === s.dummyID,
          ).length > 0
        }
        onChange={() => {
          const alreadySelected =
            selectedSheeps.filter(
              (selectedSheep) => selectedSheep.dummyID == s.dummyID,
            ).length > 0;
          if (alreadySelected) {
            const newSelectedSheeps = selectedSheeps.filter(
              (sheep) => sheep.dummyID !== s.dummyID,
            );
            setSelectedSheeps(newSelectedSheeps);
          } else {
            const newSelectedSheeps = [
              sheeps.filter((sheep) => sheep.dummyID === s.dummyID)[0],
              ...selectedSheeps,
            ];
            setSelectedSheeps(newSelectedSheeps);
          }
        }}
      />
    ),
    error: errors[s.dummyID] ? errors[s.dummyID].join(', ') : null,
  }));
  for (let i = 0; i < sheeps.length; i++) {
    for (const columnId of columnIds) {
      sheepRowsWithCheck[i][columnId] = sheeps[i][columnId]
        ? sheeps[i][columnId]
        : '-';
    }
  }

  if (redirect !== '') {
    return <Redirect to={redirect} />;
  }

  return (
    <VerticalContainer
      width="100%"
      align_items="flex-start"
      justify_content="flex-start"
    >
      <PageHeader>Sheep Bulk Import</PageHeader>
      <br />
      <BackLink to="/sheep" label="Return to sheep dashboard" />
      <br />
      <DropdownDiv>
        <h3>Premise for Sheep:</h3>
        <PremiseSelect
          value={selectedPremise}
          onChange={(e) => {
            setSelectedPremise(e.target.value);
          }}
        >
          <option value="" hidden>
            Select a premise
          </option>
          {premises.map((p) => (
            <>
              <option
                value={p._id}
                key={p._id}
              >{`${p.name} - ${p.pid}`}</option>
            </>
          ))}
        </PremiseSelect>
      </DropdownDiv>
      <ContentContainer
        $stylePreset="secondary-borderless"
        tablet="padding-inline: 0px; width: 100vw !important;"
        style={{ alignSelf: 'center' }}
      >
        <ContentContainer $stylePreset="light" alignItems="center">
          <h1 style={{ alignSelf: 'flex-start' }}>Select Sheep</h1>
          <Table
            columns={columns}
            rows={sheepRowsWithCheck}
            maxShowing={10}
            paginate={true}
          />
          <SelectAllSection />
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={handleSubmit}
              disabled={disabled || submitting}
              $stylePreset="bold"
              width="250px"
            >
              Submit {`${selectedSheeps.length}`} Sheep
            </Button>
          </div>
        </ContentContainer>
      </ContentContainer>
    </VerticalContainer>
  );
};

SheepImport.propTypes = {
  data: PropTypes.shape({
    isoNumber: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
  headers: PropTypes.shape({
    csipPrefix: PropTypes.any,
    dateFormat: PropTypes.any,
  }),
};

export default SheepImport;
