import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextInputField from './TextInputField'; // Adjust the import path as per your project structure

const PasswordField = ({ field, onChange, value, disabled }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  return (
    <>
      <TextInputField
        field={field}
        onChange={onChange}
        value={value}
        type={showPassword ? 'text' : 'password'}
        showPeek={showPassword}
        onTogglePeek={togglePasswordVisibility}
        disabled={disabled}
      />
    </>
  );
};

PasswordField.propTypes = {
  field: PropTypes.shape({
    disabled: PropTypes.bool,
    hint: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
  }).isRequired,
  value: PropTypes.string,

  onChange: PropTypes.func.isRequired,
};

export default PasswordField;
