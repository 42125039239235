import axios from 'axios';
import { getSelectedRole } from './RoleUtils';

export const getImportReports = () => {
  return axios
    .get('/api/import', { params: { selectedRole: getSelectedRole() } })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const getImportReportsById = (id) => {
  return axios
    .get(`/api/import/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createImportReport = (data) => {
  return axios
    .post('/api/import', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};
