/**
 * Returns a sliced string if string exceeds the specified length
 * @param {string} str - string to be potentially sliced
 * @param {number} len - maximum length of string
 */
export const stringSlice = (str, len) => {
  if (str.length > len) {
    return str.slice(0, len).trim() + '..';
  }
  return str;
};

//returns true if object passed is a string literal or string object
export const isString = (str) => {
  return (typeof str === 'string' || str instanceof String);
}

// converts boolean to Yes/No string
// true => "Yes", false => "No"
export const boolToYN = (bool) => {
  if (bool) {
    return 'Yes';
  }
  else{
    return 'No';
  }
};
