import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import PropTypes from 'prop-types';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';
import styled from 'styled-components';
import { getAllSheeps } from '../../utils/SheepUtils';
import { localDateFormatter, localTimeFormatter } from '../../utils/TimeUtils'

const treatedSheepColumns = [
  {
    id: 'isoNumber',
    name: 'Sheep ISO Number',
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
  },
  {
    id: 'gender',
    name: 'Gender',
  },
  {
    id: 'breed',
    name: 'Breed',
  },
];

const medicineColumns = [
  {
    id: 'medicine',
    name: 'Product Name',
  },
  {
    id: 'dosage',
    name: 'Dosage',
  },
  {
    id: 'isPrescription',
    name: 'Prescription',
  },
  {
    id: 'expiryDate',
    name: 'Expiry Date',
  },
  {
    id: 'route',
    name: 'Route',
  },
];

const fetchMedicalReportInfo = async (id) => {
  try {
    const request = await axios.get(`/api/animalmedical/${id}`);
    return request.data;
  } catch (err) {
    console.log(err);
    throw new Error(`An error occurred fetching report info: ${err.message}`);
  }
};

const Info = ({ name, value }) => {
  return (
    <div>
      <span>{name}:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
};

Info.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
};

const MedicalReportDetailView = () => {
  const [reportInfo, setReportInfo] = useState();
  const [medicineInfo, setMedicineInfo] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);
  const [sheepList, setSheepList] = useState([]);
  const [reportLocation, setReportLocation] = useState(null);
  const params = useParams();
  const id = params.id;

  const fetchAndSetReportInfo = async () => {
    try {
      let fetchedReportInfo = await fetchMedicalReportInfo(id);

      if (!Array.isArray(fetchedReportInfo.medicine)) {
        setReportInfo({
          ...fetchedReportInfo,
          medicine: [fetchedReportInfo.medicine],
          dosage: [fetchedReportInfo.dosage],
          isPrescription: [fetchedReportInfo.isPrescription],
          expiryDate: [fetchedReportInfo.expiryDate],
          route: [fetchedReportInfo.route],
        });
      }

      setReportInfo({
        ...fetchedReportInfo,
        createdAt: localTimeFormatter(fetchedReportInfo.createdAt),
        firstTreatmentDate: localDateFormatter(fetchedReportInfo.firstTreatmentDate),
        finalTreatmentDate: localDateFormatter(fetchedReportInfo.finalTreatmentDate),
        meatWithdrawalDate: localDateFormatter(fetchedReportInfo.meatWithdrawalDate),
        milkWithdrawalDate: localDateFormatter(fetchedReportInfo.milkWithdrawalDate),
      });

      // if this report has a geolocation, save that to a state as well
      if (
        fetchedReportInfo.location &&
        Array.isArray(fetchedReportInfo.location.coordinates) &&
        fetchedReportInfo.location.coordinates.length === 2
      ) {
        const longlat = fetchedReportInfo.location.coordinates;
        setReportLocation([longlat[1], longlat[0]]);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchAndSetReportInfo().then(() => {
      return getAllSheeps().then((allSheeps) => {
        setAllAnimals(
          allSheeps.map((sheep) => ({
            ...sheep,
            isoNumber: sheep.tag.isoNumber ? sheep.tag.isoNumber : <NAField />,
            localMgmtNumber: sheep.tag.localMgmtNumber ? (
              sheep.tag.localMgmtNumber
            ) : (
              <NAField />
            ),
            tattooNumber: sheep.tag.tattooNumber ? (
              sheep.tag.tattooNumber
            ) : (
              <NAField />
            ),
            gender: sheep.gender ? sheep.gender : <NAField />,
            breed: sheep.breed ? sheep.breed : <NAField />,
          })),
        );
      });
    });
  }, []);

  useEffect(() => {
    if (reportInfo) {
      let sheeps = [];
      reportInfo.animals.forEach((r) => {
        const animal = allAnimals.find((a) => a._id === r._id);
        if (animal) {
          sheeps.push(animal);
        }
      });
      setSheepList(sheeps);
    }
  }, [allAnimals]);

  useEffect(() => {
    if (reportInfo && medicineInfo.length === 0) {
      const numTreatments = reportInfo.medicine.length;
      let treatments = [];

      for (let i = 0; i < numTreatments; i++) {
        let medicine = {
          medicine: reportInfo.medicine[i],
          dosage: reportInfo.dosage[i],
          isPrescription: reportInfo.isPrescription[i] ? 'Yes' : 'No',
          expiryDate: localDateFormatter(reportInfo.expiryDate[i]),
          route: reportInfo.route[i],
        };
        treatments.push(medicine);
      }
      setMedicineInfo(treatments);
    }
  }, [reportInfo]);

  return (
    <>
      <PageCard
        title="Medical Report Details"
        verify={`/verify/medical/${id}`}
        back
      >
        {reportInfo ? (
          <>
            <SimpleCard title="Report Information">
              <InfoDiv>
                <Info name="Created At" value={reportInfo.createdAt} />
                <Info name="Sheep Premise ID" value={reportInfo.premiseID} />
                <Info
                  name="Treatment Start Date"
                  value={reportInfo.firstTreatmentDate}
                />
                <Info
                  name="Treatment End Date"
                  value={reportInfo.finalTreatmentDate}
                />
              </InfoDiv>
              <InfoDiv>
                <Info
                  name="Reason for Treatment"
                  value={reportInfo.treatmentReason}
                />
                <Info
                  name="Estimated Sheep Weight"
                  value={reportInfo.animalWeight}
                />
                <Info
                  name="Number of Sheep Treated"
                  value={reportInfo.numberOfAnimals}
                />
                <br></br>
              </InfoDiv>
            </SimpleCard>

            {reportLocation && (
              <>
                <SimpleCard title="Location" size="md">
                  <InfoDiv>
                    <Info name="Latitude" value={reportLocation[0]} />
                  </InfoDiv>
                  <InfoDiv>
                    <Info name="Longitude" value={reportLocation[1]} />
                  </InfoDiv>
                </SimpleCard>
                <br></br>
              </>
            )}

            <SimpleCard title="Treated Sheep" size="md"></SimpleCard>
            <StripedTable
              columns={treatedSheepColumns}
              rows={sheepList}
              ShowCount
              max={10}
              paginate
            />
            <SimpleCard title="Treatment Information" size="md">
              <TreatmentTable columns={medicineColumns} rows={medicineInfo} />
              <Info
                name="Withdrawal Date of Meat"
                value={reportInfo.meatWithdrawalDate}
              />
              <Info
                name="Withdrawal Date of Milk"
                value={reportInfo.milkWithdrawalDate}
              />
            </SimpleCard>
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const TreatmentTable = ({ rows, columns }) => {
  if (!rows) rows = [];
  if (!columns) columns = [];

  return (
    <StripedTableDiv>
      <StyledTable>
        <thead>
          <tr>
            {columns.map((c) => (
              <StyledTh key={c.id}>{c.name}</StyledTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((r, rowIdx) => {
            return (
              <tr key={rowIdx}>
                {columns.map((c, colIdx) => {
                  return (
                    <StyledTd key={`${rowIdx}-${colIdx}`}>
                      {Array.isArray(r[`${c.id}`])
                        ? r[`${c.id}`].map((item, idx) => (
                            <div key={idx}>{item}</div>
                          ))
                        : r[`${c.id}`]}
                    </StyledTd>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </StyledTable>
    </StripedTableDiv>
  );
};

TreatmentTable.propTypes = {
  columns: PropTypes.shape({
    map: PropTypes.func,
  }),
  rows: PropTypes.shape({
    map: PropTypes.func,
  }),
};

const InfoDiv = styled.div`
  width: 50%;
  display: inline-block;
  margin-bottom: 10px;
`;
const StripedTableDiv = styled.div`
  margin-bottom: 20px;
`;
const StyledTable = styled.table`
  width: 100%;
  background-color: #424242;
  border-collapse: collapse;
  border: none;
  tr:nth-child(even) {
    background-color: #515151;
  }
  td:last-child {
  }
  th:last-child {
  }
`;
const StyledTh = styled.th`
  text-align: left;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-top: 0.5rem;
  background-color: #17a737;
  width: 20ch;
`;
const StyledTd = styled.td`
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 20ch;
`;

export default MedicalReportDetailView;
