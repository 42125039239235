import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TraceDashboard from '../views/TraceDashboard';
import TraceSearch from '../views/TraceSearch';
import TraceSheepReportsList from '../views/TraceSheepReportsList';
import TraceSheepDetailsView from '../components/TraceSheepDetailsView';

const TraceRouter = () => (
  <Switch>
    <Route exact path="/trace" component={TraceDashboard} />
    <Route exact path="/trace/:isoNumber" component={TraceSheepDetailsView} />
    <Route exact path="/trace/search" component={TraceSearch} />
    <Route
      exact
      path="/trace/reports/:isoNumber"
      component={TraceSheepReportsList}
    />
  </Switch>
);

export default TraceRouter;
