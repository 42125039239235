import React, { useState } from 'react';
import styled from 'styled-components';
import { convertAndParseXLS, parseAndSetData } from '../../utils/CSVUtils';
import Form from '../../components/Form.js';
import Table from '../../components/Table.js';
import {
  VerticalContainer,
  BackLink,
  ContentContainer,
  HorizontalContainer,
} from '../../components/CommonComponents';
import SheepImport from './SheepImport';
import { Redirect } from 'react-router-dom';
import { PageHeader } from '../../components/PageComponents.js';
import { COLORS } from '../../utils/Constants.js';
import FileUpload from '../../components/FileUpload.js';

export default function SheepCsvImportView() {
  const [headings, setHeadings] = useState([]);
  const [headers, setHeaders] = useState({});
  const [fileName, setFileName] = useState('');
  const [data, setData] = useState();
  const [next, setNext] = useState(false);
  const [redirect, _] = useState();

  const fields = [
    {
      name: 'CSIP Prefix',
      id: 'csipPrefix',
    },
    {
      name: 'CSIP Number',
      id: 'isoNumber',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: headings,
      required: true,
      validityCheck: (formValues) => {
        if (
          formValues.isoNumber === 'N/A' &&
          formValues.localMgmtNumber === 'N/A' &&
          formValues.tattooNumber === 'N/A' &&
          formValues.usScrapieId === 'N/A'
        )
          return false;
        return true;
      },
    },
    {
      name: 'Local Managment Number',
      id: 'localMgmtNumber',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: headings,
      required: true,
    },
    {
      name: 'Tattoo Number',
      id: 'tattooNumber',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: headings,
      required: true,
    },
    {
      name: 'US Scrapie ID',
      id: 'usScrapieId',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: headings,
      required: true,
    },
    {
      name: 'Date of Birth',
      id: 'birthdate',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: headings,
      required: true,
    },
    {
      name: 'Date Format',
      id: 'dateFormat',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: [
        { name: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
        { name: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
        { name: 'YYYY-MM-DD', value: 'YYYY-MM-DD' },
        { name: 'MM-DD-YYYY', value: 'MM-DD-YYYY' },
      ],
      validityCheck: (fieldValues) => {
        if (
          fieldValues.dateFormat === '' &&
          fieldValues.birthdate !== 'N/A' &&
          fieldValues.birthdate !== ''
        )
          return false;
        return true;
      },
    },
    {
      name: 'Gender',
      id: 'gender',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: headings,
      required: true,
    },
    {
      name: 'Subgender',
      id: 'subgender',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: headings,
      required: true,
    },
    {
      name: 'Breed',
      id: 'breed',
      type: 'select',
      hint: 'Select',
      value: 'N/A',
      options: headings,
      required: true,
    },
  ];

  const fileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    setFileName('Upload CSV or Excel File: ' + uploadedFile.name);

    // Parse CSV or XLS file
    if (/.*\.(csv|CSV)$/.test(uploadedFile.name)) {
      parseAndSetData(uploadedFile, setHeadings, setData, true);
    } else if (/.*\.(xls|XLS).?$/.test(uploadedFile.name)) {
      convertAndParseXLS(uploadedFile, setHeadings, setData, true);
    }
  };

  const removeEmptyVals = (obj) => {
    let result = {},
      key;

    for (key in obj) {
      if (obj[key] && obj[key] != 'N/A') result[key] = obj[key];
    }
    return result;
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <VerticalContainer
      width="100%"
      align_items="flex-start"
      justify_content="flex-start"
    >
      {next ? (
        <SheepImport data={data} headers={removeEmptyVals(headers)} />
      ) : (
        <VerticalContainer
          width="100%"
          align_items="flex-start"
          justify_content="flex-start"
        >
          <PageHeader>Create Sheep</PageHeader>
          <br />
          <BackLink to="/sheep" label="Return to sheep dashboard" />
          <br />
          <h2>CSV Upload</h2>
          <HorizontalContainer tablet="flex-direction: row !important;">
            <FileUpload fileName={fileName} onFileChange={fileUpload} />
          </HorizontalContainer>
          {data && (
            <>
              <h2>File Preview</h2>
              <Table
                columns={Object.keys(data[0] || {})}
                rows={data}
                maxShowing={5}
                $stylePreset="boxyScrollable"
                paginate={true}
              />
            </>
          )}

          {headings.length > 0 && (
            <VerticalContainer width="100%" align_items="flex-start">
              <h2>Headers</h2>
              <h3 style={{ fontWeight: 'normal', marginTop: '0px' }}>
                <i>At least one identifier column must be specified.</i>
              </h3>
              <ContentContainer
                width="100%"
                alignItems="center"
                $stylePreset="borderless"
                style={{
                  boxShadow: '5px 5px 20px #00000029',
                  alignSelf: 'center',
                }}
                tablet="padding: 0px;"
              >
                <Form
                  title="Import Sheep"
                  fieldInfo={fields}
                  fieldFilter={(formValues) => {
                    if (['', 'N/A'].includes(formValues.birthdate))
                      return ['dateFormat'];
                    return [];
                  }}
                  onSubmit={(formValues) => {
                    setHeaders(formValues);
                    setNext(true);
                  }}
                />
              </ContentContainer>
            </VerticalContainer>
          )}
        </VerticalContainer>
      )}
    </VerticalContainer>
  );
}

const UploadButton = styled.label`
  padding: 5px 10px;
  background-color: ${COLORS.primary};
  color: white;
  border: ${COLORS.primary} 2px solid;
  border-radius: 20px;
  font-size: calc(8px + 0.7vw);

  &:hover {
    background-color: white;
    color: ${COLORS.primary};
  }
`;
