// TagAllocationRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TagAllocationCreateRequestView from '../views/TagAllocation/TagAllocationCreateRequestView';
import TagAllocationReviewListView from '../views/TagAllocation/TagAllocationReviewListView';
import TagAllocationReviewView from '../views/TagAllocation/TagAllocationReviewView';
import TagAllocationQueueView from '../views/TagAllocation/TagAllocationQueueView';
import TagAllocationRequestListView from '../views/TagAllocation/TagAllocationRequestListView';
import TagAllocationRequestView from '../views/TagAllocation/TagAllocationRequestView';

const TagAllocationRouter = () => (
  <Switch>
    {/* User Routes */}
    <Route
      exact
      path="/tagallocation/requests/create"
      component={TagAllocationCreateRequestView}
    />
    <Route
      exact
      path="/tagallocation/requests"
      component={TagAllocationRequestListView}
    />
    <Route
      exact
      path="/tagallocation/request/:id"
      component={TagAllocationRequestView}
    />
    {/* Admin Routes */}
    <Route
      exact
      path="/tagallocation/reviews"
      component={TagAllocationReviewListView}
    />
    <Route
      exact
      path="/tagallocation/review/:id"
      component={TagAllocationReviewView}
    />
    <Route
      exact
      path="/tagallocation/queue"
      component={TagAllocationQueueView}
    />
  </Switch>
);
export default TagAllocationRouter;
