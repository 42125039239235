import React, { useState, useEffect } from 'react';
import Form from '../../components/Form.js';
import {
  PopUpWindow,
  SubmittingSpinner,
} from '../../components/CommonComponents.js';
import {
  getAllProvinceAbbrv,
  getProvinceNameFromAbbrv,
  getAllStateAbbrv,
  getStateNameFromAbbrv,
} from '../../utils/Constants.js';
import { getVehicleById } from '../../utils/VehicleUtils.js';
import { getUserActiveOperations } from '../../utils/OperationUtils';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { getUsername } from '../../utils/TokenUtils.js';
import { toast } from 'react-toastify';
import SearchableDropdown from '../../components/SearchableDropdown';

const VehicleUpdateView = () => {
  const [operations, setOperations] = useState([]);
  const [vehicle, setVehicle] = useState({
    name: '',
    licensePlateNum: '',
    location: '',
    operation: {},
  });
  const [submitting, setSubmitting] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState({});
  const [redirect, setRedirect] = useState('');

  const { vehicleId } = useParams();

  const fetchOperations = async () => {
    try {
      const result = await getUserActiveOperations(getUsername());

      if (result) {
        const reducedOperations = result.map((o) => ({
          id: o._id,
          name: o.businessName,
        }));

        setOperations(reducedOperations);
      }
    } catch (error) {
      console.error('Error fetching operations:', error);
    }
  };

  const fetchVehicle = async () => {
    const result = await getVehicleById(vehicleId);
    const reducedVehicle = {
      name: result.name,
      licensePlateNum: result.licensePlateNum,
      location: result.stateProvince
        ? result.stateProvince
        : [...result.provinces, ...result.states].join(''),
      operation: result.operation,
    };
    setSelectedOperation(result.operation);
    setVehicle(reducedVehicle);
  };

  useEffect(async () => {
    sessionStorage.clear();
    await fetchVehicle();
    await fetchOperations();
    setLoaded(true);
  }, []);

  const submitVehicle = async (formValues) => {
    try {
      formValues.provinces = null;
      formValues.states = null;
      formValues.stateProvince = formValues.location;

      formValues.operation = selectedOperation.id
        ? selectedOperation.id
        : vehicle.operation._id;
      delete formValues.location;
      setSubmitting(true);

      const updatedVehicle = await axios.put(
        `/api/vehicles/${vehicleId}`,
        formValues,
      );
      setRedirect(`/vehicles/${vehicleId}`);
    } catch (err) {
      setSubmitting(false);
      toast.error(
        'An error occurred when updating the form. Contact an administrator',
      );
    }
  };

  const locations = () => {
    const provinces = getAllProvinceAbbrv();
    const states = getAllStateAbbrv();
    let locations = [];
    provinces.forEach((province) => {
      locations.push({
        name: getProvinceNameFromAbbrv(province),
        value: province,
      });
    });
    states.forEach((state) => {
      locations.push({
        name: getStateNameFromAbbrv(state),
        value: state,
      });
    });
    return locations;
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <PopUpWindow>
      {loaded && (
        <Form
          title="Update Your Vehicle"
          fieldInfo={[
            {
              name: 'Vehicle Name',
              id: 'name',
              value: vehicle ? vehicle.name : '',
            },
            {
              name: 'Vehicle Identification',
              id: 'licensePlateNum',
              description: 'License Plate, VIN Number, or Description',
              required: true,
              value: vehicle ? vehicle.licensePlateNum : '',
            },
            {
              name: 'Canadian Province/Territory or US State',
              id: 'location',
              required: true,
              type: 'select',
              options: locations(),
              value: vehicle ? vehicle.location : '',
            },
            {
              name: 'Operation',
              type: 'content',
              content: (
                <SearchableDropdown
                  id="operation-search"
                  type="operation"
                  optionKey="businessName"
                  options={operations.map((o) => ({
                    businessName: o.name,
                    id: o.id,
                  }))}
                  placeholder={
                    vehicle.operation?.businessName ||
                    'Search by Operation Name...'
                  }
                  onChange={(businessName) =>
                    setSelectedOperation(businessName)
                  }
                  onSelect={(option) => setSelectedOperation(option)}
                  onSelectionChange={(option) => setSelectedOperation(option)}
                />
              ),
              description: 'Select an operation that this vehicle belongs to.',
            },
            {
              type: 'content',
              content: <>{submitting && <SubmittingSpinner />}</>,
            },
          ]}
          onCancel={() => setRedirect('/vehicles')}
          onSubmit={submitVehicle}
        />
      )}
    </PopUpWindow>
  );
};

export default VehicleUpdateView;
