import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { PopUpWindow } from '../../components/CommonComponents';
import { createOperationUpdateLog } from '../../utils/OperationUtils';
import Form from '../../components/Form';
import {
  updateOperation,
  getOperationById,
} from '../../utils/OperationUtils.js';
import { getOperationTypes } from '../../utils/OperationTypeUtils';

export default function OperationUpdateView() {
  const [operation, setOperation] = useState({});
  const [operationTypes, setOperationTypes] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const { id } = useParams();

  const fields = [
    {
      name: 'Business Name',
      hint: 'Name',
      required: true,
      id: 'businessName',
      value: operation.businessName,
    },
    {
      name: 'Phone Number',
      hint: '647 222 2018',
      required: true,
      id: 'phoneNumber',
      value: operation.phoneNumber,
    },
    {
      name: 'Email',
      hint: 'canadasheepfederation@sheep.com',
      required: true,
      id: 'email',
      value: operation.email,
      validityCheck: (fields) => {
        const basicEmailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        if (!basicEmailRegex.test(fields.email)) {
          return 'Please enter a valid email address';
        }
        return true;
      },
    },
    {
      name: 'Operation Type',
      id: 'operationType',
      required: true,
      type: 'select',
      options: operationTypes.map((t) => ({ name: t.niceName, value: t.name })),
      value: operation.operationType,
    },
    {
      name: 'CVSP',
      id: 'cvsp',
      type: 'select',
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      value: operation.cvsp,
    },
    {
      name: 'SFCP',
      id: 'sfcp',
      type: 'select',
      options: [
        { name: 'Yes', value: true },
        { name: 'No', value: false },
      ],
      value: operation.sfcp,
    },
  ];

  const submitOperation = async (fieldValues) => {
    try {
      await updateOperation(fieldValues, id);
      createOperationUpdateLog({
        businessName: operation.businessName,
        phoneNumber: operation.phoneNumber,
        operationType: operation.operationType,
        cvsp: operation.cvsp === '' ? false : operation.cvsp,
        sfcp: operation.sfcp === '' ? false : operation.sfcp,
        newBusinessName: fieldValues.businessName,
        newPhoneNumber: fieldValues.phoneNumber,
        newOperationType: fieldValues.operationType,
        newCvsp: fieldValues.cvsp === '' ? false : fieldValues.cvsp,
        newSfcp: fieldValues.sfcp === '' ? false : fieldValues.sfcp,
        id: id,
      })
        .then(() => {
          toast.success('Operation successfully Updated');
        })
        .catch((err) => {
          toast.error(err);
        });
      setRedirect(true);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  useEffect(() => {
    (async () => {
      setOperation(await getOperationById(id));
      setOperationTypes(await getOperationTypes());
      setLoaded(true);
    })();
  }, []);

  if (redirect) {
    return <Redirect to="/operations" />;
  }

  return (
    <PopUpWindow>
      {loaded && (
        <Form
          title="Update an Operation"
          fieldInfo={fields}
          onCancel={() => setRedirect(true)}
          onSubmit={submitOperation}
        />
      )}
    </PopUpWindow>
  );
}
