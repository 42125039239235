import React from 'react';
import { getMoveLogs, getRelatedMoveLogs } from '../../utils/MoveUtils';
import { toast } from 'react-toastify';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import { localTimeFormatter } from '../../utils/TimeUtils';
import { dynamicSortDate } from '../../utils/ListUtils';

const moveOutColumns = [
  {
    id: 'departureDate',
    name: 'Date of Departure',
  },
  {
    id: 'departurePremise',
    name: 'Departure Premise',
  },
  {
    id: 'destinationPremise',
    name: 'Destination Premise',
  },
  {
    id: 'animalTags',
    name: 'Moved Out Animals',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const MoveOutReportListView = () => {
  const fetchAndTransformMoveLogs = async () => {
    const relatedLogs = await getRelatedMoveLogs().then((reports) => {
      return reports
        .filter((log) => log.moveOut)
        .map((report) => ({ ...report, related: true }));
    });

    return getMoveLogs()
      .then((moveLogs) => {
        let moveOut = moveLogs
          .filter((log) => log.moveOut)
          .concat(relatedLogs)
          .sort(dynamicSortDate('-createdAt'));

        moveOut = moveOut.map((moveLog) => ({
          ...moveLog,
          departurePremise: moveLog.departurePremise
            ? moveLog.departurePID
            : '',
          destinationPremise: moveLog.destinationPID
            ? moveLog.destinationPID
            : '',
          departureDate: moveLog.departureTime
            ? localTimeFormatter(moveLog.departureTime)
            : '',
          loadTime: moveLog.loadTime
            ? localTimeFormatter(moveLog.loadTime)
            : '',
        }));
        return moveOut;
      })
      .catch((err) => {
        toast.error(err);
      });
  };

  return (
    <SheepReportListComponent
      title="Move-Out"
      description="A Move-out Report records the movement of animals from a premises.
       Most operations are not required to report the movement of animals off their 
       premises, with the exception of abattoirs or farms moving animals to a community 
       pasture. However, a movement document is required to accompany the movement of 
       all animals between premises except when moving within the same operation. 
       You can create movement documents and move-out reports here."
      fetchReportsFunction={fetchAndTransformMoveLogs}
      detailEndpoint="/reports/moveout/"
      createEndpoint="/reports/moveout/new"
      csvEndpoint={'/reports/moveout/new/csv'}
      columns={moveOutColumns}
    />
  );
};

export default MoveOutReportListView;
