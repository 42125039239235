import { searchableDropdownField } from './commonFields';
import Phone from 'phone';

export const getFormFields = (allPremises, setSelectedPID) => {
  const fields = [
    {
      name: 'Premise Information',
      subtitle: 'Premise Information',
      subtype: 'withSubtitle',
      message:
        'Please fill out the information for the premise associated with the requested tags.',
      type: 'instruction',
    },
    {
      ...searchableDropdownField(
        'Premise ID (PID)',
        'pid',
        'premise',
        'pid',
        allPremises,
        'Search by PID...',
        setSelectedPID,
        'Fill out the PID of premise for tag allocation',
        true,
      ),
    },
    {
      name: 'Species of animal(s) for Requested Tags',
      id: 'species',
      type: 'textarea',
      value: '',
      maxLength: 500,
      required: true,
    },
    {
      name: 'Legal Description of Site',
      id: 'description',
      type: 'textarea',
      value: '',
      maxLength: 500,
      required: true,
    },
    {
      name: 'Location (Latitude, Longitude)',
      hint: 'Latitude, Longitude',
      id: 'location',
      type: 'location',
      required: true,
    },
    {
      name: 'Tag Manufacturing Company',
      id: 'manufacturer',
      type: 'select',
      options: [
        { name: 'Shearwell', value: 'Shearwell' },
        { name: 'Allflex', value: 'Allflex' },
      ],
      required: true,
    },
    {
      name: 'Contact Info',
      subtitle: 'Contact Info',
      subtype: 'withSubtitle',
      message:
        'Please enter the contact information associated with the tag order',
      type: 'instruction',
    },
    {
      name: 'Contact Name',
      id: 'name',
      type: 'text',
      required: true,
    },
    {
      name: 'E-Mail',
      id: 'email',
      type: 'text',
      required: true,
      errorMessage: 'Please enter a valid email address',
      validityCheck: (fields) => {
        const basicEmailRegex = /^[^@]+@[^@]+\.[^@]+$/;
        if (!basicEmailRegex.test(fields.value)) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Phone Number',
      id: 'phoneNumber',
      type: 'text',
      required: true,
      errorMessage: 'Please enter a valid phone number',
      validityCheck: (data) => {
        if (data.value && !Phone(data.value).isValid) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Tag Information',
      subtitle: 'Tag Information',
      subtype: 'withSubtitle',
      message: 'Please enter the number of tags you wish to request',
      type: 'instruction',
    },
    {
      name: 'Number of Tags',
      id: 'numberOfTags',
      type: 'text',
      required: true,
      errorMessage: 'Please enter a number',
      validityCheck: (fields) => {
        const numberRegex = /^\d*$/;
        if (!numberRegex.test(fields.value)) {
          return false;
        }
        return true;
      },
    },
  ];

  // Grouping steps into a single array
  const tagAllocationFields = [
    {
      name: 'Tag Allocation',
      fields: fields,
      isValid: false,
      errorMessage: 'Something is wrong with the form.',
    },
  ];

  return tagAllocationFields;
};
