import { useLocation } from 'react-router';
import React, { useEffect, useState } from 'react';
import Form from '../../components/Form.js';
import SheepSelector from '../../components/SheepSelector.js';
import { PopUpWindow } from '../../components/CommonComponents';
import { toast } from 'react-toastify';
import { Redirect, useHistory } from 'react-router';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { getUsername } from '../../utils/TokenUtils';
import { checkAdmin } from '../../utils/RoleUtils';
import { SubmittingSpinner } from '../../components/CommonComponents';
import { getSheep, getSheepsForUser } from '../../utils/SheepUtils';

import 'moment-precise-range-plugin';
import moment from 'moment';
library.add(faSpinner);

const axios = require('axios');

// SheepEditView.js
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const fetchBreeds = async () => {
  try {
    const request = await axios.get('/api/breed');
    const breeds = request.data;
    return breeds;
  } catch (err) {
    console.log(err);
    throw new Error(`An error occured fetching breeds: ${err.message}`);
  }
};

const SheepEditView = () => {
  const query = useQuery();
  const [sheeps, setSheeps] = useState([]);
  const [sheepInfo, setSheepInfo] = useState();
  const [search, setSearch] = useState('');
  const [breeds, setBreeds] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  // Process Custom Breed input
  const processBreed = (sheep, breeds) => {
    if (sheep.breed && !breeds.some((breed) => breed.name === sheep.breed)) {
      sheep.customBreed = sheep.breed;
      sheep.breed = 'Other';
    }
    return sheep;
  };

  const fetchAndSetSheepInfo = async (breedsIn) => {
    try {
      let sheepId = query.get('id');
      if (sheepId) {
        let fetchedSheep = await getSheep(sheepId, true);
        if (
          fetchedSheep.breed &&
          !breedsIn.some((breed) => breed.name === fetchedSheep.breed)
        ) {
          fetchedSheep.customBreed = fetchedSheep.breed;
          fetchedSheep.breed = 'Other';
        }
        setSheepInfo(fetchedSheep);
        setSearch(
          fetchedSheep.tag.isoNumber ||
            fetchedSheep.tag.usScrapieId ||
            fetchedSheep.tag.localMgmtNumber ||
            fetchedSheep.tag.tattooNumber,
        );
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const fetchAndSetBreeds = async () => {
    try {
      let breeds = await fetchBreeds();
      breeds = breeds.map((breed) => {
        return { name: breed.name, value: breed.name };
      });
      setBreeds(breeds);
      await fetchAndSetSheepInfo(breeds);
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleSheepSelection = (s) => {
    s = processBreed(s, breeds);
    setSheepInfo(s);
    setSearch(
      s.tag.isoNumber ||
        s.tag.usScrapieId ||
        s.tag.localMgmtNumber ||
        s.tag.tattooNumber,
    );
  };

  const handleSheepIdentifierSearch = async (value) => {
    setSearch(value);
    // Resets sheep info for every search identifier change
    setSheepInfo();

    if (value !== '') {
      let fetchedSheeps = await getSheepsForUser(
        getUsername(),
        checkAdmin(),
        value,
      );
      setSheeps(fetchedSheeps);

      // Searches for an identifier in sheeps that match value
      fetchedSheeps.forEach((s) => {
        if (
          s.tag.isoNumber === value ||
          s.tag.usScrapieId === value ||
          s.tag.localMgmtNumber === value ||
          s.tag.tattooNumber === value
        ) {
          const sheep = processBreed(s, breeds);
          setSheepInfo(sheep);
        }
      });
    } else {
      setSheeps([]);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAndSetBreeds();
      setLoaded(true);
    })();
  }, []);

  const numberOfMonths = (startDate, endDate) => {
    var starts = moment(startDate);
    var ends = moment(endDate);
    var diff = moment.preciseDiff(starts, ends, true);
    if (diff.years > 0 || diff.months >= 5) {
      return false;
    }
    return true;
  };

  const updateSheep = async (sheep) => {
    toast.info(
      'Submitting sheep to the backend. It may take up to a minute. ' +
        'You will be redirected when the sheep has been created. Please ' +
        'stay on this page.',
    );
    await axios.put('/api/sheep/' + sheepInfo._id, sheep);
  };

  const handleBreedChange = (value) => {
    setSheepInfo((prevInfo) => ({
      ...prevInfo,
      breed: value.breed,
      customBreed: '',
    }));
  };

  const handleSubmit = async (fieldValues) => {
    try {
      if (!sheepInfo) {
        toast.error(`${search} is not a valid sheep identifier.`);
        return;
      }

      // Check if the breed is custom
      const breed =
        fieldValues.breed === 'Other'
          ? fieldValues.customBreed
          : fieldValues.breed;

      const sheep = {
        gender: fieldValues.gender,
        subgender: fieldValues.subgender,
        ...(breed && { breed: breed }),
        ...(fieldValues.birthdate && { birthdate: fieldValues.birthdate }),
        premise: sheepInfo.premise,
        tag: {
          ...(sheepInfo.tag.isoNumber && {
            isoNumber: sheepInfo.tag.isoNumber,
          }),
          ...(fieldValues.localMgmtNumber && {
            localMgmtNumber: fieldValues.localMgmtNumber,
          }),
          ...(fieldValues.tattooNumber && {
            tattooNumber: fieldValues.tattooNumber,
          }),
          ...(fieldValues.usScrapieId && {
            usScrapieId: fieldValues.usScrapieId,
          }),
        },
      };
      setSubmitting(true);
      await updateSheep(sheep);
      setRedirect(`/sheep/` + sheepInfo._id);
    } catch (err) {
      toast.dismiss();
      if (err.response) {
        // In this case, the error is from Axios
        toast.error(err.response.data.message);
      } else {
        // The error isn't from Axios and won't have a response object
        toast.error(err);
      }

      // Allow the user to try submitting again
      setSubmitting(false);
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <PopUpWindow>
      {loaded && (
        <Form
          title="Edit Sheep"
          fieldInfo={[
            {
              name: 'Search for Sheep via Identifier',
              type: 'content',
              content: (
                <SheepSelector
                  value={search}
                  sheep={sheeps}
                  onChange={(value) => handleSheepIdentifierSearch(value)}
                  onSelect={handleSheepSelection}
                />
              ),
            },
            {
              name: 'CSIP Number',
              value:
                sheepInfo && sheepInfo.tag.isoNumber
                  ? sheepInfo.tag.isoNumber
                  : 'None',
              disabled: true,
              resetOnChange: true,
            },
            {
              type: 'content',
              content: (
                <p style={{ marginBottom: '0px' }}>
                  A Local Management Number or Tattoo Number must be supplied if
                  the sheep does not have a CSIP Number.
                </p>
              ),
            },
            {
              name: 'Local Management Number',
              id: 'localMgmtNumber',
              value: sheepInfo ? sheepInfo.tag.localMgmtNumber : '',
              resetOnChange: true,
              validityCheck: (fieldValues) => {
                if (
                  sheepInfo &&
                  typeof sheepInfo.tag.isoNumber === 'undefined' &&
                  fieldValues.localMgmtNumber === '' &&
                  fieldValues.tattooNumber === ''
                ) {
                  return '';
                } else if (
                  fieldValues.localMgmtNumber === '' ||
                  /^[0-9a-zA-Z]+$/.exec(fieldValues.localMgmtNumber)
                ) {
                  return true;
                } else {
                  return 'Local Management Number must only contain numeric characters.';
                }
              },
            },
            {
              name: 'Tattoo Number',
              id: 'tattooNumber',
              value: sheepInfo ? sheepInfo.tag.tattooNumber : '',
              resetOnChange: true,
              validityCheck: (fieldValues) => {
                if (
                  fieldValues.tattooNumber === '' ||
                  /^[0-9a-zA-Z]+$/.exec(fieldValues.tattooNumber)
                ) {
                  return true;
                } else {
                  return 'Tattoo Number must only contain alphanumeric characters.';
                }
              },
            },
            {
              name: 'US Scrapie ID',
              id: 'usScrapieId',
              value: sheepInfo ? sheepInfo.tag.usScrapieId : '',
              resetOnChange: true,
              validityCheck: (fieldValues) => {
                if (
                  fieldValues.usScrapieId === '' ||
                  /^[A-Z]{2}[0-9]{7}$/.exec(fieldValues.usScrapieId)
                ) {
                  return true;
                } else {
                  return 'US Scrapie ID must start with a US State abbreviation, followed by 7 digits.';
                }
              },
            },
            {
              name: 'Gender',
              id: 'gender',
              type: 'select',
              resetOnChange: true,
              options: [
                { name: 'Male', value: 'male' },
                { name: 'Female', value: 'female' },
              ],
              value: sheepInfo ? sheepInfo.gender : '',
            },
            {
              name: 'Sub Gender',
              id: 'subgender',
              type: 'select',
              resetOnChange: true,
              optionsFilter: (fieldValues) => {
                if (fieldValues.gender === 'male') {
                  return [
                    { name: 'Ram', value: 'ram' },
                    { name: 'Wether', value: 'wether' },
                  ];
                } else if (fieldValues.gender === 'female') {
                  return [
                    { name: 'Ewe', value: 'ewe' },
                    { name: 'Ewe Lamb', value: 'ewelamb' },
                  ];
                } else {
                  return [];
                }
              },
              value: sheepInfo ? sheepInfo.subgender : '',
            },
            {
              name: 'Breed',
              id: 'breed',
              type: 'select',
              resetOnChange: true,
              options: breeds ? breeds : [],
              value: sheepInfo ? sheepInfo.breed : '',
              onChange: handleBreedChange,
            },
            ...(sheepInfo && sheepInfo.breed === 'Other'
              ? [
                  {
                    name: 'Custom Breed',
                    id: 'customBreed',
                    type: 'text',
                    value: sheepInfo ? sheepInfo.customBreed : '',
                  },
                ]
              : []),
            ...(sheepInfo &&
            ((sheepInfo.birthdate &&
              numberOfMonths(sheepInfo.birthdate, new Date())) ||
              !sheepInfo.birthdate)
              ? [
                  {
                    name: 'Date of Birth',
                    id: 'birthdate',
                    type: 'date',
                    resetOnChange: true,
                    value: sheepInfo ? sheepInfo.birthdate : '',
                    validityCheck: (fieldValues) => {
                      if (numberOfMonths(fieldValues.birthdate, new Date())) {
                        return true;
                      } else {
                        return 'Cannot add a date of birth that precedes today by more than 5 months.';
                      }
                    },
                  },
                ]
              : []),
            {
              type: 'content',
              content: submitting ? <SubmittingSpinner /> : <></>,
            },
          ]}
          isSubmitting={submitting}
          onCancel={history.goBack}
          onSubmit={handleSubmit}
        />
      )}
    </PopUpWindow>
  );
};

export default SheepEditView;
