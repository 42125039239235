import React, { useState, useEffect } from 'react';
import { getSheepsForUser } from '../../utils/SheepUtils';
import { getUsername } from '../../utils/TokenUtils';
import { checkAdmin } from '../../utils/RoleUtils';
import { FetchPremises } from '../../components/SheepReportComponents';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents';
import AddActivateTag from '../../components/Forms/AddActivateTag.js';

const TagActivationCreateView = () => {
  const [premises, setPremises] = useState([]);
  const [sheeps, setSheeps] = useState([]);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      const formattedPremises = fetchedPremises.map((premise) => ({
        name: premise.name ? `${premise.pid} - ${premise.name}` : premise.pid,
        value: premise.pid,
      }));

      setPremises(formattedPremises);
    } catch (err) {
      if (err.code == 404) {
        setPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetSheeps = async () => {
    try {
      const fetchedSheeps = await getSheepsForUser(getUsername(), checkAdmin());
      setSheeps(fetchedSheeps);
    } catch (err) {
      if (err.code == 404) {
        setSheeps([]);
      } else {
        throw new Error(
          'We were unable to check which sheeps you have access to. Try again later',
        );
      }
    }
  };

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetSheeps();
  }, []);

  const paths = [
    { url: '/reports/tagactivation', title: 'Dashboard' },
    {
      url: '/reports/tagactivation/create',
      title: 'Create a Tag Activation Report',
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddActivateTag errors={{}} premises={premises} animals={sheeps} />
        </ContentContainer>
      </div>
    </>
  );
};

export default TagActivationCreateView;
