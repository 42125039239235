import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { traceSheep } from '../utils/TraceUtils.js';
import PageCard from './PageCard.js';
import TraceSheepDetails from './TraceSheepDetails.js';
import { getSheep } from '../utils/SheepUtils.js';

export default function TraceSheepDetailsView(props) {
  const sheepID = props.match.params.isoNumber;

  const history = useHistory();

  const [tracedSheep, setTracedSheep] = useState({});
  const [tracedList, setTracedList] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [sheepIdentifier, setSheepIdentifier] = useState();

  useEffect(async () => {
    try {
      const sheep = await getSheep(sheepID, '');
      setSheepIdentifier(
        sheep.tag.isoNumber ||
          sheep.tag.localMgmtNumber ||
          sheep.tag.tattooNumber ||
          sheep.tag.usScrapieId,
      );
      const traceData = await traceSheep(sheepID, 2);
      setTracedSheep(traceData.sheep);
      setTracedList(traceData.traceList);
      setLoaded(true);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err);
      }
    }
  }, [sheepID]);

  const customBack = () => {
    history.goBack();
  };

  return (
    <PageCard
      title={`Trace Sheep ${sheepIdentifier || ''}`}
      back
      line
      onBack={customBack}
    >
      {loaded ? (
        <TraceSheepDetails
          sheepId={tracedSheep._id}
          dateOfArrival={tracedSheep.arrivalDate}
          dateOfDeparture={tracedSheep.departureDate}
          premise={tracedSheep.premise || null}
          userInfo={tracedSheep.premise?.operation?.users[0] || null}
          tracedList={tracedList}
          sheepStatus={tracedSheep.active ? 'Active' : 'Inactive'}
        />
      ) : (
        <>Loading... If the page doesn't load, refresh the page</>
      )}
    </PageCard>
  );
}

TraceSheepDetailsView.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      isoNumber: PropTypes.any,
    }),
  }),
};
