import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StripedTable from './StripedTable';
import ViewEditDispose from './ViewEditDispose';
import PropTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import {
  VerticalContainer,
  ContentContainer,
} from '../components/CommonComponents.js';
import styled from 'styled-components';
import { FilterSection } from './SheepReportComponents';

const ReportsSingleView = styled(VerticalContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const ReportsDescription = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  @media (max-width: 950px) {
    width: 95%;
  }
  @media (max-width: 500px) {
    width: 85%;
  }
  @media (max-width: 460px) {
    width: 75%;
  }
`;

const DataDoesNotExist = styled(ContentContainer)`
  background: ${COLORS.secondary};
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 20px;
  }
`;

const VehicleRelatedMovesComponent = ({
  title,
  fetchReportsFunction,
  detailEndpoint,
  columns,
  vehicleNumber,
}) => {
  /* States */
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);

  /* Transforms */
  const fetchReportsAndTransform = async () => {
    try {
      let fetchedReports = await fetchReportsFunction();
      fetchedReports.forEach((r) => {
        r.actions = (
          <ViewEditDispose
            id={r._id}
            viewLink={
              r.moveOut
                ? detailEndpoint.moveout + r._id
                : detailEndpoint.movein + r._id
            }
          />
        );

        r.createdAt =
          r.createdAt && new Date(r.createdAt).toISOString().split('T')[0];
      });

      setReports(fetchedReports);
    } catch (err) {
      console.info(err);
      toast.error(err);
    }
  };

  useEffect(() => {
    fetchReportsAndTransform();
  }, []);

  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  const handleApply = (filteredData) => {
    setFilteredReports(filteredData);
  };

  function reportsDataExists() {
    return (
      <>
        <FilterSection data={reports} onApply={handleApply} />
        <StripedTable
          columns={columns}
          rows={filteredReports}
          ShowFilters
          ShowCount
          max={10}
          paginate
        />
      </>
    );
  }

  function reportsDataDoesNotExist() {
    return (
      <DataDoesNotExist>
        <p>
          It looks like you don&apos;t have any {title} Reports at the moment.
        </p>
      </DataDoesNotExist>
    );
  }

  return (
    <ReportsSingleView>
      <ReportsDescription>
        <p style={{ fontSize: '20px', marginTop: '25px' }}>
          You can view all of the {title} reports of vehicles:
          <span style={{ fontWeight: 'bold' }}> {vehicleNumber} </span>
        </p>
      </ReportsDescription>
      {reports.length === 0 ? reportsDataDoesNotExist() : reportsDataExists()}
    </ReportsSingleView>
  );
};

VehicleRelatedMovesComponent.propTypes = {
  columns: PropTypes.any,
  createEndpoint: PropTypes.any,
  detailEndpoint: PropTypes.any,
  fetchReportsFunction: PropTypes.func,
  title: PropTypes.any,
  vehicleNumber: PropTypes.any,
};

export default VehicleRelatedMovesComponent;
