import React, { useState, useEffect, useContext } from 'react';
import { NavLink as Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/logo.png';
import agroledger_logo from '../images/agro_ledger_logo_full.png';
import {
  FormSelectInput,
  HorizontalContainer,
  ImageContainer,
  VerticalContainer,
  Icon,
} from '../components/CommonComponents';
import { getUsername, getRoles } from '../utils/TokenUtils';
import PropTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import profile from '../images/icons/profile.png';
import notification from '../images/icons/notification.png';
import logout from '../images/icons/logout.png';
import menuOpen from '../images/icons/menu_open.png';
import menuClose from '../images/icons/menu_close.png';
import dropdownOpen from '../images/icons/chevron_down.png';
import dropdownClose from '../images/icons/chevron_up.png';
import { RoleChangeContext } from '../RoleChangeContext';
import axios from 'axios';

const Navbar = ({ token, handleLogout, selectedRole }) => {
  const location = useLocation();

  const [roles, setRoles] = useState([]);
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(false);
  const [newSelectedRole, setSelectedRole] = useState(selectedRole);
  const { newRoleArray, setNewRoleArray } = useContext(RoleChangeContext);
  const [holdingsActive, setHoldingsActive] = useState(false);

  const fetchRoles = async () => {
    setRoles(getRoles());
  };

  useEffect(() => {
    if (newRoleArray || token) {
      fetchRoles();
      const role = localStorage.getItem('role') || '';
      setSelectedRole(role);
      setNewRoleArray(false);
    }
  }, [token, newRoleArray]);

  useEffect(() => {
    setHoldingsActive(
      ['premises', 'operations', 'vehicles'].includes(
        location.pathname.split('/')[1],
      ),
    );
  }, [location]);

  function handleChange(e) {
    setSelectedRole(e.target.value);
    localStorage.setItem('role', e.target.value);
  }

  if (location.pathname === '/' && !token) {
    return (
      <Nav mobile={'justify-content: center;'} background={'none'}>
        <LandingNav>
          <HorizontalContainer>
            <ImageContainer
              src={logo}
              width={'15%'}
              mobile={'width: 15%;'}
              onClick={() => window.open('https://www.cansheep.ca/')}
            />
            <Link to="/" style={{ display: 'flex', justifyContent: 'center' }}>
              <ImageContainer
                width={'120%'}
                src={agroledger_logo}
                mobile={'width: 70%;'}
              />
            </Link>
          </HorizontalContainer>
        </LandingNav>
      </Nav>
    );
  }

  const checkUnread = async () => {
    const response = await axios.get('/api/notifications/check');
    setUnreadNotifications(response.data.unreadCount);
  };

  if (token) checkUnread();

  return (
    <>
      <Nav mobile={'display: none;'}>
        <HorizontalContainer ml={'10px'}>
          <ImageContainer
            src={logo}
            height={'50px'}
            onClick={() => window.open('https://www.cansheep.ca/')}
            style={{ cursor: 'pointer' }}
          />
          <Link to="/">
            <ImageContainer
              height={'50px'}
              src={agroledger_logo}
              ml={'16px'}
              mr={'16px'}
            />
          </Link>

          {token && (
            <>
              <NavLink exact to="/">
                Home
              </NavLink>
              <NavLink to="/sheep">Sheep</NavLink>
              <NavLink to="/reports">Reports</NavLink>
              <Dropdown active={holdingsActive}>
                <p
                  style={{
                    color: 'black',
                    margin: 'auto',
                    paddingInline: '20px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Holdings&nbsp;
                  <DownArrow src={dropdownOpen} />
                  <UpArrow src={dropdownClose} />
                </p>
                <DropdownContent>
                  <DropdownNavLink to="/premises">Premises</DropdownNavLink>
                  <DropdownNavLink to="/operations">Operations</DropdownNavLink>
                  <DropdownNavLink to="/vehicles">Vehicles</DropdownNavLink>
                </DropdownContent>
              </Dropdown>
            </>
          )}
        </HorizontalContainer>

        <NavRight>
          {token ? (
            <>
              {token && roles && roles.length > 0 && (
                <OperationContainer>
                  <FormSelectInput
                    name="role"
                    value={newSelectedRole}
                    onChange={(event) => handleChange(event)}
                  >
                    {roles.map((r) => (
                      <option value={r.role} key={r.role}>
                        {r.role}
                      </option>
                    ))}
                  </FormSelectInput>
                </OperationContainer>
              )}
              <ButtonNavLink to={`/users/${getUsername()}`}>
                <Icon src={profile} />
              </ButtonNavLink>
              <ButtonNavLink to="/notifications">
                <Icon src={notification} />
                {unreadNotifications > 0 && (
                  <MessageCount>{unreadNotifications}</MessageCount>
                )}
              </ButtonNavLink>
              <Icon src={logout} onClick={handleLogout} />
            </>
          ) : (
            <>
              <LandingNavLink to="/register">Register</LandingNavLink>
              <LandingNavLink to="/login">Login</LandingNavLink>
            </>
          )}
        </NavRight>
      </Nav>
      <MobileNav>
        <HorizontalContainer
          m={'20px'}
          width={'100%'}
          mobile={'none'}
          justify_content={'center'}
        >
          <VerticalContainer>
            <ImageContainer
              m={'0px'}
              src={logo}
              height={'30px'}
              onClick={() => window.open('https://www.cansheep.ca/')}
            />
            <Link to="/">
              <ImageContainer height={'30px'} src={agroledger_logo} />
            </Link>
          </VerticalContainer>
          <Icon
            src={menuOpen}
            m={'0px'}
            width={'30px'}
            style={{ position: 'absolute', right: '37px' }}
            onClick={() => {
              setMobileNavOpen(!mobileNavOpen);
            }}
          />
        </HorizontalContainer>
        {mobileNavOpen && (
          <MobileNavWindow>
            <VerticalContainer
              m={'auto'}
              width={'100%'}
              height={'95%'}
              justify_content={'flex-start'}
            >
              <Icon
                src={menuClose}
                m={'0px'}
                ml={'auto'}
                mr={'20px'}
                width={'30px'}
                onClick={() => {
                  setMobileNavOpen(!mobileNavOpen);
                }}
              />
              <VerticalContainer
                m={'10px'}
                justify_content={'center'}
                width={'100%'}
              >
                <ImageContainer
                  m={'0px'}
                  src={logo}
                  height={'30px'}
                  onClick={() => window.open('https://www.cansheep.ca/')}
                />
                <Link
                  to="/"
                  onClick={() => {
                    setMobileNavOpen(!mobileNavOpen);
                  }}
                >
                  <ImageContainer height={'30px'} src={agroledger_logo} />
                </Link>
              </VerticalContainer>
              {token ? (
                <>
                  <MobileNavLink
                    exact
                    to="/"
                    onClick={() => {
                      setMobileNavOpen(!mobileNavOpen);
                    }}
                  >
                    Home
                  </MobileNavLink>
                  <MobileNavLink
                    to="/sheep"
                    onClick={() => {
                      setMobileNavOpen(!mobileNavOpen);
                    }}
                  >
                    Sheep
                  </MobileNavLink>
                  <MobileNavLink
                    to="/reports"
                    onClick={() => {
                      setMobileNavOpen(!mobileNavOpen);
                    }}
                  >
                    Reports
                  </MobileNavLink>
                  <MobileDropdown active={holdingsActive}>
                    <p
                      style={{
                        position: 'fixed',
                        color: 'black',
                        fontSize: '16px',
                      }}
                    >
                      Holdings&nbsp;
                      <DownArrow src={dropdownOpen} />
                      <UpArrow src={dropdownClose} />
                    </p>
                    <MobileDropdownContent active={holdingsActive}>
                      <MobileDropdownNavLink
                        to="/premises"
                        onClick={() => {
                          setMobileNavOpen(!mobileNavOpen);
                        }}
                      >
                        Premises
                      </MobileDropdownNavLink>
                      <MobileDropdownNavLink
                        to="/operations"
                        onClick={() => {
                          setMobileNavOpen(!mobileNavOpen);
                        }}
                      >
                        Operations
                      </MobileDropdownNavLink>
                      <MobileDropdownNavLink
                        to="/vehicles"
                        onClick={() => {
                          setMobileNavOpen(!mobileNavOpen);
                        }}
                      >
                        Vehicles
                      </MobileDropdownNavLink>
                    </MobileDropdownContent>
                  </MobileDropdown>
                  <HorizontalContainer mb={'0px'} mobile={'none'}>
                    <ButtonNavLink
                      to={`/users/${getUsername()}`}
                      onClick={() => {
                        setMobileNavOpen(!mobileNavOpen);
                      }}
                    >
                      <Icon src={profile} />
                    </ButtonNavLink>
                    <ButtonNavLink
                      to="/notifications"
                      onClick={() => {
                        setMobileNavOpen(!mobileNavOpen);
                      }}
                    >
                      <Icon src={notification} />
                      {unreadNotifications > 0 && (
                        <MessageCount>{unreadNotifications}</MessageCount>
                      )}
                    </ButtonNavLink>
                    <Icon
                      src={logout}
                      onClick={(e) => {
                        setMobileNavOpen(!mobileNavOpen);
                        handleLogout(e);
                      }}
                    />
                  </HorizontalContainer>
                  {token && roles && roles.length > 0 && (
                    <OperationContainer>
                      <FormSelectInput name="role" value={newSelectedRole}>
                        {roles.map((r) => (
                          <option value={r.role} key={r.role}>
                            {r.role}
                          </option>
                        ))}
                      </FormSelectInput>
                    </OperationContainer>
                  )}
                </>
              ) : (
                <>
                  <MobileNavLink
                    to="/login"
                    onClick={() => {
                      setMobileNavOpen(!mobileNavOpen);
                    }}
                  >
                    Login
                  </MobileNavLink>
                  <MobileNavLink
                    to="/register"
                    onClick={() => {
                      setMobileNavOpen(!mobileNavOpen);
                    }}
                  >
                    Register
                  </MobileNavLink>
                </>
              )}
            </VerticalContainer>
          </MobileNavWindow>
        )}
      </MobileNav>
    </>
  );
};

Navbar.propTypes = {
  handleLogout: PropTypes.func,
  newSelectedRole: PropTypes.any,
  selectedRole: PropTypes.any,
  token: PropTypes.any,
};

const Nav = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 80px;
  width: 90%;
  padding-inline: 5%;
  background: ${(props) =>
    props.background ? props.background : COLORS.light};

  @media (max-width: 1150px) {
    ${(props) =>
      props.mobile ? props.mobile : 'display: none; justify-content: center;'}
  }
`;

const MobileNav = styled.div`
  display: none;
  justify-content: flex-start;

  @media (max-width: 1150px) {
    display: flex;
  }
`;

const MobileNavWindow = styled.div`
  z-index: 100;
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: ${COLORS.light};
`;

const LandingNav = styled.div`
  padding-top: 100px;
  padding-left: 100px;

  @media (max-width: 768px) {
    padding: 0px;
    padding-top: 100px;
  }
`;

const LandingNavLink = styled(Link)`
  display: inline-block;
  text-align: center;
  width: 80px;
  height: 22px;
  font-size: large;
  font-weight: bold;
  margin-inline: 20px;
  padding: 10px;
  text-decoration: none;
  color: ${COLORS.primary};
  background-color: white;
  border: ${COLORS.primary} solid 2px;
  border-radius: 25px;

  &:hover {
    color: white;
    background-color: ${COLORS.primary};
  }

  &.active {
    color: white;
    background-color: ${COLORS.primary};
  }
`;

const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 80px;
  font-size: large;
  padding: auto;
  text-decoration: none;
  color: black;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:hover {
    background-color: ${COLORS.secondary};
  }

  &.active {
    font-weight: bold;
    background-color: ${COLORS.secondary};
    border-bottom: ${COLORS.primary} 6px solid;
  }
`;

const MobileNavLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  border-bottom: ${COLORS.primary} 1px solid;
  padding: auto;
  text-decoration: none;
  color: black;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:hover {
    background-color: ${COLORS.secondary};
  }

  &.active {
    font-weight: bold;
    background-color: ${COLORS.secondary};
    border-left: ${COLORS.primary} 10px solid;
  }
`;

const ButtonNavLink = styled(Link)`
  position: relative;
  &.active > img {
    filter: brightness(0.85);
  }
`;

const DownArrow = styled(Icon)`
  display: inline-block;
  width: 15px;
  margin: auto;
`;

const UpArrow = styled(Icon)`
  display: none;
  width: 15px;
  margin: auto;
`;

const DropdownNavLink = styled(Link)`
  background-color: ${COLORS.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  width: 100%;
  height: 40px;
  font-size: large;
  padding: auto;
  text-decoration: none;
  color: black;
  border-bottom: ${COLORS.primary} 1px solid;
  font-weight: normal;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:hover {
    font-weight: bold;
  }

  &.active {
    font-weight: bold;
  }
`;

const DropdownContent = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  margin-bottom: -125px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: 5px ${COLORS.primary} solid;
`;

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  height: 80px;
  font-size: large;
  padding: auto;
  color: black;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  ${(props) =>
    props.active &&
    `font-weight: bold; background-color: ${COLORS.secondary}; border-bottom: ${COLORS.primary} 6px solid;`}

  &:hover {
    background-color: ${COLORS.secondary};
  }

  &:hover ${DropdownContent} {
    display: flex;
  }

  &:hover ${DownArrow} {
    display: none;
  }

  &:hover ${UpArrow} {
    display: inline-block;
  }
`;

const MobileDropdownNavLink = styled(Link)`
  background-color: ${COLORS.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-size: large;
  padding: auto;
  text-decoration: none;
  color: black;
  border-bottom: ${COLORS.primary} 1px solid;
  font-weight: normal;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  &:hover {
    background-color: ${COLORS.secondary};
  }

  &.active {
    font-weight: bold;
    background-color: ${COLORS.secondary};
    border-left: ${COLORS.primary} 10px solid;
  }

  &.before {
    font-size: 25px;

    &:hover {
      font-weight: bold;
      font-size: 30px;
    }
  }
`;

const MobileDropdownContent = styled.div`
  display: none;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  top: 120px;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: 5px ${COLORS.primary} solid;
  z-index: 100;

  ${(props) => props.active && `margin-left: -10px; width: calc(100% + 10px);`}}
`;

const MobileDropdown = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-size: large;
  padding: auto;
  text-decoration: none;
  color: black;
  border-bottom: ${COLORS.primary} 1px solid;

  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  ${(props) =>
    props.active &&
    `font-weight: bold; background-color: ${COLORS.secondary}; border-left: ${COLORS.primary} 10px solid;`}

  &:hover ${MobileDropdownContent} {
    display: flex;
  }

  &:hover ${DownArrow} {
    display: none;
  }

  &:hover ${UpArrow} {
    display: inline-block;
  }
`;

const NavRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const MessageCount = styled.span`
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: ${COLORS.error};
  border-radius: 50%;
  margin-top: 5px;
`;

const OperationContainer = styled.div`
  display: flex;
  margin-inline: 16px;
`;

export default Navbar;
