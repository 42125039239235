// ExportReportDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { getOperationTypeByName } from '../../utils/OperationTypeUtils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';
import { localTimeFormatter } from '../../utils/TimeUtils';

const detailExportColumns = [
  {
    id: 'isoNumber',
    name: 'ISO Number',
    required: true,
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
    required: true,
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
    required: true,
  },
  {
    id: 'breed',
    name: 'Breed',
    required: true,
  },
  {
    id: 'gender',
    name: 'Gender',
    required: true,
  },
];

const getPremiseName = (id) => {
  return axios
    .get(`/api/premises/pid/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const getVehicleName = (id) => {
  return axios
    .get(`/api/vehicles/plate?plateNum=${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      if (err.response.status == 404) {
        return;
      } else {
        throw err.response.data;
      }
    });
};

const fetchMoveOutInfo = async (id) => {
  try {
    const request = await axios.get(`/api/move/${id}`);
    return request.data;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
};

const Info = ({ name, value }) => {
  return (
    <div>
      <span>{name}:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
};

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any,
};

const MoveOutReportDetailView = () => {
  const [moveOutReportInfo, setMoveOutReportInfo] = useState();
  const [moveOutSheepInfo, setMoveOutSheepInfo] = useState([]);

  const [departurePremiseName, setDeparturePremiseName] = useState('');
  const [departurePremiseType, setDeparturePremiseType] = useState('');
  const [destpremiseName, setDestPremiseName] = useState('');
  const [destPremiseType, setDestPremiseType] = useState('');
  const [vehicleName, setVehicleName] = useState('');

  const [reportLocation, setReportLocation] = useState(null);

  const params = useParams();
  const id = params.id;

  // fetchExportReportInfo
  const fetchAndSetMoveInReportInfo = async () => {
    try {
      let fetchedMoveOutReportInfo = await fetchMoveOutInfo(id);

      fetchedMoveOutReportInfo.departureTime = localTimeFormatter(
        fetchedMoveOutReportInfo.departureTime,
      );

      fetchedMoveOutReportInfo.loadTime = localTimeFormatter(
        fetchedMoveOutReportInfo.loadTime,
      );

      // Departure Premise
      // If user viewing is not authorized to get premise name & type, then display N/A
      try {
        let fetchedDeparturePremiseName = await getPremiseName(
          fetchedMoveOutReportInfo.departurePID,
        );
        setDeparturePremiseName(fetchedDeparturePremiseName.name);

        let fetchedDepartureOperationType = await getOperationTypeByName(
          fetchedDeparturePremiseName.operation.operationType,
        );
        setDeparturePremiseType(fetchedDepartureOperationType.niceName);
      } catch (err) {
        setDeparturePremiseName('N/A');
        setDeparturePremiseType('N/A');
      }

      // Destination Premise
      if (
        fetchedMoveOutReportInfo.destinationPID &&
        fetchedMoveOutReportInfo.destinationPremise
      ) {
        // If user viewing is not authorized to get premise name & type, then display N/A
        try {
          let fetchedFirstDestPremiseName = await getPremiseName(
            fetchedMoveOutReportInfo.destinationPID,
          );
          setDestPremiseName(fetchedFirstDestPremiseName.name);

          let fetchedDestOperationType = await getOperationTypeByName(
            fetchedFirstDestPremiseName.operation.operationType,
          );
          setDestPremiseType(fetchedDestOperationType.niceName);
        } catch (err) {
          setDestPremiseName('N/A');
          setDestPremiseType('N/A');
        }
      } else {
        setDestPremiseName('N/A');
        setDestPremiseType('N/A');
      }

      let fetchedVehicle = await getVehicleName(
        fetchedMoveOutReportInfo.vehicleNumber,
      );
      if (fetchedVehicle) {
        setVehicleName(fetchedVehicle[0].name);
      } else {
        setVehicleName('N/A');
      }

      setMoveOutSheepInfo(fetchedMoveOutReportInfo.animals);
      setMoveOutReportInfo(fetchedMoveOutReportInfo);

      // if this report has a geolocation, save that to a state as well
      if (
        fetchedMoveOutReportInfo.location &&
        Array.isArray(fetchedMoveOutReportInfo.location.coordinates) &&
        fetchedMoveOutReportInfo.location.coordinates.length === 2
      ) {
        const longlat = fetchedMoveOutReportInfo.location.coordinates;
        setReportLocation([longlat[1], longlat[0]]);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchAndSetMoveInReportInfo();
  }, []);

  moveOutSheepInfo.forEach((s) => {
    s.isoNumber = s.tag.isoNumber ? s.tag.isoNumber : <NAField />;
    s.localMgmtNumber = s.tag.localMgmtNumber ? (
      s.tag.localMgmtNumber
    ) : (
      <NAField />
    );
    s.tattooNumber = s.tag.tattooNumber ? s.tag.tattooNumber : <NAField />;
    s.breed = s.breed ? s.breed : <NAField />;
    s.gender = s.gender ? s.gender : <NAField />;
  });

  return (
    <>
      <PageCard
        title="Move-Out Report Details"
        verify={`/verify/moveout/${id}`}
        back
      >
        {moveOutReportInfo ? (
          <>
            <SimpleCard title="Premise Information" size="md">
              {/* Location Panel*/}
              <OneThirdDiv>
                <Info
                  name="Departure Premise PID"
                  value={moveOutReportInfo.departurePID}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info
                  name="Departure Premise Name"
                  value={departurePremiseName}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info
                  name="Departure Premise Type"
                  value={departurePremiseType}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info name="Destination Premise Name" value={destpremiseName} />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info
                  name="Destination Premise PID"
                  value={moveOutReportInfo.destinationPID}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info name="Destination Premise Type" value={destPremiseType} />
              </OneThirdDiv>
            </SimpleCard>
            <br></br>
            <br></br>

            {/* Transport Sheep Info */}
            <SimpleCard title="Transported Sheep" size="md">
              <StripedTable
                columns={detailExportColumns}
                rows={moveOutSheepInfo}
                ShowCount
                max={10}
              />
            </SimpleCard>

            {/* Vehicle Date Info */}
            {moveOutReportInfo.vehicleNumber ? (
              <>
                <SimpleCard title="Vehicle Information" size="sm">
                  <HalfDiv>
                    <Info name="Name" value={vehicleName} />
                  </HalfDiv>
                  <HalfDiv>
                    <Info
                      name="License Plate Number"
                      value={moveOutReportInfo.vehicleNumber}
                    />
                  </HalfDiv>
                </SimpleCard>
              </>
            ) : (
              <> </>
            )}
            <br></br>

            {/* Receive Date Info */}
            {moveOutReportInfo.receivedTime ? (
              <>
                <SimpleCard title="Receive Date and Time" size="sm">
                  <HalfDiv>
                    <Info name="Name" value={moveOutReportInfo.receivedTime} />
                  </HalfDiv>
                  <HalfDiv>
                    <Info
                      name="License Plate Number"
                      value={moveOutReportInfo.unloadTime}
                    />
                  </HalfDiv>
                </SimpleCard>
              </>
            ) : (
              <> </>
            )}
            <br></br>

            {/* Arrival Date Info */}
            {moveOutReportInfo.departureTime ? (
              <>
                <SimpleCard title="Departure Date" size="sm">
                  <HalfDiv>
                    <Info name="Date" value={moveOutReportInfo.departureTime} />
                  </HalfDiv>
                  <HalfDiv>
                    <Info name="Load Time" value={moveOutReportInfo.loadTime} />
                  </HalfDiv>
                </SimpleCard>
              </>
            ) : (
              <> </>
            )}
            <br></br>

            {reportLocation && (
              <SimpleCard title="Location" size="sm">
                <HalfDiv>
                  <Info name="Latitude" value={reportLocation[0]} />
                </HalfDiv>
                <HalfDiv>
                  <Info name="Longitude" value={reportLocation[1]} />
                </HalfDiv>
              </SimpleCard>
            )}
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const OneThirdDiv = styled.div`
  width: 33%;
  float: left;
  display: inline-block;
`;
const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`;

export default MoveOutReportDetailView;
