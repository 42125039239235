import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Redirect } from 'react-router-dom';
import useWebSocket, { ReadyState } from "react-use-websocket"
import styled from 'styled-components';
import { toast } from 'react-toastify';
import {
  ContentContainer,
  Button,
  Icon,
  VerticalContainer,
} from '../../components/CommonComponents.js';
import FileUploadButton from '../../components/FileUploadButton.js'
import CustomButton from '../../components/reusedComponents/CustomButton.js'
import camera from '../../images/icons/camera.png';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { getUsername } from '../../utils/TokenUtils';
import { checkAdmin } from '../../utils/RoleUtils';
import { getSheep, getSheepsForUser } from '../../utils/SheepUtils';
import SearchableDropdown from '../../components/SearchableDropdown.js';
import FieldLabel from '../../components/reusedComponents/FieldLabel.js';

import sheepFrontGood from '../../images/sheepFrontGood.png';
import sheepSideGood from '../../images/sheepSideGood.png';
import sheepHidden from '../../images/sheepHidden.png';
import sheepOutOfFocus from '../../images/sheepOutOfFocus.png';

const axios = require('axios');

const ButtonsContainer = styled(ContentContainer)`
  display: flex;
  background: none;
  border: none;
  padding: 10px;
  margin: 0px;
  flex-direction: row;
  gap: 45px;
  align-items: start;
  justify-content: center;
  width: 100%;
`;

const ImagesContainer = styled(ContentContainer)`
  display: flex;
  flex-flow: row wrap;
  background: none;
  border: none;
  padding: 10px;
  margin: 0px;
  gap: 20px;
  align-items: start;
  justify-content: center;
  width: 100%;
`;

const DescriptionArea = styled(ContentContainer)`
  align-items: center;
  background: none;
  border: none;
  padding: 10px;
  margin: 0px;
`;

const TipArea = styled(ContentContainer)`
  align-items: center;
  background: none;
  border: none;
  padding: 10px;
  margin: 0px;
  font-size: 13px;
`;

const FormTitle = styled.div`
  padding: 10px;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
`;

const ThumbnailImage = styled.img`
  border: black solid 2px;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  object-fit cover;
  transition: transform .2s;

  &:hover {
    transform: scale(3);
    object-fit: contain;
    border: none;
    margin-right: 80px;
    margin-left: 80px;
  }
`;

const ExampleText = styled.p`
  color: ${(props) => (props.good ? 'green' : 'red')};
  border: none;
  background: none;
  padding: 0px;
  padding-top: 10px;
  font-size: 13px;
`;

const RemoveButton = styled.button`
  color: red;
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0px;
  padding-top: 10px;
  font-size: 13px;
  cursor: pointer;
`;

export default function SheepFrAddImagesView() {

  const [redirect, setRedirect] = useState('');

  const [animals, setAnimals] = useState([]);
  const [identifier, setIdentifier] = useState('');
  const [images, setImages] = useState([]);
  const [faces, setFaces] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [snapshotUrls, setSnapshotUrls] = useState([]);
  const [cameraOn, setCameraOn] = useState(false);
  const [wsConnected, setWsConnected] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const [videoSource, setVideoSource] = useState(document.querySelector('#camera'));

  //websocket parameters
  const WS_URL = "ws://localhost:3001";
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    WS_URL,
    {
      share: false,
      shouldReconnect: () => true,
      onerror: (err) => {
        console.error(err);
        toast.error(`Websocket connection failed`);
        setWsConnected(false);
      },
    },
  );

  //number of frames processed per second
  const sFPS = 5;

  //clear storage before rendering any components
  useLayoutEffect (() => {
    sessionStorage.clear();
  }, []);

  useEffect (async() => {
    try {
      const fetchedSheeps = await getSheepsForUser(
        getUsername(),
        checkAdmin(),
      );
      setAnimals(fetchedSheeps);
    } catch (err) {
      toast.error(`The following error occured while fetching sheep: ${err.message}`);
    }
  }, []);

  //updates connection of websocket
  useEffect (() => {
    if(readyState === ReadyState.OPEN){
      setWsConnected(true);
    }else{
      setWsConnected(false);
    }
  }, [readyState]);

  //message receiver
  useEffect (() => {
    if(lastMessage){
      var data = JSON.parse(lastMessage.data);
      if (data.face_images){
        setFaces([...faces, ...data.face_images]);
      }
    }
  }, [lastMessage]);

  useEffect (() => {
    let imageArray = faces;
    if(!wsConnected){
      imageArray = [...imageUrls, ...snapshotUrls];  
    }
    if (imageArray.length >= 3 && identifier !== ''){
      setDisabled(false);
    }
    else{
      setDisabled(true);
    }
  }, [imageUrls, snapshotUrls, faces, identifier]);

  // convert all file objects to Data URLs
  useEffect (async() => {
    let encodedImages = [];
    for (let file of images){
        let encodedImage = await fileToData(file);
        if(!imageUrls.some((url) => url === encodedImage)){
          encodedImages.push(encodedImage);
          if(wsConnected){
            sendMessage(encodedImage);
          }
        }
    }
    setImageUrls([...imageUrls, ...encodedImages]);
  }, [images]);

  const fileToData = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(file);
    })
  };

  const getFrame = (source) => {
    let canvas = document.querySelector('canvas');
    let ctx = canvas.getContext('2d');
    ctx.drawImage(source,0,0);
    let dataURL = canvas.toDataURL('image/jpeg');
    return dataURL;
  };

  const getCamera = async () => {
    navigator.mediaDevices.getUserMedia({
      video: {
        width: 480,
        height: 480,
        facingMode: 'environment'
      }
    })
    .then(stream => {
      let video = document.querySelector('#camera')
      setVideoSource(video)
      video.srcObject = stream;
      video.onloadedmetadata = (e) => {
          video.play();
      };
      setCameraOn(true);
    })
    .catch( (err) => {
      toast.error(err.message);
    });
  };

  const snap = () => {
    let snapshot = getFrame(videoSource)
    if (wsConnected){
      sendMessage(snapshot);
    }
    setSnapshotUrls([...snapshotUrls, snapshot]);
  };

  const removeFromArray = (index, array, setArray) => {
    const newArray = [...array];
    newArray.splice(index, 1);
    setArray(newArray);
  };

  const handleSubmit = async () => {
    try{
      setSubmitting(true);
      for (let image of faces){
        let imgObject = {
          identifier: identifier.tag.isoNumber ||
                      identifier.tag.localMgmtNumber ||
                      identifier.tag.tattooNumber ||
                      identifier.tag.usScrapieId,
          imageBuffer: image,
        };
        await axios.post(`/api/sheepimages/`, imgObject);
      }

      toast.dismiss();
      toast.success('All images added successfully');
      setRedirect('/sheep')
    } catch (err) {
      toast.dismiss();
      if (err.response) {
        // In this case, the error is from Axios
        toast.error(err.response.data.message);
      } else {
        // The error isn't from Axios and won't have a response object
        toast.error(err);
      }

      // Allow the user to try submitting again
      setSubmitting(false);
    }
  };

  const paths = [
    { url: '/sheep', title: 'Dashboard' },
    { url: '/sheep/create/fr', title: 'Add Sheep Images' },
  ];

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        {wsConnected ? (
          <ContentContainer
            alignItems="center"
            height="100%"
            flexDirection="column"
          >
            <FormTitle>Add Sheep Images for Facial Recognition</FormTitle>
            <DescriptionArea>Here you can upload images of one of your sheep. These images can be used for facial recognition later. Here are a few tips to get you started:</DescriptionArea>
            <TipArea>- Upload images where the sheep's face is fully visible and well lit</TipArea>
            <TipArea>- Be sure to upload images of the same sheep from different angles, as this will improve its chances of being detected</TipArea>
            <TipArea>- The sheep's face may be difficult to detect if it is partially obscured or out of focus</TipArea>
            <TipArea>- Wool on or around a sheep's face can also make it harder to detect</TipArea>
            <ImagesContainer
              key='example-container'
            >
              <VerticalContainer>
                <ThumbnailImage
                  key='sheep-front-good'
                  src={sheepFrontGood}
                  alt={sheepFrontGood}
                />
                <ExampleText good>Good!</ExampleText>
              </VerticalContainer>
              <VerticalContainer>
                <ThumbnailImage
                  key='sheep-side-good'
                  src={sheepSideGood}
                  alt={sheepSideGood}
                />
                <ExampleText good>Good!</ExampleText>
              </VerticalContainer>
              <VerticalContainer>
                <ThumbnailImage
                  key='sheep-hidden'
                  src={sheepHidden}
                  alt={sheepHidden}
                />
                <ExampleText>Face Obscured</ExampleText>
              </VerticalContainer>
              <VerticalContainer>
                <ThumbnailImage
                  key='sheep-out-of-focus'
                  src={sheepOutOfFocus}
                  alt={sheepOutOfFocus}
                />
                <ExampleText>Face Out of Focus</ExampleText>
              </VerticalContainer>
            </ImagesContainer>
            <FieldLabel
              name='Sheep Identifier'
              required={true}
            />
            <SearchableDropdown
              key='sheep-searchable'
              id='sheepId'
              type='sheepId'
              optionKey='tag'
              options={animals}
              placeholder='Search by Animal ID'
              onChange={setIdentifier}
              onSelect={setIdentifier}
              onSelectionChange={setIdentifier}
            />
            <ButtonsContainer>
              <FileUploadButton
                buttonLabel="Upload Images "
                fileFormat="image/*"
                files={images}
                setFiles={setImages}
                stylePreset='bold'
                icon={true}
              />
              <h2>or</h2>
              <Button
                $stylePreset='dark'
                onClick={getCamera}
              >
                <span>Use Camera </span>
                <Icon width="25px" m="0px" src={camera} />
              </Button>
            </ButtonsContainer>
            <>
              <video
                id='camera' 
                width="480"
                height="480"
                muted 
                autoPlay
                playsInline
                hidden={!cameraOn}
              />
              <canvas 
                id='cameraCanvas'
                width="480"
                height="480"
                hidden
              />
              {cameraOn &&
                <Button
                  $stylePreset='dark'
                  onClick={snap}
                >
                  <span>Snap </span>
                  <Icon width="25px" m="0px" src={camera} />
                </Button>
              }
            </>
            {(imageUrls.length > 0 || snapshotUrls.length > 0) && (
              <VerticalContainer>
                <h2>Images</h2>
                <ImagesContainer
                  key='images-container'
                >
                  {imageUrls.map((thumb, index) => (
                    <VerticalContainer>
                      <ThumbnailImage
                        key={index}
                        src={thumb}
                        alt={thumb}
                      />
                      <RemoveButton
                        onClick={() => {
                            removeFromArray(index,images,setImages)
                            removeFromArray(index,imageUrls,setImageUrls)
                          }}
                      >
                        remove
                      </RemoveButton>
                    </VerticalContainer>
                  ))}
                  {snapshotUrls.map((thumb, index) => (
                    <VerticalContainer>
                      <ThumbnailImage
                        key={index}
                        src={thumb}
                        alt={thumb}
                      />
                      <RemoveButton
                        onClick={() => {
                          removeFromArray(index,snapshotUrls,setSnapshotUrls)
                        }}
                      >
                        remove
                      </RemoveButton>
                    </VerticalContainer>
                  ))}
                </ImagesContainer>
              </VerticalContainer>
            )}
            {faces.length > 0 && (
              <VerticalContainer>
                <h2>Faces</h2>
                <ImagesContainer
                  key='faces-container'
                >
                  {faces.map((thumb, index) => (
                    <VerticalContainer>
                      <ThumbnailImage
                        key={index}
                        src={thumb}
                        alt={thumb}
                      />
                      <RemoveButton
                        onClick={() => {
                          removeFromArray(index,faces,setFaces)
                        }}
                      >
                        remove
                      </RemoveButton>
                    </VerticalContainer>
                  ))}
                </ImagesContainer>
              </VerticalContainer>
            )}
            {faces.length < 3 && (
              <h3>At least 3 faces must be detected from the uploaded images</h3>
            )}
            <CustomButton
              type="button"
              $stylePreset={disabled ? 'submit-disabled' : 'submit-active'}
              onClick={handleSubmit}
              disabled={disabled || submitting}
            >
              Submit
            </CustomButton>
          </ContentContainer>
        ):
          <h2>This service is currently unavailable, please contact an administrator for assistance</h2>
        }
      </div>
    </>
  );
}