import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, HorizontalContainer } from './CommonComponents';
import { ConfirmationModal } from './reusedComponents/ConfirmationModal';

export default function TagAllocationButtons({
  approve,
  deny,
  setReason,
  skip = null,
}) {
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState();

  const buttons = [
    {
      text: 'Approve',
      $stylePreset: 'dark-border',
      onClick: approve
        ? () => {
            setShowModal(true);
            setModalMode('approve');
          }
        : null,
    },
    {
      text: 'Deny',
      $stylePreset: 'dark',
      onClick: deny
        ? () => {
            setShowModal(true);
            setModalMode('deny');
          }
        : null,
    },
    {
      text: 'Skip',
      $stylePreset: 'default',
      onClick: skip,
    },
  ];

  return (
    <>
      <ButtonContainer>
        {buttons.map((buttonInfo) => {
          if (buttonInfo.onClick)
            if (buttonInfo.text !== 'Skip')
              return (
                <Button
                  key={buttonInfo.text}
                  $stylePreset={buttonInfo.$stylePreset}
                  onClick={buttonInfo.onClick}
                >
                  {buttonInfo.text}
                </Button>
              );
            else
              return (
                <SkipLink key={buttonInfo.text} onClick={buttonInfo.onClick}>
                  {buttonInfo.text} &rarr;
                </SkipLink>
              );
        })}
      </ButtonContainer>

      <ConfirmationModal
        message={
          modalMode === 'deny'
            ? 'Are you sure you would like to deny?'
            : 'Are you sure you would like to approve?'
        }
        subMessage={
          modalMode === 'deny' ? 'Please enter the reason for denial' : null
        }
        cancel={() => {
          setShowModal(false);
        }}
        confirm={modalMode === 'deny' ? deny : approve}
        textSet={modalMode === 'deny' ? setReason : null}
        show={showModal}
      />
    </>
  );
}

const ButtonContainer = styled(HorizontalContainer)`
  align-self: center;
  display: flex;
  justify-content: space-between;
`;

const SkipLink = styled.button`
  background: none;
  border: none;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  display: block;
  font-size: 16px;
`;
