import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard';
import SimpleCard from '../components/SimpleCard';
import StripedTable from '../components/StripedTable';
import styled from 'styled-components';
import { getOperationUpdateLogs } from '../utils/OperationUtils';
import { getUsername } from '../utils/TokenUtils';

const columns = [
  {
    id: 'businessName',
    name: 'Business Name',
  },
  {
    id: 'newBusinessName',
    name: 'New Business Name',
  },
  {
    id: 'phoneNumber',
    name: 'Phone Number',
  },
  {
    id: 'newPhoneNumber',
    name: 'New Phone Number',
  },
  {
    id: 'operationType',
    name: 'Type',
  },
  {
    id: 'newOperationType',
    name: 'New Type',
  },
  {
    id: 'cvsp',
    name: 'CVSP',
  },
  {
    id: 'newCvsp',
    name: 'New CVSP',
  },
  {
    id: 'sfcp',
    name: 'SFCP',
  },
  {
    id: 'newSfcp',
    name: 'New SFCP',
  },
];

let tableLogs = [];

const OperationUpdateReportView = () => {
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);

    const username = getUsername();
    //convert bools in updateLogs to strings so they display properly
    getOperationUpdateLogs(username)
      .then((updateLogs) => {
        tableLogs = [];

        updateLogs.reverse().forEach(function (log) {
          let modifiedLogs = {
            businessName: log.businessName,
            newBusinessName:
              log.newBusinessName === log.businessName
                ? 'N/A'
                : log.newBusinessName,
            phoneNumber: log.phoneNumber,
            newPhoneNumber:
              log.newPhoneNumber === log.phoneNumber
                ? 'N/A'
                : log.newPhoneNumber,
            operationType: log.operationType,
            newOperationType:
              log.newOperationType === log.operationType
                ? 'N/A'
                : log.newOperationType,
            cvsp: log.cvsp ? 'Yes' : 'No',
            newCvsp:
              log.newCvsp === log.cvsp ? 'N/A' : log.newCvsp ? 'Yes' : 'No',
            sfcp: log.sfcp ? 'Yes' : 'No',
            newSfcp:
              log.newSfcp === log.sfcp ? 'N/A' : log.newSfcp ? 'Yes' : 'No',
          };

          tableLogs.push(modifiedLogs);
        });
        setLoad(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <PageCard title="View Operation Update Reports" line back>
        <Description>
          <div>
            Operation update reports contain information about operations that
            have had their information modified.
          </div>
        </Description>
        {load ? (
          <>Loading...</>
        ) : (
          <>
            <SimpleCard>
              <StripedTable
                columns={columns}
                rows={tableLogs}
                ShowCount
                max={10}
                paginate
              />
            </SimpleCard>
          </>
        )}
      </PageCard>
    </>
  );
};

const Description = styled.div`
  margin-bottom: 50px;
  line-height: 25px;
`;

export default OperationUpdateReportView;
