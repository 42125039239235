import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';
import React, { useState, useEffect, useRef } from 'react';

const stringIncludes = (str1, str2) => {
  // Convert non-string parameters to strings
  str1 = String(str1);
  str2 = String(str2);

  try {
    return str1.toLowerCase().includes(str2.toLowerCase());
  } catch (error) {
    // Handle  errors that might occur during string manipulation
    console.error('Error while checking string inclusion:', error);
    throw error;
  }
};

const SearchableDropdown = React.memo(
  ({
    placeholder = 'Search',
    type,
    options,
    onChange,
    onSelect,
    optionKey,
    id = 'searchable-dropdown',
    multiselect = false,
    onSelectionChange,
  }) => {
    const [focused, setFocused] = useState(false);
    const [value, setValue] = useState('');
    const [results, setResults] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const initialLoad = useRef(true);

    let optionRenderer;
    let optionFilter;
    let rangeFilter;
    let optionSorter;

    switch (type) {
      case 'premise':
        optionRenderer = ({ name, pid }) => (name ? `${name} - ${pid}` : pid);
        optionFilter = (value, { name, pid }) =>
          stringIncludes(pid, value) || stringIncludes(name, value);
        optionSorter = (a, b) => 
          a.name ? a.name.localeCompare(b.name) : 1;
        break;
      case 'operation':
        optionRenderer = ({ businessName }) => businessName;
        optionFilter = (value, { businessName }) =>
          stringIncludes(businessName, value);
        optionSorter = (a, b) => 
          a.businessName ? a.businessName.localeCompare(b.businessName) : 1;
        break;
      case 'vehicle':
        optionRenderer = ({ name, licensePlateNum }) =>
          name ? `${name} - ${licensePlateNum}` : licensePlateNum;
        optionFilter = (value, { name, licensePlateNum }) =>
          stringIncludes(name, value) || stringIncludes(licensePlateNum, value);
        optionSorter = (a, b) => 
          a.name ? a.name.localeCompare(b.name) : 1;
        break;
      case 'sheep':
        optionRenderer = ({ tag }) => tag.isoNumber;
        optionFilter = (value, option) => {
          const isoNumber = option?.tag?.isoNumber;
          const pid = sessionStorage.getItem('pid');
          if (pid) {
            return (
              isoNumber &&
              stringIncludes(isoNumber, value) &&
              pid &&
              option?.premise?.pid === pid
            );
          } else {
            return isoNumber ? stringIncludes(isoNumber, value) : false;
          }
        };
        rangeFilter = (lower, option) => {
          const isoNumber = option?.tag?.isoNumber;
          const pid = sessionStorage.getItem('pid');
          if (pid) {
            return (
              isoNumber &&
              lower <= Number(isoNumber) &&
              pid &&
              option?.premise?.pid === pid
            );
          } else {
            return isoNumber ? lower <= Number(isoNumber) : false;
          }
        }; 
        optionSorter = (a, b) => 
          a.tag.isoNumber ? a.tag.isoNumber.localeCompare(b.tag.isoNumber) : 1;
        break;
      case 'sheepId':
        optionRenderer = ({ tag, premise }) => (
          tag.isoNumber ||
          tag.usScrapieId ||
          (tag.localMgmtNumber && premise?.pid
            ? `${premise.pid} — ${tag.localMgmtNumber}`
            : tag.localMgmtNumber) ||
          (tag.tattooNumber && premise?.pid
            ? `${premise.pid} — ${tag.tattooNumber}`
            : tag.tattooNumber)
        );
        optionFilter = (value, option) => {
          const isoNumber = option?.tag?.isoNumber;
          const usScrapieId = option?.tag?.usScrapieId;
          const localMgmtNumber = option?.tag?.localMgmtNumber;
          const tattooNumber = option?.tag?.tattooNumber;
          const pid = sessionStorage.getItem('pid');
          if (pid) {
            return (
              (stringIncludes(isoNumber, value) ||
              stringIncludes(usScrapieId, value) ||
              stringIncludes(localMgmtNumber, value) ||
              stringIncludes(tattooNumber, value))&&
              pid &&
              option?.premise?.pid === pid
            );
          } else {
            return (
              stringIncludes(isoNumber, value) ||
              stringIncludes(usScrapieId, value) ||
              stringIncludes(localMgmtNumber, value) ||
              stringIncludes(tattooNumber, value)
            );
          }
        };
        break;
      default:
        throw new Error('Invalid SearchDropdown type');
    }

    // Handle displaying the dropdown
    useEffect(() => {
      const handleClickOutside = (e) => {
        if (!document.getElementById(`${id}-container`)?.contains(e.target)) {
          setFocused(false);
        }
      };
      window.addEventListener('click', handleClickOutside);
      return () => window.removeEventListener('click', handleClickOutside);
    }, [id]);

    useEffect(() => {
      if (results.length === 1 && results[0][optionKey] === value) {
        onSelect(results[0]);
      }
    }, [results]);

    // Handle loading of data from session storage, used to ensure consistency between steps
    useEffect(() => {
      const savedOptions = sessionStorage.getItem(id);
      if (savedOptions) {
        try {
          const parsedOptions = JSON.parse(savedOptions);
          if (multiselect) {
            if (Array.isArray(parsedOptions)) {
              setSelectedOptions(parsedOptions);
            } else {
              setValue(parsedOptions);
            }
          } else {
            const parsedOption = JSON.parse(savedOptions);
            setValue(getOptionValue(parsedOption, optionKey));
            onSelect(parsedOption);
          }
        } catch (err) {
          setValue(savedOptions);
          initialLoad.current = false;
          return;
        }
      }
      initialLoad.current = false;
    }, [id, multiselect, optionKey]);

    useEffect(() => {
      if (!initialLoad.current && multiselect) {
        onSelectionChange(selectedOptions);
        sessionStorage.setItem(id, JSON.stringify(selectedOptions));
      }
    }, [selectedOptions, id, onSelectionChange]);

    const getOptionValue = (option, key) => {
      if (key === 'tag.isoNumber') {
        return option?.tag?.isoNumber;
      }
      else if(key === 'tag'){
        return optionRenderer(option);
      }
      return key.split('.').reduce((acc, part) => acc && acc[part], option);
    };

    const handleOptionClick = (option) => {
      const optionValue = getOptionValue(option, optionKey);

      if (multiselect) {
        const isSelected = selectedOptions.some(
          (selected) => getOptionValue(selected, optionKey) === optionValue,
        );

        if (!isSelected) {
          setSelectedOptions([...selectedOptions, option]);
        } else {
          setSelectedOptions(
            selectedOptions.filter(
              (selected) => getOptionValue(selected, optionKey) !== optionValue,
            ),
          );
        }
      } else {
        sessionStorage.setItem(id, JSON.stringify(option));
        setValue(optionValue);
        onSelect(option);
        setFocused(false);
      }
    };

    return (
      <SearchContainer
        id={`${id}-container`}
      >
        <input
          autoComplete="off"
          placeholder={placeholder}
          id={id}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            let values = e.target.value.split('-');
            if(values.length === 2){
              setResults(
                options
                  .filter((option) => rangeFilter(Number(values[0]), option))
                  .sort(optionSorter),
              );

              if(Number(values[0]) < Number(values[1])){
                let optionsInRange = options.filter((option) => 
                  (Number(getOptionValue(option, optionKey)) >= Number(values[0]) && 
                  Number(getOptionValue(option, optionKey)) <= Number(values[1]) &&
                  !selectedOptions.some(
                    (selected) => getOptionValue(selected, optionKey) === getOptionValue(option, optionKey),
                  )
                ));
                setSelectedOptions([...selectedOptions, ...optionsInRange]);
              }
            }
            else{
              setResults(
                options
                  .filter((option) => optionFilter(e.target.value, option))
                  .sort(optionSorter),
              );
            }
            if (onChange) {
              onChange(e.target.value);
              sessionStorage.setItem(id, e.target.value);
            }
          }}
          onClick={() => {
            setResults(
              options
                .filter((option) => optionFilter(value, option))
                .sort(optionSorter),
            );
            setFocused(true);
          }}
        />
        {focused && (
          <DropdownList>
            {results.map((option, index) => (
              <OptionItem
                key={index}
                onClick={() => handleOptionClick(option)}
                selected={selectedOptions.some(
                  (selected) => getOptionValue(selected, optionKey) === getOptionValue(option, optionKey)
                )}
              >
                {optionRenderer(option)}
              </OptionItem>
            ))}
          </DropdownList>
        )}
        {!focused && multiselect && (
          <SelectedOptions>
            {selectedOptions.map((selected, index) => (
              <SelectedOption key={index}>
                {optionRenderer(selected)}
                <RemoveOptionButton
                  type="button"
                  onClick={() =>
                    setSelectedOptions(
                      selectedOptions.filter(
                        (item) =>
                          getOptionValue(item, optionKey) !==
                          getOptionValue(selected, optionKey),
                      ),
                    )
                  }
                >
                  &times;
                </RemoveOptionButton>
              </SelectedOption>
            ))}
          </SelectedOptions>
        )}
      </SearchContainer>
    );
  },
);

SearchableDropdown.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  multiselect: PropTypes.bool,
  selectedValues: PropTypes.array,
  onSelectionChange: PropTypes.func.isRequired,
};

const SearchContainer = styled.div`
  position: relative;
  overflow: visible;
  > input {
    width: 100%;
    height: 35px;
    border: ${(props) =>
      props.border ? props.border : `1px solid ${COLORS.primary}`};
    border-radius: 15px;
    background: ${(props) => (props.background ? props.background : 'white')};
    font-size: 14px;
    outline: none;
    padding: 8px 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  > div > div:hover {
    backdrop-filter: brightness(0.8);
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
`;


const DropdownList = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  background: ${COLORS.secondary};
  border: 1px solid ${COLORS.primary};
  overflow-y: auto;
  z-index: 100;
`;

const OptionItem = styled.div`
  padding: 8px 15px;
  cursor: pointer;
  background: ${(props) => props.selected ? COLORS.selected : 'none'};
`;

const SelectedOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
`;

const SelectedOption = styled.div`
  background-color: ${COLORS.primary};
  color: white;
  padding: 3px 8px;
  border-radius: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
`;

const RemoveOptionButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  margin-left: 5px;
`;

export default SearchableDropdown;
