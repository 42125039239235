import {
  multiselectSearchableDropdownField,
  searchableDropdownField,
} from './commonFields';
import { formatDateField } from '../utils/TimeUtils';

const getFormFields = (
  premises,
  allPremises,
  animals,
  handleAnimalSelect,
  selectedAnimals,
  setSelectedDeparturePID,
  vehicles,
  setSelectedVehicle,
  handleSelectionChange,
  setTypedSheep,
) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Complete this form to move animals into your premise, if you do not have a premise, create one here:  `,
      LinkData: { label: 'Add a Premise', path: '/premises/create' },
    },
    {
      name: 'Destination PID',
      id: 'destinationPID',
      type: 'select',
      options: premises,
      required: true,
    },
    {
      ...searchableDropdownField(
        'Departure PID',
        'departurePID',
        'premise',
        'pid',
        allPremises,
        'Search by PID...',
        setSelectedDeparturePID,
        'Fill out the PID of the departure premise',
        true,
      ),
    },
    {
      ...searchableDropdownField(
        'Vehicle Identification',
        'vehicle-search',
        'vehicle',
        'licensePlateNum',
        vehicles,
        'Search by Vehicle ID',
        setSelectedVehicle,
        'Fill out the ID of the transport vehicle',
        true,
      ),
    },
  ];

  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the identification of the animals being moved in. You can also enter a range of IDs separated by a dash (eg: 124000311111115-124000311111125)`,
    },
    {
      ...multiselectSearchableDropdownField(
        'Animal IDs',
        'animals',
        'sheep',
        'tag.isoNumber',
        animals,
        'Search by Animal ID',
        handleAnimalSelect,
        'Fill out the IDs of the animals, separate multiple values with a comma, or select your own',
        true,
        selectedAnimals,
        handleSelectionChange,
        setTypedSheep,
      ),
    },
  ];

  const stepThreeFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the move in details for the report`,
    },
    {
      name: 'Move-in Date',
      id: 'receivedDate',
      type: 'date',
      required: true,
      value: formatDateField(new Date()),
      errorMessage: 'Move-in Date cannot be in the future',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Move-in Time',
      id: 'receivedTime',
      type: 'time',
      required: true,
      errorMessage: 'Move-in Date cannot be in the future',
      value: new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
      }),
      validityCheck: (fieldValues) => {
        const moveInDateField = stepThreeFields.find(
          (field) => field.id === 'receivedDate',
        );
        const selectedDateTime = new Date(
          `${moveInDateField.value} ${fieldValues.value}`,
        );
        return selectedDateTime < Date.now();
      },
    },
    {
      name: 'Unloading Date',
      id: 'unloadDate',
      type: 'date',
      required: true,
      value: formatDateField(new Date()),
      errorMessage:
        'Unloading Date cannot precede Move-In Date or be in the future',
      validityCheck: (fieldValues) => {
        const moveInDateField = stepThreeFields.find(
          (field) => field.id === 'receivedDate',
        );
        if (
          new Date(fieldValues.value).getTime() > Date.now() ||
          new Date(fieldValues.value) < new Date(moveInDateField.value)
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Unloading Time',
      id: 'unloadTime',
      type: 'time',
      required: true,
      value: new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
      }),
      errorMessage: 'Unload date cannot be in the future',
      validityCheck: (fieldValues) => {
        const moveInDateField = stepThreeFields.find(
          (field) => field.id === 'unloadDate',
        );
        const selectedDateTime = new Date(
          `${moveInDateField.value} ${fieldValues.value}`,
        );
        return selectedDateTime < Date.now();
      },
    },
  ];

  // Grouping steps into a single array
  const addMoveInFields = [
    {
      name: 'Transport Identification',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
    {
      name: 'Transport Information',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'At least one animal ID is required.',
    },
    {
      name: 'Move In Information',
      fields: stepThreeFields,
      isValid: true,
      errorMessage: 'Required Fields Missing',
    },
  ];
  return addMoveInFields;
};

export default getFormFields;
