import React from 'react';
import styled from 'styled-components';
import { HorizontalContainer } from './CommonComponents';
import { COLORS } from '../utils/Constants';

export default function TagAllocationData({ data }) {
  const allocationInfo = [
    {
      subHeader: 'Premise Information',
      values: [
        { name: 'Premise ID (PID)', value: data.pid },
        { name: 'Legal Description of Site', value: data.description },
        { name: 'Location (Latitude, Longitude)', value: data.location },
      ],
    },
    {
      subHeader: 'Contact Information',
      values: [
        { name: 'Name', value: data.name },
        { name: 'Phone Number', value: data.phoneNumber },
        { name: 'Email Address', value: data.email },
      ],
    },
    {
      subHeader: 'Request Information',
      values: [
        { name: 'Tag Manufacturer', value: data.manufacturer },
        { name: 'Number of Tags Requested', value: data.numberOfTags },
      ],
    },
  ];

  return (
    <TagAllocationContentContainer>
      {allocationInfo.map((info) => {
        return (
          <TagAllocationInfoDiv key={info.subHeader}>
            <SubHeader>{info.subHeader}</SubHeader>
            {info.values.map((subInfo) => {
              return (
                <Info
                  key={subInfo.name}
                  name={subInfo.name}
                  value={subInfo.value}
                />
              );
            })}
          </TagAllocationInfoDiv>
        );
      })}
    </TagAllocationContentContainer>
  );
}

const TagAllocationContentContainer = styled(HorizontalContainer)`
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 20px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 5px lightgrey;
`;

const TagAllocationInfoDiv = styled.div`
  align-self: stretch;
  padding: 15px 20px;
  width: 100%;
  border-right: 1px solid ${COLORS.primary};
  &:last-child {
    border-right: none;
  }
`;

const SubHeader = styled.h3`
  color: ${COLORS.primary};
  margin-top: 0px;
  margin-bottom: 20px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const InfoDiv = styled.div`
  padding-bottom: 20px;
`;

export const Info = ({ name, value }) => {
  return (
    <InfoDiv>
      <Bold>{name}:&nbsp;</Bold>
      <span>{value}</span>
    </InfoDiv>
  );
};
