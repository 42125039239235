// PremiseRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PremisesView from '../views/Premise/PremisesView';
import PremiseCreate from '../views/Premise/PremiseCreate';
import PremiseView from '../views/Premise/PremiseView';
import PremiseUpdate from '../views/Premise/PremiseUpdate';

const PremiseRouter = () => (
  <Switch>
    <Route exact path="/premises" component={PremisesView} />
    <Route exact path="/premises/create" component={PremiseCreate} />
    <Route exact path="/premises/:id" component={PremiseView} />
    <Route exact path="/premises/update/:id" component={PremiseUpdate} />
  </Switch>
);

export default PremiseRouter;
