import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { getUsername } from '../../utils/TokenUtils';
import { getPremisesForUser } from '../../utils/PremiseUtils';
import { checkAdmin } from '../../utils/RoleUtils';
import { getSheepsForUser } from '../../utils/SheepUtils';
import AddMedical from '../../components/Forms/AddMedical';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath';
import { ContentContainer } from '../../components/CommonComponents';

const MedicalReportCreateView = () => {
  const [premises, setPremises] = useState([]);
  const [allAnimals, setAllAnimals] = useState([]);

  useEffect(() => {
    getPremisesForUser(getUsername())
      .then((premises) =>
        setPremises(
          premises.map((premise) => ({
            name: premise.name
              ? `${premise.pid} — ${premise.name}`
              : premise.pid,
            value: premise.pid,
          })),
        ),
      )
      .catch((err) => {
        if (err.response && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Unable to retrieve your premises at this time');
        }
      });

    getSheepsForUser(getUsername(), checkAdmin())
      .then((animals) => setAllAnimals(animals))
      .catch((err) => {
        if (err.response && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error('Unable to retrieve your sheep at this time');
        }
      });
  }, []);

  const paths = [
    { url: '/reports/medical/list', title: 'Dashboard' },
    { url: '/reports/medical/create', title: 'Add a Medical Report' },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddMedical errors={{}} animals={allAnimals} premises={premises} />
        </ContentContainer>
      </div>
    </>
  );
};

export default MedicalReportCreateView;
