import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Styled Components
const StripedTableContentContainer = styled.div`
  border: none;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 30px;
  width: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: ${COLORS.primary};
  padding: 10px 15px;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 15px;
`;

const TableHeaderContainer = styled.div`
  background: ${COLORS.primary};
  padding: 10px 25px;
  font-weight: bold;
  color: white;
  flex: 1;
`;

const BodyContainer = styled.div`
  border: 2px solid green;
  border-radius: 10px;
  padding: 5px 0px;
  overflow-y: auto;
  height: 400px;
`;

const TableRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px 5px;
  cursor: pointer;
  background-color: ${({ selected }) => (selected ? COLORS.selected : 'white')};
  &:hover {
    background-color: ${COLORS.selected};
  }
`;

const TableDataContainer = styled.div`
  flex: 1;
  padding: 10px 15px;
`;

const StyledSpan = styled.span`
  display: block;
  word-wrap: break-word;
`;

const LoadingDiv = styled.div`
  text-align: center;
`;

// Component to display a spinner while loading
const LoadingSpinner = () => (
  <LoadingDiv>
    <FontAwesomeIcon icon="spinner" spin />
    &nbsp;Loading...
  </LoadingDiv>
);

// Main Component
const StripedScrollableTable = ({
  rows,
  columns,
  onRowClick,
  selectedRows,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  const handleRowClick = (row) => {
    onRowClick(row);
  };

  const renderRowValues = (r, c) => {
    if (!r || typeof r[c.id] === 'undefined') {
      return '-';
    }
    return Array.isArray(r[c.id]) && r[c.id].length > 1
      ? r[c.id].slice(0, 1).join(', ') + '...'
      : r[c.id];
  };

  return (
    <StripedTableContentContainer>
      <HeaderContainer>
        {columns.map((c) => (
          <TableHeaderContainer key={c.id} columnId={c.id}>
            {c.name}
          </TableHeaderContainer>
        ))}
      </HeaderContainer>

      <BodyContainer>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <>
            {rows.map((r, index) => (
              <TableRowContainer
                key={index} // Use index as the key
                selected={selectedRows.includes(r.id)}
                onClick={() => handleRowClick(r)}
              >
                {columns.map((c) => (
                  <TableDataContainer key={c.id}>
                    <StyledSpan>{renderRowValues(r, c)}</StyledSpan>
                  </TableDataContainer>
                ))}
              </TableRowContainer>
            ))}
          </>
        )}
      </BodyContainer>
    </StripedTableContentContainer>
  );
};

StripedScrollableTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  selectedRows: PropTypes.array,
};

StripedScrollableTable.defaultProps = {
  selectedRows: [],
};

export default StripedScrollableTable;
