import React, { useEffect, useState } from 'react';
import StripedTable from './StripedTable'; // Adjust import as needed
import styled from 'styled-components';

const sheepTableColumns = [
  {
    id: 'isoNumber',
    name: 'ISO Number',
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
  },
  {
    id: 'pid',
    name: 'Current Premise',
  },
  {
    id: 'alive',
    name: 'Alive',
  },
];

const ReportedAnimalsList = ({ sheep }) => {
  const [reportSheep, setReportSheep] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    try {
      if (sheep && sheep.length > 0) {
        const sheepDetails = sheep.map((sheep) => ({
          _id: sheep._id,
          isoNumber: sheep.tag.isoNumber ? sheep.tag.isoNumber : 'N/A',
          localMgmtNumber: sheep.tag.localMgmtNumber
            ? sheep.tag.localMgmtNumber
            : 'N/A',
          tattooNumber: sheep.tag.tattooNumber ? sheep.tag.tattooNumber : 'N/A',
          pid: sheep.premise ? sheep.premise.pid : 'N/A',
          alive: sheep.active ? 'Yes' : 'No',
        }));

        setReportSheep(sheepDetails);
        setIsLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  }, [sheep]);

  return (
    <StyledReportedAnimalsList>
      {isLoading ? (
        <p>Loading...</p>
      ) : reportSheep.length > 0 ? (
        <StripedTable
          columns={sheepTableColumns}
          rows={reportSheep}
          max={10}
          paginate
        />
      ) : (
        <h3>No other animals record found in the report.</h3>
      )}
    </StyledReportedAnimalsList>
  );
};

const StyledReportedAnimalsList = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto; /* Enable scrolling if content exceeds container size */
`;

export default ReportedAnimalsList;
