import axios from 'axios';
import { sortReportsByDate } from '../../components/SheepReportComponents';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import { localDateFormatter } from '../../utils/TimeUtils';
import React from 'react';
import { getSelectedRole } from '../../utils/RoleUtils';

const getSightingReports = async () => {
  try {
    const res = await axios.get('/api/animalsighting', {
      params: { selectedRole: getSelectedRole() },
    });
    return res.data;
  } catch (err) {
    throw err.response.data.message;
  }
};

const tempExportColumns = [
  {
    id: 'reportDate',
    name: 'Date Sighted',
    required: true,
  },
  {
    id: 'location',
    name: 'Location',
  },
  {
    id: 'isoNumber',
    name: 'ISO Number',
    required: true,
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
    required: true,
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
    required: true,
  },
  {
    id: 'reporterName',
    name: 'Reporter of Sighting',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const SightingReportListView = () => {
  const fetchSightingReport = async () => {
    //var fetchedReport
    let fetchedReport = await getSightingReports();
    fetchedReport.forEach((r) => {
      r.reportDate = localDateFormatter(r.reportDate);
      r.premiseID = r.location;
    });
    return sortReportsByDate(fetchedReport);
  };

  return (
    <SheepReportListComponent
      title="Sheep Sighting"
      description="A sheep sighting report records when animals 
      were confirmed as present at a premises on a specific date 
      and time. You are not required to sight animals, but sighting 
      reports can be useful tools for confirming animal inventories. 
      You can view and create sighting reports here."
      fetchReportsFunction={fetchSightingReport}
      detailEndpoint="/reports/sighting/"
      createEndpoint="/reports/sighting/create"
      csvEndpoint="/reports/sighting/csvCreate"
      columns={tempExportColumns}
    />
  );
};

export default SightingReportListView;
