import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import { useParams } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { Info } from '../../components/TraceSheepDetails';
import { localTimeFormatter } from '../../utils/TimeUtils';

const getReplaceReports = (id) => {
  return axios
    .get(`/api/tagreplacement/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const replaceFields = [
  {
    name: 'Old Identifiers',
    id: 'oldIsoNumber',
    localMan: 'oldLocalMgmtNumber',
    tattoo: 'oldTattooNumber',
    Scrapie: 'oldUsScrapieId',
  },
  {
    name: 'New Identifiers',
    id: 'newIsoNumber',
    localMan: 'newLocalMgmtNumber',
    tattoo: 'newTattooNumber',
    Scrapie: 'newUsScrapieId',
  },
];

const TagReplaceDetailView = () => {
  const [replaceReport, setReplaceReport] = useState();
  const params = useParams();
  const id = params.id;

  const fetchReplaceReport = async () => {
    let fetchedReport = await getReplaceReports(id);

    if (
      fetchedReport.location &&
      fetchedReport.location.coordinates &&
      fetchedReport.location.coordinates.length === 2
    ) {
      fetchedReport.location = `${fetchedReport.location.coordinates[1]}, ${fetchedReport.location.coordinates[0]}`;
    } else {
      fetchedReport.location = '';
    }

    setReplaceReport(fetchedReport);
  };

  useEffect(() => {
    fetchReplaceReport();
  }, []);

  const detailColumns = [
    { id: 'Parameters', name: 'Parameters', required: true },
    { id: 'ISO Number', name: 'ISO Number', required: true },
    {
      id: 'Local Management Num',
      name: 'Local Management Num',
      required: true,
    },
    { id: 'Tattoo Number', name: 'Tattoo Number', required: true },
    { id: 'US Scrapie ID', name: 'US Scrapie ID', required: true },
  ];

  const tableData = replaceFields.map((field) => ({
    _id: `${field.id || ''}${field.localMan || ''}${field.tattoo || ''}${
      field.Scrapie || ''
    }`,
    Parameters: field.name,
    'ISO Number': replaceReport ? replaceReport[field.id] || 'N/A' : 'N/A',
    'Local Management Num': replaceReport
      ? replaceReport[field.localMan] || 'N/A'
      : 'N/A',
    'Tattoo Number': replaceReport
      ? replaceReport[field.tattoo] || 'N/A'
      : 'N/A',
    'US Scrapie ID': replaceReport
      ? replaceReport[field.Scrapie] || 'N/A'
      : 'N/A',
  }));

  return (
    <>
      <PageCard
        title=" Tag Replacement Report Details"
        verify={`/verify/replace/${id}`}
        line
        back
      >
        <SimpleCard title="Tag Information" size="md">
          <StripedTable
            columns={detailColumns}
            rows={tableData}
            ShowCount
            max={10}
          />
        </SimpleCard>

        {replaceReport ? (
          <>
            <SimpleCard title="Report Information" size="md">
              <HalfDiv>
                <Info
                  name="Replacement Date"
                  value={localTimeFormatter(replaceReport.replacementDate)}
                />
                <Info
                  name="PID"
                  value={replaceReport.pid ? replaceReport.pid : 'N/A'}
                />
                <Info
                  name="Vehicle"
                  value={replaceReport.vehicle ? replaceReport.vehicle : 'N/A'}
                />
              </HalfDiv>
              <HalfDiv>
                {replaceReport.location && (
                  <Info
                    name="Geolocation (Latitude, Longitude)"
                    value={replaceReport.location}
                  />
                )}
              </HalfDiv>
            </SimpleCard>
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
  margin-bottom: 20px;
`;

export default TagReplaceDetailView;
