import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StripedTable from './StripedTable';
import { agGreen, viewColor } from './Colors';
import SimpleCard from './SimpleCard';
import { useHistory } from 'react-router-dom';
import { getSheepByIdentifiers, getSheepByISO } from '../utils/SheepUtils.js';
import { toast } from 'react-toastify';

export const Info = ({ name, value }) => {
  return (
    <InfoDiv>
      <FieldSpan>{name}</FieldSpan>
      <ValueSpan>{value}</ValueSpan>
    </InfoDiv>
  );
};

Info.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
};

const sheepTableColumns = [
  {
    id: 'isoNumber',
    name: 'ISO Number',
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
  },
  {
    id: 'usScrapieId',
    name: 'US Scrapie ID',
  },
  {
    id: 'premise.pid',
    name: 'Current Premise',
  },
  {
    id: 'alive',
    name: 'Alive',
  },
];

const formatDate = (date) => {
  const formattedDate = new Date(date).toISOString().split('T')[0];
  return formattedDate;
};

export default function TraceSheepDetails(props) {
  const {
    dateOfArrival,
    dateOfDeparture,
    premise,
    userInfo,
    sheepStatus,
    tracedList,
    sheepId,
  } = props;

  const [firstOrderContact, setFirstOrderContact] = useState([]);
  const [secondOrderContact, setSecondOrderContact] = useState([]);
  const history = useHistory();

  const handleRowClick = async (row) => {
    let sheep;
    if (row.isoNumber) {
      sheep = await getSheepByISO(row.isoNumber);
    } else {
      let identifier =
        row.localMgmtNumber || row.tattooNumber || row.usScrapieId;
      let pid = row['premise.pid'];
      sheep = await getSheepByIdentifiers(identifier, pid);
    }
    if (!sheep) {
      toast.error('An error has occurred');
    }
    history.push(`/trace/reports/${sheep._id}`);
  };

  useEffect(() => {
    setFirstOrderContact(tracedList[0] || []);
    setSecondOrderContact(tracedList[1] || []);
  }, [tracedList]);

  let premiseLocation;
  if (premise && premise.location) {
    premiseLocation = premise.location;
  } else {
    premiseLocation = 'N/A';
  }

  return (
    <DetailContainer>
      <SimpleCardContainer>
        <SimpleCard title="Status" size="md">
          <Info
            name="Sheep Status"
            value={
              userInfo && userInfo.firstName == 'N/A' ? (
                <>
                  This sheep is not in our system at this point in time, we are
                  unaware of its status
                </>
              ) : sheepStatus === 'Active' ? (
                <AliveText>{sheepStatus}</AliveText>
              ) : (
                <DeadText>{sheepStatus}</DeadText>
              )
            }
          />
        </SimpleCard>
      </SimpleCardContainer>
      <SimpleCardContainer>
        <SimpleCard title="Date Information" size="md">
          <Info
            name="Date of Arrival at Location"
            value={
              dateOfArrival !== undefined ? formatDate(dateOfArrival) : 'N/A'
            }
          />
          <Info
            name="Date of Departure From Location"
            value={
              dateOfArrival === undefined
                ? 'N/A'
                : dateOfDeparture !== ''
                ? formatDate(dateOfDeparture)
                : 'This sheep has not departed yet'
            }
          />
        </SimpleCard>
      </SimpleCardContainer>
      <SimpleCardContainer>
        <SimpleCard title="Owner Information" size="md">
          <Info
            name="Owner Name"
            value={
              !userInfo
                ? 'This user is not in our system'
                : userInfo.firstName && userInfo.lastName
                ? userInfo.firstName + ' ' + userInfo.lastName
                : 'N/A'
            }
          />
          <Info
            name="Email"
            value={!userInfo || !userInfo.email ? 'N/A' : userInfo.email}
          />
          <Info
            name="Phone Number"
            value={!userInfo || !userInfo.phone ? 'N/A' : userInfo.phone}
          />
        </SimpleCard>
      </SimpleCardContainer>
      <SimpleCardContainer>
        <SimpleCard title="Premise Information" size="md">
          <Info
            name="Premise ID"
            value={
              premise && premise.pid
                ? premise.pid
                : 'The sheep is not on a premise that is known to the system'
            }
          />
          <Info
            name="Premise Name"
            value={premise && premise.name ? premise.name : 'N/A'}
          />
          <Info
            name="Location (Latitude, Longitude)"
            value={premiseLocation ? premiseLocation : 'N/A'}
          />
          <Info
            name="Associated Operation"
            value={
              premise && premise.operation
                ? premise.operation.businessName
                : 'N/A'
            }
          />
        </SimpleCard>
      </SimpleCardContainer>
      <SimpleCardContainer>
        <SimpleCard title="Sheep Information" size="md">
          <SectionDescription>
            See all the sheep which came in contact with the sheep you searched
            for
          </SectionDescription>
          <h2>First Order Contact</h2>
          <SectionTableContainer>
            {firstOrderContact.length > 0 ? (
              <StripedTable
                columns={sheepTableColumns}
                rows={firstOrderContact.map((sheep) => ({
                  _id: sheep.tag.isoNumber,
                  isoNumber: sheep.tag.isoNumber,
                  localMgmtNumber: sheep.tag.localMgmtNumber,
                  tattooNumber: sheep.tag.tattooNumber,
                  usScrapieId: sheep.tag.usScrapieId,
                  'premise.pid': sheep.premise ? sheep.premise.pid : 'N/A',
                  alive: sheep.active ? 'Yes' : 'No',
                }))}
                max={10}
                paginate
                onRowClick={handleRowClick}
              />
            ) : (
              <h3>
                No records of sheep coming into contact with the sheep you
                searched for.
              </h3>
            )}
          </SectionTableContainer>
          <h2>Second Order Contact</h2>
          <SectionTableContainer>
            {secondOrderContact && secondOrderContact.length > 0 ? (
              <StripedTable
                columns={sheepTableColumns}
                rows={secondOrderContact.map((sheep) => ({
                  _id: sheep.tag.isoNumber,
                  isoNumber: sheep.tag.isoNumber,
                  localMgmtNumber: sheep.tag.localMgmtNumber,
                  tattooNumber: sheep.tag.tattooNumber,
                  usScrapieId: sheep.tag.usScrapieId,
                  'premise.pid': sheep.premise ? sheep.premise.pid : 'N/A',
                  alive: sheep.active ? 'Yes' : 'No',
                }))}
                max={10}
                paginate
                onRowClick={handleRowClick}
              />
            ) : (
              <h3>
                No records of other sheep making contact through the searched
                sheep in the second order.
              </h3>
            )}
          </SectionTableContainer>
        </SimpleCard>
      </SimpleCardContainer>

      <ButtonContainer>
        <Link to="/">
          <button style={{ background: agGreen }}>Return to Home Page</button>
        </Link>
        <Link to="/trace">
          <button style={{ background: viewColor }}>
            Return to Trace Dashboard
          </button>
        </Link>
        <Link to={`/trace/reports/${sheepId}`}>
          <button style={{ background: viewColor }}>
            View Reports Timeline
          </button>
        </Link>
      </ButtonContainer>
    </DetailContainer>
  );
}

TraceSheepDetails.propTypes = {
  dateOfArrival: PropTypes.any,
  dateOfDeparture: PropTypes.any,
  premise: PropTypes.shape({
    location: PropTypes.string,
    name: PropTypes.any,
    operation: PropTypes.shape({
      businessName: PropTypes.any,
    }),
    pid: PropTypes.any,
  }),
  sheepId: PropTypes.any,
  userInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.any,
    username: PropTypes.any,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
  sheeps: PropTypes.any,
};

const DetailContainer = styled.div`
  & > div {
    margin: 1rem auto 2rem;
  }
`;

const InfoDiv = styled.div`
  font-size: 1.2rem;
  margin: 0.7em;
`;

const FieldSpan = styled.span`
  display: inline-block;
  width: 30ch;
  font-weight: 500;
`;

const ValueSpan = styled.span`
  font-style: italic;
`;

const ButtonContainer = styled.div`
  margin: 0 auto;
  width: max-content;

  button {
    margin: 0 1em;
    border: none;
    border-radius: 8px;
    padding: 0.7em 1.2em;
    color: white;
    font-size: 1.15rem;
    cursor: pointer;
  }
`;

const AliveText = styled.div`
  background-color: green;
  border-radius: 8px;
  padding: 0.5em;
  width: max-content;
  display: inline-block;
`;

const DeadText = styled.div`
  background-color: rgb(211, 0, 0);
  border-radius: 8px;
  padding: 0.5em;
  width: max-content;
  display: inline-block;
`;

const SectionDescription = styled.div`
  margin: 1em;
  font-size: 1.1rem;
`;

const SectionTableContainer = styled.div`
  margin: 1em auto 5em;
`;

const SimpleCardContainer = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 1.5em;
  border: 1px solid #000000;
  margin-bottom: 2em;
`;
