import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  ContentContainer,
  HorizontalContainer,
  Icon,
} from './CommonComponents';
import left from '../images/icons/chevron_left.png';
import right from '../images/icons/chevron_right.png';
import columnDown from '../images/icons/column-down-arrow.png';
import columnUp from '../images/icons/column-up-arrow.png';
import columnIdle from '../images/icons/column-unselected-arrow.png';
import { COLORS } from '../utils/Constants';

/**
 * Table with column header sort and white design style
 * @param rows - data for the table
 * @param columns - column headers and ids corresponding to row keys
 * @param max - maximum amount of rows to show per page,
 * @param handleSort - a function to handle the sorting rows \
 * (if this^ is null then sorting will be disabled) (check TagAllocationReviewListView.js for an example of this)
 * @param defaultSortColumnId - if sorting is enabled, the column id which should be sorted on default)
 * Note: If you pass a column with id = action, it will be treated as though you are passing a component
 */

export default function CleanTable({
  rows = [],
  columns = [],
  max = 10,
  handleSort = null,
  defaultSortColumnId = null,
}) {
  const [page, setPage] = useState(1);
  const [sortedColumn, setSortedColumn] = useState(defaultSortColumnId);
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    if (handleSort && defaultSortColumnId) {
      handleSort(defaultSortColumnId);
    }
  }, []);

  if (
    rows.slice((page - 1) * max, page * max).length === 0 &&
    rows.length !== 0
  ) {
    setPage(page - 1);
  }

  const PaginationComponent = () => {
    return (
      <HorizontalContainer mobile="none" style={{ alignSelf: 'center' }}>
        <Icon
          width="25px"
          src={left}
          hover="filter: opacity(0.5)"
          disabled={page === 1}
          onClick={() => {
            if (page > 1) {
              setPage(page - 1);
            }
          }}
        />
        {page - 1 >= 1 && (
          <h3
            style={{ paddingBlock: '5px', paddingInline: '10px' }}
            onClick={() => {
              setPage(page - 1);
            }}
          >
            {page - 1}
          </h3>
        )}
        <h3
          style={{
            background: COLORS.secondary,
            borderRadius: '10px',
            paddingBlock: '5px',
            paddingInline: '10px',
          }}
        >
          {page}
        </h3>
        {page + 1 <= Math.ceil(rows.length / max) && (
          <h3
            style={{ paddingBlock: '5px', paddingInline: '10px' }}
            onClick={() => {
              setPage(page + 1);
            }}
          >
            {page + 1}
          </h3>
        )}
        <Icon
          width="25px"
          src={right}
          hover="filter: opacity(0.5)"
          disabled={page === Math.ceil(rows.length / max)}
          onClick={() => {
            if (page < Math.ceil(rows.length / max)) {
              setPage(page + 1);
            }
          }}
        />
      </HorizontalContainer>
    );
  };

  const changeSort = (id) => {
    if (sortedColumn !== id) {
      setSortOrder('asc');
      setSortedColumn(id);
      handleSort(id);
    } else {
      if (sortOrder === 'asc') {
        setSortOrder('desc');
        handleSort(`-${id}`);
      } else {
        setSortOrder('asc');
        handleSort(id);
      }
    }
  };

  return (
    <WhiteTableContentContainer>
      <WhiteTableContainer>
        <TableHeaderContainer>
          {columns.map((c) => {
            return c.id !== 'actions' ? (
              handleSort !== null ? (
                <HeaderContainerSort
                  onClick={() => {
                    changeSort(c.id);
                  }}
                  key={c.id}
                >
                  {c.name}{' '}
                  {c.id === sortedColumn ? (
                    sortOrder === 'asc' ? (
                      <Arrow src={columnUp} />
                    ) : (
                      <Arrow src={columnDown} />
                    )
                  ) : (
                    <Arrow src={columnIdle} />
                  )}
                </HeaderContainerSort>
              ) : (
                <HeaderContainer key={c.id}>{c.name}</HeaderContainer>
              )
            ) : (
              <ActionsContainer key={c.id}></ActionsContainer>
            );
          })}
        </TableHeaderContainer>
        {rows.slice((page - 1) * max, page * max).map((r) => {
          return (
            <TableRowContainer key={r._id}>
              {columns.map((c) => {
                return c.id !== 'actions' ? (
                  <TableDataContainer key={c.id}>
                    {Array.isArray(r[c.id]) && r[c.id].length > 1
                      ? r[c.id].slice(0, 1).join(', ') + '...'
                      : r[c.id]}
                  </TableDataContainer>
                ) : (
                  <ActionsContainer key={c.id}>{r[c.id]}</ActionsContainer>
                );
              })}
            </TableRowContainer>
          );
        })}
      </WhiteTableContainer>
      <PaginationComponent />
    </WhiteTableContentContainer>
  );
}
CleanTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  max: PropTypes.number,
  handleSort: PropTypes.any,
  defaultSortColumnId: PropTypes.any,
};

const WhiteTableContentContainer = styled(ContentContainer)`
  background: none;
  padding: 15px 0;
  border: none;
`;

const WhiteTableContainer = styled(ContentContainer)`
  background: none;
  border: none;
  box-shadow: 0px 0px 15px 5px lightgrey;
`;

const HeaderContainer = styled.div`
  padding-top: 5px;
  padding-bottom: 10px;
  font-weight: bold;
  width: 100%;
`;

const HeaderContainerSort = styled(HeaderContainer)`
  cursor: pointer;
`;

const TableHeaderContainer = styled(HorizontalContainer)`
  width: 100%;
  justify-content: space-between;
  padding-bottom: 10px;
`;

const TableDataContainer = styled.div`
  width: 100%;
  padding: 0px;
  border: none;
`;

const ActionsContainer = styled(TableDataContainer)`
  width: 40%;
`;

const TableRowContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
  border-bottom: 1px solid lightgrey;
  &:last-child {
    border-bottom: none;
  }
`;

const Arrow = styled.img`
  width: 15px;
`;
