import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { PageCardContainer, PageHeader } from './PageComponents.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { agGreen } from './Colors.js';
import { Button } from './CommonComponents.js';
import { OldBackLink } from './CommonComponents.js';
import ErrorArea from './ErrorArea';
import propTypes from 'prop-types';

const CreateButton = ({ to }) => (
  <CreateSpan>
    <Link to={to}>
      <FontAwesomeIcon icon={faPlusCircle} size="2x" color={agGreen} />
    </Link>
  </CreateSpan>
);

CreateButton.propTypes = {
  to: propTypes.any,
};

/*

Note: Component title is the title of a page such as an individual operations/premises page.

*/

const PageCard = ({
  title,
  loader,
  componentTitle,
  maxContent,

  to,
  back,
  buttonContent,
  children,
  errors,
  line,
  form,
  verify,
}) => {
  const history = useHistory();

  return (
    <PageCardContainer maxContent={maxContent} form={form}>
      {back && <OldBackLink onClick={history.goBack}>&larr; Back</OldBackLink>}
      {componentTitle && buttonContent && (
        <ContentContainer>
          <PageHeader style={{ flex: '1' }}>{componentTitle}</PageHeader>
          {buttonContent}
        </ContentContainer>
      )}
      {errors && <ErrorArea formErrors={errors} />}
      {loader ? (
        <PageHeader style={{ display: 'flex' }}>
          {title}
          {loader}
        </PageHeader>
      ) : (
        <PageHeader>{title}</PageHeader>
      )}
      {to && <CreateButton to={to} />}
      {verify && (
        <Link to={verify}>
          <Button style={{ float: 'right' }}>Verify Transaction</Button>
        </Link>
      )}
      {line ? <hr /> : <></>}
      <div>{children}</div>
    </PageCardContainer>
  );
};

PageCard.propTypes = {
  to: propTypes.any,
  title: propTypes.any,
  loader: propTypes.any,
  componentTitle: propTypes.any,
  back: propTypes.any,
  buttonContent: propTypes.any,
  children: propTypes.any,
  errors: propTypes.any,
  line: propTypes.any,
  form: propTypes.any,
  maxContent: propTypes.any,
  onBack: propTypes.func,
  relative: propTypes.any,
  verify: propTypes.any,
};

export default PageCard;

const CreateSpan = styled.span`
  margin-left: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
`;
