import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import SearchableDropdown from '../../components/SearchableDropdown';
import styled from 'styled-components';
import { extractDataFromHeaders } from '../../utils/CSVUtils';
import { getUserActiveOperations } from '../../utils/OperationUtils';

import { Redirect } from 'react-router';
import { Link } from '@material-ui/core';
import propTypes from 'prop-types';
import {
  FetchPremises,
  FetchVehicles,
  VehicleFormatter,
  PremisesFormatter,
} from '../../components/SheepReportComponents.js';
import { createVehicle, regionOptions } from '../../utils/VehicleUtils.js';
import Form from '../../components/Form.js';
import { createDate } from '../../utils/TimeUtils.js';
import { getUsername } from '../../utils/TokenUtils.js';
import { createTransportLog } from '../../utils/TransportUtils.js';
import Table from '../../components/Table.js';
import { Button } from '../../components/CommonComponents.js';

const CheckBox = ({ onChange, checked }) => {
  return (
    <input
      style={{ cursor: 'pointer' }}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  );
};

CheckBox.propTypes = {
  onChange: propTypes.func,
  checked: propTypes.bool,
};

const fetchPremises = async () => {
  const fetchedPremises = await FetchPremises();
  return fetchedPremises;
};

const fetchVehicles = async () => {
  const fetchedVehicles = await FetchVehicles();
  const formattedVehicles = VehicleFormatter(fetchedVehicles);
  return formattedVehicles;
};

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const TransportReportCsvCreateView = ({ data, headers }) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);

  const [selectedDestinationPID, setSelectedDestinationPID] = useState(null);
  const [selectedDeparturePID, setSelectedDeparturePID] = useState(null);
  const [premises, setPremises] = useState([]);
  const [formattedPremises, setFormattedPremises] = useState([]);

  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState();

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [vehicleOption, setVehicleOption] = useState('');

  const [formValues, setFormValues] = useState(null);

  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');

  // Setting column constants for select sheep table
  const columns = ['', 'CSIP Number', ''];
  const columnIds = ['isonum', ''];

  useEffect(async () => {
    setSheeps(extractDataFromHeaders(data, headers));
    return fetchPremises()
      .then(async (fetchedPremises) => {
        setPremises(fetchedPremises);
        setFormattedPremises(PremisesFormatter(fetchedPremises));
        return fetchVehicles().then((fetchedVehicles) => {
          setVehicles(fetchedVehicles);
        });
      })
      .catch((err) => {
        toast.error(`An error occurred while fetching data: ${err}`);
      });
  }, [data, headers]);

  useEffect(() => {
    sessionStorage.clear();
    if (operations.length === 0) {
      fetchOperations();
    }
  }, []);

  const fetchOperations = async () => {
    try {
      const result = await getUserActiveOperations(getUsername());

      if (result) {
        const reducedOperations = result.map((o) => ({
          id: o._id,
          name: o.businessName,
        }));

        setOperations(reducedOperations);
      }
    } catch (error) {
      console.error('Error fetching operations:', error);
    }
  };

  const operationPrompt = () => {
    if (operations.length === 0) {
      return (
        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {' '}
          You have not added an operation yet.&nbsp;{' '}
          <Link to="/operations" style={{ color: 'black' }}>
            {' '}
            Add one here{' '}
          </Link>
        </p>
      );
    }

    return <></>;
  };

  const sheepRowsWithCheck = sheeps.map((s) => ({
    check: (
      <CheckBox
        checked={
          selectedSheeps.filter(
            (selectedSheep) => selectedSheep.dummyID === s.dummyID,
          ).length > 0
        }
        onChange={() => {
          const alreadySelected =
            selectedSheeps.filter(
              (selectedSheep) => selectedSheep.dummyID == s.dummyID,
            ).length > 0;
          if (alreadySelected) {
            const newSelectedSheeps = selectedSheeps.filter(
              (sheep) => sheep.dummyID !== s.dummyID,
            );
            setSelectedSheeps(newSelectedSheeps);
          } else {
            const newSelectedSheeps = [
              sheeps.filter((sheep) => sheep.dummyID === s.dummyID)[0],
              ...selectedSheeps,
            ];
            setSelectedSheeps(newSelectedSheeps);
          }
        }}
      />
    ),
  }));

  for (let i = 0; i < sheeps.length; i++) {
    for (const columnId of columnIds) {
      sheepRowsWithCheck[i][columnId] =
        columnId !== ''
          ? sheeps[i][columnId]
            ? sheeps[i][columnId]
            : '-'
          : '';
    }
  }

  const SelectAllSection = () => (
    <CenteredButtonDiv>
      <Button
        style={{ marginRight: '10px' }}
        onClick={(e) => {
          e.preventDefault();
          setSelectedSheeps(sheeps);
        }}
      >
        Select All
      </Button>
      {selectedSheeps.length > 0 ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelectedSheeps([]);
          }}
        >
          Clear All
        </Button>
      ) : (
        <></>
      )}
    </CenteredButtonDiv>
  );

  const handleSubmit = (formValues) => {
    setSubmitting(true);
    setFormValues(formValues);
    if (!selectedDestinationPID || !selectedSheeps.length > 0) {
      toast.error('At least one sheep, and a destination PID is required.');
      return;
    }
    toast.info(
      'Submitting transport report to the server. This may take up to a minute.',
    );

    const logInfo = {
      departurePID:
        selectedDeparturePID.pid.toUpperCase() || selectedDeparturePID,
      destinationPID:
        selectedDestinationPID.pid.toUpperCase() || selectedDestinationPID,
      animals: selectedSheeps.map((sheep) => {
        return sheep.isonum;
      }),
      fwrDatetime: formValues.fwrDatetime,
      fwrAddress: formValues.fwrAddress,
      condition: formValues.condition,
      vehicleNumber:
        vehicleOption === 'new'
          ? formValues.licensePlateNum
          : formValues.vehicleNum,
      departureDate: formValues.departureDate + 'T' + formValues.departureTime,
    };

    let vehicleInfo = {};

    if (vehicleOption === 'new') {
      vehicleInfo.licensePlateNum = formValues.licensePlateNum;

      vehicleInfo.provinces = [''];
      vehicleInfo.states = [''];

      vehicleInfo.name = formValues.vehicleName;

      if (formValues.country == 'Canada') {
        vehicleInfo.provinces = [formValues.locations];
      }
      if (formValues.country == 'United States') {
        vehicleInfo.states = [formValues.locations];
      }

      vehicleInfo.operation = selectedOperation?.id;
    }

    createTransportLog(logInfo)
      .then(() => {
        if (vehicleOption === 'new') {
          createVehicle(vehicleInfo)
            .then(toast.success('Success: Vehicle created'))
            .catch((err) => {
              setSubmitting(false);
              toast.dismiss();
              toast.error('Error creating vehicle: ' + err);
            });
        }

        setSubmitting(false);
        toast.success('Success: Report created');
        setRedirect('/reports/moveout');
      })
      .catch((err) => {
        setSubmitting(false);
        toast.dismiss();
        toast.error('Error creating transport report: ' + err);
      });
  };

  const formFields = [
    {
      id: 'departurePremise',
      name: 'Departure Premise',
      type: 'content',
      requiredLabel: true,
      content: (
        <>
          <SearchableDropdown
            id="departurePID"
            type="premise"
            optionKey="pid"
            options={premises}
            placeholder="Search by PID..."
            onChange={(pid) => setSelectedDeparturePID(pid)}
            onSelect={(pid) => setSelectedDeparturePID(pid)}
          />
        </>
      ),
    },
    {
      name: 'Destination PID',
      id: 'destinationPremise',
      requiredLabel: true,
      type: 'content',
      content: (
        <>
          <SearchableDropdown
            id="destinationPID"
            type="premise"
            optionKey="pid"
            options={premises}
            placeholder="Search by PID..."
            onChange={(pid) => setSelectedDestinationPID(pid)}
            onSelect={(pid) => setSelectedDestinationPID(pid)}
          />
        </>
      ),
    },
    {
      id: 'sheepSelect',
      name: 'Select Sheep to include in Report',
      type: 'content',
      content: (
        <Table columns={columns} rows={sheepRowsWithCheck} paginate={true} />
      ),
    },
    {
      type: 'content',
      content: <SelectAllSection />,
    },
    {
      name: 'Departure Date',
      id: 'departureDate',
      type: 'date',
      required: true,
      value: formValues?.departureDate || '',
      validityCheck: (fieldValues) => {
        if (
          new Date(createDate(fieldValues.departureDate)).getTime() > Date.now()
        ) {
          return 'Date of Departure cannot be in the future';
        }
        return true;
      },
    },
    {
      name: 'Departure Time',
      id: 'departureTime',
      type: 'time',
      required: true,
      value: formValues?.departureTime || '',
      validityCheck: (fieldValues) => {
        const [fieldHours, fieldMinutes] = fieldValues.departureTime
          .split(':')
          .map(Number);
        const depDate = new Date(createDate(fieldValues.departureDate));

        if (depDate.setHours(fieldHours, fieldMinutes) > Date.now()) {
          return 'Departure Time cannot be in the future';
        } else if (
          depDate.setHours(fieldHours, fieldMinutes) < fieldValues.fwrDatetime
        ) {
          return 'Departure Time cannot be before FWR Datetime';
        }
        return true;
      },
    },
    {
      name: 'Vehicle Option',
      id: 'vehicleOption',
      type: 'select',
      options: [
        { name: 'Use Existing Vehicle', value: 'exist' },
        { name: 'Create a New Vehicle', value: 'new' },
      ],
      onChange: (fieldValues) => {
        setVehicleOption(fieldValues.vehicleOption);
      },
      required: true,
    },
    vehicleOption == 'exist' && {
      name: 'Vehicle Identifier',
      id: 'vehicleNum',
      description: 'Select the identifier',
      type: 'select',
      options: vehicles,
      onChange: (fieldValues) => {
        setSelectedVehicle(fieldValues.vehicle);
      },
      required: true,
    },
    vehicleOption == 'new' && {
      name: 'Vehicle Name',
      id: 'vehicleName',
    },
    vehicleOption == 'new' && {
      name: 'Vehicle Identifier',
      id: 'licensePlateNum',
      description: 'License Plate, VIN Number, or Description',
      required: true,
      validityCheck: (fieldValues) => {
        if (!/^[A-Za-z0-9 ]+$/.exec(fieldValues.licensePlateNum)) {
          return 'Vehicle Identification must only contain alphanumeric characters';
        } else {
          return true;
        }
      },
    },
    vehicleOption == 'new' && {
      name: 'Country of Registration',
      id: 'country',
      required: true,
      type: 'select',
      options: Object.keys(regionOptions).map((loc) => ({
        name: loc,
        value: loc,
      })),
    },
    vehicleOption == 'new' && {
      name: 'Region',
      id: 'locations',
      required: true,
      type: 'select',
      optionsFilter: (fields) =>
        fields.country !== '' ? regionOptions[fields.country] : [],
    },
    vehicleOption == 'new' && {
      name: 'Operation',
      type: 'content',
      content: (
        <SearchableDropdown
          id="operation-search"
          type="operation"
          optionKey="businessName"
          options={operations.map((o) => ({
            businessName: o.name,
            id: o.value,
          }))}
          placeholder="Search by Operation Name..."
          onChange={(businessName) => setSelectedOperation(businessName)}
          onSelect={(option) => setSelectedOperation(option)}
        />
      ),
      description: 'Select an operation that this vehicle belongs to.',
    },
    vehicleOption == 'new' && {
      type: 'content',
      content: operationPrompt(),
    },
    {
      type: 'content',
      content: (
        <>
          {premises.length == 0 && (
            <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
              You have not added a premise yet.&nbsp;
              <Link to="/premises" style={{ color: 'black' }}>
                {' '}
                Add one here{' '}
              </Link>
            </p>
          )}
        </>
      ),
    },
    {
      name: 'Last Access to Feed, Water and Rest Datetime',
      id: 'fwrDatetime',
      type: 'datetime-local',
      required: true,
      validityCheck: (fieldValues) => {
        const departureDateTime = new Date(
          `${fieldValues.departureDate}T${fieldValues.departureTime}`,
        ).getTime();
        if (new Date(fieldValues.fwrDatetime).getTime() > Date.now()) {
          return 'FWR Datetime cannot be in the future';
        } else if (
          new Date(fieldValues.fwrDatetime) > new Date(departureDateTime)
        ) {
          return 'FWR Datetime cannot be after the departure date';
        } else return true;
      },
    },
    {
      name: 'Last Access to Feed, Water and Rest Location',
      hint: '3 CherryTree Street',
      id: 'fwrAddress',
      type: 'text',
      required: true,
    },
    {
      name: 'Condition of Animals upon Arrival',
      hint: 'Description of animal condition',
      id: 'condition',
      type: 'textarea',
      maxLength: 100,
    },
  ].filter(Boolean);

  if (redirect !== '') return <Redirect to={redirect} />;
  return (
    <Form
      style={{ width: '900px', maxWidth: '900px' }}
      title="Bulk Sheep Transport"
      onSubmit={handleSubmit}
      fieldInfo={formFields}
      disabled={
        selectedSheeps.length <= 0 ||
        !selectedDestinationPID ||
        !selectedDeparturePID
      }
    />
  );
};

TransportReportCsvCreateView.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string),
};

export default TransportReportCsvCreateView;
