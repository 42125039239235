import axios from 'axios';
import { getRoles } from './TokenUtils';

const fetchRoleTypes = async () => {
  try {
    const response = await axios.get('/api/roleTypes');
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching role types: ${error.message}`);
  }
};

const getRolesFromUsername = async (username) => {
  try {
    const response = await axios.get(`/api/roles/${username}`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching role: ${error.message}`);
  }
};

const addRole = async (userId, roleType) => {
  try {
    await axios.post('/api/roles', { userId, roleType });
  } catch (error) {
    throw new Error(`Error adding role ${roleType}: ${error.message}`);
  }
};

const deleteRole = async (userId, roleType) => {
  try {
    await axios.delete('/api/roles', { data: { userId, roleType } });
  } catch (error) {
    throw new Error(`Error removing role ${roleType}: ${error.message}`);
  }
};

const checkAdmin = () => {
  const roles = getRoles();
  if (!roles) return false;
  for (const role of roles) {
    if (role.role === 'admin') {
      return true;
    }
  }
  return false;
};

/** Function to get the currently selected role from the user */
const getSelectedRole = () => {
  return localStorage.getItem('role');
};

export {
  getRolesFromUsername,
  fetchRoleTypes,
  deleteRole,
  addRole,
  checkAdmin,
  getSelectedRole,
};
