import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { COLORS } from '../utils/Constants.js';
import TraceTimeline from './reusedComponents/TraceTimeline.js';
import UserPicture from './reusedComponents/UserPicture.js';

export const Info = ({ name, value }) => {
  return (
    <InfoDiv>
      <FieldSpan>{name}</FieldSpan>
      <ValueSpan>{value}</ValueSpan>
    </InfoDiv>
  );
};

Info.propTypes = {
  name: PropTypes.any,
  value: PropTypes.any,
};

export default function TraceSheepReports({ sheepId }) {
  const [users, setUsers] = useState([]);
  const history = useHistory();

  const handleUserClick = (user) => {
    if (user && user.username) {
      history.push(`/users/${user.username}`);
    } else {
      toast.error('User not found');
    }
  };

  return (
    <DetailContainer>
      <ProfileListContainer>
        {users &&
          users.map((user, index) => (
            <UserPicture
              key={index}
              src={user && user.src ? user.src : ''}
              firstName={user ? user.firstName : ''}
              lastName={user ? user.lastName : ''}
              isSelected={user ? user.isSelected : false}
              onClick={() => handleUserClick(user ? user : null)}
              showUsername={true}
            />
          ))}
      </ProfileListContainer>
      <TraceTimeline sheepID={sheepId} setUsers={setUsers} />
    </DetailContainer>
  );
}

TraceSheepReports.propTypes = {
  sheepId: PropTypes.any,
};

const ProfileListContainer = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000; /* Ensure it's above other content */
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 2px -2px rgba(0, 0, 0, 0.2);

  /* Add margin between child elements */
  > * {
    margin-right: 10px;
  }

  /* Ensure last child does not have margin */
  > *:last-child {
    margin-right: 0;
  }
`;

const DetailContainer = styled.div`
  & > div {
    margin: 1rem auto 2rem;
  }
  background-color: ${COLORS.light};
  z-index: 900;
  padding-bottom: 2rem;
`;

const InfoDiv = styled.div`
  font-size: 1.2rem;
  margin: 0.7em;
`;

const FieldSpan = styled.span`
  display: inline-block;
  width: 30ch;
  font-weight: 500;
`;

const ValueSpan = styled.span`
  font-style: italic;
`;
