// AdminRoleView.js

import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import DescriptionCard from '../../components/DescriptionCard';
import PageCard from '../../components/PageCard';
import TableComponent from '../../components/TableComponent';
import { getUsers } from '../../utils/UserUtils';

// Get users

const AdminRoleView = () => {
  const [users, setUsers] = useState([]);
  const [redirect, setRedirect] = useState('');

  useEffect(() => {
    (async () => {
      let resUsers = await getUsers();
      resUsers = resUsers.map((u) => {
        const roleString = u.roles.map((r) => r.role).join(', ');
        return {
          ...u,
          roleString,
        };
      });
      setUsers(resUsers);
    })();
  }, []);

  if (redirect !== '') {
    return <Redirect to={redirect} push />;
  }

  const viewRole = ({ username }) => {
    setRedirect(`/roles/${username}`);
  };

  const tableHeadings = [
    { name: 'First Name', id: 'firstName' },
    { name: 'Last Name', id: 'lastName' },
    { name: 'Username', id: 'username' },
    { name: 'Roles', id: 'roleString' },
  ];

  return (
    <PageCard>
      <DescriptionCard page="User" plural="users" />
      <TableComponent
        headings={tableHeadings}
        data={users}
        view={{ cb: viewRole, title: 'View' }}
        actions
      />
    </PageCard>
  );
};

export default AdminRoleView;
