import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getUser } from '../../utils/UserUtils';
import {
  getRolesFromUsername,
  checkAdmin,
  getSelectedRole,
} from '../../utils/RoleUtils';
import styled from 'styled-components';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath';
import { COLORS } from '../../utils/Constants';
import ProfileMenu from './ProfileMenu';
import EditRoles from '../Edit/EditRoles';
import EditPassword from '../Edit/EditPassword';
import EditEmail from '../Edit/EditEmail';
import EditProfile from '../Edit/EditProfile';

const Container = styled.div`
  display: flex;
  height: 100vh;
  justify-content: ${(props) => props.justifyContent || 'stretch'};
`;

const UserView = () => {
  const params = useParams();
  const username = params.username;
  const [selectedItem, setSelectedItem] = useState('Profile');
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const getRoleString = async (username) => {
    let roles = await getRolesFromUsername(username);
    return roles.map((r) => r.roleType).join(', ');
  };

  const fetchUserData = async () => {
    try {
      let userData = await getUser(username);
      let transformedUser = {
        id: userData.id,
        roleString: checkAdmin()
          ? await getRoleString(username)
          : 'Not available',
        username: userData.username,
        firstName: userData.firstName,
        lastName: userData.lastName,
        email: userData.email,
        phone: userData.phone,
        profileImage: userData.profileImage,
      };
      setUser(transformedUser);
    } catch (error) {
      console.error('Failed to fetch user data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const paths = [
    { url: '/', title: 'Home' },
    { url: '', title: 'Profile' },
  ];

  // Switch statement to render the selected component
  const renderSelectedComponent = () => {
    switch (selectedItem) {
      case 'Profile':
        return <EditProfile userData={user} fetchUserData={fetchUserData} />;
      case 'Password':
        return <EditPassword userData={user} fetchUserData={fetchUserData} />;
      case 'Email':
        return <EditEmail userData={user} fetchUserData={fetchUserData} />;
      case 'Roles':
        return <EditRoles userData={user} fetchUserData={fetchUserData} />;
      default:
        return <EditProfile userData={user} fetchUserData={fetchUserData} />;
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <div style={{ display: 'flex' }}>
        <NavigationPath paths={paths} />
      </div>
      <Container justify_content="stretch">
        <ProfileMenu
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          showRoles={checkAdmin() && getSelectedRole() === 'admin'}
        />
        <div
          style={{
            flex: 1,
            backgroundColor: COLORS.light,
            borderRadius: '25px',
          }}
        >
          {renderSelectedComponent()}
        </div>
      </Container>
    </div>
  );
};

export default UserView;
