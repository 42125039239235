import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';

export const DashboardDeleteModal = ({
  selectedItem,
  itemName,

  deleteItem,
  cancel,
}) => {
  const confirmDelete = () => {
    deleteItem(selectedItem);
  };

  return (
    <DeleteModal>
      <DeleteModalContent>
        <h1 style={{ textAlign: 'center' }}>
          Are you sure you want to delete?
        </h1>
        <h3 style={{ textAlign: 'center' }}>
          This {itemName} will be deleted forever.
        </h3>
        <DeleteModalButtonContainer>
          <DeleteModalButton onClick={cancel}>Cancel</DeleteModalButton>
          <DeleteModalButton
            onClick={confirmDelete}
            color="white"
            background={COLORS.text}
          >
            Confirm
          </DeleteModalButton>
        </DeleteModalButtonContainer>
      </DeleteModalContent>
    </DeleteModal>
  );
};

DashboardDeleteModal.propTypes = {
  selectedItem: PropTypes.any,
  cancel: PropTypes.func.isRequired,
  deleteItem: PropTypes.func.isRequired,
  itemName: PropTypes.string.isRequired,
};

const DeleteModal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
`;

const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 35%;
  color: ${COLORS.text};
  background-color: ${COLORS.light};
  margin: auto;
  padding: 20px 20px 40px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
  margin: 0px 20px;
`;

const DeleteModalButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const DeleteModalButton = styled.button`
  color: ${(props) => (props.color ? props.color : COLORS.text)};
  font-size: 20px;
  font-weight: bold;
  background: ${(props) => (props.background ? props.background : 'white')};
  border: ${COLORS.text} 1px solid;
  border-radius: 30px;
  width: 120px;
  height: 40px;

  &:hover {
    filter: contrast(1.5);
    cursor: pointer;
  }
`;
