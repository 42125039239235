import { getSelectedRole } from './RoleUtils';

const axios = require('axios');

export const getMoveLogs = () => {
  return axios
    .get('/api/move', {
      params: {
        selectedRole: getSelectedRole(),
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const getRelatedMoveLogs = async () => {
  return axios
    .get('/api/move/related')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

//get the move logs for a specific sheep
export const getMoveLogsForSheep = (id) => {
  return axios
    .get(`/api/move/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};
export const getMoveLogsForVehicles = (vehiclesArray, isoNumber) => {
  return axios.post('/api/vehicles/move', {
    vehiclesArray,
    isoNumber,
  });
};
export const getMoveLogsForVehicle = (vehicleNumber) => {
  return axios.get(`/api/vehicles/move/${vehicleNumber}`);
};

export const createMoveLog = (data) => {
  return axios
    .post('/api/move', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const bulkMove = (data) => {
  return axios
    .post(`/api/move/bulk`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};
