import React from 'react';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
import FormTitle from './FormTitle.js';

const Header = styled.div`
  display: flex;
  flex-direction: column; /* Arrange children in a column */
  align-items: center; /* Center align items horizontally */
  width: 100%;
  max-width: 100%;
`;

const Row = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.justify_content ? props.justify_content : 'center'};
  width: 100%;
`;

const CancelButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const FormTitleWrapper = styled.div`
  text-align: center; /* Center align text within FormTitleWrapper */
`;

const FormHeader = ({ title, onCancel }) => {
  return (
    <Header>
      {onCancel && (
        <Row justify_content="flex-end">
          <CancelButton onClick={onCancel}>
            <AiOutlineClose size={24} />
          </CancelButton>
        </Row>
      )}
      <Row>
        <FormTitleWrapper>
          <FormTitle title={title} />
        </FormTitleWrapper>
      </Row>
    </Header>
  );
};

export default FormHeader;
