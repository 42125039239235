import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SimpleCard from '../../components/SimpleCard';
import StripedScrollableTable from '../../components/StripedScrollableTable';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import { COLORS } from '../../utils/Constants';

// Styled components for layout and styling
const DualListContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

const ListContainer = styled.div`
  width: 45%;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MoveButton = styled.button`
  margin: 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: ${({ disabled }) =>
    disabled ? COLORS.disabled : COLORS.primary};
  color: white;
  font-size: 14px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? COLORS.disabled : COLORS.dark};
  }
`;

// Base columns for the StripedScrollableTable
const baseColumns = [
  { id: 'isoNumber', name: 'CSIP Number' },
  { id: 'localMgmtNumber', name: 'Local Management Number' },
  { id: 'tattooNumber', name: 'Tattoo Number' },
  { id: 'usScrapieId', name: 'US Scrapie' },
  { id: 'gender', name: 'Gender' },
];

/**
 * Dual list component for selecting sheep to dispose.
 * Allows moving sheep between available sheep list and selected disposal list.
 * @param {object} props - Component props
 * @param {string} props.sheepId - ID of sheep to be preselected
 * @param {array} props.sheep - Array of sheep objects
 * @param {function} props.setSelectedSheep - Function to set selected sheep
 */
const SheepDualListDisposal = ({ sheepId, sheep, setSelectedSheep }) => {
  const [availableSheep, setAvailableSheep] = useState([]);
  const [selectedDisposedSheep, setSelectedDisposedSheep] = useState([]);
  const [selectedAvailableSheep, setSelectedAvailableSheep] = useState([]);
  const [selectedDisposedSheepIds, setSelectedDisposedSheepIds] = useState([]);

  useEffect(() => {
    const sheepList = sheep.map((s) => ({ ...s, selected: false }));
    const initialDisposedSheep = sheepList.filter((s) => s.id === sheepId);
    const initialAvailableSheep = sheepList.filter((s) => s.id !== sheepId);

    setAvailableSheep(initialAvailableSheep);
    setSelectedDisposedSheep(initialDisposedSheep);
    setSelectedDisposedSheepIds(initialDisposedSheep.map((s) => s.id));
  }, [sheep, sheepId]);

  useEffect(() => {
    setSelectedSheep(selectedDisposedSheep);
  }, [selectedDisposedSheep, setSelectedSheep]);

  const handleAvailableRowClick = (row) => {
    setSelectedAvailableSheep((prevSelected) =>
      prevSelected.includes(row.id)
        ? prevSelected.filter((id) => id !== row.id)
        : [...prevSelected, row.id],
    );
  };

  const handleSelectedRowClick = (row) => {
    setSelectedDisposedSheepIds((prevSelected) =>
      prevSelected.includes(row.id)
        ? prevSelected.filter((id) => id !== row.id)
        : [...prevSelected, row.id],
    );
  };

  const handleMoveSelectedToLeft = () => {
    const selectedToMove = selectedDisposedSheep.filter((s) =>
      selectedDisposedSheepIds.includes(s.id),
    );

    const remainingSelected = selectedDisposedSheep.filter(
      (s) => !selectedDisposedSheepIds.includes(s.id),
    );

    setAvailableSheep([...availableSheep, ...selectedToMove]);
    setSelectedDisposedSheep(remainingSelected);
    setSelectedDisposedSheepIds([]);
  };

  const handleMoveAllToRight = () => {
    setSelectedDisposedSheep([...selectedDisposedSheep, ...availableSheep]);
    setAvailableSheep([]);
    setSelectedAvailableSheep([]);
  };

  const handleMoveSelectedToRight = () => {
    const selected = availableSheep.filter((s) =>
      selectedAvailableSheep.includes(s.id),
    );
    const remaining = availableSheep.filter(
      (s) => !selectedAvailableSheep.includes(s.id),
    );

    setSelectedDisposedSheep([...selectedDisposedSheep, ...selected]);
    setAvailableSheep(remaining);
    setSelectedAvailableSheep([]);
  };

  const handleMoveAllToLeft = () => {
    setAvailableSheep([...availableSheep, ...selectedDisposedSheep]);
    setSelectedDisposedSheep([]);
    setSelectedAvailableSheep([]);
    setSelectedDisposedSheepIds([]);
  };

  const isMoveRightDisabled = availableSheep.every(
    (s) => !selectedAvailableSheep.includes(s.id),
  );

  const isMoveLeftDisabled = selectedDisposedSheepIds.length === 0;

  return (
    <DualListContainer>
      <ListContainer>
        <SimpleCard title={`All Sheep`} size="md">
          <StripedScrollableTable
            columns={baseColumns}
            rows={availableSheep}
            onRowClick={handleAvailableRowClick}
            selectedRows={selectedAvailableSheep} // Pass selectedAvailableSheep array here
          />
        </SimpleCard>
      </ListContainer>

      <ButtonContainer>
        <MoveButton
          onClick={handleMoveAllToRight}
          disabled={availableSheep.length === 0}
        >
          Select All
        </MoveButton>
        <MoveButton
          onClick={handleMoveSelectedToRight}
          disabled={isMoveRightDisabled}
        >
          <FaAngleRight />
        </MoveButton>
        <MoveButton
          onClick={handleMoveSelectedToLeft}
          disabled={isMoveLeftDisabled}
        >
          <FaAngleLeft />
        </MoveButton>
        <MoveButton
          onClick={handleMoveAllToLeft}
          disabled={
            selectedDisposedSheep.length === 0 &&
            selectedDisposedSheepIds.length === 0 &&
            selectedAvailableSheep.length === 0
          }
        >
          Deselect All
        </MoveButton>
      </ButtonContainer>

      <ListContainer>
        <SimpleCard title="Sheep to Dispose" size="md">
          <StripedScrollableTable
            columns={baseColumns}
            rows={selectedDisposedSheep}
            onRowClick={handleSelectedRowClick}
            selectedRows={selectedDisposedSheepIds} // Pass selectedDisposedSheepIds array here
          />
        </SimpleCard>
      </ListContainer>
    </DualListContainer>
  );
};

SheepDualListDisposal.propTypes = {
  sheepId: PropTypes.string.isRequired,
  sheep: PropTypes.array.isRequired,
  setSelectedSheep: PropTypes.func.isRequired,
};

export default SheepDualListDisposal;
