import axios from 'axios';

export const createSightingReport = async (data) => {
  return axios
    .post('/api/animalsighting', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const bulkSheepSightingReport = async (data) => {
  return axios
    .post('/api/animalsighting/bulk', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};
