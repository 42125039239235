// UserRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import UsersView from '../views/User/UsersView';

const UserRouter = () => (
  <>
    <Switch>
      <Route exact path="/users" component={UsersView} />
    </Switch>
  </>
);

export default UserRouter;
