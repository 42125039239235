import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router';
import { useParams } from 'react-router';

import styled from 'styled-components';
import {
  OldBackLink,
  VerticalContainer,
} from '../../components/CommonComponents.js';
import { COLORS } from '../../utils/Constants.js';
import TagAllocationData, { Info } from '../../components/TagAllocationData.js';

import { data } from './TagAllocationData.js'; // <--- Temporary, just for testing
import TagAllocationButtons from '../../components/TagAllocationButtons.js';

const fetchTagAllocationData = async (_id) => {
  return data.find((r) => r._id == _id);
};

export default function TagAllocationReviewView() {
  const [data, setData] = useState({});
  const [redirect, setRedirect] = useState();

  const [reason, setReason] = useState(''); // this is unused but will be sent to the back-end in the future

  const params = useParams();
  const _id = params.id;

  const history = useHistory();

  useEffect(() => {
    fetchAndSetReportInfo();
  }, []);

  const fetchAndSetReportInfo = async () => {
    let fetchedTagAllocationData = await fetchTagAllocationData(_id);
    setData(fetchedTagAllocationData);
  };

  const deny = async () => {
    toast.success('Tag Allocation Request Denied');
    setRedirect('/tagallocation/reviews');
  };

  const approve = async () => {
    toast.success('Tag Allocation Request Approved');
    setRedirect('/tagallocation/reviews');
  };

  if (redirect) return <Redirect to={redirect} />;

  return (
    <TagAllocationContainer>
      <VerticalContainer width="100%" align_items="start" mt="50px">
        <OldBackLink onClick={history.goBack}>&larr; Back</OldBackLink>
        <Header>Tag Allocation Review</Header>
        <p>
          This is the tag allocation request details page, from here you can
          approve or deny requests
        </p>

        <TagAllocationData data={data} />

        <h3>Status: {data.status}</h3>
        {data.status === 'Awaiting Approval' && (
          <TagAllocationButtons
            approve={approve}
            deny={deny}
            setReason={setReason}
          />
        )}
        {data.status === 'Approved' && (
          <Info
            name="Tag Range Allocated"
            value="124000310000000-124000310000010"
          />
        )}
        {data.status === 'Denied' && <Info name="Reason" value={data.reason} />}
      </VerticalContainer>
    </TagAllocationContainer>
  );
}

const TagAllocationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  height: auto;
`;

const Header = styled.h2`
  color: ${COLORS.primary};
  margin-top: 0px;
  margin-bottom: 10px;
`;
