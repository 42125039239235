import React from 'react';
import { HorizontalContainer, Icon } from '../CommonComponents';
import left from '../../images/icons/chevron_left.png';
import right from '../../images/icons/chevron_right.png';
import { COLORS } from '../../utils/Constants';

/**
 * Card component for a premise details page
 * @param rows - data to be paginated through
 * @param max - maximum number of rows being displayed on the page
 * @param page - current page number (should be a state variable from parent)
 * @param setPage - setter for page variable from parent
 */
export const PaginationComponent = ({ rows, max = 10, page, setPage }) => {
  const maxPages = Math.ceil(rows.length / max);

  return (
    <HorizontalContainer mobile="none" style={{ alignSelf: 'center' }}>
      <Icon
        width="25px"
        src={left}
        hover="filter: opacity(0.5)"
        disabled={page === 1}
        onClick={() => {
          if (page > 1) {
            setPage(page - 1);
          }
        }}
      />
      {[...Array(maxPages).keys()]
        .map((i) => i + 1)
        .map((pageNum) => {
          // This is to only show 4 pages on each side of the
          // current page unless it is nearing the max or the min
          let showPages = 5;
          if (page - 5 < 0) {
            showPages = showPages + Math.abs(page - 5);
          } else if (page + 5 > maxPages) {
            showPages = showPages + (5 - 1 - (maxPages - page));
          }

          if (pageNum === page) {
            return (
              <h3
                style={{
                  background: COLORS.secondary,
                  borderRadius: '10px',
                  paddingBlock: '5px',
                  paddingInline: '10px',
                }}
                key={pageNum}
              >
                {pageNum}
              </h3>
            );
          } else {
            return (
              page + showPages > pageNum &&
              page - showPages < pageNum &&
              pageNum >= 1 &&
              pageNum >= 1 &&
              pageNum <= maxPages && (
                <h3
                  style={{
                    paddingBlock: '5px',
                    paddingInline: '10px',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setPage(pageNum);
                  }}
                  key={pageNum}
                >
                  {pageNum}
                </h3>
              )
            );
          }
        })}
      <Icon
        width="25px"
        src={right}
        hover="filter: opacity(0.5)"
        disabled={page === maxPages}
        onClick={() => {
          if (page < maxPages) {
            setPage(page + 1);
          }
        }}
      />
    </HorizontalContainer>
  );
};
