import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faLock,
  faEnvelope,
  faUserShield,
} from '@fortawesome/free-solid-svg-icons';

const MenuContainer = styled.div`
  width: 25%;
  background-color: ${COLORS.light};
  padding: 20px;
  border-radius: 25px;
  margin-right: 20px;
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? '#ddd' : 'transparent')};
  &:hover {
    background-color: #ddd;
  }
`;

const menuItems = [
  { name: 'Profile', icon: faUser },
  { name: 'Password', icon: faLock },
  { name: 'Email', icon: faEnvelope },
  { name: 'Roles', icon: faUserShield },
];

const ProfileMenu = ({
  selectedItem,
  setSelectedItem,
  items = menuItems,
  showRoles,
}) => {
  // Filter menu items based on showEditRole
  const filteredItems = showRoles
    ? items
    : items.filter((item) => item.name !== 'Roles');

  return (
    <MenuContainer>
      {filteredItems.map((item) => (
        <MenuItem
          key={item.name}
          selected={selectedItem === item.name}
          onClick={() => setSelectedItem(item.name)}
        >
          <FontAwesomeIcon icon={item.icon} style={{ marginRight: '10px' }} />
          {item.name}
        </MenuItem>
      ))}
    </MenuContainer>
  );
};

export default ProfileMenu;
