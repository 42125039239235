// SheepRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import SheepCsvImportView from '../views/Sheep/SheepCsvImportView';
import SheepCreateManual from '../views/Sheep/SheepCreateManualView';
import SheepDetailView from '../views/Sheep/SheepDetailView';
import SheepDocumentUploadView from '../views/Sheep/SheepDocumentUploadView';
import SheepDashboardView from '../views/Sheep/SheepDashboardView';
import SheepPremiseView from '../views/Sheep/SheepPremiseView';
import SheepDisposeView from '../views/Sheep/SheepDisposeView';
import SheepReplaceView from '../views/Sheep/SheepReplaceView';
import SheepEditView from '../views/Sheep/SheepEditView';
import SheepLostTagView from '../views/Sheep/SheepLostTagView';
import SheepFrAddImagesView from '../views/Sheep/SheepFrAddImagesView.js';

const SheepRouter = () => (
  <Switch>
    <Route exact path="/sheep" component={SheepDashboardView} />
    <Route exact path="/sheep/dispose" component={SheepDisposeView} />
    <Route exact path="/sheep/replace" component={SheepReplaceView} />
    <Route exact path="/sheep/edit" component={SheepEditView} />
    <Route exact path="/sheep/document" component={SheepDocumentUploadView} />
    <Route exact path="/sheep/operation/:id" component={SheepPremiseView} />
    <Route exact path="/sheep/premise/:id" component={SheepPremiseView} />
    <Route exact path="/sheep/create/csv" component={SheepCsvImportView} />
    <Route exact path="/sheep/create/fr" component={SheepFrAddImagesView} />
    <Route exact path="/sheep/:id" component={SheepDetailView} />
    <Route path="/sheep/create/manual" component={SheepCreateManual} />
    <Route path="/sheep/create/lostTag" component={SheepLostTagView} />
  </Switch>
);

export default SheepRouter;
