// SlaughterReportCreate.js
import React, { useState, useEffect } from 'react';
import FormCard from '../components/FormCard.js';
import { FormContainer, Button } from '../components/CommonComponents.js';
import Input from '../components/Input.js';
import { Redirect } from 'react-router-dom';
import DateField from '../components/DateField.js';
import { createSlaughterReport } from '../utils/SlaughterReportUtils.js';
import 'react-datepicker/dist/react-datepicker.css';
import {
  FetchPremises,
  FetchVehicles,
  FetchAnimals,
} from '../components/SheepReportComponents.js';

const SlaughterReportCreateView = () => {
  const [_premises, setPremises] = useState([]);
  const [_vehicles, setVehicles] = useState([]);
  const [_animals, setAnimals] = useState([]);
  const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    departureDateTime: new Date(),
    deathDateTime: new Date(),
    animalID: '',
    vehicleID: '',
  });

  useEffect(() => {
    setPremises(FetchPremises);
    setVehicles(FetchVehicles);
    setAnimals(FetchAnimals);
  }, []);

  const [redirect, setRedirect] = useState('');

  const submitSlaughterReport = async (e) => {
    console.log(form);
    e.preventDefault();
    const res = await createSlaughterReport(form);
    console.log(res);

    setRedirect('/slaughterReport');
  };

  if (redirect !== '') return <Redirect to={redirect} />;

  const formInputs = [
    {
      name: 'Departure Premise ID',
      hint: '112334556778990',
      id: 'departurePremiseID',
    },
    {
      name: 'Destination Premise ID',
      hint: '112334556778990',
      id: 'destinationPremiseID',
    },
    {
      name: 'Animal ID',
      id: 'animalID',
    },
    {
      name: 'Vehicle ID',
      id: 'vehicleID',
    },
  ];

  const dateChange = (date, fieldName) => {
    setForm({
      ...form,
      [fieldName]: date,
    });
  };

  const dateFields = [
    {
      labelName: 'Departure Date',
      id: 'departureDateTime',
      onChange: (date) => dateChange(date, 'departureDateTime'),
      value: form.departureDateTime,
    },
    {
      labelName: 'Death Date',
      id: 'deathDateTime',
      onChange: (date) => dateChange(date, 'deathDateTime'),
      value: form.deathDateTime,
    },
  ];

  return (
    <>
      <FormCard title="Create a Slaughter Report" back>
        <FormContainer onSubmit={submitSlaughterReport}>
          {dateFields.map((d) => (
            <>
              <DateField
                id={d.id}
                labelName={d.labelName}
                value={d.value}
                onChange={d.onChange}
              />
            </>
          ))}
          {formInputs.map((i) => (
            <>
              <Input
                id={i.id}
                placeholder={i.hint}
                labelName={i.name}
                onChange={(e) =>
                  setForm({
                    ...form,
                    [e.target.name]: e.target.value,
                  })
                }
                value={form[i.id]}
                required
              />
            </>
          ))}
          <Button>Submit</Button>
        </FormContainer>
      </FormCard>
    </>
  );
};

export default SlaughterReportCreateView;
