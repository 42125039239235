import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFormFields, {
  getStepOneFields,
} from '../../Fields/AddTransportFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import { createTransportLog } from '../../utils/TransportUtils.js';
import { createVehicle } from '../../utils/VehicleUtils.js';
import {
  isISONumber,
  validateCommaISO,
} from 'agroledger-shared-library/TagUtils.js';

export default function AddTransport({
  allPremises,
  animals,
  vehicles,
  operations,
}) {
  const [redirect, setRedirect] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedSheep, setSelectedSheep] = useState([]);
  const [selectedDestinationPID, setSelectedDestinationPID] = useState(null);
  const [selectedDeparturePID, setSelectedDeparturePID] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [typedSheep, setTypedSheep] = useState(null);

  /** Handle adding fields to form, based on if the user is to use an existing vehicle or a new one */
  const handleVehicleCreation = async () => {
    setFormFields((prevFields) => {
      if (!prevFields) return null;
      const updatedFields = prevFields.map((step) =>
        step.name === 'Transport Information'
          ? {
              ...step,
              fields: [
                step.fields[0],
                step.fields[1],
                step.fields[2],
                step.fields[3],
                step.fields[4],
                step.fields[5],
                ...getStepOneFields(operations),
              ],
            }
          : step,
      );
      setValidFieldsInCurrentStep(false);
      return updatedFields;
    });
  };

  const handleVehicleRemove = async () => {
    setFormFields((prevFields) => {
      if (!prevFields) return null;
      const updatedFields = prevFields.map((step) =>
        step.name === 'Transport Information'
          ? {
              ...step,
              fields: [
                step.fields[0],
                step.fields[1],
                step.fields[2],
                step.fields[3],
                step.fields[4],
                step.fields[5],
              ],
            }
          : step,
      );
      return updatedFields;
    });
  };

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  const handleSelectionChange = (selectedSheep) => {
    if (!formFields) {
      return;
    }
    setSelectedSheep(selectedSheep);
    const isValid = selectedSheep.length > 0;
    setValidFieldsInCurrentStep(isValid);
  };

  useEffect(() => {
    // Check validity
    if (!formFields) {
      return;
    }
    if (
      currentStep === 0 &&
      selectedDestinationPID &&
      selectedDeparturePID &&
      selectedVehicle
    ) {
      sessionStorage.setItem('pid', selectedDeparturePID.pid);
      const updatedFields = [...formFields];
      let isValid = FormUtils.checkRequiredFieldsValidity(
        updatedFields[currentStep].fields,
      );
      setValidFieldsInCurrentStep(isValid);
      updatedFields[currentStep].isValid = isValid;
      updateFields(updatedFields);
      FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
    }
  }, [selectedDestinationPID, selectedDeparturePID, selectedVehicle]);

  const handleStepUpdate = async () => {
    const updatedFields = [...formFields];

    let isValid = FormUtils.checkRequiredFieldsValidity(
      updatedFields[currentStep].fields,
    );

    if (selectedVehicle && !selectedVehicle._id) {
      await handleVehicleCreation();
      isValid = false;
    } else {
      await handleVehicleRemove();
    }
    if (isValid && selectedVehicle) {
      // Update validity logic here
      setValidFieldsInCurrentStep(true);

      // Update the isValid flag for the current step in formFields
      updatedFields[currentStep].isValid = true;
      FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
    }
  };

  useEffect(() => {
    clearTimeout(typingTimeout);
    // Set timeout to detect when input entry stops
    setTypingTimeout(
      setTimeout(() => {
        if (currentStep === 0) {
          if (!formFields) {
            return;
          }
          handleStepUpdate();
        }
      }, 500),
    );
  }, [selectedVehicle]);

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(
          allPremises,
          setSelectedDestinationPID,
          vehicles,
          setSelectedVehicle,
          setSelectedDeparturePID,
          animals,
          setSelectedSheep,
          selectedSheep,
          handleSelectionChange,
          setTypedSheep,
        );
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [allPremises, animals, vehicles]);

  /** Handle Change in typed entry for animal ids */
  useEffect(() => {
    if (
      selectedSheep.length === 0 &&
      (isISONumber(typedSheep) || validateCommaISO(typedSheep)) &&
      currentStep === 1
    ) {
      setValidFieldsInCurrentStep(true);
    } else {
      setValidFieldsInCurrentStep(false);
    }
  }, [currentStep, typedSheep]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );

          if (fieldToUpdate.id === 'departureDate') {
            const timeField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'departureTime',
            );
            FormUtils.checkFieldInputValidity(
              timeField,
              updatedFields[currentStep].fields,
            );
            isValid = FormUtils.checkRequiredFieldsValidity(
              updatedFields[currentStep].fields,
            );
          } else if (fieldToUpdate.id === 'departureTime') {
            const dateField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'departureDate',
            );
            FormUtils.checkFieldInputValidity(
              dateField,
              updatedFields[currentStep].fields,
            );
            isValid = FormUtils.checkRequiredFieldsValidity(
              updatedFields[currentStep].fields,
            );
          }

          if (fieldToUpdate.id === 'country') {
            const regionField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'locations',
            );
            if (value !== '') {
              regionField.disabled = false;
            }
          }

          // Verify all searchables are filled in step 0
          if (currentStep === 0) {
            isValid =
              isValid &&
              selectedDestinationPID &&
              selectedDeparturePID &&
              selectedVehicle;
          }

          setValidFieldsInCurrentStep(isValid);

          if (currentStep === 2 && isValid) {
            setValidFieldsInCurrentStep(true);
            setValidForm(true);
            updatedFields[2].isValid = true;
            updateFields(updatedFields);
            return;
          }

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    let isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );

    if (
      currentStep === 0 &&
      isValid &&
      selectedVehicle &&
      selectedVehicle._id &&
      selectedDeparturePID &&
      selectedDestinationPID
    ) {
      formFields[0].isValid = true;
    }

    if (currentStep === 1) {
      const validity =
        selectedSheep.length > 0 ||
        isISONumber(typedSheep) ||
        validateCommaISO(typedSheep);
      isValid = validity;
      formFields[1].isValid = validity;
    }

    if (currentStepData.isValid || stepIndex < currentStep) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      if (nextStep === 2) {
        const updatedFields = [...formFields];
        const fwrDatetime = updatedFields[2].fields.find(
          (field) => field.id === 'fwrDatetime',
        );
        fwrDatetime.validity = FormUtils.checkFieldInputValidity(
          fwrDatetime,
          updatedFields[2].fields,
        );
        updateFields(updatedFields);
      }
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  const handleSubmit = async () => {
    let animalIds;
    if (selectedSheep.length > 0) {
      animalIds = selectedSheep.map((animal) => animal.tag.isoNumber);
    } else {
      animalIds = typedSheep.split(',').map((iso) => iso.trim());
    }
    try {
      const formValues = FormUtils.flattenForm(formFields);

      const localFWRDate = new Date(formValues.fwrDatetime);
      const utcFWRDate = new Date(localFWRDate.toUTCString());
      const utcFWRDateString = utcFWRDate.toISOString();

      const localDepDateTime = new Date(
        `${formValues.departureDate}T${formValues.departureTime}`,
      );
      const utcDepDateTime = new Date(localDepDateTime.toUTCString());
      const utcDepDateTimeString = utcDepDateTime.toISOString();

      const report = await createTransportLog({
        departurePID: selectedDeparturePID.pid
          ? selectedDeparturePID.pid.toUpperCase()
          : selectedDeparturePID,
        destinationPID: selectedDestinationPID.pid
          ? selectedDestinationPID.pid.toUpperCase()
          : selectedDestinationPID,
        animals: animalIds,
        fwrDatetime: utcFWRDateString,
        fwrAddress: formValues.fwrAddress,
        condition: formValues.condition,
        vehicleNumber: selectedVehicle.licensePlateNum || selectedVehicle,
        departureDate: utcDepDateTimeString,
      });

      if (formValues.vehicleName) {
        vehicleInfo.licensePlateNum = selectedVehicle;
        vehicleInfo.name = formValues.vehicleName;
        vehicleInfo.stateProvince = formValues.locations;
        vehicleInfo.operation = formValues.operation;

        try {
          await createVehicle(vehicleInfo);
          toast.success('Success: Vehicle created');
        } catch (err) {
          setSubmitting(false);
          toast.dismiss();
          toast.error('Error creating vehicle: ' + err);
          return;
        }
      }

      setSubmitting(false);
      toast.dismiss();
      toast.success('Success: Report created');
      setRedirect(`${report._id}`);
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      toast.error(err);
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create a Transport Report"
      onCancelPath="/reports/transport/list"
    />
  );
}
