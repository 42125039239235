// PremiseIDUtils.js
import axios from 'axios';

const pidEndpoint = '/api/pid';

const getPIDs = async () => {
  const res = await axios.get(pidEndpoint);
  return res.data;
};

const getPID = async (id) => {
  const res = await axios.get(`${pidEndpoint}?premiseID=${id}`);
  return res.data;
};

const deletePID = async (id) => {
  const res = await axios.delete(`${pidEndpoint}/${id}`);
  return res.data;
};

const createPID = async (pidToCreate) => {
  const res = await axios.post(pidEndpoint, pidToCreate);
  return res.data;
};

export { getPIDs, deletePID, getPID, createPID };
