import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFormFields, {
  getStepOneFields,
} from '../../Fields/AddMoveInFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import { createMoveLog } from '../../utils/MoveUtils.js';
import { createVehicle } from '../../utils/VehicleUtils.js';
import {
  isISONumber,
  validateCommaISO,
} from 'agroledger-shared-library/TagUtils.js';

export default function AddMoveIn({
  premises,
  allPremises,
  animals,
  vehicles,
}) {
  const [redirect, setRedirect] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedSheep, setSelectedSheep] = useState([]);
  const [selectedDeparturePID, setSelectedDeparturePID] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [typedSheep, setTypedSheep] = useState(null);

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  const handleSelectionChange = (selectedSheep) => {
    if (!formFields) {
      return;
    }
    setSelectedSheep(selectedSheep);
    const isValid = selectedSheep.length > 0;
    setValidFieldsInCurrentStep(isValid);
  };

  useEffect(() => {
    if (
      selectedSheep.length === 0 &&
      (isISONumber(typedSheep) || validateCommaISO(typedSheep)) &&
      currentStep === 1
    ) {
      setValidFieldsInCurrentStep(true);
    } else {
      setValidFieldsInCurrentStep(false);
    }
  }, [currentStep, typedSheep]);

  useEffect(() => {
    // Check validity
    if (currentStep === 0 && formFields) {
      const updatedFields = [...formFields];
      const fieldToUpdate = updatedFields[currentStep].fields.find(
        (field) => field.id === 'destinationPID',
      );
      if (fieldToUpdate.value && selectedVehicle && selectedDeparturePID) {
        // Update validity logic here
        setValidFieldsInCurrentStep(true);

        // Update the isValid flag for the current step in formFields
        updatedFields[currentStep].isValid = true;
        // Update the form fields
        updateFields(updatedFields);
        FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
      }
    }
  }, [selectedVehicle, selectedDeparturePID]);

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(
          premises,
          allPremises,
          animals,
          setSelectedSheep,
          selectedSheep,
          setSelectedDeparturePID,
          vehicles,
          setSelectedVehicle,
          handleSelectionChange,
          setTypedSheep,
        );
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [premises, allPremises, animals, vehicles]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );

          if (currentStep === 0 && selectedVehicle && selectedDeparturePID) {
            setValidFieldsInCurrentStep(isValid);
          } else if (currentStep !== 0) {
            setValidFieldsInCurrentStep(isValid);
          }

          if (fieldToUpdate.id === 'country') {
            const regionField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'locations',
            );
            if (value !== '') {
              regionField.disabled = false;
            }
          }

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    let isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );

    setValidFieldsInCurrentStep(isValid);

    if (
      (currentStep === 1 && selectedSheep.length > 0) ||
      isISONumber(typedSheep) ||
      validateCommaISO(typedSheep)
    ) {
      setValidFieldsInCurrentStep(true);
      formFields[1].isValid = true;
    }

    if (currentStep === 0 && !selectedVehicle && !selectedDeparturePID) {
      setValidFieldsInCurrentStep(false);
      formFields[0].isValid = false;
    }

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (
      currentStepData.isValid ||
      stepIndex < currentStep ||
      (currentStep === 1 && selectedSheep.length > 0)
    ) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  const handleSubmit = async () => {
    let animalIds;
    if (selectedSheep.length > 0) {
      animalIds = selectedSheep.map((animal) => animal.tag.isoNumber);
    } else {
      animalIds = typedSheep.split(',').map((iso) => iso.trim());
    }
    try {
      setSubmitting(true);
      const fieldValues = FormUtils.flattenForm(formFields);

      await createMoveLog({
        moveOut: false,
        departurePID: selectedDeparturePID.pid || selectedDeparturePID,
        destinationPID: fieldValues.destinationPID,
        animals: animalIds,
        vehicleNumber: selectedVehicle.licensePlateNum || selectedVehicle,
        receivedTime: fieldValues.receivedDate + 'T' + fieldValues.receivedTime,
        unloadTime: fieldValues.unloadDate + 'T' + fieldValues.unloadTime,
      });

      setSubmitting(false);
      toast.success('Success: Report created');
      setRedirect('/reports/movein');
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      toast.error('Error creating report: ' + err);
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create a Move In Report"
      onCancelPath="/reports/movein"
    />
  );
}
