import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';
import { localTimeFormatter } from '../../utils/TimeUtils';

const detailExportColumns = [
  { id: 'isoNumber', name: 'ISO Number', required: true },
  { id: 'localMgmtNumber', name: 'Local Management Number', required: true },
  { id: 'tattooNumber', name: 'Tattoo Number', required: true },
  { id: 'breed', name: 'Breed', required: true },
  { id: 'gender', name: 'Gender', required: true },
];

const getPremiseName = async (id) => {
  try {
    const response = await axios.get(`/api/premises/pid/${id}`);
    return response.data;
  } catch (err) {
    // Return a default value or handle the error gracefully
    return { name: 'N/A' };
  }
};

const fetchMoveInInfo = async (id) => {
  try {
    const response = await axios.get(`/api/move/${id}`);
    return response.data;
  } catch (err) {
    throw new Error(`An error occurred fetching move-in info: ${err.message}`);
  }
};

const Info = ({ name, value }) => (
  <div>
    <span>{name}:&nbsp;</span>
    <span>{value}</span>
  </div>
);

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any,
};

const MoveInReportDetailView = () => {
  const [moveInReportInfo, setMoveInReportInfo] = useState(null);
  const [moveInSheepInfo, setMoveInSheepInfo] = useState([]);
  const [departurePremiseName, setDeparturePremiseName] =
    useState('Loading...');
  const [destPremiseName, setDestPremiseName] = useState('Loading...');
  const [reportLocation, setReportLocation] = useState(null);

  const params = useParams();
  const id = params.id;

  const fetchAndSetMoveInReportInfo = async () => {
    try {
      const fetchedMoveInReportInfo = await fetchMoveInInfo(id);

      fetchedMoveInReportInfo.departureTime = localTimeFormatter(
        fetchedMoveInReportInfo.departureTime,
      );
      fetchedMoveInReportInfo.receivedTime = localTimeFormatter(
        fetchedMoveInReportInfo.receivedTime,
      );
      fetchedMoveInReportInfo.unloadTime = localTimeFormatter(
        fetchedMoveInReportInfo.unloadTime,
      );

      const fetchedDeparturePremiseName = await getPremiseName(
        fetchedMoveInReportInfo.departurePID,
      );
      setDeparturePremiseName(fetchedDeparturePremiseName.name);

      const fetchedDestPremiseName = await getPremiseName(
        fetchedMoveInReportInfo.destinationPID,
      );
      setDestPremiseName(fetchedDestPremiseName.name);

      setMoveInSheepInfo(fetchedMoveInReportInfo.animals);
      setMoveInReportInfo(fetchedMoveInReportInfo);

      // if this report has a geolocation, save that to a state as well
      if (
        fetchedMoveInReportInfo.location &&
        Array.isArray(fetchedMoveInReportInfo.location.coordinates) &&
        fetchedMoveInReportInfo.location.coordinates.length === 2
      ) {
        const [lat, lng] = fetchedMoveInReportInfo.location.coordinates;
        setReportLocation([lat, lng]);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchAndSetMoveInReportInfo();
  }, [id]);

  moveInSheepInfo.forEach((s) => {
    s.isoNumber = s.tag.isoNumber ? s.tag.isoNumber : <NAField />;
    s.localMgmtNumber = s.tag.localMgmtNumber ? (
      s.tag.localMgmtNumber
    ) : (
      <NAField />
    );
    s.tattooNumber = s.tag.tattooNumber ? s.tag.tattooNumber : <NAField />;
    s.breed = s.breed ? s.breed : <NAField />;
    s.gender = s.gender ? s.gender : <NAField />;
  });

  return (
    <>
      <PageCard
        title="Move-In Report Details"
        verify={`/verify/movein/${id}`}
        back
      >
        {moveInReportInfo ? (
          <>
            <SimpleCard title="Premise Information" size="md">
              <HalfDiv>
                <Info
                  name="Departure Premise PID"
                  value={moveInReportInfo.departurePID}
                />
              </HalfDiv>

              <HalfDiv>
                <Info
                  name="Destination Premise PID"
                  value={moveInReportInfo.destinationPID}
                />
              </HalfDiv>

              <HalfDiv>
                <Info
                  name="Departure Premise Name"
                  value={departurePremiseName}
                />
              </HalfDiv>

              <HalfDiv>
                <Info name="Destination Premise Name" value={destPremiseName} />
              </HalfDiv>
            </SimpleCard>
            <br />
            <br />

            <SimpleCard title="Transported Sheep" size="md">
              <StripedTable
                columns={detailExportColumns}
                rows={moveInSheepInfo}
                ShowCount
                max={10}
              />
            </SimpleCard>

            {moveInReportInfo.departureTime && (
              <SimpleCard title="Departure Date" size="sm">
                <Info name="Date" value={moveInReportInfo.departureTime} />
              </SimpleCard>
            )}
            <br />

            {moveInReportInfo.receivedTime && (
              <SimpleCard title="Receive Date" size="sm">
                <HalfDiv>
                  <Info
                    name="Date"
                    value={moveInReportInfo.receivedTime.split('T')[0]}
                  />
                </HalfDiv>
                <HalfDiv>
                  <Info
                    name="Unload Time"
                    value={moveInReportInfo.unloadTime}
                  />
                </HalfDiv>
              </SimpleCard>
            )}
            <br />

            {reportLocation && (
              <SimpleCard title="Location" size="sm">
                <HalfDiv>
                  <Info name="Latitude" value={reportLocation[0]} />
                </HalfDiv>
                <HalfDiv>
                  <Info name="Longitude" value={reportLocation[1]} />
                </HalfDiv>
              </SimpleCard>
            )}
          </>
        ) : (
          <>Loading...</>
        )}
      </PageCard>
    </>
  );
};

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`;

export default MoveInReportDetailView;
