import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFormFields from '../../Fields/AddImportFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import { createImportReport } from '../../utils/ImportUtils.js';
import { validateSingleCsip } from 'agroledger-shared-library/TagUtils';

export default function AddImport({
  premises,
  countries,
  abattoirPids,
  vehicles,
}) {
  const [redirect, setRedirect] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(
          premises,
          countries,
          abattoirPids,
          vehicles,
          selectedVehicle,
          setSelectedVehicle,
        );
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [premises, countries, abattoirPids, vehicles]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );

          setValidFieldsInCurrentStep(isValid);

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    const isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );

    setValidFieldsInCurrentStep(isValid);

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStepData.isValid || stepIndex < currentStep) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  /** Submit the Operation to the Database */
  const handleSubmit = async () => {
    try {
      setSubmitting(true);
      const formValues = FormUtils.flattenForm(formFields);
      let animalIdentificationsArray = [];
      if (formValues.animalIdentifications !== '') {
        animalIdentificationsArray =
          formValues.animalIdentifications.split(/[ ,]+/);
        for (let i = 0; i < animalIdentificationsArray.length; i++) {
          const animalId = animalIdentificationsArray[i];
          const validityCheck = validateSingleCsip(animalId, '');
          if (!validityCheck.validity) {
            toast.error(`Animal ID ${animalId} is Invalid.`);
            setSubmitting(false);
            return;
          }
        }
      }

      const vehicleIdentification = selectedVehicle
        ? selectedVehicle.licensePlateNum || selectedVehicle
        : null;
      const report = await createImportReport({
        exportingCountry: formValues.exportingCountry,
        departureSite: formValues.departureSite,
        abattoirPID: formValues.abattoirPID.toUpperCase(),
        firstDestinationPID: formValues.firstDestinationPID,
        dateOfArrival: formValues.dateOfArrival,
        animalIdentifications: animalIdentificationsArray,
        vehicleIdentification: vehicleIdentification,
      });

      setSubmitting(false);
      toast.dismiss();
      toast.success('Success: Report created');
      setRedirect(`/reports/import/${report._id}`);
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      toast.error('Error submitting report: ' + err);
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create a new Import Report"
    />
  );
}
