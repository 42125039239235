import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFormFields, {
  getFilteredSheep,
} from '../../Fields/AddMedicalFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import { createMedicalReport } from '../../utils/MedicalReportUtils.js';

export default function AddMedical({ premises, animals }) {
  const [redirect, setRedirect] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedAnimals, setSelectedAnimals] = useState([]);
  const [treatments, setTreatments] = useState([]);

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  const handleSelectionChange = (selectedAnimals) => {
    setSelectedAnimals(selectedAnimals);
    const isValid = selectedAnimals.length > 0;
    setValidFieldsInCurrentStep(isValid);
    formFields[0].isValid = isValid;
  };

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(
          premises,
          treatments,
          setTreatments,
        );
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [premises, animals]);

  useEffect(() => {
    if (treatments && treatments.length > 0) {
      const updatedFields = [...formFields];
      let isValid = FormUtils.checkRequiredFieldsValidity(
        updatedFields[currentStep].fields,
      );
      setValidFieldsInCurrentStep(isValid);
      updatedFields[currentStep].isValid = isValid;
      updateFields(updatedFields);
      FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
    }
  }, [treatments]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );

          // On premise change, apply filter to animals
          if (fieldToUpdate.id === 'premise') {
            setSelectedAnimals([]);
            const updatedStepFields = getFilteredSheep(
              value,
              animals,
              handleSelectionChange,
            );
            updatedFields[currentStep].fields = [
              updatedFields[currentStep].fields[0],
              updatedFields[currentStep].fields[1],
              ...updatedStepFields,
            ];
            isValid = false;
          }

          // Trigger validity check if a treatment date changes
          if (fieldToUpdate.id === 'firstTreatmentDate') {
            const dateField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'finalTreatmentDate',
            );
            FormUtils.checkFieldInputValidity(
              dateField,
              updatedFields[currentStep].fields,
            );
            isValid = FormUtils.checkRequiredFieldsValidity(
              updatedFields[currentStep].fields,
            );
          } else if (fieldToUpdate.id === 'finalTreatmentDate') {
            const dateField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'firstTreatmentDate',
            );
            FormUtils.checkFieldInputValidity(
              dateField,
              updatedFields[currentStep].fields,
            );
            isValid = FormUtils.checkRequiredFieldsValidity(
              updatedFields[currentStep].fields,
            );
          }

          if (currentStep === 1) {
            isValid = isValid && treatments && treatments.length > 0;
          }

          setValidFieldsInCurrentStep(isValid);

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    let isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );
    setValidFieldsInCurrentStep(isValid);

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStep === 0) {
      const validity = selectedAnimals.length > 0;
      isValid = validity;
      formFields[1].isValid = validity;
    } else if (currentStep === 1) {
      const validity = treatments.length > 0;
      isValid = validity;
      formFields[1].isValid = validity;
    }

    if (currentStepData.isValid || stepIndex < currentStep) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  const handleSubmit = async () => {
    try {
      const medicineList = treatments.map((m) => m.medicine);
      const dosageList = treatments.map((d) => d.dosage);
      const expiryDateList = treatments.map((e) => e.expiryDate);
      const isPrescriptionList = treatments.map((t) => t.isPrescription);
      const routeList = treatments.map((r) => r.route);

      setSubmitting(true);

      const fields = FormUtils.flattenForm(formFields);

      const report = await createMedicalReport({
        premiseID: fields.premise,
        animals: selectedAnimals.map((a) => a._id),
        animalWeight: fields.animalWeight,
        numberOfAnimals: selectedAnimals.length,
        firstTreatmentDate: fields.firstTreatmentDate,
        finalTreatmentDate: fields.finalTreatmentDate,
        treatmentReason: fields.treatmentReason,
        medicine: medicineList,
        expiryDate: expiryDateList,
        dosage: dosageList,
        isPrescription: isPrescriptionList,
        route: routeList,
        meatWithdrawalDate: fields.meatWithdrawalDate,
        milkWithdrawalDate: fields.milkWithdrawalDate,
        location: fields.location,
      });
      setSubmitting(false);
      toast.dismiss();
      toast.success('Success: Report created');
      setRedirect(`/reports/medical/${report._id}`);
    } catch (error) {
      setSubmitting(false);
      toast.dismiss();
      if (error.response && error.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error('Failed to create medical report');
      }
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create a new Medical Report"
      onCancelPath="/reports/medical/list"
    />
  );
}
