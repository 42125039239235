// PremiseIDView.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PageCard from '../../components/PageCard.js';
import { getPID } from '../../utils/PremiseIDUtils.js';
import { getOperationTypes } from '../../utils/OperationTypeUtils.js';

const PremiseIDView = () => {
  const [premiseIdObj, setPremiseIdObj] = useState();

  const params = useParams();
  const premiseId = params.id;

  const fetchAndSetPremiseId = async () => {
    const result = await getPID(premiseId);
    const operationTypes = await getOperationTypes();
    //const operationType = operationTypes.filter(o => o.name == result.operation.operationType)[0];

    const transformedPremiseIds = {
      premiseID: result.premiseID,
      name: result.operation.businessName,
      number: result.operation.phoneNumber,
      cvsp: result.operation.cvsp,
      sfcp: result.operation.sfcp,
      type: operationTypes.filter(
        (o) => o.name === result.operation.operationType,
      )[0].niceName,
    };

    setPremiseIdObj(transformedPremiseIds);
  };

  useEffect(() => {
    if (!premiseIdObj) fetchAndSetPremiseId();
  }, []);

  const fields = [
    { id: 'premiseID', name: 'Premise ID' },
    { id: 'name', name: 'Name' },
    { id: 'number', name: 'Phone Number' },
    { id: 'cvsp', name: 'CVSP' },
    { id: 'sfcp', name: 'SFCP' },
    { id: 'type', name: 'Type of Operation' },
  ];

  return (
    <>
      <PageCard title={`View Details: ${premiseId}`} back>
        {premiseIdObj ? (
          fields.map((f) => (
            <div key={f.id}>
              <strong>{f.name}:</strong> {premiseIdObj[f.id].toString()}
            </div>
          ))
        ) : (
          <>Loading...</>
        )}
      </PageCard>
    </>
  );
};

export default PremiseIDView;
