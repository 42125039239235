// OperationUtils.js
import axios from 'axios';

const operationEndpoint = '/api/operations';
const getOperationTypes = async () => {
  const res = await axios.get('/api/operationType');
  return res.data;
};

const createOperation = async (operation) => {
  console.log(operation);
  const res = await axios.post(operationEndpoint, operation);
  console.log(res);
  return res.data;
};

const getOperationUpdateLogs = (username) => {
  return axios
    .get(`/api/operations/update/logs?username=${username}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const createOperationUpdateLog = (data) => {
  const url = `/api/operations/update/logs/${data.id}`;
  return axios
    .post(url, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const updateOperation = async (operation, id) => {
  const res = await axios.put(`${operationEndpoint}/${id}`, operation);
  console.log(res);
  return res.data;
};

const getOperationById = async (id) => {
  const res = await axios.get(`${operationEndpoint}/${id}`);
  return res.data;
};

const getOperations = async () => {
  const res = await axios.get(operationEndpoint);
  return res.data;
};

const deleteOperation = async (id) => {
  const res = await axios.delete(`${operationEndpoint}/${id}`);
  return res.data;
};

const getUserActiveOperations = async (username) => {
  try {
    const res = await axios.get(`/api/operations/user/${username}`);

    const activeOperations = res.data.filter(
      (operation) => !operation.isDeleted,
    );

    return activeOperations;
  } catch (error) {
    console.error('Error fetching operations:', error);
    throw error;
  }
};

const addUserToOperation = async (operationId, username) => {
  const res = await axios.put(
    `/api/operations/${operationId}/user/${username}`,
  );
  return res.data;
};

const removeUserFromOperation = async (operationId, username) => {
  const res = await axios.delete(
    `/api/operations/${operationId}/user/${username}`,
  );
  return res.data;
};

const addAdminToOperation = async (operationId, username) => {
  const res = await axios.put(
    `/api/operations/${operationId}/admin/${username}`,
  );
  return res.data;
};

const removeAdminFromOperation = async (operationId, username) => {
  const res = await axios.delete(
    `/api/operations/${operationId}/admin/${username}`,
  );
  return res.data;
};
export {
  getOperationTypes,
  createOperation,
  getOperationUpdateLogs,
  createOperationUpdateLog,
  updateOperation,
  getOperationById,
  getOperations,
  deleteOperation,
  getUserActiveOperations,
  addUserToOperation,
  removeUserFromOperation,
  addAdminToOperation,
  removeAdminFromOperation,
};
