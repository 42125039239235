import React from 'react';
import styled from 'styled-components';
import PageCard from '../components/PageCard.js';
import { COLORS } from '../utils/Constants.js';

const LearnMore = () => {
  return (
    <PageCard title="Learn More" back>
      <StyledTitle> CVSP (Canadian Verified Sheep Program) </StyledTitle>
      <span style={{ fontSize: 20 }}>
        The Canadian Verified Sheep Program is Canada&apos;s quality assurance
        program for sheep ranchers and farmers. Developed by producers with
        input from veterinarians, industry stakeholders and government, this
        voluntary program helps farmers and ranchers implement, demonstrate and
        validate exemplary food safety, animal welfare and biosecurity
        management practices. With the help of a training session and the
        Canadian Verified Sheep Program Manual, you will have the tools to
        reduce risks.
      </span>
      <div>&nbsp;</div>
      <StyledTitle> SFCP (Scrapie Flock Certification Program)</StyledTitle>
      <span style={{ fontSize: 20 }}>
        The SFCP is a national flock/herd scrapie risk management program for
        the Canadian sheep and goat industries and is available to any sheep or
        goat producer who agrees to meet the requirements of the program. The
        primary benefit to participating producers is the individual risk
        protection against scrapie in their flock/herd. Over time, producers
        will have their flock/herd recognized as being at a much lower risk for
        scrapie than the rest of the farms in the national herd. This
        recognition can increase trade opportunities within Canada and allows
        increased access to the import of live animals from the United States.
        The long term goal of the SFCP is to eradicate scrapie from the national
        flock and herd and to help open up market access for export.
      </span>
    </PageCard>
  );
};

const StyledTitle = styled.h1`
  color: ${COLORS.primary};
  font-size: 35px;
  text-decoration: underline;
`;

export default LearnMore;
