import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFormFields from '../../Fields/AddTagReplacementFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import axios from 'axios';

export default function AddReplaceTag({ premises, animals, vehicles }) {
  const [redirect, setRedirect] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedSheep, setSelectedSheep] = useState(null);
  const [selectedVehicle, setSelectedVehicle] = useState([]);

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  const handleSheepChange = (sheep) => {
    setSelectedSheep(sheep);
  };

  useEffect(() => {
    // Check validity based on selectedSheep and currentStep
    if (selectedSheep && currentStep === 0 && formFields) {
      const updatedFields = [...formFields];
      const fieldToUpdate = updatedFields[currentStep].fields.find(
        (field) => field.id === 'pid',
      );

      if (fieldToUpdate.value) {
        // Update validity logic here
        setValidFieldsInCurrentStep(true);

        // Update the isValid flag for the current step in formFields
        updatedFields[currentStep].isValid = true;
        // Update the form fields
        updateFields(updatedFields);
        FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
      }
    }
  }, [selectedSheep]);

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(
          premises,
          animals,
          handleSheepChange,
          vehicles,
          setSelectedVehicle,
        );
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [premises, animals]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );

          if (fieldToUpdate.id === 'pid') {
            sessionStorage.setItem('pid', value);
          }
          if (selectedSheep && currentStep === 0) {
            setValidFieldsInCurrentStep(isValid);
          } else if (currentStep !== 0) {
            setValidFieldsInCurrentStep(isValid);
          }
          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    const isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );
    setValidFieldsInCurrentStep(isValid);

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStepData.isValid || stepIndex < currentStep) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  const handleSubmit = async () => {
    const fieldValues = FormUtils.flattenForm(formFields);
    try {
      fieldValues.vehicle = selectedVehicle.licensePlateNum || selectedVehicle;
      setSubmitting(true);
      let id = selectedSheep;
      if (selectedSheep.tag) {
        id =
          selectedSheep.tag.isoNumber ||
          selectedSheep.tag.usScrapieId ||
          selectedSheep.tag.localMgmtNumber ||
          selectedSheep.tag.tattooNumber;
      }

      const res = await axios.post(`/api/tagreplacement/${id}`, fieldValues);
      setSubmitting(false);
      setRedirect(`/reports/replace/view/${res.data._doc._id}`);
    } catch (err) {
      setSubmitting(false);
      if (err.response && err.response.data.message) {
        if (err.response.status === 409) {
          const errorMessage = err.response.data.message;

          if (errorMessage.includes('CSIP')) {
            toast.error(
              err.response.data.message + ': Tag Reuse is not Permitted',
            );
          }
        } else {
          toast.error(err.response.data.message);
        }
      } else {
        toast.error(err.message);
      }
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create Tag Replacement Report"
      onCancelPath="/reports/replace/listView"
    />
  );
}
