// path: /premises/update/:id

import React, { useState, useEffect } from 'react';
import { useParams, Redirect } from 'react-router-dom';
import { PopUpWindow } from '../../components/CommonComponents';
import Form from '../../components/Form';
import {
  getAllProvinceAbbrv,
  getProvinceNameFromAbbrv,
} from '../../utils/Constants.js';
import { getUsername } from '../../utils/TokenUtils';
import { getUserActiveOperations } from '../../utils/OperationUtils';

import { toast } from 'react-toastify';
import SearchableDropdown from '../../components/SearchableDropdown';
const axios = require('axios');

const PremiseUpdate = () => {
  const [originalPremise, setOriginalPremise] = useState({});
  const [redirected, setRedirected] = useState('');
  const [operations, setOperations] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const username = getUsername();
  const params = useParams();
  const [selectedOperation, setSelectedOperation] = useState();

  const getPremise = async () => {
    try {
      const { data } = await axios.get(`/api/premises/${params.id}`);
      setOriginalPremise(data);
    } catch (e) {
      console.error(e);
      toast.error('Could not get existing premise');
    }
  };

  const getOperations = async () => {
    try {
      const data = await getUserActiveOperations(username);
      setOperations(data);
    } catch (e) {
      console.error(e);
      toast.error('Could not get list of associated operations');
    }
  };

  const submitPremise = async (formValues) => {
    try {
      formValues.operation = selectedOperation
        ? selectedOperation
        : originalPremise.operation;
      if (formValues.postalCode) {
        formValues.postalCode = formValues.postalCode.toUpperCase();
      }
      await axios.put(`/api/premises/${params.id}`, formValues);
      await axios.put(`/api/premises/${params.id}/operation`, formValues);
      setRedirected(`/premises/${params.id}`);
      toast.success('Premise successfully updated');
    } catch (e) {
      toast.error('Could not submit edited premise data');
    }
  };

  useEffect(() => {
    sessionStorage.clear();
    (async () => {
      await getOperations();
      await getPremise();
      setLoaded(true);
    })();
  }, []);

  if (redirected) {
    return <Redirect to={redirected} />;
  }

  const fields = [
    {
      name: 'Premise Name',
      value: originalPremise.name,
      id: 'name',
    },
    {
      name: 'Address',
      value: originalPremise.address,
      id: 'address',
      required: true,
    },
    {
      name: 'Postal Code',
      value: originalPremise.postalCode ?? '',
      id: 'postalCode',
      validityCheck: (field) => {
        const postalRegEx =
          /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/;
        if (
          field.postalCode.trim() !== '' &&
          !postalRegEx.test(field.postalCode.toUpperCase().trim())
        ) {
          return 'Invalid Postal Code';
        }
        return true;
      },
    },
    {
      name: 'Municipality',
      value: originalPremise.municipality ?? '',
      id: 'municipality',
    },
    {
      name: 'Province',
      id: 'province',
      value: originalPremise.province,
      type: 'select',
      options: getAllProvinceAbbrv().map((p) => ({ name: p, value: p })),
      required: true,
    },
    {
      name: 'Description',
      value: originalPremise.description ?? '',
      id: 'description',
      type: 'textarea',
      maxLength: 100,
    },
    {
      name: 'Associated Operation',
      type: 'content',
      content: (
        <SearchableDropdown
          id="operation-search"
          type="operation"
          optionKey="businessName"
          options={operations}
          placeholder={
            originalPremise.operation && originalPremise.operation._id
              ? originalPremise.operation.businessName
              : 'Select an operation'
          }
          onChange={(businessName) => setSelectedOperation(businessName)}
          onSelect={(option) => setSelectedOperation(option)}
          onSelectionChange={setSelectedOperation}
        />
      ),
      description: 'Select an operation that this premise belongs to.',
    },
    {
      name: 'Location (Latitude, Longitude)',
      value: originalPremise.location ?? '',
      id: 'location',
      type: 'location',
    },
  ];

  return (
    <PopUpWindow>
      {loaded && (
        <Form
          title="Update a Premise"
          fieldInfo={fields}
          onCancel={() => setRedirected('/premises')}
          onSubmit={submitPremise}
        />
      )}
    </PopUpWindow>
  );
};

export default PremiseUpdate;
