import React from 'react';
import { searchableDropdownField } from './commonFields';
import { formatDateField } from '../utils/TimeUtils';
import { Link } from 'react-router-dom';

const getFormFields = (animals, premises, handleAnimalSelect) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Complete this form to report animal sightings, if you do not have an animal, create one here:  `,
      LinkData: { label: 'Add an Animal', path: '/sheep/create/manual' },
    },
    {
      ...searchableDropdownField(
        'Animal IDs',
        'animals',
        'sheep',
        'tag.isoNumber',
        animals,
        'Search by Animal ID',
        handleAnimalSelect,
        'Fill out the ID of the animal',
        true,
      ),
    },
  ];

  // Step 2 fields
  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Provide the details for your sighting:`,
    },
    {
      name: 'Premise',
      id: 'premise',
      type: 'select',
      options: premises,
      required: true,
    },
    {
      type: 'content',
      content: (
        <>
          {premises.length == 0 && (
            <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
              You have not added a premise yet.&nbsp;
              <Link to="/premises" style={{ color: 'black' }}>
                {' '}
                Add one here{' '}
              </Link>
            </p>
          )}
        </>
      ),
    },
    {
      name: 'Reporter of Sighting',
      id: 'reporter',
      type: 'text',
      required: true,
    },
    {
      name: 'Date Sighted',
      id: 'sightDate',
      type: 'date',
      required: true,
      validity: true,
      value: formatDateField(new Date()),
      errorMessage: 'Sighting Date cannot be in the future',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Location (Latitude, Longitude)',
      hint: 'Latitude, Longitude',
      id: 'location',
      type: 'location',
    },
  ];

  // Grouping steps into a single array
  const addSightingFields = [
    {
      name: 'Animal Details',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'You must provide an animal',
    },
    {
      name: 'Sighting Details',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'Required fields missing',
    },
  ];
  return addSightingFields;
};

export default getFormFields;
