// FeedbackRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FeedbackView from '../views/FeedbackView';

const FeedbackRouter = () => (
  <Switch>
    <Route exact path="/feedback" component={FeedbackView} />
  </Switch>
);

export default FeedbackRouter;
