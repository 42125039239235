import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import SearchableDropdown from '../components/SearchableDropdown';
import styled from 'styled-components';
import { extractDataFromHeaders } from '../utils/CSVUtils';
import { getUserActiveOperations } from '../utils/OperationUtils';

import { Redirect } from 'react-router';
import { Link } from '@material-ui/core';
import propTypes from 'prop-types';
import {
  FetchPremises,
  FetchVehicles,
  VehicleFormatter,
  PremisesFormatter,
} from './SheepReportComponents';
import { createVehicle, regionOptions } from '../utils/VehicleUtils.js';

import Form from './Form.js';

import { createDate } from '../utils/TimeUtils.js';
import { getUsername } from '../utils/TokenUtils.js';
import { bulkMove } from '../utils/MoveUtils';
import Table from './Table.js';
import { Button } from './CommonComponents.js';

const CheckBox = ({ onChange, checked }) => {
  return (
    <input
      style={{ cursor: 'pointer' }}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  );
};

CheckBox.propTypes = {
  onChange: propTypes.func,
  checked: propTypes.bool,
};

const fetchPremises = async () => {
  const fetchedPremises = await FetchPremises();
  return fetchedPremises;
};

const fetchVehicles = async () => {
  const fetchedVehicles = await FetchVehicles();
  const formattedVehicles = VehicleFormatter(fetchedVehicles);
  return formattedVehicles;
};

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const CsvMoveOutSheepSelect = ({ data, headers }) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);

  const [selectedDestinationPID, setSelectedDestinationPID] = useState(null);
  const [premises, setPremises] = useState([]);
  const [formattedPremises, setFormattedPremises] = useState([]);

  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState();

  const [vehicles, setVehicles] = useState([]);
  const [vehicleOption, setVehicleOption] = useState('');

  const [formValues, setFormValues] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');

  // Setting column constants for select sheep table
  const columns = ['', 'CSIP Number', ''];
  const columnIds = ['isonum', ''];

  useEffect(() => {
    setSheeps(extractDataFromHeaders(data, headers));
    return fetchPremises()
      .then((fetchedPremises) => {
        setPremises(fetchedPremises);
        setFormattedPremises(PremisesFormatter(fetchedPremises));
        return fetchVehicles().then((fetchedVehicles) => {
          setVehicles(fetchedVehicles);
        });
      })
      .catch((err) => {
        toast.error(`An error occurred while fetching data: ${err}`);
      });
  }, [data, headers]);

  useEffect(() => {
    sessionStorage.clear();
    if (operations.length === 0) {
      fetchOperations();
    }
  }, []);

  const fetchOperations = async () => {
    try {
      const result = await getUserActiveOperations(getUsername());
      const reducedOperations = result.map((o) => ({
        name: o.businessName,
        value: o._id,
      }));
      setOperations(reducedOperations);
    } catch (error) {
      console.error('Error fetching operations:', error);
    }
  };

  const operationPrompt = () => {
    if (operations.length === 0) {
      return (
        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {' '}
          You have not added an operation yet.&nbsp;{' '}
          <Link to="/operations" style={{ color: 'black' }}>
            {' '}
            Add one here{' '}
          </Link>
        </p>
      );
    }

    return <></>;
  };

  const sheepRowsWithCheck = sheeps.map((s) => ({
    check: (
      <CheckBox
        checked={
          selectedSheeps.filter(
            (selectedSheep) => selectedSheep.dummyID === s.dummyID,
          ).length > 0
        }
        onChange={() => {
          const alreadySelected =
            selectedSheeps.filter(
              (selectedSheep) => selectedSheep.dummyID == s.dummyID,
            ).length > 0;
          if (alreadySelected) {
            const newSelectedSheeps = selectedSheeps.filter(
              (sheep) => sheep.dummyID !== s.dummyID,
            );
            setSelectedSheeps(newSelectedSheeps);
          } else {
            const newSelectedSheeps = [
              sheeps.filter((sheep) => sheep.dummyID === s.dummyID)[0],
              ...selectedSheeps,
            ];
            setSelectedSheeps(newSelectedSheeps);
          }
        }}
      />
    ),
  }));

  for (let i = 0; i < sheeps.length; i++) {
    for (const columnId of columnIds) {
      sheepRowsWithCheck[i][columnId] =
        columnId !== ''
          ? sheeps[i][columnId]
            ? sheeps[i][columnId]
            : '-'
          : '';
    }
  }

  const SelectAllSection = () => (
    <CenteredButtonDiv>
      <Button
        style={{ marginRight: '10px' }}
        onClick={(e) => {
          e.preventDefault();
          setSelectedSheeps(sheeps);
        }}
      >
        Select All
      </Button>
      {selectedSheeps.length > 0 ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelectedSheeps([]);
          }}
        >
          Clear All
        </Button>
      ) : (
        <></>
      )}
    </CenteredButtonDiv>
  );

  const handleSubmit = (formValues) => {
    setSubmitting(true);
    setFormValues(formValues);
    if (!selectedDestinationPID || !selectedSheeps.length > 0) {
      toast.error('At least one sheep, and a destination PID is required.');
      return;
    }
    toast.info(
      'Submitting move-out report to the server. This may take up to a minute.',
    );

    const logInfo = {
      moveOut: true,
      departurePID: formValues.departurePremise,
      destinationPID: selectedDestinationPID.pid.toUpperCase(),
      destinationType: formValues.destinationType,
      animals: selectedSheeps.map((sheep) => {
        return sheep.isonum;
      }),
      vehicleNumber:
        vehicleOption === 'new'
          ? formValues.licensePlateNum
          : formValues.vehicleNum,
      departureTime: formValues.departureDate + 'T' + formValues.departureTime,
      loadTime: formValues.loadDate + 'T' + formValues.loadTime,
    };

    let vehicleInfo = {};

    if (vehicleOption === 'new') {
      vehicleInfo.licensePlateNum = formValues.licensePlateNum;

      vehicleInfo.provinces = [''];
      vehicleInfo.states = [''];

      vehicleInfo.name = formValues.vehicleName;

      if (formValues.country == 'Canada') {
        vehicleInfo.provinces = [formValues.locations];
      }
      if (formValues.country == 'United States') {
        vehicleInfo.states = [formValues.locations];
      }

      vehicleInfo.operation = selectedOperation?.id;
    }

    bulkMove(logInfo)
      .then(() => {
        if (vehicleOption === 'new') {
          createVehicle(vehicleInfo)
            .then(toast.success('Success: Vehicle created'))
            .catch((err) => {
              setSubmitting(false);
              toast.dismiss();
              toast.error('Error creating vehicle: ' + err);
            });
        }

        setSubmitting(false);
        toast.success('Success: Report created');
        setRedirect('/reports/moveout');
      })
      .catch((err) => {
        setSubmitting(false);
        toast.dismiss();
        toast.error('Error creating move-out report: ' + err);
      });
  };

  const formFields = [
    {
      id: 'departurePremise',
      name: 'Departure Premise',
      type: 'select',
      options: formattedPremises,
      required: true,
    },
    {
      name: 'Destination PID',
      id: 'destinationPremise',
      requiredLabel: true,
      type: 'content',
      content: (
        <>
          <SearchableDropdown
            type="premise"
            optionKey="pid"
            options={premises}
            placeholder="Search by PID..."
            onChange={(pid) => setSelectedDestinationPID(pid)}
            onSelect={(pid) => setSelectedDestinationPID(pid)}
          />
        </>
      ),
    },
    {
      id: 'sheepSelect',
      name: 'Select Sheep to include in Report',
      type: 'content',
      content: (
        <Table columns={columns} rows={sheepRowsWithCheck} paginate={true} />
      ),
    },
    {
      type: 'content',
      content: <SelectAllSection />,
    },
    {
      name: 'Select Destination Type',
      id: 'destinationType',
      type: 'select',
      options: [
        { name: 'Community Pasture', value: 'Community Pasture' },
        { name: 'Abattoir', value: 'Abattoir' },
        { name: 'Other', value: 'Other' },
      ],
      required: true,
    },
    {
      name: 'Departure Date',
      id: 'departureDate',
      type: 'date',
      required: true,
      value: formValues?.departureDate || '',
      validityCheck: (fieldValues) => {
        if (
          new Date(createDate(fieldValues.departureDate)).getTime() > Date.now()
        ) {
          return 'Date of Departure cannot be in the future';
        }
        return true;
      },
    },
    {
      name: 'Departure Time',
      id: 'departureTime',
      type: 'time',
      required: true,
      value: formValues?.departureTime || '',
      validityCheck: (fieldValues) => {
        const [fieldHours, fieldMinutes] = fieldValues.departureTime
          .split(':')
          .map(Number);
        const depDate = new Date(createDate(fieldValues.departureDate));

        if (depDate.setHours(fieldHours, fieldMinutes) > Date.now()) {
          return 'Departure Time cannot be in the future';
        }
        return true;
      },
    },
    {
      name: 'Vehicle Option',
      id: 'vehicleOption',
      type: 'select',
      options: [
        { name: 'Use Existing Vehicle', value: 'exist' },
        { name: 'Create a New Vehicle', value: 'new' },
      ],
      onChange: (fieldValues) => {
        setVehicleOption(fieldValues.vehicleOption);
      },
      required: true,
    },
    vehicleOption == 'exist' && {
      name: 'Vehicle Identifier',
      id: 'vehicleNum',
      description: 'Select the identifier',
      type: 'select',
      options: vehicles,
      required: true,
    },
    vehicleOption == 'new' && {
      name: 'Vehicle Name',
      id: 'vehicleName',
    },
    vehicleOption == 'new' && {
      name: 'Vehicle Identifier',
      id: 'licensePlateNum',
      description: 'License Plate, VIN Number, or Description',
      required: true,
      validityCheck: (fieldValues) => {
        if (!/^[A-Za-z0-9 ]+$/.exec(fieldValues.licensePlateNum)) {
          return 'Vehicle Identification must only contain alphanumeric characters';
        } else {
          return true;
        }
      },
    },
    vehicleOption == 'new' && {
      name: 'Country of Registration',
      id: 'country',
      required: true,
      type: 'select',
      options: Object.keys(regionOptions).map((loc) => ({
        name: loc,
        value: loc,
      })),
    },
    vehicleOption == 'new' && {
      name: 'Region',
      id: 'locations',
      required: true,
      type: 'select',
      optionsFilter: (fields) =>
        fields.country !== '' ? regionOptions[fields.country] : [],
    },
    vehicleOption == 'new' && {
      name: 'Operation',
      type: 'content',
      content: (
        <SearchableDropdown
          id="operation-search"
          type="operation"
          optionKey="businessName"
          options={operations.map((o) => ({
            businessName: o.name,
            id: o.value,
          }))}
          placeholder="Search by Operation Name..."
          onChange={(businessName) => setSelectedOperation(businessName)}
          onSelect={(option) => setSelectedOperation(option)}
        />
      ),
      description: 'Select an operation that this vehicle belongs to.',
    },
    vehicleOption == 'new' && {
      type: 'content',
      content: operationPrompt(),
    },
    {
      type: 'content',
      content: (
        <>
          {premises.length == 0 && (
            <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
              You have not added a premise yet.&nbsp;
              <Link to="/premises" style={{ color: 'black' }}>
                {' '}
                Add one here{' '}
              </Link>
            </p>
          )}
        </>
      ),
    },
    {
      name: 'Loading Date',
      id: 'loadDate',
      type: 'date',
      required: true,
      value: formValues?.loadDate || '',
      validityCheck: (fieldValues) => {
        if (
          new Date(fieldValues.loadDate).getTime() > Date.now() ||
          new Date(fieldValues.loadDate) > new Date(fieldValues.departureDate)
        ) {
          return 'Loading Date cannot be after Move-out Date or be in the future';
        }
        return true;
      },
    },
    {
      name: 'Loading Time',
      id: 'loadTime',
      type: 'time',
      required: true,
      value: formValues?.loadTime || '',
      validityCheck: (fieldValues) => {
        const [loadHours, loadMinutes] = fieldValues.loadTime
          .split(':')
          .map(Number);

        const [depHours, depMinutes] = fieldValues.departureTime
          .split(':')
          .map(Number);

        var depDate = new Date(`${fieldValues.departureDate}T00:00:00`);
        depDate.setHours(depHours, depMinutes);

        var loadDate = new Date(`${fieldValues.loadDate}T00:00:00`);
        loadDate.setHours(loadHours, loadMinutes);

        if (loadDate > Date.now() || loadDate > depDate) {
          return 'Loading Time cannot be after Move-out Time or be in the future';
        }
        return true;
      },
    },
  ].filter(Boolean);

  if (redirect !== '') return <Redirect to={redirect} />;
  return (
    <Form
      style={{ width: '900px', maxWidth: '900px' }}
      title="Bulk Sheep Move Out"
      onSubmit={handleSubmit}
      fieldInfo={formFields}
      disabled={selectedSheeps.length <= 0 || !selectedDestinationPID}
    />
  );
};

CsvMoveOutSheepSelect.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string),
};

export default CsvMoveOutSheepSelect;
