import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';
import { ContentContainer, Icon } from '../components/CommonComponents';
import options from '../images/icons/dot_menu.png';
import editIcon from '../images/icons/edit.png';
import deleteIcon from '../images/icons/delete.png';

/**
 * Card component for a premise details page
 * @param data - data from "GET/api/premises/${params.id}" endpoint
 * @param operation - operation data associated with premise
 * @param editPremise - handleclick function for edit dropdown
 * @param deletePremise - handleClick function for delete dropdown
 */
export const PremiseDetailsCard = ({
  data,
  operation,
  editPremise,
  deletePremise,
}) => {
  const deleteBtn = document.getElementById('deleteModal');

  const confirmDelete = () => {
    deletePremise();
    deleteBtn.style.display = 'none';
  };

  return (
    <ContentContainer alignItems="stretch" position="relative" padding="40px">
      <DeleteModal id="deleteModal">
        <DeleteModalContent>
          <h1 style={{ textAlign: 'center' }}>
            Are you sure you want to delete?
          </h1>
          <h3 style={{ textAlign: 'center' }}>
            This premise will be deleted forever.
          </h3>
          <DeleteModalButtonContainer>
            <DeleteModalButton
              onClick={() => {
                deleteBtn.style.display = 'none';
              }}
            >
              Cancel
            </DeleteModalButton>
            <DeleteModalButton
              onClick={() => {
                confirmDelete();
              }}
              color="white"
              background={COLORS.text}
            >
              Confirm
            </DeleteModalButton>
          </DeleteModalButtonContainer>
        </DeleteModalContent>
      </DeleteModal>
      <CardOptions>
        <Icon width="30px" src={options} />
        <CardDropdownContainer>
          <CardDropdown
            onClick={() => {
              editPremise({ _id: data._id });
            }}
          >
            <Icon width="20px" src={editIcon} />
            Edit
          </CardDropdown>
          <CardDropdown
            onClick={() => {
              deleteBtn.style.display = 'flex';
            }}
          >
            <Icon
              width="20px"
              src={deleteIcon}
              style={{
                filter:
                  'invert(14%) sepia(68%) saturate(4982%) hue-rotate(352deg) brightness(80%) contrast(103%)',
              }}
            />
            Delete
          </CardDropdown>
        </CardDropdownContainer>
      </CardOptions>
      <CardTextBox background={COLORS.secondary}>
        PID:&nbsp;
        <CardTextData>{data.pid}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Premise Name:&nbsp;
        <CardTextData>{data.name || 'N/A'}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Operation:&nbsp;
        <CardTextData>{operation.businessName}</CardTextData>
      </CardTextBox>
      <CardTextContainer>
        <CardTextHeader>Description:</CardTextHeader>
        <CardDescriptionBox>{data.description || 'N/A'}</CardDescriptionBox>
      </CardTextContainer>
      <CardTextBox>
        Address:&nbsp;
        <CardTextData>{data.address}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Username:&nbsp;
        <CardTextData>
          {operation.users ? operation.users[0].username : ''}
        </CardTextData>
      </CardTextBox>
      <CardTextBox>
        Province:&nbsp;
        <CardTextData>{data.province}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Municipality:&nbsp;
        <CardTextData>{data.municipality || 'N/A'}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Postal Code:&nbsp;
        <CardTextData>{data.postalCode || 'N/A'}</CardTextData>
      </CardTextBox>
      <CardTextBox>
        Location (Longitude, Latitude):&nbsp;
        <CardTextData>{data.location || 'N/A'}</CardTextData>
      </CardTextBox>
    </ContentContainer>
  );
};

PremiseDetailsCard.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.any,
    address: PropTypes.any,
    description: PropTypes.string,
    location: PropTypes.string,
    municipality: PropTypes.string,
    name: PropTypes.string,
    pid: PropTypes.any,
    postalCode: PropTypes.string,
    province: PropTypes.any,
  }).isRequired,
  deletePremise: PropTypes.func.isRequired,
  editPremise: PropTypes.func.isRequired,
  operation: PropTypes.any.isRequired,
};

/**
 * Container for a card text box or title if it needs a header included
 */
const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

/**
 * Card text display box
 */
const CardTextBox = styled.div`
  display: flex;
  padding: 15px 20px 15px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 19px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
  color: ${COLORS.text};
  background: ${(props) => (props.background ? props.background : 'white')};
`;

/**
 * Larger sized text box for description
 */
const CardDescriptionBox = styled(CardTextBox)`
  display: flex;
  padding-bottom: 57px;
`;

/**
 * Text (string) data taken from backend
 */
const CardTextData = styled.div`
  font-weight: bold;
  color: ${COLORS.text};
`;

/**
 * Header for a Card Text Box or the card title
 */
const CardTextHeader = styled.div`
  color: ${COLORS.text};
`;

/**
 * Card options button
 */
const CardOptions = styled.button`
  position: absolute;
  top: 1px;
  right: 30px;
  width: 64px;
  border: none;
  background: none;
`;

/**
 * Container that holds all of the boxes for the dropdown options
 */
const CardDropdownContainer = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  background: none;
  top: 40px;
  left: -60px;
  width: 120px;
  border: none;
  border-redius: none;
  z-index: 9999;

  ${CardOptions}:focus-within & {
    display: flex;
  }
`;

/**
 * Dropdown content box for the options button
 */
const CardDropdown = styled.div`
  display: flex;
  align-items: center;
  background: white;
  border: ${COLORS.text} 1px solid;
  font-size: 20px;

  &:hover {
    background: lightgrey;
    transition: background 0.1s;
    cursor: pointer;
  }

  &:first-child {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top: none;
    color: red;
  }
`;

/**
 * Modal popup to delete premise
 */
const DeleteModal = styled.div`
  display: none;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
`;

/**
 * Contents of model for deleting the premise
 */
const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 35%;
  color: ${COLORS.text};
  background-color: ${COLORS.light};
  margin: auto;
  padding: 20px 20px 40px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
  margin: 0px 20px;
`;

const DeleteModalButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

/**
 * Delete Modal button
 */
const DeleteModalButton = styled.button`
  color: ${(props) => (props.color ? props.color : COLORS.text)};
  font-size: 20px;
  font-weight: bold;
  background: ${(props) => (props.background ? props.background : 'white')};
  border: ${COLORS.text} 1px solid;
  border-radius: 30px;
  width: 120px;
  height: 40px;

  &:hover {
    filter: contrast(1.5);
    cursor: pointer;
  }
`;
