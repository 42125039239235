// VehicleUtils.js
import axios from 'axios';

import {
  getAllProvinceAbbrv,
  getProvinceNameFromAbbrv,
  getAllStateAbbrv,
  getStateNameFromAbbrv,
} from './Constants.js';
import { getSelectedRole } from './RoleUtils.js';

const vehicleEndpoint = '/api/vehicles';

const getVehicles = async () => {
  const res = await axios.get(vehicleEndpoint);
  return res.data;
};

const getVehiclesForUser = async (username) => {
  const res = await axios.get('/api/vehicles/user/' + username);
  return res.data;
};

const getVehiclesByOperation = async (operationID) => {
  const res = await axios.get(`${vehicleEndpoint}?operation=${operationID}`);
  return res.data;
};

const createVehicle = async (vehicleToCreate) => {
  const res = await axios.post(vehicleEndpoint, vehicleToCreate);
  return res.data;
};

const getVehicleById = async (vehicleId) => {
  const res = await axios.get(`${vehicleEndpoint}?_id=${vehicleId}`);
  return res.data[0];
};

const getVehicleByUser = async (username) => {
  const selectedRole = getSelectedRole();
  const res = await axios.get(`${vehicleEndpoint}/user/${username}`, {
    params: {
      selectedRole: selectedRole,
    },
  });
  return res.data;
};

const deleteVehicleById = async (vehicleId) => {
  const res = await axios.delete(`${vehicleEndpoint}/${vehicleId}`);
  return res.data;
};

const updateVehicleById = async (vehicleId, updatedVehicle) => {
  const res = await axios.put(
    `${vehicleEndpoint}/${vehicleId}`,
    updatedVehicle,
  );
  return res.data;
};

function validateVehicleForm(form) {
  // Check if all required fields have a value
  if (!form.licensePlateNum || !form.name || !form.operation) {
    return new Error('All required fields must be filled');
  }

  // No whitespace
  if (/^\s*$/.test(form.name) || /^\s*$/.test(form.licensePlateNum)) {
    return new Error('Required fields cannot be empty whitespaces');
  }

  // License plate must be alphanumeric
  if (!/^([a-zA-Z0-9 ])+$/.test(form.licensePlateNum)) {
    return new Error(
      'License plate must be alphanumeric and cannot contain any symbols or special characters',
    );
  }

  if (form.licensePlateNum.replace(/\s+/g, '').length > 8) {
    return new Error('License plate cannot have more than 8 characters');
  }

  return;
}

const regionOptions = {
  Canada: getAllProvinceAbbrv().map((abbrv) => ({
    name: getProvinceNameFromAbbrv(abbrv),
    value: abbrv,
  })),
  'United States': getAllStateAbbrv().map((abbrv) => ({
    name: getStateNameFromAbbrv(abbrv),
    value: abbrv,
  })),
};

export {
  getVehicles,
  getVehiclesByOperation,
  createVehicle,
  getVehicleById,
  getVehicleByUser,
  deleteVehicleById,
  updateVehicleById,
  validateVehicleForm,
  getVehiclesForUser,
  regionOptions,
};
