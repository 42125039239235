// OperationTypeUtils.js
import axios from 'axios';

const operationTypeEndpoint = '/api/operationType';

const getOperationTypes = async () => {
  const res = await axios.get(operationTypeEndpoint);
  return res.data;
};

const getOperationTypeByName = async (name) => {
  const res = await axios.get(`${operationTypeEndpoint}?name=${name}`);
  return res.data;
};

export { getOperationTypes, getOperationTypeByName };
