import React from 'react';

const UnactivatedView = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      }}
    >
      <h1 style={{ marginTop: 0 }}>Your account is unactivated</h1>
      <p>
        Your account needs to be activated before you can start using
        Agroledger.
        <br />
        Contact an administrator to help you get started.
      </p>
    </div>
  );
};

export default UnactivatedView;
