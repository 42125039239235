// Message Area
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const MessageArea = ({ messages }) => {
  return (
    <>
      {messages.map((m) => (
        <MessageContainer key={m}>
          <span>{m}</span>
        </MessageContainer>
      ))}
    </>
  );
};

MessageArea.propTypes = {
  messages: PropTypes.node.isRequired,
};

const MessageContainer = styled.div`
  padding: 10px;
  color: green;
  font-weight: bold;
`;

export default MessageArea;
