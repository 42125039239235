import React, { useState } from 'react';
import styled from 'styled-components';
import SearchBar from './SearchBar';
import propTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import {
  LinkButton,
  VerticalContainer,
  HorizontalContainer,
} from './CommonComponents';

/**
 * Searchable table react element
 * @prop   {Array}     columns             Array of table column strings
 * @prop   {Array}     rows                Array of table row data arrays
 * @prop   {Integer}   maxShowing          (optional) Maximum number of rows displayed in the table
 * @prop   {String}    viewMoreLink        Endpoint that directs user to a view that displays all of the table's rows
 * @prop   {String}    searchPlaceholder   Placeholder for the table's search bar
 */
const SearchableTable = ({
  columns,
  rows,
  maxShowing = 10,
  viewMoreLink,
  searchPlaceholder,
}) => {
  const [search, setSearch] = useState('');

  const searchRows = (rows) => {
    if (rows.length === 0) return rows;
    return rows.filter((row) => {
      for (const data of row) {
        if (typeof data === 'string' && data.includes(search)) return true;
      }
      return false;
    });
  };

  const objectToArray = (object) => {
    let array = [];
    for (const [_key, value] of Object.entries(object)) {
      array.push(value);
    }
    return array;
  };

  let filteredRows = [];
  for (const row of rows) {
    if (typeof row === 'object') {
      filteredRows.push(objectToArray(row));
    } else {
      filteredRows.push(row);
    }
  }
  filteredRows = searchRows(filteredRows);
  filteredRows = filteredRows.slice(0, maxShowing);

  return (
    <VerticalContainer>
      <SearchBar
        value={search}
        placeholder={searchPlaceholder}
        onChange={(e) => setSearch(e.target.value)}
      />
      <StyledTable>
        <thead>
          <tr>
            {columns.map(({ id, name }) => (
              <StyledTh key={id}>{name}</StyledTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredRows.map((row) => {
            return (
              <StyledTr key={row}>
                {row.map((data) => (
                  <StyledTd key={data}>{data}</StyledTd>
                ))}
              </StyledTr>
            );
          })}
        </tbody>
      </StyledTable>
      <HorizontalContainer width="100%">
        <NumberOfResults>
          Showing {Math.min(filteredRows.length, maxShowing)} of {rows.length}{' '}
          results
        </NumberOfResults>
        {viewMoreLink && (
          <ViewMoreButton $stylePreset="dark" to={viewMoreLink}>
            View more
          </ViewMoreButton>
        )}
      </HorizontalContainer>
    </VerticalContainer>
  );
};

SearchableTable.propTypes = {
  columns: propTypes.array,
  rows: propTypes.array,
  maxShowing: propTypes.number,
  viewMoreLink: propTypes.string,
  searchPlaceholder: propTypes.string,
};

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0px 5px;
`;

const StyledTr = styled.tr`
  background: ${COLORS.light};
`;

const StyledTh = styled.th`
  padding-block: 15px;
`;

const StyledTd = styled.td`
  padding-block: 5px;
  padding-inline: 15px;
  border-block: ${COLORS.text} 1px solid;
  font-weight: bold;

  &:first-child {
    border-left: ${COLORS.text} 1px solid;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-child {
    border-right: ${COLORS.text} 1px solid;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`;

const NumberOfResults = styled.small`
  align-self: flex-start;
  margin-block: 10px;
  margin-right: auto;
`;

const ViewMoreButton = styled(LinkButton)`
  align-self: flex-end;
  margin-top: 20px;
`;

export default SearchableTable;
