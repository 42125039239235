import React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionVerificationDashboard from '../views/TransactionVerificationDashboard';
import TransactionVerifyView from '../views/TransactionVerifyView';

const TransactionVerificationRouter = () => (
  <Switch>
    <Route exact path="/verify" component={TransactionVerificationDashboard} />
    <Route
      exact
      path="/verify/:reportType/:id"
      component={TransactionVerifyView}
    />
  </Switch>
);

export default TransactionVerificationRouter;
