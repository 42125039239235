import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import error from '../images/error.png';
import { Redirect } from 'react-router-dom';
import PageCard from '../components/PageCard';

export default function ErrorPage() {
  const [redirect, setRedirect] = useState(false);
  const { pathname } = useLocation();
  const error = {};

  useEffect(() => {
    setTimeout(() => {
      setRedirect(true);
    }, 4000);
  }, []);

  if (redirect) {
    return <Redirect to={'/'} />;
  }

  switch (pathname) {
    case '/403':
      error.type = '403';
      error.title = 'Forbidden';
      error.message =
        'You do not have permission to access this page. ' +
        'You will be redirected back to the home page soon.';
      break;
    default:
      error.type = '404';
      error.title = 'Page Not Found';
      error.message =
        "We can't seem to find the page you are trying to reach. " +
        'You will be redirected back to the home page soon.';
  }

  return (
    <PageCard title={error.type + ': ' + error.title} line>
      {error.message}
      <ErrorImageContainer>
        <ErrorImage />
      </ErrorImageContainer>
    </PageCard>
  );
}

const ErrorImageContainer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const ErrorImage = styled.img.attrs({
  src: `${error}`,
})`
  width: 600px;
  height: 400px;
  margin: 10px;
`;
