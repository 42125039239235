import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import { createDate, formatDateField } from '../../utils/TimeUtils';
import DynamicFormFields from '../../components/reusedComponents/DynamicFormFields';
import StyledForm from '../../components/reusedComponents/StyledForm';
import { getUsername } from '../../utils/TokenUtils';
import { checkAdmin } from '../../utils/RoleUtils';
import { getSheepsForUser } from '../../utils/SheepUtils';
import { ConfirmationModal } from '../../components/reusedComponents/ConfirmationModal.js';
import styled from 'styled-components';
import { Button } from '../../components/CommonComponents';
import { Redirect, useHistory } from 'react-router-dom';
import propTypes from 'prop-types';
import SheepDualListDisposal from './SheepDualListDisposal';

// Styled components
const SubmittingDiv = styled.div`
  text-align: center;
`;

const DisposeDiv = styled.div`
  margin-top: 2rem;
  text-align: center;
`;

// Component to display a spinner while submitting
const SubmittingSpinner = () => (
  <SubmittingDiv>
    <FontAwesomeIcon icon="spinner" spin />
    &nbsp;Submitting...
  </SubmittingDiv>
);

// Fields for the disposal form
const disposalFields = [
  {
    name: 'Disposal Report Type',
    id: 'action',
    type: 'select',
    options: [
      { name: 'Slaughter', value: 'slaughter' },
      { name: 'Carcass Disposal', value: 'carcass' },
    ],
    required: true,
    errorMessage: 'Please select a disposal report type',
    validityCheck: (fieldValues) => {
      return fieldValues.value !== '';
    },
  },
  {
    name: 'Disposal Date',
    id: 'disposalDate',
    type: 'date',
    required: true,
    validity: true,
    value: formatDateField(new Date()),
    errorMessage: 'Date of Disposal cannot be in the future',
    validityCheck: (fieldValues) => {
      return createDate(fieldValues.value).getTime() <= Date.now();
    },
  },
  {
    name: 'Location (Latitude, Longitude)',
    hint: 'Latitude, Longitude',
    id: 'location',
    type: 'location',
    required: true,
    validityCheck: (fieldValues) => {
      return true;
    },
  },
];

// Function to dispose of sheep
const disposeOfSheep = async (sheepIDs, type, dateOf, location) => {
  try {
    await axios.delete('/api/sheep/bulk', {
      data: {
        sheep: sheepIDs,
        type: type,
        date: dateOf,
        location: location,
      },
    });
    toast.dismiss();
    toast.success('Sheep disposed successfully!');
  } catch (err) {
    throw new Error(
      `An error occurred submitting the sheep: ${err.response.data.message}`,
    );
  }
};

// Main component for sheep disposal view
const SheepDisposeView = () => {
  const history = useHistory();
  // Extract premiseId from URL query parameters
  const searchParams = new URLSearchParams(location.search);
  const premiseId = searchParams.get('pid');
  const sheepId = searchParams.get('id');
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [formFields, setFormFields] = useState(disposalFields);
  const [showModal, setShowModal] = useState(false);

  // Component for the disposal section
  const DisposeSection = ({ onClick, count, disabled }) => (
    <DisposeDiv>
      <Button
        onClick={onClick}
        type="submit"
        disabled={disabled}
        style={{ whiteSpace: 'nowrap', padding: '15px' }}
      >
        Dispose {count}
      </Button>
    </DisposeDiv>
  );

  DisposeSection.propTypes = {
    onClick: propTypes.func.isRequired,
    count: propTypes.number.isRequired,
    disabled: propTypes.bool.isRequired,
  };

  // Handler for form submission
  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      await disposeOfSheep(
        selectedSheeps.map((s) => s.id),
        formFields[0].value,
        formFields[1].value,
        formFields[2].value,
      );
      setRedirect(history.goBack());
    } catch (err) {
      setSubmitting(false);
      console.error(err);
      toast.error(err.message);
    }
  };

  // Fetch and transform sheep data
  const fetchSheepsAndTransform = async () => {
    try {
      let fetchedSheeps = await getSheepsForUser(getUsername(), checkAdmin());
      fetchedSheeps = fetchedSheeps.filter((sheep) => {
        return !sheep.isExported && sheep?.premise?.pid === premiseId;
      });

      const transformedSheeps = fetchedSheeps.map((s) => ({
        id: s._id,
        isoNumber: s.tag.isoNumber,
        localMgmtNumber: s.tag.localMgmtNumber,
        tattooNumber: s.tag.tattooNumber,
        usScrapieId: s.tag.usScrapieId,
        gender: s.gender,
        selected: false,
        clickedOn: false,
      }));
      setSheeps(transformedSheeps);
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);

    fetchSheepsAndTransform();
  }, []);

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  // Handler for field changes in the form
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[index];

    if (fieldToUpdate) {
      fieldToUpdate.value = value;
      // Perform validity check
      fieldToUpdate.validity = fieldToUpdate.validityCheck(fieldToUpdate);
      setFormFields(updatedFields);
      setFormFields(updatedFields);
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  // Check if all fields are valid
  const allFieldsValid = formFields.every((field) => field.validity);

  return (
    <PageCard
      maxContent={`100%`}
      line
      back
      title={`${premiseId}'s Sheep Disposal`}
    >
      <p
        style={{ fontSize: 'x-large', marginRight: 'auto', fontWeight: 'bold' }}
      >
        Welcome to the Disposal Page. This page allows you to select and dispose
        sheep from your premise.
      </p>
      <p style={{ fontSize: '18px', marginRight: 'auto', fontWeight: 'bold' }}>
        Use the dual list to choose the sheep you wish to dispose of. Then,
        enter the required disposal information.
      </p>
      <>
        <SheepDualListDisposal
          sheepId={sheepId}
          sheep={sheeps}
          setSelectedSheep={setSelectedSheeps}
        />
        <SimpleCard title="Disposal Information" size="md">
          <StyledForm
            width="100%"
            height="100%"
            align_items="stretch"
            backgroundColor="transparent"
          >
            <DynamicFormFields
              stepFields={formFields}
              handleFieldChange={handleFieldChange}
            />
          </StyledForm>
          {submitting ? <SubmittingSpinner /> : <></>}
        </SimpleCard>
        <DisposeSection
          count={selectedSheeps.length}
          onClick={() => setShowModal(true)}
          disabled={
            submitting || selectedSheeps.length === 0 || !allFieldsValid
          }
        />
        <ConfirmationModal
          message={`Are you sure you would like to dispose ${selectedSheeps.length} sheep `}
          cancel={() => {
            setShowModal(false);
          }}
          confirm={handleSubmit}
          show={showModal}
        />
      </>
    </PageCard>
  );
};

export default SheepDisposeView;
