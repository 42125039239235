import React from 'react';
import { FetchCarcassDisposalReports } from '../../components/SheepReportComponents';
import SheepReportListComponent from '../../components/SheepReportListComponent';

const CarcassDisposalListView = () => {
  return (
    <>
      <SheepReportListComponent
        title="Carcass Disposal Reports"
        name="carcass disposal report"
        createLink="/sheep/dispose"
        description="Carcass Disposal Reports must be created when carcasses are disposed of"
        fetchReportsFunction={FetchCarcassDisposalReports}
        detailEndpoint={'/reports/raw/'}
      />
    </>
  );
};

export default CarcassDisposalListView;
