import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../utils/Constants';

const StyledTitle = styled.h1`
  align-self: center;
  margin-top: 0;
  margin-bottom: 2em;
  color: ${(props) => props.color || COLORS.text};
`;

const FormTitle = ({ title, ...props }) => {
  return <StyledTitle {...props}>{title}</StyledTitle>;
};

FormTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default FormTitle;
