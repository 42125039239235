import React from 'react';

import {
  CSIPNumberField,
  localMgmtNumberField,
  tattooNumberField,
  usScrapieIdField,
} from './commonFields';

import FileUploadButton from '../components/FileUploadButton.js';

const getFormFields = (
  premises,
  breeds,
  breedPopulateHandler,
  setAssociatedDocument,
  associatedDocument,
) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Please add the associated Premise for your sheep if you have not
            already done so.`,
      LinkData: { label: ' Add a Premise', path: '/premises/create' },
    },
    {
      name: 'Premise',
      id: 'premise',
      type: 'select',
      options: premises,
      required: true,
      errorMessage:
        'Premise required. Sheep must be associated with their premises.',
      validityCheck: (field) => {
        return (
          field.value !== '' &&
          field.value !== null &&
          field.value !== undefined
        );
      },
    },
  ];

  // Step 2 fields
  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `You must provide at least one of the following
            identifiers:`,
    },
    { ...CSIPNumberField },
    {
      ...localMgmtNumberField,
    },
    {
      ...tattooNumberField,
    },
    {
      ...usScrapieIdField,
    },
  ];

  // Step 3 fields
  const stepThreeFields = [
    {
      name: 'Gender',
      id: 'gender',
      type: 'select',
      options: [
        { name: 'Male', value: 'male' },
        { name: 'Female', value: 'female' },
      ],
    },
    {
      name: 'Sub Gender',
      id: 'subgender',
      type: 'select',
      optionsFilter: (fieldValues) => {
        // Find the object with id 'gender'
        const genderField = fieldValues.find((field) => field.id === 'gender');
        const subgenderField = fieldValues.find(
          (field) => field.id === 'subgender',
        );

        // Check if the gender field exists and its value
        if (genderField && genderField.value === 'female') {
          subgenderField.required = true;
          return [
            { name: 'Ewe', value: 'ewe' },
            { name: 'Ewe Lamb', value: 'ewelamb' },
          ];
        } else if (genderField && genderField.value === 'male') {
          subgenderField.required = true;

          return [
            { name: 'Ram', value: 'ram' },
            { name: 'Wether', value: 'wether' },
          ];
        } else {
          return [];
        }
      },
    },
    {
      name: 'Breed',
      id: 'breed',
      type: 'select',
      options: breeds,
      subfield: {
        name: 'Custom Breed',
        id: 'customBreed',
        type: 'text',
      },
    },

    {
      name: 'Date of Birth',
      id: 'birthdate',
      type: 'date',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.birthdate).getTime() > Date.now()) {
          return 'Date of Birth cannot be in the future';
        }
        return true;
      },
    },
    {
      type: 'instruction',
      subtype: 'withSubtitle',
      subtitle: 'Attach Relevant Documents',
      message: `Upload any relevant documentation related to your sheep.`,
    },
    {
      name: 'Title',
      id: 'documentTitle',
      type: 'text',
    },
    {
      name: 'Description',
      id: 'documentDescription',
      type: 'textarea',
      value: '',
      maxLength: 500,
    },
    {
      name: 'Document',
      type: 'content',
      id: 'uploadFile',

      content: (
        <FileUploadButton
          buttonLabel="Upload"
          fileFormat=".pdf"
          files={associatedDocument}
          setFiles={setAssociatedDocument}
          stylePreset="dark"
        />
      ),
    },
  ];

  // Grouping steps into a single array
  const addSheepFields = [
    {
      name: 'Premise Selection',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Premise is Required',
    },
    {
      name: 'Sheep Identifiers',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'You must provide at least one of the IDs',
    },
    {
      name: 'Sheep Details',
      fields: stepThreeFields,
      isValid: true,
      errorMessage: 'Required fields are missing',
    },
  ];
  return addSheepFields;
};

export default getFormFields;
