// SlaughterReportRouter.js
import React from 'react';
import SlaughterReportListView from '../views/Sheep/SlaughterReportListView.js';
import SlaughterReportCreateView from '../views/SlaughterReportCreateView.js';
import { Route } from 'react-router-dom';

const SlaughterReportRouter = () => (
  <>
    <Route exact path="/slaughterReport" component={SlaughterReportListView} />
    <Route
      exact
      path="/slaughterReport/create"
      component={SlaughterReportCreateView}
    />
  </>
);

export default SlaughterReportRouter;
