import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  VerticalContainer,
  Icon,
} from './CommonComponents';
import { COLORS } from '../utils/Constants';
import propTypes from 'prop-types';
import add from '../images/icons/add.png';

const FileUploadLabel = styled.label`
  display: inline-block;
  text-align: center;
  text-decoration: none;
  margin-block: 10px;
  border-radius: 25px;
  padding-block: 10px;
  padding-inline: 10px;
  color: white;
    color: ${COLORS.primary};
  background-color: white;
  border: ${COLORS.primary} solid 2px;
  cursor: pointer;
  white-space: nowrap;

  * {
    vertical-align: middle;
  }

  &:hover {
    color: white;
    background-color: ${COLORS.primary};
  }

  ${(props) => {
    switch (props.$stylePreset) {
      case 'default':
        return `
          color: ${COLORS.primary};
          background-color: white;
          border: ${COLORS.primary} solid 2px;

          & > img {
            filter: invert(31%) sepia(78%) saturate(498%) hue-rotate(65deg) brightness(98%) contrast(90%);
          }

          &:hover {
            color: white;
            background-color: ${COLORS.primary};

            & > img {
              filter: invert(100%) brightness(200%);
            }
          }
        `;
      case 'bold':
        return `
          color: white;
          background-color: ${COLORS.primary};
          border: ${COLORS.primary} solid 2px;
          font-size: large;
          font-weight: bold;

          & > img {
                  filter: invert(100%) brightness(200%);
          }

          &:hover {
                  color: ${COLORS.primary};
                  background-color: white;

                  & > img {
                          filter: invert(31%) sepia(78%) saturate(498%) hue-rotate(65deg) brightness(98%) contrast(90%);
                  }
          }
         `;
      case 'dark':
        return `
          color: white;
          background-color: ${COLORS.text};
          border: ${COLORS.text} solid 2px;

          & > img {
            filter: invert(100%) brightness(200%);
          }

          &:hover {
            color: ${COLORS.text};
            background-color: white;

            & > img {
              filter: invert(13%) sepia(53%) saturate(549%) hue-rotate(64deg) brightness(93%) contrast(87%);
            }
          }
        `;
    }
  }}

`;

const HiddenInput = styled.input`
  display: none
`;

const FileText = styled.label`
  display: inline-block;
  text-align: center;
  font-size: 13px;
  padding-top: 10px;
  text-decoration: none;
  color: ${COLORS.dark};
  background: none;
`;

const ClearButton = styled.button`
  color: blue;
  text-decoration: underline;
  border: none;
  background: none;
  padding: 0px;
  padding-top: 10px;
  font-size: 13px;
  cursor: pointer;
`;

const FileUploadButton = ({buttonLabel, fileFormat, files, setFiles, stylePreset, icon}) => {
  const [localFileNames, setLocalFileNames] = useState(files ? files.map((f) => (f.name)): []);

  //stash files in local storage for later
  useEffect(() => {
    const storedFiles = sessionStorage.getItem('fileNames');
    if (storedFiles) {
      setLocalFileNames(JSON.parse(storedFiles));
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem('fileNames', JSON.stringify(localFileNames));
  }, [localFileNames]);

  const handleUpload = (e) => {
    const newFiles = [...files, ...Array.from(e.target.files)];
    const newFileNames = [...localFileNames, ...(Array.from(e.target.files)).map((f) => (f.name))];
    setFiles(newFiles);
    setLocalFileNames(newFileNames);
  };

  return (
    <VerticalContainer align_items='start'>
      <FileUploadLabel $stylePreset={stylePreset} for="file-upload">
        <span>{buttonLabel}</span>
        {icon &&
        <Icon width="25px" m="0px" src={add} />
        }
      </FileUploadLabel>
      <HiddenInput id="file-upload" type="file" accept={fileFormat} onChange={handleUpload} multiple/>
      {localFileNames.map((f, index) => (
        <FileText key={index}>{f}</FileText>
      ))}
      {localFileNames.length > 0 && 
        <ClearButton
          onClick={() => {
            setFiles([]);
            setLocalFileNames([]);
          }}
        >
          clear all
        </ClearButton>
      }
    </VerticalContainer>
  );
};

FileUploadButton.propTypes = {
  buttonLabel: propTypes.any,
  fileFormat: propTypes.any,
  setFiles: propTypes.func,
  files: propTypes.array,
  stylePreset: propTypes.any,
  icon: propTypes.bool,
};

export default FileUploadButton;