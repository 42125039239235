import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  HorizontalContainer,
  LinkButton,
  OldBackLink,
  VerticalContainer,
  Button,
} from '../../components/CommonComponents.js';
import { COLORS } from '../../utils/Constants.js';
import CleanTable from '../../components/CleanTable.js';
import ViewApproveDenyIconMenu from '../../components/reusedComponents/ViewApproveDenyIconMenu.js';
import SearchBar from '../../components/SearchBar.js';
import {
  dynamicSortAlpha,
  dynamicSortDate,
  dynamicSortNumeric,
} from '../../utils/ListUtils.js';
import add from '../../images/icons/add.png';

import { data } from './TagAllocationData.js'; // <--- Temporary, just for testing
import { getSelectedRole } from '../../utils/RoleUtils.js';

const columns = [
  { name: 'Tag Manufacturer', id: 'manufacturer' },
  { name: '# of Tags Requested', id: 'numberOfTags' },
  { name: 'Approval/Denial Date', id: 'decisionDate' },
  { name: 'Status', id: 'status' },
  { name: 'Actions', id: 'actions' },
];

export default function TagAllocationRequestListView() {
  const [redirect, setRedirect] = useState();
  const history = useHistory();

  const [allocationRequests, setAllocationRequests] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchAndFormatAllocationRequests();
  }, []);

  const fetchAndFormatAllocationRequests = () => {
    let fetchedAllocationReqs = data;
    fetchedAllocationReqs.forEach((r) => {
      r.actions = (
        <ViewApproveDenyIconMenu viewLink={`/tagallocation/request/${r._id}`} />
      );
    });

    setAllocationRequests(fetchedAllocationReqs);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = (sortField) => {
    const sortedArr = [...allocationRequests];

    if (sortField === 'numberOfTags' || sortField === '-numberOfTags')
      setAllocationRequests(sortedArr.sort(dynamicSortNumeric(sortField)));
    else if (sortField === 'decisionDate' || sortField === '-decisionDate')
      setAllocationRequests(sortedArr.sort(dynamicSortDate(sortField)));
    else setAllocationRequests(sortedArr.sort(dynamicSortAlpha(sortField)));
  };

  const filteredTagAllocReqs = allocationRequests.filter((r) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      r.manufacturer.toLowerCase().includes(lowerCaseQuery) ||
      r.numberOfTags.includes(lowerCaseQuery) ||
      r.name.toLowerCase().includes(lowerCaseQuery) ||
      r.status.toLowerCase().includes(lowerCaseQuery)
    );
  });

  if (redirect) return <Redirect to={redirect} />;

  if (
    getSelectedRole() !== 'admin' &&
    getSelectedRole() !== 'tagManufacturer'
  ) {
    return (
      <VerticalContainer
        justify_content="center"
        align_items="center"
        mt="50px"
      >
        <h2>You do not have permission to view this page.</h2>
        <Button onClick={() => setRedirect('/')}>Go Home</Button>
      </VerticalContainer>
    );
  }

  return (
    <VerticalContainer justify_content="start" align_items="start" mt="50px">
      <OldBackLink onClick={history.goBack}>&larr; Back</OldBackLink>
      <Header style={{ marginRight: 'auto' }}>Tag Allocation Requests</Header>
      <p>All requests from that you have made are listed here.</p>

      <p>Click the eye icon to view the full details from your request.</p>
      <SearchAndCreateDiv>
        <SearchContainer>
          <SearchBar
            placeholder="Search Tag Allocation Requests"
            value={searchQuery}
            onChange={handleSearch}
          />
        </SearchContainer>

        <LinkButton $stylePreset="dark" to="/tagallocation/requests/create">
          <span>Add a Request </span>
          <AddIcon src={add} />
        </LinkButton>
      </SearchAndCreateDiv>

      <CleanTable
        columns={columns}
        rows={filteredTagAllocReqs}
        handleSort={handleSort}
        defaultSortColumnId="decisionDate"
      />
    </VerticalContainer>
  );
}

const Header = styled.h2`
  color: ${COLORS.primary};
  margin-top: 0px;
  margin-bottom: 20px;
`;

const SearchContainer = styled.div`
  width: 300px;
`;

const SearchAndCreateDiv = styled(HorizontalContainer)`
  width: 100%;
  justify-content: space-between;
`;

const AddIcon = styled.img`
  width: 25px;
  margin: 0;
`;
