import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import approve from '../../images/icons/check.png';
import deny from '../../images/icons/no-entry.png';
import eye from '../../images/icons/eye.png';
import { HorizontalContainer } from '../CommonComponents';

export default function ViewApproveDenyIconMenu({
  approveLink,
  viewLink,
  denyLink,
}) {
  const history = useHistory();

  const linkInfo = [
    {
      image: eye,
      action: viewLink,
    },
    {
      image: deny,
      action: denyLink,
    },
    {
      image: approve,
      action: approveLink,
    },
  ];

  return (
    <ViewApproveDenyContainer>
      {linkInfo.map((link) => {
        if (link.action && link.action !== 'disabled') {
          return (
            <IconLink
              key={link.image}
              {...(typeof link.action === 'function'
                ? {
                    onClick: (e) => {
                      e.preventDefault();
                      link.action();
                    },
                  }
                : {
                    onClick: () => {
                      history.push(link.action);
                    },
                  })}
            >
              <Icon src={link.image} />
            </IconLink>
          );
        } else if (link.action === 'disabled') {
          return (
            <DisabledIconLink
              onClick={(e) => e.preventDefault()}
              key={link.image}
            >
              <Icon src={link.image} />
            </DisabledIconLink>
          );
        }
      })}
    </ViewApproveDenyContainer>
  );
}

const ViewApproveDenyContainer = styled(HorizontalContainer)`
  justify-content: space-between;
  width: 70px;
  flex-direction: row-reverse;
`;

const IconLink = styled.div`
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const DisabledIconLink = styled.div`
  cursor: not-allowed;
  opacity: 0.3;
`;

const Icon = styled.img`
  width: 15px;
`;
