/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PageCard from '../components/PageCard.js';
import { localTimeFormatter } from '../utils/TimeUtils.js';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants.js';
import email from '../images/icons/email.png';
import { Notification } from '../components/Notifications.js';
import SearchBar from '../components/SearchBar.js';
import { toast } from 'react-toastify';
import { ConfirmationModal } from '../components/reusedComponents/ConfirmationModal.js';

const axios = require('axios');

export default function NotificationsView() {
  const [notificationList, setNotificationList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const fetchNotificationReport = async () => {
    try {
      const response = await axios.get(`/api/notifications/user`);
      setNotificationList(
        response.data.reverse().map((n) => ({
          ...n,
          date: localTimeFormatter(n.date),
        })),
      );
    } catch (error) {
      console.error('Error fetching notifications:', error);
      // Handle error appropriately, e.g., show error message to user
    }
  };

  useEffect(() => {
    fetchNotificationReport();
  }, []);

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredNotifications = notificationList.filter((r) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      r.message?.toLowerCase().includes(lowerCaseQuery) ||
      '' ||
      r.subject?.toLowerCase().includes(lowerCaseQuery) ||
      '' ||
      r.sender?.toLowerCase().includes(lowerCaseQuery) ||
      ''
    );
  });

  const updateNotificationRead = async (_id, markUnread = false) => {
    const result = await axios.post(`/api/notifications/read/${_id}`, {
      markUnread: markUnread,
    });
    if (result.data.updated) {
      notificationList.forEach((notification) => {
        if (notification._id === _id) {
          notification.read = !markUnread;
        }
      });
    }
    setNotificationList([...notificationList]);
  };

  const markAllNotificationsAsRead = async () => {
    try {
      await axios.post('/api/notifications/read');
      notificationList.forEach((notification) => {
        notification.read = true;
      });
      setNotificationList([...notificationList]);
      toast.success('All notifications marked as read.');
    } catch (error) {
      toast.error('Failed to mark all notifications as read.');
      console.error('Error marking notifications as read:', error);
    }
  };

  const deleteNotification = (data) => {
    return axios
      .delete(`/api/notifications/delete/${data}`)
      .then((res) => {
        fetchNotificationReport();
        toast.success('Notification Deleted');
        return res.data;
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        throw err.response.data.message;
      });
  };

  const deleteAllNotifications = async () => {
    try {
      const response = await axios.delete(`/api/notifications/delete`);
      fetchNotificationReport();
      toast.success('All Notifications Deleted');
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
      throw error.response.data.message;
    }
  };

  return (
    <PageCard back title="Notifications">
      <IconDiv>
        <h1>Inbox</h1>
        <IconCircle>
          <Icon src={email} />
        </IconCircle>
      </IconDiv>
      {notificationList.length !== 0 && (
        <>
          <ButtonContainer>
            <Button
              onClick={() => {
                setShowModal(true);
              }}
            >
              Delete All
            </Button>
            <Button
              onClick={() => {
                markAllNotificationsAsRead();
              }}
            >
              Mark All as Read
            </Button>
          </ButtonContainer>
          <SearchContainer>
            <SearchBar
              placeholder="Search Notifications"
              value={searchQuery}
              onChange={handleSearch}
            />
          </SearchContainer>
          <Notification
            data={filteredNotifications}
            updateNotif={updateNotificationRead}
            deleteNotif={deleteNotification}
          />
          <ConfirmationModal
            message="Are you sure you would like to delete all notifications?"
            show={showModal}
            cancel={() => setShowModal(false)}
            confirm={() => {
              deleteAllNotifications();
              setShowModal(false);
            }}
          />
        </>
      )}
      {notificationList.length === 0 && <h3>No Notifications to Show</h3>}
    </PageCard>
  );
}

const SearchContainer = styled.div`
  width: 300px;
`;

const ButtonContainer = styled.div`
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: ${COLORS.primary};
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${COLORS.secondary};
    color: black;
  }
`;

// Icon Components
const IconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const Icon = styled.img`
  width: 20px;
`;

const IconCircle = styled.div`
  display: flex;
  border-radius: 50%;
  background: ${COLORS.secondary};
  margin: 5px;
  padding: 10px;
`;
