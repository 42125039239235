import { useState } from 'react';
import DecisionCard from '../../components/DecisionCard';
import React from 'react';

const LeavePremiseDecisionView = () => {
  const [currentId, setCurrentId] = useState('INIT');

  const EventDecisions = [
    {
      id: 'INIT',
      backToHelp: true,
      title: 'Leaving Premises - Community Pasture',
      description: 'Did the animal(s) move to a community pasture?',
      choices: [
        {
          name: 'Yes',
          update: () => {
            setCurrentId('COMMUNITY');
          },
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setCurrentId('ONABATTOIR');
          },
        },
      ],
    },
    {
      id: 'COMMUNITY',
      backToHelp: true,
      prev: 'INIT',
      onBack: () => setCurrentId('INIT'),
      title: 'Leaving Premises - Community Pasture',
      description:
        'You are not required to report the disposal of an untagged animal on its farm of origin',
      choices: [
        {
          name: 'Create Move-out Report',
          link: '/reports/moveout/new',
        },
      ],
    },
    {
      id: 'ONABATTOIR',
      backToHelp: true,
      prev: 'INIT',
      onBack: () => setCurrentId('INIT'),
      title: 'Leaving Premises - Abattoir',
      description: 'Are the animal(s) moving out to an abattoir?',
      choices: [
        {
          name: 'Yes',
          update: () => {
            setCurrentId('ABATTOIR');
          },
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setCurrentId('NOTABATTOIR');
          },
        },
      ],
    },
    {
      id: 'ABATTOIR',
      backToHelp: true,
      prev: 'ONABATTOIR',
      onBack: () => setCurrentId('ONABATTOIR'),
      title: 'Leaving Premises - Abattoir',
      description:
        'Abattoirs are required to report the move out of live animals from their premises. You can create a move-out report here',
      choices: [
        {
          name: 'Create Move-out Report',
          link: '/reports/moveout/new',
        },
      ],
    },
    {
      id: 'NOTABATTOIR',
      backToHelp: true,
      prev: 'ONABATTOIR',
      onBack: () => setCurrentId('ONABATTOIR'),
      title: 'Leaving Premises - Non Abattoir',
      description:
        'Operations other than abattoirs are not required to report the movement of animals ' +
        'off their premises (unless animals are moving to a community pasture). However, ' +
        'a movement document is required to accompany the movement of animals between premises ' +
        '(this does not apply to the movement of animals within the same operation). You can ' +
        'create a movement document for animals moving off your premises here.',
      choices: [
        {
          name: 'Create Move-out Report',
          link: '/reports/moveout/new',
        },
      ],
    },
  ];

  const getDecision = (id) => {
    const decisions = EventDecisions.filter((d) => d.id == id);
    if (decisions.length == 0) {
      setCurrentId('INIT');
      alert('Invalid decision id');
      return EventDecisions[0];
    }

    return decisions[0];
  };

  return <DecisionCard decision={getDecision(currentId)} />;
};

export default LeavePremiseDecisionView;
