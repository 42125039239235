// TagActivationListView.js
import React from 'react';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import { getAllTagActivationReports } from '../../utils/TagUtils';
import { localTimeFormatter } from '../../utils/TimeUtils';

const headerColumns = [
  {
    id: 'date',
    name: 'Created At',
  },
  {
    id: 'premiseID',
    name: 'Premises ID',
  },
  {
    id: 'isoNumber',
    name: 'ISO Number',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const TagActivationListView = () => {
  const fetchActivationReports = async () => {
    let fetchedReports = await getAllTagActivationReports();
    fetchedReports = fetchedReports.reverse();
    //get properly formatted time when displaying
    fetchedReports.forEach((report) => {
      report.premiseID = report.premise;
      report.date = report.date ? localTimeFormatter(report.date) : '';
    });

    return fetchedReports;
  };

  return (
    <>
      <SheepReportListComponent
        title="Tag Activation"
        description="A tag activation report records when an approved identifier is first affixed to an animal. 
      You are not required to report a tag activation while the animal is still on its farm of origin, but
      doing so allows you to track animals in your own inventory and add attributes to the animal. You are
      required to report a tag activation when affixing an identifier to an acquired animal that has not
      previously been identified"
        fetchReportsFunction={fetchActivationReports}
        detailEndpoint="/reports/tagactivation/"
        createEndpoint="/reports/tagactivation/create"
        columns={headerColumns}
      />
    </>
  );
};

export default TagActivationListView;
