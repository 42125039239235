import { operationSelect } from './commonFields';

const getFormFields = (operations, provinces) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Please add the associated Operation for your premise if you have not
            already done so. `,
      LinkData: { label: 'Add an Operation', path: '/operations/create' },
    },
    {
      ...operationSelect(operations),
    },
  ];

  // Step 2 fields
  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Provide the details for your premise:`,
    },
    {
      name: 'PID',
      required: true,
      hint: 'ON3456789',
      type: 'text',
      id: 'pid',
      errorMessage: 'PID Must be Alphanumeric',
      validityCheck: (field) => {
        const value = field.value.trim();
        return !!value && /^[a-zA-Z0-9]*$/.test(value);
      },
    },
    {
      name: 'Premise Name',
      hint: "John's Sheep Farm",
      id: 'name',
      type: 'text',
    },
    {
      name: 'Description',
      hint: 'Home to many sheep...',
      id: 'description',
      type: 'textarea',
      maxLength: 100,
    },
  ];

  // Step 3 fields
  const stepThreeFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Provide the location details for your premise:`,
    },
    {
      name: 'Address',
      hint: '3 CherryTree Street',
      id: 'address',
      type: 'text',
      required: true,
      validityCheck: (field) => !!field.value.trim(),
    },
    {
      name: 'Postal Code',
      hint: 'B4P 9K3',
      id: 'postalCode',
      type: 'text',
      errorMessage: 'Invalid Postal Code',
      validityCheck: (field) => {
        const postalRegEx =
          /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z] ?\d[ABCEGHJ-NPRSTV-Z]\d$/;
        if (
          field.value.trim() !== '' &&
          !postalRegEx.test(field.value.trim())
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Municipality',
      hint: 'Highwater',
      id: 'municipality',
      type: 'text',
    },
    {
      name: 'Province',
      id: 'province',
      type: 'select',
      options: provinces.map((p) => ({ name: p, value: p })),
      required: true,
    },
    {
      name: 'Location (Latitude, Longitude)',
      hint: 'Latitude, Longitude',
      id: 'location',
      type: 'location',
    },
  ];

  // Grouping steps into a single array
  const addPremiseFields = [
    {
      name: 'Operation Selection',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Operation is Required',
    },
    {
      name: 'Premise Details',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'You must provide a PID',
    },
    {
      name: 'Premise Location',
      fields: stepThreeFields,
      isValid: false,
      errorMessage: 'Required fields are missing',
    },
  ];
  return addPremiseFields;
};

export default getFormFields;
