import React from 'react';
import { Icon } from '../components/CommonComponents';
import styled from 'styled-components';
import propTypes from 'prop-types';

const DescriptionCard = ({ plural, page, content, icon }) => (
  <DescriptionBox>
    <DescriptionTitle>
      Welcome to {plural || `${page}s`}
      {icon ? (
        <Icon
          width="35px"
          m="-10px"
          ml="5px"
          src={icon}
          hover="none"
          cursor="pointer"
        />
      ) : null}
    </DescriptionTitle>
    <DescriptionHeader>
      You can view, edit, and delete all of your {plural || `${page}s`} within
      this page!
    </DescriptionHeader>
    {/* conditionally render description */}
    <div>
      {content ? (
        <DescriptionContent>{content}</DescriptionContent>
      ) : (
        <DescriptionContent>
          Each row in the table is an individual {page} with information about
          various fields. The buttons underneath the Action column represent the
          3 actions: View, Edit and Delete.
        </DescriptionContent>
      )}
    </div>
  </DescriptionBox>
);

DescriptionCard.propTypes = {
  plural: propTypes.node,
  page: propTypes.node,
  content: propTypes.node,
  icon: propTypes.node,
};
export default DescriptionCard;

const DescriptionBox = styled.div`
  background-color: white;
  margin: 10px 0;
  border-radius: 10px;
`;

const DescriptionTitle = styled.div`
  color: black;
  font-weight: bold;

  text-decoration: bold;
  font-size: 30px;
  margin-block: 10px;
`;

const DescriptionHeader = styled.div`
  color: black;
  font-size: 25px;
  margin-block: 30px;
`;

const DescriptionContent = styled.div`
  color: black;
`;
