import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import propTypes from 'prop-types';
import {
  ContentContainer,
  HorizontalContainer,
  VerticalContainer,
  Icon,
  Button,
} from '../../components/CommonComponents';
import ViewEditDispose from '../../components/ViewEditDispose';
import { COLORS } from '../../utils/Constants';
import SearchBar from '../../components/SearchBar';
import left from '../../images/icons/chevron_left.png';
import right from '../../images/icons/chevron_right.png';

const GetSheepsByPremise = async (premiseId) => {
  const request = await axios.get(
    `/api/sheep?premiseID=${premiseId}&premise=true`,
  );
  const fetchedSheeps = request.data;
  let premiseSheeps = [];
  for (const sheep of fetchedSheeps) {
    if (sheep.premise._id === premiseId) {
      premiseSheeps.push(sheep);
    }
  }
  return premiseSheeps;
};

const GetPremiseInfo = async (premiseID) => {
  const endpoint = `/api/premises/${premiseID}`;
  try {
    const request = await axios.get(endpoint);
    const fetchedInfo = request.data;
    return fetchedInfo;
  } catch (err) {
    throw new Error(`An error occurred fetching premise info: ${err.message}`);
  }
};

const SheepPremiseView = () => {
  const [premise, setPremise] = useState({});
  const [sheeps, setSheeps] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [gender, setGender] = useState('');
  const [sheepId, setSheepId] = useState('');

  const params = useParams();
  const id = params.id;

  const fetchPremiseInfo = async () => {
    const premiseInfo = await GetPremiseInfo(id);
    setPremise(premiseInfo);
  };

  const fetchSheeps = async () => {
    const sheeps = await GetSheepsByPremise(id);
    setSheeps(sheeps);
  };

  useEffect(() => {
    fetchPremiseInfo();
    fetchSheeps();
  }, []);

  const filterSheeps = () => {
    let tempSheeps = [];
    for (const s of sheeps) {
      if (
        (s.tag.isoNumber && s.tag.isoNumber.startsWith(sheepId)) ||
        (s.tag.usScrapieId && s.tag.usScrapieId.startsWith(sheepId)) ||
        (s.tag.localMgmtNumber && s.tag.localMgmtNumber.startsWith(sheepId)) ||
        (s.tag.tattooNumber &&
          s.tag.tattooNumber.startsWith(
            sheepId,
          )) /*&&
        s.date >= startDate &&
        s.date <= endDate*/
      ) {
        if (gender != '' && s.gender != gender) {
          continue;
        }
        tempSheeps.push(s);
      }
    }
    return tempSheeps;
  };

  let filteredSheeps = filterSheeps();

  const FilterHeader = styled.p`
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 3px;
  `;

  return (
    <VerticalContainer align_items="flex-start" justify_content="flex-start">
      <h1 style={{ fontSize: 'xxx-large' }}>Sheep</h1>
      <Link to="/sheep/">
        <HorizontalContainer mobile="none">
          <Icon src={left} width="20px" m="0px" />
          <p style={{ verticalAlign: 'middle', margin: '0px' }}>Back</p>
        </HorizontalContainer>
      </Link>
      <h1 style={{ marginBottom: '0px' }}>View Details</h1>
      <h1 style={{ color: COLORS.primary }}>
        {premise.name}
        {premise.pid && ' - ' + premise.pid}
      </h1>
      <ContentContainer $stylePreset="light">
        <h2 style={{ marginTop: '0px' }}>Filters</h2>
        <HorizontalContainer
          align_items="flex-start"
          width="100%"
          tablet="flex-direction: column;"
        >
          <HorizontalContainer align_items="flex-start" width="100%">
            <FilterContainer mr="10px">
              <FilterHeader>Start date</FilterHeader>
              <DateInput
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </FilterContainer>
            <FilterContainer tablet="margin-right: 0px !important;">
              <FilterHeader>End date</FilterHeader>
              <DateInput
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </FilterContainer>
          </HorizontalContainer>
          <FilterContainer
            width="50%"
            tablet="width: calc(50% - 10px) !important;"
            mobile="width: 100% !important;"
          >
            <FilterHeader>Gender</FilterHeader>
            <SelectInput
              value={gender}
              onChange={(e) => setGender(e.target.value)}
            >
              <option value="" selected>
                Any
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </SelectInput>
          </FilterContainer>
          <FilterContainer mobile="width: 100% !important;">
            <FilterHeader>Search for an animal via identifier</FilterHeader>
            <SearchBar
              placeholder="Identifier search"
              containerStyle={{
                borderRadius: '15px',
                paddingBlock: '3.5px',
                width: '100%',
              }}
              value={sheepId}
              onChange={(e) => setSheepId(e.target.value)}
            />
          </FilterContainer>
          <FilterContainer style={{ width: 'min-content' }}>
            <FilterHeader>&nbsp;</FilterHeader>
            <Button
              style={{
                alignSelf: 'flex-start',
                height: '40px',
                margin: '0px',
                borderWidth: '1px',
              }}
              onClick={() => {
                setStartDate('');
                setEndDate('');
                setGender('');
                setSheepId('');
              }}
            >
              Clear
            </Button>
          </FilterContainer>
        </HorizontalContainer>
      </ContentContainer>
      <SheepTable sheeps={filteredSheeps} />
    </VerticalContainer>
  );
};

const SheepTable = ({ sheeps }) => {
  const [pageNumber, setPageNumber] = useState(1);

  const slicedSheeps = sheeps.slice((pageNumber - 1) * 30, pageNumber * 30);

  const StyledTh = styled.th`
    background: ${COLORS.secondary};
    height: 70px;
    width: auto;
    padding-block: auto;
    padding-inline: 30px;
    border-bottom: 10px solid white;
    font-weight: medium;
  `;

  const StyledBoldTh = styled.th`
    z-index: 10;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLORS.text};
    color: white;
    height: 70px;
    width: 130px;
    padding-block: auto;
    padding-inline: 30px;
    border-bottom: 10px solid white;
    font-weight: medium;
  `;

  const DummyTh = styled.th`
    min-width: 190px;
    width: 190px;
    padding: 0px;
  `;

  const StyledTd = styled.td`
    left: 190px;
    background: ${COLORS.light};
    height: 70px;
    width: auto;
    padding-block: auto;
    padding-inline: 30px;
    font-weight: bold;
    border-bottom: 1px solid #707070;
    &::first-letter {
      text-transform: capitalize;
    }
  `;

  const StyledBoldTd = styled.td`
    z-index: 10;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${COLORS.text};
    color: white;
    height: 70px;
    width: 130px;
    padding-inline: 30px;
    font-weight: bold;
    border-bottom: 1px solid #707070;
  `;

  const DummyTd = styled.td`
    min-width: 190px;
    width: 190px;
    padding: 0px;
  `;

  const TableWrapper = styled(VerticalContainer)`
    width: 100%;
    align-items: flex-start;

    overflow-x: auto;
    overflow-y: hidden;
    ::-webkit-scrollbar {
      width: 20px;
    }
    ::-webkit-scrollbar-track {
      border: 1px solid ${COLORS.primary};
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background: ${COLORS.text};
      border: 3px solid transparent;
      border-radius: 10px;
      background-clip: content-box;
    }
  `;

  return (
    <VerticalContainer
      width="100%"
      align_items="flex-start"
      style={{ position: 'relative' }}
    >
      <TableWrapper tablet="width: 90vw !important;">
        <table
          style={{
            borderSpacing: '0px',
            textAlign: 'center',
            width: '100%',
            marginTop: '40px',
          }}
        >
          <thead>
            <tr>
              <StyledBoldTh>CSIP Number</StyledBoldTh>
              <DummyTh />
              <StyledTh>Local Management Number</StyledTh>
              <StyledTh>US Scrapie ID</StyledTh>
              <StyledTh>Tattoo Number</StyledTh>
              <StyledTh>Gender</StyledTh>
              <StyledTh>Last Activity</StyledTh>
              <StyledTh />
            </tr>
          </thead>
          <tbody>
            {slicedSheeps.map((s) => {
              return (
                <tr key={s.id}>
                  <StyledBoldTd>{s.tag.isoNumber || '-'}</StyledBoldTd>
                  <DummyTd />
                  <StyledTd>{s.tag.localMgmtNumber || '-'}</StyledTd>
                  <StyledTd>{s.tag.usScrapieId || '-'}</StyledTd>
                  <StyledTd>{s.tag.tattooNumber || '-'}</StyledTd>
                  <StyledTd>{s.gender || '-'}</StyledTd>
                  <StyledTd>{s.lastActivity || 'N/A'}</StyledTd>
                  <StyledTd>
                    <ViewEditDispose
                      id={s._id}
                      viewLink={`/sheep/${s._id}`}
                      editLink={`/sheep/edit?id=${s._id}`}
                      disposeLink={`/sheep/dispose?pid=${s.premise.pid}&id=${s._id}`}
                      position="absolute"
                    />
                  </StyledTd>
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableWrapper>
      <small style={{ alignSelf: 'flex-start', marginTop: '10px' }}>
        Showing {Math.min(slicedSheeps.length, 30)} of {sheeps.length} results
      </small>
      <HorizontalContainer
        mobile="none"
        mt="-20px"
        style={{ alignSelf: 'center' }}
      >
        <Icon
          width="25px"
          src={left}
          hover="filter: opacity(0.5)"
          onClick={() => {
            if (pageNumber > 1) {
              setPageNumber(pageNumber - 1);
            }
          }}
        />
        {pageNumber - 1 >= 1 && (
          <h3
            style={{ paddingBlock: '5px', paddingInline: '10px' }}
            onClick={() => {
              setPageNumber(pageNumber - 1);
            }}
          >
            {pageNumber - 1}
          </h3>
        )}
        <h3
          style={{
            background: COLORS.secondary,
            borderRadius: '10px',
            paddingBlock: '5px',
            paddingInline: '10px',
          }}
        >
          {pageNumber}
        </h3>
        {pageNumber + 1 <= Math.ceil(sheeps.length / 30) && (
          <h3
            style={{ paddingBlock: '5px', paddingInline: '10px' }}
            onClick={() => {
              setPageNumber(pageNumber + 1);
            }}
          >
            {pageNumber + 1}
          </h3>
        )}
        <Icon
          width="25px"
          src={right}
          hover="filter: opacity(0.5)"
          onClick={() => {
            if (pageNumber < Math.ceil(sheeps.length / 30)) {
              setPageNumber(pageNumber + 1);
            }
          }}
        />
      </HorizontalContainer>
    </VerticalContainer>
  );
};

SheepTable.propTypes = {
  sheeps: propTypes.array,
};

const FilterContainer = styled(VerticalContainer)`
  width: ${(props) => props.width || '100%'};
  margin-right: ${(props) => props.mr || '50px'};
  align-items: flex-start;
  justify-content: flex-start;
`;

const DateInput = styled.input.attrs({ type: 'date' })`
  width: 100%;
  height: 40px;
  border: 1px solid ${COLORS.primary};
  border-radius: 15px;
  padding-inline: 10px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

const SelectInput = styled.select`
  width: 100%;
  height: 40px;
  background: ${COLORS.secondary};
  border: 1px solid ${COLORS.primary};
  border-radius: 15px;
  padding-inline: 10px;
`;

export default SheepPremiseView;
