import axios from 'axios';
import { getSelectedRole } from './RoleUtils';

export const createTagActivationReport = (data) => {
  return axios
    .post(`/api/tagactivation/${data.isoNumber}`, data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const createTagRetirementReport = (data) => {
  return axios
    .post('/api/tagretirement', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const getTagActivationReportById = (id) => {
  return axios
    .get(`/api/tagactivation/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const getAllTagActivationReports = () => {
  return axios
    .get(`/api/tagactivation/`, {
      params: {
        selectedRole: getSelectedRole(),
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const fetchTagActivationBySheepID = async (sheepID) => {
  try {
    const response = await axios.get(`/api/tagactivation/sheep/${sheepID}`);
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const tagActivations = response.data;

    return tagActivations;
  } catch (error) {
    console.error('Error fetching tag activation logs:', error);
    throw error;
  }
};

export const fetchTagReplacementBySheepID = async (sheepID) => {
  try {
    const response = await axios.get(`/api/tagreplacement/sheep/${sheepID}`);

    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const tagReplacements = response.data;

    return tagReplacements;
  } catch (error) {
    console.error('Error fetching tag replacement logs:', error);
    throw error;
  }
};

export const fetchTagRetirementBySheepID = async (sheepID) => {
  try {
    const response = await axios.get(`/api/tagretirement/sheep/${sheepID}`);
    if (response.status !== 200) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const tagRetirements = response.data;

    return tagRetirements;
  } catch (error) {
    console.error('Error fetching tag Retirement logs:', error);
    throw error;
  }
};
