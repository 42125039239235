import React, { useState } from 'react';
import FormCard from '../../components/FormCard.js';
import { parseAndSetData, convertAndParseXLS } from '../../utils/CSVUtils';
import { VerticalContainer, Button } from '../../components/CommonComponents';
import ImportSheep from './ImportSheep.js';
import Table from '../../components/Table.js';
import FileUpload from '../../components/FileUpload';
import FilePreview from '../../components/FilePreview';
import FileHints from '../../components/FileHints.js';
import styled from 'styled-components';
import { COLORS } from '../../utils/Constants.js';

const ImportCSVCreateView = () => {
  const [headings, setHeadings] = useState([]);
  const [data, setData] = useState([]);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [headers, setHeaders] = useState({});
  const [next, setNext] = useState(false);
  const [fileName, setFileName] = useState('');
  const fields = [{ name: 'ISO Number', id: 'isonum', required: true }];

  const fileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    setFileName('Upload CSV or Excel File: ' + uploadedFile.name);

    // Parse CSV or XLS file
    if (/.*\.(csv|CSV)$/.test(uploadedFile.name)) {
      parseAndSetData(uploadedFile, setHeadings, setData);
    } else if (/.*\.(xls|XLS).?$/.test(uploadedFile.name)) {
      convertAndParseXLS(uploadedFile, setHeadings, setData);
    }
  };

  const disableSelectHeaders = () =>
    !headers.isonum || headers.isonum === 'N/A';

  const hints = [
    'ISO numbers should follow this format: 124000 or 840000 followed by 9 digits',
    ' ',
  ];

  return (
    <>
      {next ? (
        <VerticalContainer>
          <BackButton onClick={() => setNext(false)}>Back</BackButton>
          <ImportSheep data={data} headers={headers} />
        </VerticalContainer>
      ) : (
        <FormCard title="Add Imported Sheep" back>
          <h2>Upload File</h2>
          <FileUpload fileName={fileName} onFileChange={fileUpload} />
          {headings.length === 0 ? (
            <>
              <Title>Example Table Format:</Title>
              <Table
                columns={['ISO Number']}
                rows={['124000310000000']}
                maxShowing={1}
                $stylePreset="boxyScrollable"
                paginate={false}
              />
              <FileHints hints={hints} />
            </>
          ) : (
            <FilePreview
              headings={headings}
              data={data}
              showAllColumns={showAllColumns}
              setShowAllColumns={setShowAllColumns}
              fields={fields}
              headers={headers}
              setHeaders={setHeaders}
              onSubmit={(e) => {
                e.preventDefault();
                setNext(true);
              }}
              disableSubmit={disableSelectHeaders()}
            />
          )}
        </FormCard>
      )}
    </>
  );
};

const BackButton = styled(Button)`
  margin: 20px auto;
  display: block;
`;

const Title = styled.h2`
  color: ${COLORS.text};
  font-size: 25px;
  text-align: left;
  margin: 20px 0;
`;

export default ImportCSVCreateView;
