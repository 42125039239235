import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import getFormFields from '../../Fields/AddOperationFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import { getUsername } from '../../utils/TokenUtils.js';

export default function AddOperation({ operationTypes }) {
  const [redirect, setRedirect] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(operationTypes);
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [operationTypes]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );
          setValidFieldsInCurrentStep(isValid);

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    const isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );

    setValidFieldsInCurrentStep(isValid);

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStepData.isValid || stepIndex < currentStep) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  /** Submit the Operation to the Database */
  const handleSubmit = async () => {
    try {
      // Flatten the formFields array
      const fieldValues = FormUtils.flattenForm(formFields);

      const body = {
        operation: { ...fieldValues },
      };

      // Set the user to whoever is creating the operation
      body.operation.user = getUsername();
      await axios.post('/api/operations', body).then(
        (res) => {
          setSubmitting(false);
          setRedirect(`/operations/${res.data._id}`);
          toast.info(
            'Please verify your operation email address. A verification link has been sent to your operation inbox. Check your operation email and click the link to complete verification.',
          );
        },
        (err) => {
          if (err.response.data.error) {
            Object.values(err.response.data.error).forEach((error) => {
              toast.error(error);
            });
          } else {
            toast.error(err.response ? err.response.data.message : err.message);
          }
          setSubmitting(false);
        },
      );
    } catch (err) {
      handleError(err);
    }
  };

  const handleError = (err) => {
    toast.dismiss();
    if (err.response && err.response.data) {
      toast.error(
        'There are errors in your submission. Please review and try again.',
      );
    } else {
      toast.error(err.message);
    }
    setSubmitting(false);
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create Operation"
    />
  );
}
