import React, { useState, useEffect } from 'react';
import { useParams, Redirect, useHistory } from 'react-router-dom';
import {
  VerticalContainer,
  OldBackLink,
} from '../../components/CommonComponents';
import { PageHeader } from '../../components/PageComponents.js';
import { PremiseDetailsCard } from '../../components/PremiseDetailsCard';
import { toast } from 'react-toastify';

const axios = require('axios');

export default function PremiseView() {
  const history = useHistory();
  const [premise, setPremise] = useState({
    pid: '',
    name: '',
    address: '',
    municipality: '',
    province: '',
    postalCode: '',
    description: '',
  });
  const [_, setNewPremise] = useState({
    pid: '',
    name: '',
    address: '',
    municipality: '',
    province: '',
    postalCode: '',
    description: '',
  });
  const [_2, setPidLog] = useState({
    pid: '',
    alternateId: '',
    address: '',
    municipality: '',
    province: '',
    postalCode: '',
    txId: '',
    date: '',
    operationName: '',
    operationType: '',
  });
  const [operation, setOperation] = useState('');

  const [redirect, setRedirect] = useState('');

  let params = useParams();

  useEffect(() => {
    getPremise();
  }, []);

  if (redirect !== '') {
    return <Redirect push to={redirect} />;
  }

  // Getting premise data for specific premise
  async function getPremise() {
    await axios
      .get(`/api/premises/${params.id}`)
      .then(function (response) {
        if (!response.data.pid) {
          response.data.pid = ' ';
        }
        setPremise(response.data);
        setNewPremise(response.data);
        setOperation(response.data.operation);

        var idParam = '';
        if (response.data.pid !== ' ') {
          idParam = response.data.pid;
        } else {
          idParam = response.data.name;
        }
        axios
          .get(`/api/pidLog/${idParam}`)
          .then(function (response) {
            if (response.data === null) {
              // this will only occur if the user changes the PID which should
              // not be allowed
              setPidLog({
                pid: 'No log value found',
                alternateId: 'No log value found',
                address: 'No log value found',
                municipality: 'No log value found',
                province: 'No log value found',
                postalCode: 'No log value found',
                txId: 'No log value found',
                date: 'No log value found',
                operationName: 'No log value found',
                operationType: 'No log value found',
              });
            } else {
              setPidLog(response.data);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch(function (error) {
        console.log(error);
        // setFieldErrors(error.response.data);
      });
  }

  function editPremise({ _id }) {
    setRedirect(`/premises/update/${_id}`);
  }

  async function deletePremise() {
    const totalPremises = premise.operation.premises.length;
    setRedirect(`/premises`);
    if (totalPremises > 1) {
      await axios
        .delete(`/api/premises/${premise._id}`)
        .then(function () {
          toast.success('Premise Successfully Deleted');
        })
        .catch(function (error) {
          console.log(error);
          toast.error('Error occurred when deleting premise');
        });
      setRedirect(true);
    } else {
      toast.error(
        'Premise Cannot Be Removed: Each Operation Must Have Atleast One Premise',
      );
    }
  }

  return (
    <VerticalContainer align_items="stretch" justify_content="flex-start">
      <div>
        <OldBackLink onClick={history.goBack}>&larr; Back</OldBackLink>
      </div>
      <PageHeader>Premise</PageHeader>
      <br />
      <br />
      <h3>Premise Details</h3>
      <PremiseDetailsCard
        data={premise}
        operation={operation}
        editPremise={editPremise}
        deletePremise={deletePremise}
      />
      <br />
      {redirect ? <Redirect to={`/premises`} /> : null}
    </VerticalContainer>
  );
}
