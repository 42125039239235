import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import FormCard from '../../components/FormCard.js';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import LoadingCard from '../../components/LoadingCard.js';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors.js';
import { useParams } from 'react-router';
import styled from 'styled-components';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
  FormTextInputSelection,
  Button,
} from '../../components/CommonComponents';
import SimpleCard from '../../components/SimpleCard';
import { createTransportLog } from '../../utils/TransportUtils';

const SubmittingSpinner = () => {
  return (
    <SubmittingDiv>
      <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
      &nbsp;Submitting...
    </SubmittingDiv>
  );
};

const getAllVehicles = () => {
  return axios.get('/api/vehicles').then((res) => {
    return res.data;
  });
};

const getAllOperation = () => {
  return axios.get('/api/operations/').then((res) => {
    return res.data;
  });
};

const TransportReportTransferView = () => {
  const [load, setLoad] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);

  //form information that is used in the move log
  const [form, setForm] = useState({
    departurePremiseID: '',
    destinationPremiseID: '',
    destinationType: 'Abattoir',
    vehicleID: '',
    departureDate: new Date(),
    loadTime: new Date(),
    location: null,
  });

  //state vars for retrieving and displaying dropdown options
  const [sheepInfo, setSheepInfo] = useState([]);
  const [reportInfo, setReportInfo] = useState();
  const [allVehicles, setAllVehicles] = useState([]);
  const [allOperations, setAllOperations] = useState([]);
  const [vehicleOption, setVehicleOption] = useState([]);
  const [operationOptions, setOperationOptions] = useState([]);
  const transportOperationRef = useRef(null);
  const [transportingOperation, _] = useState([]);
  const params = useParams();
  const id = params.id;
  const [transport, setTransport] = useState();

  const fetchReportInfo = async (id) => {
    try {
      const request = await axios.get(`/api/transport/${id}`);
      return request.data;
    } catch (err) {
      throw new Error(`An error occurred fetching report info: ${err.message}`);
    }
  };

  useEffect(() => {
    getAllVehicles().then((cars) => {
      //Filter out vehicles with null operations
      const availableCars = cars.filter((car) => car.operation != null);

      //filter for only transporter vehicles
      const options = availableCars.filter(
        (car) => car.operation.operationType == 'transporter',
      );
      setAllVehicles(options);
    });
  }, []);

  const fetchAndSetReportInfo = async () => {
    try {
      let fetchedReportInfo = await fetchReportInfo(id);

      fetchedReportInfo.createdAt = new Date(fetchedReportInfo.createdAt)
        .toISOString()
        .split('T')[0];
      fetchedReportInfo.departureDate = new Date(
        fetchedReportInfo.departureDate,
      )
        .toISOString()
        .split('T')[0];

      getAllOperation().then((operations) => {
        setAllOperations(operations);
      });

      setSheepInfo(fetchedReportInfo.animals);
      setReportInfo(fetchedReportInfo);
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchAndSetReportInfo();
    setLoad(false);
  }, []);

  //check that required form values are present
  const createBtnEnabled = () => {
    return !submitting;
  };

  const handleSubmit = () => {
    if (form.transporterOperationID === reportInfo.transporter) {
      toast.error('Cannot transfer a transport report to yourself');
      return;
    }

    var allsheep = [];
    for (let i = 0; i < sheepInfo.length; i++) {
      allsheep[i] = sheepInfo[i]._id;
    }

    if (transport) {
      setSubmitting(true);
      toast.info(
        'Submitting move-out report to the server. This may take up to a minute.',
      );
      createTransportLog({
        departurePID: reportInfo.departurePID,
        destinationPID: reportInfo.destinationPID,
        animals: allsheep,
        vehicleNumber: reportInfo.vehicleNumber,
        departureDate: reportInfo.departureDate,
        departureTime: reportInfo.departureTime,
        transporter: form.transporterOperation,
        forTransporter: true,
      })
        .then(() => {
          setSubmitting(false);
          toast.success('Success: Report created');
          setRedirect(true);
        })
        .catch((err) => {
          setSubmitting(false);
          toast.dismiss();
          toast.error(err);
        });
    } else {
      setSubmitting(true);
      toast.info(
        'Submitting move-out report to the server. This may take up to a minute.',
      );
      createTransportLog({
        departurePID: reportInfo.departurePID,
        destinationPID: reportInfo.destinationPID,
        animals: allsheep,
        vehicleNumber: reportInfo.vehicleNumber,
        departureDate: reportInfo.departureDate,
        departureTime: reportInfo.departureTime,
        transporter: transportingOperation,
        forTransporter: false,
        targetUser: form.transporterOperationID,
      })
        .then(() => {
          setSubmitting(false);
          toast.success('Success: Report created');
          setRedirect(true);
        })
        .catch((err) => {
          setSubmitting(false);
          toast.dismiss();
          toast.error(err);
        });
    }
  };

  if (redirect) return <Redirect to="/reports/moveout" />;
  return (
    <ReportContainer>
      {!load ? (
        <FormCard back>
          <SimpleCard title="Send a Transport Report to Another User" />

          <RadioButtonContainer>
            Query Type: <>&nbsp;</>
            <br></br>
            <input
              type="radio"
              name="destType"
              checked={form.queryType === 'Vehicle'}
              onChange={() =>
                setForm({
                  ...form,
                  queryType: 'Vehicle',
                  transporterOperation: null,
                })
              }
              style={{ cursor: 'pointer' }}
            />
            Vehicle <br></br>
            <input
              type="radio"
              name="destType"
              checked={form.queryType === 'Operation'}
              onChange={() =>
                setForm({
                  ...form,
                  queryType: 'Operation',
                  transporterOperation: null,
                })
              }
              style={{ cursor: 'pointer' }}
            />
            Operation Name <br></br>
          </RadioButtonContainer>

          {form.queryType != 'Operation' ? (
            <EmptyDiv>
              {allVehicles.length >= 1 ? (
                <FormInputWrapper>
                  <FormInputLabel>Vehicle Number Search</FormInputLabel>

                  <FormTextInput
                    ref={transportOperationRef}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setVehicleOption([]);
                      } else {
                        const options = allVehicles.filter(
                          (cars) =>
                            cars.licensePlateNum.includes(e.target.value) ||
                            cars.operation.businessName.includes(
                              e.target.value,
                            ),
                        );

                        setVehicleOption(options);

                        if (
                          options.length === 1 &&
                          options[0].pid === transportOperationRef.current.value
                        ) {
                          setForm({
                            ...form,
                            transporterOperation: options[0].pid,
                          });
                        }
                      }
                    }}
                  />

                  <div
                    style={{
                      maxHeight: '150px',
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                    }}
                  >
                    {vehicleOption.map((cars, idx) => (
                      <FormTextInputSelection
                        key={idx}
                        onClick={() => {
                          setVehicleOption([]);
                          setForm({
                            ...form,
                            transporterOperation: cars.operation.businessName,
                            transporterOperationID: cars.operation._id,
                          });
                          transportOperationRef.current.value =
                            cars.operation.businessName;
                        }}
                      >
                        <h4 style={{ margin: 0 }}>{cars.licensePlateNum}</h4>
                        <div>{cars.operation.businessName}</div>
                      </FormTextInputSelection>
                    ))}
                  </div>
                </FormInputWrapper>
              ) : (
                <></>
              )}
            </EmptyDiv>
          ) : (
            <EmptyDiv>
              {allOperations.length >= 1 ? (
                <FormInputWrapper>
                  <FormInputLabel>Operation Search</FormInputLabel>

                  <FormTextInput
                    ref={transportOperationRef}
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setOperationOptions([]);
                      } else {
                        const options = allOperations.filter((operations) =>
                          operations.businessName.includes(e.target.value),
                        );

                        setOperationOptions(options);

                        if (
                          options.length === 1 &&
                          options[0].pid === transportOperationRef.current.value
                        ) {
                          setForm({
                            ...form,
                            transporterOperation: options[0].pid,
                          });
                        }
                      }
                    }}
                  />

                  <div
                    style={{
                      maxHeight: '150px',
                      overflowY: 'scroll',
                      overflowX: 'hidden',
                    }}
                  >
                    {operationOptions.map((operation, idx) => (
                      <FormTextInputSelection
                        key={idx}
                        onClick={() => {
                          setOperationOptions([]);
                          setForm({
                            ...form,
                            transporterOperation: operation.businessName,
                            transporterOperationID: operation._id,
                          });
                          transportOperationRef.current.value =
                            operation.businessName;
                        }}
                      >
                        <h4 style={{ margin: 0 }}>{operation.businessName}</h4>
                      </FormTextInputSelection>
                    ))}
                  </div>
                </FormInputWrapper>
              ) : (
                <></>
              )}
            </EmptyDiv>
          )}

          <RadioButtonContainer>
            Would you like to send this report to a move in premise or
            transporter? <br></br>
            <input
              type="radio"
              name="transport"
              checked={transport}
              onChange={() => setTransport(true)}
              style={{ cursor: 'pointer' }}
            />
            Transporter <br></br>
            <input
              type="radio"
              name="transport"
              checked={!transport}
              onChange={() => setTransport(false)}
              style={{ cursor: 'pointer' }}
            />
            Move In <br></br>
          </RadioButtonContainer>

          <Button
            type="button"
            disabled={!createBtnEnabled()}
            onClick={handleSubmit}
          >
            Create
          </Button>

          {submitting ? <SubmittingSpinner /> : <></>}
        </FormCard>
      ) : (
        <PageCard
          title="Processing your request ..."
          loader={
            <Loader type="Oval" color={agGreen} height={50} width={150} />
          }
        >
          <LoadingCard />
        </PageCard>
      )}
    </ReportContainer>
  );
};

const ReportContainer = styled.div`
  width: fit-content;
  max-width: 700px;
  color: white;
  position: relative;
  margin: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const EmptyDiv = styled.div``;

const SubmittingDiv = styled.div`
  text-align: center;
`;

const RadioButtonContainer = styled.div`
  margin-bottom: 20px;
  margin-left: 25px;
`;

export default TransportReportTransferView;
