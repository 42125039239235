import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SheepReportCard from './SheepReportCard';
import styled from 'styled-components';
import Line from './Line';
import { FetchSheepReportsById } from '../SheepReportComponents';
import { getExportReportsForSheep } from '../../utils/ExportUtils';
import { getMoveLogsForSheep } from '../../utils/MoveUtils';
import {
  fetchTagActivationBySheepID,
  fetchTagReplacementBySheepID,
  fetchTagRetirementBySheepID,
} from '../../utils/TagUtils';
import { getUserById } from '../../utils/UserUtils';
import { getTransportReportsForSheep } from '../../utils/TransportUtils';
import * as formatters from '../../utils/SheepReportsFormatters';
import { REPORT_TYPES } from '../../utils/Constants';
import { toast } from 'react-toastify';
import { getImportReportsById } from '../../utils/ImportUtils';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TimelineWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TimelineStep = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const TraceTimeline = ({ sheepID, setUsers }) => {
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state
  const [sortedReports, setSortedReports] = useState([]); // State to hold sorted reports

  const updateReports = (newReports) => {
    setReports((prevReports) => {
      const updatedReports = [...prevReports];
      newReports.forEach((report) => {
        const reportKey = report.id;
        const exists = updatedReports.some((r) => r.id === reportKey);
        if (!exists) {
          updatedReports.push(report);
        }
      });
      return updatedReports;
    });
  };

  const fetchSubmitters = async () => {
    try {
      // Extract unique submitters from the fetched reports
      const uniqueSubmittersIDs = Array.from(
        new Set(
          reports
            .map((report) => report.submitter)
            .filter(
              (submitter) => submitter !== undefined && submitter !== null,
            ),
        ),
      );

      // Prepare an array to store user data
      const fetchedUsers = [];

      // Loop through unique submitter IDs and fetch user data for each
      for (const submitterID of uniqueSubmittersIDs) {
        const user = await getUserById(submitterID); // fetch user data
        if (user) {
          // Determine isSelected based on conditions
          const isSelected = uniqueSubmittersIDs.length === 1 ? true : false;

          // Construct user object with necessary fields
          const userData = {
            id: user._id,
            src: user.profileImage || '',
            username: user.username || '',
            firstName: user.firstName,
            lastName: user.lastName,
            isSelected: isSelected,
          };
          fetchedUsers.push(userData);
        }
      }

      // Update users state with fetched users
      setUsers(fetchedUsers);
    } catch (err) {
      console.error('Error fetching submitters:', err);
      toast.error(err.message);
    }
  };

  const getImportReports = async () => {
    try {
      let response = await getImportReportsById(sheepID);

      let fetchedReports = response.data;
      const formattedReports = formatters.formatImportReports(fetchedReports);

      updateReports(formattedReports);
    } catch (err) {
      toast.error(err);
    }
  };

  /**
   * Retrieves move logs, filters them into move-out and move-in reports,
   * formats them, and updates the state with the formatted reports.
   */
  const getMoveReports = async () => {
    try {
      let moveLogs = await getMoveLogsForSheep(sheepID);
      const moveOutReports = moveLogs.filter((log) => log.moveOut);
      const moveInReports = moveLogs.filter((log) => !log.moveOut);

      const formattedMoveOutReports =
        formatters.formatMoveOutReports(moveOutReports);
      const formattedMoveInReports =
        formatters.formatMoveInReports(moveInReports);

      const combinedMoveReports = [
        ...formattedMoveOutReports,
        ...formattedMoveInReports,
      ];

      updateReports(combinedMoveReports);

      setLoading(false); // Set loading to false once all reports are fetched
    } catch (err) {
      toast.error(`Error fetching reports: ${err.message}`);
    }
  };

  /** Refactor to not retrieve ALL reports */
  const getSheepAddReports = async () => {
    try {
      const fetchedReports = await FetchSheepReportsById(sheepID);
      const filteredReports = fetchedReports.filter(
        (report) => report.sheep === sheepID,
      );
      const formattedReports =
        formatters.formatSheepAddReports(filteredReports);
      updateReports(formattedReports);
    } catch (err) {
      toast.error(err);
    }
  };

  const getExportReports = async () => {
    try {
      let fetchedReports = await getExportReportsForSheep(sheepID);

      const formattedReports = formatters.formatExportReports(fetchedReports);

      updateReports(formattedReports);
    } catch (err) {
      toast.error(err);
    }
  };

  const getTransportReports = async () => {
    try {
      let fetchedReports = await getTransportReportsForSheep(sheepID);

      const formattedReports =
        formatters.formatTransportReports(fetchedReports);

      updateReports(formattedReports);
    } catch (err) {
      toast.error(err);
    }
  };

  const getTagActivationReports = async () => {
    try {
      const fetchedReports = await fetchTagActivationBySheepID(sheepID);
      const formattedReports =
        formatters.formatTagActivationReports(fetchedReports);

      updateReports(formattedReports);
    } catch (err) {
      toast.error(err);
    }
  };

  const getTagReplacementReports = async () => {
    try {
      const fetchedReports = await fetchTagReplacementBySheepID(sheepID);
      const formattedReports =
        formatters.formatTagReplacementReports(fetchedReports);

      updateReports(formattedReports);
    } catch (err) {
      toast.error(err);
    }
  };

  const getTagRetirementReports = async () => {
    try {
      const fetchedReports = await fetchTagRetirementBySheepID(sheepID);
      const formattedReports =
        formatters.formatTagRetirementReports(fetchedReports);

      updateReports(formattedReports);
    } catch (err) {
      toast.error(err);
    }
  };

  useEffect(() => {
    const fetchReportsData = async () => {
      try {
        setReports([]); // Clear previous reports
        setLoading(true); // Set loading to true before fetching
        await getSheepAddReports();
        await getImportReports();
        await getMoveReports();
        await getExportReports();
        await getTransportReports();
        await getTagActivationReports();
        await getTagReplacementReports();
        await getTagRetirementReports();
      } catch (err) {
        toast.error(`Error fetching reports: ${err.message}`);
      } finally {
        setLoading(false); // Ensure loading state is set to false in case of error
      }
    };

    fetchReportsData();
  }, []);

  const getReportDate = (report) => {
    switch (report.type) {
      case REPORT_TYPES.IMPORT:
      case REPORT_TYPES.MOVE_IN:
        return report.destination_datetime.value;
      case REPORT_TYPES.ADD:
        return report.add_datetime.value;
      case REPORT_TYPES.EXPORT:
      case REPORT_TYPES.TRANSPORT:
      case REPORT_TYPES.MOVE_OUT:
        return report.departure_datetime.value;
      case REPORT_TYPES.TAG_ACTIVATION:
        return report.activation_datetime.value;
      case REPORT_TYPES.TAG_REPLACEMENT:
        return report.replacement_date.value;
      case REPORT_TYPES.TAG_RETIREMENT:
        return report.disposal_datetime.value;
      default:
        return null;
    }
  };
  useEffect(() => {
    // Sort reports after loading is complete
    if (!loading && reports.length > 0) {
      const sorted = [...reports].sort(
        (a, b) => new Date(getReportDate(b)) - new Date(getReportDate(a)),
      );
      setSortedReports(sorted);
    }
  }, [loading, reports]);

  useEffect(() => {
    // Fetch submitters when sorted reports are updated
    if (sortedReports.length > 0) {
      fetchSubmitters();
    }
  }, [sortedReports]);

  const handleVisible = (submitterID) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === submitterID
          ? { ...user, isSelected: true }
          : { ...user, isSelected: false },
      ),
    );
  };

  return (
    <Container>
      <TimelineWrapper>
        {loading && <p>Loading...</p>}
        {!loading && sortedReports && sortedReports.length > 0 && (
          <Line endDateTime={getReportDate(sortedReports[0])} />
        )}
        {!loading &&
          (sortedReports && sortedReports.length > 0 ? (
            sortedReports.map((report, index) => (
              <React.Fragment key={index}>
                {index > 0 && <Line endDateTime={getReportDate(report)} />}
                <TimelineStep>
                  <SheepReportCard
                    key={index}
                    report={report}
                    onVisible={handleVisible}
                    sheepID={sheepID}
                  />
                </TimelineStep>
              </React.Fragment>
            ))
          ) : (
            <p>No trace reports found.</p>
          ))}
        {!loading && !sortedReports && <p>No trace reports found.</p>}
      </TimelineWrapper>
    </Container>
  );
};

TraceTimeline.propTypes = {
  sheepID: PropTypes.string.isRequired,
  setUsers: PropTypes.func.isRequired,
};

export default TraceTimeline;
