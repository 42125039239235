import styled from 'styled-components';
import React from 'react';
import { COLORS } from '../../utils/Constants';

const NavigationPathContainer = styled.nav``;

const NavigationPathList = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
`;

const NavigationPathItem = styled.li`
  font-size: 18px;
  margin-right: 5px;
`;

const NavigationPathLink = styled.a`
  text-decoration: none;
  color: ${COLORS.primary};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const NavigationPathSeparator = styled.span`
  margin: 0 5px;
  color: ${COLORS.dark};
`;

export const NavigationPath = ({ paths }) => {
  return (
    <NavigationPathContainer aria-label="navigationpath">
      <NavigationPathList>
        {paths.map((path, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <NavigationPathSeparator>{'>'}</NavigationPathSeparator>
            )}
            <NavigationPathItem>
              {index === paths.length - 1 ? (
                <span>{path.title}</span>
              ) : (
                <NavigationPathLink href={path.url}>
                  {path.title}
                </NavigationPathLink>
              )}
            </NavigationPathItem>
          </React.Fragment>
        ))}
      </NavigationPathList>
    </NavigationPathContainer>
  );
};
