import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { agGreen, deleteColor, viewColor } from './Colors';
import { Button } from './CommonComponents';
import PropTypes from 'prop-types';
import React from 'react';

const { default: PageCard } = require('./PageCard');

const DescriptionSection = ({ description }) => {
  return (
    <>
      <DescriptionDiv>{description}</DescriptionDiv>
    </>
  );
};

DescriptionSection.propTypes = {
  description: PropTypes.string.isRequired,
};

const getColor = (color) => {
  if (color == 'primary') return agGreen;
  if (color == 'secondary') return viewColor;
  if (color == 'danger') return deleteColor;
  return agGreen;
};

const DecisionSection = ({ choices }) => {
  return (
    <>
      {/* Centered buttons corresponding to the decisions */}
      <DecisionDiv>
        {choices.map((c) =>
          c.link ? (
            <>
              <Link to={c.link}>
                <Button style={{ backgroundColor: getColor(c.color) }}>
                  {c.name}
                  {c.description ? <> - {c.description}</> : <></>}
                </Button>
              </Link>
            </>
          ) : (
            <>
              <Button
                style={{ backgroundColor: getColor(c.color) }}
                onClick={() => {
                  if (c.update) c.update();
                }}
              >
                {c.name}
                {c.description ? <> - {c.description}</> : <></>}
              </Button>
            </>
          ),
        )}
      </DecisionDiv>
    </>
  );
};

DecisionSection.propTypes = {
  choices: PropTypes.shape({
    map: PropTypes.func,
  }),
};

const DecisionCard = ({ decision }) => {
  return (
    <>
      <PageCard
        title={decision.title}
        line
        maxContent
        back
        onBack={decision.onBack}
      >
        {decision.images && decision.images.length > 0 ? (
          <>{/* Display Images */}</>
        ) : (
          <></>
        )}
        {decision.description ? (
          <>
            <DescriptionSection description={decision.description} />
          </>
        ) : (
          <></>
        )}
        {decision.choices && decision.choices.length > 0 ? (
          <>
            {/* Have the buttons at the bottom of the screen */}
            <DecisionSection choices={decision.choices} />
          </>
        ) : (
          <></>
        )}
        {decision.backToHelp && (
          <BackToHelpDiv>
            <Link to="/help">Back to help</Link>
          </BackToHelpDiv>
        )}
      </PageCard>
    </>
  );
};

DecisionCard.propTypes = {
  decision: PropTypes.shape({
    backToHelp: PropTypes.any,
    choices: PropTypes.shape({
      length: PropTypes.number,
    }),
    description: PropTypes.any,
    images: PropTypes.shape({
      length: PropTypes.number,
    }),
    onBack: PropTypes.any,
    title: PropTypes.any,
  }),
};

const DescriptionDiv = styled.div`
  margin: 1em 0.5em;
  font-size: 18px;
`;

const DecisionDiv = styled.div`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
`;

const BackToHelpDiv = styled.div`
  text-align: center;
  margin-top: 20px;

  a {
    color: black;
    font-size: 20px;
  }
`;

export default DecisionCard;
