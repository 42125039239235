import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import SearchableDropdown from '../../components/SearchableDropdown';
import styled from 'styled-components';
import { extractDataFromHeaders } from '../../utils/CSVUtils';
import { Redirect } from 'react-router';
import { Link } from '@material-ui/core';
import propTypes from 'prop-types';
import {
  FetchPremises,
  FetchVehicles,
  VehicleFormatter,
  PremisesFormatter,
} from '../../components/SheepReportComponents';
import { createVehicle, regionOptions } from '../../utils/VehicleUtils.js';
import Form from '../../components/Form.js';
import { getUsername } from '../../utils/TokenUtils.js';
import { getUserActiveOperations } from '../../utils/OperationUtils';

import Table from '../../components/Table.js';
import { Button } from '../../components/CommonComponents.js';
import { createImportReport } from '../../utils/ImportUtils.js';
import { countries } from '../../utils/Countries';
import { createDate } from '../../utils/TimeUtils.js';
import { getPremisesById } from '../../utils/PremiseUtils.js';
import { getOperationsForUser } from '../../utils/OperationUtils.js';

const CheckBox = ({ onChange, checked }) => {
  return (
    <input
      style={{ cursor: 'pointer' }}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
  );
};

CheckBox.propTypes = {
  onChange: propTypes.func,
  checked: propTypes.bool,
};

const fetchPremises = async () => {
  const fetchedPremises = await FetchPremises();
  return fetchedPremises;
};

const fetchVehicles = async () => {
  const fetchedVehicles = await FetchVehicles();
  const formattedVehicles = VehicleFormatter(fetchedVehicles);
  return formattedVehicles;
};

const CenteredButtonDiv = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const ImportSheep = ({ data, headers }) => {
  const [sheeps, setSheeps] = useState([]);
  const [selectedSheeps, setSelectedSheeps] = useState([]);
  const [premises, setPremises] = useState([]);
  const [formattedPremises, setFormattedPremises] = useState([]);
  const [operations, setOperations] = useState([]);
  const [selectedOperation, setSelectedOperation] = useState();
  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState();
  const [vehicleOption, setVehicleOption] = useState('');
  const [formValues, setFormValues] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState('');
  const [abattoirPids, setAbattoirPids] = useState([]);

  // Setting column constants for select sheep table
  const columns = ['', 'CSIP Number', ''];
  const columnIds = ['isonum', ''];

  useEffect(() => {
    setSheeps(extractDataFromHeaders(data, headers));
    return fetchPremises()
      .then((fetchedPremises) => {
        setPremises(fetchedPremises);
        setFormattedPremises(PremisesFormatter(fetchedPremises));
        fetchAndSetAbattoirs(getUsername());
        return fetchVehicles().then((fetchedVehicles) => {
          setVehicles(fetchedVehicles);
        });
      })
      .catch((err) => {
        toast.error(`An error occurred while fetching data: ${err}`);
      });
  }, [data, headers]);

  useEffect(() => {
    sessionStorage.clear();
    if (operations.length === 0) {
      fetchOperations();
    }
  }, []);

  const fetchAndSetAbattoirs = async (username) => {
    const userOperations = await getUserActiveOperations(username);
    let abattoirIDs = [];
    userOperations.map((op) => {
      if (op.operationType === 'abattoir') {
        abattoirIDs = abattoirIDs.concat(op.premises);
      }
    });
    const promises = abattoirIDs.map((id) =>
      typeof id === 'string' ? getPremisesById(id) : getPremisesById(id._id),
    );
    let abattoirPids = await Promise.all(promises);
    abattoirPids = abattoirPids.map((ab) => ab.pid);
    setAbattoirPids(abattoirPids);
  };

  const fetchOperations = async () => {
    const result = await getUserActiveOperations(getUsername());
    const reducedOperations = result.data.map((o) => ({
      name: o.businessName,
      value: o._id,
    }));
    setOperations(reducedOperations);
  };

  const operationPrompt = () => {
    if (operations.length === 0) {
      return (
        <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
          {' '}
          You have not added an operation yet.&nbsp;{' '}
          <Link to="/operations" style={{ color: 'black' }}>
            {' '}
            Add one here{' '}
          </Link>
        </p>
      );
    }

    return <></>;
  };

  const sheepRowsWithCheck = sheeps.map((s) => ({
    check: (
      <CheckBox
        checked={
          selectedSheeps.filter(
            (selectedSheep) => selectedSheep.dummyID === s.dummyID,
          ).length > 0
        }
        onChange={() => {
          const alreadySelected =
            selectedSheeps.filter(
              (selectedSheep) => selectedSheep.dummyID == s.dummyID,
            ).length > 0;
          if (alreadySelected) {
            const newSelectedSheeps = selectedSheeps.filter(
              (sheep) => sheep.dummyID !== s.dummyID,
            );
            setSelectedSheeps(newSelectedSheeps);
          } else {
            const newSelectedSheeps = [
              sheeps.filter((sheep) => sheep.dummyID === s.dummyID)[0],
              ...selectedSheeps,
            ];
            setSelectedSheeps(newSelectedSheeps);
          }
        }}
      />
    ),
  }));

  for (let i = 0; i < sheeps.length; i++) {
    for (const columnId of columnIds) {
      sheepRowsWithCheck[i][columnId] =
        columnId !== ''
          ? sheeps[i][columnId]
            ? sheeps[i][columnId]
            : '-'
          : '';
    }
  }

  const SelectAllSection = () => (
    <CenteredButtonDiv>
      <Button
        style={{ marginRight: '10px' }}
        onClick={(e) => {
          e.preventDefault();
          setSelectedSheeps(sheeps);
        }}
      >
        Select All
      </Button>
      {selectedSheeps.length > 0 ? (
        <Button
          onClick={(e) => {
            e.preventDefault();
            setSelectedSheeps([]);
          }}
        >
          Clear All
        </Button>
      ) : (
        <></>
      )}
    </CenteredButtonDiv>
  );

  const handleSubmit = (formValues) => {
    try {
      setSubmitting(true);
      setFormValues(formValues);
      if (!selectedSheeps.length > 0) {
        toast.error('At least one sheep is required.');
        return;
      }
      toast.info(
        'Submitting import report to the server. This may take up to a minute.',
      );

      const logInfo = {
        exportingCountry: formValues.exportingCountry,
        departureSite: formValues.departureSite,
        abattoirPID: formValues.abattoirPID.toUpperCase(),
        firstDestinationPID: formValues.firstDestinationPID,
        dateOfArrival: formValues.arrivalDate,
        animalIdentifications: selectedSheeps.map((sheep) => {
          return sheep.isonum;
        }),
        vehicleIdentification:
          vehicleOption === 'new'
            ? formValues.licensePlateNum
            : formValues.vehicleNum,
        location: formValues.location,
      };

      let vehicleInfo = {};

      if (vehicleOption === 'new') {
        vehicleInfo.licensePlateNum = formValues.licensePlateNum;

        vehicleInfo.provinces = [''];
        vehicleInfo.states = [''];

        vehicleInfo.name = formValues.vehicleName;

        if (formValues.country == 'Canada') {
          vehicleInfo.provinces = [formValues.locations];
        }
        if (formValues.country == 'United States') {
          vehicleInfo.states = [formValues.locations];
        }

        vehicleInfo.operation = selectedOperation?.id;
      }

      createImportReport(logInfo)
        .then(() => {
          if (vehicleOption === 'new') {
            createVehicle(vehicleInfo)
              .then(toast.success('Success: Vehicle created'))
              .catch((err) => {
                setSubmitting(false);
                toast.dismiss();
                toast.error('Error creating vehicle: ' + err);
              });
          }

          setSubmitting(false);
          toast.success('Success: Report created');
          setRedirect('/reports/import');
        })
        .catch((err) => {
          setSubmitting(false);
          toast.dismiss();
          toast.error('Error creating import report: ' + err);
        });
    } catch (error) {
      setSubmitting(false);
      toast.dismiss();
      toast.error('Error creating import report: ' + err);
    }
  };

  const formFields = [
    {
      name: 'First Destination PID',
      id: 'firstDestinationPID',
      type: 'select',
      options: formattedPremises,
      required: true,
    },
    {
      name: 'Exporting Country',
      id: 'exportingCountry',
      type: 'select',
      description: 'Select the Country the Animals are exported from',
      options: countries.map((country) => ({ value: country, name: country })),
      required: true,
    },
    {
      name: 'Departure Site',
      id: 'departureSite',
      description: 'Enter the ID of the Departure Site',
      type: 'text',
      required: true,
    },
    {
      id: 'sheepSelect',
      name: 'Select Sheep to include in Report',
      description:
        'Sheep will be automatically created for CSIP numbers that are not already in our system.',
      type: 'content',
      content: (
        <Table columns={columns} rows={sheepRowsWithCheck} paginate={true} />
      ),
    },
    {
      type: 'content',
      content: <SelectAllSection />,
    },
    {
      name: 'Abattoir PID',
      id: 'abattoirPID',
      type: 'select',
      options: abattoirPids.map((ab) => ({ value: ab, name: ab })),
    },
    {
      name: 'Date of Arrival',
      id: 'arrivalDate',
      type: 'date',
      required: true,
      value: formValues?.arrivalDate || '',
      validityCheck: (fieldValues) => {
        if (
          new Date(createDate(fieldValues.arrivalDate)).getTime() > Date.now()
        ) {
          return 'Date of arrival cannot be in the future';
        }
        return true;
      },
    },
    {
      name: 'Vehicle Option',
      id: 'vehicleOption',
      type: 'select',
      options: [
        { name: 'Use Existing Vehicle', value: 'exist' },
        { name: 'Create a New Vehicle', value: 'new' },
      ],
      onChange: (fieldValues) => {
        setVehicleOption(fieldValues.vehicleOption);
      },
      required: true,
    },
    vehicleOption == 'exist' && {
      name: 'Vehicle Identifier',
      id: 'vehicleNum',
      description: 'Select the identifier',
      type: 'select',
      options: vehicles,
      onChange: (fieldValues) => {
        setSelectedVehicle(fieldValues.vehicle);
      },
      required: true,
    },
    vehicleOption == 'new' && {
      name: 'Vehicle Name',
      id: 'vehicleName',
    },
    vehicleOption == 'new' && {
      name: 'Vehicle Identifier',
      id: 'licensePlateNum',
      description: 'License Plate, VIN Number, or Description',
      required: true,
      validityCheck: (fieldValues) => {
        if (!/^[A-Za-z0-9 ]+$/.exec(fieldValues.licensePlateNum)) {
          return 'Vehicle Identification must only contain alphanumeric characters';
        } else {
          return true;
        }
      },
    },
    vehicleOption == 'new' && {
      name: 'Country of Registration',
      id: 'country',
      required: true,
      type: 'select',
      options: Object.keys(regionOptions).map((loc) => ({
        name: loc,
        value: loc,
      })),
    },
    vehicleOption == 'new' && {
      name: 'Region',
      id: 'locations',
      required: true,
      type: 'select',
      optionsFilter: (fields) =>
        fields.country !== '' ? regionOptions[fields.country] : [],
    },
    vehicleOption == 'new' && {
      name: 'Operation',
      type: 'content',
      content: (
        <SearchableDropdown
          id="operation-search"
          type="operation"
          optionKey="businessName"
          options={operations.map((o) => ({
            businessName: o.name,
            id: o.value,
          }))}
          placeholder="Search by Operation Name..."
          onChange={(businessName) => setSelectedOperation(businessName)}
          onSelect={(option) => setSelectedOperation(option)}
        />
      ),
      description: 'Select an operation that this vehicle belongs to.',
    },
    vehicleOption == 'new' && {
      type: 'content',
      content: operationPrompt(),
    },
    {
      type: 'content',
      content: (
        <>
          {premises.length == 0 && (
            <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
              You have not added a premise yet.&nbsp;
              <Link to="/premises" style={{ color: 'black' }}>
                {' '}
                Add one here{' '}
              </Link>
            </p>
          )}
        </>
      ),
    },
    {
      name: 'Location (Latitude, Longitude)',
      hint: 'Latitude, Longitude',
      id: 'location',
      type: 'location',
    },
  ].filter(Boolean);

  if (redirect !== '') return <Redirect to={redirect} />;
  return (
    <Form
      style={{ width: '900px', maxWidth: '900px' }}
      title="Bulk Sheep Import"
      onSubmit={handleSubmit}
      fieldInfo={formFields}
      disabled={selectedSheeps.length <= 0}
    />
  );
};

ImportSheep.propTypes = {
  data: propTypes.arrayOf(propTypes.string),
  headers: propTypes.arrayOf(propTypes.string),
};

export default ImportSheep;
