// VehicleCreate.js
import React, { useState, useEffect } from 'react';
import { getUsername } from '../../utils/TokenUtils.js';
import { getUserActiveOperations } from '../../utils/OperationUtils';

import AddVehicle from '../../components/Forms/AddVehicle.js';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents.js';

const VehicleCreateView = () => {
  const [operations, setOperations] = useState([]);

  const paths = [
    { url: '/vehicles', title: 'Dashboard' },
    { url: '/vehicles/create', title: 'Add a Vehicle' },
  ];

  useEffect(() => {
    if (operations.length === 0) {
      fetchOperations();
    }
  }, []);

  const fetchOperations = async () => {
    try {
      const result = await getUserActiveOperations(getUsername());
      setOperations(result);
    } catch (error) {
      console.error('Error fetching operations:', error);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddVehicle errors={{}} operations={operations} />
        </ContentContainer>
      </div>
    </>
  );
};

export default VehicleCreateView;
