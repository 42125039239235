import React from 'react';
import SearchableDropdown from '../components/SearchableDropdown';
import { formatDateField } from '../utils/TimeUtils';

const getFormFields = (
  formattedPremises,
  countries,
  abattoirPids,
  vehicles,
  selectedVehicle,
  setSelectedVehicle,
) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Complete this form to file an import report:`,
    },
    {
      name: 'First Destination PID',
      id: 'firstDestinationPID',
      type: 'select',
      options: formattedPremises,
      required: true,
    },
    {
      name: 'Exporting Country',
      id: 'exportingCountry',
      type: 'select',
      description: 'Select the Country the Animals are exported from',
      options: countries.map((country) => ({ value: country, name: country })),
      required: true,
    },
    {
      name: 'Departure Site',
      id: 'departureSite',
      description: 'Enter the ID of the Departure Site',
      type: 'text',
      required: true,
    },
    {
      name: 'Abbatoir PID',
      id: 'abattoirPID',
      type: 'select',
      options: abattoirPids.map((ab) => ({ value: ab, name: ab })),
    },
    {
      name: 'Animal IDs',
      id: 'animalIdentifications',
      type: 'text',
      required: true,
      description: 'Separate each new ID with a comma',
    },
    {
      name: 'Date of Arrival',
      id: 'dateOfArrival',
      type: 'date',
      required: true,
      validity: true,
      value: formatDateField(new Date()),
      errorMessage: 'Date of Arrival cannot be in the future',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Vehicle',
      description: 'Fill out the identifier or select one of your own',
      type: 'content',
      content: (
        <SearchableDropdown
          id="vehicle-search"
          type="vehicle"
          optionKey="licensePlateNum"
          options={vehicles}
          placeholder="Search by vehicle ID..."
          onChange={(vehicle) => setSelectedVehicle(vehicle)}
          onSelect={(vehicle) => setSelectedVehicle(vehicle)}
          selectedValues={selectedVehicle}
          onSelectionChange={setSelectedVehicle}
        />
      ),
    },
    {
      name: 'Location (Latitude, Longitude)',
      hint: 'Latitude, Longitude',
      id: 'location',
      type: 'location',
    },
  ];

  // Grouping steps into a single array
  const addImportFields = [
    {
      name: 'Import Information',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
  ];
  return addImportFields;
};

export default getFormFields;
