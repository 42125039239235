import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import getFormFields, {
  getFilteredSheep,
  getStepOneFields,
  getStepTwoFields,
} from '../../Fields/AddRetireTagFields.js';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';
import { createTagRetirementReport } from '../../utils/TagUtils.js';

export default function AddRetireTag({ animals, vehicles, premises }) {
  const [redirect, setRedirect] = useState('');

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [selectedSheep, setSelectedSheep] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  const handleSelectionChange = (selectedSheep) => {
    setSelectedSheep(selectedSheep);
    const isValid = selectedSheep.length > 0;
    setValidFieldsInCurrentStep(isValid);
    formFields[0].isValid = isValid;
  };

  // Use Effect to check validity when a vehicle is selected
  useEffect(() => {
    // Check validity
    if (currentStep === 1) {
      const updatedFields = [...formFields];
      if (selectedVehicle) {
        let isValid = FormUtils.checkRequiredFieldsValidity(
          updatedFields[currentStep].fields,
        );
        setValidFieldsInCurrentStep(isValid);

        // Update the isValid flag for the current step in formFields
        updatedFields[currentStep].isValid = isValid;
        // Update the form fields
        updateFields(updatedFields);
        FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
      }
    }
  }, [selectedVehicle]);

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = () => {
      try {
        const initialFormFieldsData = getFormFields();
        // Get Fields to pass them to the StepBasedForm
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [animals]);

  /** Handle when the user changes a field in the form */
  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          // Sheep Selector Type Performed
          if (fieldToUpdate.id === 'useSheepMultiSelector') {
            setSelectedSheep([]);
            const updatedStepFields = getStepOneFields(
              value,
              animals,
              handleSelectionChange,
              premises,
              selectedSheep,
            );
            updatedFields[currentStep].fields = [
              updatedFields[currentStep].fields[0],
              updatedFields[currentStep].fields[1],
              ...updatedStepFields,
            ];
          }

          if (fieldToUpdate.id === 'sourcePremise') {
            setSelectedSheep([]);
            const updatedStepFields = getFilteredSheep(
              value,
              animals,
              handleSelectionChange,
            );
            updatedFields[currentStep].fields = [
              updatedFields[currentStep].fields[0],
              updatedFields[currentStep].fields[1],
              updatedFields[currentStep].fields[2],
              ...updatedStepFields,
            ];
          }

          // Disposal Select is performed
          if (fieldToUpdate.id === 'action') {
            const updatedStepFields = getStepTwoFields(
              value,
              premises,
              vehicles,
              selectedVehicle,
              setSelectedVehicle,
            );
            updatedFields[currentStep].fields = [
              updatedFields[currentStep].fields[0],
              updatedFields[currentStep].fields[1],
              updatedFields[currentStep].fields[2],
              ...updatedStepFields,
            ];
          }

          let isValid = false;
          // Other steps: Check if all required fields in the current step are valid
          isValid = FormUtils.checkRequiredFieldsValidity(
            updatedFields[currentStep].fields,
          );

          if (currentStep === 0) {
            isValid = isValid && selectedSheep.length > 0;
          } else if (currentStep === 1) {
            const actionField = updatedFields[currentStep].fields.find(
              (field) => field.id === 'action',
            );
            if (actionField.value === 'SLAUGHTER') {
              isValid = isValid && selectedVehicle;
            }
          }

          setValidFieldsInCurrentStep(isValid);

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  /** Handle when the user attempts to move to another step */
  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    const isValid = FormUtils.checkRequiredFieldsValidity(
      formFields[currentStep].fields,
    );
    setValidFieldsInCurrentStep(isValid);

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStepData.isValid || stepIndex < currentStep) {
      const { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  const handleSubmit = async () => {
    let animalIds;
    if (Array.isArray(selectedSheep)) {
      animalIds = selectedSheep.map((animal) => animal.tag.isoNumber);
    } else {
      animalIds = selectedSheep.split(',').map((iso) => iso.trim());
    }
    const fieldValues = FormUtils.flattenForm(formFields);
    let [year, month, day] = fieldValues.disposalDate.split('-');
    if (!year || !month || !day) {
      [year, month, day] = fieldValues.disposalDate.split('/');
    }
    const now = new Date();
    const disposalDate = new Date(
      year,
      month - 1,
      day,
      now.getHours(),
      now.getMinutes(),
      now.getSeconds(),
    );
    try {
      setSubmitting(true);
      const vehicle = selectedVehicle?.licensePlateNum ?? selectedVehicle;
      const report = await createTagRetirementReport({
        animals:
          fieldValues.useSheepMultiSelector === 'true'
            ? selectedSheep.map(
                (s) =>
                  s.tag.isoNumber ||
                  s.tag.usScrapieId ||
                  s.tag.localMgmtNumber ||
                  s.tag.tattooNumber,
              )
            : animalIds,
        disposalPID: fieldValues.disposalSitePID,
        departurePID: fieldValues.departureSitePID,
        dateOfDisposal: disposalDate.toISOString(),
        dateOfConveyance: fieldValues.dateOfConveyance,
        vehicle: vehicle,
        action: fieldValues.action,
      });

      setSubmitting(false);
      sessionStorage.clear();
      toast.dismiss();
      toast.success('Success: Report created');
      setRedirect(`/reports/retire/${report._id}`);
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      toast.error('Error creating report: ' + err);
    }
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }

  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Create Tag Retirement Report"
      onCancelPath="/reports/retire/listView"
    />
  );
}
