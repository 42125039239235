import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  FetchPremises,
  FetchVehicles,
  FetchAnimals,
  PremisesFormatter,
} from '../../components/SheepReportComponents.js';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents';
import AddExport from '../../components/Forms/AddExport.js';

const MoveOutReportCreateView = () => {
  const [redirect, setRedirect] = useState();
  const [premises, setPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [animals, setAnimals] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetVehicles();
    fetchAndSetAnimals();
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      setPremises(PremisesFormatter(fetchedPremises));
    } catch (err) {
      if (err.code == 404) {
        setPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetVehicles = async () => {
    try {
      const fetchedVehicles = await FetchVehicles();
      setVehicles(fetchedVehicles);
    } catch (err) {
      if (err.code == 404) {
        setVehicles([]);
      } else {
        throw new Error(
          'We were unable to check which vehicles you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetAnimals = async () => {
    try {
      const animalIdsFetched = await FetchAnimals();
      setAnimals(animalIdsFetched);
    } catch (err) {
      if (err.code === 404) {
        setAnimals([]);
      } else {
        throw new Error('Unable to Find any Animals');
      }
    }
  };

  const paths = [
    { url: '/reports/export', title: 'Dashboard' },
    {
      url: '/reports/export/create/manual',
      title: 'Create an Export Report',
    },
  ];

  if (redirect) return <Redirect to={redirect} />;
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddExport
            errors={{}}
            animals={animals}
            premises={premises}
            vehicles={vehicles}
          />
        </ContentContainer>
      </div>
    </>
  );
};

export default MoveOutReportCreateView;
