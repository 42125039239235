// ImportReportDetailView.js
import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { getPremiseByPid } from '../../utils/PremiseUtils';
import { toast } from 'react-toastify';
import { useParams } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';
import { localDateFormatter } from '../../utils/TimeUtils';

const detailExportColumns = [
  {
    id: 'isoNumber',
    name: 'Sheep ISO Number',
    required: true,
  },
];

const fetchImportInfo = async (id) => {
  try {
    const request = await axios.get(`/api/import/${id}`);
    return request.data;
  } catch (err) {
    console.log(err);
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
};

const Info = ({ name, value }) => {
  return (
    <div>
      <span>{name}:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
};

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any,
};

const ImportReportDetailView = () => {
  const [importReportInfo, setImportReportInfo] = useState();
  const [importSheepInfo, setImportSheepInfo] = useState([]);
  const [abbatoirPremiseName, setAbbatoirPremiseName] = useState([]);
  const [firstDestpremiseName, setFirstDestPremiseName] = useState([]);
  const [reportLocation, setReportLocation] = useState(null);
  const params = useParams();
  const id = params.id;

  // fetchExportReportInfo
  const fetchAndSetExportReportInfo = async () => {
    try {
      let fetchedImportReportInfo = await fetchImportInfo(id);

      // Abattoir
      if (fetchedImportReportInfo.abattoirPID) {
        // If user is not authorized to view premise details then display N/A
        try {
          let fetchedAbattoirPremiseName = await getPremiseByPid(
            fetchedImportReportInfo.abattoirPID,
          );
          setAbbatoirPremiseName(fetchedAbattoirPremiseName.name);
        } catch (err) {
          setAbbatoirPremiseName('N/A');
        }
      } else {
        fetchedImportReportInfo.abattoirPID = <NAField />;
        setAbbatoirPremiseName(<NAField />);
      }

      // If user is not authorized to view premise details then display N/A
      try {
        // First Destination Premise
        let fetchedFirstDestPremiseName = await getPremiseByPid(
          fetchedImportReportInfo.firstDestinationPID,
        );
        setFirstDestPremiseName(fetchedFirstDestPremiseName.name);
      } catch (err) {
        setFirstDestPremiseName('N/A');
      }

      // Sheep Animal IDs
      const sheepIsoNumberArray = [];
      fetchedImportReportInfo.animalIdentifications.forEach((id) => {
        sheepIsoNumberArray.push({ _id: id, isoNumber: id });
      });
      setImportSheepInfo(sheepIsoNumberArray);

      fetchedImportReportInfo.dateOfArrival =
        fetchedImportReportInfo.dateOfArrival ? (
          localDateFormatter(fetchedImportReportInfo.dateOfArrival)
        ) : (
          <NAField />
        );
      // Departure Site
      fetchedImportReportInfo.departureSite =
        fetchedImportReportInfo.departureSite ? (
          fetchedImportReportInfo.departureSite
        ) : (
          <NAField />
        );

      setImportReportInfo(fetchedImportReportInfo);

      // if this report has a geolocation, save that to a state as well
      if (
        fetchedImportReportInfo.location &&
        Array.isArray(fetchedImportReportInfo.location.coordinates) &&
        fetchedImportReportInfo.location.coordinates.length === 2
      ) {
        const longlat = fetchedImportReportInfo.location.coordinates;
        setReportLocation([longlat[1], longlat[0]]);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  useEffect(() => {
    fetchAndSetExportReportInfo();
  }, []);

  return (
    <>
      <PageCard
        title="Import Report Details"
        verify={`/verify/import/${id}`}
        back
      >
        {importReportInfo ? (
          <>
            <SimpleCard title="Location Information" size="md">
              {/* Location Panel*/}
              <OneThirdDiv>
                <Info
                  name="Export Country"
                  value={importReportInfo.exportingCountry}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info
                  name="Abbatoir Premise ID"
                  value={importReportInfo.abattoirPID}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info
                  name="First Destination Premise PID"
                  value={importReportInfo.firstDestinationPID}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info
                  name="Departure Site"
                  value={importReportInfo.departureSite}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info
                  name="Abbatoir Premise Name"
                  value={abbatoirPremiseName}
                />
              </OneThirdDiv>

              <OneThirdDiv>
                <Info
                  name="First Destination Premise Name"
                  value={firstDestpremiseName}
                />
              </OneThirdDiv>
            </SimpleCard>
            <br></br>
            <br></br>

            {/* Transport Sheep Info */}
            <SimpleCard title="Transported Sheep" size="md">
              <StripedTable
                columns={detailExportColumns}
                rows={importSheepInfo}
                ShowCount
                max={10}
              />
            </SimpleCard>

            {/* Vehicle Info */}
            <SimpleCard title="Vehicle Information" size="sm">
              <Info
                name="License Plate Number"
                value={importReportInfo.vehicleIdentification}
              />
            </SimpleCard>
            <br></br>

            {/* Arrival Date Info */}
            {importReportInfo.dateOfArrival ? (
              <>
                <SimpleCard title="Arrival Date" size="sm">
                  <Info name="Date" value={importReportInfo.dateOfArrival} />
                </SimpleCard>
              </>
            ) : (
              <> </>
            )}
            <br></br>

            {reportLocation && (
              <SimpleCard title="Location" size="sm">
                <Info name="Latitude" value={reportLocation[0]} />
                <Info name="Longitude" value={reportLocation[1]} />
              </SimpleCard>
            )}
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const OneThirdDiv = styled.div`
  width: 33%;
  float: left;
  display: inline-block;
`;

export default ImportReportDetailView;
