import axios from 'axios';

/** @module utils/UserUtils */

/**
 * Sends a request to the server to fetch all users from the database.
 *
 * @function
 * @returns {Object[]} An array of objects representing the users
 */
export const getUsers = () => {
  return axios.get('/api/users').then((res) => res.data);
};

/**
 * Sends a request to the server to retrieve a user by their username.
 *
 * @function
 * @param {string} username - The username of the user to retrieve
 * @returns {Object} An object representing the user
 */
export const getUser = (username) => {
  return axios.get(`/api/users/username/${username}`).then((res) => res.data);
};

/**
 * Sends a request to the server to retrieve a user by their ID.
 *
 * @function
 * @param {string} userId - The ID of the user to retrieve
 * @returns {Object} An object representing the user
 */
export const getUserById = (userId) => {
  return axios.get(`/api/users/${userId}`).then((res) => res.data);
};

/**
 * Sends a request to the server to remove a user by their username.
 *
 * @function
 * @param {string} username - The username of the user to delete
 */
export const deleteUser = (username) => {
  axios
    .delete(`/api/users/${username}`)
    .then((res) => res.data)
    .catch((err) => {
      console.error(`Error during user deletion: ${JSON.stringify(err)}`);
    });
};

export const updateUser = (username, updateBody) => {
  return axios
    .put(`/api/users/${username}`, updateBody)
    .then((res) => res.data)
    .catch((err) => {
      console.error(`Error during user update: ${JSON.stringify(err)}`);
    });
};

export async function uploadProfileImage(file, userId) {
  const formData = new FormData();
  formData.append('profileImage', file);

  try {
    const response = await axios.post(
      `/api/users/upload-profile-image/${userId}`,
      formData,
    );

    return response.data.profileImage;
  } catch (error) {
    console.error('Error uploading profile image:', error);
    throw error; // Optionally rethrow the error for handling elsewhere
  }
}

/**
 * Constructs the full URL for a profile image using the provided relative URL.
 * If the URL is not empty, prepends it with the API base URL from environment variables.
 * @param {string} url - Relative URL of the profile image.
 * @returns {string | undefined} Full API URL of the profile image, or undefined if the input URL is empty or if apiUrl is not defined.
 */
export const handleImageUrl = (url) => {
  // Check if apiUrl is defined
  const apiUrl = process.env.REACT_APP_API_URL;
  if (!apiUrl) {
    return undefined;
  }

  // Check if url is empty
  if (url === '') {
    return undefined;
  }

  // Construct and return full API URL
  return `${apiUrl}${url}`;
};
