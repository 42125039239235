import React, { useState, useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { getUsername } from '../../utils/TokenUtils.js';
import { getUserActiveOperations } from '../../utils/OperationUtils';
import {
  FetchPremises,
  PremisesFormatter,
  FetchVehicles,
  FetchAnimals,
} from '../../components/SheepReportComponents';
import { ContentContainer } from '../../components/CommonComponents';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import AddMoveOut from '../../components/Forms/AddMoveOut.js';

const MoveOutReportCreateView = () => {
  const location = useLocation();
  const [redirect, setRedirect] = useState(false);
  const [allPremises, setAllPremises] = useState([]);
  const [formattedPremises, setFormattedPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [animalIds, setAnimalIds] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetVehicles();
    fetchAndSetAnimalIds();
    if (location.state && location.state.isoNumber) {
      setDefaultISONumber(location.state.isoNumber);
    }
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      setAllPremises(fetchedPremises);
      setFormattedPremises(PremisesFormatter(fetchedPremises));
    } catch (err) {
      if (err.code == 404) {
        setFormattedPremises([]);
        setAllPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetVehicles = async () => {
    try {
      setVehicles(await FetchVehicles());
    } catch (err) {
      if (err.code == 404) {
        setVehicles([]);
      } else {
        throw new Error(
          'We were unable to check which vehicles you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetAnimalIds = async () => {
    try {
      const animalIdsFetched = await FetchAnimals();
      setAnimalIds(animalIdsFetched);
    } catch (err) {
      if (err.code === 404) {
        setAnimalIds([]);
      } else {
        throw new Error('Unable to Find any Animals');
      }
    }
  };

  const fetchOperations = async () => {
    try {
      const result = await getUserActiveOperations(getUsername());
      const reducedOperations = result.map((o) => ({
        name: o.businessName,
        value: o._id,
      }));
      setOperations(reducedOperations);
    } catch (error) {
      console.error('Error fetching operations:', error);
    }
  };
  if (redirect) return <Redirect to={redirect} />;

  const paths = [
    { url: '/reports/moveout', title: 'Dashboard' },
    {
      url: '/reports/moveout/new',
      title: 'Create a Move Out Report',
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddMoveOut
            errors={{}}
            premises={formattedPremises}
            allPremises={allPremises}
            animals={animalIds}
            vehicles={vehicles}
          />
        </ContentContainer>
      </div>
    </>
  );
};

export default MoveOutReportCreateView;
