import React, { useState, useEffect } from 'react';
import { ContentContainer } from '../../components/CommonComponents';
import TagAllocationRequest from '../../components/Forms/TagAllocationRequest';
import { FetchPremises } from '../../components/SheepReportComponents';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath';

export default function TagAllocationCreateRequestView({}) {
  const [allPremises, setAllPremises] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      setAllPremises(fetchedPremises);
    } catch (err) {
      if (err.code == 404) {
        setFormattedPremises([]);
        setAllPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const paths = [
    { url: '/', title: 'Home' },
    { url: '/tagallocation/requests', title: 'Tag Allocation Requests' },
    { url: '/tagallocation/requests/create', title: 'Create' },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <TagAllocationRequest errors={{}} premises={allPremises} />
        </ContentContainer>
      </div>
    </>
  );
}
