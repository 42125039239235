import React, { useEffect, useState } from 'react';
import PageCard from '../../components/PageCard';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams, useLocation } from 'react-router';
import SimpleCard from '../../components/SimpleCard';
import propTypes from 'prop-types';
import styled from 'styled-components';
import StripedTable from '../../components/StripedTable';
import { NAField } from '../../components/SheepReportComponents';
import { Button } from '../../components/CommonComponents.js';
import { Redirect } from 'react-router';
import { createMoveLog } from '../../utils/MoveUtils';
import { getPremiseByPid } from '../../utils/PremiseUtils';

const convertToAmPm = (time) => {
  const hourMinute = time.split(':');
  let hr = +hourMinute[0];
  let min = +hourMinute[1];
  const ampm = hr >= 12 ? 'pm' : 'am';
  hr = hr % 12;
  hr = hr > 0 ? hr : 12;
  min = min < 10 ? '0' + min : min;
  return `${hr}:${min}${ampm}`;
};

const detailExportColumns = [
  {
    id: 'isoNumber',
    name: 'ISO Number',
    required: true,
  },
  {
    id: 'localMgmtNumber',
    name: 'Local Management Number',
    required: true,
  },
  {
    id: 'tattooNumber',
    name: 'Tattoo Number',
    required: true,
  },
  {
    id: 'breed',
    name: 'Breed',
    required: true,
  },
  {
    id: 'gender',
    name: 'Gender',
    required: true,
  },
];

const fetchMoveOutInfo = async (id) => {
  try {
    const request = await axios.get(`/api/move/${id}`);
    return request.data;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
};

const Info = ({ name, value }) => {
  return (
    <div>
      <span>{name}:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
};

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any,
};

const CreateMoveInFromMoveOut = () => {
  const [moveInReportInfo, setMoveInReportInfo] = useState();
  const [moveInSheepsInfo, setMoveInSheepsInfo] = useState([]);
  const [reportSheepsInfo, setReportSheepsInfo] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [departurePremiseName, setDeparturePremiseName] = useState([]);
  const [destpremiseName, setDestPremiseName] = useState([]);
  const [vehicleName, setVehicleName] = useState([]);

  const [reportLocation, setReportLocation] = useState(null);

  const params = useParams();
  const id = params.id;

  const location = useLocation();
  const notificationID = location.state?.id;

  const createEnabled = () => {
    return !submitting;
  };

  // fetchExportReportInfo
  const fetchAndSetMoveInReportInfo = async () => {
    try {
      let fetchedMoveOutReportInfo = await fetchMoveOutInfo(id);

      let fetchedDeparturePremiseName;

      try {
        fetchedDeparturePremiseName = await getPremiseByPid(
          fetchedMoveOutReportInfo.departurePID,
        );
      } catch (err) {
        fetchedDeparturePremiseName = { name: 'N/A' };
      }

      setDeparturePremiseName(fetchedDeparturePremiseName.name);

      let fetchedFirstDestPremiseName = await getPremiseByPid(
        fetchedMoveOutReportInfo.destinationPID,
      );

      setDestPremiseName(fetchedFirstDestPremiseName.name);
      setVehicleName(fetchedMoveOutReportInfo.vehicleNumber);
      const animalIds = fetchedMoveOutReportInfo.animalTags;
      setReportSheepsInfo(animalIds);
      setMoveInSheepsInfo(fetchedMoveOutReportInfo.animals);
      setMoveInReportInfo(fetchedMoveOutReportInfo);
      // if this report has a geolocation, save that to a state as well
      if (
        fetchedMoveOutReportInfo.location &&
        Array.isArray(fetchedMoveOutReportInfo.location.coordinates) &&
        fetchedMoveOutReportInfo.location.coordinates.length === 2
      ) {
        const longlat = fetchedMoveOutReportInfo.location.coordinates;
        setReportLocation([longlat[1], longlat[0]]);
      }
    } catch (err) {
      toast.error(err);
    }
  };

  const handleSubmit = async () => {
    const receivedDate = new Date();

    setSubmitting(true);
    toast.info(
      'Submitting move-in report to the server. This may take up to a minute.',
    );
    try {
      await createMoveLog({
        moveOut: false,
        noMoveOut: null,
        departurePID: moveInReportInfo.departurePID,
        destinationPID: moveInReportInfo.destinationPID,
        animals: reportSheepsInfo,
        vehicleNumber: moveInReportInfo.vehicleNumber,
        departureTime: moveInReportInfo.departureTime,
        receivedTime: receivedDate,
        unloadTime: new Date(),
        location: moveInReportInfo.location,
      });
      // Delete Notification
      await deleteNotification(notificationID);
      setSubmitting(false);
      toast.success('Success: Report created');
      setRedirect(true);
    } catch (error) {
      toast.error(
        'There is an error with the submission, please use the move in from report dashboard to manually create a move in report',
      );
      setSubmitting(false);
    }
  };

  const deleteNotification = async (data) => {
    return axios
      .delete(`/api/notifications/delete/${data}`)
      .then(() => {})
      .catch(() => {
        toast.error(
          'Error Deleting Notification, Delete the Notification Manually',
        );
      });
  };

  useEffect(() => {
    fetchAndSetMoveInReportInfo();
  }, []);

  moveInSheepsInfo.forEach((s) => {
    s.isoNumber = s.tag.isoNumber ? s.tag.isoNumber : <NAField />;
    s.localMgmtNumber = s.tag.localMgmtNumber ? (
      s.tag.localMgmtNumber
    ) : (
      <NAField />
    );
    s.tattooNumber = s.tag.tattooNumber ? s.tag.tattooNumber : <NAField />;
    s.breed = s.breed ? s.breed : <NAField />;
    s.gender = s.gender ? s.gender : <NAField />;
  });

  if (redirect) return <Redirect to="/reports/movein" />;
  return (
    <>
      <PageCard
        title="Move-Out Report Details"
        verify={`/verify/moveout/${id}`}
        back
      >
        {moveInReportInfo ? (
          <>
            <SimpleCard title="Premise Information" size="md">
              {/* Location Panel*/}
              <HalfDiv>
                <Info
                  name="Departure Premise PID"
                  value={moveInReportInfo.departurePID}
                />
              </HalfDiv>

              <HalfDiv>
                <Info
                  name="Destination Premise PID"
                  value={moveInReportInfo.destinationPID}
                />
              </HalfDiv>

              <HalfDiv>
                <Info
                  name="Departure Premise Name"
                  value={departurePremiseName}
                />
              </HalfDiv>

              <HalfDiv>
                <Info name="Destination Premise Name" value={destpremiseName} />
              </HalfDiv>
            </SimpleCard>
            <br></br>
            <br></br>

            {/* Transport Sheep Info */}
            <SimpleCard title="Transported Sheep" size="md">
              <StripedTable
                columns={detailExportColumns}
                rows={moveInSheepsInfo}
                ShowCount
                max={10}
              />
            </SimpleCard>

            {/* Vehicle Date Info */}
            {moveInReportInfo.vehicle ? (
              <>
                <SimpleCard title="Vehicle Information" size="sm">
                  <HalfDiv>
                    <Info name="Name" value={vehicleName} />
                  </HalfDiv>
                  <HalfDiv>
                    <Info
                      name="License Plate Number"
                      value={moveInReportInfo.vehicle.licensePlateNum}
                    />
                  </HalfDiv>
                </SimpleCard>
              </>
            ) : (
              <> </>
            )}
            <br></br>

            {/* Receive Date Info */}
            {moveInReportInfo.receivedTime ? (
              <>
                <SimpleCard title="Receive Date and Time" size="sm">
                  <HalfDiv>
                    <Info name="Name" value={moveInReportInfo.receivedTime} />
                  </HalfDiv>
                  <HalfDiv>
                    <Info
                      name="License Plate Number"
                      value={moveInReportInfo.unloadTime}
                    />
                  </HalfDiv>
                </SimpleCard>
              </>
            ) : (
              <> </>
            )}
            <br></br>

            {/* Arrival Date Info */}
            {moveInReportInfo.departureTime ? (
              <>
                <SimpleCard title="Departure Date" size="sm">
                  <HalfDiv>
                    <Info
                      name="Date"
                      value={moveInReportInfo.departureTime.split('T')[0]}
                    />
                  </HalfDiv>
                  <HalfDiv>
                    <Info
                      name="Load Time"
                      value={convertToAmPm(moveInReportInfo.loadTime)}
                    />
                  </HalfDiv>
                </SimpleCard>
              </>
            ) : (
              <> </>
            )}
            <br></br>

            {reportLocation && (
              <SimpleCard title="Location" size="sm">
                <HalfDiv>
                  <Info name="Latitude" value={reportLocation[0]} />
                </HalfDiv>
                <HalfDiv>
                  <Info name="Longitude" value={reportLocation[1]} />
                </HalfDiv>
              </SimpleCard>
            )}

            <br></br>
            <br></br>
            <LeftDiv>
              <Button disabled={!createEnabled()} onClick={handleSubmit}>
                Create a move In
              </Button>
            </LeftDiv>
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
`;

const LeftDiv = styled.div`
  float: right;
`;

export default CreateMoveInFromMoveOut;
