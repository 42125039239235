// Input.js
import React from 'react';
import {
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
} from './CommonComponents.js';
import propTypes from 'prop-types';

const Input = ({ id, labelName, placeholder, required, onChange, value }) => (
  <>
    <FormInputWrapper key={id}>
      <FormInputLabel htmlFor="input">
        {labelName}
        {required ? <span style={{ color: 'red' }}>*</span> : <></>}
      </FormInputLabel>
      <FormTextInput
        placeholder={placeholder}
        id={id}
        name={id}
        value={value}
        onChange={onChange}
      />
    </FormInputWrapper>
  </>
);

Input.propTypes = {
  id: propTypes.node,
  labelName: propTypes.node,
  placeholder: propTypes.node,
  required: propTypes.node,
  onChange: propTypes.node,
  value: propTypes.node,
};

export default Input;
