import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import propTypes from 'prop-types';
import axios from 'axios';
import getFormFields from '../../Fields/addSheepFields.js';
import {
  isSingleISOFormat,
  isRangeISOFormat,
} from 'agroledger-shared-library/TagUtils';
import StepBasedForm from '../reusedComponents/StepBasedForm.js';
import * as FormUtils from '../../utils/FormUtils.js';

/**
 * Submits a sheep to the server to be processed. If the sheep is properly
 * formatted and the user has the right to create a new sheep, the sheep will
 * be registered to the database
 *
 * @param {Object} sheep - An object representing a sheep to be submitted
 */
const submitSheep = async (sheep) => {
  const request = await axios.post('/api/sheep/', sheep);
  return request.data.sheep;
};

export default function AddSheep({ breeds, premises, breedPopulateHandler }) {
  const [redirect, setRedirect] = useState('');
  const [associatedDocument, setAssociatedDocument] = useState([]);

  const [submitting, setSubmitting] = useState(false);
  const [validFieldsInCurrentStep, setValidFieldsInCurrentStep] =
    useState(false);
  const [validForm, setValidForm] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsNames, setStepsNames] = useState([]);
  const [formFields, setFormFields] = useState();
  // State to hold the timeout ID
  const [typingTimeout, setTypingTimeout] = useState(0);

  const updateFields = (updatedFields) => {
    setFormFields(updatedFields);
  };

  useEffect(() => {
    sessionStorage.clear();
    // Fetch initial fields when component mounts
    const fetchInitialFormFields = async () => {
      try {
        const initialFormFieldsData = getFormFields(
          premises,
          breeds,
          breedPopulateHandler,
          setAssociatedDocument,
          associatedDocument
        );
        // Get Fields to pass them to the DynamiFormFields
        updateFields(initialFormFieldsData);
        // Get Names to pass them to the timeline
        setStepsNames(initialFormFieldsData.map((stepObj) => stepObj.name));
      } catch (error) {
        console.error('Error fetching initial fields:', error);
      }
    };

    fetchInitialFormFields();
  }, [breeds, premises, breedPopulateHandler]);

  const handleFieldChange = (index, event) => {
    let { value } = event.target;
    const updatedFields = [...formFields];
    const fieldToUpdate = updatedFields[currentStep].fields[index];

    if (fieldToUpdate) {
      // Formatting user inputs if provided to onchange()
      if (fieldToUpdate.onChange) {
        value = fieldToUpdate.onChange(value);
      }

      // Update New Value
      fieldToUpdate.value = value;

      // Clear previous timeout
      clearTimeout(typingTimeout);

      // Set timeout to detect when input entry stops
      setTypingTimeout(
        setTimeout(() => {
          // Check field validity after input entry stops
          const inputIsValid = FormUtils.checkFieldInputValidity(
            fieldToUpdate,
            updatedFields[currentStep].fields,
          );
          fieldToUpdate.validity = inputIsValid;

          let isValid = false;
          if (currentStep === 1) {
            // Step 2: Sheep Identifiers - check if at least one ID is valid
            isValid = updatedFields[currentStep].fields.some(
              (field) => field.validity === true,
            );
          } else {
            // Other steps: Check if all required fields in the current step are valid
            isValid = FormUtils.checkRequiredFieldsValidity(
              updatedFields[currentStep].fields,
            );
          }
          setValidFieldsInCurrentStep(isValid);

          // Update the isValid flag for the current step in formFields
          updatedFields[currentStep].isValid = isValid;
          // Update the form fields
          updateFields(updatedFields);
          FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
        }, 100),
      );
    } else {
      console.error(`Field '${index}' not found in formFields.`);
    }
  };

  useEffect(() => {
    if (associatedDocument && associatedDocument.length > 0) {
      const updatedFields = [...formFields];
      let isValid = FormUtils.checkRequiredFieldsValidity(
        updatedFields[currentStep].fields,
      );
      setValidFieldsInCurrentStep(isValid);
      updatedFields[currentStep].isValid = isValid;
      updateFields(updatedFields);
      FormUtils.checkAndUpdateStepsValidity(updatedFields, setValidForm);
    }
  }, [associatedDocument]);

  const handleStepClick = (stepIndex) => {
    const currentStepData = formFields[currentStep];

    FormUtils.checkAndUpdateStepsValidity(formFields, setValidForm);

    if (currentStepData.isValid || stepIndex < currentStep) {
      let { isValid, step: nextStep } = FormUtils.nextStep(
        formFields,
        stepIndex,
        currentStep,
      );
      if (nextStep === 1) {
        isValid =
          isValid &&
          formFields[nextStep].fields.some((field) => field.validity === true);
      }
      setValidFieldsInCurrentStep(isValid);
      setCurrentStep(nextStep);
      return;
    }

    toast.dismiss();
    toast.error(currentStepData.errorMessage);
  };

  if (!formFields) {
    return null;
  }

  /**
   * Handles the event on which the user pushes the "Create" button. This
   * function will submit the new sheep data to be processed by the server
   * and reports any errors
   */
  const handleSubmit = async () => {
    // Flatten the formFields array
    const flattenedFields = formFields.flatMap((step) => step.fields);

    // Get the field values for the current step
    let fieldValues = FormUtils.getFieldValues(flattenedFields);
    try {
      setSubmitting(true);

      let redirectPath = `/sheep`;

      if (
        fieldValues.isoNumber === '' ||
        isSingleISOFormat(fieldValues.isoNumber)
      ) {
        let addedSheep = await handleSingleISO(fieldValues);
        if (addedSheep)
          redirectPath = addedSheep._id
            ? `${redirectPath}/${addedSheep._id}`
            : redirectPath;
      } else if (isRangeISOFormat(fieldValues.isoNumber)) {
        await handleRangeISO(fieldValues);
      } else {
        throw new Error('Invalid ISO Number format');
      }
      if (associatedDocument && associatedDocument.length > 0) { await uploadDocument(fieldValues); }

      setSubmitting(false);
      toast.dismiss();
      toast.success('Added Sheep successfuly!');
      setRedirect(redirectPath);
    } catch (err) {
      handleError(err, fieldValues);
    }
  };

  const handleSingleISO = async (fieldValues) => {
    const currentIso = fieldValues.isoNumber || '';
    const sheep = constructSheep(fieldValues, currentIso);
    let addedSheep = await submitSheep(sheep);
    return addedSheep;
  };

  const handleRangeISO = async (fieldValues) => {
    let isoNumber = fieldValues.isoNumber;
    const isoRangeRegex = /^(\d{15})-(\d{15})$/;
    const [, start, end] = isoRangeRegex.exec(isoNumber);

    const batchSize = 10; // Number of sheep to submit in each batch
    const startNumber = parseInt(start);
    const endNumber = parseInt(end);

    for (let i = startNumber; i <= endNumber; i += batchSize) {
      const batchEnd = Math.min(i + batchSize - 1, endNumber);
      // Create an array of promises for this batch
      const promises = [];
      for (let j = i; j <= batchEnd; j++) {
        const currentIso = j.toString().padStart(15, '0');
        const sheep = constructSheep(fieldValues, currentIso);
        promises.push(submitSheep(sheep));
      }

      // Wait for all sheep in this batch to be submitted
      await Promise.all(promises);
    }
  };

  const constructSheep = (fieldValues, isoNumber) => {
    return {
      premise: fieldValues.premise,
      gender: fieldValues.gender,
      subgender: fieldValues.subgender,
      breed: fieldValues.breed,
      birthdate: fieldValues.birthdate,
      tag: {
        isoNumber: isoNumber || undefined,
        localMgmtNumber: fieldValues.localMgmtNumber || undefined,
        tattooNumber: fieldValues.tattooNumber || undefined,
        usScrapieId: fieldValues.usScrapieId || undefined,
      },
    };
  };

  const uploadDocument = async (fieldValues) => {
    const formData = new FormData();
    formData.append('title', fieldValues.documentTitle);
    formData.append('description', fieldValues.documentDescription);
    formData.append('isonum', fieldValues.isoNumber);
    formData.append('document', associatedDocument[0], associatedDocument[0].name);

    try {
      await axios.post('/api/document/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    } catch (err) {
      handleDocumentUploadError(err);
    }
  };

  const handleDocumentUploadError = (err) => {
    if (err.response && err.response.data.message) {
      toast.error(`Could not upload document: ${err.response.data.message}`);
    } else {
      toast.error('Unable to upload documents at this time');
    }
  };

  const handleError = async (err, fieldValues) => {
    toast.dismiss();
    if (err.response && err.response.data) {
      const errorMessage = err.response.data.message;

      if (errorMessage.includes('CSIP')) {
        toast.error(`${errorMessage}: CSIP Reuse is not Permitted`);
      } else {
        toast.error(errorMessage);
      }
    } else {
      toast.error(err.message);
    }
    setSubmitting(false);
  };

  if (redirect != '') {
    return <Redirect to={redirect} />;
  }
  return (
    <StepBasedForm
      formFields={formFields}
      currentStep={currentStep}
      stepsNames={stepsNames}
      handleStepClick={handleStepClick}
      handleFieldChange={handleFieldChange}
      validFieldsInCurrentStep={validFieldsInCurrentStep}
      validForm={validForm}
      submitting={submitting}
      handleSubmit={handleSubmit}
      title="Add Sheep"
    />
  );
}

AddSheep.propTypes = {
  breeds: propTypes.arrayOf(
    propTypes.exact({
      name: propTypes.string.isRequired,
      value: propTypes.string.isRequired,
    }),
  ),
  premises: propTypes.arrayOf(
    propTypes.exact({
      name: propTypes.string.isRequired,
      value: propTypes.string.isRequired,
    }),
  ),
  breedPopulateHandler: propTypes.func.isRequired,
};
