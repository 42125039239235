import React, { useState } from 'react';
import FormCard from '../../components/FormCard.js';
import styled from 'styled-components';
import { parseAndSetData, convertAndParseXLS } from '../../utils/CSVUtils';
import { Button, VerticalContainer } from '../../components/CommonComponents';
import { COLORS } from '../../utils/Constants';
import SheepSight from './SheepSight.js';
import Table from '../../components/Table.js';
import FileUpload from '../../components/FileUpload.js';
import FilePreview from '../../components/FilePreview.js';
import FileHints from '../../components/FileHints.js';

const SightingCSVCreateView = () => {
  const [headings, setHeadings] = useState([]);
  const [data, setData] = useState([]);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [headers, setHeaders] = useState({});
  const [next, setNext] = useState(false);
  const [fileName, setFileName] = useState('');
  const fields = [
    { name: 'ISO Number', id: 'isoNumber' },
    { name: 'Local Management Number', id: 'localMgmtNumber' },
    { name: 'Tattoo Number', id: 'tattooNumber' },
    { name: 'US Scrapie ID', id: 'scrapieId' },
  ];

  const fileUpload = (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) return;

    setFileName('Upload CSV or Excel File: ' + uploadedFile.name);

    // Parse CSV or XLS file
    if (/.*\.(csv|CSV)$/.test(uploadedFile.name)) {
      parseAndSetData(uploadedFile, setHeadings, setData);
    } else if (/.*\.(xls|XLS).?$/.test(uploadedFile.name)) {
      convertAndParseXLS(uploadedFile, setHeadings, setData);
    }
  };

  const disableSelectHeaders = () => {
    const mappedHeaders = Object.values(headers);
    const allHeadersMapped =
      mappedHeaders.length === fields.length &&
      mappedHeaders.every((header) => header && header !== 'Select');
    const uniqueHeaders = new Set(
      mappedHeaders.filter((header) => header !== 'N/A'),
    );
    const noDuplicates =
      uniqueHeaders.size ===
      mappedHeaders.filter((header) => header !== 'N/A').length;

    return !allHeadersMapped || !noDuplicates;
  };

  const hints = [
    'ISO numbers should follow this format: 124000 or 840000 followed by 9 digits',
    'US Scrapie IDs should be a two letter US state abbreviation followed by 7 digits',
  ];

  return (
    <>
      {next ? (
        <VerticalContainer>
          <BackButton onClick={() => setNext(false)}>Back</BackButton>
          <SheepSight data={data} headers={headers} />
        </VerticalContainer>
      ) : (
        <FormCard title="Create Sighting Reports" back>
          <h2>Upload File</h2>
          <FileUpload
            fileName={fileName}
            onFileChange={(file) => {
              fileUpload(file);
              setShowAllColumns(false);
            }}
            accept=".csv,.xls,.xlsx"
          />

          {headings.length === 0 ? (
            <>
              <Title>Example Table Format:</Title>
              <Table
                columns={[
                  'CSIP Number',
                  'Local Management Number',
                  'Tattoo Number',
                  'US Scrapie ID',
                ]}
                rows={[
                  ['124000310000000', 'A150', '100', 'CA1234567'],
                  ['124000310000001', '-', '-', '-'],
                ]}
                maxShowing={2}
                $stylePreset="boxyScrollable"
                paginate={false}
              />
              <FileHints hints={hints} />
            </>
          ) : (
            <FilePreview
              headings={headings}
              data={data}
              showAllColumns={showAllColumns}
              setShowAllColumns={setShowAllColumns}
              fields={fields}
              headers={headers}
              setHeaders={setHeaders}
              onSubmit={(e) => {
                e.preventDefault();
                setNext(true);
              }}
              disableSubmit={disableSelectHeaders()}
            />
          )}
        </FormCard>
      )}
    </>
  );
};

const BackButton = styled(Button)`
  margin: 20px auto;
  display: block;
`;

const Title = styled.h2`
  color: ${COLORS.text};
  font-size: 25px;
  text-align: left;
  margin: 20px 0;
`;

export default SightingCSVCreateView;
