import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import FormCard from '../../components/FormCard.js';
import { FormInputWrapper } from '../../components/CommonComponents.js';
import EditButtons from '../../components/reusedComponents/EditButtons';
import { COLORS } from '../../utils/Constants';
import { fetchRoleTypes, addRole, deleteRole } from '../../utils/RoleUtils.js';
import { getUsername, refreshTokens } from '../../utils/TokenUtils.js';

const Container = styled.div`
  padding: 2rem;
  max-width: 800px;
  margin: auto;
`;

const RoleTable = styled.table`
  margin-top: 25%;
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  padding: 10px;
  border-bottom: 1px solid ${COLORS.lightGray};
`;

const TableCell = styled.td`
  padding: 25px;
  border-bottom: 1px solid ${COLORS.lightGray};
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const TableRow = styled.tr`
  background-color: ${(props) =>
    props.isChecked ? COLORS.selected : 'inherit'};
`;

const GreenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  accent-color: green;
`;

const EditRoles = ({ userData, fetchUserData }) => {
  const [user, setUser] = useState(null);
  const [roleTypes, setRoleTypes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [checkedRoles, setCheckedRoles] = useState([]);

  useEffect(() => {
    setUser(userData);
    const fetchSystemRoles = async () => {
      try {
        const roleTypesData = await fetchRoleTypes();
        setRoleTypes(roleTypesData);
        setCheckedRoles(userData.roleString.split(', '));
      } catch (error) {
        console.error('Error fetching data:', error);
        toast.error('Error fetching role data');
      }
    };

    fetchSystemRoles();
  }, [userData]);

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setCheckedRoles(user.roleString.split(', '));
  };

  const handleCheckboxChange = (roleName, checked) => {
    if (!editMode) return;

    if (user.username === getUsername() && roleName === 'admin') {
      return;
    }

    if (checked && !checkedRoles.includes(roleName)) {
      setCheckedRoles((prevCheckedRoles) => [...prevCheckedRoles, roleName]);
    } else if (!checked) {
      setCheckedRoles((prevCheckedRoles) =>
        prevCheckedRoles.filter((role) => role !== roleName),
      );
    }
  };

  const handleSave = async () => {
    try {
      const rolesToAdd = checkedRoles.filter(
        (role) => !user.roleString.split(', ').includes(role),
      );
      const rolesToRemove = user.roleString
        .split(', ')
        .filter((role) => !checkedRoles.includes(role));

      if (rolesToRemove.length > user.roleString.length) {
        toast.info('At least one role required');
        return;
      } else if (rolesToAdd.length === 0 && rolesToRemove.length === 0) {
        toast.info('No changes applied.');
        return;
      }

      const addRolePromises = rolesToAdd.map((role) =>
        addRole(user.id, role)
          .then(() => toast.success(`${role} added!`))
          .catch((error) =>
            toast.error(`Error adding ${role}: ${error.message}`),
          ),
      );

      const removeRolePromises = rolesToRemove.map((role) =>
        deleteRole(user.id, role)
          .then(() => toast.success(`${role} removed!`))
          .catch((error) =>
            toast.error(`Error removing ${role}: ${error.message}`),
          ),
      );

      await Promise.all([...addRolePromises, ...removeRolePromises]);
      if (user.username === getUsername()) {
        await refreshTokens();
      }
      fetchUserData();
      setEditMode(false);
    } catch (error) {
      toast.error(`Error updating roles: ${error.message}`);
    }
  };

  if (!user || !roleTypes.length) {
    return (
      <Container>
        <p>Loading...</p>
      </Container>
    );
  }

  return (
    <FormCard title="Edit Roles">
      <FormInputWrapper>
        <RoleTable>
          <thead>
            <tr>
              <TableHeader>Assignment</TableHeader>
              <TableHeader>Role</TableHeader>
              <TableHeader>Description</TableHeader>
            </tr>
          </thead>
          <tbody>
            {roleTypes.map((role) => (
              <TableRow
                key={role.name}
                isChecked={checkedRoles.includes(role.name)}
              >
                <TableCell>
                  <CheckboxLabel>
                    <GreenCheckbox
                      value={role.name}
                      disabled={
                        !editMode ||
                        (user.username === getUsername() &&
                          role.name === 'admin')
                      }
                      checked={checkedRoles.includes(role.name)}
                      onChange={(e) =>
                        handleCheckboxChange(role.name, e.target.checked)
                      }
                    />
                  </CheckboxLabel>
                </TableCell>
                <TableCell>{role.niceName}</TableCell>
                <TableCell>{role.description}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </RoleTable>
        <EditButtons
          editMode={editMode}
          onCancel={handleCancel}
          onSave={handleSave}
          onEdit={handleEdit}
        />
      </FormInputWrapper>
    </FormCard>
  );
};

export default EditRoles;
