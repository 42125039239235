import { useState } from 'react';
import DecisionCard from '../../components/DecisionCard';
import React from 'react';

const AnimalDeathDecisionView = () => {
  const [currentId, setCurrentId] = useState('INIT');

  const EventDecisions = [
    {
      id: 'INIT',
      backToHelp: true,
      title: 'Animal Death - Abattoir or Slaughterhouse',
      description: 'Are you reporting as an abattoir or slaughterhouse?',
      choices: [
        {
          name: 'Yes',
          update: () => {
            setCurrentId('ABATTOIR');
          },
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setCurrentId('NONABATTOIR');
          },
        },
      ],
    },
    {
      id: 'ABATTOIR',
      backToHelp: true,
      prev: 'INIT',
      onBack: () => setCurrentId('INIT'),
      title: 'Animal Death - Abattoir or Slaughterhouse',
      description:
        'As an abattoir, you are required to report the death of an animal.  You can report a tag retirement at an abattoir here',
      choices: [
        {
          name: 'Tag Retirement',
          link: '/reports/retire/create',
        },
      ],
    },
    {
      id: 'NONABATTOIR',
      backToHelp: true,
      prev: 'INIT',
      onBack: () => setCurrentId('INIT'),
      title: 'Animal Death',
      description: 'Did you dispose of the animal on your premises?',
      choices: [
        {
          name: 'Yes',
          update: () => {
            setCurrentId('IDENTIFIED');
          },
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setCurrentId('MOVEMENT');
          },
        },
      ],
    },
    {
      id: 'IDENTIFIED',
      backToHelp: true,
      prev: 'NONABATTOIR',
      onBack: () => setCurrentId('NONABATTOIR'),
      title: 'Animal Death',
      description: 'Was the animal identified with an approved tag?',
      choices: [
        {
          name: 'Yes',
          update: () => {
            setCurrentId('RETIREMENT');
          },
        },
        {
          name: 'No',
          color: 'secondary',
          update: () => {
            setCurrentId('NOACTION');
          },
        },
      ],
    },
    {
      id: 'MOVEMENT',
      backToHelp: true,
      prev: 'NONABATTOIR',
      onBack: () => setCurrentId('NONABATTOIR'),
      title: 'Animal Death',
      description:
        'You are not required to report the death of an animal identified with an ' +
        'approved tag if the carcass was removed from the premises by deadstock collection, ' +
        'rendering facility, veterinary clinic, pathology lab, or government agency. ' +
        "The receiving parties will report the animal's death and retirement of the " +
        'approved tag. However, you are required to have a movement document to accompany ' +
        'the carcass to the destination site. You can prepare a movement document here.',
      choices: [
        {
          name: 'Create Movement Report',
          link: '/reports/movein/new',
        },
      ],
    },
    {
      id: 'RETIREMENT',
      backToHelp: true,
      prev: 'IDENTIFIED',
      onBack: () => setCurrentId('IDENTIFIED'),
      title: 'Animal Death',
      description:
        'You are required to report the disposal of an animal on your premises if it was identified with an approved tag (tag retirement).  You can report a tag retirement event here.',
      choices: [
        {
          name: 'Tag Retirement',
          link: '/reports/retire/create',
        },
      ],
    },
    {
      id: 'NOACTION',
      backToHelp: true,
      prev: 'IDENTIFIED',
      onBack: () => setCurrentId('IDENTIFIED'),
      title: 'Animal Death',
      description:
        'You are not required to report the disposal of an untagged animal on its farm of origin.',
      choices: [
        {
          name: 'Back to home page',
          link: '/',
        },
      ],
    },
  ];

  const getDecision = (id) => {
    const decisions = EventDecisions.filter((d) => d.id == id);
    if (decisions.length == 0) {
      setCurrentId('INIT');
      alert('Invalid decision id');
      return EventDecisions[0];
    }

    return decisions[0];
  };

  return <DecisionCard decision={getDecision(currentId)} />;
};

export default AnimalDeathDecisionView;
