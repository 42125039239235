import { getSelectedRole } from './RoleUtils';

const axios = require('axios');

export const getTransportLogs = () => {
  return axios
    .get('/api/transport', {
      params: {
        selectedRole: getSelectedRole(),
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const getRelatedTransportLogs = () => {
  return axios
    .get('/api/transport/related')
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

export const createTransportLog = (data) => {
  return axios
    .post('/api/transport', data)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

//get the transport reports for a specific sheep
export const getTransportReportsForSheep = (id) => {
  return axios
    .get(`/api/transport/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};
