import React, { useState } from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import {
  ContentContainer,
  HorizontalContainer,
  Icon,
} from '../components/CommonComponents.js';
import left from '../images/icons/chevron_left.png';
import right from '../images/icons/chevron_right.png';

const StripedTableContentContainer = styled(ContentContainer)`
  background: ${COLORS.secondary};
  border: none;
  padding-top: 35px;
  padding-bottom: 35px;
  margin-bottom: 30px;
  @media (max-width: 950px) {
    width: 95%;
  }
`;

const HeaderContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: ${COLORS.primary};
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  gap: 10px;
  margin-bottom: 30px;
  @media (max-width: 1150px) {
    font-size: 15px;
  };
  @media (max-width: 720px) {
    font-size: 13px;
  };
  @media (max-width: 620px) {
    font-size: 12px;
  };
  @media (max-width: 500px) {
    width: 85%
    font-size: 5px;
  };
  @media (max-width: 460px) {
    width: 75%
    font-size: 2px;
  };
`;

const TableHeaderContainer = styled(ContentContainer)`
  background: ${COLORS.primary};
  padding: 0px;
  border: none;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const BodyContainer = styled(ContentContainer)`
  padding: 0px;
  border: none;
  background: white;
  gap: 10px;
`;

const TableRowContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  gap: 10px;
  font-weight: bold;
`;

const TableDataContainer = styled(ContentContainer)`
  padding: 0px;
  border: none;
`;

const PaginationContainer = styled(ContentContainer)`
  padding: 0px;
  border: none;
  background: white;
  flex: display;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const NumberOfRecordsContainer = styled(ContentContainer)`
  padding: 0px;
  border: none;
  background: white;
  align-items: center;
  width: 100px;
`;

const StyledSpan = styled.span`
  display: block;
  word-wrap: break-word;
  @media (max-width: 1150px) {
    width: 110px;
  }
  @media (max-width: 950px) {
    width: 70px;
  }
  @media (max-width: 768px) {
    font-size: 13px;
    width: 50px;
  }
  @media (max-width: 620px) {
    font-size: 12px;
    width: 40px;
  }
  @media (max-width: 580px) {
    font-size: 11px;
    width: 30px;
  }

  @media (max-width: 460px) {
    width: 25px;
    font-size: 10px;
  }
`;

const StripedTable = ({ rows, columns, max, onRowClick }) => {
  if (!max) max = 10;
  const [page, setPage] = useState(1);
  const [maxState, setMaxState] = useState(max);

  if (!rows) rows = [];
  if (!columns) columns = [];

  const PaginationComponent = () => {
    return (
      <PaginationContainer>
        <HorizontalContainer mobile="none" style={{ alignSelf: 'center' }}>
          <Icon
            width="25px"
            src={left}
            hover="filter: opacity(0.5)"
            disabled={page === 1}
            onClick={() => {
              if (page > 1) {
                setPage(page - 1);
              }
            }}
          />
          {page - 1 >= 1 && (
            <h3
              style={{
                paddingBlock: '5px',
                paddingInline: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setPage(page - 1);
              }}
            >
              {page - 1}
            </h3>
          )}
          <h3
            style={{
              background: COLORS.secondary,
              borderRadius: '10px',
              paddingBlock: '5px',
              paddingInline: '10px',
              cursor: 'pointer',
            }}
          >
            {page}
          </h3>
          {page + 1 <= Math.ceil(rows.length / maxState) && (
            <h3
              style={{
                paddingBlock: '5px',
                paddingInline: '10px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setPage(page + 1);
              }}
            >
              {page + 1}
            </h3>
          )}
          <Icon
            width="25px"
            src={right}
            hover="filter: opacity(0.5)"
            disabled={page === Math.ceil(rows.length / maxState)}
            onClick={() => {
              if (page < Math.ceil(rows.length / maxState)) {
                setPage(page + 1);
              }
            }}
          />
        </HorizontalContainer>
        <h4 style={{ textAlign: 'center' }}>Records per page</h4>
        <NumberOfRecordsContainer>
          <select
            value={maxState}
            style={{
              borderColor: 'green',
              borderRadius: '10px',
              padding: '5px',
              paddingLeft: '20px',
              paddingRight: '20px',
              fontWeight: 'bold',
              fontSize: '15px',
            }}
            onChange={(e) => {
              setPage(1);
              setMaxState(e.target.value);
            }}
          >
            {[10, 25, 50].map((pageSize) => {
              return (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              );
            })}
          </select>
        </NumberOfRecordsContainer>
      </PaginationContainer>
    );
  };

  return (
    <>
      <StripedTableContentContainer>
        <ContentContainer
          $stylePreset="light"
          style={{ paddingTop: '45px', paddingBottom: '45px' }}
        >
          <HeaderContainer $stylePreset="light">
            {columns.map((c) => (
              <TableHeaderContainer key={c.id} style={{ color: 'white' }}>
                {c.name}
              </TableHeaderContainer>
            ))}
          </HeaderContainer>

          <BodyContainer>
            {rows.slice((page - 1) * maxState, page * maxState).map((r) => {
              return (
                <TableRowContainer
                  key={r._id}
                  onClick={onRowClick ? () => onRowClick(r) : null}
                >
                  {columns.map((c) => {
                    const cellContent =
                      Array.isArray(r[c.id]) && r[c.id].length > 1
                        ? r[c.id].slice(0, 1).join(', ') +
                          '... + ' +
                          (r[c.id].length - 1)
                        : r[c.id];
                    return (
                      <TableDataContainer key={c.id}>
                        <StyledSpan>{cellContent || 'N/A'}</StyledSpan>
                      </TableDataContainer>
                    );
                  })}
                </TableRowContainer>
              );
            })}
          </BodyContainer>
        </ContentContainer>
      </StripedTableContentContainer>
      <PaginationComponent />
    </>
  );
};

StripedTable.propTypes = {
  rows: propTypes.any,
  columns: propTypes.any,
  ShowFilters: propTypes.any,
  max: propTypes.any,
  ShowCount: propTypes.any,
  paginate: propTypes.any,
};

export default StripedTable;
