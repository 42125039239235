import {
  multiselectSearchableDropdownField,
  searchableDropdownField,
} from './commonFields';
import { formatDateField } from '../utils/TimeUtils';
import { regionOptions } from '../utils/VehicleUtils.js';
import { Link } from '@material-ui/core';
const operationPrompt = (operations) => {
  if (operations.length === 0) {
    return (
      <p style={{ fontWeight: 'bold', textAlign: 'center' }}>
        {' '}
        You have not added an operation yet.&nbsp;{' '}
        <Link to="/operations" style={{ color: 'black' }}>
          {' '}
          Add one here{' '}
        </Link>
      </p>
    );
  }
  return <></>;
};

export const getStepOneFields = (operations) => {
  return [
    {
      name: 'Vehicle Name',
      id: 'vehicleName',
      type: 'text',
    },
    {
      name: 'Country of Registration',
      id: 'country',
      required: true,
      type: 'select',
      options: Object.keys(regionOptions).map((loc) => ({
        name: loc,
        value: loc,
      })),
    },
    {
      name: 'Region',
      id: 'locations',
      required: true,
      type: 'select',
      disabled: true,
      optionsFilter: (fields) => {
        const countryField = fields.find((field) => field.id === 'country');
        return countryField.value !== ''
          ? regionOptions[countryField.value]
          : [];
      },
    },
    {
      name: 'Operation',
      id: 'operation',
      type: 'select',
      required: true,
      options: operations,
      validityCheck: (field) => field !== 'Select Operation',
    },
    {
      type: 'content',
      content: operationPrompt(operations),
    },
  ];
};

const getFormFields = (
  allPremises,
  setSelectedDestinationPID,
  vehicles,
  setSelectedVehicle,
  setSelectedDeparturePID,
  animals,
  setSelectedSheep,
  selectedSheep,
  handleSelectionChange,
  setTypedSheep,
) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Complete this form to transport animals between premises, if you do not have a premise, create one here:  `,
      LinkData: { label: 'Add a Premise', path: '/premises/create' },
    },
    {
      ...searchableDropdownField(
        'Departure PID',
        'departurePID',
        'premise',
        'pid',
        allPremises,
        'Search by PID...',
        setSelectedDeparturePID,
        'Fill out the PID and Select from the options',
        true,
      ),
    },
    {
      ...searchableDropdownField(
        'Destination PID',
        'destinationPID',
        'premise',
        'pid',
        allPremises,
        'Search by PID...',
        setSelectedDestinationPID,
        'Fill out the PID and Select from the options',
        true,
      ),
    },
    {
      name: 'Departure Date',
      id: 'departureDate',
      type: 'date',
      required: true,
      validity: true,
      value: formatDateField(new Date()),
      errorMessage: 'Departure Date cannot be in the future',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Departure Time',
      id: 'departureTime',
      type: 'time',
      required: true,
      validity: true,
      errorMessage: 'Departure Date cannot be in the future',
      value: new Date().toLocaleTimeString('en-US', {
        hour12: false,
        hour: 'numeric',
        minute: 'numeric',
      }),
      validityCheck: (fieldValues) => {
        const moveoutDateField = stepOneFields.find(
          (field) => field.id === 'departureDate',
        );
        const selectedDateTime = new Date(
          `${moveoutDateField.value} ${fieldValues.value}`,
        );
        return selectedDateTime < Date.now();
      },
    },
    {
      ...searchableDropdownField(
        'Vehicle Identification',
        'vehicle-search',
        'vehicle',
        'licensePlateNum',
        vehicles,
        'Search by Vehicle ID...',
        setSelectedVehicle,
        'Fill out the Vehicle ID and Select from the options',
        true,
      ),
    },
  ];

  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the transport details for the report`,
    },
    {
      ...multiselectSearchableDropdownField(
        'Animal IDs',
        'animals',
        'sheep',
        'tag.isoNumber',
        animals,
        'Search by Animal ID',
        setSelectedSheep,
        'Fill out the IDs of the animals, separate multiple values with a comma, or select your own',
        true,
        selectedSheep,
        handleSelectionChange,
        setTypedSheep,
      ),
    },
  ];

  const stepThreeFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the animal condition details for the report`,
    },
    {
      name: 'Last Access to Feed, Water and Rest Datetime',
      id: 'fwrDatetime',
      type: 'datetime-local',
      required: true,
      errorMessage:
        'FWR Datetime cannot be in the future, or after the departure date',
      validityCheck: (fieldValues) => {
        const departureDateField = stepOneFields.find(
          (field) => field.id === 'departureDate',
        );
        const departureTimeField = stepOneFields.find(
          (field) => field.id === 'departureTime',
        );
        const departureDateTime = new Date(
          `${departureDateField.value}T${departureTimeField.value}`,
        ).getTime();
        if (
          new Date(fieldValues.value).getTime() > Date.now() ||
          new Date(fieldValues.value) > new Date(departureDateTime)
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Last Access to Feed, Water and Rest Location',
      hint: '3 CherryTree Street',
      id: 'fwrAddress',
      type: 'text',
      required: true,
    },
    {
      name: 'Condition of Animals upon Arrival',
      hint: 'Description of animal condition',
      id: 'condition',
      type: 'textarea',
      maxLength: 100,
    },
  ];

  // Grouping steps into a single array
  const addTransportFields = [
    {
      name: 'Transport Information',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
    {
      name: 'Animal Identification',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'At least one animal ID is required.',
    },
    {
      name: 'Animal Condition Information',
      fields: stepThreeFields,
      isValid: true,
      errorMessage: 'Required Fields Missing',
    },
  ];
  return addTransportFields;
};

export default getFormFields;
