// SheepDetailView.js
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { useParams } from 'react-router';
import { COLORS } from '../../utils/Constants';
import axios from 'axios';
import {
  ContentContainer,
  ToLink,
  LinkButton,
  HorizontalContainer,
  VerticalContainer,
  OldBackLink,
} from '../../components/CommonComponents';
import Table from '../../components/Table.js';
import { localTimeFormatter, localDateFormatter } from '../../utils/TimeUtils';

const fetchSheepInfo = async (id) => {
  try {
    const request = await axios.get(`/api/sheep/${id}?premise=true`);
    const sheepInfo = request.data;
    return sheepInfo;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
};

const fetchOperationInfo = async (id) => {
  try {
    const request = await axios.get(`/api/operations/${id}`);
    const operationInfo = request.data;
    return operationInfo;
  } catch (err) {
    console.log(err);
    throw new Error(
      `An error occurred fetching operation info: ${err.message}`,
    );
  }
};

const Info = ({ name, value }) => {
  return (
    <div>
      <span>{name}:&nbsp;</span>
      <span>{value || 'N/A'}</span>
    </div>
  );
};

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any,
};

const SheepDetailView = () => {
  const [sheep, setSheep] = useState();
  const [documents, setDocuments] = useState([]);
  const [medicalReports, setMedicalReports] = useState([]);
  const [moveReports, setMoveReports] = useState([]);
  const [relatedVehicles, setRelatedVehicles] = useState([]);
  const [importReports, setImportReports] = useState([]);
  const [exportReports, setExportReports] = useState([]);
  const [transportReports, setTransportReports] = useState([]);
  const [replaceReports, setReplaceReports] = useState([]);
  const [editReports, setEditReports] = useState([]);
  const [inactivityData, setInactivityData] = useState();
  const [ownership, setOwnership] = useState(null);
  const history = useHistory();

  const params = useParams();
  const id = params.id;

  const fetchAndSetReport = async (endpoint, stateSetter) => {
    try {
      const request = await axios.get(endpoint);
      const reports = request.data;

      stateSetter(reports);
    } catch (err) {
      if (err.response && err.response.data) {
        toast.error(err.response.data.message);
        console.error(err);
      }
      // If the request fails, leave it blank
      return [];
    }
  };

  const fetchAndSetOwnership = async (id) => {
    try {
      const request = await axios.get(`/api/users/${id}`);
      const operationInfo = request.data;
      return operationInfo;
    } catch (err) {
      console.log(err);
      throw new Error(`An error occurred fetching user info: ${err.message}`);
    }
  };

  useEffect(() => {
    (async () => {
      let fetchedSheepInfo = await fetchSheepInfo(id);
      const fetchedOperationInfo = await fetchOperationInfo(
        fetchedSheepInfo.premise.operation._id,
      );
      fetchedSheepInfo.premise.operation = fetchedOperationInfo;
      setSheep(fetchedSheepInfo);

      // Retrieve Username of Owner
      let fetchedOwnershipInfo = await fetchAndSetOwnership(
        fetchedSheepInfo.premise.operation.users[0],
      );

      setOwnership(fetchedOwnershipInfo.username);
      const isonum = fetchedSheepInfo.tag.isoNumber;

      const reportEndpoints = [
        { setter: setDocuments, url: `/api/document?isonum=${isonum}` },
        { setter: setMedicalReports, url: `/api/animalmedical/sheep/${id}` },
        { setter: setMoveReports, url: `/api/move/sheep/${id}` },
        { setter: setRelatedVehicles, url: `/api/vehicles/sheep/${id}` },
        { setter: setImportReports, url: `/api/import/sheep/${id}` },
        { setter: setExportReports, url: `/api/animalexport/sheep/${id}` },
        { setter: setTransportReports, url: `/api/transport/sheep/${id}` },
        { setter: setReplaceReports, url: `/api/tagreplacement/sheep/${id}` },
        { setter: setEditReports, url: `api/sheep/edit/sheep/all/${id}` },
      ];

      // Set the report states
      await Promise.all(
        reportEndpoints.map((endpoint) =>
          fetchAndSetReport(endpoint.url, endpoint.setter),
        ),
      );

      if (fetchedSheepInfo.active) {
        return;
      }

      const fetchedSheepReports = (
        await axios.get(`api/sheepreports/sheep/${id}`)
      ).data;
      const fetchedRetireReports = (
        await axios.get(
          `api/tagretirement/?animals=${
            fetchedSheepInfo.tag.isoNumber ||
            fetchedSheepInfo.tag.usScrapieId ||
            fetchedSheepInfo.tag.localMgmtNumber ||
            fetchedSheepInfo.tag.tattooNumber
          }`,
        )
      ).data;

      const retireReport = fetchedRetireReports[0];
      const sheepReport = fetchedSheepReports.find(
        (r) => r.action === 'SLAUGHTER' || r.action === 'CARCASSDISPOSAL',
      );

      if (!retireReport) {
        toast.error(
          'This sheep is missing a tag retirement report. Contact an administrator for help',
        );
        return;
      }

      if (!sheepReport) {
        toast.error(
          'This sheep is missing a disposal or slaughter report. Contact an administrator for help',
        );
        return;
      }

      const date = new Date(
        retireReport?.dateOfDisposal || sheepReport.createdAt,
      );
      const friendlyDate = `${date.getUTCDate()}/${
        date.getUTCMonth() + 1
      }/${date.getUTCFullYear()}`;

      const slaughtered = sheepReport.action == 'SLAUGHTER';
      setInactivityData({
        ...sheepReport,
        ...retireReport,
        slaughtered,
        header: `This sheep was ${
          slaughtered ? 'slaughtered' : 'disposed of'
        } on ${friendlyDate}`,
      });
    })();
  }, []);

  const handleDocumentSelect = (doc) => async () => {
    try {
      // eslint-disable-next-line
      const response = await axios.post('/api/document/download', {
        isonum: doc.isonum,
        filename: doc.document,
      });
    } catch (err) {
      if (err.response && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(`Unable to fetch document "${doc.title}" at this time`);
      }
    }
  };

  const moveInOutColumns = [
    { name: 'Type', id: 'moveOut' },
    { name: 'Departure', id: 'departurePID' },
    { name: 'Destination ', id: 'destinationPID' },
    { name: 'Vehicle', id: 'vehicleNumber' },
    { name: 'Date Created', id: 'departureTime' },
  ];
  const transportColumns = [
    { name: 'Departure Premise ID', id: 'departurePID' },
    { name: 'Destination Premise ID', id: 'destinationPID' },
    { name: 'Number of Sheep Transported', id: 'count' },
    { name: 'Date Created', id: 'departureDate' },
  ];
  const importColumns = [
    { name: 'Exporting Country', id: 'exportingCountry' },
    { name: 'License Plate Number', id: 'vehicleIdentification' },
    { name: 'Date of Arrival', id: 'dateOfArrival' },
  ];
  const exportColumns = [
    { name: 'Importing Country', id: 'region' },
    { name: 'License Plate Number', id: 'vehicle' },
    { name: 'Date Created', id: 'arrivalTime' },
  ];
  const tagReplacementColumns = [
    { name: 'New CSIP Number', id: 'newIsoNumber' },
    { name: 'New Local Management Number', id: 'newLocalMgmtNumber' },
    { name: 'New Tattoo Number', id: 'newTattooNumber' },
    { name: 'New US Scrapie ID', id: 'newUsScrapieId' },
    { name: 'Date Created', id: 'createdAt' },
  ];
  const sheepEditColumns = [
    { name: 'New Birth Date', id: 'newBirthDate' },
    { name: 'New Gender', id: 'newGender' },
    { name: 'New Sub-Gender', id: 'newSubGender' },
    { name: 'New Breed', id: 'newBreed' },
    { name: 'Date Created', id: 'createdAt' },
  ];

  const tables = [
    {
      name: 'Move-in and Move-out Reports',
      type: 'move-in or move-out',
      data: moveReports.map((r) => ({
        ...r,
        moveOut: r.moveOut ? 'Move-Out' : 'Move-In',
        departureTime: localTimeFormatter(r.createdAt),
      })),
      columns: moveInOutColumns,
    },
    {
      name: 'Associated Transport Reports',
      type: 'transport',
      data: transportReports.map((r) => ({
        ...r,
        count: r.animals.length,
        departureDate: localTimeFormatter(r.createdAt),
      })),
      columns: transportColumns,
    },
    {
      name: 'Associated Import Reports',
      type: 'import',
      data: importReports.map((r) => ({
        ...r,
        dateOfArrival: localTimeFormatter(r.createdAt),
      })),
      columns: importColumns,
    },
    {
      name: 'Associated Export Reports',
      type: 'export',
      data: exportReports.map((r) => ({
        ...r,
        arrivalTime: localTimeFormatter(r.createdAt),
      })),
      columns: exportColumns,
    },
    {
      name: 'Associated Tag Replacement Reports',
      type: 'tag replacement',
      data: replaceReports.map((r) => ({
        ...r,
        createdAt: localTimeFormatter(r.createdAt),
      })),
      columns: tagReplacementColumns,
    },
    {
      name: 'Associated Sheep Edit Reports',
      type: 'sheep edit',
      data: editReports.map((r) => ({
        ...r,
        newBirthDate: r.birthdate ? r.birthdate.split('T')[0] : 'N/A',
        createdAt: localTimeFormatter(r.createdAt),
      })),
      columns: sheepEditColumns,
    },
  ];

  return (
    <VerticalContainer>
      <VerticalContainer align_items="flex-start" width="100%">
        <h1 style={{ fontSize: 'xxx-large' }}>Sheep</h1>
        <OldBackLink onClick={history.goBack}>&larr; Back</OldBackLink>
        <HorizontalContainer
          width="100%"
          justify_content="flex-start"
          align_items="flex-start"
          mobile="none;"
        >
          <HorizontalContainer
            width="100%"
            justify_content="flex-start"
            align_items="flex-start"
            tablet="flex-direction: column;"
          >
            <h1 style={{ marginRight: 'auto' }}>Sheep Details</h1>

            {sheep &&
              ((sheep.tag.isoNumber && (
                <h1 style={{ color: COLORS.primary }}>
                  CSIP #: {sheep.tag.isoNumber}
                </h1>
              )) ||
                (sheep.tag.localMgmtNumber && (
                  <h1 style={{ color: COLORS.primary }}>
                    Local Management #: {sheep.tag.localMgmtNumber}
                  </h1>
                )) ||
                (sheep.tag.tattooNumber && (
                  <h1 style={{ color: COLORS.primary }}>
                    Tattoo #: {sheep.tag.tattooNumber}
                  </h1>
                )) ||
                (sheep.tag.usScrapieId && (
                  <h1 style={{ color: COLORS.primary }}>
                    US Scrapie ID: {sheep.tag.usScrapieId}
                  </h1>
                )))}
          </HorizontalContainer>
        </HorizontalContainer>
        <StyledHr />
      </VerticalContainer>
      <HorizontalContainer
        width="100%"
        justify_content="flex-start"
        align_items="flex-start"
        tablet="flex-direction: column;"
      >
        <ContentContainer
          $stylePreset="light"
          width="35%"
          mr="5%"
          tablet="width: 100%;"
        >
          <InfoHeader>Animal Information</InfoHeader>
          {sheep && (
            <>
              <Info name="CSIP Number" value={sheep.tag.isoNumber} />
              <Info
                name="Local Management Number"
                value={sheep.tag.localMgmtNumber}
              />
              <Info name="Tattoo Number" value={sheep.tag.tattooNumber} />
              <Info name="US Scrapie ID" value={sheep.tag.usScrapieId} />
              <Info
                name="Date of Birth"
                value={
                  sheep.birthdate ? localDateFormatter(sheep.birthdate) : 'N/A'
                }
              />
              <Info name="Gender" value={sheep.gender} />
              <Info name="Breed" value={sheep.breed} />
              <Info name="Ownership" value={ownership || 'N/A'} />
              <Info
                name="Create At"
                value={localTimeFormatter(sheep.createdAt)}
              />
              <LinkButton to={`/sheep/edit?id=${sheep._id}`}>
                Edit Sheep
              </LinkButton>
            </>
          )}
          <StyledHr />
          <InfoHeader>Past IDs</InfoHeader>
          {replaceReports.length > 0 ? (
            <ul>
              {replaceReports.map((report) => (
                <li key={report._id}>CSIP Number: {report.oldIsoNumber}</li>
              ))}
            </ul>
          ) : (
            <p>There are no previous IDs associated with this sheep.</p>
          )}
          <StyledHr />
          <InfoHeader>Premise Information</InfoHeader>
          {sheep && (
            <>
              <Info name="Name" value={sheep.premise.name} />
              <Info name="Address" value={sheep.premise.address} />
              <Info name="Municipality" value={sheep.premise.municipality} />
              <Info name="Postal Code" value={sheep.premise.postalCode} />
              <Info name="Province" value={sheep.premise.province} />
              <Info name="Premise ID" value={sheep.premise.pid} />
            </>
          )}
          <StyledHr />
          <InfoHeader>Operation Information</InfoHeader>
          {sheep && (
            <>
              <Info
                name="Business Name"
                value={sheep.premise.operation.businessName}
              />
              <Info
                name="Contact"
                value={sheep.premise.operation.phoneNumber}
              />
            </>
          )}
          <StyledHr />
          <InfoHeader>Associated Medical Reports</InfoHeader>
          {sheep && medicalReports ? (
            <>
              <p style={{ marginTop: 0 }}>
                This sheep has {medicalReports.length} medical reports.
              </p>
              {medicalReports.map((report) => (
                <React.Fragment key={report._id}>
                  <span>
                    Medicine: {report.medicine} —{' '}
                    {localTimeFormatter(report.createdAt)}
                  </span>
                  <br />
                </React.Fragment>
              ))}
            </>
          ) : (
            <p>There is no medical report associated with this sheep.</p>
          )}
          <StyledHr />
          {sheep && relatedVehicles ? (
            <>
              <HorizontalContainer justifyContent="left" align>
                <InfoHeader style={{ marginRight: 20 }}>
                  Associated Vehicles
                </InfoHeader>
                <ToLink
                  to={{
                    pathname: `/reports/movement/${sheep.tag.isoNumber}`,
                    state: { relatedVehicles: relatedVehicles },
                  }}
                  label="Related Movements"
                  style={{ marginBottom: 20 }}
                />
              </HorizontalContainer>
              <p style={{ marginTop: 0 }}>
                This sheep has been on {relatedVehicles.length} vehicle
                {relatedVehicles.length === 1 ? '' : 's'}:
              </p>
              {relatedVehicles.map((relatedVehicle) => (
                <HorizontalContainer
                  justify_content="space-between"
                  width="100%"
                  align
                  key={relatedVehicle}
                >
                  <span style={{ marginBottom: 5 }}>{relatedVehicle}</span>
                  <ToLink
                    to={{
                      pathname: `/reports/movement/vehicle/${relatedVehicle}`,
                    }}
                    label="Details"
                    style={{ marginBottom: 20 }}
                  />
                </HorizontalContainer>
              ))}
            </>
          ) : (
            <p>There is no vehicle associated with this sheep.</p>
          )}
          <StyledHr />
          <HorizontalContainer align>
            <InfoHeader style={{ marginRight: 20 }}>
              Related Documents
            </InfoHeader>
            <UploadButton
              to={`/sheep/document?id=${sheep && sheep._id}`}
              $stylePreset="dark"
            >
              Upload
            </UploadButton>
          </HorizontalContainer>
          {documents.length > 0 ? (
            documents.map((doc) => (
              <React.Fragment key={doc.title}>
                <InfoSubHeader>
                  <a href="#" onClick={handleDocumentSelect(doc)}>
                    {doc.title}
                  </a>
                </InfoSubHeader>
                <p style={{ marginTop: 0 }}>{doc.description}</p>
              </React.Fragment>
            ))
          ) : (
            <p>There are currently no related documents.</p>
          )}
        </ContentContainer>
        <VerticalContainer
          width="60%"
          justify_content="flex-start"
          align_items="flex-start"
          tablet="width: 100%;"
        >
          {inactivityData && (
            <ContentContainer>
              <h2 style={{ color: 'darkred', marginTop: 0 }}>
                {inactivityData.header}
              </h2>
              <table>
                <tr>
                  <InactivityTd>
                    {inactivityData.slaughtered
                      ? 'Abbatoir:'
                      : 'Location of disposal:'}
                  </InactivityTd>
                  <td>{inactivityData.disposalPID}</td>
                </tr>
                {inactivityData.departurePID && (
                  <tr>
                    <InactivityTd>Departure premise:</InactivityTd>
                    <td>{inactivityData.departurePID}</td>
                  </tr>
                )}
                {inactivityData.dateOfConvoyance && (
                  <tr>
                    <InactivityTd>Date of convoyance:</InactivityTd>
                    <td>{inactivityData.dateOfConvoyance}</td>
                  </tr>
                )}
                {inactivityData.vehicle && (
                  <tr>
                    <InactivityTd>
                      Transporting vehicle identifier:
                    </InactivityTd>
                    <td>{inactivityData.vehicle}</td>
                  </tr>
                )}
              </table>
            </ContentContainer>
          )}
          {tables.map((table) => (
            <React.Fragment key={table.name}>
              <h2>{table.name}</h2>
              {table.data && table.data.length > 0 ? (
                <Table
                  paginate={true}
                  maxShowing={5}
                  columns={table.columns.map((column) => column.name)}
                  rows={(() => {
                    let rows = [];

                    // Ensure the cells are in the right order
                    for (let datum of table.data) {
                      let cells = [];
                      for (let column of table.columns) {
                        cells.push(datum[column.id]);
                      }
                      rows.push(cells);
                    }

                    return rows.reverse();
                  })()}
                />
              ) : (
                <NoReports reportType={table.type} />
              )}
            </React.Fragment>
          ))}
        </VerticalContainer>
      </HorizontalContainer>
    </VerticalContainer>
  );
};

const InactivityTd = styled.td`
  font-weight: bold;
`;

const InfoHeader = styled.h2`
  color: ${COLORS.primary};
  margin-top: 0px;
  margin-bottom: 20px;
`;

const InfoSubHeader = styled.h3`
  color: ${COLORS.primary};
  margin-top: 0;
  margin-bottom: 0;
`;
const UploadButton = styled(LinkButton)`
  font-size: 1em;
  margin-top: 0;
  margin-bottom: 20px;
  height: 30px;
  min-width: 100px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledHr = styled.hr`
  width: 100%;
  grid-column: 1 / span 2;
  margin-block: 20px;
`;

const NoReports = ({ reportType }) => {
  return (
    <ContentContainer alignItems="center">
      <p>There are no {reportType} reports associated with this sheep.</p>
    </ContentContainer>
  );
};

NoReports.propTypes = {
  reportType: propTypes.any,
};

export default SheepDetailView;
