// NotificationRouter.js
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NotificationsView from '../views/NotificationsView';
import NotificationDetailView from '../views/NotificationDetailView';

const NotificationRouter = () => (
  <Switch>
    <Route exact path="/notifications">
      <NotificationsView />
    </Route>
    <Route
      exact
      path="/notifications/details/:id"
      component={NotificationDetailView}
    />
  </Switch>
);

export default NotificationRouter;
