export const getFieldValues = (fields) => {
  let values = {};
  fields.forEach((field) => {
    if (field.type === 'content' || field.disabled === true) return;
    values[field.id] = field.value;
  });
  return values;
};

export const checkFieldInputValidity = (field, fieldValues) => {
  if (field.validityCheck || field.required) {
    if (field.value === '') {
      return false;
    }
    if (field.validityCheck) {
      let check = field.validityCheck(field, fieldValues);
      field.validity = check.validity !== undefined ? check.validity : check;
      field.errorMessage = check.errorMessage || field.errorMessage;
      return field.validity;
    }
  }
  return true;
};

export const checkAndUpdateStepsValidity = (formFields, setValidForm) => {
  let allStepsAreValid = true;

  formFields.forEach((step) => {
    const stepIsValid = step.isValid;
    allStepsAreValid = allStepsAreValid && stepIsValid;
  });
  setValidForm(allStepsAreValid);
};

export const checkRequiredFieldsValidity = (fields) => {
  const requiredFields = fields.filter(
    (field) => field.type !== 'content' && field.required === true,
  );

  let isValid = true;
  for (let field of requiredFields) {
    if (!field.validity) {
      isValid = false;
      break;
    }
  }
  return isValid;
};

export const flattenForm = (formFields) => {
  // Flatten the formFields array
  const flattenedFields = formFields.flatMap((step) => step.fields);
  let fieldValues = getFieldValues(flattenedFields);
  fieldValues = Object.keys(fieldValues).reduce((acc, key) => {
    acc[key] = fieldValues[key] === undefined ? '' : fieldValues[key];
    return acc;
  }, {});

  if (fieldValues.hasOwnProperty('undefined')) {
    delete fieldValues.undefined;
  }
  return fieldValues;
};

export const nextStep = (formFields, stepIndex, currentStep) => {
  if (currentStep === formFields.length - 1) {
    let isValid = checkRequiredFieldsValidity(formFields[stepIndex].fields);
    return { isValid, step: stepIndex };
  }

  const nextStepValid = checkRequiredFieldsValidity(
    formFields[currentStep + 1].fields,
  );

  let setStep = stepIndex;
  let isValid = false;

  if (stepIndex < currentStep || nextStepValid) {
    setStep = stepIndex;
    isValid = checkRequiredFieldsValidity(formFields[stepIndex].fields);
  } else {
    setStep = currentStep + 1;
    isValid = checkRequiredFieldsValidity(formFields[currentStep + 1].fields);
  }
  return { isValid, step: setStep };
};
