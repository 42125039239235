export const COLORS = {
  dark: '#222222', //a very dark shade of gray
  primary: '#29741D', // a medium dark shade of green
  secondary: '#E9EFE9', // a very light shade of greenish gray for outlined buttons
  light: '#F7F8F8', // a very light shade of cyan gray
  text: '#1F341B', // a dark shade of green
  disabled: '#A9A9A9', // a medium light shade of gray color for disabled state
  selected: '#A4D3A2', // a light shade of green for selected items
  error: '#c6342c', // a dark red for error and delete items
};
export const CAProvinceHash = {
  AB: 'Alberta',
  BC: 'British Columbia',
  MB: 'Manitoba',
  NB: 'New Brunswick',
  NL: 'Newfoundland and Labrador',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  ON: 'Ontario',
  PE: 'Prince Edward Island',
  QC: 'Québec',
  SK: 'Saskatchewan',
  YT: 'Yukon',
};

export const getAllProvinceNames = () => Object.values(CAProvinceHash);
export const getAllProvinceAbbrv = () => Object.keys(CAProvinceHash);
export const getProvinceNameFromAbbrv = (abbrv) => CAProvinceHash[abbrv];

export const USAStateHash = {
  AL: 'Alabama',
  AK: 'Alaska',
  AS: 'American Samoa',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  MP: 'Northern Mariana Islands',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Islands',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const REPORT_TYPES = {
  IMPORT: 'Import',
  MOVE_IN: 'Move In',
  ADD: 'Add',
  EXPORT: 'Export',
  TRANSPORT: 'Transport',
  MOVE_OUT: 'Move Out',
  TAG_ACTIVATION: 'Tag Activation',
  TAG_REPLACEMENT: 'Tag Replacement',
  TAG_RETIREMENT: 'Tag Retirement',
};
export const OperationTypes = [
  {
    name: 'abattoir',
    description:
      'A slaughterhouse or abattoir is a facility where animals are killed for consumption as food products.',
    niceName: 'Abattoir (Slaughterhouse or Meat Packer)',
  },
  {
    name: 'accreditedVeterinarian',
    description:
      'A private veterinarian authorized by CFIA to deliver various programs, each function individually accredited.',
    niceName: 'Accredited Veterinarian',
  },
  {
    name: 'assemblyPoints',
    description:
      'Assembly point would mean an auction mart, assembly yard or other site where animals are temporarily collected to be sold or redirected to another site, but does not include an abattoir, farms, feedlots, community pastures, fairgrounds, artificial insemination units, zoo or the site of an event such as a rodeo or a circus.',
    niceName: 'Assembly Points',
  },
  {
    name: 'auctionOrLivestockSaleFacilities',
    description:
      'A company that buys and sells livestock by offering them up for bid, taking bids, and then selling the item to the highest bidder. These facilities may include assembly yards and feed lots.',
    niceName: 'Auction or Livestock Sale Facilities',
  },
  {
    name: 'backgrounder',
    description:
      'A livestock producer who buys or keeps animals in order to grow them larger and to make a profit.',
    niceName: 'Backgrounder',
  },
  {
    name: 'cfiaDistrictOffice',
    description:
      'Made up of CFIA officers and veterinarians who audit other livestock operations and/or submit information on behalf of other operation types.',
    niceName: 'CFIA District Office',
  },
  {
    name: 'communityPasture',
    description:
      'A community pasture would be considered land that is managed by or leased from the Government of Canada or a provincial government, or that is owned by, managed by or leased from a community pasture association, grazing association or a grazing cooperative and where animals from more than one farm are assembled and commingled.',
    niceName: 'Community Pasture',
  },
  {
    name: 'deadStockOperator',
    description:
      'An individual who collects dead animals for proper disposal and/or rendering.',
    niceName: 'Dead Stock Operator',
  },
  {
    name: 'exibitionOrRaceTracksOrPettingZoo',
    description: 'An event where livestock are exhibited, judged or displayed.',
    niceName: 'Exibition (Fair), Race Tracks, or Petting Zoo',
  },
  {
    name: 'exporter',
    description:
      'A business or individual responsible for shipping animals out of Canada.',
    niceName: 'Exporter',
  },
  {
    name: 'importer',
    description: 'A business or individual responsible for importing animals.',
    niceName: 'Importer',
  },
  {
    name: 'transporter',
    description: `An individual or business who transports animals.  A transporter may not necessarily own the animals. The proposed regulatory amendment would include a requirement to have information accompany the domestic movement of all regulated species (cattle, bison, sheep, goat, cervid and pig). This information would be required to be in a form that can be easily read by an inspector and the operator of the destination site receiving the animals or carcasses. This would not apply for movement of animals within a farm.
    While the form or media on which the information should be provided will not be prescribed within the regulations, the information that would be required to accompany movement of animals or carcasses would include:
    •	The premises identification number of the departure site;
    •	The premises identification number of the destination site;
    •	The number of animals or carcasses being transported and their species;
    •	The date and time that the animal or carcass left the departure site; and 
    •	The licence plate number, or other identification of the vehicle that transported the animal or carcass.
    Anyone who transports or moves an animal using a vehicle, from one site to another site, would be required to have information that accompanies those animals at all times until the destination site is reached. At that time, the livestock carrier would provide that information to the operator of the destination site. 
    It would be the responsibility of the livestock carrier to keep this information that accompanied the animal for at least two years.`,
    niceName: 'Transporter',
  },
  {
    name: 'feedlot',
    description:
      'A feedlot or feedyard is a type of animal feeding operation (AFO) which is used in factory farming for finishing  beef cattle prior to slaughter. Large beef feedlots are called Concentrated Animal Feeding Operations (CAFOs). They may contain thousands of animals in an array of pens.',
    niceName: 'Feedlot',
  },
  {
    name: 'industryOrganization',
    description:
      "An organization such as the Canadian Sheep Federation, Canadian Co-operative Wool Growers and Canadian Sheep Breeders' Association who may submit information for other operation types. They may or may not be a regulated party.",
    niceName: 'Industry Organization',
  },
  {
    name: 'inseminationOrEmbryoCollectionCentre',
    description:
      'A facility that inseminates or collects embryos from female sheep and may also gather semen from rams.',
    niceName: 'Insemination and/or Embryo Collection Centre',
  },
  {
    name: 'nonProducerParticipant',
    description:
      'An individual who wants to enter information into the system who is not a sheep producer. This may include third-party service providers in the form of individuals or programs auditing or managing data for regulated parties.',
    niceName: 'Non Producer Participant',
  },
  {
    name: 'packingPlant',
    description:
      'A facility that packs animal products. A packing plant may or may not slaughter or butcher the animals.',
    niceName: 'Packing Plant',
  },
  {
    name: 'pathologyLaboratory',
    description:
      'A Pathology Lab study and diagnose animal diseases. They may have animals on site to gather specimens for study for example blood or tissue.',
    niceName: 'Pathology Laboratory',
  },
  {
    name: 'portOfEntry',
    description: 'A place where animals may lawfully enter Canada.',
    niceName: 'Port of Entry',
  },
  {
    name: 'producer',
    description:
      'A single individual, family, community, corporation or a company who own, breed and raise cattle and/or other livestock.',
    niceName: 'Producer',
  },
  {
    name: 'programAdministrator',
    description:
      'An agency such as the Canadian Sheep Federation who delivers assurance or disease control programs which affix a designation to an animal, group of animals or premises. They may submit information for other operation types.',
    niceName: 'Program Administrator',
  },
  {
    name: 'quarantineFacility',
    description:
      'A place where sick or diseased animals are kept or taken to separate them from their herd until they can be diagnosed and cured, or where for biosecurity purposes animals must reside in isolation before moving into a biosecure facility or moved to another country.',
    niceName: 'Quarantine Facility',
  },
  {
    name: 'researchFacilities',
    description:
      'A place where research is carried out using animals to research drug and other medical applications of new veterinary medicine or practices.',
    niceName: 'Research Facilities',
  },
  {
    name: 'responsibleAdministrator',
    description:
      'The agency responsible for managing data on behalf of a sector and accountable to the CFIA for managing traceability data.  The responsible administratorwho may audit other livestock operations and/or submit information on behalf of other operation types.',
    niceName: 'Responsible Administrator',
  },
  {
    name: 'tagDealer',
    description:
      'A company that sells tags to individuals or other tag dealers. Tag Dealers must have an approved agreement with CCIA.',
    niceName: 'Tag Dealer',
  },
  {
    name: 'tagDistributor',
    description:
      'A company that sells tags to tag dealers or other tag distributors. Tag Distributors must have an approved agreement with CCIA.',
    niceName: 'Tag Distributor',
  },
  {
    name: 'tagManufacturer',
    description:
      'A company that manufactures CCIA RFID tags. They may also sell tags to tag distributors or individuals. Tag Manufacturers must have an approved agreement with CCIA. They pay CCIA for tag numbers that are allocated to them.',
    niceName: 'Tag Manufacturer',
  },
  {
    name: 'taggingSite',
    description:
      'An approved tagging site is any facility where the manager of the site has received prior approval to operate such a site and has given the undertakings required in the regulations. Only facilities that have requested and gained recognition as an approved tagging site are able provide this service. Operations such as auction markets, community pastures, bull test stations, or any other suitable facilities may apply to become approved tagging sites but are not required to offer this service.',
    niceName: 'Tagging Site',
  },
  {
    name: 'testStation',
    description:
      'The term "test station" applies to any site where performance or progeny tests are carried out.',
    niceName: 'Test Station',
  },
  {
    name: 'veterinaryHospitalAndLab',
    description:
      'A veterinary hospital is for long term, emergency and non emergency care with beds. A "hospital" is available 24 hours and staffed by either a veterinarian or para-professional vet tech at all times. A veterinary lab performs certain functions such as: Molecular Diagnosis and Finding of Pathogen, Biochemical Analysis of Blood Samples , Tests for Poultry Blood & Egg ,Serological Tests on Livestock Blood and Milk. Labs that conduct post-mortem examinations will be responsible for reporting carcass disposal.',
    niceName: 'Veterinary Hospital and Lab',
  },
  {
    name: 'zoo',
    description:
      'A facility in which animals are confined within enclosures, displayed to the public, and in which they may also be bred.',
    niceName: 'Zoo',
  },
];

export const getOperationTypeNiceName = (name) => {
  for (const operationType of OperationTypes) {
    if (operationType.name === name) {
      return operationType.niceName;
    }
  }
  return '';
};

export const getStateNameFromAbbrv = (abbrv) => USAStateHash[abbrv];
export const getAllStateNames = () => Object.values(USAStateHash);
export const getAllStateAbbrv = () => Object.keys(USAStateHash);
