import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { getMoveLogs, getMoveLogsForVehicle } from '../../utils/MoveUtils';
import VehicleRelatedMovesComponent from '../../components/VehicleRelatedMovesComponent';
import { VehicleDetailsCard } from '../../components/VehicleDetailsCard.js';
import PageCard from '../../components/PageCard';
import { getVehicleById } from '../../utils/VehicleUtils';
import { localTimeFormatter } from '../../utils/TimeUtils';

export default function VehicleView(props) {
  const history = useHistory();
  const vehicleID = props.match.params.vehicleId;
  const [vehicle, setVehicle] = useState(null);

  const moveOutColumns = [
    {
      id: 'type',
      name: 'Type',
    },
    {
      id: 'departureDate',
      name: 'Date of Departure',
    },
    {
      id: 'departurePremise',
      name: 'Departure',
    },
    {
      id: 'destinationPremise',
      name: 'Destination',
    },
    {
      id: 'animalTags',
      name: 'Moved Out Animals',
    },
    {
      id: 'actions',
      name: 'Actions',
    },
  ];

  const deleteAndRedirect = async () => {
    try {
      await deleteVehicleById(vehicle.id);
      setRedirect('/vehicles');
    } catch (err) {
      toast.error('Vehicle could not be deleted: ' + err.message);
    }
  };

  const fetchSetVehicle = async () => {
    const fetchedVehicle = await getVehicleById(vehicleID);
    const transformedVehicle = {
      id: fetchedVehicle._id,
      name: fetchedVehicle.name ? fetchedVehicle.name : 'N/A',
      location: fetchedVehicle.stateProvince
        ? fetchedVehicle.stateProvince
        : [...fetchedVehicle.provinces, ...fetchedVehicle.states].join(''),
      licensePlateNum: fetchedVehicle.licensePlateNum,
      operation: fetchedVehicle.operation
        ? fetchedVehicle.operation.businessName
        : 'N/A',
    };
    setVehicle(transformedVehicle);
    return transformedVehicle;
  };

  const fetchAndTransformMoveLogs = async () => {
    const fetchedVehicle = await fetchSetVehicle();
    const response = await getMoveLogsForVehicle(
      fetchedVehicle.licensePlateNum,
    );
    const movements = response.data;
    let styledMovements = movements.map((moveLog) => ({
      ...moveLog,
      departurePremise: moveLog.departurePID ? moveLog.departurePID : '',
      destinationPremise: moveLog.destinationPID ? moveLog.destinationPID : '',
      departureDate: moveLog.departureTime
          ? localTimeFormatter(moveLog.departureTime)
          : '',
      loadTime: moveLog.loadTime
      ? localTimeFormatter(moveLog.loadTime)
      : '',
      receivedTime: moveLog.receivedTime
          ? localTimeFormatter(moveLog.receivedTime)
          : '',
      type: moveLog.moveOut ? 'Move-Out' : 'Move-In',
    }));
    return styledMovements;
  };
  const customBack = () => {
    history.goBack();
  };

  useEffect(() => {
    (async () => {
      await fetchSetVehicle();
    })();
  }, []);

  return (
    <PageCard title={`Vehicle Details`} back line onBack={customBack}>
      {vehicle && (
        <VehicleDetailsCard data={vehicle} deleteVehicle={deleteAndRedirect} />
      )}
      {vehicle && (
        <VehicleRelatedMovesComponent
          title="Move History"
          description="Here is a list of movements history for the related vehicles of a specific sheep."
          fetchReportsFunction={fetchAndTransformMoveLogs}
          detailEndpoint={{
            moveout: '/reports/moveout/',
            movein: '/reports/movein/',
          }}
          columns={moveOutColumns}
          vehicleNumber={vehicle.licensePlateNum}
        />
      )}
    </PageCard>      
  );
}
