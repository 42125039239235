import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import DescriptionCard from '../../components/DescriptionCard.js';
import { deleteVehicleById } from '../../utils/VehicleUtils.js';
import {
  LinkButton,
  Icon,
  Grid,
  ContentContainer,
  VerticalContainer,
  MessageField,
  FormSelectInput,
} from '../../components/CommonComponents.js';
import { DashboardDeleteModal } from '../../components/DashboardDeleteModal.js';
import { PageHeader } from '../../components/PageComponents.js';
import add from '../../images/icons/add.png';
import vehicleIcon from '../../images/icons/vehicle.png';
import DashboardCard from '../../components/reusedComponents/DashboardCard.js';
import { FetchVehicles } from '../../components/SheepReportComponents.js';
import SearchBar from '../../components/SearchBar.js';
import { dynamicSortAlpha } from '../../utils/ListUtils.js';
import Loader from 'react-loader-spinner';
import PageCard from '../../components/PageCard.js';
import { agGreen } from '../../components/Colors';
import { toast } from 'react-toastify';

const VehiclesView = () => {
  const [vehicles, setVehicles] = useState([]);
  const [redirect, setRedirect] = useState('');
  const [windowSize, setWindowSize] = useState(0);
  const [sortField, setSortField] = useState('name');
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItem, setSelectedItem] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchVehicles = async () => {
    setLoading(true);
    const fetchedVehicles = await FetchVehicles();
    const transformedVehicles = fetchedVehicles.map((v) => {
      return {
        id: v._id,
        name: v.name ? v.name : 'N/A',
        location: v.stateProvince
          ? v.stateProvince
          : [...v.provinces, ...v.states].join(''),
        licensePlateNum: v.licensePlateNum,
        operation: v.operation ? v.operation.businessName : 'N/A',
      };
    });
    setVehicles(transformedVehicles.sort(dynamicSortAlpha(sortField)));
    setLoading(false);
  };

  useEffect(() => {
    fetchVehicles();
  }, [sortField]);

  useEffect(() => {
    if (window.innerWidth !== windowSize) {
      setWindowSize(window.innerWidth);
    }
  }, [windowSize]);

  if (redirect !== '') {
    return <Redirect push to={redirect} />;
  }

  const deleteVehicle = async (item) => {
    if (item) {
      try {
        await deleteVehicleById(item);
        await fetchVehicles();
        setSelectedItem(null);
        setIsModalVisible(false);
        toast.success('Vehicle deleted Successfully');
      } catch (error) {
        toast.error('Failed to delete vehicle. Please try again.');
      }
    }
  };

  const showDeleteModal = (_id) => {
    setSelectedItem(_id);
    setIsModalVisible(true);
  };

  const viewVehicle = ({ _id }) => {
    setRedirect(`/vehicles/${_id}`);
  };

  const editVehicle = ({ _id }) => {
    setRedirect(`/vehicles/${_id}/update`);
  };

  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSort = (event) => {
    const newSortField = event.target.value;
    setSortField(newSortField);
    setVehicles(vehicles.sort(dynamicSortAlpha(newSortField)));
  };

  const filteredVehicles = vehicles.filter((vehicle) => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    return (
      vehicle.licensePlateNum?.toLowerCase().includes(lowerCaseQuery) ||
      vehicle.name?.toLowerCase().includes(lowerCaseQuery) ||
      vehicle.location?.toLowerCase().includes(lowerCaseQuery) ||
      vehicle.operation?.toLowerCase().includes(lowerCaseQuery)
    );
  });

  const description =
    'Animal movement reports need to include information about conveyance vehicles used in the movement event.' +
    ' You can create a list of your commonly used conveyance vehicles here' +
    ' to save time when completing movement reports later.' +
    ' Vehicles can be identified by license plate numbers, VIN (vehicle identification numbers)' +
    ' or easily recognizable physical description.';

  return (
    <>
      {loading ? (
        <PageCard
          title="Loading your vehicles..."
          loader={
            <Loader type="Oval" color={agGreen} height={50} width={150} />
          }
        />
      ) : (
        <VerticalContainer align_items="stretch" justify_content="flex-start">
          <PageHeader style={{ margin: '34px 0px 0px' }}>Vehicles</PageHeader>
          <DescriptionCard
            page="Vehicle"
            content={description}
            icon={vehicleIcon}
          ></DescriptionCard>
          <h2>Add a new Vehicle</h2>
          <LinkButton $stylePreset="dark" to="/vehicles/create">
            <span>Add</span> <Icon width="20px" m="0px" src={add} />
          </LinkButton>
          <br />
          <ContentContainer
            $stylePreset={'light'}
            mb="30px"
            style={{
              flexDirection: 'row',
              gap: '30px 20px',
              width: 'fit-content',
            }}
          >
            <VerticalContainer style={{ alignContent: 'right' }}>
              <h2>Search for a Vehicle</h2>
              <SearchBar
                placeholder="Search for a Vehicle"
                value={searchQuery}
                onChange={handleSearch}
                containerStyle={{ marginBottom: '20px' }}
              />
            </VerticalContainer>
            <VerticalContainer style={{ alignContent: 'right' }}>
              <h2>Sort List</h2>
              <FormSelectInput
                name="sort field"
                value={sortField}
                onChange={handleSort}
                style={{ marginBottom: '20px' }}
              >
                <option value="name">Vehicle Name</option>
                <option value="licensePlateNum">Vehicle ID</option>
                <option value="location">Province/State</option>
                <option value="operation">Operation</option>
              </FormSelectInput>
            </VerticalContainer>
          </ContentContainer>
          {filteredVehicles.length === 0 ? (
            <MessageField>
              It looks like you don&apos;t have any vehicles at the moment.
            </MessageField>
          ) : (
            <>
              <Grid
                templateColumns="repeat(auto-fill, minmax(320px, 1fr))"
                gap="30px 20px"
              >
                {filteredVehicles.map((data) => (
                  <DashboardCard
                    data={data}
                    viewItem={viewVehicle}
                    editItem={editVehicle}
                    deleteItem={() => {
                      showDeleteModal(data.id);
                    }}
                    key={data.id}
                    cardTitle="Vehicle Name"
                    additionalFields={[
                      { label: 'Vehicle ID', key: 'licensePlateNum' },
                      { label: 'Province/State', key: 'location' },
                      { label: 'Operation', key: 'operation' },
                    ]}
                  />
                ))}
              </Grid>
            </>
          )}
          {isModalVisible && (
            <DashboardDeleteModal
              selectedItem={selectedItem}
              cancel={() => {
                setSelectedItem(false);
                setIsModalVisible(false);
              }}
              deleteItem={deleteVehicle}
              itemName={'vehicle'}
            />
          )}
        </VerticalContainer>
      )}
    </>
  );
};

export default VehiclesView;
