// SlaughterReportListView.js
import React, { useState, useEffect } from 'react';
import TableComponent from '../../components/TableComponent';
import PageCard from '../../components/PageCard.js';
import { getSlaughterReports } from '../../utils/SlaughterReportUtils';

const SlaughterReportListView = () => {
  const [slaughterReports, setSlaughterReports] = useState([]);

  const fetchSlaughterReports = async () => {
    const result = await getSlaughterReports();
    setSlaughterReports(result);
  };

  useEffect(() => {
    fetchSlaughterReports();
  }, []);

  const reportFields = [
    { name: 'Departure Premise', id: 'departurePremiseID' },
    { name: 'Destination Premise', id: 'destinationPremiseID' },
    { name: 'Departure Date / Time', id: 'departureDateTime' },
    { name: 'Death Date / Time', id: 'deathDateTime' },
    { name: 'Animal ID', id: 'animalID' },
    { name: 'Vehicle ID', id: 'vehicleID' },
  ];

  return (
    <PageCard title="Slaughter Reports" to="/slaughterReport/create" back>
      <TableComponent headings={reportFields} data={slaughterReports} actions />
    </PageCard>
  );
};

export default SlaughterReportListView;
