import React, {
  useRef,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { REPORT_TYPES } from '../../utils/Constants';
import { useHistory } from 'react-router-dom';
import { getSheep } from '../../utils/SheepUtils';
import ReportedAnimalsList from '../ReportedAnimalsList';
import FormHeader from './FormHeader.js';
import { COLORS } from '../../utils/Constants';

// Styled components for the card layout
const Card = styled.div`
  border-radius: 24px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: grid;
  grid-template-rows: auto 1fr; /* Header in row 1, details in row 2 */
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  background-color: #fff;
  cursor: ${(props) =>
    props.showReportedAnimals ? 'default' : 'pointer'}; /* Conditional cursor */
  transition: box-shadow 0.2s ease-in-out; /* Smooth transition for box-shadow */

  &:hover {
    box-shadow: ${(props) =>
      props.showReportedAnimals
        ? '2px 2px 12px rgba(0, 0, 0, 0.1)'
        : '4px 4px 24px rgba(0, 0, 0, 0.2)'}; /* Conditional shadow on hover */
  }
`;

const CardHeader = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 8px;
  margin-bottom: 16px;
`;

const ColumnStyleOne = styled.div`
  .info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .label {
      font-weight: bold;
      padding-right: 1em;
      color: #333; /* Dark grey for better readability */
    }

    .value {
      color: #333;
    }
  }

  background-color: #d0d9d2; /* Light green closer to gray */
  margin: 5px;
  padding: 20px;
  border-radius: 25px;
`;

const ColumnStyleTwo = styled.div`
  .info-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    .label {
      font-weight: bold;
      color: #333;
      padding-right: 1em;
    }

    .value {
      color: #333;
    }
  }
`;

const StyledReportedAnimalsListWrapper = styled.div`
  grid-column: 1 / -1; /* Ensure it spans all columns */
  grid-row: 2; /* Ensure it spans the details row */
`;

const Label = styled.div`
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  color: white;
  background-color: black;
  font-weight: bold;
  text-transform: uppercase;
`;
const ViewButton = styled.button`
  background-color: transparent;
  color: ${COLORS.primary}; /* Green text color */
  border: 2px solid ${COLORS.primary}; /* Green border */
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${COLORS.primary}; /* Green background on hover */
    color: white;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(40, 167, 69, 0.5);
  }
`;

/**
 * Component for displaying a sheep report card with detailed information.
 * @param {object} props - Component props
 * @param {object} props.report - Data object containing information to display in the card
 * @param {function} props.onVisible - Callback function triggered when the card becomes visible
 */
const SheepReportCard = ({ sheepID, report, onVisible }) => {
  const cardRef = useRef(null);
  const history = useHistory();
  const [showReportedAnimals, setShowReportedAnimals] = useState(false); // Loading state
  const [reportedAnimals, setReportedAnimals] = useState([]); // Loading state
  const animalsCount = report.reported_animals_ids?.length || 0;
  const showViewButton = animalsCount > 1;

  const getPath = (report) => {
    let type = report.type;
    let api_path = '';
    if (type === 'Tag Replacement') {
      api_path = `reports/replace/view`;
    } else if (type === 'Tag Retirement') {
      api_path = 'reports/retire';
    } else if (type === 'Add') {
      api_path = 'sheep';
    } else {
      api_path = 'reports/' + type.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    }
    return api_path;
  };

  const handleCardClick = useCallback(() => {
    if (showReportedAnimals) {
      return;
    } else {
      onVisible(report.submitter);
      const detailsViewPath = getPath(report);
      if (report.type === 'Add') history.push(`/${detailsViewPath}/${sheepID}`);
      else history.push(`/${detailsViewPath}/${report.id}`);
    }
  });

  const handleCloseClick = () => {
    setShowReportedAnimals(false);
  };
  useEffect(() => {
    const handleScroll = () => {
      if (!cardRef.current) return;

      // Calculate the vertical end of the card
      const cardRect = cardRef.current.getBoundingClientRect();
      const cardVerticalEnd = cardRect.top + cardRect.height;

      // Calculate the vertical center of the viewport
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const viewportCenter = window.scrollY + viewportHeight / 2;

      // Check if the absolute difference between cardVerticalEnd and viewportCenter
      // is less than half of the viewport height
      if (Math.abs(cardVerticalEnd - viewportCenter) < viewportHeight / 2) {
        onVisible(report.submitter);
      }
    };

    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [onVisible, report.submitter]);

  const handleViewAnimalsClick = useCallback(() => {
    getReportedSheepListDetails(report.reported_animals_ids);
  });

  const getReportedSheepListDetails = async (sheepIds) => {
    try {
      setReportedAnimals([]);
      setShowReportedAnimals(true);

      for (const id of sheepIds) {
        try {
          const sheep = await getSheep(id, true); // Fetch sheep data
          if (sheep) {
            setReportedAnimals((prev) => [...prev, sheep]);
          } else {
            console.warn(`Sheep with ID ${id} not found.`);
          }
        } catch (err) {
          console.error(`Error fetching sheep with ID ${id}:`, err);
        }
      }
    } catch (err) {
      console.error('Error processing sheep list:', err);
    }
  };

  // Memoize keys computation
  const keys = useMemo(() => {
    return Object.keys(report).filter((key) => {
      const field = report[key];
      return (
        field &&
        typeof field === 'object' && // Check if the field is an object
        field.value !== null && // Ensure the value is not null
        Object.prototype.hasOwnProperty.call(field, 'label') // Check if the object has a 'label' property
      );
    });
  }, [report]);

  // Split remaining keys into two halves for two columns Report Display
  const halfLength = Math.ceil(keys.length / 2);
  const firstColumnKeys = keys.slice(0, halfLength);
  const secondColumnKeys = keys.slice(halfLength);
  return (
    <Card
      ref={cardRef}
      showReportedAnimals={showReportedAnimals}
      onClick={handleCardClick}
    >
      <CardHeader>
        <Label>{report.type}</Label>
      </CardHeader>
      {!showReportedAnimals ? (
        <>
          {/* First column of information items */}
          <ColumnStyleOne type={report.type}>
            {firstColumnKeys.map((key) => (
              <div className="info-item" key={key}>
                <span className="label">{report[key].label}:</span>
                <span className="value">{report[key].value}</span>
              </div>
            ))}
          </ColumnStyleOne>
          {/* Second column of information items */}
          <ColumnStyleOne>
            <ColumnStyleTwo>
              {secondColumnKeys.map((key) => (
                <div className="info-item" key={key}>
                  <span className="label">{report[key].label}:</span>
                  <span className="value">{report[key].value}</span>
                </div>
              ))}
              {/* Button to view animals in report */}
              {showViewButton && (
                <ViewButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewAnimalsClick();
                  }}
                >
                  View All Reported Animals ({animalsCount}){' '}
                </ViewButton>
              )}
            </ColumnStyleTwo>
          </ColumnStyleOne>
        </>
      ) : (
        <StyledReportedAnimalsListWrapper>
          <FormHeader
            title={' Reported Animals'}
            onCancel={(e) => {
              e.stopPropagation();
              handleCloseClick();
            }}
          ></FormHeader>

          <ReportedAnimalsList sheep={reportedAnimals} />
        </StyledReportedAnimalsListWrapper>
      )}
    </Card>
  );
};

SheepReportCard.propTypes = {
  report: PropTypes.object.isRequired,
  onVisible: PropTypes.func.isRequired,
};

export default SheepReportCard;
