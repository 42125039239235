import React from 'react';
import SheepMultiSelector from '../components/SheepMultiSelector';
import TreatmentAdder from '../components/TreatmentAdder';
import { formatDateField } from '../utils/TimeUtils';
const getFormFields = (premises, treatments, setTreatments) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Complete this form to file a medical report for your animals, if you do not have any animals create one here:  `,
      LinkData: { label: 'Add an Animal', path: '/sheep/create/manual/' },
    },
    {
      name: 'Premise',
      id: 'premise',
      required: true,
      type: 'select',
      options: premises,
    },
  ];

  // Step 2 fields
  const stepTwoFields = [
    {
      type: 'content',
      content: (
        <TreatmentAdder treatments={treatments} setTreatments={setTreatments} />
      ),
    },
    {
      name: 'Reason for treatment',
      id: 'treatmentReason',
      required: true,
      type: 'text',
    },
    {
      name: 'First treatment date',
      id: 'firstTreatmentDate',
      type: 'date',
      value: formatDateField(new Date()),
      validity: true,
      required: true,
      errorMessage:
        'First Treatment Date cannot be in the future, or after the final treatment date',
      validityCheck: (fieldValues) => {
        const finalDateField = stepTwoFields.find(
          (field) => field.id === 'finalTreatmentDate',
        );
        if (
          new Date(fieldValues.value).getTime() > Date.now() ||
          new Date(fieldValues.value) > new Date(finalDateField.value)
        ) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Final treatment date',
      id: 'finalTreatmentDate',
      type: 'date',
      value: formatDateField(new Date()),
      validity: true,
      required: true,
      errorMessage:
        'Final Treatment Date cannot be in the future, or before the first treatment date',
      validityCheck: (fieldValues) => {
        const firstDateField = stepTwoFields.find(
          (field) => field.id === 'firstTreatmentDate',
        );
        if (
          new Date(fieldValues.value).getTime() > Date.now() ||
          new Date(fieldValues.value) < new Date(firstDateField.value)
        ) {
          return false;
        }
        return true;
      },
    },
  ];

  // Step 3 fields
  const stepThreeFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: 'Enter the sheep details here: ',
    },
    {
      name: 'Estimated sheep weight',
      id: 'animalWeight',
      required: true,
      type: 'text',
    },
    {
      name: 'Meat withdrawal date',
      id: 'meatWithdrawalDate',
      type: 'date',
      required: true,
      value: formatDateField(new Date()),
      validity: true,
      errorMessage: 'Meat withdrawal date cannot be in the future.',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Milk withdrawal date',
      id: 'milkWithdrawalDate',
      type: 'date',
      required: true,
      value: formatDateField(new Date()),
      validity: true,
      errorMessage: 'Milk withdrawal date cannot be in the future.',
      validityCheck: (fieldValues) => {
        if (new Date(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
    {
      name: 'Location (Latitude, Longitude)',
      hint: 'Latitude, Longitude',
      id: 'location',
      type: 'location',
    },
  ];

  // Grouping steps into a single array
  const addMedicalFields = [
    {
      name: 'Animal Identification',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'At least one animal is required',
    },
    {
      name: 'Treatment Information',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'You must provide at least one treatment',
    },
    {
      name: 'Withdrawal Information',
      fields: stepThreeFields,
      isValid: false,
      errorMessage: 'Required fields are missing',
    },
  ];
  return addMedicalFields;
};

export const getFilteredSheep = (value, animals, handleAnimalSelect) => {
  return [
    {
      name: 'Select Sheep',
      type: 'content',
      id: 'sheepMultiSelector',
      required: true,
      content: (
        <SheepMultiSelector
          sheeps={animals.filter(
            (animal) => animal.premise && animal.premise.pid === value,
          )}
          onChange={(animal) => handleAnimalSelect(animal)}
        />
      ),
    },
  ];
};

export default getFormFields;
