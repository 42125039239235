import React from 'react';
import styled from 'styled-components';

const FileHints = ({ hints }) => (
  <>
    {hints.map((hint, idx) => (
      <FormHint key={idx}>{hint}</FormHint>
    ))}
  </>
);

const FormHint = styled.p`
  margin-top: 5px;
  color: orange;
  font-weight: bold;
  font-size: 12px;
  padding: 0 20px;
  text-align: center;
`;

export default FileHints;
