/* This is a temporary file, it will be deleted when the tag allocation 
module is completed and connected to the back-end / API. This is just for testing */

export const data = [
  {
    _id: 1,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '100',
    decisionDate: '2024-07-06',
    name: 'John Doe',
    email: 'johndoe@email.com',
    phoneNumber: '123456789010',
    status: 'Awaiting Approval',
    reason: '',
  },
  {
    _id: 2,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '75',
    decisionDate: '2024-07-05',
    name: 'John Doe',
    email: 'johndoe@email.com',
    phoneNumber: '123456789010',
    status: 'Awaiting Approval',
    reason: '',
  },
  {
    _id: 3,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '100',
    decisionDate: '2024-07-02',
    name: 'John Doe',
    email: 'johndoe@email.com',
    phoneNumber: '123456789010',
    status: 'Approved',
    reason: '',
  },
  {
    _id: 4,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Allflex',
    numberOfTags: '120',
    decisionDate: '2024-07-09',
    name: 'Jane Doe',
    email: 'janedoe@email.com',
    phoneNumber: '123456789010',
    status: 'Approved',
    reason: '',
  },
  {
    _id: 5,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Allflex',
    numberOfTags: '100',
    decisionDate: '2024-07-01',
    name: 'Jane Doe',
    email: 'janedoe@email.com',
    phoneNumber: '123456789010',
    status: 'Denied',
    reason: 'This was denied because it was denied...',
  },
  {
    _id: 6,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '100',
    decisionDate: '2024-05-28',
    name: 'Jane Doe',
    email: 'janedoe@email.com',
    phoneNumber: '123456789010',
    status: 'Denied',
    reason: 'This was denied because it was denied...',
  },
  {
    _id: 7,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '20',
    decisionDate: '2024-03-05',
    name: 'John Doe',
    email: 'johndoe@email.com',
    phoneNumber: '123456789010',
    status: 'Denied',
    reason: 'This was denied because it was denied...',
  },
  {
    _id: 8,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Allflex',
    numberOfTags: '25',
    decisionDate: '2024-07-03',
    name: 'Jane Doe',
    email: 'janedoe@email.com',
    phoneNumber: '123456789010',
    status: 'Approved',
    reason: '',
  },
  {
    _id: 9,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '30',
    decisionDate: '2024-06-06',
    name: 'Jane Doe',
    email: 'janedoe@email.com',
    phoneNumber: '123456789010',
    status: 'Approved',
    reason: '',
  },
  {
    _id: 10,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '100',
    decisionDate: '2024-07-08',
    name: 'Jane Doe',
    email: 'janedoe@email.com',
    phoneNumber: '123456789010',
    status: 'Awaiting Approval',
    reason: '',
  },
  {
    _id: 11,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Allflex',
    numberOfTags: '100',
    decisionDate: '2024-07-03',
    name: 'John Doe',
    email: 'johndoe@email.com',
    phoneNumber: '123456789010',
    status: 'Awaiting Approval',
    reason: '',
  },
  {
    _id: 12,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Allflex',
    numberOfTags: '60',
    decisionDate: '2024-06-22',
    name: 'John Doe',
    email: 'johndoe@email.com',
    phoneNumber: '123456789010',
    status: 'Approved',
    reason: '',
  },
  {
    _id: 13,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '100',
    decisionDate: '2024-07-06',
    name: 'John Doe',
    email: 'johndoe@email.com',
    phoneNumber: '123456789010',
    status: 'Approved',
    reason: '',
  },
];

export const queueData = [
  {
    _id: 1,
    pid: 'ON84973291',
    description: 'A medium amount of sheep',
    location: '74.943205, 55.12323489',
    manufacturer: 'Shearwell',
    numberOfTags: '100',
    decisionDate: '2024-07-09',
    name: 'Name Namerson',
    email: 'name@email.com',
    phoneNumber: '0987654321',
    status: 'Awaiting Approval',
    reason: '',
  },
  {
    _id: 2,
    pid: 'ON1234567',
    description:
      'Lots of sheep! So many. And they are so so happy. Also the premise is good.',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '75',
    decisionDate: '2024-07-03',
    name: 'John Doe',
    email: 'johndoe@email.com',
    phoneNumber: '123456789010',
    status: 'Awaiting Approval',
    reason: '',
  },
  {
    _id: 10,
    pid: 'ON543987923',
    description: 'This premise is okay. I like it',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Shearwell',
    numberOfTags: '100',
    decisionDate: '2024-07-05',
    name: 'Hello World',
    email: 'helloworld@email.com',
    phoneNumber: '83792438999',
    status: 'Awaiting Approval',
    reason: '',
  },
  {
    _id: 11,
    pid: 'ON1239844',
    description: 'Not so many sheep',
    location: '74.12351234, 49.345345342',
    manufacturer: 'Allflex',
    numberOfTags: '100',
    decisionDate: '2024-06-22',
    name: 'Sheep Farmer',
    email: 'sheepfarms@email.com',
    phoneNumber: '890834234720',
    status: 'Awaiting Approval',
    reason: '',
  },
];
