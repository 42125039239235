import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/Constants';
import { Button } from '../CommonComponents';

export const ConfirmationModal = ({
  message,
  subMessage = null,
  selectedItem,
  cancel,
  confirm,
  textSet = null,
  show = false,
}) => {
  const [input, setInput] = useState('');

  return (
    <>
      {show && (
        <ConfirmationModalContainer id="ConfirmationModal">
          <ConfirmationModalContent>
            <h1 style={{ textAlign: 'center' }}>{message}</h1>
            {subMessage ? (
              <h3 style={{ textAlign: 'center' }}>{subMessage}</h3>
            ) : null}
            {textSet && (
              <DescBox
                onChange={(e) => {
                  setInput(e.target.value);
                  textSet(e.target.value);
                }}
              />
            )}
            <ConfirmationModalButtonContainer>
              <Button
                $stylePreset="dark-border"
                onClick={() => {
                  setInput('');
                  cancel();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={textSet && input.length === 0}
                $stylePreset="dark"
                onClick={() => {
                  if (selectedItem) confirm(selectedItem);
                  else confirm();
                  setInput('');
                }}
                color="white"
                background={COLORS.text}
              >
                Confirm
              </Button>
            </ConfirmationModalButtonContainer>
          </ConfirmationModalContent>
        </ConfirmationModalContainer>
      )}
    </>
  );
};

/**
 * Modal popup to delete item
 */
const ConfirmationModalContainer = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
`;

/**
 * Contents of model for deleting the item
 */
const ConfirmationModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${COLORS.text};
  background-color: ${COLORS.light};
  margin: auto;
  padding: 20px 20px 40px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
  margin: 0px 20px;
`;

const ConfirmationModalButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

const DescBox = styled.textarea`
  min-height: 90px;
  resize: vertical; /* Allowing vertical resizing */
  border: 1px solid ${COLORS.primary};
  border-radius: 10px;
  padding: 10px;
  margin-bottom:7px
  font-family: 'Inter-Regular', sans-serif; /* Ensure correct font-family syntax */
  font-size: 14px;
  line-height: 1.5; /* Adjust line-height for readability */
`;
