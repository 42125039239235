import React from 'react';
import { Link } from 'react-router-dom';
import { VerticalContainer } from '../CommonComponents';
import PropTypes from 'prop-types';

const Instruction = ({ field }) => {
  const { subtype, message, subtitle, LinkData } = field;

  const renderInstruction = () => {
    switch (subtype) {
      case 'withLink':
        return (
          <VerticalContainer align_items="flex-start">
            <p>
              {message || ''}
              {LinkData && (
                <Link to={LinkData.path} style={{ color: 'green' }}>
                  {LinkData.label}
                </Link>
              )}
            </p>
          </VerticalContainer>
        );
      case 'simple':
        return (
          <VerticalContainer align_items="flex-start">
            <p>{message || ''}</p>
          </VerticalContainer>
        );
      case 'withSubtitle':
        return (
          <>
            <h3 style={{ marginBottom: 0 }}>{subtitle}</h3>
            <p>{message || ''}</p>
          </>
        );
      default:
        return null;
    }
  };

  return <div>{renderInstruction()}</div>;
};

Instruction.propTypes = {
  field: PropTypes.shape({
    subtype: PropTypes.oneOf(['withLink', 'simple', 'withSubtitle']),
    message: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    LinkData: PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
export default Instruction;
