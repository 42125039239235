import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import unreadIcon from '../images/icons/unread-message.png';
import deleteIcon from '../images/icons/delete.png';
import { COLORS } from '../utils/Constants';
import { PaginationComponent } from './reusedComponents/PaginationComponent';
import {
  HorizontalContainer,
  Icon,
  VerticalContainer,
} from './CommonComponents';
import { ConfirmationModal } from './reusedComponents/ConfirmationModal';
import CustomButton from './reusedComponents/CustomButton';

export const Notification = ({ data, max = 5, updateNotif, deleteNotif }) => {
  const [selectedNotif, setSelectedNotif] = useState(null);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();

  if (
    data.slice((page - 1) * max, page * max).length === 0 &&
    data.length !== 0
  ) {
    setPage(page - 1);
  }

  return (
    <NotificationGrid>
      <NotificationSubjectContainer>
        {data.length === 0 && (
          <VerticalContainer align_items="center">
            <h3>No Results</h3>
          </VerticalContainer>
        )}
        {data.slice((page - 1) * max, page * max).map((notification) => {
          return (
            <Subject
              style={
                selectedNotif && selectedNotif._id === notification._id
                  ? { background: COLORS.secondary }
                  : null
              }
              onClick={
                !selectedNotif || selectedNotif._id !== notification._id
                  ? () => {
                      setSelectedNotif(notification);
                      updateNotif(notification._id);
                    }
                  : () => updateNotif(notification._id)
              }
              key={notification._id}
            >
              <HorizontalContainer justify_content="space-between">
                <div style={{ width: '90%' }}>
                  <SubjectHeader>{notification.subject}</SubjectHeader>
                  <SubjectText>{notification.date}</SubjectText>
                </div>
                {!notification.read && <Unread />}
              </HorizontalContainer>
            </Subject>
          );
        })}
      </NotificationSubjectContainer>
      <NotificationBodyContainer>
        {selectedNotif && (
          <NotificationBody>
            <HorizontalContainer justify_content="space-between">
              <div>
                <Bold>From:</Bold> {selectedNotif.sender} <br />
                <Bold>Date Sent</Bold> {selectedNotif.date}
              </div>
              <HorizontalContainer>
                <IconContainer
                  onClick={() => {
                    updateNotif(selectedNotif._id, true);
                  }}
                >
                  <IconHover>
                    <Icon
                      hover={'cursor: pointer; opacity: 0.6;'}
                      width="25px"
                      src={unreadIcon}
                    />
                  </IconHover>
                  <ToolTip>Unread</ToolTip>
                </IconContainer>
                <IconContainer
                  onClick={() => {
                    setShowModal(true);
                  }}
                >
                  <IconHover>
                    <Icon
                      width="23px"
                      src={deleteIcon}
                      style={{
                        filter:
                          'invert(14%) sepia(68%) saturate(4982%) hue-rotate(352deg) brightness(80%) contrast(103%)',
                      }}
                    />
                  </IconHover>
                  <ToolTip>Delete</ToolTip>
                </IconContainer>
              </HorizontalContainer>
            </HorizontalContainer>
            <hr
              style={{
                width: '100%',
                border: `0.5px solid ${COLORS.dark}`,
              }}
            />
            <h2>{selectedNotif.subject}</h2>
            <p style={{ whiteSpace: 'pre-line' }}>{selectedNotif.message}</p>
            {selectedNotif.link && (
              <CustomButton
                width="150px"
                onClick={() => {
                  history.push({
                    pathname: selectedNotif.link,
                    state: { id: selectedNotif._id },
                  });
                }}
              >
                View Report
              </CustomButton>
            )}
          </NotificationBody>
        )}

        {!selectedNotif && (
          <VerticalContainer align_items="center" height="100%">
            <h3>Click on a notification to view it</h3>
          </VerticalContainer>
        )}
      </NotificationBodyContainer>
      <PaginationContainer>
        <PaginationComponent
          rows={data}
          max={max}
          page={page}
          setPage={setPage}
        />
      </PaginationContainer>
      <ConfirmationModal
        message="Are you sure you would like to delete this notification?"
        show={showModal}
        cancel={() => setShowModal(false)}
        confirm={() => {
          deleteNotif(selectedNotif._id);
          setSelectedNotif(null);
          setShowModal(false);
        }}
      />
    </NotificationGrid>
  );
};

const NotificationGrid = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`;

const NotificationSubjectContainer = styled.div`
  padding: 20px;
  grid-column: 1 / span 5;
  margin-right: 5%;
  background: ${COLORS.light};
  border-radius: 10px;
`;

const NotificationBodyContainer = styled.div`
  padding: 20px;
  grid-column: 6 / span 7;
  margin-left: 5%;
  background: ${COLORS.light};
  border-radius: 10px;
`;

const NotificationBody = styled.div`
  padding: 25px 30px;
  border: solid 1px ${COLORS.primary};
  background: white;
  border-radius: 10px;
`;

const PaginationContainer = styled.div`
  grid-column: 1 / span 5;
`;

const Subject = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  :hover {
    background: ${COLORS.secondary};
  }
`;

const SubjectText = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const SubjectHeader = styled(SubjectText)`
  margin-bottom: 5px;
  font-weight: bold;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Unread = styled.div`
  padding: 6px;
  background: ${COLORS.primary};
  border-radius: 50%;
`;

const IconHover = styled.div`
  display: flex;
  transition: 0.2s;
`;

const ToolTip = styled.div`
  position: absolute;
  background: ${COLORS.dark};
  color: white;
  bottom: -25px;
  border-radius: 5px;
  padding: 5px;

  opacity: 0;
  transition: 0.2s;

  user-select: none;
`;

const IconContainer = styled(VerticalContainer)`
  position: relative;
  cursor: pointer;
  &:hover ${ToolTip} {
    opacity: 100;
  }

  &:hover ${IconHover} {
    background-color: ${COLORS.secondary};
    border-radius: 50%;
  }
`;
