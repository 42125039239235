import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import SimpleCard from './SimpleCard';
import { library } from '@fortawesome/fontawesome-svg-core';
import propTypes from 'prop-types';
import axios from 'axios';
import { getAllStateAbbrv } from '../utils/Constants';
import {
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
  FormContainer,
  FormSelectInput,
  Button,
} from './CommonComponents';
import GeolocationInput from './GeolocationInput';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
library.add(faSpinner);

const RequiredSpan = styled.span`
  color: red;
`;

const RequiredStar = () => <RequiredSpan>*</RequiredSpan>;

const SubmittingDiv = styled.div`
  text-align: center;
`;
const SubmittingSpinner = () => {
  return (
    <SubmittingDiv>
      <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
      &nbsp;Submitting...
    </SubmittingDiv>
  );
};

const validateDate = (dateForm) => {
  console.log('HERE', dateForm);

  if (dateForm == null) {
    return false;
  }

  var todayDate = new Date();
  var minDate = new Date();
  var enteredDate = new Date(dateForm.activeDate);
  minDate.setDate(todayDate.getDate() - 8);

  if (enteredDate < minDate || enteredDate > todayDate) {
    return false;
  } else {
    return true;
  }
};

const validateForm = (form) => {
  let hasId = false;
  if (!form.tag) return false;
  if (
    (form.tag.isoNumber && form.tag.isoNumber !== '') ||
    (form.tag.localMgmtNumber && form.tag.localMgmtNumber !== '') ||
    (form.tag.tattooNumber && form.tag.tattooNumber !== '')
  )
    hasId = true;

  if (!validateDate(form)) return false;

  if (!form.premise) return false;

  return hasId;
};

const submitSheep = async (sheep) => {
  try {
    toast.info(
      'Submitting sheep to the backend. It may take up to a minute. You will be redirected when the sheep has been created. Please stay on this page.',
    );
    const request = await axios.post('/api/sheep', sheep);
    const returnedSheep = request.data;
    return returnedSheep;
  } catch (err) {
    err.message = err.response.data.message;
    throw new Error(err);
  }
};

const submitReplace = async (data) => {
  try {
    await axios.post('/api/sheep/tag/lost', data);
  } catch (err) {
    err.message = err.response.data.message;
    throw new Error(err);
  }
};

// ensures that the date of birth is not in the future
const validateDateOfBirth = (dob) => {
  const now = new Date();
  const birthDate = new Date(dob);
  return now >= birthDate;
};

//Validate birthdate of sheep
const validateDateOfBirthPast = (dob) => {
  const past = new Date();
  past.setFullYear(past.getFullYear() - 10);
  const birthDate = new Date(dob);
  return past <= birthDate;
};

// ensures the iso number is valid
const validateIsoNumber = (iso) => {
  return /^124000\d{9}$/.test(iso) || /^840000\d{9}$/.test(iso);
};

// ensures the US Scrapie ID is valid
const validateScraipeID = (scrapieId) => {
  const usStates = getAllStateAbbrv();
  return (
    /^[A-Z]{2}\d{7}$/.test(scrapieId) &&
    usStates.includes(scrapieId.substring(0, 2))
  );
};

export default function SheepLostTagForm({ errors, breeds, premises }) {
  const [form, setForm] = useState({
    premise: '',
    tag: {},
    gender: 'male',
    isExported: false,
    location: null,
  });
  const [dateForm, setDateForm] = useState();
  const [redirect, setRedirect] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [inputDob, setInputDob] = useState();

  useEffect(() => {
    setForm({
      ...form,
      premise: premises.length > 0 ? premises[0]._id : '',
    });
  }, [premises]);

  useEffect(() => {
    setInputDob(new Date().toISOString().slice(0, 10));
  }, []);

  const sheepFields = [
    {
      name: 'Premise',
      type: 'dropdown',
      required: true,
      id: 'premise',
      altvalue: true,
      unique: true,
    },
    { name: 'Gender', type: 'dropdown', id: 'gender' },
    { name: 'Sub Gender', type: 'dropdown', id: 'subgender' },
    { name: 'Breed', type: 'dropdown', id: 'breed' },
    {
      name: 'Date of Birth',
      type: 'date',
      id: 'birthdate',
      hint: 'May 11, 2019',
    },
  ];

  const dateInputs = [
    {
      name: 'Up to 7 days before today for tag activation',
      hint: 'You should enter a date between today and 7 days back ',
      type: 'date',
      id: 'activeDate',
    },
  ];

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      // remove any empty strings from the tag
      if (form.tag.isoNumber === '') delete form.tag.isoNumber;
      if (form.tag.localMgmtNumber === '') delete form.tag.localMgmtNumber;
      if (form.tag.tattooNumber === '') delete form.tag.tattooNumber;
      if (form.tag.usScrapieId === '') delete form.tag.usScrapieId;

      // validates the date of birth
      if (form.birthdate) {
        if (!validateDateOfBirth(form.birthdate)) {
          toast.error(
            'Please select a valid date: Date of birth cannot be in the future',
          );
          return;
        } else if (!validateDateOfBirthPast(form.birthdate)) {
          toast.error(
            'Please select a valid date: Date of birth cannot be more than 10 years in the past',
          );
          return;
        }
      }

      // validates the iso number
      if (form.tag.isoNumber && !validateIsoNumber(form.tag.isoNumber)) {
        toast.error('Please enter a valid ISO Number');
        return;
      }
      // validates the US Scrapie ID
      if (form.tag.usScrapieId && !validateScraipeID(form.tag.usScrapieId)) {
        toast.error('Please enter a valid US Scraipe ID');
        return;
      }

      setSubmitting(true);

      var lostTag = {
        tattooNumber: 'Lost',
        localMgmtNumber: 'Lost',
      };

      let sheepInfo = {
        gender: form.gender,
        isExproted: false,
        location: form.location,
        premise: form.premise,
        tag: lostTag,
      };

      sheepInfo.tag = lostTag;

      const returnedSheep = await submitSheep(sheepInfo);

      let replaceInfo = {
        sheepID: returnedSheep._id,
        oldTagId: returnedSheep.tag,
        isoNumber: form.tag.isoNumber ? form.tag.isoNumber : null,
        tattooNumber: form.tag.tattooNumber ? form.tag.tattooNumber : null,
        localMgmtNumber: form.tag.localMgmtNumber
          ? form.tag.localMgmtNumber
          : null,
        location: form.location,
        usScrapieId: form.tag.usScrapieId ? form.tag.usScrapieId : null,
        replaceDate: dateForm,
      };

      await submitReplace(replaceInfo);
      setSubmitting(false);
      setRedirect(`/sheep/${returnedSheep._id}`);
    } catch (err) {
      setSubmitting(false);
      toast.dismiss();
      if (err.message) {
        // parse error message to display a more readable error to user
        if (err.message.includes('duplicate key')) {
          if (err.message.includes('isoNumber')) {
            toast.error(`The ISO number ${form.tag.isoNumber} already exists.`);
          } else if (err.message.includes('usScrapieId')) {
            toast.error(
              `The US Scrapie ID ${form.tag.usScrapieId} already exists.`,
            );
          }
        } else {
          toast.error(err.message);
        }
      } else {
        toast.error('Something went wrong with creating this sheep.');
      }
    }
  };

  const setGeolocation = () => {
    const onSuccess = (position) => {
      const location = [position.coords.longitude, position.coords.latitude];
      setForm({ ...form, location });
    };

    const onError = () => {
      toast.info('Could not get the geolocation of your device');
    };

    if (!navigator.geolocation) {
      toast.info('Geolocation is not supported by this browser');
    } else {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  };

  const clearLocation = () => {
    setForm({ ...form, location: null });
  };

  const tagFields = [
    {
      name: 'Iso Number',
      id: 'isoNumber',
      hints: [
        'ISO number must start with either 124 for Canada, or 840 for US, followed by 3 zeros, followed by 9 digits.',
        'eg. 124000635671357',
      ],
    },
    {
      name: 'Local Management Number',
      id: 'localMgmtNumber',
    },
    {
      name: 'Tattoo Number',
      id: 'tattooNumber',
    },
    {
      name: 'US Scrapie Id',
      id: 'usScrapieId',
      hints: [
        'US Scrapie ID must start with a US state abbreviation, followed by 7 digits.',
        'eg. NY1083612',
      ],
    },
  ];

  let dropdowns = {
    breeds: breeds,
    premises: premises,
    genders: [
      { name: 'Male', value: 'male' },
      { name: 'Female', value: 'female' },
    ],
  };

  if (form.gender === 'male') {
    dropdowns.subgenders = [
      { name: 'Other', value: null },
      { name: 'Ram', value: 'ram' },
      { name: 'Wether', value: 'wether' },
    ];
  } else if (form.gender === 'female') {
    dropdowns.subgenders = [
      { name: 'Other', value: null },
      { name: 'Ewe', value: 'ewe' },
      { name: 'Ewe Lamb', value: 'ewelamb' },
    ];
  } else {
    dropdowns.subgenders = [];
  }

  if (redirect !== '') {
    return <Redirect to={redirect} />;
  }
  return (
    <SheepContainer>
      <SimpleCard
        title="Sheep Information"
        size="md"
        description={
          <>
            <>Enter in the following information about the animal.</>
            <br />
            <br />
            <>
              <RequiredStar /> indicates a required field
            </>
          </>
        }
      >
        {sheepFields.map((element) => (
          <FormInputWrapper key={element._id}>
            <FormInputLabel htmlFor="input">
              {element.name}
              {element.required ? (
                <>
                  &nbsp;
                  <RequiredStar />
                </>
              ) : (
                <></>
              )}
            </FormInputLabel>
            {element.type === 'dropdown' ? (
              <FormSelectInput
                invalid={errors[element.id]}
                value={form[element.id]}
                onChange={(e) =>
                  setForm({
                    ...form,
                    [element.id]: e.target.value,
                  })
                }
              >
                {dropdowns[`${element.id}s`] &&
                  dropdowns[`${element.id}s`].map((option) => (
                    <option
                      key={option._id}
                      value={
                        element.altvalue
                          ? option._id
                          : option.value
                          ? option.value
                          : option.name
                      }
                    >
                      {option.name}
                    </option>
                  ))}
              </FormSelectInput>
            ) : (
              <FormTextInput
                disabled={element.unique && form.disabled}
                placeholder={element.hint}
                type={element.type ? element.type : 'text'}
                id="input"
                invalid={errors[element.id]}
                name={element.id}
                value={inputDob || ''}
                onChange={(e) => {
                  if (e.target.value === '') {
                    // eslint-disable-next-line no-unused-vars
                    const { [element.id]: _, ...updatedState } = form;
                    setForm(updatedState);
                  } else {
                    setInputDob(e.target.value);
                    setForm({ ...form, [e.target.name]: e.target.value });
                  }
                }}
              />
            )}
          </FormInputWrapper>
        ))}
        <GeolocationInput
          location={form.location}
          getLocation={setGeolocation}
          clearLocation={clearLocation}
        />
      </SimpleCard>
      {/*Tag fields*/}
      <SimpleCard
        title="Tag Information"
        size="md"
        description="Enter the following uniquely identifying information for the sheep. Note that at least one of the following elements must be provided: Iso Number, Local Management Number or Tattoo Number"
      >
        {tagFields.map((f) => (
          <>
            <FormInputWrapper>
              <FormInputLabel htmlFor="input">{f.name}</FormInputLabel>
              <FormTextInput
                placeholder={f.placeholder}
                type="text"
                id="input"
                value={form.tag[f.id]}
                onChange={(e) => {
                  setForm({
                    ...form,
                    tag: {
                      ...form.tag,
                      [f.id]: e.target.value,
                    },
                  });
                }}
              />
              {f.hints &&
                f.hints.map((hint, idx) => (
                  <FormHint key={idx} htmlFor="input">
                    {hint}
                  </FormHint>
                ))}
            </FormInputWrapper>
          </>
        ))}
        {dateInputs.map((i) => (
          <>
            <FormInputWrapper>
              <FormInputLabel htmlFor="input">{i.name}</FormInputLabel>
              <FormTextInput
                disabled={i.unique && form.disabled}
                placeholder={i.hint}
                type={i.type ? i.type : 'text'}
                id="input"
                name={i.id}
                onChange={(e) => {
                  if (e.target.value === '') {
                    // eslint-disable-next-line no-unused-vars
                    const { [i.id]: _, ...updatedState } = dateForm;
                    setDateForm(updatedState);
                  } else
                    setDateForm({
                      ...dateForm,
                      [e.target.name]: e.target.value,
                    });
                }}
              />
            </FormInputWrapper>
          </>
        ))}
      </SimpleCard>
      <Button
        type="submit"
        onClick={handleSubmit}
        disabled={!validateForm(form) || submitting || !validateDate(dateForm)}
      >
        Create
      </Button>
      {submitting ? <SubmittingSpinner /> : <></>}
    </SheepContainer>
  );
}

SheepLostTagForm.propTypes = {
  errors: propTypes.any,
  breeds: propTypes.any,
  premises: propTypes.any,
};
// This should be the new default
const SheepContainer = styled(FormContainer)``;

const FormHint = styled.label`
  margin-top: 5px;
  color: orange;
  font-size: 12px;
`;
