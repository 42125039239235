import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import PageCard from '../../components/PageCard.js';
import { viewColor, deleteColor } from '../../components/Colors.js';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  EditButton,
  Button,
  VerticalContainer,
} from '../../components/CommonComponents';
import { getUsers, deleteUser } from '../../utils/UserUtils.js';
import SimpleCard from '../../components/SimpleCard';
import axios from 'axios';
import StripedTable from '../../components/StripedTable.js';
import { getSelectedRole } from '../../utils/RoleUtils.js';
import { Redirect } from 'react-router';

export default function UsersView() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [redirect, setRedirect] = useState();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get('/api/users/role');
        setRoles(res.data);

        if (res.data.some((r) => r.roleType == 'admin')) {
          const users = await getUsers();
          users.forEach((user) => {
            user._id = user.username;
            user.actions = (
              <ButtonContainer>
                <Link to={`/users/${user.username}`}>
                  <ViewButton>View</ViewButton>
                </Link>
                <DeleteButton onClick={() => deleteUsername(user.username)}>
                  Delete
                </DeleteButton>
              </ButtonContainer>
            );
          });
          setUsers(users);
        }

        setLoading(false);
      } catch (err) {
        toast.error(err.message);
      }
    })();
  }, []);

  const deleteUsername = (username) => {
    deleteUser(username);
    const deleted = users.filter((user) => user.username !== username);
    setUsers(deleted);
  };

  const columns = [
    { name: 'Username', id: 'username' },
    { name: 'First Name', id: 'firstName' },
    { name: 'Last Name', id: 'lastName' },
    { name: 'Actions', id: 'actions' },
  ];

  if (redirect) return <Redirect to={redirect} />;

  if (getSelectedRole() !== 'admin') {
    return (
      <VerticalContainer
        justify_content="center"
        align_items="center"
        mt="50px"
      >
        <h2>You do not have permission to view this page.</h2>
        <Button onClick={() => setRedirect('/')}>Go Home</Button>
      </VerticalContainer>
    );
  }

  return (
    <PageCard title="All Users" back>
      {loading ? (
        <div>Loading...</div>
      ) : roles.some((r) => r.roleType === 'admin') ? (
        <StripedTable rows={users} columns={columns} />
      ) : (
        <SimpleCard>
          <h2>
            You do not have the Permission to view this page, please click the
            button below to be redirected back
          </h2>
          <Link to="/">
            <EditButton>Home</EditButton>
          </Link>
        </SimpleCard>
      )}
    </PageCard>
  );
}

const ButtonContainer = styled.div`
  display: flex;
`;

const ViewButton = styled(EditButton)`
  background-color: ${viewColor};
`;

const DeleteButton = styled(EditButton)`
  background-color: ${deleteColor};
`;
