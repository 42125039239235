import Papa from 'papaparse';
const moment = require('moment');
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import XLSX from 'xlsx';

// TODO: Could probably use the same parseSheep function, just conditionally set the preview & transform flags

// These configurations are custom picked for parsing the sheep CSV's. An equiv one can be made for other files

/**
 * Parses only the first row of data to make it easier to extract the headers. Returns
 * a promise that resolves with an array of the headers and the complete first row of
 * data from the CSV file
 *
 * @function
 */
export const parseHeaders = (path) =>
  new Promise((resolve, reject) => {
    Papa.parse(path, {
      download: true,
      header: true, // To allow retrieval by field name
      skipEmptyLines: true,
      complete: (results) => {
        if (results.data.length > 0) {
          // Extract the headers by accessing the first row
          const headers = Object.keys(results.data[0]);

          // We'll also pass it the rest of the data as a sample
          resolve([headers, results.data[0]]);
        } else {
          const error = new Error("The given file doesn't have any data");
          reject(error);
        }
      },
      error: reject,
    });
  });

export const parseSheep = (path, headers, completeCB) => {
  // Set ewename and ramname
  let eweName = '';
  let ramName = '';
  if (headers.gender) {
    eweName = headers.eweName;
    ramName = headers.ramName;
  }
  Papa.parse(path, {
    download: true,
    header: true, // To allow retrieval by field name
    // worker: true, // TODO: Fix... So that the page stays reactive and papaparser works on its own thread
    skipEmptyLines: true,
    transformHeader: (header) => {
      const val = Object.keys(headers).find((key) => headers[key] === header);
      return val ? val : header;
    },
    transform: (value, header) => {
      // Clean any fields that require it
      if (header === 'isonum') {
        // In order to clean: Remove anything except numbers. Then from the left, take 15 numbers
        return value.replace(/[^\d]/g, '').substring(0, 15);
      }
      if (header === 'gender') {
        if (value === ramName) return 'Male';
        if (value === eweName) return 'Female';
        return '';
      }
      if (header === 'date') {
        const parsed = moment(value, headers.dateFormat);
        if (!parsed.isValid()) {
          // TODO: Redirect user, since format is wrong...
        }
        return parsed.format('LL');
      }
      return value;
    },
    complete: completeCB,
    error: (error) => {
      // This is an error to do with FileReader and not papaparse
      console.log(`An error has occurred during parsing: ${error}`);
    },
  });
};

const parseCSVPromise = (csv) => {
  return new Promise((resolve) => {
    Papa.parse(csv, {
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: (results) => {
        resolve(results.data);
      },
    });
  });
};

const reduce = (reducer) => (row) => row.reduce(reducer, {});

// parseCSV can take either a CSV file or a CSV string
export const parseCSV = (csv) => {
  return parseCSVPromise(csv)
    .then((rows) => {
      const headers = rows[0].map(
        (header, idx) => header || 'Column ' + (idx + 1),
      );
      return rows
        .slice(1)
        .map(reduce((acc, val, idx) => ({ ...acc, [headers[idx]]: val })));
    })
    .catch((err) => {
      throw err;
    });
};

const getCSVValueFromHeader = (row, header) => {
  const value = row[header];
  // value is empty, return null
  if (!value || value === '') {
    return null;
  }
  // value can be converted to number, return value as string after converting to number
  if (!isNaN(+value)) {
    return '' + +value;
  }
  // value can be converted to number by removing commas, return value as string after converting to number
  const commalessValue = ('' + value).replace(/,/g, '');
  if (!isNaN(+commalessValue)) {
    return '' + +commalessValue;
  }
  // value cannot be converted to number in any way, return as is
  return value;
};

export const extractDataFromHeaders = (data, headers) => {
  let sheeps = [];

  // transform into sheep
  data.forEach((row) => {
    let sheep = {};
    Object.keys(headers).forEach((key) => {
      const value = getCSVValueFromHeader(row, headers[key]);
      if (value && value !== '') sheep[key] = value;
    });
    if (Object.keys(sheep).length > 0) {
      // Generate a dummy id
      const prefix = headers.csipPrefix;
      if (prefix && prefix !== '' && sheep.isoNumber && sheep.isoNumber !== '')
        sheep.isoNumber = `${prefix}${sheep.isoNumber}`;
      sheep.dummyID = uuidv4();
      sheeps.push(sheep);
    }
  });

  return sheeps;
};

/**
 * Returns the parsed CSV file as a promise. Papa.parse doesn't return anything
 * on its own. Here, we promisify the method to make it easier to use elsewhere.
 *
 * @function
 */
export const parseAsync = async (file) => {
  return new Promise((resolve) => {
    Papa.parse(file, {
      download: true,
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        resolve(results.data);
      },
    });
  });
};

export const parseAndSetData = (
  file,
  setHeadings,
  setData,
  legacyForm = false,
) => {
  parseCSV(file)
    .then((rows) => {
      const filteredRows = rows.filter(
        (row) => !Object.values(row).every((val) => val === null),
      );
      var headings;

      if (legacyForm) {
        headings = Object.keys(filteredRows[0]).map((heading) => ({
          id: heading,
          name: heading,
          value: heading,
        }));
        headings = [{ id: 'N/A', name: 'N/A', value: 'N/A' }, ...headings];
      } else {
        headings = Object.keys(filteredRows[0]).map((heading) => ({
          id: heading,
          name: heading,
        }));
      }

      setHeadings(headings);

      setData(
        filteredRows.map((row) => {
          Object.keys(row).forEach((k) => {
            if (!row[k]) row[k] = '';
          });
          return row;
        }),
      );
    })
    .catch((err) => {
      toast.error(err);
    });
};

export const convertAndParseXLS = (
  file,
  setHeadings,
  setData,
  legacyForm = false,
) => {
  const reader = new FileReader();
  reader.onload = (event) => {
    const data = new Uint8Array(event.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const csv = XLSX.utils.sheet_to_csv(worksheet);

    parseAndSetData(csv, setHeadings, setData, legacyForm);
  };
  reader.readAsArrayBuffer(file);
};
