import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/Constants';
import { getPremisesById } from '../../utils/PremiseUtils.js';
import { getSheepByPremiseID } from '../../utils/SheepUtils.js';

/**
 * Delete Modal for dashboard pages
 * @param item - item to be deleted
 * @param itemType - type of item ('operation' or 'premise')
 * @param deleteItem - function to be called on deleteItem confirmation (deleting item from database)
 */
const ItemDeleteModal = ({ item, itemType, deleteItem, closeModal }) => {
  const [itemNameInput, setItemNameInput] = useState('');
  const [premises, setPremises] = useState([]);
  const [hasActiveSheep, setHasActiveSheep] = useState(true);
  const [loading, setLoading] = useState(true);

  const fetchActiveSheepCountsByPremiseIds = async (premiseIds) => {
    try {
      const sheepPromises = premiseIds.map(async (premiseId) => {
        try {
          const queryParams = {
            premiseID: premiseId,
            getInactive: false,
          };
          const fetchedSheeps = await getSheepByPremiseID(
            '/api/sheep',
            queryParams,
          );
          const activeSheepCount = fetchedSheeps.filter(
            (sheep) => sheep.active,
          ).length;
          return { id: premiseId, activeSheepCount };
        } catch (error) {
          console.error(
            `Failed to fetch sheep for premise ID ${premiseId}:`,
            error,
          );
          return { id: premiseId, activeSheepCount: 0 };
        }
      });

      const sheepResults = await Promise.all(sheepPromises);

      const filteredResults = sheepResults.filter(
        (result) => result.activeSheepCount > 0,
      );

      const premisesPromises = filteredResults.map(async (result) => {
        try {
          const premise = await getPremisesById(result.id);
          return {
            id: result.id,
            name: premise ? premise.name : '',
            pid: premise ? premise.pid : '',
            activeSheepCount: result.activeSheepCount,
          };
        } catch (error) {
          console.error(
            `Failed to fetch details for premise ID ${result.id}:`,
            error,
          );
          return {
            id: result.id,
            name: '',
            pid: '',
            activeSheepCount: result.activeSheepCount,
          };
        }
      });

      const premisesResults = await Promise.all(premisesPromises);

      return premisesResults;
    } catch (error) {
      console.error('Failed to fetch active sheep counts:', error);
      return [];
    }
  };

  useEffect(() => {
    setLoading(true);
    if (itemType === 'premise') {
      const premiseId = item?._id;
      if (premiseId) {
        fetchActiveSheepCountsByPremiseIds([premiseId])
          .then((activeSheepDataArray) => {
            console.log('activeSheepDataArray', activeSheepDataArray);
            setPremises(activeSheepDataArray);
            setHasActiveSheep(activeSheepDataArray.length > 0);
          })
          .catch((error) => {
            console.error('Error fetching active sheep data:', error.message);
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setHasActiveSheep(false);
        setLoading(false);
      }
    } else {
      if (item?.premises && item.premises.length === 0) {
        setHasActiveSheep(false);
        setLoading(false);
        return;
      }

      if (item?.premises?.length > 0) {
        const premiseIds = item.premises;
        const validPremiseIds = premiseIds.filter((id) => id !== null);

        fetchActiveSheepCountsByPremiseIds(validPremiseIds)
          .then((activeSheepDataArray) => {
            const uniquePremises = activeSheepDataArray.filter(
              (premise, index, self) =>
                index === self.findIndex((p) => p.id === premise.id),
            );

            setPremises(uniquePremises);

            const anyActiveSheep = uniquePremises.some(
              (premise) => premise.activeSheepCount > 0,
            );
            setHasActiveSheep(anyActiveSheep);
          })
          .catch((error) => {
            console.error('Error fetching active sheep data:', error.message);
            console.error(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [item, itemType]);

  const confirmDelete = (item) => {
    if (itemNameInput.trim() !== itemName) {
      alert(`Item name does not match. Please type '${itemName}' to confirm.`);
      return;
    }
    closeModal(false);

    deleteItem(item);
    setItemNameInput('');
  };

  if (loading) {
    return (
      <DeleteModal>
        <DeleteModalContent>
          <h1 style={{ textAlign: 'center' }}>Delete Item</h1>
          <h3 style={{ textAlign: 'center' }}>Loading...</h3>
        </DeleteModalContent>
      </DeleteModal>
    );
  }

  const itemName = itemType === 'premise' ? item?.pid : item?.businessName;
  const itemTypeDisplay = itemType === 'premise' ? 'Premise' : 'Operation';

  return (
    <DeleteModal>
      <DeleteModalContent>
        <h1 style={{ textAlign: 'center' }}>Close {itemTypeDisplay}</h1>
        {hasActiveSheep && (
          <>
            <h3 style={{ textAlign: 'center' }}>
              To close this {itemTypeDisplay}, please dispose the sheep from the
              following premises:
            </h3>
            <div style={{ padding: '20px' }}>
              {premises.map((premise) => (
                <h4
                  key={premise.id}
                  style={{
                    textAlign: 'center',
                    lineHeight: '1.5em',
                    margin: '0px 50px',
                  }}
                >
                  Premise {premise.name} ({premise.pid}), which includes{' '}
                  {premise.activeSheepCount} sheep.
                </h4>
              ))}
            </div>
            <DeleteModalButtonContainer>
              <DeleteModalButton
                onClick={() => {
                  closeModal(false);
                }}
              >
                OK
              </DeleteModalButton>
            </DeleteModalButtonContainer>
          </>
        )}
        {!hasActiveSheep && (
          <>
            <h3
              style={{
                textAlign: 'center',
                lineHeight: '1.5em',
                margin: '0px 50px',
              }}
            >
              You are about to close <strong>'{itemName}'</strong>. Which can be
              restored later.
              <br />
              Type the name below to confirm.
            </h3>

            <ItemNameInput
              type="text"
              placeholder={`Type "${itemName}" to confirm`}
              value={itemNameInput}
              onChange={(e) => setItemNameInput(e.target.value)}
            />
            <DeleteModalButtonContainer>
              <DeleteModalButton
                onClick={() => {
                  closeModal(false);
                }}
              >
                Cancel
              </DeleteModalButton>
              <DeleteModalButton
                onClick={() => {
                  confirmDelete(item);
                }}
                color="white"
                background={COLORS.text}
              >
                Confirm
              </DeleteModalButton>
            </DeleteModalButtonContainer>
          </>
        )}
      </DeleteModalContent>
    </DeleteModal>
  );
};

ItemDeleteModal.propTypes = {
  item: PropTypes.object,
  itemType: PropTypes.string.isRequired, // 'operation' or 'premise'
  deleteItem: PropTypes.func,
};

/**
 * Modal popup to delete item
 */
const DeleteModal = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(4px);
`;

/**
 * Contents of modal
 */
const DeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 35%;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  color: ${COLORS.text};
  background-color: ${COLORS.light};
  padding: 20px 20px 40px;
  border: 1px ${COLORS.primary} solid;
  border-radius: 15px;
`;

/**
 * Container for modal buttons
 */
const DeleteModalButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`;

/**
 * Modal button styling
 */
const DeleteModalButton = styled.button`
  color: ${(props) => (props.color ? props.color : COLORS.text)};
  font-size: 20px;
  font-weight: bold;
  background: ${(props) => (props.background ? props.background : 'white')};
  border: ${COLORS.text} 1px solid;
  border-radius: 30px;
  width: 120px;
  height: 40px;

  &:hover {
    filter: contrast(1.5);
    cursor: pointer;
  }
`;
/**
 * Input field for item name
 */
const ItemNameInput = styled.input`
 margin: 10px auto;
  width: 80%;
  padding: 10px;
  font-size: 16px;
  text-align: center
  border: 1px solid ${COLORS.primary};
  border-radius: 5px;

`;
export default ItemDeleteModal;
