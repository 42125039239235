import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageCard from '../components/PageCard';
import SimpleCard from '../components/SimpleCard.js';
import { FormTextInput, FormInputLabel } from '../components/CommonComponents';

export default function TraceSearch() {
  const [iso, setIso] = useState('');
  const history = useHistory();

  const handleSearch = () => {
    return axios
      .post('/api/trace/history', { isoNumber: iso })
      .then(() => {
        history.push(`/trace/reports/${iso}`);
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err);
        }
      });
  };

  return (
    <PageCard title="Trace a sheep" back line maxContent>
      <ContentContainer>
        <SimpleCard title="Search ISO Number" size="md" />
        <InnerContentContainer>
          Enter the ISO Number of the sheep that you would like to trace
          <div>
            <FormInputLabel>ISO Number</FormInputLabel>
            <FormTextInput
              value={iso}
              onChange={(e) => setIso(e.target.value)}
            />
            <button onClick={handleSearch}>Search</button>
          </div>
        </InnerContentContainer>
      </ContentContainer>
    </PageCard>
  );
}

const ContentContainer = styled.div`
  min-width: 80ch;
  margin: 1.5rem auto;
`;

const InnerContentContainer = styled.div`
  padding: 0.5em 1em;
  font-size: 1.2rem;
  text-align: center;

  div {
    margin: 2rem auto;
    width: 50%;
    text-align: left;
  }

  div label {
    display: block;
  }

  div input {
    margin-bottom: 1.25rem;
    width: 100%;
  }

  div button {
    font-size: 1.2rem;
    border: none;
    border-radius: 8px;
    padding: 0.5em 1em;
    display: block;
    margin: auto;
    background-color: #17a737;
    cursor: pointer;

    :hover {
      background-color: #66ad57;
    }
  }
`;
