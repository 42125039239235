import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import StripedTable from './StripedTable';
import ViewEditDispose from './ViewEditDispose';
import PropTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import {
  LinkButton,
  Icon,
  VerticalContainer,
  BackLink,
  ContentContainer,
} from '../components/CommonComponents.js';
import add from '../images/icons/add.png';
import styled from 'styled-components';
import { FilterSection } from './SheepReportComponents';
import PageCard from './PageCard.js';
import { agGreen } from './Colors.js';
import Loader from 'react-loader-spinner';

const ReportsSingleView = styled(VerticalContainer)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;

const ReportsDescription = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
  @media (max-width: 950px) {
    width: 95%;
  }
  @media (max-width: 500px) {
    width: 85%;
  }
  @media (max-width: 460px) {
    width: 75%;
  }
`;

const DescriptionArea = styled(ContentContainer)`
  background: white;
  border: none;
  padding: 0px;
  margin: 0px;
`;

const DataDoesNotExist = styled(ContentContainer)`
  background: ${COLORS.secondary};
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  p {
    font-size: 20px;
  }
`;

const SheepReportListComponent = ({
  title,
  fetchReportsFunction,
  detailEndpoint,
  createEndpoint,
  description,
  columns,
  csvEndpoint,
  frEndpoint,
}) => {
  /* States */
  const [reports, setReports] = useState([]);
  const [filteredReports, setFilteredReports] = useState([]);
  const [loading, setLoading] = useState(false);

  /* Transforms */
  const fetchReportsAndTransform = async () => {
    setLoading(true);
    try {
      let fetchedReports = await fetchReportsFunction();
      fetchedReports.forEach((r) => {
        r.actions = (
          <ViewEditDispose id={r._id} viewLink={detailEndpoint + r._id} />
        );

        r.createdAt =
          r.createdAt && new Date(r.createdAt).toISOString().split('T')[0];
      });

      setReports(fetchedReports);
    } catch (err) {
      console.info(err);
      toast.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReportsAndTransform();
  }, []);

  useEffect(() => {
    setFilteredReports(reports);
  }, [reports]);

  const handleApply = (filteredData) => {
    setFilteredReports(filteredData);
  };

  function reportsDataExists() {
    return (
      <>
        <FilterSection data={reports} onApply={handleApply} />
        <StripedTable
          columns={columns}
          rows={filteredReports}
          ShowFilters
          ShowCount
          max={10}
          paginate
        />
      </>
    );
  }

  function reportsDataDoesNotExist() {
    return (
      <DataDoesNotExist>
        <p>
          It looks like you don&apos;t have any {title} Reports at the moment.
        </p>
      </DataDoesNotExist>
    );
  }
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };
  return (
    <>
      {loading ? (
        <PageCard
          title={`Loading ${title} Reports`}
          loader={
            <Loader type="Oval" color={agGreen} height={50} width={150} />
          }
        />
      ) : (
        <ReportsSingleView>
          <ReportsDescription>
            <h1
              style={{
                gridColumn: '1 / span 2',
                fontSize: 'xxx-large',
                marginRight: 'auto',
                marginBottom: '18px',
                marginTop: '30px',
              }}
            >
              {title} Reports
            </h1>
            <BackLink to="/reports" label="Return to Reports" />
            <h2 style={{ marginTop: '40px', marginBottom: '0px' }}>
              Welcome to {title} Reports
            </h2>
            <p style={{ fontSize: '20px', marginTop: '25px' }}>
              You can view all of your {title} reports on this page
            </p>
            <DescriptionArea style={{ margin: '0px', marginTop: '5px' }}>
              {description}
            </DescriptionArea>
            <div>
              <h3 style={{ marginBottom: '5px' }}>Create a new Report</h3>
              <LinkButton
                $stylePreset="dark"
                style={{
                  marginBottom: '70px',
                  marginRight: '10px',
                  minWidth: '180px',
                }}
                to={createEndpoint || detailEndpoint}
              >
                <span style={{ fontSize: '15px' }}>Create Manually</span>
                {'  '}
                <Icon width="20px" m="0" src={add} />
              </LinkButton>
              {csvEndpoint && (
                <LinkButton
                  $stylePreset="dark"
                  style={{
                    marginBottom: '70px',
                    minWidth: '180px',
                    marginRight: '10px',
                  }}
                  to={csvEndpoint || detailEndpoint}
                >
                  <span style={{ fontSize: '15px' }}>Create by CSV</span>
                  {'  '}
                  <Icon width="20px" m="0" src={add} />
                </LinkButton>
              )}
              {frEndpoint && (
                <LinkButton
                  $stylePreset="dark"
                  style={{
                    marginBottom: '70px',
                    minWidth: '180px',
                    width: '125px',
                  }}
                  to={frEndpoint || detailEndpoint}
                >
                  <span style={{ fontSize: '15px' }}>Create by FR</span>
                  {'  '}
                  <Icon
                    width="20px"
                    m="0"
                    src={add}
                    style={{ filter: 'brightness(0) invert(1)' }}
                  />
                </LinkButton>
              )}
            </div>
          </ReportsDescription>
          {reports.length === 0
            ? reportsDataDoesNotExist()
            : reportsDataExists()}
        </ReportsSingleView>
      )}
    </>
  );
};

SheepReportListComponent.propTypes = {
  columns: PropTypes.any,
  createEndpoint: PropTypes.any,
  description: PropTypes.any,
  detailEndpoint: PropTypes.any,
  fetchReportsFunction: PropTypes.func,
  title: PropTypes.any,
};

export default SheepReportListComponent;
