import { useLocation } from 'react-router';
import React, { useEffect, useState } from 'react';
import Form from '../../components/Form.js';
import SheepSelector from '../../components/SheepSelector.js';
import FileUploadButton from '../../components/FileUploadButton.js';
import { PopUpWindow } from '../../components/CommonComponents';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { getUsername } from '../../utils/TokenUtils';
import { checkAdmin } from '../../utils/RoleUtils';
import { getSheep, getSheepsForUser } from '../../utils/SheepUtils';
library.add(faSpinner);

const axios = require('axios');

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SheepDocumentUploadView = () => {
  const query = useQuery();
  const [sheeps, setSheeps] = useState([]);
  const [sheepInfo, setSheepInfo] = useState();
  const [search, setSearch] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [associatedDocument, setAssociatedDocument] = useState([]);

  const fetchAndSetSheepInfo = async () => {
    try {
      const sheepId = query.get('id');
      if (sheepId) {
        let fetchedSheep = await getSheep(sheepId, true);
        setSheepInfo(fetchedSheep);
        setSearch(
          fetchedSheep.tag.isoNumber ||
            fetchedSheep.tag.UsScrapieId ||
            fetchedSheep.tag.localMgmtNumber ||
            fetchedSheep.tag.tattooNumber,
        );
      }
    } catch (err) {
      if (err.response && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const handleSheepIdentifierSearch = async (value) => {
    setSearch(value);
    // Resets sheep info for every search identifier change
    setSheepInfo();

    if (value !== '') {
      let fetchedSheeps = await getSheepsForUser(
        getUsername(),
        checkAdmin(),
        value,
      );
      setSheeps(fetchedSheeps);

      // Searches for an identifier in sheeps that match value
      fetchedSheeps.forEach((sheep) => {
        if (
          sheep.tag.isoNumber === value ||
          sheep.tag.localMgmtNumber === value ||
          sheep.tag.tattooNumber === value
        ) {
          setSheepInfo(sheep);
        }
      });
    } else {
      setSheeps([]);
    }
  };

  const handleSheepSelection = (s) => {
    setSheepInfo(s);
    setSearch(
      s.tag.isoNumber ||
        s.tag.usScrapieId ||
        s.tag.localMgmtNumber ||
        s.tag.tattooNumber,
    );
  };

  useEffect(() => {
    fetchAndSetSheepInfo();
  }, []);

  const SubmittingSpinner = () => {
    return (
      <div style={{ textAlign: 'center' }}>
        <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
        &nbsp;Submitting...
      </div>
    );
  };

  const handleSubmit = async (fieldValues) => {
    try {
      setSubmitting(true);

      let formData = new FormData();
      formData.set('title', fieldValues.title);
      formData.set('description', fieldValues.description);
      formData.set('isonum', sheepInfo.tag.isoNumber);

      formData.set('document', associatedDocument, associatedDocument.name);

      // eslint-disable-next-line
      const response = await axios.post('/api/document/', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      setRedirect(`/sheep/${sheepInfo._id}`);
    } catch (err) {
      if (err.response) {
        // In this case, the error is from Axios
        toast.error(err.response.data.message);
      } else {
        // The error isn't from Axios and won't have a response object
        toast.error(err.message);
      }

      // Allow the user to try submitting again
      setSubmitting(false);
    }
  };

  const handleCancel = () => {
    setRedirect(`/sheep/${query.get('id')}`);
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <PopUpWindow>
      <Form
        title="Upload a Document"
        fieldInfo={[
          {
            name: 'Search for Sheep via Identifier',
            type: 'content',
            content: (
              <SheepSelector
                value={search}
                sheep={sheeps}
                onChange={(value) => handleSheepIdentifierSearch(value)}
                onSelect={handleSheepSelection}
              />
            ),
          },
          {
            name: 'Sheep CSIP',
            id: 'isoNumber',
            value:
              sheepInfo && sheepInfo.tag.isoNumber
                ? sheepInfo.tag.isoNumber
                : 'None',
            description: 'Your documents will be associated with this sheep.',
            disabled: true,
            resetOnChange: true,
          },
          {
            name: 'Title',
            id: 'title',
            required: true,
          },
          {
            name: 'Description',
            id: 'description',
            type: 'textarea',
            maxLength: '500',
            required: true,
          },
          {
            name: 'Document',
            type: 'content',
            content: (
              <input
                required={true}
                onChange={(e) => {
                  setAssociatedDocument(e.target.files[0]);
                }}
                type="file"
              />
            ),
          },
          {
            type: 'content',
            content: submitting ? <SubmittingSpinner /> : <></>,
          },
        ]}
        onCancel={handleCancel}
        onSubmit={handleSubmit}
      />
    </PopUpWindow>
  );
};

export default SheepDocumentUploadView;
