import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormInputWrapper } from '../../components/CommonComponents.js';
import FormCard from '../../components/FormCard.js';
import EditButtons from '../../components/reusedComponents/EditButtons';
import TextInputField from '../../components/reusedComponents/TextInputField';

const EditEmail = ({ userData, fetchUserData }) => {
  const [email, setEmail] = useState('');

  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    setUser(userData);

    // Update the email state with userData.email
    setEmail(userData.email || '');
  }, [userData]);

  const checkEmailValidity = (NewEmail) => {
    const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;

    let result = { validity: true, errorMessage: '' };
    if (!email.trim()) {
      result.validity = false;
      result.errorMessage =
        'Please enter your email address. It cannot be empty.';
    } else if (!emailRegex.test(NewEmail)) {
      result.validity = false;
      result.errorMessage = 'Please enter a valid email address';
    }
    return result;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSaveEmail = async (e) => {
    e.preventDefault();
    if (email === user.email) {
      toast.info('No changes applied.');
      setEditMode(false);

      return;
    }

    let emailCheck = checkEmailValidity(email);
    if (!emailCheck.validity) {
      toast.error(emailCheck.errorMessage);
      return;
    }
    const updatedEmail = { newEmail: email };

    try {
      await axios.put(`/api/users/${user.username}/email`, updatedEmail);
      toast.success('Your email has been updated');
      fetchUserData();
      setEditMode(false);
    } catch (err) {
      if (err.response && err.response.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err.message);
      }
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  return (
    <FormCard title="Edit Email">
      {user ? (
        <>
          <FormInputWrapper>
            <TextInputField
              key={email.id}
              field={{ id: 'email', name: 'Email Address', type: 'text' }}
              value={email}
              onChange={handleEmailChange}
              disabled={!editMode} // Disable fields if not in edit mode
            />
            <EditButtons
              editMode={editMode}
              onCancel={handleCancel}
              onSave={handleSaveEmail}
              onEdit={handleEdit}
            />
          </FormInputWrapper>
        </>
      ) : (
        <>Loading...</>
      )}
    </FormCard>
  );
};

export default EditEmail;
