import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';
import left from '../images/icons/chevron_left.png';
import right from '../images/icons/chevron_right.png';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

/**
 * Common Form Components
 */

export const FormContainer = styled.form`
  width: 400px;
  color: ${COLORS.text};
  position: relative;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

export const FormSectionHeader = styled.div`
  font-size: 30px;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  flex-basis: 100% !important;
`;

export const FormInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px;
  margin-top: 0;
  width: 90%;
`;

export const FormInputLabel = styled.label`
  font-family: Inter-Regular;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const NewFormInputLabel = styled.label`
  color: ${COLORS.text};
  font-family: Inter-Regular;
  font-size: 15px;
  margin: 0 0 10px 0;
`;

export const FormTextInput = styled.input`
  height: 30px;
  border: ${(props) =>
    props.border ? props.border : `1px solid ${COLORS.primary}`};
  border-radius: 10px;
  background: ${(props) => (props.background ? props.background : 'white')};
  font-family: Inter-Regular;
  font-size: 14px;
  outline: none;
  padding: 2px 10px;
  &:disabled {
    background-color: silver;
    color: dimgray;
    cursor: not-allowed;
  }
`;

FormTextInput.propTypes = {
  border: PropTypes.bool,
  background: PropTypes.string,
};

export const FormTextInputSelection = styled.div`
  min-height: 30px;
  background: white;
  color: black;
  font-family: Inter-Regular;
  font-size: 12px;
  outline: none;
  border: none;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  :hover {
    background: #c2c6ca;
  }
`;

export const FormParagraphInput = styled.textarea`
  height: 100px;
  border-radius: 10px;
  background: ${(props) => (props.invalid ? '#DE3434' : '#C2C6CA')};
  font-family: Inter-Regular;
  font-size: 14px;
  outline: none;
  padding: 2px 10px;

  &:disabled {
    background-color: silver;
    color: dimgray;
    cursor: not-allowed;
  }
`;

FormParagraphInput.propTypes = {
  invalid: PropTypes.string,
};

export const FormPasswordContainer = styled.div`
  display: flex;
  position: relative;

  > input {
    width: 100%;
    padding-right: 30px;
  }
`;

export const FormPassPeek = styled.span`
  position: absolute;
  z-index: 1;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: 5px;
`;

export const FormSelectInput = styled.select`
  width: 100%;
  padding: 8px 12px;
  border: ${(props) =>
    props.border ? props.border : `1px solid ${COLORS.primary}`};
  border-radius: 12px;
  background: ${(props) => (props.backgroud ? props.background : 'white')};
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }
`;

FormSelectInput.propTypes = {
  border: PropTypes.bool,
  background: PropTypes.string,
};

export const FormTextList = styled.div`
  padding: 0;
`;

export const FormTextListItem = styled.li`
  cursor: pointer;
  position: relative;
  padding: 10px;
  list-style-type: none;
  background: #c2c6ca;
  color: black;

  &:hover {
    font-weight: bold;
  }

  ${({ remove }) =>
    remove &&
    `
    &::after {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    content: '\u00D7';
  }
  `}
`;

FormTextListItem.propTypes = {
  remove: PropTypes.bool,
};

export const FormRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  color: white;
  margin-right: 10px;

  * {
    width: 200px;
  }
`;

export const MessageField = styled.div`
  font-size: large;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  padding: 50px;
  border-radius: 15px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: ${COLORS.secondary};
  border: ${COLORS.primary} solid 1px;
`;

export const SubmittingSpinner = () => {
  return (
    <div style={{ textAlign: 'center' }}>
      <FontAwesomeIcon icon="spinner" spin />
      &nbsp;Submitting...
    </div>
  );
};

/**
 * Common Button and Link Components
 */

export const OldBackLink = styled.button`
  background: none;
  border: none;
  color: black;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
`;
export const ToLink = ({ to, label, style }) => {
  return (
    <Link to={to}>
      <HorizontalContainer
        mobile="none"
        justify_content="flex-start"
        style={style}
      >
        <p style={{ verticalAlign: 'middle', margin: '0px' }}>{label}</p>
        <Icon src={right} width="20px" m="0px" />
      </HorizontalContainer>
    </Link>
  );
};
ToLink.propTypes = {
  label: PropTypes.string.isRequired,
  to: PropTypes.any.isRequired,
  style: PropTypes.object,
};

// backlink: if no url is provided, link returns to the previous page
export const BackLink = ({
  to = 'javascript:history.back()',
  label = 'Back',
}) => {
  return (
    <a href={to}>
      <HorizontalContainer mobile="none" justify_content="flex-start">
        <Icon src={left} width="20px" m="0px" />
        <p style={{ verticalAlign: 'middle', margin: '0px' }}>{label}</p>
      </HorizontalContainer>
    </a>
  );
};

BackLink.propTypes = {
  label: PropTypes.string,
  to: PropTypes.string,
};

export const Button = styled.button`
  display: inline-block;
  text-align: center;
  min-width: 150px;
  width: ${(props) => (props.width ? props.width : 'min-content')};
  font-size: large;
  font-weight: bold;
  margin-block: 10px;
  padding: 10px;
  text-decoration: none;
  border-radius: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: ${COLORS.primary};
  background-color: white;
  border: ${COLORS.primary} solid 2px;
  white-space: nowrap;

  * {
    vertical-align: middle;
  }

  &:hover {
    color: white;
    background-color: ${COLORS.primary};
  }

  ${(props) => {
    switch (props.$stylePreset) {
      case 'default':
        return `
          color: ${COLORS.primary};
          background-color: white;
          border: ${COLORS.primary} solid 2px;

          & > img {
            filter: invert(31%) sepia(78%) saturate(498%) hue-rotate(65deg) brightness(98%) contrast(90%);
          }

          &:hover {
            color: white;
            background-color: ${COLORS.primary};

            & > img {
              filter: invert(100%) brightness(200%);
            }

          }
        `;
      case 'bold':
        return `
          color: white;
          background-color: ${COLORS.primary};
          border: ${COLORS.primary} solid 2px;

          & > img {
            filter: invert(100%) brightness(200%);
          }

          &:hover {
            color: ${COLORS.primary};
            background-color: white;

            & > img {
              filter: invert(31%) sepia(78%) saturate(498%) hue-rotate(65deg) brightness(98%) contrast(90%);
            }
          }
        `;
      case 'dark':
        return `
          color: white;
          background-color: ${COLORS.text};
          border: ${COLORS.text} solid 2px;

          & > img {
            filter: invert(100%) brightness(200%);
          }

          &:hover {
            color: ${COLORS.text};
            background-color: white;

            & > img {
              filter: invert(13%) sepia(53%) saturate(549%) hue-rotate(64deg) brightness(93%) contrast(87%);
            }
          }`;
      case 'dark-text':
        return `
          color: ${COLORS.text};
          background-color: white;
          border: ${COLORS.primary} solid 2px;

          &:hover {
            color: white;
            background-color: ${COLORS.primary};
          }
        `;
      case 'dark-border':
        return `
          color: ${COLORS.text};
          background-color: white;
          border: ${COLORS.text} solid 1px;

          &:hover {
            color: white;
            background-color: ${COLORS.primary};
          }
        `;
    }
  }}

  &:disabled {
    background-color: silver;
    color: dimgray;
    cursor: not-allowed;
  }
`;

Button.propTypes = {
  width: PropTypes.string,
  $stylePreset: PropTypes.oneOf([
    'default',
    'bold',
    'dark',
    'dark-text',
    'dark-border',
  ]),
};

export const LinkButton = styled(Link)`
  display: inline-block;
  text-align: center;
  min-width: 140px;
  width: min-content;
  font-size: large;
  font-weight: bold;
  margin-block: 10px;
  padding-block: 10px;
  padding-inline: 30px;
  text-decoration: none;
  border-radius: 25px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  color: ${COLORS.primary};
  background-color: white;
  border: ${COLORS.primary} solid 2px;
  white-space: nowrap;

  * {
    vertical-align: middle;
  }

  &:hover {
    color: white;
    background-color: ${COLORS.primary};
  }

  ${(props) => {
    switch (props.$stylePreset) {
      case 'default':
        return `
          color: ${COLORS.primary};
          background-color: white;
          border: ${COLORS.primary} solid 2px;

          & > img {
            filter: invert(31%) sepia(78%) saturate(498%) hue-rotate(65deg) brightness(98%) contrast(90%);
          }

          &:hover {
            color: white;
            background-color: ${COLORS.primary};

            & > img {
              filter: invert(100%) brightness(200%);
            }
          }
        `;
      case 'bold':
        return `
          color: white;
          background-color: ${COLORS.primary};
          border: ${COLORS.primary} solid 2px;

          & > img {
                  filter: invert(100%) brightness(200%);
          }

          &:hover {
                  color: ${COLORS.primary};
                  background-color: white;

                  & > img {
                          filter: invert(31%) sepia(78%) saturate(498%) hue-rotate(65deg) brightness(98%) contrast(90%);
                  }
          }
         `;
      case 'dark':
        return `
          color: white;
          background-color: ${COLORS.text};
          border: ${COLORS.text} solid 2px;

          & > img {
            filter: invert(100%) brightness(200%);
          }

          &:hover {
            color: ${COLORS.text};
            background-color: white;

            & > img {
              filter: invert(13%) sepia(53%) saturate(549%) hue-rotate(64deg) brightness(93%) contrast(87%);
            }
          }
        `;
      case 'dark-text':
        return `
          color: ${COLORS.text};
          background-color: white;
          border: ${COLORS.primary} solid 2px;

          &:hover {
            color: white;
            background-color: ${COLORS.primary};
          }`;
      case 'dark-border':
        return `
          color: ${COLORS.text};
          background-color: white;
          border: ${COLORS.text} solid 1px;

          &:hover {
            color: white;
            background-color: ${COLORS.primary};
          }
        `;
    }
  }}

  &:disabled {
    background-color: silver;
    color: dimgray;
    cursor: not-allowed;
  }
`;

LinkButton.propTypes = {
  $stylePreset: PropTypes.oneOf([
    'default',
    'bold',
    'dark',
    'dark-text',
    'dark-border',
  ]),
};

/**
 * Common Text and Text Edit Components
 */

export const TextContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
  align-self: center;
`;

export const Text = styled.div`
  font-size: 50px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #545741;
`;

export const EditBox = styled.div`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
  &:nth-child(odd) {
    background-color: white;
  }
  font-size: 20px;
  max-width: 700px;
  height: 25px;
  color: white;
  border-radius: 5px;
`;

export const EditKey = styled.h1`
  font-size: 20px;
  margin-right: 10px;
  color: white;
`;

export const EditButton = styled.button`
  max-width: 113px;
  height: 25px;
  font-size: 15px;
  color: white;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: ${(props) => (props.bColor ? props.bColor : '#4caf50')};
  border: 2px solid #008000;
  box-shadow: 0 2px #999;
  cursor: pointer;

  &:hover {
    border-color: white;
    border: 1px solid;
  }
`;

EditButton.propTypes = {
  bColor: PropTypes.string,
};

/**
 * Common Container Components
 */

export const VerticalContainer = styled.div`
  display: ${(props) => (props.display ? props.display : 'flex')};
  flex-direction: column;
  margin: ${(props) => (props.m ? props.m : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  align-items: ${(props) => (props.align_items ? props.align_items : 'center')};
  justify-content: ${(props) =>
    props.justify_content ? props.justify_content : 'center'};

  @media (max-width: 1150px) {
    ${(props) => props.tablet};
  }

  @media (max-width: 768px) {
    ${(props) => (props.mobile ? props.mobile : '')}
  }
`;

VerticalContainer.propTypes = {
  display: PropTypes.string,
  m: PropTypes.string,
  mt: PropTypes.string,
  ml: PropTypes.string,
  mb: PropTypes.string,
  mr: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  align_items: PropTypes.string,
  justify_content: PropTypes.string,
  tablet: PropTypes.string,
  mobile: PropTypes.string,
};

export const HorizontalContainer = styled.div`
  display: ${(props) => (props.display ? props.display : 'flex')};
  flex-direction: row;
  margin: ${(props) => (props.m ? props.m : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  align-items: ${(props) => (props.align_items ? props.align_items : 'center')};
  justify-content: ${(props) =>
    props.justify_content ? props.justify_content : 'center'};

  @media (max-width: 1150px) {
    ${(props) => props.tablet};
  }

  @media (max-width: 768px) {
    ${(props) => (props.mobile ? props.mobile : 'flex-direction: column;')}
  }
`;

HorizontalContainer.propTypes = {
  display: PropTypes.string,
  m: PropTypes.string,
  mt: PropTypes.string,
  ml: PropTypes.string,
  mb: PropTypes.string,
  mr: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  align_items: PropTypes.string,
  justify_content: PropTypes.string,
  tablet: PropTypes.string,
  mobile: PropTypes.string,
};

export const Grid = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.templateColumns ? props.templateColumns : 'auto auto auto'};
  grid-template-rows: ${(props) => props.templateRows};
  grid-auto-rows: min-content;
  gap: ${(props) => (props.gap ? props.gap : '20px')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : null)};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  @media (max-width: 1150px) {
    ${(props) => props.tablet};
  }

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    ${(props) => props.mobile};
  }
`;

Grid.propTypes = {
  templateColumns: PropTypes.string,
  templateRows: PropTypes.string,
  gap: PropTypes.string,
  alignItems: PropTypes.string,
  tablet: PropTypes.string,
  mobile: PropTypes.string,
};

export const GridItem = styled.div`
  display: grid;
  align-items: center;
  justify-content: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : 'column'};
  position: ${(props) => (props.position ? props.position : 'static')};
  width: ${(props) => (props.width ? props.width : '100%')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'none')};
  height: ${(props) => props.height};
  margin: ${(props) => (props.m ? props.m : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  border-radius: 15px;
  padding: ${(props) => (props.padding ? props.padding : '25px 30px')};
  align-items: ${(props) =>
    props.alignItems ? props.alignItems : 'flex-start'};
  justify-content: ${(props) =>
    props.justifyContent ? props.justifyContent : 'flex-start'};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px ${COLORS.primary} solid;
  background: ${COLORS.light};

  ${(props) => {
    switch (props.$stylePreset) {
      case 'default':
        return `border: 1px ${COLORS.primary} solid;
                            background: ${COLORS.light};`;
      case 'light':
        return `border: 1px ${COLORS.primary} solid;
                            background: white;`;
      case 'bold':
        return `border: 1px ${COLORS.primary} solid;
                            background: white;
                            border-left: 20px ${COLORS.primary} solid;`;
      case 'borderless':
        return `border: none;
                            background: ${COLORS.light};`;
      case 'secondary':
        return `border: 1px ${COLORS.primary} solid;
                            background: ${COLORS.secondary};`;
      case 'secondary-borderless':
        return `border: none;
                            background: ${COLORS.secondary};`;
    }
  }}

  @media (max-width: 1150px) {
    ${(props) => props.tablet};
  }

  @media (max-width: 768px) {
    width: 100%;
    ${(props) => props.mobile};
  }
`;

ContentContainer.propTypes = {
  flexDirection: PropTypes.string,
  position: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  height: PropTypes.string,
  m: PropTypes.string,
  mt: PropTypes.string,
  ml: PropTypes.string,
  mb: PropTypes.string,
  mr: PropTypes.string,
  padding: PropTypes.string,
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  $stylePreset: PropTypes.oneOf([
    'default',
    'light',
    'bold',
    'borderless',
    'secondary',
    'secondary-borderless',
  ]),
  tablet: PropTypes.string,
  mobile: PropTypes.string,
};

export const PopUpWindow = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-block: 100px;
  backdrop-filter: brightness(60%) blur(5px);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
`;

/**
 * Common Image Components
 */

export const ImageContainer = styled.img`
  object-fit: ${(props) => (props.object_fit ? props.object_fit : 'cover')};
  object-position: ${(props) => props.object_position};
  margin: ${(props) => (props.m ? props.m : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};

  @media (max-width: 1150px) {
    ${(props) => props.tablet};
  }

  @media (max-width: 768px) {
    ${(props) => (props.mobile ? props.mobile : '')}
  }
`;

ImageContainer.propTypes = {
  object_fit: PropTypes.string,
  object_position: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  m: PropTypes.string,
  mt: PropTypes.string,
  ml: PropTypes.string,
  mb: PropTypes.string,
  mr: PropTypes.string,
  tablet: PropTypes.string,
  mobile: PropTypes.string,
};

export const Icon = styled.img`
  display: inline-block;
  width: ${(props) => (props.width ? props.width : '50px')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  margin: ${(props) => (props.m ? props.m : '10px')};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};

  &:hover {
    ${(props) =>
      props.hover ? props.hover : 'filter: brightness(0.85); cursor: pointer;'}
  }

  @media (max-width: 1150px) {
    ${(props) => props.tablet};
  }

  @media (max-width: 768px) {
    ${(props) => props.mobile};
  }

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.6;
      filter: grayscale(1);
      cursor: not-allowed;
    `}
`;

Icon.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  m: PropTypes.string,
  mt: PropTypes.string,
  ml: PropTypes.string,
  mb: PropTypes.string,
  mr: PropTypes.string,
  hover: PropTypes.string,
  tablet: PropTypes.string,
  mobile: PropTypes.string,
};
