import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../utils/Constants';

const InputWrapper = styled.label`
  background-color: ${(props) => props.backgroundColor || COLORS.light};
  color: ${(props) => props.color || COLORS.primary};
  border: ${(props) => `2px solid ${props.borderColor || COLORS.primary}`};
  padding: 8px 12px;
  margin: 8px 0px;
  cursor: pointer;
  display: inline-block;
  border-radius: 4px;
`;

const Input = styled.input`
  display: none;
`;

const FileInput = ({
  backgroundColor,
  color,
  borderColor,
  labelText,
  onChange,
}) => {
  const handleInputChange = (e) => {
    onChange(e);
  };

  return (
    <InputWrapper
      backgroundColor={backgroundColor}
      color={color}
      borderColor={borderColor}
    >
      {labelText}
      <Input type="file" accept="image/*" onChange={handleInputChange} />
    </InputWrapper>
  );
};

export default FileInput;
