import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { sortReportsByDate } from '../../components/SheepReportComponents';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import { localTimeFormatter, localDateFormatter } from '../../utils/TimeUtils';
import { getSelectedRole } from '../../utils/RoleUtils';

const getRetireReports = () => {
  return axios
    .get('/api/tagretirement', {
      params: { selectedRole: getSelectedRole() },
    })
    .then((res) => {
      let data = [...res.data].reverse();
      data = data.map((report) => ({
        ...report,
        dateOfDisposal: localDateFormatter(report.dateOfDisposal),
        dateOfConveyance: report.dateOfConveyance
          ? localDateFormatter(report.dateOfConveyance)
          : '',
        animalTags: report.animals,
      }));
      return data;
    })
    .catch((err) => {
      return [];
    });
};

const tempExportColumns = [
  {
    id: 'dateOfDisposal',
    name: 'Date of Disposal',
  },
  {
    id: 'disposalPID',
    name: 'Disposal Site PID',
  },
  {
    id: 'animals',
    name: 'Tags Retired',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const TagRetireListView = () => {
  const [retireReports, setRetireReports] = useState([]);

  const fetchRetireReports = async () => {
    let fetchedReports = await getRetireReports();
    if (fetchedReports && fetchedReports.length > 0) {
      const validReports = fetchedReports.filter(
        (report) => report.revived === false,
      ); //Filter reports for revived sheep

      validReports.forEach((r) => {
        r.dateRetired = localTimeFormatter(r.dateRetired);

        if (
          r.location &&
          r.location.coordinates &&
          r.location.coordinates.length === 2
        ) {
          r.location =
            r.location.coordinates[1] + ', ' + r.location.coordinates[0];
        } else {
          r.location = '';
        }
      });
      setRetireReports(sortReportsByDate(validReports));
    }
  };

  useEffect(() => {
    fetchRetireReports();
  }, []);

  retireReports.forEach((s) => {
    if (!s.oldIsoNumber) {
      if (s.oldTattooNumber) {
        s.oldIsoNumber = s.oldTattooNumber;
      } else if (s.oldLocalMgmtNumber) {
        s.oldIsoNumber = s.oldLocalMgmtNumber;
      }
    }

    if (!s.newIsoNumber) {
      if (s.newTattooNumber) {
        s.newIsoNumber = s.newTattooNumber;
      } else if (s.newLocalMgmtNumber) {
        s.newIsoNumber = s.newLocalMgmtNumber;
      }
    }
  });

  return (
    <>
      <SheepReportListComponent
        title="Tag Retirement"
        description="A tag retirement report records the disposal of an animal or carcass bearing an approved identifier. You are required to report the disposal of
                    an animal on your premises if it was identified with an approved identifier. You are not required to report the disposal of an animal that has
                    never had an approved identifier affixed."
        detailEndpoint="/reports/retire/"
        fetchReportsFunction={getRetireReports}
        createEndpoint="/reports/retire/create"
        columns={tempExportColumns}
      />
    </>
  );
};

export default TagRetireListView;
