// DateField.js
import React from 'react';
import PropTypes from 'prop-types';
import { FormInputWrapper, FormInputLabel } from './CommonComponents.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

const DateField = ({ id, labelName, onChange, value, required }) => (
  <>
    <FormInputWrapper key={id}>
      <FormInputLabel htmlFor="datepicker">
        {labelName}
        {required && (
          <>
            &nbsp;<RequiredSpan>*</RequiredSpan>
          </>
        )}
      </FormInputLabel>
      <DateDiv>
        <DatePicker selected={value} onChange={onChange} />
        <IconDiv>
          <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
        </IconDiv>
      </DateDiv>
    </FormInputWrapper>
  </>
);

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool.isRequired,
  value: PropTypes.node.isRequired,
};

const RequiredSpan = styled.span`
  color: red;
`;
const DateDiv = styled.div`
  display: flex;
  max-width: 400px;
`;
const IconDiv = styled.div`
  display: inline-block;
  margin-left: 10px;
`;

export default DateField;
