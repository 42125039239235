import axios from 'axios';

export const getSheepByPremiseID = async (endpoint, queryParams) => {
  try {
    const response = await axios.get(endpoint, { params: queryParams });
    return response.data;
  } catch (error) {
    throw new Error(`Failed to fetch sheep data: ${error.message}`);
  }
};

export const getAllSheeps = (inactive = false) => {
  if (inactive) {
    return axios
      .get('/api/sheep/?inactive=true')
      .then((res1) => {
        const inactiveSheep = res1.data;
        return axios.get('/api/sheep').then((res2) => {
          const activeSheep = res2.data;
          return [...activeSheep, ...inactiveSheep];
        });
      })
      .catch((err) => {
        throw new Error(`An error occurred fetching sheep: ${err.message}`);
      });
  } else {
    return axios
      .get('/api/sheep')
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        throw new Error(`An error occurred fetching sheep: ${err.message}`);
      });
  }
};

export const getSheep = async (id, populatePremise) => {
  let endpoint = `/api/sheep/${id}`;
  if (populatePremise) {
    endpoint = `${endpoint}?premise=true`;
  }

  return axios
    .get(endpoint)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(`An error occurred fetching the sheep: ${err.message}`);
    });
};

export const getSheepByISO = async (isoNumber) => {
  let endpoint = `/api/sheep/tag/iso/${isoNumber}`;
  return axios
    .get(endpoint)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(`An error occurred fetching the sheep: ${err.message}`);
    });
};

export const getSheepByIdentifiers = async (identifier, premise) => {
  try {
    const response = await axios.get(`/api/sheep/tag/identifiers/`, {
      params: {
        identifier,
        premise,
      },
    });
    return response.data;
  } catch (err) {
    console.error('Error fetching sheep by identifiers:', err);
    throw err;
  }
};

export const getSheepsForUser = async (username, isAdmin, searchString) => {
  let endpoint = '';
  // Admin users should be able to get all sheep
  if (isAdmin) {
    endpoint = `/api/sheep?premise=true&getInactive=false`;
  } else {
    endpoint = `/api/sheep?username=${username}&premise=true&getInactive=false`;
  }

  // Handle Search
  if (searchString) {
    endpoint = `${endpoint}&search=${searchString}`;
  }

  try {
    const request = await axios.get(endpoint);
    const fetchedSheeps = request.data;

    const filteredSheeps = fetchedSheeps.filter((sheep) => !sheep.movedOut);

    return filteredSheeps;
  } catch (err) {
    throw new Error(
      `An error occurred fetching sheep for user: ${err.message}`,
    );
  }
};

export const getSheepsByPremise = (pid, dateOfArrival, dateOfDeparture) => {
  return axios
    .get(
      `/api/trace/sheep/premise/${pid}?arrivalDate=${dateOfArrival}${
        dateOfDeparture ? `&departureDate=${dateOfDeparture}` : ''
      }`,
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error(`An error occurred fetching sheeps: ${err.message}`);
    });
};
