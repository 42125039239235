// SlaughterReportUtils.js
import axios from 'axios';

const reportEndpoint = '/api/slaughterReport';

const getSlaughterReports = async () => {
  const res = await axios.get(reportEndpoint);
  return res.data;
};

const createSlaughterReport = async (reportToCreate) => {
  const res = await axios.post(reportEndpoint, reportToCreate);
  return res.data;
};

export { getSlaughterReports, createSlaughterReport };
