import { isISONumber } from 'agroledger-shared-library/TagUtils';
import { REPORT_TYPES } from './Constants';

// Helper function to find isoTag based on isISONumber condition
const findIsoTag = (animalTags) => {
  return animalTags.find((tag) => isISONumber(tag)) || null;
};

//  Helper function to format date and time string into a user-readable format
const formatDate = (dateTimeString) => {
  // Check if dateTimeString is valid and not empty
  if (!dateTimeString || typeof dateTimeString !== 'string') {
    return 'Invalid Date';
  }

  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(dateTimeString);

  // Check if the date object is valid
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }
  return new Intl.DateTimeFormat('en-US', options).format(date);
};
// ===========================================
// Getters and Formatters for Reports on Sheep that 'joined'
// ===========================================

const formatImportReports = (importReports) => {
  return importReports.map((report) => {
    const isoTag = findIsoTag(report.animalIdentifications);

    return {
      id: report._id,
      type: REPORT_TYPES.IMPORT,
      submitter: report.user,
      reported_animals_ids: report.animals,
      csip: { label: 'CSIP', value: isoTag ? isoTag : '' }, // Assign CSIP if found, otherwise empty string
      departure_pid: { label: 'Departure PID', value: report.departureSite },
      departure_datetime: {
        label: 'Departure Datetime',
        value: formatDate(report.dateOfArrival),
      },
      destination_pid: {
        label: 'Destination PID',
        value: report.firstDestinationPID,
      },
      destination_datetime: {
        label: 'Destination Datetime',
        value: formatDate(report.dateOfArrival),
      }, // Assuming DesDateTime is receivedTime
      conveyance: {
        label: 'Conveyance',
        value: report.vehicleIdentification || 'N/A',
      },
    };
  });
};

const formatMoveInReports = (moveInReports) => {
  return moveInReports.map((report) => {
    const isoTag = findIsoTag(report.animalTags);

    return {
      id: report._id,
      type: REPORT_TYPES.MOVE_IN,
      submitter: report.user,
      reported_animals_ids: report.animals,
      csip: { label: 'CSIP', value: isoTag ? isoTag : '' }, // Assign CSIP if found, otherwise empty string
      departure_pid: { label: 'Departure PID', value: report.departurePID },
      departure_datetime: {
        label: 'Departure Datetime',
        value: formatDate(report.departureTime),
      },
      destination_pid: {
        label: 'Destination PID',
        value: report.destinationPID,
      },
      destination_datetime: {
        label: 'Destination Datetime',
        value: formatDate(report.receivedTime),
      }, // Assuming DesDateTime is receivedTime
      unload_time: {
        label: 'Loading Datetime',
        value: formatDate(report.unloadTime) || 'N/A',
      },
      conveyance: { label: 'Conveyance', value: report.vehicleNumber || 'N/A' },
    };
  });
};

const formatSheepAddReports = (sheepAddReports) => {
  return sheepAddReports
    .filter((report) => report.action === 'CREATE')
    .map((report) => {
      return {
        id: report._id,
        type: REPORT_TYPES.ADD,
        submitter: report.user,
        reported_animals_ids: report.animals,
        alternate_id: {
          label: 'Alternate ID',
          value:
            report.isoNumber ||
            report.localMgmtNumber ||
            report.tattooNumber ||
            report.usScrapieId,
        },
        add_pid: { label: 'Add PID', value: report.pid },
        add_datetime: {
          label: 'Add Datetime',
          value: formatDate(report.createdAt),
        },
      };
    });
};

// ===========================================
// Getters and Formatters for Reports on Sheep that 'left'
// ===========================================

const formatExportReports = (exportReports) => {
  return exportReports.map((report) => {
    const isoTag = findIsoTag(report.animalTags);

    return {
      id: report._id,
      type: REPORT_TYPES.EXPORT,
      submitter: report.user,
      reported_animals_ids: report.animals,
      csip: { label: 'CSIP', value: isoTag ? isoTag : '' }, // Assign CSIP if found, otherwise empty string
      departure_pid: { label: 'Departure PID', value: report.departurePID }, // To double check as no clear corresponding field exists in ExportLogSchema
      departure_datetime: {
        label: 'Departure Datetime',
        value: formatDate(report.departureTime),
      }, // To double check as no corresponding field exists in ExportLogSchema
      destination_site: {
        label: 'Destination Site',
        value: `${report.destinationSite}-${report.destinationRegion}`,
      },
      conveyance: { label: 'Conveyance', value: report.vehicleNumber || 'N/A' },
    };
  });
};

const formatTransportReports = (transportReports) => {
  return transportReports.map((report) => {
    const isoTag = findIsoTag(report.animalTags);

    return {
      id: report._id,
      type: REPORT_TYPES.TRANSPORT,
      submitter: report.user,
      reported_animals_ids: report.animals.map((animal) => animal._id),
      csip: { label: 'CSIP', value: isoTag ? isoTag : '' }, // Assign CSIP if found, otherwise empty string
      departure_pid: { label: 'Departure PID', value: report.departurePID },
      departure_datetime: {
        label: 'Departure Datetime',
        value: formatDate(report.departureDate),
      },
      destination_pid: {
        label: 'Destination PID',
        value: report.destinationPID,
      },

      conveyance: { label: 'Conveyance', value: report.vehicleNumber || 'N/A' },
    };
  });
};

const formatMoveOutReports = (moveOutReports) => {
  return moveOutReports.map((report) => {
    const isoTag = findIsoTag(report.animalTags);

    return {
      id: report._id,
      type: REPORT_TYPES.MOVE_OUT,
      submitter: report.user,
      reported_animals_ids: report.animals,
      csip: { label: 'CSIP', value: isoTag ? isoTag : '' }, // Assign CSIP if found, otherwise empty string
      departure_datetime: {
        label: 'Departure Datetime',
        value: formatDate(report.departureTime),
      },
      destination_pid: {
        label: 'Destination PID',
        value: report.destinationPID,
      },
      load_time: {
        label: 'Loading Datetime',
        value: formatDate(report.loadTime) || 'N/A',
      },
    };
  });
};

// ===========================================
// Getters and Formatters for Reports on tags
// ===========================================

const formatTagActivationReports = (tagActivationReports) => {
  return tagActivationReports.map((report) => {
    return {
      id: report._id,
      type: REPORT_TYPES.TAG_ACTIVATION,
      submitter: report.user,
      csip: { label: 'CSIP', value: report.isoNumber },
      activation_pid: { label: 'Activation PID', value: report.premise },
      activation_datetime: {
        label: 'Activation Datetime',
        value: formatDate(report.date),
      },
    };
  });
};

const formatTagReplacementReports = (tagReplacementReports) => {
  return tagReplacementReports.map((report) => {
    return {
      id: report._id,
      type: REPORT_TYPES.TAG_REPLACEMENT,
      submitter: report.user,
      old_csip: { label: 'Old CSIP', value: report.oldIsoNumber },
      new_csip: { label: 'New CSIP', value: report.newIsoNumber },
      replacement_pid: { label: 'Replacement PID', value: report.pid },
      replacement_date: {
        label: 'Replacement Date',
        value: formatDate(report.replacementDate),
      },
      conveyance: { label: 'Conveyance', value: report.vehicle || 'N/A' },
    };
  });
};

const formatTagRetirementReports = (tagRetirementReports) => {
  return tagRetirementReports
    .filter((report) => {
      const isoTag = findIsoTag(report.animals);
      return isoTag && report.disposalPID;
    })
    .map((report) => {
      const isoTag = findIsoTag(report.animals);

      return {
        id: report._id,
        type: REPORT_TYPES.TAG_RETIREMENT,
        submitter: report.user,
        csip: { label: 'CSIP', value: isoTag ? isoTag : '' },
        disposal_pid: { label: 'Disposal PID', value: report.disposalPID },
        disposal_datetime: {
          label: 'Disposal Datetime',
          value: formatDate(report.dateOfDisposal),
        },
        conveyance: { label: 'Conveyance', value: report.vehicle || 'N/A' },
        date_of_conveyance: {
          label: 'Date of Conveyance',
          value: formatDate(report.dateOfConveyance) || 'N/A',
        },
      };
    });
};
export {
  formatImportReports,
  formatMoveInReports,
  formatSheepAddReports,
  formatExportReports,
  formatTransportReports,
  formatMoveOutReports,
  formatTagActivationReports,
  formatTagReplacementReports,
  formatTagRetirementReports,
};
