//SheepReplaceViews
//Imports
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import DropDown from '../../components/DropDown.js';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormContainer,
  Button,
  FormTextInput,
  FormInputWrapper,
  FormInputLabel,
} from '../../components/CommonComponents.js';
import Input from '../../components/Input.js';
import FormCard from '../../components/FormCard.js';
import GeolocationInput from '../../components/GeolocationInput';
import { Redirect, useLocation } from 'react-router-dom';
import {
  FetchVehicles,
  VehicleFormatter,
} from '../../components/SheepReportComponents.js';

/* fetches sheep info based on the id given
input: sheep._id
output: sheep + tag information of the sheep */
const fetchSheepInfo = async (id) => {
  try {
    const request = await axios.get(`/api/sheep/${id}?premise=true`);
    const sheepInfo = request.data;
    return sheepInfo;
  } catch (err) {
    throw new Error(`An error occurred fetching sheep info: ${err.message}`);
  }
};

//Submitting spinners
const SubmittingDiv = styled.div`
  text-align: center;
`;
const SubmittingSpinner = () => {
  return (
    <SubmittingDiv>
      <FontAwesomeIcon icon="spinner" spin></FontAwesomeIcon>
      &nbsp;Submitting...
    </SubmittingDiv>
  );
};

const parseSpacing = (form) => {
  if (form) {
    if (form.isoNumber == '') {
      form.isoNumber = undefined;
    }
    if (form.localMgmtNumber == '') {
      form.localMgmtNumber = undefined;
    }
    if (form.tattooNumber == '') {
      form.tatooNumber = undefined;
    }
  }
};

//use to validate the form and set submit button to disable
const validateForm = (form, opType) => {
  if (opType == 'feedlot' && !form.vehicleID && opType == 'exhibition') {
    return false;
  }

  //check if the form exists in the first place
  if (form == null) {
    return false;
  } else if (
    (form.isoNumber && form.isoNumber !== '') ||
    (form.localMgmtNumber && form.localMgmtNumber !== '') ||
    (form.tattooNumber && form.tattooNumber !== '')
  ) {
    return true;
  } else {
    return false;
  }
};

const Info = ({ name, value }) => {
  return (
    <div>
      <span>{name}:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
};

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any,
};

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

//use to validate if the date entered is correct
const validateDate = (dateForm) => {
  if (dateForm == null) {
    return false;
  }

  var todayDate = new Date();
  var minDate = new Date();
  var enteredDate = new Date(dateForm.replaceDate);
  minDate.setDate(todayDate.getDate() - 8);

  if (enteredDate < minDate || enteredDate > todayDate) {
    return false;
  } else {
    return true;
  }
};

const getOpType = (id) => {
  return axios
    .get(`/api/operations/opType/sheep/${id}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err.response.data.message;
    });
};

const SheepReplaceView = () => {
  const [submitting, setSubmitting] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [form, setForm] = useState({});
  const [currOperation, setCurrOperation] = useState({});
  const [dateForm, setDateForm] = useState();
  const [redirect, setRedirect] = useState('');
  const [sheepInfo, setSheepInfo] = useState();

  var fetchedSheepInfo;
  //fetch the ID of the sheep
  const query = useQuery();
  var sheepID;

  const fetchVehicles = async () => {
    const fetchedVehicles = await FetchVehicles();
    const formattedVehicles = VehicleFormatter(fetchedVehicles);

    setVehicles(formattedVehicles);
    return formattedVehicles;
  };

  useEffect(() => {
    // Fetch the sheep by id
    sheepID = query.get('id');
  }, [query]);

  useEffect(() => {
    fetchAndSetSheepInfo().then(() => {
      fetchVehicles();
    });
  }, []);

  //fetch the current sheep's information, as well as tags
  const fetchAndSetSheepInfo = async () => {
    try {
      let fetchedOp = await getOpType(sheepID);
      setCurrOperation(fetchedOp.operationType);
      fetchedSheepInfo = await fetchSheepInfo(sheepID);
      setSheepInfo(fetchedSheepInfo);

      //pre-fill fields so non-unique identifiers are carried over
      setForm({
        ...form,
        localMgmtNumber: fetchedSheepInfo.tag.localMgmtNumber
          ? fetchedSheepInfo.tag.localMgmtNumber
          : '',
        tattooNumber: fetchedSheepInfo.tag.tattooNumber
          ? fetchedSheepInfo.tag.tattooNumber
          : '',
      });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const vehicleDropDown = {
    name: 'Vehicle',
    id: 'vehicleID',
  };

  //const, list all the form inputs
  const formInputs = [
    {
      name: 'New Iso Number',
      hint: 'CSIP number must start with either 124 for Canada, or 840 for US, followed by 3 zeros and then 9 more digits.',
      id: 'newIsoNumber',
    },
    {
      name: 'New Local Management Number',
      id: 'newLocalMgmtNumber',
    },
    {
      name: 'New Tattoo Number',
      id: 'newTattooNumber',
    },
    {
      name: 'New US Scrapie Id',
      hint: 'US state abbreviation, followed by 7 digits',
      id: 'newUsScrapieId',
    },
  ];

  const dateInputs = [
    {
      name: 'Date Replacement, Enter a date between 7 days before to today',
      hint: 'You should enter a date between today and 7 days back ',
      type: 'date',
      id: 'replacementDate',
    },
  ];

  const handleSubmit = async (e) => {
    setSubmitting(true);
    var replaceInfo;
    e.preventDefault();
    parseSpacing(form);
    try {
      if (form == null) {
        toast.error('Invalid, please fill in correct information');
        return;
      }

      if (sheepInfo) {
        replaceInfo = {
          ...form,
        };
      }

      toast.info(
        'Submitting tag replacement to the backend. It may take up to a minute. You will be redirected when the tag have been replaced. Please stay on this page.',
      );
      // Determine which ID to use to search for the sheep
      const oldId =
        sheepInfo.tag.isoNumber ||
        sheepInfo.tag.usScrapieId ||
        sheepInfo.tag.localMgmtNumber ||
        sheepInfo.tag.tattooNumber;

      // Request a new tag for the sheep
      await axios.put(`/api/tagreplacement/${oldId}`, replaceInfo);

      //Set redirect
      toast.success('Tag Replaced successfully!');
      setRedirect(`/sheep`);
    } catch (err) {
      toast.error(err.response.data.message);
      setSubmitting(false);
    }
  };

  const setGeolocation = () => {
    const onSuccess = (position) => {
      const location = [position.coords.longitude, position.coords.latitude];
      setForm({ ...form, location });
    };

    const onError = () => {
      toast.info('Could not get the geolocation of your device');
    };

    if (!navigator.geolocation) {
      toast.info('Geolocation is not supported by this browser');
    } else {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    }
  };

  const clearLocation = () => {
    setForm({ ...form, location: null });
  };

  if (redirect !== '') {
    return <Redirect to={redirect} />;
  }

  return (
    <>
      <FormCard
        title={
          <>
            <>Replace a sheep tag</>
            <br />
          </>
        }
        back
      >
        <FormContainer onSubmit={handleSubmit}>
          {formInputs.map((i) => (
            <>
              <Input
                id={i.id}
                placeholder={i.hint}
                labelName={i.name}
                value={form[i.id]}
                onChange={(e) =>
                  setForm({
                    ...form,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </>
          ))}
          {dateInputs.map((i) => (
            <>
              <FormInputWrapper>
                <FormInputLabel htmlFor="input">{i.name}</FormInputLabel>
                <FormTextInput
                  disabled={i.unique && form.disabled}
                  placeholder={i.hint}
                  type={i.type ? i.type : 'text'}
                  id="input"
                  name={i.id}
                  onChange={(e) => {
                    if (e.target.value === '') {
                      // eslint-disable-next-line no-unused-vars
                      const { [i.id]: _, ...updatedState } = dateForm;
                      setDateForm(updatedState);
                    } else
                      setDateForm({
                        ...dateForm,
                        [e.target.name]: e.target.value,
                      });
                  }}
                />
              </FormInputWrapper>
            </>
          ))}

          <GeolocationInput
            location={form.location}
            getLocation={setGeolocation}
            clearLocation={clearLocation}
          />

          {(currOperation == 'feedlot' ||
            currOperation == 'auction' ||
            currOperation == 'exhibition' ||
            currOperation == 'exporter' ||
            currOperation == 'vetHospital' ||
            currOperation == 'embryoCollectionCenter') && (
            <DropDown
              id={vehicleDropDown.id}
              name={vehicleDropDown.name}
              onChange={(e) => {
                setForm({
                  ...form,
                  vehicleID: e.target.value,
                });
              }}
              value={form[vehicleDropDown.id]}
              options={vehicles.map((o) => ({
                name: o.name,
                value: o.id,
              }))}
              required
            />
          )}

          <Button
            disabled={
              submitting ||
              !validateForm(form, currOperation) ||
              !validateDate(dateForm)
            }
          >
            Submit
          </Button>
          {submitting ? <SubmittingSpinner /> : <></>}
        </FormContainer>
      </FormCard>
    </>
  );
};

//export
export default SheepReplaceView;
