import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { toast } from 'react-toastify';
import PageCard from '../components/PageCard';
import SimpleCard from '../components/SimpleCard';
import StripedTable from '../components/StripedTable';
import { COLORS } from '../utils/Constants';
import { localTimeFormatter } from '../utils/TimeUtils';
import {
  FormTextInput,
  FormInputLabel,
  VerticalContainer,
} from '../components/CommonComponents';
import { getSheepByISO, getSheepByIdentifiers } from '../utils/SheepUtils';
import { isISONumber } from 'agroledger-shared-library/TagUtils';
import { getSelectedRole } from '../utils/RoleUtils';

const columns = [
  { name: 'ISO Number', id: 'isoNumber' },
  { name: 'Identifier', id: 'identifier' },
  { name: 'Premise', id: 'premise' },
  { name: 'Date Searched', id: 'dateSearched' },
  { name: 'Action', id: 'actions' },
];

export default function TraceComponent() {
  const [iso, setIso] = useState('');
  const [premise, setPremise] = useState('');
  const [traceHistory, setTraceHistory] = useState([]);
  const [filteredHistory, setFilteredHistory] = useState([]);
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [tempFilter, setTempFilter] = useState({
    startDate: '',
    endDate: '',
  });
  const [tracePermission, setTracePermission] = useState(true);

  const history = useHistory();

  const handleSearch = async () => {
    try {
      let sheepID;
      let postData = {};
      if (isISONumber(iso)) {
        sheepID = await getSheepByISO(iso);
        postData = { isoNumber: iso };
      } else {
        sheepID = await getSheepByIdentifiers(iso, premise);
        postData = { identifier: iso, premise: premise };
      }
      return axios
        .post('/api/trace/history', postData)
        .then(() => {
          history.push(`/trace/reports/${sheepID._id}`);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err);
          }
        });
    } catch (err) {
      toast.error('Provided Animal not found.');
    }
  };

  const handleSearchAgain = async (isoNumber, identifier, premise) => {
    try {
      let sheepID;
      let postData = {};
      if (isISONumber(isoNumber)) {
        sheepID = await getSheepByISO(isoNumber);
        postData = { isoNumber: isoNumber };
      } else {
        sheepID = await getSheepByIdentifiers(identifier, premise);
        postData = { identifier: identifier, premise: premise };
      }
      return axios
        .post('/api/trace/history', postData)
        .then(() => {
          history.push(`/trace/reports/${sheepID._id}`);
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(err);
          }
        });
    } catch (err) {
      toast.error('Provided Animal not found.');
    }
  };

  const handleTrace = async (isoNumber, identifer, premise) => {
    let sheepID;
    if (isISONumber(isoNumber)) {
      sheepID = await getSheepByISO(isoNumber);
    } else {
      sheepID = await getSheepByIdentifiers(identifer, premise);
    }
    history.push(`/trace/${sheepID._id}`);
  };

  const handleDeleteAction = async (isoNumber, identifier, premise) => {
    let postData = {};
    if (isoNumber && isISONumber(isoNumber)) {
      postData = { data: { isoNumber: isoNumber } };
    } else {
      postData = { data: { identifier: identifier, premise: premise } };
    }
    return axios
      .delete('/api/trace/history', postData)
      .then(() => {
        fetchTraceHistory();
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err);
        }
      });
  };

  const handleDeleteAll = async () => {
    return axios
      .delete('api/trace/history/all')
      .then(() => {
        fetchTraceHistory();
        toast.success('Search History Cleared');
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(err);
        }
      });
  };

  const fetchTraceHistory = async () => {
    try {
      const { data } = await axios.get('/api/trace/history');
      const traces = data.results.map((trace) => ({
        ...trace,
        _id: trace.isoNumber
          ? trace.isoNumber
          : `${trace.premise}${trace.identifer}`,
        dateSearched: localTimeFormatter(trace.dateSearched),
        actions: (
          <SearchDelete
            isoNumber={trace.isoNumber}
            identifier={trace.identifier}
            premise={trace.premise}
          />
        ),
      }));
      setTraceHistory(traces);
      setFilteredHistory(traces);

      if (data.errors.orphanedSearches) {
        toast.error(
          'Some entries in your search history are missing data. Contact an administrator.',
        );
      }
    } catch (err) {
      if (err.response?.statusCode === 403) {
        setTracePermission(false);
      } else if (err.response?.data.message) {
        toast.error(err.response.data.message);
      } else {
        toast.error(err);
      }
    }
  };

  useEffect(() => {
    fetchTraceHistory();
  }, []);

  useEffect(() => {
    if (filter.startDate && filter.endDate) {
      const startDate = new Date(filter.startDate);
      const endDate = new Date(filter.endDate);

      const filtered = traceHistory.filter((trace) => {
        const dateSearched = new Date(trace.dateSearched);
        return dateSearched >= startDate && dateSearched <= endDate;
      });
      setFilteredHistory(filtered);
    } else {
      setFilteredHistory(traceHistory);
    }
  }, [filter, traceHistory]);

  const updateTempFilter = (updatedValue, filterID) => {
    const newFilter = { ...tempFilter, [filterID]: updatedValue };

    if (filterID === 'startDate') {
      if (!tempFilter.endDate) {
        newFilter.endDate = updatedValue;
      } else {
        const startDate = new Date(updatedValue);
        const endDate = new Date(tempFilter.endDate);
        if (startDate > endDate) newFilter.endDate = updatedValue;
      }

      if (!updatedValue) newFilter.endDate = '';
    }

    if (filterID === 'endDate') {
      if (!tempFilter.startDate) {
        newFilter.startDate = updatedValue;
      } else {
        const startDate = new Date(tempFilter.startDate);
        const endDate = new Date(updatedValue);
        if (endDate < startDate) newFilter.startDate = updatedValue;
      }

      if (!updatedValue) newFilter.startDate = '';
    }

    setTempFilter(newFilter);
  };

  const filterFields = [
    {
      id: 'startDate',
      name: 'Start Date',
      type: 'date',
      onChange: (e) => updateTempFilter(e.target.value, 'startDate'),
      onClick: () => updateTempFilter('', 'startDate'),
    },
    {
      id: 'endDate',
      name: 'End Date',
      type: 'date',
      onChange: (e) => updateTempFilter(e.target.value, 'endDate'),
      onClick: () => updateTempFilter('', 'endDate'),
    },
  ];

  const handleApply = () => setFilter(tempFilter);

  const handleClear = (e) => {
    e.preventDefault();
    setTempFilter({ startDate: '', endDate: '' });
    setFilter({ startDate: '', endDate: '' });
  };

  const SearchDelete = ({ isoNumber, identifier, premise }) => (
    <ButtonsContainer>
      <SearchAgainButton
        onClick={() => handleSearchAgain(isoNumber, identifier, premise)}
      >
        Reports Timeline
      </SearchAgainButton>
      <TraceButton onClick={() => handleTrace(isoNumber, identifier, premise)}>
        Trace Sheep
      </TraceButton>
      <DeleteButton
        onClick={() => handleDeleteAction(isoNumber, identifier, premise)}
      >
        Delete
      </DeleteButton>
    </ButtonsContainer>
  );

  if (getSelectedRole() !== 'admin') {
    return (
      <VerticalContainer
        justify_content="center"
        align_items="center"
        mt="50px"
      >
        <h2>You do not have permission to view this page.</h2>
        <Button onClick={() => history.push('/')}>Go Home</Button>
      </VerticalContainer>
    );
  }

  return (
    <PageCard title="Trace A Sheep" back line>
      <ContentContainer>
        <SimpleCard size="md" />

        <InnerContentContainer>
          Enter the ISO Number of the sheep that you would like to trace, or
          another identifier with a premise.
          <div>
            <FormInputLabel>Identification</FormInputLabel>
            <FormTextInput
              value={iso}
              onChange={(e) => setIso(e.target.value)}
            />
            <FormInputLabel>Premise ID</FormInputLabel>
            <FormTextInput
              value={premise}
              onChange={(e) => setPremise(e.target.value)}
            />
            <SearchButton onClick={handleSearch}>Search</SearchButton>
          </div>
        </InnerContentContainer>
      </ContentContainer>
      {tracePermission && (
        <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <h2>Search History</h2>
            {traceHistory && traceHistory.length > 0 && (
              <Button onClick={handleDeleteAll}>Clear Search History</Button>
            )}
          </div>

          <StripedTable
            columns={columns}
            rows={filteredHistory}
            max={10}
            paginate
          />
        </>
      )}
      {!tracePermission && (
        <NoPermissionBlock>
          You do not have permission to trace sheep.
        </NoPermissionBlock>
      )}
    </PageCard>
  );
}

const ContentContainer = styled.div`
  min-width: 80ch;
  margin: 1.5rem auto;
`;

const InnerContentContainer = styled.div`
  padding: 0.5em 1em;
  font-size: 1.2rem;
  text-align: center;

  div {
    margin: 2rem auto;
    width: 50%;
    text-align: left;
  }

  div label {
    display: block;
  }

  div input {
    margin-bottom: 1.25rem;
    width: 100%;
  }
`;

const SearchButton = styled.button`
  font-size: 1.2rem;
  border: none;
  border-radius: 8px;
  padding: 0.5em 1em;
  display: block;
  margin: auto;
  background-color: #17a737;
  cursor: pointer;

  &:hover {
    background-color: #66ad57;
  }
`;

const SearchAgainButton = styled.button`
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5em;
  background-color: #66ad57;
  background-color: ${COLORS.primary};
  cursor: pointer;
`;

const TraceButton = styled.button`
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5em;

  background-color: #66ad57;
  cursor: pointer;
`;

const DeleteButton = styled.button`
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5em;
  background-color: #ff0000;
  cursor: pointer;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 0.5em;
`;

const NoPermissionBlock = styled.div`
  width: max-content;
  margin: 3em auto;
  font-size: 1.5rem;
  padding: 1.5em 3em;
  border-radius: 5px;
  border: 2px solid white;
  background-color: rgb(255, 88, 88);
`;

const Button = styled.button`
  padding: 12px 24px;
  background-color: ${COLORS.primary};
  color: white;
  border: none;
  font-size: 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${COLORS.secondary};
    color: black;
  }
`;
