import React, { useState, useEffect } from 'react';
import { getUsername } from '../../utils/TokenUtils';
import { getUserActiveOperations } from '../../utils/OperationUtils';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath';
import { ContentContainer } from '../../components/CommonComponents';
import AddPremise from '../../components/Forms/AddPremise.js';

export default function PremiseCreate() {
  const [operations, setOperations] = useState([]);
  const username = getUsername();

  const provinces = [
    'NL',
    'PE',
    'NS',
    'NB',
    'QC',
    'ON',
    'MB',
    'SK',
    'AB',
    'BC',
    'YT',
    'NT',
    'NU',
  ];

  const fetchOperations = async () => {
    try {
      const fetchedOperations = await getUserActiveOperations(username);
      setOperations(fetchedOperations);
    } catch (error) {
      console.error('Error fetching operations:', error);
    }
  };

  useEffect(() => {
    fetchOperations();
  }, [username]);

  const paths = [
    { url: '/premises', title: 'Dashboard' },
    { url: '/premises/create', title: 'Add a Premise' },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddPremise
            errors={{}}
            operations={operations}
            provinces={provinces}
          />
        </ContentContainer>
      </div>
    </>
  );
}
