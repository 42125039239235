import { searchableDropdownField } from './commonFields';
import SheepMultiSelector from '../components/SheepMultiSelector';
import React from 'react';
import SearchableDropdown from '../components/SearchableDropdown';
import { createDate, formatDateField } from '../utils/TimeUtils';

const getFormFields = () => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Complete this form to retire an animal, if you do not have any animals, add one here:  `,
      LinkData: { label: 'Add a Sheep', path: '/sheep/create/manual' },
    },
    {
      name: 'Select sheep by',
      hint: 'Select one',
      type: 'select',
      required: true,
      id: 'useSheepMultiSelector',
      options: [
        { name: 'Choosing them from one of your premises', value: true },
        { name: 'Inputting their IDs manually', value: false },
      ],
    },
  ];

  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: `Provide the new identification details for the selected sheep: `,
    },
    {
      name: 'The sheep have been',
      id: 'action',
      type: 'select',
      options: [
        { name: 'Slaughtered', value: 'SLAUGHTER' },
        { name: 'Disposed of on premise', value: 'CARCASSDISPOSAL' },
      ],
      required: true,
    },
    {
      name: 'Date of Disposal',
      id: 'disposalDate',
      type: 'date',
      validity: true,
      required: true,
      value: formatDateField(new Date()),
      errorMessage: 'Date of Disposal cannot be in the future',
      validityCheck: (fieldValues) => {
        if (createDate(fieldValues.value).getTime() > Date.now()) {
          return false;
        }
        return true;
      },
    },
  ];

  // Grouping steps into a single array
  const addRetireFields = [
    {
      name: 'Animal Identification',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'You must provide at least one animal',
    },
    {
      name: 'Retirement Information',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'Required Fields Missing',
    },
  ];

  return addRetireFields;
};

export const getStepOneFields = (
  value,
  animals,
  handleAnimalSelect,
  premises,
  selectedValues,
) => {
  if (value === 'false') {
    return [
      {
        ...searchableDropdownField(
          'Animal IDs',
          'animalIds',
          'sheep',
          'tag.isoNumber',
          animals,
          'Search by Animal ID',
          handleAnimalSelect,
          'Search by animal ID and select the animals to be retired. You can also enter a range of IDs separated by a dash (eg: 124000311111115-124000311111125)',
          true,
          true,
          selectedValues,
        ),
      },
    ];
  } else {
    return [
      {
        name: 'Where should the sheep be selected from?',
        id: 'sourcePremise',
        required: true,
        type: 'select',
        options: premises,
      },
    ];
  }
};

export const getFilteredSheep = (value, animals, handleAnimalSelect) => {
  return [
    {
      name: 'Select Sheep',
      type: 'content',
      id: 'sheepMultiSelector',
      required: true,
      content: (
        <SheepMultiSelector
          sheeps={animals.filter(
            (animal) => animal.premise && animal.premise.pid === value,
          )}
          onChange={(animal) => handleAnimalSelect(animal)}
        />
      ),
    },
  ];
};

export const getStepTwoFields = (
  value,
  premises,
  vehicles,
  selectedVehicle,
  setSelectedVehicle,
) => {
  if (value === 'CARCASSDISPOSAL') {
    return [
      {
        name: 'Disposal Site',
        id: 'disposalSitePID',
        type: 'select',
        options: premises,
        required: true,
      },
    ];
  } else {
    return [
      {
        name: 'Abbatoir Premise',
        id: 'disposalSitePID',
        type: 'select',
        options: premises,
        required: true,
      },
      {
        name: 'Departure Site',
        id: 'departureSitePID',
        type: 'select',
        options: premises,
        required: true,
      },
      {
        name: 'Date of Conveyance',
        id: 'dateOfConveyance',
        type: 'date',
        required: true,
        validity: true,
        value: formatDateField(new Date()),
        errorMessage: 'Date of Conveyance cannot be in the future',
        validityCheck: (fieldValues) => {
          if (
            fieldValues.value &&
            createDate(fieldValues.value).getTime() > Date.now()
          ) {
            return false;
          }
          return true;
        },
      },
      {
        name: 'Vehicle Identification',
        type: 'content',
        description: 'Fill out the identifier or select one of your own',
        requiredLabel: true,
        content: (
          <SearchableDropdown
            id="vehicle-search"
            type="vehicle"
            optionKey="licensePlateNum"
            options={vehicles}
            placeholder="Search by vehicle ID"
            onChange={(vehicle) => setSelectedVehicle(vehicle)}
            onSelect={(vehicle) => setSelectedVehicle(vehicle)}
            selectedValues={selectedVehicle}
            onSelectionChange={setSelectedVehicle}
          />
        ),
      },
    ];
  }
};

export default getFormFields;
