import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import {
  FetchPremises,
  PremisesFormatter,
  FetchVehicles,
  FetchAnimals,
} from '../../components/SheepReportComponents';
import AddReplaceTag from '../../components/Forms/AddReplaceTag.js';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents.js';

export default function TagReplacementCreate() {
  const [redirect, setRedirect] = useState(false);
  const [formattedPremises, setFormattedPremises] = useState([]);
  const [animals, setAnimals] = useState([]);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetVehicles();
    fetchAndSetAnimals();
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      setFormattedPremises(PremisesFormatter(fetchedPremises));
    } catch (err) {
      if (err.code == 404) {
        setFormattedPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetVehicles = async () => {
    try {
      setVehicles(await FetchVehicles());
    } catch (err) {
      if (err.code == 404) {
        setVehicles([]);
      } else {
        throw new Error(
          'We were unable to check which vehicles you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetAnimals = async () => {
    try {
      const animalsFetched = await FetchAnimals();
      setAnimals(animalsFetched);
    } catch (err) {
      if (err.code === 404) {
        setAnimals([]);
      } else {
        throw new Error('Unable to Find any Animals');
      }
    }
  };

  if (redirect) {
    return <Redirect to="/reports/replace/listView" />;
  }

  const paths = [
    { url: '/reports/replace/listView', title: 'Dashboard' },
    {
      url: '/reports/replace/create',
      title: 'Create a Tag Replacement Report',
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddReplaceTag
            errors={{}}
            premises={formattedPremises}
            animals={animals}
            vehicles={vehicles}
          />
        </ContentContainer>
      </div>
    </>
  );
}
