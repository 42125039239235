import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  ImageContainer,
  ContentContainer,
  Grid,
  Icon,
  VerticalContainer,
  LinkButton,
  HorizontalContainer,
} from '../components/CommonComponents';
import reportIcon from '../images/icons/report.png';
import reportHeader from '../images/report_header.png';
import { COLORS } from '../utils/Constants';
import styled from 'styled-components';

import activateTag from '../images/icons/activate_tags.png';
import replaceTag from '../images/icons/replace_tags.png';
import retireTag from '../images/icons/retire_tags.png';

import moveIn from '../images/icons/move_in.png';
import moveOut from '../images/icons/move_out.png';
import transport from '../images/icons/transport.png';
import importIcon from '../images/icons/import.png';
import exportIcon from '../images/icons/export.png';

import add from '../images/icons/add.png';
import edit from '../images/icons/edit.png';
import sighting from '../images/icons/sighting.png';
import medical from '../images/icons/medical.png';
import inventory from '../images/icons/inventory.png';

const ReportsDashboard = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const ReportsHeader = styled(ContentContainer)`
  gap: 10px;
  @media (max-width: 1150px) {
    gap: 0px;
  }
`;

const ReportsHorizontalContainer = styled(HorizontalContainer)`
  h2 {
    font-weight: normal;
    font-size: calc(1vw + 10px);
    margin: auto;
    margin-inline: 10px;
  }
  @media (max-width: 1150px) {
    h2 {
      font-size: calc(1vw + 17px);
    }
  }
  @media (max-width: 768px) {
    h2 {
      font-size: calc(1vw + 15px);
    }
  }
`;

const ReportsDashboardContainer = styled(ContentContainer)`
  border: none;
  padding: 0px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 50px;
  @media (max-width: 1150px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0px;
    width: 83%;
    h3 {
      font-size: 25px;
    }
    p {
      font-size: 20px;
    }
  }
  @media (max-width: 768px) {
    h3 {
      font-size: ;
    }
    p {
      font-size: ;
    }
  }
`;

const ReportsContainer = styled(ContentContainer)`
  padding-top: 5px;
  padding-bottom: 5px;
  h3 {
    margin-bottom: 2px;
  }
`;

const OuterReportsContainer = styled(ContentContainer)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
  }
`;

const InnerReportsContainer = styled(ContentContainer)`
  border: 0px;
  padding: 0px;
  h5,
  p {
    margin: 0px;
    padding: 0px;
  }
  h5 {
    font-size: 16px;
    margin-bottom: 16px;
  }
  p {
    font-size: 14px;
  }
  @media (max-width: 1150px) {
    h5 {
      font-size: 18px;
    }

    p {
      font-size: 16px;
    }
  }
  @media (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    h5 {
      font-size: 22px;
      margin: 0px;
    }
  }
`;

const ReportButton = styled(LinkButton)`
  width: 10%;
  padding-left: 25px;
  font-size: 15px;
  @media (max-width: 768px) {
    width: 35%;
    font-size: 18px;
    margin-left: 5px;
    margin-right: 5px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media (max-width: 660px) {
    width: 30%;
    font-size: 16px;
    padding-left: 20px;
  }
`;

const ReportIcon = styled(Icon)`
  padding: 2px;
  width: 30px;
  @media (max-width: 1150px) {
    width: 70%;
  }
  @media (max-width: 768px) {
    width: 60%;
  }
`;

const BackgroundIcon = styled(ContentContainer)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75px;
  height: 50px;
  margin: none;
  padding: 0px;
  border-color: white;
  border-radius: 50px;
  @media (max-width: 1150px) {
    padding: 3px;
    width: 85px;
    height: 58px;
  }
  @media (max-width: 768px) {
    margin: 2px;
    width: 70px;
    height: 70px;
  }
`;

const NeedHelpContainer = styled(VerticalContainer)`
  margin-top: 80px;

  @media (max-width: 1150px) {
    margin-top: 0px;
    width: 120%;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
    width: 80%;
    margin-bottom: 20px;
  }
`;

const HoldingsIcon = (props) => {
  return (
    <ReportIcon
      src={props.src}
      hover={'none'}
      m={'none'}
      width={'36px'}
      style={{ backgroundColor: `${COLORS.secondary}` }}
    />
  );
};

HoldingsIcon.propTypes = {
  src: PropTypes.any,
};

const tagReportsArray = [
  [
    'Activate Tags',
    activateTag,
    'Activate Tags',
    'A tag activation report records when an approved identifier is first affixed to an animal.',
    '/reports/tagactivation',
  ],
  [
    'Replace Tags',
    replaceTag,
    'Replace Tags',
    'Tag replacement reports recrods the replacement of an approved identifier.',
    '/reports/replace/listView',
  ],
  [
    'Retire Tags',
    retireTag,
    'Retire Tags',
    'A tag retirement report records the disposal of an animal or carcass bearing an approved identifier.',
    '/reports/retire/listView',
  ],
];

const movementReportsArray = [
  [
    'Move-in',
    moveIn,
    'Move-in',
    'A Move-in Report records the receipt of animals onto a premises.',
    '/reports/movein',
  ],
  [
    'Move-out',
    moveOut,
    'Move-out',
    'A Move-out Report records the movement of animals from a premises.',
    '/reports/moveout',
  ],
  [
    'Transport',
    transport,
    'Transport',
    'A Transport Report captures information related to how animals were moved between premises.',
    '/reports/transport/list',
  ],
  [
    'Import',
    importIcon,
    'Import',
    'An Import Report records that animals originating from another country have arrived at their first Canadian premises.',
    '/reports/import',
  ],
  [
    'Export',
    exportIcon,
    'Export',
    'An Export Report records details related to animals moving from a Canadian premises to another country.',
    '/reports/export',
  ],
];

const otherReportsArray = [
  [
    'Add',
    add,
    'Add Sheep',
    'Add a new sheep to AgroLedger.',
    '/sheep/create/manual',
  ],
  [
    'Edit',
    edit,
    'Edit Sheep',
    "Sheep edit reports record all changes made to the animal's identification, age or gender.",
    '/sheep/edit',
  ],
  [
    'Sighting',
    sighting,
    'Sighting',
    'A sheep sighting report records when animals were confirmed as present at a premises on a specific date and time.',
    '/reports/sighting/listview',
  ],
  [
    'Medical',
    medical,
    'Medical',
    "Medical reports record medical events in an animal's history.",
    '/reports/medical/list',
  ],
  [
    'Inventory Management',
    inventory,
    'Inventory',
    'Inventory Management reports record when animals have ben transferred between different premises of a single operation.',
    '/sheep',
  ],
];

function reportsItemGenerator(array) {
  return array.map((item) => {
    const itemTitle = item[0];
    const iconImage = item[1];
    const buttonLabel = item[2];
    const itemDescription = item[3];
    const link = item[4];

    return (
      <OuterReportsContainer
        key={item[0]}
        $stylePreset="light"
        mb="30px"
        tablet="margin-right: 30px;"
        mobile="margin-right: 0px;"
        style={{ borderRadius: '0px' }}
      >
        <BackgroundIcon
          $stylePreset="light"
          style={{ backgroundColor: `${COLORS.secondary}` }}
        >
          <HoldingsIcon src={iconImage} />
        </BackgroundIcon>

        <InnerReportsContainer $stylePreset="light">
          <h5>{itemTitle}</h5>
          <p>{itemDescription}</p>
        </InnerReportsContainer>

        <ReportButton to={link} $stylePreset={'dark-text'}>
          {buttonLabel}
        </ReportButton>
      </OuterReportsContainer>
    );
  });
}

function reportsItemGeneratorMobile(array) {
  return array.map((item) => {
    const itemTitle = item[0];
    const iconImage = item[1];
    const buttonLabel = item[2];
    const itemDescription = item[3];

    return (
      <OuterReportsContainer
        key={item[0]}
        $stylePreset="light"
        mb="30px"
        tablet="margin-right: 30px;"
        mobile="margin-right: 0px;"
        style={{ borderRadius: '0px' }}
      >
        <InnerReportsContainer $stylePreset="light">
          <BackgroundIcon
            $stylePreset="light"
            style={{ backgroundColor: `${COLORS.secondary}` }}
          >
            <HoldingsIcon src={iconImage} />
          </BackgroundIcon>
          <h5>{itemTitle}</h5>
        </InnerReportsContainer>

        <p style={{ marginLeft: '10px', marginRight: '10px' }}>
          {itemDescription}
        </p>

        <ReportButton to="/reports" $stylePreset={'dark-text'}>
          {buttonLabel}
        </ReportButton>
      </OuterReportsContainer>
    );
  });
}

export default function ReportDashboardView() {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  });

  return (
    <ReportsDashboard gap="60px" tablet="display: flex;">
      <h1
        style={{
          gridColumn: '1 / span 2',
          fontSize: 'xxx-large',
          marginRight: 'auto',
        }}
      >
        Reports
      </h1>
      <ReportsHeader
        $stylePreset="borderless"
        flexDirection="row"
        alignItems="center"
        tablet="flex-direction: column; justify-content:space-evenly;"
        style={{
          gridColumn: '1 / span 2',
          boxShadow: '5px 5px 20px #00000029',
        }}
      >
        <ReportsHorizontalContainer
          width="60%"
          tablet="width: 100%; margin-bottom: 20px;"
          mobile="flex-direction: row;"
        >
          <Icon
            src={reportIcon}
            width="100px"
            hover="none"
            tablet="width: 100px;"
            mobile="width: 70px;"
          />
          <h2>
            Welcome to the Reports Dashboard. Reports are created to identify
            events that involve animals. You can find all your reports here,
            organized by event type.
          </h2>
        </ReportsHorizontalContainer>
        <ImageContainer
          src={reportHeader}
          width="40%"
          height="175px"
          object_position="50% 1"
          m="0"
          ml="auto"
          tablet="width: 100%; height: 50%; object-position: 10% 90%;"
          style={{ borderRadius: '25px' }}
        />
      </ReportsHeader>

      <ReportsDashboardContainer $stylePreset="light">
        <VerticalContainer align_items="flex-start" width="120%">
          <h1>Reports Dashboard</h1>
          <VerticalContainer
            width="calc(100% - 60px)"
            mobile="width: 95%; align-self: center;"
            style={{
              background: `${COLORS.secondary}`,
              padding: '30px',
              paddingBottom: '0px',
              borderRadius: '15px',
              marginBottom: '20px',
            }}
          >
            <ReportsContainer $stylePreset="light" width="100%" mb="30px">
              <h3>Tag Reports</h3>
              <p>
                Create and view reports to tag activation, tag replacement, and
                tag retirement here.
              </p>
              {width <= 768
                ? reportsItemGeneratorMobile(tagReportsArray)
                : reportsItemGenerator(tagReportsArray)}
            </ReportsContainer>
          </VerticalContainer>

          <VerticalContainer
            width="calc(100% - 60px)"
            mobile="width: 95%; align-self: center;"
            style={{
              background: `${COLORS.secondary}`,
              padding: '30px',
              paddingBottom: '0px',
              borderRadius: '15px',
              marginBottom: '20px',
            }}
          >
            <ReportsContainer $stylePreset="light" width="100%" mb="30px">
              <h3>Movement Reports</h3>
              <p>
                Create and view animal movement events, organized by event type,
                here.
              </p>
              {width <= 768
                ? reportsItemGeneratorMobile(movementReportsArray)
                : reportsItemGenerator(movementReportsArray)}
            </ReportsContainer>
          </VerticalContainer>

          <VerticalContainer
            width="calc(100% - 60px)"
            mobile="width: 95%; align-self: center;"
            style={{
              background: `${COLORS.secondary}`,
              padding: '30px',
              paddingBottom: '0px',
              borderRadius: '15px',
              marginBottom: '20px',
            }}
          >
            <ReportsContainer $stylePreset="light" width="100%" mb="30px">
              <h3>Other Reports</h3>
              <p>
                Add sheep to your inventory, and create and view other event
                reports here.
              </p>
              {width <= 768
                ? reportsItemGeneratorMobile(otherReportsArray)
                : reportsItemGenerator(otherReportsArray)}
            </ReportsContainer>
          </VerticalContainer>
        </VerticalContainer>

        <NeedHelpContainer align_items="flex-start">
          <ContentContainer>
            <h2 style={{ margin: '0px' }}>Need Help?</h2>
            <p>
              You can find answers to common questions about how to user
              AgroLedger here.
            </p>
            <LinkButton to="/help/" style={{ marginBottom: '0px' }}>
              Help
            </LinkButton>
          </ContentContainer>
        </NeedHelpContainer>
      </ReportsDashboardContainer>
    </ReportsDashboard>
  );
}
