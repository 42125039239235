import React from 'react';
import styled, { css } from 'styled-components';
import { handleImageUrl } from '../../utils/UserUtils.js';
import profile from '../../images/icons/profile.png';

const UserPictureContainer = styled.div`
  display: inline-block;
  text-align: center;
  position: relative; /* Ensure container is positioned relative for absolute positioning */
`;

const ProfilePicture = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  cursor: pointer;
  vertical-align: middle;

  ${(props) =>
    !props.isSelected &&
    css`
      transform: scale(0.8); /* Scale down non-selected pictures */
      opacity: 0.5; /* Reduce opacity for non-selected pictures */
    `}

  ${(props) =>
    props.isSelected &&
    css`
      transform: scale(1.2); /* Scale up the selected picture */
    `}
`;

const ProfileAvatar = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  position: relative;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  vertical-align: middle; /* Ensure vertical alignment */

  ${(props) =>
    !props.isSelected &&
    css`
      transform: scale(0.8); /* Scale down non-selected avatars */
      opacity: 0.5; /* Reduce opacity for non-selected avatars */
    `}

  ${(props) =>
    props.isSelected &&
    css`
      transform: scale(1.2); /* Scale up the selected avatar */
    `}
`;

const UserName = styled.span`
  font-size: 14px;
  display: block;
  margin-top: 5px; /* Adjust as needed */
`;

const UserPicture = ({
  src,
  firstName,
  lastName,
  isSelected,
  onClick,
  showUsername,
}) => {
  const getInitials = (firstName, lastName) => {
    if (firstName && lastName && firstName.length > 0 && lastName.length > 0) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else if (firstName && firstName.length > 0) {
      return `${firstName.charAt(0)}`;
    } else if (lastName && lastName.length > 0) {
      return `${lastName.charAt(0)}`;
    } else {
      return 'User';
    }
  };

  let img = handleImageUrl(src) || profile;
  return (
    <UserPictureContainer>
      {src && src !== '' ? (
        <ProfilePicture
          src={img}
          alt="Profile"
          isSelected={isSelected}
          onClick={onClick}
        />
      ) : (
        <ProfileAvatar isSelected={isSelected} onClick={onClick}>
          {getInitials(firstName, lastName)}
        </ProfileAvatar>
      )}
      {showUsername && <UserName>{firstName}</UserName>}
    </UserPictureContainer>
  );
};

export default UserPicture;
