import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextInputField from './TextInputField';
import FieldLabel from './FieldLabel';

const DropDownMenu = ({ handleFieldChange, field, index, required }) => {
  const [selectedOption, setSelectedOption] = useState(field.value || '');
  const [isCustom, setIsCustom] = useState(false);
  const [customFieldValue, setCustomFieldValue] = useState('');

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
    if (value === 'Other') {
      setIsCustom(true);
    } else {
      setIsCustom(false); // Reset isCustom when other options are selected
      handleFieldChange(index, e); // Propagate selection change
    }
  };

  const handleSubfieldChange = (e) => {
    const { value } = e.target;
    setCustomFieldValue(value); // Update custom field value
    handleFieldChange(index, e); // Propagate custom field change
  };

  const sortedOptions = field.options?.sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  return (
    <div>
      <FieldLabel name={field.name} required={required} />

      <select
        onChange={handleSelectChange}
        disabled={field.disabled || field.options?.length === 0}
        value={selectedOption}
      >
        <option value="">
          {field.options?.length > 0
            ? field.hint || `Select ${field.name}`
            : 'No Selection'}
        </option>
        {sortedOptions?.map((option, optIndex) => (
          <option key={optIndex} value={option.value}>
            {option.name}
          </option>
        ))}
      </select>
      <small style={{ marginTop: '3px' }}>{field.helper}</small>

      {isCustom &&
        (field.subfield ? (
          field.subfield.type === 'text' ? (
            <TextInputField
              field={{
                name: field.subfield.name,
                value: customFieldValue,
                disabled: false,
              }}
              onChange={handleSubfieldChange}
            />
          ) : field.subfield.type === 'select' ? (
            <DropDownMenu
              field={{
                name: field.subfield.name,
                value: customFieldValue,
                disabled: false,
              }}
              onChange={handleSubfieldChange}
            />
          ) : null
        ) : null)}
    </div>
  );
};

DropDownMenu.propTypes = {
  handleFieldChange: PropTypes.func.isRequired,
  field: PropTypes.shape({
    disabled: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    value: PropTypes.string,
    hint: PropTypes.string,
    name: PropTypes.string.isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default DropDownMenu;
