import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../utils/Constants';
import React, { useState, useEffect } from 'react';

/**
 * Sheep selection dropdown
 *  @param {String}             value                         The value of the input field
 *  @param {Array}              sheep                         Array of sheep objects
 *    @param {Object}             sheep.tag                     Object containing the different sheep tags
 *      @param {String}             sheep.tag.isoNumber           US or Canadian ISO number
 *      @param {String}             sheep.tag.usScrapieId         US Scrapie ID
 *      @param {String}             sheep.tag.localMgmtNumber     Local Management Number
 *      @param {String}             sheep.tag.tattooNumber        Tattoo Number
 *  @param {Function}           onChange                      Invoked when the input field's value changes
 *  @param {Function}           onSelect                      Invoked when a sheep is selected
 */
const SheepSelector = ({ value, sheep, onChange, onSelect }) => {
  const [focused, setFocused] = useState(false);

  // You'd think that this is an unnecessarily complex way of simulating the
  // "onfocus" and "onblur" DOM events, and you'd be right, but "onblur" is
  // always executed before click events, making it impossible to select the
  // hovering sheep IDs before they're hidden.
  useEffect(() => {
    window.addEventListener('click', (e) => {
      setFocused(e.target.id === 'sheep-selector');
    });
  }, []);

  return (
    <SearchContainer>
      <input
        autoComplete="off"
        id="sheep-selector"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <div
        style={{
          position: 'absolute',
          maxHeight: '200px',
          backgroundColor: COLORS.secondary,
          border: `1px ${COLORS.primary} solid`,
          overflowY: 'scroll',
          zIndex: '100',
          width: '100%',
        }}
        hidden={!focused}
      >
        {sheep.map((s, i) => (
          <div key={i} onClick={() => onSelect(s)}>
            <p
              style={{
                fontSize: 'large',
                margin: '0',
                paddingBlock: '8px',
                paddingInline: '15px',
              }}
            >
              {s.tag.isoNumber ||
                s.tag.usScrapieId ||
                (s.tag.localMgmtNumber && s.premise?.pid
                  ? `${s.premise.pid} — ${s.tag.localMgmtNumber}`
                  : s.tag.localMgmtNumber) ||
                (s.tag.tattooNumber && s.premise?.pid
                  ? `${s.premise.pid} — ${s.tag.tattooNumber}`
                  : s.tag.tattooNumber)}
            </p>
          </div>
        ))}
      </div>
    </SearchContainer>
  );
};

SheepSelector.propTypes = {
  value: PropTypes.string,
  sheep: PropTypes.array,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
};

const SearchContainer = styled.div`
  position: relative;
  overflow: visible;
  > input {
    width: 100%;
    height: 35px;
    border: ${(props) =>
      props.border ? props.border : `1px solid ${COLORS.primary}`};
    border-radius: 15px;
    background: ${(props) => (props.background ? props.background : 'white')};
    font-size: 14px;
    outline: none;
    padding: 8px 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
  > div > div:hover {
    backdrop-filter: brightness(0.8);
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
`;

export default SheepSelector;
