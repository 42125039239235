import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { getMoveLogsForVehicles } from '../utils/MoveUtils';
import SheepRelatedMovesComponent from '../components/SheepRelatedMovesComponent';
import PageCard from '../components/PageCard';

export default function MoveHistoryListView(props) {
  const history = useHistory();
  const isoNumber = props.match.params.isoNumber;
  const location = useLocation();
  const [vehiclesArray, setVehiclesArray] = useState([]);

  useEffect(() => {
    // Check if location.state is defined before accessing relatedVehicles
    if (location.state && location.state.relatedVehicles) {
      setVehiclesArray(location.state.relatedVehicles);
    }
  }, [location]);

  const moveOutColumns = [
    {
      id: 'type',
      name: 'Type',
    },
    {
      id: 'vehicleNumber',
      name: 'Vehicle',
    },
    {
      id: 'departurePremise',
      name: 'Departure',
    },
    {
      id: 'destinationPremise',
      name: 'Destination',
    },
    {
      id: 'animalTags',
      name: 'Associated Animals',
    },
    {
      id: 'departureTime',
      name: 'Date of Departure',
    },
    {
      id: 'actions',
      name: 'Actions',
    },
  ];
  const convertToAmPm = (time) => {
    const hourMinute = time.split(':');
    let hr = +hourMinute[0];
    let min = +hourMinute[1];
    const ampm = hr >= 12 ? 'pm' : 'am';
    hr = hr % 12;
    hr = hr > 0 ? hr : 12; // 0 hr should be 12
    min = min < 10 ? '0' + min : min;
    return `${hr}:${min}${ampm}`;
  };
  const fetchAndTransformMoveLogs = async () => {
    const response = await getMoveLogsForVehicles(vehiclesArray, isoNumber);
    const movements = response.data;
    let styledMovements = movements.map((moveLog) => ({
      ...moveLog,
      animalTags:
        moveLog.animalTags.filter((tag) => tag !== isoNumber).join(', ') ||
        'None',
      departurePremise: moveLog.departurePID ? moveLog.departurePID : '',
      destinationPremise: moveLog.destinationPID ? moveLog.destinationPID : '',
      departureTime: new Date(moveLog.departureTime)
        .toISOString()
        .split('T')[0],
      loadTime: moveLog.loadTime ? convertToAmPm(moveLog.loadTime) : '',
      type: moveLog.moveOut ? 'Move-Out' : 'Move-In',
    }));
    return styledMovements;
  };
  const customBack = () => {
    history.goBack();
  };

  return (
    <PageCard
      title={`Move History Related to ${isoNumber}`}
      back
      line
      onBack={customBack}
    >
      {vehiclesArray.length > 0 && (
        <SheepRelatedMovesComponent
          title="Move History"
          description="Here is a list of movement histories for the related vehicles of a specific sheep."
          fetchReportsFunction={fetchAndTransformMoveLogs}
          detailEndpoint={{
            moveout: '/reports/moveout/',
            movein: '/reports/movein/',
          }}
          createEndpoint={{
            pathname: '/reports/moveout/new',
            state: { isoNumber: isoNumber },
          }}
          columns={moveOutColumns}
          vehiclesArray={vehiclesArray}
        />
      )}
    </PageCard>
  );
}
