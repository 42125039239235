import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { COLORS } from '../../utils/Constants';
import { SubmittingSpinner } from '../CommonComponents';

const CustomButton = ({
  width,
  $stylePreset = 'default',
  children,
  onClick,
  alignSelf,
  disabled = false,
  submitting = false,
  ...rest
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const getButtonStyle = () => {
    let buttonStyle = {
      display: 'inline-block',
      textAlign: 'center',
      alignSelf: alignSelf,
      minWidth: '60px',
      width: width ? width : 'min-content',
      fontSize: rest.fontSize ? rest.fontSize : 'large',
      fontWeight: 'bold',
      marginBlock: '10px',
      textDecoration: 'none',
      padding: '10px 20px',
      borderRadius: '4px',
      boxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      WebkitBoxSizing: 'border-box',
      cursor: disabled || submitting ? 'not-allowed' : 'pointer',
      transition:
        'background-color 0.3s, color 0.3s, border-color 0.3s, opacity 0.3s',
      opacity: isHovered && !disabled && !submitting ? 0.8 : 1,
    };

    if (disabled || submitting) {
      buttonStyle.backgroundColor = COLORS.disabled;
      buttonStyle.color = COLORS.light;
      buttonStyle.border = `2px solid ${COLORS.disabled}`;
    } else {
      switch ($stylePreset) {
        case 'next-active':
          buttonStyle.backgroundColor = COLORS.primary;
          buttonStyle.color = COLORS.light;
          buttonStyle.border = `2px solid ${COLORS.primary}`;
          break;
        case 'next-disabled':
          buttonStyle.backgroundColor = COLORS.disabled;
          buttonStyle.color = COLORS.light;
          buttonStyle.border = `2px solid ${COLORS.disabled}`;
          break;
        case 'back-active':
          buttonStyle.backgroundColor = COLORS.light;
          buttonStyle.color = COLORS.primary;
          buttonStyle.border = `2px solid ${COLORS.primary}`;
          break;
        case 'back-disabled':
          buttonStyle.backgroundColor = COLORS.disabled;
          buttonStyle.color = COLORS.light;
          buttonStyle.border = `2px solid ${COLORS.disabled}`;
          break;
        case 'submit-active':
          buttonStyle.backgroundColor = COLORS.primary;
          buttonStyle.color = COLORS.light;
          buttonStyle.border = `2px solid ${COLORS.primary}`;
          break;
        case 'submit-disabled':
          buttonStyle.backgroundColor = COLORS.disabled;
          buttonStyle.color = COLORS.light;
          buttonStyle.border = `2px solid ${COLORS.disabled}`;
          break;
        default:
          buttonStyle.backgroundColor = COLORS.primary;
          buttonStyle.color = COLORS.light;
          buttonStyle.border = `2px solid ${COLORS.primary}`;
      }
    }

    return buttonStyle;
  };
  const handleHover = () => {
    if (!disabled) {
      setIsHovered(!isHovered);
    }
  };

  return (
    <button
      style={getButtonStyle()}
      onClick={submitting ? undefined : onClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleHover}
      onFocus={handleHover}
      onBlur={handleHover}
      disabled={disabled || submitting}
      {...rest}
    >
      {submitting ? <SubmittingSpinner /> : children}
    </button>
  );
};

CustomButton.propTypes = {
  width: PropTypes.string,
  alignSelf: PropTypes.string,
  $stylePreset: PropTypes.oneOf([
    'default',
    'next-active',
    'next-disabled',
    'back-active',
    'back-disabled',
    'submit-active',
    'submit-disabled',
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  submitting: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default CustomButton;
