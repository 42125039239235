import React, { useState, useEffect } from 'react';
import { countries } from '../../utils/Countries';
import { getUsername } from '../../utils/TokenUtils';
import {
  FetchPremises,
  PremisesFormatter,
  FetchVehicles,
} from '../../components/SheepReportComponents';
import { getUserActiveOperations } from '../../utils/OperationUtils.js';
import { getPremisesById } from '../../utils/PremiseUtils.js';
import { NavigationPath } from '../../components/reusedComponents/NavigationPath.js';
import { ContentContainer } from '../../components/CommonComponents';
import AddImport from '../../components/Forms/AddImport.js';

const ImportManualCreateView = () => {
  const [formattedPremises, setFormattedPremises] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [abattoirPids, setAbbatoirPids] = useState([]);

  useEffect(() => {
    fetchAndSetPremises();
    fetchAndSetVehicles();
    fetchAndSetAbbatoirs(getUsername());
  }, []);

  const fetchAndSetPremises = async () => {
    try {
      const fetchedPremises = await FetchPremises();
      setFormattedPremises(PremisesFormatter(fetchedPremises));
    } catch (err) {
      if (err.code == 404) {
        setFormattedPremises([]);
      } else {
        throw new Error(
          'We were unable to check which premises you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetVehicles = async () => {
    try {
      setVehicles(await FetchVehicles());
    } catch (err) {
      if (err.code == 404) {
        setVehicles([]);
      } else {
        throw new Error(
          'We were unable to check which vehicles you have access to. Try again later',
        );
      }
    }
  };

  const fetchAndSetAbbatoirs = async (username) => {
    const userOperations = await getUserActiveOperations(username);
    let abattoirIDs = [];
    userOperations.map((op) => {
      if (op.operationType === 'abattoir') {
        abattoirIDs = abattoirIDs.concat(op.premises);
      }
    });
    const promises = abattoirIDs.map((id) =>
      typeof id === 'string' ? getPremisesById(id) : getPremisesById(id._id),
    );
    let abattoirPids = await Promise.all(promises);
    abattoirPids = abattoirPids.map((ab) => ab.pid);
    setAbbatoirPids(abattoirPids);
  };

  const paths = [
    { url: '/reports/import', title: 'Dashboard' },
    {
      url: '/reports/import/create/manual',
      title: 'Create an Import Report',
    },
  ];

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex' }}>
          <NavigationPath paths={paths} />
        </div>
        <ContentContainer
          alignItems="center"
          height="100%"
          flexDirection="column"
        >
          <AddImport
            errors={{}}
            premises={formattedPremises}
            countries={countries}
            abattoirPids={abattoirPids}
            vehicles={vehicles}
          />
        </ContentContainer>
      </div>
    </>
  );
};
export default ImportManualCreateView;
