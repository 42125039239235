import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import FieldLabel from './FieldLabel';
import { COLORS } from '../../utils/Constants';

const FormFieldWrapper = styled.div`
  margin-bottom: 7px;
  justifyalignment: 'center';
`;

export const StyledInput = styled.input`
  width: 100%;
  padding: 8px 30px 8px 10px;

  box-sizing: border-box;
  min-height: 36px;
  ${(props) => props.disabled && 'opacity: 0.6;'}
  width: 100%;
  height: 35px;
  border: ${(props) =>
    props.border ? props.border : `1px solid ${COLORS.primary}`};
  border-radius: ${(props) => props.inputBorderRadius || '10px'};
  background: ${(props) =>
    props.inputBackground ? props.inputBackground : 'white'};
  font-size: 14px;
  outline: none;
  padding: 8px 15px;

  &:disabled {
    background-color: ${COLORS.secondary};
    color: ${COLORS.text};
    opacity: 0.85;
  }
`;

const PeekIconContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
`;

const TextInputField = ({
  field,
  value,
  onChange,
  type,
  showPeek,
  onTogglePeek,
  disabled,
}) => (
  <FormFieldWrapper>
    <FieldLabel name={field.name} required={field.required} />
    <div
      style={{
        position: 'relative',
        width: '100%',
      }}
    >
      <StyledInput
        type={type === 'password' && !showPeek ? 'password' : 'text'}
        onChange={onChange}
        placeholder={field.hint}
        value={field.value || value || ''}
        disabled={field.disabled || disabled === true}
      />
      {!disabled && (
        <>
          {type === 'password' && !showPeek && (
            <PeekIconContainer onClick={onTogglePeek}>
              <FontAwesomeIcon icon={faEye} color="gray" />
            </PeekIconContainer>
          )}
          {showPeek && (
            <PeekIconContainer onClick={onTogglePeek}>
              <FontAwesomeIcon icon={faEyeSlash} color="gray" />
            </PeekIconContainer>
          )}
        </>
      )}
    </div>

    {type === 'text' && (
      <small style={{ marginTop: '3px' }}>{field.helper}</small>
    )}

    {field.validity === false && field.value !== '' && (
      <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
        {field.errorMessage === '' ? 'Invalid input.' : field.errorMessage}
      </p>
    )}
  </FormFieldWrapper>
);

TextInputField.propTypes = {
  field: PropTypes.shape({
    disabled: PropTypes.bool,
    hint: PropTypes.string,
    helper: PropTypes.string,
    errorMessage: PropTypes.string,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    validity: PropTypes.bool,
    value: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['text', 'password']),
  showPeek: PropTypes.bool,
  onTogglePeek: PropTypes.func,
};

export default TextInputField;
