// TagActivationDetailView.js
import React from 'react';
import SheepReportDetailView from '../../components/SheepReportDetailView';
import { useParams } from 'react-router';

const RawDetailView = () => {
  const params = useParams();
  const id = params.id;

  return (
    <>
      <SheepReportDetailView
        title={'Disposal'}
        verifyLink={`/verify/raw/${id}`}
        reportType="disposal"
      />
    </>
  );
};

export default RawDetailView;
