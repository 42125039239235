import { operationSelect } from './commonFields';
import { regionOptions } from '../utils/VehicleUtils';
const getFormFields = (operations) => {
  // Step 1 fields
  const stepOneFields = [
    {
      type: 'instruction',
      subtype: 'withLink',
      message: `Please add the associated Operation for your vehicle if you have not
            already done so. `,
      LinkData: { label: 'Add an Operation', path: '/operations/create' },
    },
    { ...operationSelect(operations) },
  ];

  // Step 2 fields
  const stepTwoFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: 'Enter the identification information for your vehicle: ',
    },
    {
      name: 'Vehicle Identification',
      id: 'licensePlateNum',
      description: 'License Plate, VIN Number, or Description',
      type: 'text',
      required: true,
      validityCheck: (fieldValues) => {
        if (!/^[A-Za-z0-9 ]+$/.exec(fieldValues.licensePlateNum)) {
          return 'Vehicle Identification must only contain alphanumeric characters';
        } else {
          return true;
        }
      },
    },
    {
      name: 'Vehicle Name',
      id: 'name',
      type: 'text',
    },
  ];

  // Step 3 fields
  const stepThreeFields = [
    {
      type: 'instruction',
      subtype: 'simple',
      message: 'Enter the registration location for your vehicle: ',
    },
    {
      name: 'Country of Registration',
      id: 'country',
      required: true,
      type: 'select',
      options: Object.keys(regionOptions).map((loc) => ({
        name: loc,
        value: loc,
      })),
    },
    {
      name: 'Region',
      id: 'locations',
      required: true,
      type: 'select',
      disabled: true,
      optionsFilter: (fieldValues) => {
        const countryField = fieldValues.find(
          (field) => field.id === 'country',
        );
        return countryField.value !== ''
          ? regionOptions[countryField.value]
          : [];
      },
    },
  ];

  // Grouping steps into a single array
  const addVehicleFields = [
    {
      name: 'Operation Selection',
      fields: stepOneFields,
      isValid: false,
      errorMessage: 'Operation is Required',
    },
    {
      name: 'Vehicle Identification',
      fields: stepTwoFields,
      isValid: false,
      errorMessage: 'You must provide a vehicle identifier',
    },
    {
      name: 'Registration Location',
      fields: stepThreeFields,
      isValid: false,
      errorMessage: 'Required fields are missing',
    },
  ];
  return addVehicleFields;
};

export default getFormFields;
