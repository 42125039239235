import { getMedicalReports } from '../../utils/MedicalReportUtils';
import { sortReportsByDate } from '../../components/SheepReportComponents';
import SheepReportListComponent from '../../components/SheepReportListComponent';
import { localDateFormatter } from '../../utils/TimeUtils';
import React from 'react';

const columns = [
  {
    id: 'firstTreatmentDate',
    name: 'First Treatment Date',
  },
  {
    id: 'premiseID',
    name: 'Sheep Premise ID',
  },
  {
    id: 'medicine',
    name: 'Product Used',
  },
  {
    id: 'treatmentReason',
    name: 'Reason for Treatment',
  },
  {
    id: 'animalTags',
    name: 'Sheep Treated',
  },
  {
    id: 'actions',
    name: 'Actions',
  },
];

const MedicalReportListView = () => {
  const fetchMedicalReports = async () => {
    let fetchedReports = await getMedicalReports();

    fetchedReports.forEach((r) => {
      r.firstTreatmentDate = localDateFormatter(r.firstTreatmentDate);
      r.finalTreatmentDate = localDateFormatter(r.finalTreatmentDate);
      r.meatWithdrawalDate = localDateFormatter(r.meatWithdrawalDate);
      r.milkWithdrawalDate = localDateFormatter(r.milkWithdrawalDate);
    });
    return sortReportsByDate(fetchedReports);
  };

  return (
    <SheepReportListComponent
      title="Sheep Medical"
      description="Medical reports record medical events in an animal's 
      history and can include information like antimicrobial treatments 
      and vaccines. Medical reports are not mandatory but they can be 
      useful for keeping track of treatments for management and food 
      safety purposes. You can view and create medical reports here."
      fetchReportsFunction={fetchMedicalReports}
      detailEndpoint="/reports/medical/"
      createEndpoint="/reports/medical/create"
      columns={columns}
    />
  );
};

export default MedicalReportListView;
