import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import {
  FormContainer,
  FormInputWrapper,
  FormInputLabel,
  FormTextInput,
  Button,
} from '../components/CommonComponents';
import FormCard from '../components/FormCard.js';
import axios from 'axios';
import { toast } from 'react-toastify';

const ForgotUsernameView = () => {
  const [email, setEmail] = useState('');
  const [redirect, setRedirect] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`/api/users/forget`, { email });
      toast.success(
        'The username associated with the email was sent to your mailbox.',
      );
      setRedirect('/login');
    } catch (err) {
      toast.error(`Couldn't send the email: ${err}`);
    }
  };

  if (redirect !== '') return <Redirect to={redirect} push />;

  return (
    <FormCard title="Forgot Username" back>
      <FormContainer>
        <FormInputWrapper>
          <FormInputLabel htmlFor="input">Email</FormInputLabel>
          <FormTextInput
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormInputWrapper>
        <Button
          type="submit"
          disabled={email === ''}
          onClick={async (e) => await handleSubmit(e)}
        >
          Submit
        </Button>
      </FormContainer>
    </FormCard>
  );
};

export default ForgotUsernameView;
