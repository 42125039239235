import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import FormHeader from './FormHeader.js';
import { COLORS } from '../../utils/Constants';
import { Redirect, useHistory } from 'react-router-dom';

const StyledFormContainer = styled.form`
  display: ${(props) => (props.display ? props.display : 'flex')};
  grid-template-columns: ${(props) => props.gridTemplateColumns || '1fr 1fr'};

  flex-direction: column;
  justify-content: ${(props) =>
    props.justify_content ? props.justify_content : 'center'};
  align-items: ${(props) => (props.align_items ? props.align_items : 'center')};
  min-width: ${(props) => props.minWidth || '50%'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || '100%'};
  background-color: ${(props) => props.backgroundColor || COLORS.light};
  color: ${(props) => props.color || COLORS.text};
  border-radius: ${(props) => props.borderRadius || '25px'};
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: ${(props) => props.padding};

  @media (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }

  input:not([type='checkbox']),
  select {
    width: 100%;
    height: 35px;
    border: ${(props) =>
      props.border ? props.border : `1px solid ${COLORS.primary}`};
    border-radius: ${(props) => props.inputBorderRadius || '15px'};
    background: ${(props) =>
      props.inputBackground ? props.inputBackground : 'white'};
    font-size: 14px;
    outline: none;
    padding: 8px 15px;

    &:disabled {
      background-color: ${COLORS.secondary};
      color: ${COLORS.text};
      opacity: 0.85;
    }

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
`;

const StyledForm = ({ children, title, onCancelPath, ...props }) => {
  const [redirect, setRedirect] = useState(false);
  const history = useHistory();

  const onCancel = () => {
    sessionStorage.clear();
    setRedirect(true);
  };

  if (redirect) {
    return <Redirect to={onCancelPath} />;
  }

  return (
    <>
      {title && title.placement === 'outside' && (
        <FormHeader
          title={title.text}
          onCancel={onCancelPath ? onCancel : history.goBack}
        />
      )}
      <StyledFormContainer {...props}>
        {title && title.placement === 'inside' && (
          <FormHeader
            title={title.text}
            onCancel={onCancelPath ? onCancel : null}
          />
        )}
        {children}
      </StyledFormContainer>
    </>
  );
};

StyledForm.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.object.isRequired,
  onCancelPath: PropTypes.string,

  maxWidth: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  padding: PropTypes.string,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  borderRadius: PropTypes.string,
  outline: PropTypes.string,
  border: PropTypes.string,
  inputBorderRadius: PropTypes.string,
  inputBackground: PropTypes.string,
};

export default StyledForm;
