import React from 'react';
import styled from 'styled-components';

const LoadingCard = () => (
  <StyledLoadingCard>
    <LoadingMsg>
      Please do not navigate away from this page until request is processed.
    </LoadingMsg>
    <LoadingMsg>This may take between 20 seconds to 40 seconds.</LoadingMsg>
  </StyledLoadingCard>
);

export default LoadingCard;

const StyledLoadingCard = styled.div`
  background-color: grey;
  margin: 0 10px 20px;
  border-radius: 10px;
  padding: 5px 10px 10px;
`;

const LoadingMsg = styled.div`
  font-size: 30px;
  margin-top: 15px;
`;
