import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { COLORS } from '../utils/Constants';
import {
  LinkButton,
  VerticalContainer,
  HorizontalContainer,
  ContentContainer,
  Icon,
} from './CommonComponents';
import left from '../images/icons/chevron_left.png';
import right from '../images/icons/chevron_right.png';

/**
 * Table react element
 * @prop   {Array}     columns             Array of table column strings
 * @prop   {Array}     rows                Array of table row data arrays
 * @prop   {Integer}   maxShowing          (optional) Maximum number of rows displayed in the table
 * @prop   {String}    viewMoreLink        Endpoint that directs user to a view that displays all of the table's rows
 * @prop   {String}    $stylePreset         String indicating which style preset should be used
 */
const Table = ({
  columns,
  rows,
  maxShowing = 10,
  viewMoreLink,
  $stylePreset,
  paginate = false,
}) => {
  const [pageSize, setPageSize] = useState(maxShowing);
  const [currentPage, setCurrentPage] = useState(1);

  const objectToArray = (object) => {
    var { error, ...objectToConvert } = object;

    return Object.values(objectToConvert).map((value) =>
      value !== undefined ? value : 'N/A',
    );
  };

  const filteredRows = rows
    .filter((row) => row !== undefined && row !== null)
    .map((row) => {
      if (typeof row === 'object') {
        return row.error
          ? { rowData: objectToArray(row), error: row.error }
          : { rowData: objectToArray(row) };
      } else {
        return { rowData: [row] };
      }
    });

  const totalPages = Math.ceil(filteredRows.length / pageSize);
  const currentRows = paginate
    ? filteredRows.slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : filteredRows;

  return (
    <TableWrapper tablet="width: 90vw !important;">
      <StyledTable $stylePreset={$stylePreset}>
        <thead>
          <tr>
            {columns.map((column) => {
              return (
                <StyledTh key={column} $stylePreset={$stylePreset}>
                  {column}
                </StyledTh>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {currentRows.map((row, index) => {
            return (
              <>
                <StyledTr key={row.rowData} $stylePreset={$stylePreset}>
                  {row.rowData.map((data, i) => (
                    <StyledTd key={i} $stylePreset={$stylePreset}>
                      {data}
                    </StyledTd>
                  ))}
                </StyledTr>
                {row.error && (
                  <tr style={{ color: COLORS.error }}>
                    <td colSpan={columns.length}>{row.error}</td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </StyledTable>
      <HorizontalContainer width="100%">
        <NumberOfResults>
          Showing {Math.min(filteredRows.length, pageSize)} of{' '}
          {filteredRows.length} results
        </NumberOfResults>
        <PaginationInfo>Records per page: </PaginationInfo>
        <PageSizeSelector
          pageSize={pageSize}
          setPageSize={setPageSize}
          onPageChange={setCurrentPage}
        />
        {viewMoreLink && (
          <ViewMoreButton $stylePreset="dark" to={viewMoreLink}>
            View more
          </ViewMoreButton>
        )}
      </HorizontalContainer>
      {paginate && (
        <HorizontalContainer width="100%">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={setCurrentPage}
          />
        </HorizontalContainer>
      )}
    </TableWrapper>
  );
};

const PageSizeSelector = ({ pageSize, setPageSize, onPageChange }) => {
  return (
    <select
      value={pageSize}
      style={{
        borderColor: 'green',
        borderRadius: '10px',
        padding: '5px',
        paddingLeft: '20px',
        paddingRight: '20px',
        fontWeight: 'bold',
        fontSize: '15px',
        maxWidth: '75px',
      }}
      onChange={(e) => {
        setPageSize(Number(e.target.value));
        onPageChange(1);
      }}
    >
      {[3, 5, 10, 15, 25, 50].map((pageSize) => {
        return (
          <option key={pageSize} value={pageSize}>
            {pageSize}
          </option>
        );
      })}
    </select>
  );
};

const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  return (
    <PaginationContainer>
      <HorizontalContainer mobile="none" style={{ alignSelf: 'center' }}>
        <h3
          style={{
            paddingBlock: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
          }}
          onClick={() => {
            onPageChange(1);
          }}
        >
          {currentPage === 1 ? '...' : 1}
        </h3>
        <Icon
          width="25px"
          src={left}
          hover="filter: opacity(0.5)"
          disabled={currentPage === 1}
          onClick={() => {
            if (currentPage > 1) {
              onPageChange(currentPage - 1);
            }
          }}
        />
        {currentPage - 1 >= 1 && (
          <h3
            style={{
              paddingBlock: '5px',
              paddingInline: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onPageChange(currentPage - 1);
            }}
          >
            {currentPage - 1}
          </h3>
        )}
        <h3
          style={{
            background: COLORS.secondary,
            borderRadius: '10px',
            paddingBlock: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
          }}
        >
          {currentPage}
        </h3>
        {currentPage + 1 <= totalPages && (
          <h3
            style={{
              paddingBlock: '5px',
              paddingInline: '10px',
              cursor: 'pointer',
            }}
            onClick={() => {
              onPageChange(currentPage + 1);
            }}
          >
            {currentPage + 1}
          </h3>
        )}
        <Icon
          width="25px"
          src={right}
          hover="filter: opacity(0.5)"
          disabled={currentPage === totalPages}
          onClick={() => {
            if (currentPage < totalPages) {
              onPageChange(currentPage + 1);
            }
          }}
        />
        <h3
          style={{
            paddingBlock: '5px',
            paddingInline: '10px',
            cursor: 'pointer',
          }}
          onClick={() => {
            onPageChange(totalPages);
          }}
        >
          {currentPage === totalPages ? '...' : totalPages}
        </h3>
      </HorizontalContainer>
    </PaginationContainer>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  maxShowing: PropTypes.number,
  rows: PropTypes.array,
  $stylePreset: PropTypes.any,
  viewMoreLink: PropTypes.string,
  paginate: PropTypes.bool,
};

const TableWrapper = styled(VerticalContainer)`
  width: 100%;
  align-items: flex-start;

  overflow-x: auto;
  overflow-y: hidden;

  ::-webkit-scrollbar {
    width: 20px;
  }
  ::-webkit-scrollbar-track {
    border: 1px solid ${COLORS.primary};
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${COLORS.text};
    border: 3px solid transparent;
    border-radius: 10px;
    background-clip: content-box;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  text-align: left;

  ${(props) => {
    switch (props.$stylePreset) {
      case 'boxyScrollable':
        return `
                    border-spacing: 0px;
                `;
      default:
        return `
                    border-spacing: 0px 10px;
                `;
    }
  }}
`;

const StyledTr = styled.tr`
  background: ${COLORS.light};
`;

const StyledTh = styled.th`
  text-align: center;

  ${(props) => {
    switch (props.$stylePreset) {
      case 'boxyScrollable':
        return `
                    background: ${COLORS.secondary};
                    height: 70px;
                    width: auto;
                    padding-block: auto;
                    padding-inline: 30px;
                    font-weight: bold;
                    border-bottom: 1px solid #707070;
                `;
      default:
        return `
                    padding-block: 10px;
                    padding-inline: 15px;
                    border-block: ${COLORS.text} 1px solid;
                    background: ${COLORS.secondary};
                `;
    }
  }}

  &:first-child {
    ${(props) => {
      switch (props.$stylePreset) {
        case 'boxyScrollable':
          return `
                        z-index: 10;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: ${COLORS.text};
                        color: white;
                        height: 70px;
                        padding-inline: 30px;
                        font-weight: bold;
                        border-bottom: 1px solid #707070;
                    `;
        default:
          return `
                        border-left: ${COLORS.text} 1px solid;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    `;
      }
    }}
  }

  &:last-child {
    ${(props) => {
      switch (props.$stylePreset) {
        case 'boxyScrollable':
          return ``;
        default:
          return `
                        border-right: ${COLORS.text} 1px solid;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    `;
      }
    }}
  }
`;

const StyledTd = styled.td`
  font-weight: bold;
  &::first-letter {
    text-transform: capitalize;
  }
  text-align: center;

  ${(props) => {
    switch (props.$stylePreset) {
      case 'boxyScrollable':
        return `
                    left: 190px;
                    background: ${COLORS.light};
                    height: 70px;
                    width: auto;
                    padding-block: auto;
                    font-weight: bold;
                    border-bottom: 1px solid #707070;
                `;
      default:
        return `
                    padding-block: 10px;
                    padding-inline: 15px;
                    border-block: ${COLORS.text} 1px solid;
                `;
    }
  }}

  &:first-child {
    ${(props) => {
      switch (props.$stylePreset) {
        case 'boxyScrollable':
          return `
                        z-index: 10;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: ${COLORS.secondary};
                        color: white;
                        height: 70px;
                        padding-inline: 30px;
                        font-weight: bold;
                        border-bottom: 1px solid #707070;
                        color: black;
                    `;
        default:
          return `
                        border-left: ${COLORS.text} 1px solid;
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    `;
      }
    }}
  }

  &:last-child {
    ${(props) => {
      switch (props.$stylePreset) {
        case 'boxyScrollable':
          return ``;
        default:
          return `
                        border-right: ${COLORS.text} 1px solid;
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                    `;
      }
    }}
  }
`;

const PaginationInfo = styled.small`
  margin-right: 10px;
`;
const NumberOfResults = styled.small`
  align-self: flex-start;
  margin-block: 10px;
  margin-right: auto;
`;

const ViewMoreButton = styled(LinkButton)`
  align-self: flex-end;
  margin-top: 20px;
`;

const PaginationContainer = styled(ContentContainer)`
  padding: 0px;
  border: none;
  background: white;
  flex: display;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default Table;
