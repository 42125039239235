import React, { useState, useEffect } from 'react';
import PageCard from '../../components/PageCard';
import SimpleCard from '../../components/SimpleCard';
import { useParams } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { StyledTable } from '../../components/TableComponents.js';
import { EditButton } from '../../components/CommonComponents.js';

const getEditReports = (id) => {
  return axios
    .get(`/api/sheep/edit/sheep/${id}`)
    .then((res) => {
      let report = res.data;

      if (report) {
        if (report.gender === undefined) report.gender = 'N/A';
        if (report.newGender === undefined) report.newGender = 'Unchanged';

        if (report.subgender === undefined) report.subgender = 'N/A';
        if (report.newSubGender === undefined)
          report.newSubGender = 'Unchanged';

        if (report.breed === undefined) report.breed = 'N/A';
        if (report.newBreed === undefined) report.newBreed = 'Unchanged';

        if (report.birthdate === undefined) report.birthdate = 'N/A';
        else report.birthdate = report.birthdate.split('T')[0];
        if (report.newBirthdate === undefined)
          report.newBirthdate = 'Unchanged';
        else report.newBirthdate = report.newBirthdate.split('T')[0];
      }

      return report;
    })
    .catch((err) => {
      if (err.response) throw err.response.data.message;
      else throw err;
    });
};

const replaceFields = [
  {
    name: 'Old Information',
    id: 'oldInfo',
    gender: 'gender',
    subgender: 'subgender',
    breed: 'breed',
    birthdate: 'birthdate',
  },
  {
    name: 'New Information',
    id: 'newInfo',
    gender: 'newGender',
    subgender: 'newSubGender',
    breed: 'newBreed',
    birthdate: 'newBirthdate',
  },
];

const TagRetireDetailView = () => {
  const [editReport, setEditReport] = useState([]);
  const params = useParams();
  const id = params.id;

  const fetchEditReport = async () => {
    let fetchedReport = await getEditReports(id);
    fetchedReport.createdAt = new Date(fetchedReport.createdAt)
      .toISOString()
      .split('T')[0];
    setEditReport(fetchedReport);
  };

  useEffect(() => {
    fetchEditReport();
  }, []);

  return (
    <>
      <PageCard
        title=" Sheep Edit Report Details"
        verify={`/verify/sheepedit/${id}`}
        line
        back
      >
        <SimpleCard title="Tag Information" size="md">
          <DetailTable data={editReport} fields={replaceFields} />
        </SimpleCard>

        {editReport ? (
          <>
            <SimpleCard title="Report Information" size="md">
              <HalfDiv>
                <Info
                  name="PID"
                  value={
                    editReport.animal ? editReport.animal.premise.pid : 'N/A'
                  }
                />
                <Info
                  name="PID"
                  value={
                    editReport.animal ? editReport.animal.premise.name : 'N/A'
                  }
                />
              </HalfDiv>
              <HalfDiv>
                <Info
                  name="Edited Date"
                  value={
                    editReport.createdAt
                      ? new Date(editReport.createdAt)
                          .toISOString()
                          .split('T')[0]
                      : 'N/A'
                  }
                />
              </HalfDiv>
            </SimpleCard>
          </>
        ) : (
          <>Loading</>
        )}
      </PageCard>
    </>
  );
};

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DetailTable = ({ data, fields, buttons }) => {
  return (
    <>
      <StyledTable style={{ marginTop: '10px', tableLayout: 'fixed' }}>
        <thead>
          <tr>
            <th>Parameters</th>
            <th>Gender</th>
            <th>Sub Gender</th>
            <th>Breed</th>
            <th>Birthdate</th>
          </tr>
        </thead>
        <tbody>
          {fields.map((field) => (
            <>
              <tr>
                <td>{field.name}</td>
                <td>{data[field.gender]}</td>
                <td>{data[field.subgender]}</td>
                <td>{data[field.breed]}</td>
                <td>{data[field.birthdate]}</td>
              </tr>
            </>
          ))}
          <tr>
            <td colSpan="2">
              <ButtonsDiv>
                {buttons && buttons.length > 0 ? (
                  buttons.map((button) => (
                    <>
                      <EditButton
                        bColor={button.color}
                        onClick={button.onClick}
                      >
                        {button.name}
                      </EditButton>
                    </>
                  ))
                ) : (
                  <></>
                )}
              </ButtonsDiv>
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </>
  );
};

DetailTable.propTypes = {
  data: propTypes.node,
  fields: propTypes.node,
  buttons: propTypes.node,
};

const Info = ({ name, value }) => {
  return (
    <div>
      <span>{name}:&nbsp;</span>
      <span>{value}</span>
    </div>
  );
};

Info.propTypes = {
  name: propTypes.string,
  value: propTypes.any,
};

const HalfDiv = styled.div`
  width: 50%;
  float: left;
  display: inline-block;
  margin-bottom: 20px;
`;

export default TagRetireDetailView;
