import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import sheepPic from '../../images/b0.jpg';
import { Link } from 'react-router-dom';
import { Button } from '../../components/CommonComponents.js';
import { useParams, useHistory } from 'react-router-dom';

import { refreshTokens, getUsername } from '../../utils/TokenUtils';
import axios from 'axios';

export default function CarouselView() {
  let history = useHistory();
  const [username, setUsername] = useState('');
  const { page } = useParams();

  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    setUsername(getUsername());
    setActiveIndex(+page);
    console.log('page is equal to ' + page);
  }, [page]);

  function updateUser() {
    axios.put(`/api/users/${username}`, {}).then(() => {
      refreshTokens();
    });
  }

  const slideData = [
    {
      title: 'Welcome',
      caption:
        'Thank you for registering with AgroLedger.  Let’s get started with building your account.',
      img: sheepPic,
    },
    {
      title: 'Operations',
      caption: `First, we need to create your operation(s) in AgroLedger.  These are your business operations defined by the types of activities the operation carries out (i.e. farm, feedlot, etc).
				  You can have more than one operation associated with your account, and defining different operations is important for making sure you can access appropriate AgroLedger activities.`,
      img: sheepPic,
    },

    {
      title: 'Operations',
      img: sheepPic,
      msg: 'Create your AgroLedger operations here',
      link: '/operations',
    },

    {
      title: 'Vehicles',
      caption: `Reports that include moving animals with vehicles often require adding vehicle identification information.  If you have your own trucks and trailers for moving sheep,
       you can enter them in the system now to make event reporting easier later.`,
      img: sheepPic,
    },

    {
      title: 'Vehicles',
      img: sheepPic,
      caption:
        'Note: Vehicles are added under an operation. If you have not created an operation yet, please do so first.',
      msg: 'Add vehicles to your account here',
      link: '/operations',
    },

    {
      title: 'Premises',
      caption: `First, let’s create your premises.  Premises are locations where various events will take place, and you can have multiple premises linked to your account.  To get started with creating your premises, you will need your PIDs (Premises Identification Number(s)) issued by your provincial government.
        Don’t have your PIDs yet? Here’s where you can find more information about registering with your provincial government: `,
      external: `https://www.inspection.gc.ca/animal-health/terrestrial-animals/traceability/premises-identification/eng/1495127375452/1495127376419`,
      img: sheepPic,
    },

    {
      title: 'Premises',
      img: sheepPic,
      msg: 'Create your AgroLedger premises here',
      link: '/premises',
    },

    {
      title: 'Animals',
      caption: `Next, you can create or enter animals you currently have on your premises.  Creating an animal inventory in the system is not mandatory, but it will help build a starting point for your account and make it easier to generate system reports.
        You can add animals one at a time or upload an existing inventory file.`,
      img: sheepPic,
    },

    {
      title: 'Animals',
      img: sheepPic,
      msg: 'Add animals to your account here',
      link: '/sheep',
    },

    {
      title: 'Event Reporting',
      caption: `Finally, you can report events involving one or more animals.  Some events involve mandatory reporting under the current regulation, some events will be mandatory to report under future regulations and can be voluntarily reported now, and some events are completely voluntary.  
      In all cases, keeping track of animals by reporting life events is an invaluable tool both for management and traceability purposes.`,
      img: sheepPic,
    },
  ];

  function clickLeft() {
    if (activeIndex > 0) {
      setActiveIndex((prev) => prev - 1);
      history.push({ pathname: `/onboarding/${activeIndex - 1}` });
      // return(
      // 	<Redirect push to={`/onboarding/${activeIndex - 1}`}/>
      // );
    }
  }

  function clickRight() {
    console.log(slideData.length);
    console.log(activeIndex);
    if (activeIndex < slideData.length - 1) {
      setActiveIndex((prev) => prev + 1);
      history.push({ pathname: `/onboarding/${activeIndex + 1}` });
      // return(
      // 	<Redirect push to={`/onboarding/${activeIndex + 1}`}/>
      // );
    } else if (activeIndex === slideData.length - 1) {
      updateUser();
      history.push({ pathname: '/' });
      // return(
      // 	<Redirect push to="/"/>
      // );
    }
  }

  function circleClick(index) {
    setActiveIndex(index);
    history.push({ pathname: `/onboarding/${index}` });
    // return(
    // 	<Redirect push to={`/onboarding/${index}`}/>
    // );
  }

  return (
    <>
      <SlideCard>
        <Slide>
          <ArrowIcon onClick={clickLeft} icon={faChevronLeft} />
          <Section>
            <SlideTitle>{slideData[activeIndex].title}</SlideTitle>
            <Img src={slideData[activeIndex].img} />
            <SlideCaption>
              {slideData[activeIndex].caption}
              {slideData[activeIndex].external && (
                <div>
                  <br />
                  <ExternalLink
                    onClick={() =>
                      window.open(`${slideData[activeIndex].external}`)
                    }
                  >
                    Find out more !
                  </ExternalLink>
                </div>
              )}
            </SlideCaption>
            {slideData[activeIndex].msg && (
              <div>
                <Link to={slideData[activeIndex].link}>
                  <Button>{slideData[activeIndex].msg}</Button>
                </Link>
              </div>
            )}
            <Indicators>
              {slideData.map((_slide, index) => {
                if (activeIndex === index) {
                  return (
                    <Circle
                      key={index}
                      filled
                      onClick={() => circleClick(index)}
                    />
                  );
                } else {
                  return (
                    <Circle key={index} onClick={() => circleClick(index)} />
                  );
                }
              })}
            </Indicators>
            {activeIndex === slideData.length - 1 && (
              <LinkContainer>
                <StyledLink onClick={() => setActiveIndex(0)}>Back</StyledLink>
                <StyledLink>
                  <Link
                    style={{ color: 'white' }}
                    onClick={updateUser()}
                    to="/"
                  >
                    Let&apos;s get started!
                  </Link>
                </StyledLink>
              </LinkContainer>
            )}
          </Section>

          <ArrowIcon onClick={clickRight} icon={faChevronRight} />
        </Slide>
      </SlideCard>
    </>
  );
}

const SlideCard = styled.div`
  padding: 10px;
  min-height: 500px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
`;
const ArrowIcon = styled(FontAwesomeIcon)`
  color: white;
  font-size: 50px;
  margin-top: 350px;
  cursor: pointer;
`;
const Slide = styled.div`
  display: flex;
  // justify-content: space-between;
  height: 100%;
`;

const Circle = styled.circle`
  height: 25px;
  width: 25px;
  border: 2px solid black;
  border-radius: 50%;
  background: ${(props) => (props.filled ? 'white' : 'grey')};
  display: inline-block;
  margin-left: 10px;
  cursor: pointer;
`;

const SlideTitle = styled.div`
  font-size: 40px;
`;

const SlideCaption = styled.div`
  font-size: 20px;
  line-height: 1.6;
  // margin-top: 20px;
`;
const Section = styled.div`
  width: 900px;
  // height: 700px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  // background-color: green;
  justify-content: space-evenly;
`;
const Indicators = styled.div`
  flex-direction: row;
  margin-top: 50px;
`;

const Img = styled.img`
  // width: 300px;
  // height: 300px;
  max-width: 500px;
  max-height: 500px;
  min-width: 300px;
  min-height: 300px;

  // margin: auto;
`;

const LinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledLink = styled.div`
  font-size: 2em;
  text-decoration: underline;
  cursor: pointer;
  font-color: white;
`;

const ExternalLink = styled.div`
  color: white;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
`;
