import React, { useState } from 'react';
import { FormInputLabel } from '../components/CommonComponents.js';
import propTypes from 'prop-types';

function TestNotifications({ client }) {
  const [recipient, setRecipient] = useState('defaultAdmin');
  const [message, setMessage] = useState('Hey');

  const sendNotification = () => {
    client.send(
      JSON.stringify({
        request: 'SEND',
        recipient: recipient,
        data: { type: 'MESSAGE', data: message },
      }),
    );
  };

  return (
    <form>
      <FormInputLabel>To:</FormInputLabel>
      <input value={recipient} onChange={(e) => setRecipient(e.target.value)} />
      <FormInputLabel>Message</FormInputLabel>
      <input value={message} onChange={(e) => setMessage(e.target.value)} />
      <button onClick={sendNotification}>Send request</button>
    </form>
  );
}

TestNotifications.propTypes = {
  client: propTypes.any,
};

export default TestNotifications;
