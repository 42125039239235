import axios from 'axios';

export const traceSheep = async (sheepID, depth) => {
  try {
    const response = await axios.get(`api/trace/sheep/${sheepID}/${depth}`);
    return response.data;
  } catch (err) {
    throw err.response.data.message || err.message;
  }
};
